import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import USPhoneNumberWithoutCountryCodeUniversal from "../../../global_components/inputs/us_phone_number_without_country_code";
import * as yup from 'yup';
import {useFormik} from "formik";
import {log} from "../../../utils/logger";
import {deleteVermostLawFirmBackend, getVermostLawFirmLocationsPagination} from "../../../services/company_management";
import {useSelector} from "react-redux";
import CustomSelectInput from "../../../global_components/inputs/select-field";
import {Avatar, Grid, Tooltip} from "@mui/material";
import {useSnackbar} from "notistack";
import {hasUserPermission} from "../../../utils/user_permission";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {ConfirmationPopup} from "../../../utils/confirmation_popup";
import {removeLawFirm} from "../../../store/company_management";
import {useLocation, useNavigate} from "react-router";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'; // Import CreatableSelect
import { getAPICallv2 } from "../../../services/helper";
import { API_URL } from "../../../constants";
import { memo } from 'react';
import { FixedSizeList as List } from 'react-window';

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const MenuList = memo((props) => {
    const height = 35; // Set height for each option item
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue(); // Get the selected value
    const initialOffset = options.indexOf(value) * height; // Scroll to the selected item

    return (
        <List
            height={Math.min(maxHeight, options.length * height)} // Set height for the list, based on the number of options or max height
            itemCount={children.length} // Number of items to render
            itemSize={height} // Height of each item
            initialScrollOffset={initialOffset} // Initial scroll position
        >
            {({ index, style }) => (
                <div style={style}>
                    {children[index]} {/* Render the visible option */}
                </div>
            )}
        </List>
    );
});

function LawFirmMain(props) {
    const {enqueueSnackbar} = useSnackbar();
    const [addressOptions, setAddressOptions] = useState([]);
    const [validPhone, setValidPhone] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const isValidate = hasUserPermission(user_permission, 'ignore_validation');
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [locationOptions, setLocationOptions] = useState({ state: [], city: [], county: [] });
    const [locationResults, setLocationResults] = useState({});
    const [zipCode, setZipCode] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const super_user = useSelector((state) => state.auth.user.user_role_name) === "SuperAdmin";
    const [indexValue, setIndexvalue] = useState(0);

    const debouncedZipCode = useDebounce(zipCode, 1000); // 500ms delay

    const schema = yup.object().shape(
        props.id !== 0 ?
            {
                //name: yup.string().required("Law Firm Name is required."),
                //customer_number: isValidate ? yup.number().nullable() : yup.number().required('Customer Number is required.').min(0),
                accounting_contact_email: yup.string().nullable().matches(/\S+@\S+\.\S+/, 'Please enter a valid email.'),
                accounting_contact_phone: yup.string().nullable().matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378"),
                locations: yup.array().of(yup.object().shape({
                    name: yup.string().required('Location Name is required.'),
                    phone: yup.string().required('Phone is required.'),
                    address: yup.string().required('Address is required.'),
                    state: yup.string().required('State is required.'),
                    zip_code: yup.string().required('Fax is required.'),
                    city: yup.string().required('City is required.'),
                    county: yup.string().required('County is required.')
                }))
            }
            :
            {
                name: yup.string().required("Law Firm Name is required."),
                // customer_number: (user_permission.length === 0 || isValidate) ? yup.number().nullable() : yup.number().required('Customer Number is required.').min(0),
                accounting_contact_email: yup.string().nullable().matches(/\S+@\S+\.\S+/, 'Please enter a valid email.'),
                accounting_contact_phone: yup.string().nullable().matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378"),
                locations: yup.array().of(yup.object().shape({
                    name: yup.string().required('Location Name is required.'),
                    phone: yup.string().required('Phone is required.'),
                    address: yup.string().required('Address is required.'),
                    state: yup.string().required('State is required.'),
                    zip_code: yup.string().required('Fax is required.'),
                    city: yup.string().required('City is required.'),
                    county: yup.string().required('County is required.')
                }))
            });
            

    useEffect(async () => {
        const fetchLocationData = async () => {
            try {
                const response = await getAPICallv2('/bond/place_holder');
                
                // Ensure response is parsed as JSON
                const result = await response.json();  // Parse the body as JSON
                
                console.log(result);  // Log the parsed result
        
                // Access the first object in the result array
                if (result && result.length > 0 && result[0].city && result[0].state && result[0].county) {
                    const locData = result[0];  // Access the first object in the array
                    
                    const locOpt = {
                        city: locData.city.map(item => ({ label: item, value: item })),
                        state: locData.state.map(item => ({ label: item, value: item })),
                        county: locData.county.map(item => ({ label: item, value: item })),
                    };
                    setLocationOptions(locOpt);
                    console.log("Location options updated:", locOpt);
                }
            } catch (err) {
                console.error("Error fetching location data:", err);
            }
        };        
        if (props.id !== 0) {
            let response = await getVermostLawFirmLocationsPagination(props.id, "", "", "", "");
            if (response.status === 200) {
                const result = await response.json();
                let address_list = [];
                result.data.map((location) => address_list.push({
                    label: `${location.name}`,
                    value: location.id
                }))
                setAddressOptions(address_list);
                setAddressList(result.data);
            } else {
                const result = await response.json();
                enqueueSnackbar(result, {variant: "error"});
            }

        } 
        fetchLocationData();
    }, []);

    const destroyLawFirm = async () => {
        await setOpenConfirmationPopup(false);
        navigate(location.state !== undefined && location.state !== null ? `${location?.state?.history}` : "/law_firm_list");
    }
    const handleConfirmationPopup = async () => {
        await setOpenConfirmationPopup(false);
        await destroyLawFirm();
    }

    const getInitialValues = () => {
        if (props.id !== 0) {
            let data = props.data;
            return {
                logo: data.logo,
                name: data.name,
                website: data.website,
                accounting_contact_full_name: data.accounting_contact_full_name,
                accounting_contact_email: data.accounting_contact_email,
                accounting_contact_phone: data.accounting_contact_phone,
                mailing_address: data.mailing_address,
                physical_address: data.physical_address,
                billing_address: data.billing_address,
                is_active: data.is_active
            }
        } else {
            return {
                logo: '',
                name: '',
                website: '',
                customer_number: '',
                accounting_contact_full_name: '',
                accounting_contact_email: '',
                accounting_contact_phone: '',
                is_active: true,
                locations: [
                    {
                        name: 'Main Location',
                        customer_number: '',
                        phone: '',
                        fax: '',
                        address: '',
                        city: '',
                        county: '',
                        state: '',
                        zip_code: '',
                        use_seperate_firm_accounting_contact: false,
                        accounting_contact_full_name: '',
                        accounting_contact_email: '',
                        accounting_contact_phone: '',
                        physical_address: true,
                        mailing_address: true,
                        billing_address: true,
                        is_active: true,
                    }
                ],
            }
        }
    }
    const handleRemoveLocation = (index) => {
        let updatedLocations = [...formik.values.locations];
        if (formik.values.locations[index].billing_address) {
            updatedLocations[0].billing_address = true;
        }
        if (formik.values.locations[index].mailing_address) {
            updatedLocations[0].mailing_address = true;
        }
        if (formik.values.locations[index].physical_address) {
            updatedLocations[0].physical_address = true;
        }

        updatedLocations.splice(index, 1);
        formik.setValues(prevItems => ({
            ...prevItems,
            locations: updatedLocations
        }));
    };

    const handleAddLocation = () => {
        const newLocation = {
            name: '',
            customer_number: '',
            phone: '',
            fax: '',
            address: '',
            city: '',
            county: '',
            state: '',
            zip_code: '',
            use_seperate_firm_accounting_contact: false,
            accounting_contact_full_name: '',
            accounting_contact_email: '',
            accounting_contact_phone: "",
            physical_address: false,
            mailing_address: false,
            billing_address: false,
            is_active: true,
        };
        formik.setValues(prevItems => ({
            ...prevItems,
            locations: [...prevItems.locations, newLocation]
        }));
    };

    const formik = useFormik({
            initialValues: getInitialValues(),
            onSubmit: async (values, {setSubmitting, resetForm}) => {
                log("submit");
                setSubmitting(true);
                if (props.id !== undefined && props.id !== 0) {
                    formik.resetForm({values: ''});
                    props.oncallback('edit', values);
                } else {
                    log("vales,", values);
                    if (props.userEditPage) {
                        props.oncallback('create_law_firm', values);
                    } else {
                        props.oncallback('insert', values);
                    }
                }
                setSubmitting(false);
            },
            validationSchema: schema
        }
    );

      

    const getLocationValues = async (zip, index) => {
        const token = localStorage.getItem('userdata');
        const response = await fetch(`${API_URL}/bond/place_holder?zip_code=${zip}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json",
            }
        });
    
        const result = await response.json();
        console.log(result);
    
        // Update formik field values
        formik.setFieldValue(`locations[${index}].city`, result.city);
        formik.setFieldValue(`locations[${index}].state`, result.state);
        formik.setFieldValue(`locations[${index}].county`, result.county);

        console.log("Data Set");
    
        // Update location options if needed for dropdowns
        const locOpt = {
            city: [{ label: result.city, value: result.city }],
            state: [{ label: result.state, value: result.state }],
            county: [{ label: result.county, value: result.county }]
        };
        setLocationResults(locOpt);
    };
    
    useEffect(() => {
        if (debouncedZipCode) {
            getLocationValues(debouncedZipCode, indexValue);
        }
    }, [debouncedZipCode]);

    

    useEffect(() => {
        console.log("Updated formik values", formik.values);
      }, [formik.values]);  // This will log the updated formik values whenever they change
      

    

    return (
        <>
            <h1>{props.id !== 0 ? 'Edit Law Firm - ' + formik.values.name : 'Create New Law Firm'}</h1>
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                        <Form.Group key={'name'} controlId="validationFormik02" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Law Firm Name</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={(event) => {
                                              setShowSubmitButton(true);
                                              formik.handleChange(event)
                                          }
                                          }
                                          name='name'
                                          value={formik.values.name}
                                          isInvalid={!!formik.errors.name}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                </Row>
                <Row>
                    <div className={"col-2"} hidden={props.id === 0}>
                        <Avatar
                            alt="Logo"
                            src={formik.values.logo}
                            sx={{width: 56, height: 56}}

                        />
                    </div>
                </Row>
                <Row>
                    <Col xl={props.id !== 0 ? 6 : 4}>
                        <Form.Group key={'logo'} controlId="validationFormik01" className="mb-3">
                            <Form.Label><strong>Logo</strong></Form.Label>
                            <div className="row">
                                <div className={"col-2"} hidden={props.id !== 0}>
                                    <Avatar
                                        alt="Logo"
                                        src={formik.values.logo}
                                        sx={{width: 56, height: 56}}

                                    />
                                </div>
                                <div className={props.id !== 0 ? "col-12" : "col-10"}>
                                    <Form.Control type="file" size="sm"
                                                  accept='image/*'
                                                  onChange={(e) => {
                                                      formik.setFieldValue('logo', e.currentTarget.files[0]);
                                                      setShowSubmitButton(true);
                                                  }
                                                  }
                                                  name='logo'
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xl={props.id !== 0 ? 6 : 4}>
                        <Form.Group key={'website'} controlId="validationFormik03" className="mb-3">
                            <Form.Label><strong>Website</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={(event) => {
                                              setShowSubmitButton(true);
                                              formik.handleChange(event)
                                          }
                                          }
                                          name='website'
                                          value={formik.values.website}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={props.id !== 0 ? 6 : 4}>
                        <Form.Group key={'accounting_contact_full_name'} controlId="validationFormik11"
                                    className="mb-3">
                            <Form.Label><strong>Accounting Contact
                                Full Name</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={(event) => {
                                              setShowSubmitButton(true);
                                              formik.handleChange(event)
                                          }
                                          }
                                          name='accounting_contact_full_name'
                                          value={formik.values.accounting_contact_full_name}/>
                        </Form.Group>
                    </Col>
                    <Col xl={props.id !== 0 ? 6 : 4}>
                        <Form.Group key={'accounting_contact_email'} controlId="validationFormik12" className="mb-3">
                            <Form.Label><strong>Accounting Contact
                                Email</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={(event) => {
                                              setShowSubmitButton(true);
                                              formik.handleChange(event)
                                          }
                                          }
                                          name='accounting_contact_email'
                                          value={formik.values.accounting_contact_email}
                                          isInvalid={!!formik.errors.accounting_contact_email}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.accounting_contact_email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={props.id !== 0 ? 6 : 4}>
                        <Form.Group key={'accounting_contact_phone'} controlId="validationFormik13" className="mb-3">
                            <Form.Label><strong>Accounting Contact
                                Phone</strong></Form.Label><br></br>
                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={false}
                                                                      className="form-control"
                                                                      onChange={(val) => {
                                                                          if (val.match(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378")) {
                                                                              setValidPhone(true);
                                                                          } else {
                                                                              setValidPhone(false);
                                                                          }
                                                                          formik.values.accounting_contact_phone = val;
                                                                          setShowSubmitButton(true);

                                                                      }}
                                                                      name='accounting_contact_phone'
                                                                      id='accounting_contact_phone'
                                                                      value={formik.values.accounting_contact_phone}
                            />
                            {formik.errors['accounting_contact_phone'] && !validPhone && (
                                <div className="text-error">{formik.errors["accounting_contact_phone"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <Form.Group key={'physical_address'} controlId="validationFormik15" className="mb-3"
                                    hidden={props.id === undefined || props.id === 0}>
                            <Form.Label><strong>Physical Address</strong></Form.Label>
                            <CustomSelectInput size="sm"
                                               onChange={
                                                   (value) => {
                                                       formik.setFieldValue('physical_address', value);
                                                       setShowSubmitButton(true);
                                                   }
                                               }
                                               noOptionsMessage="You have to add a Location!"
                                               name='physical_address'
                                               value={addressOptions?.length !== 0 ? addressOptions.filter(item => item?.value === formik.values.physical_address)[0]?.value : []}
                                               options={addressOptions}/>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <Form.Group key={'mailing_address'} controlId="validationFormik07" className="mb-3"
                                    hidden={props.id === undefined || props.id === 0}>
                            <Form.Label><strong>Mailing Address</strong></Form.Label>
                            <CustomSelectInput size="sm"
                                               onChange={
                                                   (value) => {
                                                       formik.setFieldValue('mailing_address', value);
                                                       setShowSubmitButton(true);
                                                   }
                                               }
                                               noOptionsMessage="You have to add a Location!"
                                               name='mailing_address'
                                               value={addressOptions?.length !== 0 ? addressOptions.filter(item => item?.value === formik.values.mailing_address)[0]?.value : []}
                                               options={addressOptions}/>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <Form.Group key={'billing_address'} controlId="validationFormik09" className="mb-3"
                                    hidden={props.id === undefined || props.id === 0}>
                            <Form.Label><strong>Billing Address</strong></Form.Label>
                            <CustomSelectInput type="text" size="sm"
                                               onChange={
                                                   (value) => {
                                                       formik.setFieldValue('billing_address', value);
                                                       setShowSubmitButton(true);
                                                   }
                                               }
                                               noOptionsMessage="You have to add a Location!"
                                               name='billing_address'
                                               value={addressOptions?.length !== 0 ? addressOptions.filter(item => item?.value === formik.values.billing_address)[0]?.value : []}
                                               options={addressOptions}/>
                        </Form.Group>
                    </Col>
                </Row>
                {super_user && <Row>
                    <Col xl={6}> <Form.Group key={'is_active'} controlId="validationFormik14" className="mb-3"
                                             hidden={props.id === undefined || props.id === 0}>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="is_active"
                            label="Is Active"
                            onChange={(event) => {
                                setShowSubmitButton(true);
                                formik.handleChange(event)
                            }
                            }
                            checked={formik.values.is_active === true}
                        />
                    </Form.Group></Col>
                </Row>}

                {/*<Form.Group key={'mailing_address_info'}*/}
                {/*            hidden={formik.values.mailing_address === '' || formik.values.mailing_address === 0}*/}
                {/*            controlId="validationFormik08"*/}
                {/*            className="mb-3">*/}
                {/*    <Form.Label><strong>Address</strong></Form.Label><br></br>*/}
                {/*    <Form.Label>{*/}
                {/*        props.id !== undefined && props.id !== 0 && formik.values.mailing_address !== '' ?*/}
                {/*            <CopyToClipboard*/}
                {/*                data={addressList?.filter(item => item?.id === formik.values.mailing_address)[0]?.address}*/}
                {/*                subData={addressList?.filter(item => item?.id === formik.values.mailing_address)[0]?.city + ', ' + addressList?.filter(item => item?.id === formik.values.mailing_address)[0]?.state}/> : ""}*/}
                {/*    </Form.Label>*/}
                {/*</Form.Group>*/}

                {/*<Form.Group key={'billing_address_info'}*/}
                {/*            hidden={formik.values.billing_address === '' || formik.values.billing_address === 0}*/}
                {/*            controlId="validationFormik10"*/}
                {/*            className="mb-3">*/}
                {/*    <Form.Label><strong>Address</strong></Form.Label><br></br>*/}
                {/*    <Form.Label>{*/}
                {/*        props.id !== undefined && props.id !== 0 && formik.values.billing_address !== '' ?*/}
                {/*            <CopyToClipboard*/}
                {/*                data={addressList?.filter(item => item?.id === formik.values.billing_address)[0]?.address}*/}
                {/*                subData={addressList?.filter(item => item?.id === formik.values.billing_address)[0]?.city + ', ' + addressList?.filter(item => item?.id === formik.values.billing_address)[0]?.state}/> : ""}</Form.Label>*/}
                {/*</Form.Group>*/}


                {props.id === 0 &&
                    <>
                        <h4>Add Locations for this Law Firm</h4>
                        {formik.values.locations?.map((source, index) => (
                            <React.Fragment key={index}>
                                <Row style={{border: '1px solid gray', padding: '20px'}}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={8}>
                                            <div
                                                style={{display: 'flex', alignItems: 'center'}}>
                                                <h4>{`Location ${index + 1}`}</h4>
                                                {index !== 0 && <IconButton
                                                    size="small"
                                                    onClick={() => handleRemoveLocation(index)}
                                                    aria-label="Remove"
                                                    style={{marginTop: '-5px', color: 'red'}}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Row>
                                        <Col xl={8}>
                                            <Form.Group key={'locations[' + index + '].name'}
                                                        controlId={'locations[' + index + '].name'}
                                                        className="mb-3">
                                                <Form.Label
                                                    className={'required-label'}><strong>Location
                                                    Name</strong></Form.Label>
                                                <Form.Control type="text" size="sm"
                                                              onChange={(e) => {
                                                                  formik.setFieldValue("locations[" + index + "].name", e.target.value);
                                                              }}
                                                              name={'locations[' + index + '].name'}
                                                              value={formik.values.locations[index].name}/>
                                                {formik.values.locations.length > 0 && formik.errors.locations?.length > 0 && (formik.values.locations[index].name === undefined || formik.values.locations[index].name === null || formik.values.locations[index].name === '') && (
                                                    <div
                                                        className="text-error">{'Location Name is required.'}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].address'}
                                                        controlId={'locations[' + index + '].address'}
                                                        className="mb-3">
                                                <Form.Label
                                                    className={'required-label'}><strong>Address</strong></Form.Label>
                                                <Form.Control type="text" size="sm"
                                                              onChange={(e) => {
                                                                  formik.setFieldValue("locations[" + index + "].address", e.target.value);
                                                              }}
                                                              name={'locations[' + index + '].address'}
                                                              value={formik.values.locations[index].address}
                                                />
                                                {formik.values.locations.length > 0 && formik.errors.locations?.length > 0 && (formik.values.locations[index].address === undefined || formik.values.locations[index].address === null || formik.values.locations[index].address === '') && (
                                                    <div
                                                        className="text-error">{'Address is required.'}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].zip_code'}
                                                        controlId={'locations[' + index + '].zip_code'}
                                                        className="mb-3">
                                                <Form.Label className={'required-label'}><strong>Zip
                                                    Code</strong></Form.Label>
                                                <Form.Control type="text" size="sm"
                                                              onChange={(e) => {
                                                                formik.setFieldValue("locations[" + index + "].zip_code", e.target.value);
                                                                setZipCode(e.target.value); 
                                                                setIndexvalue(index);
                                                              }}
                                                              name={'locations[' + index + '].zip_code'}
                                                              value={formik.values.locations[index].zip_code}
                                                />
                                                {formik.values.locations.length > 0 && formik.errors.locations?.length > 0 && (formik.values.locations[index].zip_code === undefined || formik.values.locations[index].zip_code === null || formik.values.locations[index].zip_code === '') && (
                                                    <div
                                                        className="text-error">{'Zip Code is required.'}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4}>
    <Form.Group key={`locations[${index}].city`} controlId={`locations[${index}].city`} className="mb-3">
        <Form.Label className={'required-label'}><strong>City</strong></Form.Label>
        <CreatableSelect
            options={locationOptions.city} // The options should come from the API response or predefined list
            components={{ MenuList }}
            isSearchable
            isClearable
            onChange={(selectedOption) => {
                formik.setFieldValue(`locations[${index}].city`, selectedOption ? selectedOption.value : '');
            }}
            onCreateOption={(inputValue) => {
                // Handle creation of a new city option
                formik.setFieldValue(`locations[${index}].city`, inputValue);
            }}
            name={`locations[${index}].city`}
            value={formik.values.locations[index].city ? { label: formik.values.locations[index].city, value: formik.values.locations[index].city } : city}
        />
        {formik.values.locations.length > 0 && formik.errors.locations?.length > 0 && (!formik.values.locations[index].city) && (
            <div className="text-error">{'City is required.'}</div>
        )}
    </Form.Group>
</Col>

<Col xl={4}>
    <Form.Group key={`locations[${index}].county`} controlId={`locations[${index}].county`} className="mb-3">
        <Form.Label className={'required-label'}><strong>County</strong></Form.Label>
        <CreatableSelect
            options={locationOptions.county} // The options should come from the API response or predefined list
            isSearchable
            isClearable
            onChange={(selectedOption) => {
                formik.setFieldValue(`locations[${index}].county`, selectedOption ? selectedOption.value : '');
            }}
            onCreateOption={(inputValue) => {
                // Handle creation of a new county option
                formik.setFieldValue(`locations[${index}].county`, inputValue);
            }}
            name={`locations[${index}].county`}
            value={formik.values.locations[index].county ? { label: formik.values.locations[index].county, value: formik.values.locations[index].county } : county}
        />
        {formik.values.locations.length > 0 && formik.errors.locations?.length > 0 && (!formik.values.locations[index].county) && (
            <div className="text-error">{'County is required.'}</div>
        )}
    </Form.Group>
</Col>

<Col xl={4}>
    <Form.Group key={`locations[${index}].state`} controlId={`locations[${index}].state`} className="mb-3">
        <Form.Label className={'required-label'}><strong>State</strong></Form.Label>
        <CreatableSelect
            options={locationOptions.state} // The options should come from the API response or predefined list
            isSearchable
            isClearable
            onChange={(selectedOption) => {
                formik.setFieldValue(`locations[${index}].state`, selectedOption ? selectedOption.value : '');
            }}
            onCreateOption={(inputValue) => {
                // Handle creation of a new state option
                formik.setFieldValue(`locations[${index}].state`, inputValue);
            }}
            name={`locations[${index}].state`}
            value={formik.values.locations[index].state ? { label: formik.values.locations[index].state, value: formik.values.locations[index].state } : state}
        />
        {formik.values.locations.length > 0 && formik.errors.locations?.length > 0 && (!formik.values.locations[index].state) && (
            <div className="text-error">{'State is required.'}</div>
        )}
    </Form.Group>
</Col>
</Row>
                                    <Row>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].phone'}
                                                        controlId={'locations[' + index + '].phone'}
                                                        className="mb-3">
                                                <Form.Label
                                                    className={'required-label'}><strong>Phone</strong></Form.Label><br></br>
                                                <USPhoneNumberWithoutCountryCodeUniversal
                                                    disabled={false} required={true}
                                                    className="form-control"
                                                    onChange={(val) => {
                                                        formik.setFieldValue("locations[" + index + "].phone", val);
                                                    }}
                                                    id={'locations[' + index + '].phone'}
                                                    name={'locations[' + index + '].phone'}
                                                    value={formik.values.locations[index].phone}
                                                />
                                                {formik.values.locations.length > 0 && formik.errors.locations?.length > 0 && (formik.values.locations[index].phone === undefined || formik.values.locations[index].phone === null || formik.values.locations[index].phone === '') && (
                                                    <div
                                                        className="text-error">{'Phone is required.'}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].fax'}
                                                        controlId={'locations[' + index + '].fax'}
                                                        className="mb-3">
                                                <Form.Label><strong>Fax</strong></Form.Label><br></br>
                                                <USPhoneNumberWithoutCountryCodeUniversal
                                                    disabled={false} required={true}
                                                    className="form-control"
                                                    onChange={(val) => {
                                                        formik.setFieldValue("locations[" + index + "].fax", val);
                                                    }}
                                                    id={'locations[' + index + '].fax'}
                                                    name={'locations[' + index + '].fax'}
                                                    value={formik.values.locations[index].fax}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {hasUserPermission(user_permission, 'show_customer_number_for_law_firm') && <Row>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].customer_number'}
                                                        controlId={'locations[' + index + '].customer_number'}
                                                        className="mb-3">
                                                <Form.Label><strong>Customer
                                                    Number</strong></Form.Label>
                                                <Form.Control type="text" size="sm"
                                                              onChange={(e) => {
                                                                  formik.setFieldValue("locations[" + index + "].customer_number", e.target.value);
                                                              }}
                                                              name={'locations[' + index + '].customer_number'}
                                                              value={formik.values.locations[index].customer_number}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    }

                                    <Form.Group key={'locations[' + index + '].use_seperate_firm_accounting_contact'}
                                                controlId={'locations[' + index + '].use_seperate_firm_accounting_contact'}
                                                className="mb-3">
                                        <Form.Check
                                            type="checkbox"
                                            id={'locations[' + index + '].use_seperate_firm_accounting_contact'}
                                            name={'locations[' + index + '].use_seperate_firm_accounting_contact'}
                                            label={<strong>Use seperate Accounting Contact for this
                                                location</strong>}
                                            onChange={(e) => {
                                                formik.setFieldValue("locations[" + index + "].use_seperate_firm_accounting_contact", e.target.checked);
                                            }}
                                            checked={formik.values.locations[index].use_seperate_firm_accounting_contact}
                                        />
                                    </Form.Group>
                                    <Row
                                        hidden={formik.values.locations[index].use_seperate_firm_accounting_contact === false}>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].accounting_contact_full_name'}
                                                        controlId={'locations[' + index + '].accounting_contact_full_name'}
                                                        className="mb-3">
                                                <Form.Label><strong>Accounting Contact Full
                                                    Name</strong></Form.Label>
                                                <Form.Control type="text" size="sm"
                                                              name={'locations[' + index + '].accounting_contact_full_name'}
                                                              onChange={(e) => {
                                                                  formik.setFieldValue("locations[" + index + "].accounting_contact_full_name", e.target.value);
                                                              }}
                                                              value={!formik.values.locations[index].use_seperate_firm_accounting_contact ? formik.values.locations[index].accounting_contact_full_name : formik.values.accounting_contact_full_name}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].accounting_contact_email'}
                                                        controlId={'locations[' + index + '].accounting_contact_email'}
                                                        className="mb-3">
                                                <Form.Label><strong>Accounting Contact
                                                    Email</strong></Form.Label>
                                                <Form.Control type="text" size="sm"
                                                              onChange={(e) => {
                                                                  formik.setFieldValue("locations[" + index + "].accounting_contact_email", e.target.value);
                                                              }}
                                                              name={'locations[' + index + '].accounting_contact_email'}
                                                              value={!formik.values.locations[index].use_seperate_firm_accounting_contact ? formik.values.locations[index].accounting_contact_email : formik.values.accounting_contact_email}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].accounting_contact_phone'}
                                                        controlId={'locations[' + index + '].accounting_contact_phone'}
                                                        className="mb-3">
                                                <Form.Label><strong>Accounting Contact
                                                    Phone</strong></Form.Label><br></br>
                                                {
                                                    !formik.values.locations[index].use_seperate_firm_accounting_contact ?
                                                        <Form.Control type="text" size="sm"
                                                                      onChange={(e) => {
                                                                          formik.setFieldValue("locations[" + index + "].accounting_contact_phone", e.target.value);
                                                                      }}
                                                                      name={'locations[' + index + '].accounting_contact_phone'}
                                                                      value={!formik.values.use_seperate_firm_accounting_contact ? formik.values.locations[index].accounting_contact_phone : formik.values.accounting_contact_phone}
                                                        />
                                                        :
                                                        <USPhoneNumberWithoutCountryCodeUniversal
                                                            required={false}
                                                            className="form-control"
                                                            onChange={(val) => {
                                                                formik.setFieldValue("locations[" + index + "].accounting_contact_phone", val);
                                                            }}
                                                            name={'locations[' + index + '].accounting_contact_phone1'}
                                                            id={'locations[' + index + '].accounting_contact_phone1'}/>
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <h5>Use this location as...</h5>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].physical_address'}
                                                        controlId={'locations[' + index + '].physical_address'}
                                                        className="mb-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    id={'locations[' + index + '].physical_address'}
                                                    name={'locations[' + index + '].physical_address'}
                                                    label={<strong>Physical Address</strong>}
                                                    onChange={(e) => {
                                                        if (e.target.checked === true) {
                                                            formik.values.locations.forEach((value, tempIndex) => {
                                                                if (tempIndex !== index) {
                                                                    formik.setFieldValue("locations[" + tempIndex + "].physical_address", false);
                                                                }
                                                            })
                                                            formik.setFieldValue("locations[" + index + "].physical_address", e.target.checked);
                                                        }
                                                    }}
                                                    checked={formik.values.locations[index].physical_address}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].mailing_address'}
                                                        controlId={'locations[' + index + '].mailing_address'}
                                                        className="mb-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    id={'locations[' + index + '].mailing_address'}
                                                    name={'locations[' + index + '].mailing_address'}
                                                    label={<strong>Mailing Address</strong>}
                                                    onChange={(e) => {
                                                        if (e.target.checked === true) {
                                                            formik.values.locations.forEach((value, tempIndex) => {
                                                                if (tempIndex !== index) {
                                                                    formik.setFieldValue("locations[" + tempIndex + "].mailing_address", false);
                                                                }
                                                            })
                                                            formik.setFieldValue("locations[" + index + "].mailing_address", e.target.checked);
                                                        }
                                                    }}
                                                    checked={formik.values.locations[index].mailing_address}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4}>
                                            <Form.Group key={'locations[' + index + '].billing_address'}
                                                        controlId={'locations[' + index + '].billing_address'}
                                                        className="mb-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    id={'locations[' + index + '].billing_address'}
                                                    mame={'locations[' + index + '].billing_address'}
                                                    label={<strong>Billing Address</strong>}
                                                    onChange={(e) => {
                                                        if (e.target.checked === true) {
                                                            formik.values.locations.forEach((value, tempIndex) => {
                                                                if (tempIndex !== index) {
                                                                    formik.setFieldValue("locations[" + tempIndex + "].billing_address", false);
                                                                }
                                                            })
                                                            formik.setFieldValue("locations[" + index + "].billing_address", e.target.checked);
                                                        }
                                                    }}
                                                    checked={formik.values.locations[index].billing_address}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Row>
                            </React.Fragment>))
                        }

                        <div
                            style={{display: 'flex', alignItems: 'center', marginTop: '10px',}}>
                            <Button variant="primary" className={"ms-1"} onClick={handleAddLocation}>Add another
                                location </Button>
                        </div>
                    </>
                }


                <Form.Group key={'submit_button'} controlId="validationFormik14" className="mb-3 text-right">
                    <Row> {props.id === 0 ?
                        <>
                            <Col sm="5"></Col>
                            {props.lawFirmCreatePage && <Col sm="1">

                                <Tooltip
                                    title={<span style={{fontSize: '16px'}}>Discard all entered data</span>}>
                                    <Button size="lg" variant="warning" onClick={() => {
                                        setOpenConfirmationPopup(true)
                                    }}
                                            className={"ms-1"}>Cancel </Button>
                                </Tooltip>

                            </Col>}
                            <Col sm="1">
                                <Tooltip
                                    title={<span
                                        style={{fontSize: '16px'}}> {props.id === 0 ? "Click here to go to the Edit Law Firm's page." : "Click here to save Law Firm."}</span>}>
                                    <Button size="lg" type="submit"
                                            variant="primary"
                                            className={"ms-1"}>Submit </Button>
                                </Tooltip>
                            </Col>
                            <Col sm="5"></Col>
                        </>
                        :
                        <>
                            {showSubmitButton && <Col style={{textAlign: "right"}}>
                                <Tooltip
                                    title={<span
                                        style={{fontSize: '16px'}}> {props.id === 0 ? "Click here to go to the Edit Law Firm's page." : "Click here to save Law Firm."}</span>}>
                                    <Button size="lg" type="submit"
                                            variant="primary"
                                            className={"ms-1"}>Submit </Button>

                                </Tooltip>
                            </Col>
                            }
                        </>
                    }
                    </Row>

                </Form.Group>


                {<ConfirmationPopup show={openConfirmationPopup}
                                    setOpenConfirmationPopup={setOpenConfirmationPopup}
                                    title="Do you want to cancel all inputs?"
                                    handleClose={handleConfirmationPopup}/>
                }

            </Form>
        </>
    )
}

export default LawFirmMain;
