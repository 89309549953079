import React, { useEffect, useState } from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import "../../index.css";
import {
    Card,
    CardContent,
    Container,
} from "@mui/material";
import { ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
import { Col, Form, Row } from "react-bootstrap";
import CustomTextInput from "../../global_components/inputs/text";
import USPhoneNumberWithoutCountryCodeUniversal from "../../global_components/inputs/us_phone_number_without_country_code";

export default function PersonalInformation({ formIK, step, progress, changeStep }) {
    const [hasError, setHasError] = useState(false);

    const validate = () => {
        let error = false;
        formIK.errors = {}; // Reset errors before validation
        if (!formIK.values.user_first_name) {
            formIK.errors['user_first_name'] = 'User First Name is required.';
            error = true;
        }
        if (!formIK.values.user_last_name) {
            formIK.errors['user_last_name'] = 'User Last Name is required.';
            error = true;
        }
        if (!formIK.values.user_email) {
            formIK.errors['user_email'] = 'User Email is required.';
            error = true;
        }
        const regex = new RegExp(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm);
        if (!formIK.values.phone_number || !formIK.values.phone_number.match(regex)) {
            formIK.errors['phone_number'] = 'Please provide a valid phone number.';
            error = true;
        }

        if (error) {
            window.scroll(0, 0);
            setHasError(true);
        } else {
            changeStep(progress + step);
        }
    }

    useEffect(() => {
        // Possible use of useEffect if needed
    });

    return (
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{ textAlign: "left", width: "50%", display: "inline-block" }}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{ display: "inline" }} /> Back
                                </button>
                            </div>
                            <div style={{ textAlign: "right", width: "50%", display: "inline-block" }}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                    disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{ display: "inline" }} />
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{ justifyContent: "center" }}>
                        <Card sx={{ maxWidth: "50%" }} style={{ marginBottom: "2%" }}>

                            <CardContent>
                                <h4>Requestor Information</h4>
                                <Row>
                                    <Col>
                                        <Form.Group className="col-12" controlId="user_first_name">
                                            <Form.Label className="required-label"><strong>First Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name={"user_first_name"}
                                                className={"form-control"} value={formIK.values["user_first_name"]} onChange={(value) => {
                                                    formIK.setFieldValue("user_first_name", value);
                                                }} />
                                            <div className="text-error">{formIK.errors["user_first_name"]}</div>
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="user_last_name">
                                            <Form.Label className="required-label"><strong>Last Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name={"user_last_name"}
                                                className={"form-control"} value={formIK.values["user_last_name"]} onChange={(value) => {
                                                    formIK.setFieldValue("user_last_name", value);
                                                }} />
                                            <div className="text-error">{formIK.errors["user_last_name"]}</div>
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="user_email">
                                            <Form.Label className="required-label"><strong>Email</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name={"user_email"}
                                                className={"form-control"} value={formIK.values["user_email"]} onChange={(value) => {
                                                    formIK.setFieldValue("user_email", value);
                                                }} />
                                            <div className="text-error">{formIK.errors["user_email"]}</div>
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="phone_number">
                                            <Form.Label className="required-label"><strong>Phone Number</strong></Form.Label>
                                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                className={"form-control"} name="phone_number"
                                                value={formIK.values.phone_number} onChange={(value) => {
                                                    formIK.setFieldValue("phone_number", value);
                                                }} />
                                            <div className="text-error">{formIK.errors["phone_number"]}</div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{ textAlign: "left", width: "50%", display: "inline-block" }}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{ display: "inline" }} /> Back
                                </button>
                            </div>
                            <div style={{ textAlign: "right", width: "50%", display: "inline-block" }}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                    disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{ display: "inline" }} />
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

PersonalInformation.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
