import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import CusNavBar from '../../../global_components/navbar';
import Footer from '../../../global_components/footer';
import { API_URL } from '../../../constants';
import { useSnackbar } from 'notistack';

const VermostApproveParalegal = () => {
    const { userID } = useParams();
    const [user, setUser] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleApproveReject = async (status) => {
        const token = localStorage.getItem('userdata');
        const postData = { status };
        try {
            const response = await fetch(`${API_URL}/bond/approve_reject_paralegal/${userID}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });

            if (response.ok) {
                enqueueSnackbar(`User ${status}d successfully!`, { variant: 'success' });
                navigate('/');
            } else {
                const errMsg = await response.text();
                enqueueSnackbar(`Failed to ${status} user: ${errMsg}`, { variant: 'error' });
            }
        } catch (error) {
            console.error(`Error when trying to ${status} user:`, error);
            enqueueSnackbar(`An error occurred while trying to ${status} user`, { variant: 'error' });
        }
    };

    useEffect(() => {
        const getUser = async () => {
            if (!userID || userID === '0') {
                enqueueSnackbar('Invalid user ID', { variant: 'error' });
                navigate("/");
                return;
            }

            const url = `/user/vermost_user_detail/${userID}`;
            try {
                const token = localStorage.getItem('userdata');
                const response = await fetch(`${API_URL}${url}`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    setUser(result);
                } else {
                    enqueueSnackbar('Failed to fetch user data', { variant: 'error' });
                    navigate("/");
                }
            } catch (error) {
                console.error('Failed to fetch:', error);
                enqueueSnackbar('An error occurred', { variant: 'error' });
            }
        };

        getUser();
    }, [userID, navigate, enqueueSnackbar]);

    return (
        <div>
            <CusNavBar/>
            <div className="user-details">
                <h1 className='py-4 text-3xl'>Please Review and Approve the following Paralegal for <span className='font-bold'>Legal Services</span>:</h1>
                <div className="detail py-2"><strong>Full Name:</strong> {user.prefix} {user.first_name} {user.middle_name} {user.last_name} {user.suffix}</div>
                <div className="detail py-2"><strong>Email:</strong> {user.email}</div>
                <div className="detail py-2"><strong>Position:</strong> {user.position}</div>
                <div className="detail py-2"><strong>Phone Number:</strong> {user.phone_number}</div>
            </div>
            <div className='flex flex-row py-4'>
            <button onClick={() => handleApproveReject('approve')} className='bg-blue-500 py-2 px-2 text-white rounded-lg mr-2'>Approve</button>
            <button onClick={() => handleApproveReject('reject')} className='bg-red-500 py-2 px-3 text-white rounded-lg'>Reject</button>
            </div>
            <Footer/>
        </div>
    );
};

export default VermostApproveParalegal;
