import {DataGrid, GridFilterModel, GridSortModel} from '@mui/x-data-grid';
import {portal_insurance_history_sources} from '../constants';
import React, {useEffect, useState, useContext, useCallback} from 'react';
import "../styles/insurance.css";
import Button from '@mui/material/Button';
import CusNavBar from "./navbar";
import Footer from "./footer";
import Header from "./header";
import {get_insurance_quote_history_pagination} from "../services/portal_insurance"
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import APTableLoading from "./loadings/ap_table_loading";
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import InsuranceAlert from "../components/insurance_portal/insurance_alert";

function writeCarriers(jsonVal) {

    const listArr = [];
    if (jsonVal !== null && jsonVal !== undefined && jsonVal !== '' && jsonVal.length > 0) {
        jsonVal.forEach(element => {
            listArr.push(
                <li>{element.rater} {element.premium_amount !== null && element.premium_amount !== 0 ? '(' + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(element.premium_amount) + ')' : '(-)'}
                    <Button size='small' target="_blank" href={element.pdf_url}>
                        {
                            element.pdf_url !== null && element.pdf_url !== undefined && element.pdf_url !== "" ?
                                <DownloadIcon color="primary" fontSize="small"></DownloadIcon> : null
                        }
                    </Button>
                </li>);
        });

        listArr.push(<li>Input Data PDF<Button size='small' target="_blank"
                                               href={jsonVal[0].input_pdf_url}>{jsonVal[0].input_pdf_url !== null && jsonVal[0].input_pdf_url !== undefined && jsonVal[0].input_pdf_url !== "" ?
            <DownloadIcon color="primary" fontSize="small"></DownloadIcon> : null}</Button></li>);
    }

    return listArr;
}


var ulID = 0;

function RenderQuotes(props) {

    React.useLayoutEffect(() => {

        const tree = document.querySelectorAll('ul.tree a:not(:last-child)');
        for (var i = 0; i < tree.length; i++) {

            tree[i].addEventListener('click', function (e) {
                var parent = e.target.parentElement;
                var classList = parent.classList;

                var closeAllOpenSiblings = function () {
                    const elements = document.querySelectorAll([":scope .open"]).forEach((el) => {
                        el.classList.remove('open')
                    });

                }
                if (!classList.contains("open")) {
                    closeAllOpenSiblings();
                    classList.add('open');
                }
            });
        }

    }, []);

    const {value, ...authProviderConfig} = props;

    return (
        <strong>
            <ul id={"UL_" + ulID++}>
                <li>
                    {/* <span className="caret">All Quotations (<Button size='small' href="#" >Download All</Button>)</span> */}
                    <ul className="tree">
                        <li><a href="#">All Quotes</a>
                            <ul>
                                {

                                    writeCarriers(value)
                                }

                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

        </strong>
    );
}

function reformatDate(dateStr) {
    var dArrTemp = dateStr.split("T")[0];  // ex input: "2010-01-18"
    var dArr = dArrTemp.split("-");  // ex input: "2010-01-18"
    return dArr[1] + "/" + dArr[2] + "/" + dArr[0]; //ex output: "18/01/10"
}


const default_rows = (val) => {
    const rows = [];
    val.map(
        (info) => {

            const row = {
                id: info.id,
                key: info.id,
                applicant_full_name: info.applicant_full_name,
                applicant_address: info.applicant_address,
                applicant_cell_phone: info.applicant_cell_phone,
                request_date: reformatDate(info.request_date),
                requesting_user_full_name: info.requesting_user_full_name,
                selected_raters: info.selected_raters,
                quotes: info.request_result_quotation_detail
            };
            rows.push(row);
        })
    return rows;
}

function ExpandableCell({value}) {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Box>
            {expanded ? value : value.slice(0, 50)} &nbsp;
            {value.length > 50 && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                    type="button"
                    component="button"
                    sx={{fontSize: 'inherit'}}
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? 'view less' : 'view more'}
                </Link>
            )}
        </Box>
    );
}

ExpandableCell.propTypes = {
    value: PropTypes.any,
};
export default function ValueParserSetterGrid() {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    // Datagrid Pagination
    if (!localStorage.getItem("quote_history_datagrid_pagesize")) {
        localStorage.setItem("quote_history_datagrid_pagesize", 10);
    }
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0,
        pageSize: localStorage.getItem("quote_history_datagrid_pagesize")
    });
    const [dataLoading, setDataLoading] = useState(true);

    //Datagrid Sort
    const [prevSort, setPrevSort] = useState("");
    const [queryOptionsSort, setQueryOptionsSort] = useState({});
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSort({sortModel: [...sortModel]});
    }, []);

    const [queryOptionsFilter, setQueryOptionsFilter] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setQueryOptionsFilter({filterModel: {...filterModel.items}});
    }, []);
    const [prevFilter, setPrevFilter] = useState("");

    const default_columns = () => [
        {field: 'id', headerName: 'Quote #'},
        {field: 'requesting_user_full_name', headerName: 'Requesting User Name', flex: 1},
        {field: 'applicant_full_name', headerName: 'Applicant Name', flex: 1},
        {
            field: 'applicant_address',
            headerName: 'Applicant Address',
            flex: 1,
            renderCell: (params) => <ExpandableCell {...params} />
        },
        {field: 'applicant_cell_phone', headerName: 'Applicant Phone', flex: 1},
        {
            field: 'selected_raters',
            headerName: 'Selected Raters',
            flex: 1,
            renderCell: (params) => <ExpandableCell {...params} />
        },
        {field: 'request_date', headerName: 'Request Date', flex: 1},
        {field: 'quotes', headerName: 'Quotes', width: 400, renderCell: RenderQuotes}
    ];


    const setRowData = async () => {
        setDataLoading(true);
        let filter = ""
        if (queryOptionsFilter.filterModel) {
            console.log("filterModel[0]", queryOptionsFilter.filterModel[0])
            if (queryOptionsFilter.filterModel[0] === undefined) {
                filter = prevFilter
            } else {
                filter = queryOptionsFilter.filterModel[0].field + "-" +
                    queryOptionsFilter.filterModel[0].operator + "-" +
                    queryOptionsFilter.filterModel[0].value
                setPrevFilter(filter)
            }

        }

        let sort = ""
        if (queryOptionsSort.sortModel) {
            if (queryOptionsSort.sortModel.length > 0) {
                if (prevSort) {
                    sort = prevSort
                }
                let sortModel = queryOptionsSort.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSort !== sort) {
                    setPrevSort(sort)
                } else {
                    if (queryOptionsSort.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        console.log("filter: ", filter)
        console.log("sort: ", sort)

        const insuranceHistoryList = await get_insurance_quote_history_pagination(controller.page, controller.pageSize, sort, filter);
        console.log("insuranceHistoryList", insuranceHistoryList)
        setRowCount(insuranceHistoryList.total);
        setRows(default_rows(insuranceHistoryList.data));
        setTableLoading(false);
        setDataLoading(false);

    }


    useEffect(async () => {
        setColumns(default_columns);
        setRowData();
    }, [controller, queryOptionsSort, queryOptionsFilter]);


    return (

        <>
            <CusNavBar/>
            <InsuranceAlert isPopup={false}/>
            <Header text="Insurance Quote History"/>
            <Box style={{height: "70vh", width: '100%'}}>
                {!tableLoading ?
                    <DataGrid
                        rows={rows}
                        rowCount={rowCount}
                        columns={columns}
                        getRowHeight={() => 'auto'}
                        getEstimatedRowHeight={() => 100}
                        paginationMode="server"
                        paginationModel={controller}
                        pageSizeOptions={[10, 25, 50]}
                        onPaginationModelChange={setController}
                        loading={dataLoading}
                        filterMode="server"
                        onFilterModelChange={onFilterChange}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                'margin-top': '1em',
                                'margin-bottom': '1em'
                            },
                            '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                'margin-top': '1em',
                                'margin-bottom': '1em'
                            }
                        }}
                    />
                    :
                    <APTableLoading/>}
            </Box>
            <br/>

            <Footer/>
        </>
    );
}