import React, {useState} from "react";
import {Modal, Button, Spinner, Form} from "react-bootstrap";
import '../../styles/login.css';
import * as Yup from "yup";
import {useFormik} from "formik";
import PropTypes from "prop-types";
import {API_URL} from "../../constants";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomTextInput from "../../global_components/inputs/text";
import CustomAmountInput from "../../global_components/inputs/amount";


export default function VermostReserveBondNumberPopup(props) {
    const [loading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [bondReservedSuccessMessage, setBondReservedSuccessMessage] = useState('');
    const [showFailureMessage, setShowFailureMessage] = useState(false);

    const token = localStorage.getItem('userdata');
    const formIk = useFormik({
        initialValues: {bond_type: 'Reserve', bond_sub_category: '', bond_amount: '', deceased_legal_name: ''},
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            console.log(values);
            fetch(`${API_URL}/bond/create_reserved_bond/`, {
                method: "POST",
                body: JSON.stringify({
                    ...values
                }),
                headers: {
                    'Authorization': 'Bearer '+token,
                    "Content-Type": "application/json",
                },
            }).then(async (res)=>{
                if(res.status === 201){
                    const res_obj = await res.json();
                    resetForm({values: ''});
                    setBondReservedSuccessMessage(res_obj['msg'] + '. After 5 seconds it will open to the home page.')
                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                        props.closePopup(false);
                        }, 5000);
                }else{
                    setShowFailureMessage(true);
                    setTimeout(() => setShowFailureMessage(false), 5000);
                }
                setLoading(false);
            }).catch((error)=>{
                setShowFailureMessage(true);
                console.log(error);
                setTimeout(() => setShowFailureMessage(false), 5000);
                setLoading(false);
            })

        },
        validationSchema:Yup.object({
            bond_type: Yup.string().required('Bond type is required.'),
            bond_sub_category: Yup.string().required('Bond sub category is required.'),
            bond_amount: Yup.number().typeError('Bond amount must be greater than 0.').min(0,'Bond amount must be greater than 0.').required('Bond amount is required.'),
            deceased_legal_name: Yup.string().required('Deceased legal name is required.')
        })
    });

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please provide bond information.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group mb-2" key="current_password">
                    <Form.Group key="bond_type" className="col-6" controlId="formBasicEmail"
                                style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                        <Form.Label className='required-label'><strong>Bond Type</strong></Form.Label>
                        <CustomSelectInput disabled={false} name="bond_type" value={formIk.values.bond_type}
                                           onChange={(value)=>{
                                               formIk.setFieldValue("bond_type", value);
                                           }} options={[{label: "Reserve", value: "Reserve"}]}/>
                        {formIk.errors["bond_type"] && (
                            <div className="text-error">{formIk.errors["bond_type"]}</div>
                        )}
                    </Form.Group>
                    <Form.Group key="bond_sub_category" className="col-6" controlId="formBasicEmail"
                                style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                        <Form.Label className='required-label'><strong>Bond Sub Category</strong></Form.Label>
                        <CustomSelectInput disabled={false} name="bond_sub_category"
                                           value={formIk.values.bond_sub_category}
                                           onChange={(value)=>{
                                               formIk.setFieldValue('bond_sub_category', value);
                                           }}
                                           options={[{label: 'Blanket Bond', value: 'Blanket Bond'},
                                               {label: 'Guardian', value: 'Guardian'}, {label: 'VA', value: 'VA'},
                                               {label: 'Lost Instrument', value: 'Lost Instrument'},
                                               {label: 'Minor Guardian', value: 'Minor Guardian'},
                                               {label: 'Other', value: 'Other'}, {label: 'Receiver', value: 'Receiver'},
                                               {label: 'Trustee', value: 'Trustee'}]}/>
                        {formIk.errors["bond_sub_category"] && formIk.touched["bond_sub_category"] && (
                            <div className="text-error">{formIk.errors["bond_sub_category"]}</div>
                        )}
                    </Form.Group>
                    <Form.Group key="bond_amount" className="col-6" controlId="formBasicEmail"
                                style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                        <Form.Label className='required-label'><strong>Bond Amount</strong></Form.Label>
                        <CustomAmountInput disabled={false} required={true} name="bond_amount"
                                           className={"form-control"} id="bond_amount-input" decimalScale={0}
                                           value={formIk.values.bond_amount} onChange={(value)=>{
                                               formIk.setFieldValue("bond_amount", value);
                                           }}
                        />
                        {formIk.errors["bond_amount"] && (
                            <div className="text-error">{formIk.errors["bond_amount"]}</div>
                        )}
                    </Form.Group>
                    <Form.Group key="deceased_legal_name" className="col-6" controlId="formBasicEmail"
                                style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                        <Form.Label className='required-label'><strong>Principal</strong></Form.Label>
                        <CustomTextInput disabled={false} required={true} name="deceased_legal_name"
                                         className={"form-control"} id="deceased_legal_name-input"
                                         value={formIk.values.deceased_legal_name} onBlur={formIk.handleBlur}
                                         onChange={(value)=>{ formIk.setFieldValue("deceased_legal_name", value);}}/>
                        {formIk.errors["deceased_legal_name"] && formIk.touched["deceased_legal_name"] && (
                            <div className="text-error">{formIk.errors["deceased_legal_name"]}</div>
                        )}
                    </Form.Group>
                </div>
                {
                    showSuccessMessage && <p className='text-primary px-3'>{bondReservedSuccessMessage}</p>
                }
                {
                    showFailureMessage && <p className='text-danger px-3'>Something went wrong.</p>
                }
                {
                    !loading && (
                        <div style={{textAlign: "right"}}>
                            <button className="btn btn-success" onClick={() => formIk.submitForm()}>Reserve</button>
                        </div>
                    )
                }
                {
                    loading && (
                        <div style={{textAlign: "right"}}>
                            <Button disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Reserving bond number...
                            </Button>
                        </div>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(false);
                        }}>Close Window</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
}

VermostReserveBondNumberPopup.propTypes = {
    closePopup: PropTypes.func.isRequired,
}
