import '../styles/login.css';
import '../App.css';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CusNavBar from "./navbar";
import Footer from "./footer";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Form} from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';
import {API_URL, insurance_companies} from "../constants";
import { updateNames } from "../store/auth";
import ResetPasswordPopup from "../components/popups/reset_password_popup";
import InsuranceCompanyCredsPopup from '../components/popups/insurance_company_creds_popup';
import CusAlert from "./alert";
import Header from "./header";
import {useNavigate} from "react-router";
import {useSnackbar} from "notistack";


export default function ProfilePage(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('userdata');
    const [loading, setLoading] = useState(false);
    const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
    const [showInsuranceCompanyCredsPopup, setShowInsuranceCompanyCredsPopup] = useState(false);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [successPopUpMessage, setSuccessPopUpMessage] = useState('');
    const [showFailPopUp, setShowFailPopUp] = useState(false);
    const [failPopUpMessage, setFailPopUpMessage] = useState('');
    const company = useSelector((state)=>state.auth.company.name);
    const firstName = useSelector((state) => state.auth.user.first_name);
    const lastName = useSelector((state) => state.auth.user.last_name);
    const email = useSelector((state) => state.auth.user.email);
    const department = useSelector((state) => state.auth.user.primary_department);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
    });

    const formIk = useFormik({
        initialValues: {first_name:firstName, last_name: lastName},
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            await fetch(`${API_URL}/user/users/update_user_profile/`, {
                method: "PATCH",
                body: JSON.stringify(values),
                headers: {
                    'Authorization': 'Bearer '+token,
                    "Content-Type": "application/json",
                },
            }).then((response)=>{
                if (response.status === 200){
                    enqueueSnackbar('Profile info updated successfully.', { variant:"success" });
                    dispatch(updateNames(values));
                }else{
                    enqueueSnackbar('Profile info update failed.', { variant:"error" });
                }

            }).catch((error)=>{
                console.log(error);
                enqueueSnackbar('Profile info update failed.', { variant:"error" });
            });
            setLoading(false);
        },
        validationSchema:Yup.object({
            first_name: Yup.string().required('First name is required.'),
            last_name: Yup.string().required('Last name is required.')
        })
    });

    const updatePasswordCallBack = (status) => {
      if (!status){
          enqueueSnackbar('Profile info updated successfully.', { variant:"success" });
      }else{
          enqueueSnackbar('Profile info update failed.', { variant:"error" });
      }
    }

    const updateCompanyCredsCallBack = (status) => {
      if (status === 200){
          enqueueSnackbar('Company Credentials updated successfully., { variant:"success" }');
      }else{
          enqueueSnackbar('Company Credentials update failed.', { variant:"error" });
      }
    }

    return(
        <>
            <CusNavBar />
            <LoadingOverlay active={loading} spinner text='Updating Invoice...'>
                <Header text="Profile Details"/>
                {
                    showSuccessPopUp && <CusAlert alertType='success' alertMessage={successPopUpMessage}
                                                  alertClose={setShowSuccessPopUp} />
                }
                {
                    showFailPopUp && <CusAlert alertType='danger' alertMessage={failPopUpMessage}
                                               alertClose={setShowFailPopUp} />
                }
                <Form onSubmit={formIk.handleSubmit}>
                    <div className="form-group mb-2 col-4" key="email">
                        <label htmlFor="email"><b>Email</b></label>
                        <input disabled={true} type="text" className="form-control" name="email" id="email_input"
                               defaultValue={email}/>
                    </div>
                    <div className="form-group mb-2 col-4" key="company">
                        <label htmlFor="company"><b>Company</b></label>
                        <input disabled={true} type="text" className="form-control" name="company" id="company_input"
                               defaultValue={company}/>
                    </div>
                    <div className="form-group mb-2 col-4" key="department">
                        <label htmlFor="department"><b>Department</b></label>
                        <input disabled={true} type="text" className="form-control" name="department"
                               id="department_input" defaultValue={department}/>
                    </div>
                    <div className="form-group mb-2 col-4" key="first_name">
                        <label htmlFor="first_name" className="required-label"><b>First Name</b></label>
                        <input type="text" className="form-control" name="first_name"
                               id="first_name_input" defaultValue={firstName} onBlur={formIk.handleBlur}
                               onChange={formIk.handleChange}/>
                        {formIk.errors['first_name'] && formIk.touched['first_name'] && (
                            <div className="text-error">{formIk.errors['first_name']}</div>
                        )}
                    </div>
                    <div className="form-group mb-2 col-4" key="last_name">
                        <label htmlFor="last_name" className="required-label"><b>Last Name</b></label>
                        <input type="text" className="form-control" name="last_name"
                               id="last_name_input" defaultValue={lastName} onBlur={formIk.handleBlur}
                               onChange={formIk.handleChange}/>
                        {formIk.errors['last_name'] && formIk.touched['last_name'] && (
                            <div className="text-error">{formIk.errors['last_name']}</div>
                        )}
                    </div>
                    <button style={{marginTop:"20px"}} disabled={loading} onClick={() => formIk.submitForm()}
                            className="btn btn-success">Update</button>
                    <button style={{marginTop:"20px", marginLeft:"15px"}} disabled={loading}
                            onClick={(event) => {
                                event.preventDefault();
                                setShowResetPasswordPopup(true)
                            }} className="btn btn-primary">Reset Password</button>
                    {/* Check If the Current Company is Portal Insurance */}
                    {insurance_companies.includes(company) &&
                        <button style={{marginTop:"20px", marginLeft:"15px"}} disabled={loading}
                        onClick={(event) => {
                            event.preventDefault();
                            setShowInsuranceCompanyCredsPopup(true)
                        }} className="btn btn-warning">Vendor Credentials</button>
                    }
                </Form>
                {
                    showResetPasswordPopup && <ResetPasswordPopup show={true} closePopup={setShowResetPasswordPopup}
                                                                  callback={updatePasswordCallBack} />
                }
                {
                    showInsuranceCompanyCredsPopup && <InsuranceCompanyCredsPopup show={true} closePopup={setShowInsuranceCompanyCredsPopup}
                                                                  callback={updateCompanyCredsCallBack} />
                }
            </LoadingOverlay>
            <Footer />
        </>
    )
}
