import React, {useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Row, Button, Spinner, Form} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {API_URL} from "../../constants";
import {useFormik} from 'formik';
import CustomTextInput from '../../global_components/inputs/text';
import CustomPasswordInput from '../../global_components/inputs/password';
import {updatePortalInsuranceVendorCreds} from '../../store/auth';


export default function InsurancePortalVendorCredsForm(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showInValidMessage, setShowInValidMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);


    const getValidation = () => {
        const validation = {};
        validation['username'] = Yup.string().min(2, 'Length Too Short!').max(50, 'Length Too Long!').required('Username is Required');
        validation['password'] = Yup.string().min(2, 'Length Too Short!').max(50, 'Length Too Long!').required('Password is required.');

        return validation;
    }

    const getInitialValues = () => {

        const initialValues = {};
        initialValues['vendor'] = props.vendor ? props.vendor : '';
        initialValues['username'] = props.username ? props.username : '';
        initialValues['password'] = props.password ? props.password : '';
        initialValues['is_active'] = props.is_active === '' || props.is_active === undefined ? false : props.is_active;

        return initialValues;
    }

    const token = localStorage.getItem('userdata');
    const formik = useFormik({
        initialValues: getInitialValues(),

        onSubmit: async (values, {setSubmitting}) => {


            setLoading(true);
            await fetch(`${API_URL}/portal_insurance/update_portal_insurance_vendor_creds/`, {
                method: "PATCH",
                body: JSON.stringify(values),
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (response.status === 200) {
                    setShowSuccessMessage(true);
                    dispatch(updatePortalInsuranceVendorCreds({
                        vendor: props.vendor,
                        username: values.username,
                        password: values.password,
                        is_active: values.is_active
                    }))
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                } else if (response.status === 403) {
                    setShowInValidMessage(true);
                    setTimeout(() => setShowInValidMessage(false), 5000);
                } else {
                    setShowFailureMessage(true);
                    setTimeout(() => setShowFailureMessage(false), 5000);
                }
            }).catch((error) => {
                console.log(error);
                setShowFailureMessage(true);
                setTimeout(() => setShowFailureMessage(false), 5000);
            });
            setLoading(false);

        },
        validationSchema: Yup.object(getValidation())
    });


    return (
        <div>
            <Form className="">
                <div>
                    <Row style={{marginBottom: "2%"}}>
                        <h4>Portal Insurance ({props.vendor_name})</h4>
                    </Row>
                    <Row>
                        <Form.Group key="username" controlId="formBasicUsername" className="form-group mb-2 px-4"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className='required-label'><strong>Username</strong></Form.Label>
                            <CustomTextInput disabled={false} required={true} name="username"
                                             className={"form-control"} id="username-input"
                                             onChange={(value) => {
                                                 formik.setFieldValue('username', value);
                                             }}
                                             value={formik.values.username}
                            />
                            {formik.errors['username'] && formik.touched['username'] && (
                                <div className="text-error">{formik.errors['username']}</div>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group key="password" controlId="formBasicPassword" className="form-group mb-2 px-4">
                            <Form.Label className='required-label'><strong>Password</strong></Form.Label>
                            <CustomPasswordInput disabled={false} required={true} name="password"
                                                 className={"form-control"} id="password-input"
                                                 onChange={(value) => {
                                                     formik.setFieldValue('password', value);
                                                 }}
                                                 value={formik.values.password}
                            />
                            {formik.errors['password'] && formik.touched['password'] && (
                                <div className="text-error">{formik.errors['password']}</div>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group key="is_active" controlId="formBasicIsActive" className="form-group mb-2 px-4">
                            <Form.Check
                                name="is_active"
                                id="is_active-input"
                                label={<strong>Is Active?</strong>}
                                checked={formik.values.is_active}
                                onChange={(value) => {
                                    formik.setFieldValue('is_active', !formik.values.is_active);
                                }}
                            />

                        </Form.Group>
                    </Row>

                </div>
                {
                    showSuccessMessage && <p className='text-primary px-3'>Credential updated successfully!!!</p>
                }
                {
                    showInValidMessage &&
                    <p className='text-danger px-3'>User Company is not Portal Insurance. Credential couldn't be
                        update!!!</p>
                }
                {
                    showFailureMessage &&
                    <p className='text-danger px-3'>Something went wrong. Credential couldn't be update!!!</p>
                }
                {
                    !loading && (
                        <div className="px-3" style={{textAlign: "left", marginTop: "2%"}}>
                            <button className="btn btn-success px-4" onClick={(e) => {
                                console.log(formik.errors);
                                console.log(formik.values);
                                e.preventDefault();
                                // formik.handleSubmit();
                                formik.submitForm();
                            }} disabled={loading}>Save
                            </button>
                        </div>
                    )
                }
                {
                    loading && (
                        <Button disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
            </Form>
        </div>
    )
}

InsurancePortalVendorCredsForm.propTypes = {
    closePopup: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
}
