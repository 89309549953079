import React, {useEffect, useState, Fragment, useCallback} from 'react';
import {
    getLawFirmColumns, deleteLawFirmBackend, getVermostLawFirms
} from "../../../../../services/company_management";
import {useDispatch, useSelector} from "react-redux";
import CusNavBar from "../../../../../global_components/navbar";
import Footer from "../../../../../global_components/footer";
import LoadingOverlay from 'react-loading-overlay';
import CustomDataGrid from "../../../../../global_components/datatables/custom_datagrid";
import Header from "../../../../../global_components/header";
import {useNavigate} from "react-router";
import {hasUserPermission} from "../../../../../utils/user_permission";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {log} from "../../../../../utils/logger";
import {exportToExcel} from "../../../../../utils/excel_export"
import {useSnackbar} from "notistack";
import ActiveAccountManagementPopup from "../../../../../components/popups/vermost/account_management_active_popup";
import GoBack from "../../../../../utils/go_back_button";

function LawFirmList(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [lawFirmLoading, setLawFirmLoading] = useState(true);
    const [firstLawFirmLoading, setFirstLawFirmLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [lawFirmToEdit, setLawFirmToEdit] = useState(0);
    const [deleteId, setDeleteId] = useState(0);
    const [deleteText, setDeleteText] = useState('');
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');
    const hasAddPermission = hasUserPermission(user_permission, 'show_table_add_button')
    const [pendingApproval, setPendingApproval] = useState(false);
    const super_user = useSelector((state) => state.auth.user.user_role_name) === "SuperAdmin";

    const [lawFirmJsonData, setLawFirmJsonData] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    // Datagrid Pagination
    if (!localStorage.getItem("lawfirm_list_pagesize")) {
        localStorage.setItem("lawfirm_list_pagesize", 10);
    }
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("lawfirm_list_pagesize"))
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);
    // Datagrid Filter
    const [queryOptions, setQueryOptions] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptions({filterModel: {...filterModel.items}}), 1000);
    }, []);
    const [prevFilter, setPrevFilter] = useState("");
    //Datagrid Sort
    const [queryOptionsSort, setQueryOptionsSort] = useState({});
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSort({sortModel: [...sortModel]});
    }, []);
    const [prevSort, setPrevSort] = useState("");

    const deleteLawFirm = async (lawFirmId, showPopup) => {
        if (showPopup) {
            setDeleteText('Law Firm');
            setDeleteId(lawFirmId);
            return 0;
        }
        setLoadingMessage('Changing Law Firm Active field.....');
        setLoading(true);
        let response = await deleteLawFirmBackend(lawFirmId);
        if (response.status === 200) {
            enqueueSnackbar("Law Firm's active field changed successfully.", {variant: "success"});
            setPendingApproval(false);
            await refreshLawFirm(false);
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
        setLoading(false);
    }
    const updateLawFirm = (lawFirmId) => {
        setLawFirmToEdit(lawFirmId);
    }
    const lawFirmColumns = getLawFirmColumns(deleteLawFirm, updateLawFirm, hasUpdatePermission, hasDeletePermission);

    const refreshLawFirm = async (is_refresh) => {
        setLawFirmLoading(true);
        let filter = getFilterAndSort();
        let resp = await getVermostLawFirms(controller.page, controller.pageSize, filter[0], filter[1], false);
        if (resp.status === 200) {
            const result = await resp.json();
            setRowCount(result.total);
            setLawFirmJsonData(result.data);
            result.data.forEach((item) => {
                if (item.is_new && !item.is_active) {
                    setPendingApproval(true);
                }
            })
            setLawFirmLoading(false);
            if (is_refresh)
                enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
            return result.data;
        } else {
            const result = await resp.json();
            enqueueSnackbar(result, {variant: "error"});
            return 0;
        }
    }

    const onCallbackFun = async (type, obj) => {
        if (type === 'insert') {
            navigate("/law_firm/0")
        } else if (type === 'edit') {
            navigate(`/law_firm/${obj.id}`)
        } else if (type === 'delete') {
            deleteLawFirm(obj.id, true);
        } else if (type === 'refresh') {
            await refreshLawFirm(true);
        } else if (type === "download") {
            await exportLawFirmData();
        }
    }

    const exportLawFirmData = async () =>{
        const lastFilter = localStorage.getItem('storedFilter')?.split(',');
        let excelData = await getVermostLawFirms('', '', lastFilter[0], lastFilter[1], false);
        if (excelData.status === 200) {
            const result = await excelData.json();
            exportToExcel(result.data, "Law Firm");
        } else {
            const result = await excelData.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }

    const getFilterAndSort = () => {
        let filter = ""
        if (queryOptions.filterModel) {
            if (queryOptions.filterModel[0] !== undefined) {
                filter = queryOptions.filterModel[0].field + "-" + queryOptions.filterModel[0].operator + "-" + queryOptions.filterModel[0].value
                setPrevFilter(filter)
            } else {
                filter = prevFilter
            }
        }
        let sort = ""
        if (queryOptionsSort.sortModel) {
            if (queryOptionsSort.sortModel.length > 0) {
                if (prevSort) {
                    sort = prevSort
                }
                let sortModel = queryOptionsSort.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSort !== sort) {
                    setPrevSort(sort)
                } else {
                    if (queryOptions.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        localStorage.setItem('storedFilter', [filter, sort]);
        return [filter, sort]
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        log("user_permission,", user_permission);
        document.title = "Law Firms";
        // await refreshLawFirm(false);

        if (lawFirmLoading || firstLawFirmLoading) {
            await refreshLawFirm(false);
        } else {
            setLawFirmLoading(false);
        }
    }, [controller, queryOptions, queryOptionsSort]);

    return (
        <> <LoadingOverlay active={loading} spinner text={loadingMessage}>
            <CusNavBar/>
            <GoBack pageUrl="/account_management"></GoBack>
            <Header text="Law Firms"
                    warning={super_user && pendingApproval ? "You can approve pending law firms by setting their status to active." : ""}/>
            {
                deleteId !== 0 && <ActiveAccountManagementPopup show={true} closePopup={setDeleteId}
                                                                delete_callback={deleteLawFirm}
                                                                delete_id={deleteId} delete_text={deleteText}/>
            }
            <div className="col-12" style={{margin: "auto"}}>
                <CustomDataGrid rows={lawFirmJsonData}
                                columns={lawFirmColumns}
                                rowCount={rowCount}
                                paginationModel={controller}
                                onPaginationModelChange={gatedSetController}
                                onFilterModelChange={onFilterChange}
                                onSortModelChange={handleSortModelChange}
                                loading={lawFirmLoading}
                                isActionsVisible={true}
                                isEdit={true}
                                onCallback={onCallbackFun}
                                disableRowSelectionOnClick={true}
                                addPermission={super_user ? hasAddPermission : false}
                                deletePermission={hasDeletePermission}
                                updatePermission={hasUpdatePermission}
                                visibleActionButtons={true}
                                userApproveNotificationDisable={true}
                                columnVisibilityModel={{
                                    is_admin: false
                                }}
                />
            </div>
        </LoadingOverlay>
            <Footer/>
        </>
    )
}


export default LawFirmList;
