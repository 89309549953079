import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    documents: []
};

const documentApprovalSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        addDocuments(state, action){
            state.documents = action.payload;
        },
        removeDocuments(state, action){
            state.documents = [];
        },
        removeDocument(state, action){
            state.documents = state.documents.filter((document)=> document.id !== action.payload.id);
        },
        updateDocumentComments(state, action){
            state.documents = state.documents.map((document)=> {
                if(document.id === action.payload.id){
                    document.comments = action.payload.comments;
                }
                return document;
            });
        }
    }
});

export const  { addDocuments, removeDocuments, removeDocument, updateDocumentComments } = documentApprovalSlice.actions;

export default documentApprovalSlice.reducer;
