import {Alert, Button} from 'react-bootstrap';
import PropTypes from "prop-types";
import {useNavigate} from "react-router";

export default function CusAlert(props) {
    const navigate = useNavigate();

    return (
        <Alert variant={props.alertType}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                {props.alertMessage}
            </div>
            <div className="d-flex justify-content-end">
                {props.actionButton &&
                    <Button onClick={() => navigate(props.goPage)} variant={'primary'} >
                        Go Page
                    </Button>
                }&nbsp;
                <Button onClick={() => props.alertClose(false)} color={'red'} variant={"outline-" + props.alertType}>
                    Close
                </Button>
            </div>
        </Alert>
    )
}

CusAlert.propTypes = {
    alertType: PropTypes.string.isRequired,
    alertMessage: PropTypes.string.isRequired,
    alertClose: PropTypes.func.isRequired,
    actionButton: PropTypes.bool,
    goPage: PropTypes.string

};
