import React, {useEffect, useState} from 'react';
import '../styles/login.css';
import '../App.css';
import {useNavigate} from "react-router";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {
    addBondProcessDetails, addBondProcessInputs, mergeBondProcessHistory, removeBondProcessHistory,
    updateBondProcessHistory,
    updateBondStatus
} from '../store/bond';
import LoadingOverlay from 'react-loading-overlay';
import {Col, Form, Row} from "react-bootstrap";
import * as Yup from "yup";
import {useFormik} from "formik";
import CustomAmountInput from "./inputs/amount";
import CustomSelectInput from "./inputs/select-field";
import CustomDatePicker from "./custom_date_picker";
import {formatDate, formatDateMDY, formatDateUTCMDY, formatUTCDate} from "../services/helper";
import CustomLongInput from "./inputs/long-text";
import CustomTextInput from "./inputs/text";
import CustomNumberInput from "./inputs/number";
import {API_URL} from "../constants";
import ZipCode from "./inputs/zip_code";
import PatternFormat from "react-number-format";
import USPhoneNumberWithoutCountryCodeUniversal from "./inputs/us_phone_number_without_country_code";
import GoogleAutoComplete from "./inputs/google-auto-complete";
import fileDownload from 'js-file-download'
import axios from "axios";
import {getProcesses, getProcessInputs} from "../services/process";
import {getBondList} from "../services/bond";
import DateNormalInput from "./inputs/date-normal-input";
import {hasUserPermission} from "../utils/user_permission";
import { addLawFirm } from '../store/bond';
import { useSnackbar } from 'notistack';

function BondEditForm(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [isGuest, setIsGuest] = useState(false);
    const [guestLawFirm, setGuestLawFirm] = useState('');
    const [guestLawFirmAddress, setGuestLawFirmAddress] = useState('');
    const [guestLawFirmContact, setGuestLawFirmContact] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('Getting Bonds ...');
    const bondBeforeEdit = useSelector((state) => {
        let bondList = state.bond.bond_history.filter((bond) => bond.id === props.bond_id)[0];
        console.log("state.bond.bond_history,", state.bond.bond_history);
        console.log("bondList,", bondList);
        return bondList;
    });

    console.log("bondBeforeEdit test", bondBeforeEdit);

    if(bondBeforeEdit){
        localStorage.setItem("bondToEdit", JSON.stringify(bondBeforeEdit));
        console.log("saved to local storage");
    }

    const bondToEdit = JSON.parse(localStorage.getItem("bondToEdit"));

  // State to store the customer_number from the API response
  const [locationCustomerNumber, setLocationCustomerNumber] = useState(null);

  useEffect(() => {
    // Destructure the address from the law_firm_address object
    const { law_firm_address: { address, law_firm } } = bondToEdit;

    // Log the address for debugging
    console.log("Address being used for API call:", address);

    // Define an async function to call the API using fetch
    const fetchCustomerNumber = async () => {
      try {
        // Make the API call to fetch customer_number
        const response = await fetch(`${API_URL}/bond/get-customer-number/`, {
          method: 'POST', // Use POST method
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('userdata'),
            'Content-Type': 'application/json', // Send request as JSON
          },
          body: JSON.stringify({
            address: address, // Send address as part of the request body
            law_firm_id: law_firm
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch customer number');
        }

        // Parse the response as JSON
        const data = await response.json();

        // Assuming the API returns the customer number as { customer_number: number }
        setLocationCustomerNumber(data.customer_number);
      } catch (error) {
        console.error('Error fetching customer number:', error);
      }
    };

    // Only call the API if the address exists
    if (address) {
      fetchCustomerNumber();
    }
  }, [bondToEdit]);

  console.log("Location Customer Number", locationCustomerNumber)



    const [premiumVal, setPremiumVal] = useState(bondToEdit['premium']);
    const processInput = useSelector((state) => state.bond.bond_process_input);
    console.log("process input", processInput);
    const bond_sub_category_input = processInput.filter((input) => input.name === "bond_sub_category")[0];
    const [bondSubCategoryOptions, setBondSubCategoryOptions] = useState((bond_sub_category_input === null || bond_sub_category_input === undefined) ? [] : [...bond_sub_category_input['options'].filter((opt) => opt.filter_values.includes(bondToEdit["bond_type"]))]);
    const [personalRepresentativeInputs, setPersonalRepresentativeInputs] = useState([]);
    const lawFirms = useSelector((state) => state.bond.law_firms);

    console.log("law firms", lawFirms);

    const lawFirmAddresses = useSelector((state) => state.bond.law_firm_addresses);
    const lawFirmContacts = useSelector((state) => state.bond.law_firm_contacts);

    console.log("law firm addresses", lawFirmAddresses);
    console.log("law firm contact", lawFirmContacts);

    const user_id = useSelector((state) => state.auth.user.user_id);
    const normalInputs = processInput.filter((input) => input.is_line_item === false);
    const lineItemInputs = processInput.filter((input) => input.is_line_item === true);
    console.log("lineItem input", lineItemInputs);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const user_email = useSelector((state) => state.auth.user.email);
    const [isUpdateRequired, setIsUpdateRequired] = useState(false);
    const [viewID, setViewID] = useState(0);

    let has_history = false;

    if (bondToEdit['history'] !== null && bondToEdit['history'] !== undefined) {
        has_history = true;
    }



    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        document.title = "Bond List";
        if (bondToEdit.id !== viewID) {
            setIsUpdateRequired(false);
            setViewID(bondToEdit.id);
        }

        console.log("bondToEdit, ", bondToEdit)
    }, [bondToEdit]);

    useEffect(async() => {
        if(lawFirms.length === 0){
            fetch(`${API_URL}/bond/list_all_law_firms/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async (res)=> {
                const law_firm_data = await res.json();
                dispatch(addLawFirm(law_firm_data));
            }).catch((err)=>{
                console.log(err);
            });
        }
    },[])

    const getValidation = () => {
        const validation = {};
        const previous = new Date((new Date()).getTime());
        previous.setDate(previous.getDate() - 1);
        validation['bond_type'] = Yup.string().required(`Bond Type is required`);
        validation['bond_sub_category'] = Yup.string().required(`Bond Sub Category is required`);
        validation['deceased_legal_first_name'] = Yup.string().required(`Deceased Legal First Name is required`);
        validation['deceased_legal_middle_name'] = Yup.string();
        validation['deceased_legal_last_name'] = Yup.string().required(`Deceased Legal Last Name is required`);
        validation['deceased_legal_suffix'] = Yup.string();
        //validation['deceased_legal_name'] = Yup.string().required(`Deceased Legal Name is required`);
        validation['alias_deceased_legal_name'] = Yup.string();
        validation['date_of_death'] = Yup.string().required(`Date Of Death is required`);
        validation['court_case_number'] = Yup.string();
        validation['has_judge_order'] = Yup.string();
        validation['county'] = Yup.string().required(`County is required`);
        validation['state'] = Yup.string().required(`State is required`);
        validation['bond_amount'] = Yup.number().required(`Bond amount Category is required.`);
        validation['premium'] = Yup.number().required(`Premium amount is required`);
        validation['number_of_personal_representative'] = Yup.number().required("Number of Personal Representative should be between 1-4.").min(1, "Number of Personal Representative should be between 1-4.").max(4, "Number of Personal Representative should be between 1-4.");
        validation['estimated_estate_assets'] = Yup.number().nullable();
        validation['estate_has_ongoing_business'] = Yup.string().required(`Estate Has Ongoing Business is required`);
        validation['type_of_business_in_operation'] = Yup.string();
        validation['personal_representative_indebited_to_estate'] = Yup.string().required('Personal Representative Indebted To Estate is required.');
        validation['decedent_execute_last_will_and_testament'] = Yup.string().required(`Decedent Execute Last Will And Testament is required.`);
        validation['applicant_replace_any_previous_fiduciary_in_this_estate'] = Yup.string().required(`Applicant Replace Any Previous Fiduciary In This Estate is required.`);
        validation['previous_fiduciaries'] = Yup.string();
        validation['declined_by_another'] = Yup.string().required(`Declined by another court is required`);
        validation['ever_bankrupt'] = Yup.string().required(`Bankruptcy status is required`);
        validation['replace_previous_bond'] = Yup.string().required(`Bond replacing status is required`);
        validation['lawsuits_judgment_liens'] = Yup.string().required(`Lawsuits judgment liens is required`);
        validation['any_disputes'] = Yup.string().required(`Dispute with other court status is required`);
        validation['is_required_on_demand'] = Yup.string().required('Is Required On Demand is mandatory field.');
        validation['interested_parties'] = Yup.string();
        validation['e_signed_indemnity'] = Yup.string();
        validation['law_firm'] = Yup.string().required('Law Firm is required.');
        validation['law_firm_address'] = Yup.string().required('Law Firm Address is required.');
        validation['contact'] = Yup.string().required('Contact is required.');
        validation['copy_of_court_order'] = Yup.mixed();
        validation['copy_of_last_will_and_testament'] = Yup.mixed();
        validation['other_document'] = Yup.mixed();
        validation['user_comment'] = Yup.string();
        validation['user_first_name'] = Yup.string().required('User First Name is required.');
        validation['user_last_name'] = Yup.string().required('User Last Name is required.');
        validation['user_email'] = Yup.string().required('User Email is required.');
        validation['phone_number'] = Yup.string().matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, 'Please provide a valid phone number.').required('User Phone Number is required.');
        validation['personal_representative'] = Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(`Name of Personal Rep is required`),
                address: Yup.string().required(`Street Address of Personal Rep is required`),
                city: Yup.string(),//.required(`City/Town for Personal Rep is required`),
                state: Yup.string(),//.required(`State/Province/Region for Personal Rep is required`),
                zip_code: Yup.string(),
                is_us_citizen: Yup.string().required(`US citizenship status of Personal Rep is required`),
                citizenship: Yup.string(),
                other_country: Yup.string(),
                ssn: Yup.string(),
                occupation: Yup.string(),
                email: Yup.string(),
            })
        ).required("Number of Personal Representative should be between 1-4.").min(1, "Number of Personal Representative should be between 1-4.");
        return validation;
    }

    const getInitialValues = (inputs) => {
        const initialValues = {};
        initialValues['bond_type'] = bondToEdit["bond_type"];
        initialValues['bond_sub_category'] = bondToEdit["bond_sub_category"];
        initialValues['deceased_legal_first_name'] = bondToEdit["deceased_legal_first_name"];
        initialValues['deceased_legal_middle_name'] = bondToEdit["deceased_legal_middle_name"];
        initialValues['deceased_legal_last_name'] = bondToEdit["deceased_legal_last_name"];
        initialValues['deceased_legal_suffix'] = bondToEdit["deceased_legal_suffix"];
        //initialValues['deceased_legal_name'] = bondToEdit["deceased_legal_name"];
        initialValues['alias_deceased_legal_name'] = bondToEdit["alias_deceased_legal_name"];
        initialValues['date_of_death'] = formatDateUTCMDY(bondToEdit["date_of_death"]);
        initialValues['court_case_number'] = bondToEdit["court_case_number"];
        initialValues['has_judge_order'] = bondToEdit["has_judge_order"] === true ? "yes" : "no";
        initialValues['state'] = bondToEdit["state"];
        initialValues['county'] = bondToEdit["county"];
        initialValues['bond_amount'] = bondToEdit["bond_amount"];
        initialValues['number_of_personal_representative'] = bondToEdit["number_of_personal_representative"];
        initialValues['premium'] = bondToEdit["premium"];
        initialValues['estimated_estate_assets'] = bondToEdit["estimated_estate_assets"];
        initialValues['estate_has_ongoing_business'] = bondToEdit["estate_has_ongoing_business"] === true ? "yes" : "no";
        initialValues['type_of_business_in_operation'] = bondToEdit["type_of_business_in_operation"];
        initialValues['personal_representative_indebited_to_estate'] = bondToEdit["personal_representative_indebited_to_estate"] === true ? "yes" : "no";
        initialValues['decedent_execute_last_will_and_testament'] = bondToEdit["decedent_execute_last_will_and_testament"] === true ? "yes" : "no";
        initialValues['applicant_replace_any_previous_fiduciary_in_this_estate'] = bondToEdit["applicant_replace_any_previous_fiduciary_in_this_estate"] === true ? "yes" : "no";
        initialValues['previous_fiduciaries'] = bondToEdit["previous_fiduciaries"];
        initialValues['declined_by_another'] = bondToEdit["declined_by_another"] === true ? "yes" : "no";
        initialValues['ever_bankrupt'] = bondToEdit["ever_bankrupt"] === true ? "yes" : "no";
        initialValues['replace_previous_bond'] = bondToEdit["replace_previous_bond"] === true ? "yes" : "no";
        initialValues['lawsuits_judgment_liens'] = bondToEdit["lawsuits_judgment_liens"] === true ? "yes" : "no";
        initialValues['any_disputes'] = bondToEdit["any_disputes"] === true ? "yes" : "no";
        // initialValues['replacing_explanation'] = bondToEdit["replacing_explanation"];
        initialValues['is_required_on_demand'] = bondToEdit["is_required_on_demand"] === true ? "yes" : "no";
        initialValues['e_signed_indemnity'] = bondToEdit["e_signed_indemnity"] === true ? "yes" : "no";
        initialValues['interested_parties'] = bondToEdit["interested_parties"];
        initialValues['law_firm'] = bondToEdit['law_firm'] ? bondToEdit['law_firm']['id'] : null;
        initialValues['law_firm_address'] = bondToEdit['law_firm_address'] ? bondToEdit['law_firm_address']['id'] : null;
        initialValues['contact'] = bondToEdit['contact'] ? bondToEdit['contact']['id'] : null;
        initialValues['copy_of_last_will_and_testament'] = bondToEdit["copy_of_last_will_and_testament"];
        initialValues['copy_of_last_will_and_testament_link'] = bondToEdit["copy_of_last_will_and_testament_link"];
        initialValues['last_will_and_testament_file_name'] = bondToEdit["last_will_and_testament_file_name"];
        initialValues['copy_of_court_order'] = bondToEdit["copy_of_court_order"];
        initialValues['copy_of_court_order_link'] = bondToEdit["copy_of_court_order_link"];
        initialValues['court_order_file_name'] = bondToEdit["court_order_file_name"];
        initialValues['other_document'] = bondToEdit["other_document"];
        initialValues['other_document_link'] = bondToEdit["other_document_link"];
        initialValues['other_document_file_name'] = bondToEdit["other_document_file_name"];


        initialValues['user_comment'] = bondToEdit["user_comment"];
        initialValues['user_first_name'] = bondToEdit["user_first_name"];
        initialValues['user_last_name'] = bondToEdit["user_last_name"];
        initialValues['user_email'] = bondToEdit["user_email"];
        initialValues['phone_number'] = bondToEdit["phone_number"];
        initialValues['personal_representative'] = [];
        bondToEdit["personal_representatives"].map((pr) => {
            let temp_pr = {}
            temp_pr["name"] = pr["name"];
            temp_pr["address"] = pr["address"];
            temp_pr["city"] = pr["city"];
            temp_pr["state"] = pr["state"];
            temp_pr["zip_code"] = pr["zip_code"];
            temp_pr["is_us_citizen"] = pr["is_us_citizen"] === true ? "yes" : "no";
            temp_pr["citizenship"] = pr["citizenship"];
            temp_pr["other_country"] = pr["other_country"];
            temp_pr["ssn"] = pr["ssn"];
            temp_pr["occupation"] = pr["occupation"];
            temp_pr["email"] = pr["email"];
            initialValues['personal_representative'].push(temp_pr);
        });
        return initialValues;
    }
    const formIk = useFormik({
        initialValues: getInitialValues(processInput),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);

            setLoadingMessage('Updating Bond...')
            let formData = new FormData();
            let data = JSON.parse(JSON.stringify(values))

            if (formIk.values.applicant_replace_any_previous_fiduciary_in_this_estate === "yes") {
                if (formIk.values.previous_fiduciaries === "" || formIk.values.previous_fiduciaries === undefined) {
                    formIk.errors['previous_fiduciaries'] = 'Previous fiduciaries explanation is required.';
                    setLoading(false);
                    return false;
                }
            }
            Object.keys(data).forEach((key) => {
                if (key === 'personal_representative') {
                    data['personal_representative'].forEach((pr, index) => {
                        Object.keys(pr).forEach((prKey) => {
                            if (data['personal_representative'][index][prKey] === 'yes') {
                                data['personal_representative'][index][prKey] = true;
                            } else if (data['personal_representative'][index][prKey] === 'no') {
                                data['personal_representative'][index][prKey] = false;
                            }
                        });
                    });
                } else {
                    if (data[key] === 'yes') {
                        data[key] = true;
                    } else if (data[key] === 'no') {
                        data[key] = false;
                    }
                }
            });
            if (data['estate_has_ongoing_business'] === true) {
                let error = false;
                if (data['type_of_business_in_operation'] === "") {
                    formIk.errors['type_of_business_in_operation'] = 'This field is required.';
                    error = true;
                }

                if (error === true) {
                    setLoading(false);
                    return false;
                }
            }
            if (data['bond_amount'] < 0) {
                formIk.errors['bond_amount'] = 'Bond Amount must be greater than 0.';
                setLoading(false);
                return false;
            }
            if (data['decedent_execute_last_will_and_testament'] === true && data['bond_amount'] >= 1000000) {
                if (data['copy_of_last_will_and_testament'] === "") {
                    formIk.errors['copy_of_last_will_and_testament'] = 'Please provide copy of last will and testament.';
                    setLoading(false);
                    return false;
                }
            }

            if (data['is_required_on_demand'] === true) {
                if (data['interested_parties'] === "") {
                    formIk.errors['interested_parties'] = 'Please list and briefly describe each party.';
                    setLoading(false);
                    return false;
                }
            }
            if (data['county'] === 'Pinellas') {
                const court_case_number_regex = new RegExp("^\\d{2}-\\d{6}-(ES|GD)$");
                if (data['court_case_number'] !== "" && data['court_case_number'] !== undefined) {
                    if (!court_case_number_regex.test(data['court_case_number'])) {
                        formIk.errors['court_case_number'] = 'Court Case Number for pinellas must match Year(YY) – 6_Digit_Sequence# – Court_Type (ES, GD).';
                        setLoading(false);
                        return false;
                    }
                }
                if (data['bond_type'] === 'Probate' && data['bond_amount'] < 18000) {
                    formIk.errors['bond_amount'] = 'The minimum expected value to be entered is $18000.';
                    setLoading(false);
                    return false;
                }
            } else if (data['county'] === 'Pasco') {
                if (data['has_judge_order'] === "") {
                    formIk.errors['has_judge_order'] = 'Has Judge Stearn’s issued his order requiring bond yet? for Pasco is required.';
                    setLoading(false);
                    return false;
                }
            }
            let prError = false;
            data['personal_representative'].forEach((pr, index) => {
                if (pr['address'] === "" || pr['address'] === undefined) {
                    formIk.errors['personal_representative'] = [];
                    formIk.errors['personal_representative'][index] = {};
                    formIk.errors['personal_representative'][index]['address'] = 'This field is required.';
                    setLoading(false);
                    prError = true;
                    return false;
                }

                if (pr['is_us_citizen'] === false) {
                    if (pr['citizenship'] === undefined || pr['citizenship'] === "" || !pr.hasOwnProperty('citizenship')) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['citizenship'] = 'This field is required.';
                        setLoading(false);
                        prError = true;
                        return false;
                    }
                    if (pr['citizenship'] === "Others" && (pr['other_country'] === undefined || pr['other_country'] === "" || !pr.hasOwnProperty('other_country'))) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['other_country'] = 'This field is required.';
                        setLoading(false);
                        prError = true;
                        return false;
                    }
                } else if (pr['is_us_citizen'] === true && data['bond_amount'] >= 250000) {
                    if (pr['ssn'] === undefined || pr['ssn'] === "" || !pr.hasOwnProperty('ssn')) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['ssn'] = 'This field is required.';
                        setLoading(false);
                        prError = true;
                        return false;
                    }
                }
                if (pr['is_us_citizen'] === true) {
                    if (pr['state'] === "" || pr['state'] === undefined) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['state'] = 'State/Province/Region for Personal Rep is required.';
                        setLoading(false);
                        prError = true;
                        return false;
                    }
                    if (pr['city'] === "" || pr['city'] === undefined) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['city'] = 'City/Town for Personal Rep is required.';
                        setLoading(false);
                        prError = true;
                        return false;
                    }
                    if (pr['zip_code'] === "" || pr['zip_code'] === undefined || pr['zip_code'].includes('#')) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['zip_code'] = 'Zip/Postal Code of Personal Rep is required.';
                        setLoading(false);
                        prError = true;
                        return false;
                    }
                }

            });
            if (prError === true) {
                setLoading(false);
                return false;
            }
            if (values['copy_of_last_will_and_testament'] === "" || values['copy_of_last_will_and_testament'] === null || values['copy_of_last_will_and_testament'] === undefined) {
                delete values.copy_of_last_will_and_testament;
            }
            if (values['copy_of_court_order'] === "" || values['copy_of_court_order'] === null || values['copy_of_court_order'] === undefined) {
                delete values.copy_of_court_order;
            }
            if (values['other_document'] === "" || values['other_document'] === null || values['other_document'] === undefined) {
                delete values.other_document;
            }
            if (values['estimated_estate_assets'] === "" || isNaN(values['estimated_estate_assets']) || values['estimated_estate_assets'] === null) {
                delete values.estimated_estate_assets;
            }
            Object.keys(values).forEach((key) => {
                if (key === 'personal_representative') {
                    formData.append('personal_representatives', JSON.stringify(data[key]));
                } else if (key === 'copy_of_last_will_and_testament' || key === 'copy_of_court_order' || key === 'other_document' || key === 'copy_of_court_order_link' || key === 'copy_of_last_will_and_testament_link' || key === 'other_document_link') {
                    formData.append(key, values[key])
                } else {
                    formData.append(key, data[key]);
                }
            });
            formData.append('user_comment', values.user_comment);
            formData.append('user', user_id);
            formData.append('bond_id', bondToEdit.id);
            formData.append('bond_number', bondToEdit.bond_number.id);
            formData.append('patch_type', 'update');
            formData.append('deceased_legal_name', ''.concat(values.deceased_legal_first_name, ' ', values.deceased_legal_middle_name, ' ', values.deceased_legal_last_name, ' ', values.deceased_legal_suffix));

            await fetch(
                API_URL + '/bond/create_bond/',
                {
                    method: "PATCH",
                    body: formData,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('userdata'),
                        // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
                    },
                }
            ).then(async (res) => {
                if (res.status === 200) {
                    props.successCallBack(true);
                    //refreshBondLists();
                    setIsUpdateRequired(false);
                    setViewID(bondToEdit.id);
                    resetForm();
                    dispatch(updateBondProcessHistory(await res.json()))
                }
            }).catch((error) => {
                console.log(error);
                props.failCallBack(true);
            })

            setLoading(false);
        },
        validationSchema: Yup.object(getValidation())
    });

    const changePersonalRepresentatives = (value) => {
        if (value <= 0 || isNaN(value)) {
            formIk.values.personal_representative = [];
            bondToEdit['personal_representatives'] = [];
            return;
        }
        if (value > 4) {
            return;
        }
        if (formIk.values.personal_representative.length > value) {
            const test = [...personalRepresentativeInputs.slice(0, value)];
            formIk.values.personal_representative = [...formIk.values.personal_representative.slice(0, value)];
            bondToEdit['personal_representatives'] = [...bondToEdit['personal_representatives'].slice(0, value)];
            return
        }
        let inputs_obj = {};
        const tempLineItemInputs = lineItemInputs.map((lineItem) => {
            inputs_obj[lineItem.name] = "";
        });
        if (formIk.values.personal_representative.length > 0) {
            value = value - formIk.values.personal_representative.length;
        }
        let tempLineItemInputList = [];
        for (value; value > 0; value--) {
            tempLineItemInputList.push(inputs_obj);
        }
        formIk.values.personal_representative = [...formIk.values.personal_representative, ...tempLineItemInputList];
        bondToEdit['personal_representatives'] = [...bondToEdit['personal_representatives'], ...tempLineItemInputList];
    }

    const approveBond = async () => {
        if (bondToEdit.law_firm === null) {
            formIk.setErrors({law_firm: "Please complete the law firm details"})
            const myElement = document.getElementById("law-firm-input");
            const topPos = myElement.offsetTop;
            window.scroll(0, topPos);
            enqueueSnackbar("Law Firm Details cant be Empty", {variant: "error"});
            return;
        }
        if (bondToEdit.law_firm_address === null) {
            formIk.setErrors({law_firm_address: "Please complete the law firm details"})
            const myElement = document.getElementById("law-firm-address-input");
            const topPos = myElement.offsetTop;
            window.scroll(0, topPos);
            enqueueSnackbar("Law Firm Details cant be Empty", {variant: "error"});
            return;
        }
        if (bondToEdit.contact === null) {  
            formIk.setErrors({contact: "Please complete the law firm details"})
            const myElement = document.getElementById("law-firm-contact-input");
            const topPos = myElement.offsetTop;
            window.scroll(0, topPos);
            enqueueSnackbar("Law Firm Details cant be Empty", {variant: "error"});
            return;
        }
        if (!locationCustomerNumber) {
            formIk.setErrors({law_firm: "This law firm does not have a customer number. Please assign it from company management screen."})
            const myElement = document.getElementById("law-firm-input");
            const topPos = myElement.offsetTop;
            window.scroll(0, topPos);
            return;
        }
        setLoadingMessage('Updating Bond...');
        setLoading(true);

        const data = {
            "patch_type": "approve",
            "approver": user_email,
            "bond_type": bondToEdit.bond_type,
            "bond_sub_category": bondToEdit.bond_sub_category,
            "bond_number": bondToEdit['bond_number']['bond_number'],
            "successor": "No",
            "bond_requestor_fullname": ''.concat(bondToEdit.user_first_name, ' ', bondToEdit.user_last_name),
            "bond_requestor_email": bondToEdit.user_email,
            "customer_no": locationCustomerNumber,
            "bond_amount": parseFloat(bondToEdit.bond_amount),
            "premium": parseFloat(bondToEdit.premium),
            "signed_date": formatDateMDY(new Date().toDateString() + " 00:00:00").replaceAll('-', '/'),
            "estate_of_first_name": bondToEdit.deceased_legal_first_name,
            "estate_of_middle_name": bondToEdit.deceased_legal_middle_name,
            "estate_of_last_name": bondToEdit.deceased_legal_last_name,
            "estate_of_suffix": bondToEdit.deceased_legal_suffix,
            "estate_of": ''.concat(bondToEdit.deceased_legal_first_name, ' ', bondToEdit.deceased_legal_middle_name ? (bondToEdit.deceased_legal_middle_name + ' ') : '', bondToEdit.deceased_legal_last_name, bondToEdit.deceased_legal_suffix ? (' ' + bondToEdit.deceased_legal_suffix) : ''), //note: possible incorrect
            "alias_estate_of": bondToEdit.alias_deceased_legal_name,
            "in_the_circuit_court_of": bondToEdit.county,
            "file_no": bondToEdit.court_case_number, //note: possible empty field but not mandatory in the form
            // "us_citizen" : bondToEdit['us_citizen'] === true ? 'Yes' : 'No',
            "estimated_assests": bondToEdit.estimated_estate_assets === undefined ? null : bondToEdit.estimated_estate_assets, //note: possible empty field but not mandatory in for
            "attorney_name": lawFirmContacts[bondToEdit.law_firm.id].filter((lf) => lf.value === bondToEdit.contact.id)[0].label,
            "attorney address": lawFirmAddresses[bondToEdit.law_firm.id].filter((lf) => lf.value === bondToEdit.law_firm_address.id)[0].label,
            "declined_by_another": bondToEdit.declined_by_another ? 'Yes' : 'No',
            "replace_the_bond": bondToEdit.replace_previous_bond ? 'Yes' : 'No',
            "prior_fiduciary": bondToEdit.applicant_replace_any_previous_fiduciary_in_this_estate ? 'Yes' : 'No',
            "indebted": bondToEdit.personal_representative_indebited_to_estate ? 'Yes' : 'No',
            "third_party": bondToEdit.is_required_on_demand ? 'Yes' : 'No',
            "include_a_business": bondToEdit.estate_has_ongoing_business ? 'Yes' : 'No',
            "ever_bankrupt": bondToEdit.ever_bankrupt ? 'Yes' : 'No',
            "lawsuits_judgment_liens": bondToEdit.lawsuits_judgment_liens ? 'Yes' : 'No',
            // "date_of_death": new Date(bondToEdit.date_of_death'] + " 00:00:00"),
            "date_of_death": formatDateUTCMDY(bondToEdit.date_of_death),
            "any_disputes": bondToEdit.any_disputes ? 'Yes' : 'No',
            "last_will": bondToEdit.decedent_execute_last_will_and_testament ? 'Yes' : 'No',
            "court_order": bondToEdit['copy_of_court_order_link'] === 'null' || bondToEdit['copy_of_court_order_link'] === 'undefined' ? null : bondToEdit['copy_of_court_order_link'],
            "last_will_and_testament": bondToEdit['copy_of_last_will_and_testament_link'] === 'null' || bondToEdit['copy_of_last_will_and_testament_link'] === 'undefined' ? null : bondToEdit['copy_of_last_will_and_testament_link'],
            "other_document": bondToEdit['other_document_link'] === 'null' || bondToEdit['other_document_link'] === 'undefined' ? null : bondToEdit['other_document_link'],
        }

        for (let i = 0; i < 4; i++) {
            if (bondToEdit.personal_representatives.length - 1 < i) {
                data['principal' + (i + 1).toString()] = null;
            } else {
                data['principal' + (i + 1).toString()] = {
                    "name": bondToEdit.personal_representatives[i]['name'],
                    "street": bondToEdit.personal_representatives[i]['address'],
                    "city": bondToEdit.personal_representatives[i]['city'],
                    "state": bondToEdit.personal_representatives[i]['state'],
                    "zip": bondToEdit.personal_representatives[i]['zip_code'],
                    "us_citizen": bondToEdit.personal_representatives[i]['is_us_citizen'] ? 'Yes' : 'No',
                    "citizenship": bondToEdit.personal_representatives[i]['citizenship'], //found empty
                    "other_country": bondToEdit.personal_representatives[i]['other_country'], //found empty
                    "ssn": bondToEdit.personal_representatives[i]['ssn'], //found empty
                    "occupation": bondToEdit.personal_representatives[i]['occupation'], //found empty
                }
            }
        }

        await fetch(
            API_URL + '/bond/approve_reject_bond/?bond_id=' + bondToEdit['id'] + '&status=APPROVED',
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('userdata'),
                },
            }
        ).then((res) => {
            if (res.status === 200) {
                dispatch(updateBondStatus({bond_id: bondToEdit['id'], status: 'APPROVED'}))
                refreshBondLists();
                props.successCallBack(true);
                props.closeCallBack();
            } else {
                console.log(res);
                props.failCallBack(true);
            }
        }).catch((error) => {
            console.log(error);
            props.failCallBack(true);
        })
    }
    const rejectBond = async () => {
        setLoadingMessage('Updating Bond...');
        setLoading(true);
        await fetch(
            API_URL + '/bond/approve_reject_bond/?bond_id=' + bondToEdit['id'] + '&status=REJECTED',
            {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('userdata'),
                },
            }
        ).then((res) => {
            if (res.status === 200) {
                dispatch(updateBondStatus({bond_id: bondToEdit['id'], status: 'REJECTED'}))
                refreshBondLists();
                props.successCallBack(true);
                props.closeCallBack();

            }
        }).catch((error) => {
            console.log(error);
            props.failCallBack(true);
        })
    }

    const refreshBondLists = () => {
        getBondList('IN_PROCESS').then((res) => {
            dispatch(removeBondProcessHistory());
            dispatch(mergeBondProcessHistory(res));
        }).catch((error) => {
            console.log(error);
            navigate('/');
        });

        getBondList('APPROVED').then((res) => {
            dispatch(mergeBondProcessHistory(res));
        }).catch((error) => {
            console.log(error);
        });
        getBondList('REJECTED').then((res) => {
            dispatch(mergeBondProcessHistory(res));
        }).catch((error) => {
            console.log(error);
        });
    }

    const addressAutoFillCallBack = (address, city, state, zip_code, prIndex) => {
        formIk.setFieldValue("personal_representative[" + prIndex + "].address", address);
        formIk.setFieldValue("personal_representative[" + prIndex + "].city", city);
        formIk.setFieldValue("personal_representative[" + prIndex + "].state", state);
        formIk.setFieldValue("personal_representative[" + prIndex + "].zip_code", zip_code);
        formIk.setFieldValue("personal_representative[" + prIndex + "].is_us_citizen", 'no');
        formIk.setFieldValue("personal_representative[" + prIndex + "].is_us_citizen", 'yes');
    }

    const downloadFile = (file_url) => {
        axios.get(file_url, {
            responseType: 'blob',
        })
            .then((res) => {
                let file_name = file_url.split('/');
                file_name = file_name[file_name.length - 1];

                fileDownload(res.data, file_name)
            })
    }

    return (
        <div key={props.bond_id}>
            
            <LoadingOverlay
                active={loading}
                spinner
                text={loadingMessage}
            >
                <h1>{bondToEdit.is_approved === 'IN_PROCESS' ? "Edit Form for Bonds" : "Form for Bonds"}</h1>
                {bondToEdit.guest_law_firm_address && <p className='text-red-400'>This bond is created by a Guest User - <strong>{bondToEdit.user_email}</strong></p>}
                <Row>

                    {
                        has_history === true && (
                            <Col>
                                <div style={{display: "inline-block"}} className={"col-12"}>
                                    <h5 style={{textAlign: "center"}}>Original</h5>
                                    {normalInputs.length > 0 && bondToEdit &&
                                        <>
                                            <Form.Group key="user_first_name" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className="required-label"><strong>First
                                                    Name</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={true} name={"user_first_name"}
                                                                 className={"form-control"} id={"user_first_name-input"}
                                                                 value={bondToEdit['history']["user_first_name"]}/>
                                            </Form.Group>
                                            <Form.Group key={"user_last_name"} className={"col-12  editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className="required-label"><strong>Last
                                                    Name</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={true} name={"user_last_name"}
                                                                 className={"form-control"} id={"user_last_name-input"}
                                                                 value={bondToEdit['history']["user_last_name"]}
                                                                 onChange={(value) => {
                                                                     formIk.setFieldValue("user_last_name", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>
                                            <Form.Group key="user_email" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className="required-label"><strong>Email</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={true} name={"user_email"}
                                                                 className={"form-control"} id={"user_email-input"}
                                                                 value={bondToEdit['history']["user_email"]}
                                                                 onChange={(value) => {
                                                                     formIk.setFieldValue("user_email", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>
                                            <Form.Group key="phone_number" className={"col-12 editBondStyleHeight"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className="required-label"><strong>Phone
                                                    Number</strong></Form.Label>
                                                <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                                              onChange={() => {
                                                                  setIsUpdateRequired(true);
                                                                  return formIk.handleChange
                                                              }}
                                                              disabled={true}
                                                              name={"phone_number"}
                                                              defaultValue={formIk.values.phone_number}/>

                                            </Form.Group>
                                            <Form.Group key="bond_type" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Bond
                                                    Type</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name="bond_type"
                                                                   value={bondToEdit['history']['bond_type']}
                                                                   onChange={(value) => {
                                                                       formIk.setFieldValue("bond_type", value);
                                                                       let sub_category_opt = normalInputs.filter((input) => input.name === "bond_sub_category")[0].options;
                                                                       sub_category_opt = sub_category_opt.filter((opt) => opt.filter_values.includes(value));
                                                                       setBondSubCategoryOptions(sub_category_opt);
                                                                       formIk.setFieldValue("bond_sub_category", "");
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "bond_type")[0].options}/>
                                            </Form.Group>

                                            <Form.Group key="bond_sub_category" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Bond Sub
                                                    Category</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name="bond_sub_category"
                                                                   value={bondToEdit['history']['bond_sub_category']}
                                                                   onChange={(value) => {
                                                                       formIk.setFieldValue("bond_sub_category", value)
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={bondSubCategoryOptions}/>
                                            </Form.Group>
                                            <Form.Group key="state" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>State</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name="state"
                                                                   value={bondToEdit['history']['state']}
                                                                   onChange={(value) => {
                                                                       formIk.setFieldValue("state", value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "state")[0].options}/>
                                            </Form.Group>

                                            <Form.Group key="county" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>County</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name="county"
                                                                   value={bondToEdit['history']['county']}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "county")[0].options.filter(opt => opt.filter_values.includes(bondToEdit.history.state))}/>
                                            </Form.Group>

                                            <Form.Group key="bond_amount" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Bond
                                                    Amount</strong></Form.Label>
                                                <CustomAmountInput disabled={true} required={true} name="bond_amount"
                                                                   className={"form-control"} id="bond_amount-input"
                                                                   decimalScale={0}
                                                                   value={bondToEdit['history']['bond_amount']}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}/>
                                            </Form.Group>

                                            <Form.Group key={premiumVal} className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Premium
                                                    Amount</strong></Form.Label>
                                                <CustomAmountInput disabled={true} required={true} name="premium"
                                                                   className={"form-control"} id="premium-input"
                                                                   decimalScale={2}
                                                                   value={bondToEdit['history']['premium']}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }
                                                                   }/>
                                            </Form.Group>

                                            <Form.Group key="deceased_legal_first_name" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Deceased Legal First
                                                    Name</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={true}
                                                                 name="deceased_legal_first_name"
                                                                 className={"form-control"}
                                                                 id="deceased_legal_first_name-input"
                                                                 value={bondToEdit['history']['deceased_legal_first_name']}
                                                                 onChange={(value) => {
                                                                     console.log("deceased_legal_first_name", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>
                                            <Form.Group key="deceased_legal_middle_name" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label><strong>Deceased Legal Middle Name</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={false}
                                                                 name="deceased_legal_middle_name"
                                                                 className={"form-control"}
                                                                 id="deceased_legal_middle_name-input"
                                                                 value={bondToEdit['history']['deceased_legal_middle_name']}
                                                                 onChange={(value) => {
                                                                     console.log("deceased_legal_middle_name", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>
                                            <Form.Group key="deceased_legal_last_name" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Deceased Legal Last
                                                    Name</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={true}
                                                                 name="deceased_legal_last_name"
                                                                 className={"form-control"}
                                                                 id="deceased_legal_last_name-input"
                                                                 value={bondToEdit['history']['deceased_legal_last_name']}
                                                                 onChange={(value) => {
                                                                     console.log("deceased_legal_last_name", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>
                                            <Form.Group key="deceased_legal_suffix" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label><strong>Deceased Legal Suffix</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={false}
                                                                 name="deceased_legal_suffix"
                                                                 className={"form-control"} id="deceased_legal_suffix-input"
                                                                 value={bondToEdit['history']['deceased_legal_suffix']}
                                                                 onChange={(value) => {
                                                                     console.log("deceased_legal_suffix", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>
                                            <Form.Group key="alias_deceased_legal_name" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label><strong>Deceased Aliases</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={false}
                                                                 name="alias_deceased_legal_name"
                                                                 className={"form-control"}
                                                                 id="alias_deceased_legal_name-input"
                                                                 value={bondToEdit['history']['alias_deceased_legal_name']}
                                                                 onChange={(value) => {
                                                                     console.log("alias_deceased_legal_name", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>

                                            <Form.Group key="date_of_death" className={"col-12 editBondStyleMinHeight"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Date Of
                                                    Death</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={true} name="date_of_death"
                                                                 className={"form-control"} id="date_of_death-input"
                                                    // value={formatDateMDY(new Date(bondToEdit['history']['date_of_death']))}
                                                                 value={formatDateUTCMDY(bondToEdit['history']['date_of_death'])}
                                                                 onChange={(value) => {
                                                                     console.log("date_of_death", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>

                                            {
                                                bondToEdit.history.county === 'Pasco' && (
                                                    <Form.Group key="has_judge_order" className={"col-12 editBondStyle"}
                                                                controlId="formBasicEmail">
                                                        <Form.Label className='required-label'><strong>Has Judge Stearn’s issued
                                                            his order requiring bond yet?</strong></Form.Label>
                                                        <CustomSelectInput disabled={true} name="has_judge_order"
                                                                           value={bondToEdit['history']['has_judge_order'] === true ? 'yes' : 'no'}
                                                                           onChange={(value) => {
                                                                               console.log(value);
                                                                               setIsUpdateRequired(true);
                                                                           }}
                                                                           options={[{label: 'Yes', value: 'yes'}, {
                                                                               label: 'No',
                                                                               value: 'no'
                                                                           }]}/>
                                                    </Form.Group>
                                                )
                                            }

                                            <Form.Group key="court_case_number" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label><strong>Court Case Number</strong></Form.Label>
                                                <CustomTextInput disabled={true} required={false} name="court_case_number"
                                                                 className={"form-control"} id="court_case_number-input"
                                                                 value={bondToEdit['history']['court_case_number']}
                                                                 onChange={(value) => {
                                                                     console.log(value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                            </Form.Group>

                                            <Form.Group key="number_of_personal_representative"
                                                        className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Number Of Personal
                                                    Representative</strong></Form.Label>
                                                <CustomNumberInput disabled={true} required={true}
                                                                   name="number_of_personal_representative"
                                                                   className={"form-control"}
                                                                   id={"number_of_personal_representative-input"}
                                                                   value={bondToEdit['history']['number_of_personal_representative']}
                                                                   onChange={(value) => {
                                                                       console.log(parseInt(value));
                                                                       setIsUpdateRequired(true);
                                                                   }}/>
                                            </Form.Group>

                                            <Form.Group key="estimated_estate_assets" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label><strong>Estimated Estate Assets</strong></Form.Label>
                                                <CustomAmountInput disabled={true} required={true}
                                                                   name="estimated_estate_assets"
                                                                   className={"form-control"}
                                                                   id="estimated_estate_assets-input" decimalScale={0}
                                                                   value={bondToEdit['history']['estimated_estate_assets']}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }
                                                                   }/>
                                            </Form.Group>

                                            <Form.Group key="estate_has_ongoing_business" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Will this estate include an
                                                    ongoing business?</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name="estate_has_ongoing_business"
                                                                   value={bondToEdit['history']['estate_has_ongoing_business'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "estate_has_ongoing_business")[0].options}/>
                                            </Form.Group>

                                            {
                                                bondToEdit.history.estate_has_ongoing_business === true &&
                                                <>
                                                    <Form.Group key="type_of_business_in_operation"
                                                                className={"col-12 editBondStyle"}
                                                                controlId="formBasicEmail">
                                                        <Form.Label className='required-label'>
                                                            <strong>If YES, what type of business will be in
                                                                operation</strong></Form.Label>
                                                        <CustomTextInput disabled={true} required={false}
                                                                         name="type_of_business_in_operation"
                                                                         className={"form-control"}
                                                                         id="type_of_business_in_operation-input"
                                                                         value={bondToEdit['history']['type_of_business_in_operation']}
                                                                         onChange={(value) => {
                                                                             console.log(value);
                                                                             setIsUpdateRequired(true);
                                                                         }}/>
                                                    </Form.Group>
                                                </>
                                            }

                                            <Form.Group key="personal_representative_indebited_to_estate"
                                                        className={"col-12 editBondStyle"} controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Are any of the Personal
                                                    Representatives indebted to the Estate?</strong></Form.Label>
                                                <CustomSelectInput disabled={true}
                                                                   name="personal_representative_indebited_to_estate"
                                                                   value={bondToEdit['history']['personal_representative_indebited_to_estate'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "personal_representative_indebited_to_estate")[0].options}/>
                                            </Form.Group>

                                            <Form.Group key="decedent_execute_last_will_and_testament"
                                                        className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Did the Decedent execute a
                                                    Last Will & Testament?</strong></Form.Label>
                                                <CustomSelectInput disabled={true}
                                                                   name="decedent_execute_last_will_and_testament"
                                                                   value={bondToEdit['history']['decedent_execute_last_will_and_testament'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "decedent_execute_last_will_and_testament")[0].options}/>
                                            </Form.Group>

                                            <Form.Group key="applicant_replace_any_previous_fiduciary_in_this_estate"
                                                        className={"col-12 editBondStyle"} controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Does applicant replace any
                                                    previous fiduciary in this Estate?</strong></Form.Label>
                                                <CustomSelectInput disabled={true}
                                                                   name="applicant_replace_any_previous_fiduciary_in_this_estate"
                                                                   value={bondToEdit['history']['applicant_replace_any_previous_fiduciary_in_this_estate'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "applicant_replace_any_previous_fiduciary_in_this_estate")[0].options}/>
                                            </Form.Group>
                                            {
                                                bondToEdit.history.applicant_replace_any_previous_fiduciary_in_this_estate === true && (
                                                    <Form.Group key="previous_fiduciaries" className="mb-3 editBondStyle"
                                                                controlId="formBasicEmail">
                                                        <Form.Label><strong>If there have been previous fiduciaries, name them
                                                            here and
                                                            explain each one.</strong></Form.Label>
                                                        <CustomLongInput disabled={true} required={false}
                                                                         name="previous_fiduciaries"
                                                                         className={"form-control"}
                                                                         id={"previous_fiduciaries-input"}
                                                                         value={bondToEdit['history']['previous_fiduciaries']}
                                                                         onChange={(value) => {
                                                                             console.log(value);
                                                                             setIsUpdateRequired(true);
                                                                         }}
                                                        />
                                                    </Form.Group>
                                                )
                                            }

                                            <Form.Group key={"declined_by_another"} className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Is this bond declined by any
                                                    other court?</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name={"declined_by_another"}
                                                                   value={bondToEdit['history']['declined_by_another'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((li) => li.name === 'declined_by_another')[0].options}/>
                                            </Form.Group>

                                            <Form.Group key={"ever_bankrupt"} className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Ever
                                                    bankrupt</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name={"ever_bankrupt"}
                                                                   value={bondToEdit['history']['ever_bankrupt'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((li) => li.name === 'ever_bankrupt')[0].options}/>
                                            </Form.Group>

                                            <Form.Group key={"replace_previous_bond"} className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Does this bond replace any
                                                    other bond?</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name={"replace_previous_bond"}
                                                                   value={bondToEdit['history']['replace_previous_bond'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((li) => li.name === 'replace_previous_bond')[0].options}/>
                                            </Form.Group>

                                            <Form.Group key={"lawsuits_judgment_liens"} className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Lawsuits Judgment
                                                    Liens</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name={"lawsuits_judgment_liens"}
                                                                   value={bondToEdit['history']['lawsuits_judgment_liens'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log("lawsuits_judgment_liens", value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((li) => li.name === 'lawsuits_judgment_liens')[0].options}/>
                                            </Form.Group>

                                            <Form.Group key={"any_disputes"} className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Any
                                                    Disputes</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name={"any_disputes"}
                                                                   value={bondToEdit['history']['any_disputes'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.log(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((li) => li.name === 'any_disputes')[0].options}/>
                                            </Form.Group>

                                            <Form.Group key="is_required_on_demand" className={"col-12 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Is this bond required on the
                                                    demand of an interested party other than the
                                                    Court?</strong></Form.Label>
                                                <CustomSelectInput disabled={true} name="is_required_on_demand"
                                                                   value={bondToEdit['history']['is_required_on_demand'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       console.og(value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "is_required_on_demand")[0].options}/>
                                            </Form.Group>

                                            {
                                                bondToEdit.history.is_required_on_demand === true &&
                                                <Form.Group key="interested_parties" className="mb-3 editBondStyle"
                                                            controlId="formBasicEmail">
                                                    <Form.Label className='required-label'>
                                                        <strong>If YES, please list interested parties and give a brief
                                                            explanation.</strong></Form.Label>
                                                    <CustomLongInput disabled={true} required={false}
                                                                     name="interested_parties"
                                                                     className={"form-control"}
                                                                     id={"interested_parties-input"}
                                                                     value={bondToEdit['history']['interested_parties']}
                                                                     onChange={(value) => {
                                                                         console.log(value);
                                                                         setIsUpdateRequired(true);
                                                                     }}
                                                    />
                                                </Form.Group>
                                            }
                                            {
                                                bondToEdit.history.bond_amount > 50000 && (
                                                    <Form.Group key="e_signed_indemnity" className="col-12 editBondStyle"
                                                                controlId="formBasicEmail">
                                                        <Form.Label className='required-label'><strong>Do you want an E-signed
                                                            indemnity form?</strong></Form.Label>
                                                        <CustomSelectInput disabled={true} name="e_signed_indemnity"
                                                                           value={bondToEdit['history']['e_signed_indemnity'] === true ? 'yes' : 'no'}
                                                                           onChange={(value) => {
                                                                               console.log(value);
                                                                               setIsUpdateRequired(true);
                                                                           }}
                                                                           options={normalInputs.filter((input) => input.name === "e_signed_indemnity")[0].options}/>
                                                    </Form.Group>
                                                )
                                            }
                                            {/* {lawFirms.length > 0 &&
                                                <Form.Group key="law_firm" className={"col-12 editBondStyle"}
                                                            controlId="formBasicEmail">
                                                    <Form.Label className='required-label'><strong>Law
                                                        Firm</strong></Form.Label>
                                                    <CustomSelectInput disabled={true} name="law_firm"
                                                                       value={bondToEdit['history']['law_firm']['id']}
                                                                       onChange={(value) => {
                                                                           console.log("law_firm", value);
                                                                           setIsUpdateRequired(true);
                                                                       }}
                                                                       options={lawFirms}/>
                                                </Form.Group>
                                            }

                                            {
                                                lawFirms.length > 0 &&
                                                <Form.Group key="law_firm_address" className={"col-12 editBondStyle"}
                                                            controlId="formBasicEmail">
                                                    <Form.Label className='required-label'><strong>Law Firm Address</strong></Form.Label>
                                                    <CustomSelectInput disabled={true} name="law_firm_address"
                                                                       value={bondToEdit['history']['law_firm_address']['id']}
                                                                       onChange={(value) => {
                                                                           console.log(value);
                                                                           setIsUpdateRequired(true);
                                                                       }}
                                                                       options={lawFirmAddresses[bondToEdit['history']['law_firm']['id']]}/>
                                                </Form.Group>
                                            }

                                            {
                                                lawFirms.length > 0 &&
                                                <Form.Group key="contact" className={"col-12 editBondStyle"}
                                                            controlId="formBasicEmail">
                                                    <Form.Label className='required-label'><strong>Attorney of
                                                        Record</strong></Form.Label>
                                                    <CustomSelectInput disabled={true} name="contact"
                                                                       value={bondToEdit['history']['contact']['id']}
                                                                       onChange={(value) => {
                                                                           console.log(value);
                                                                           setIsUpdateRequired(true);
                                                                       }}
                                                                       options={lawFirmContacts[bondToEdit['history']['contact']['id']]}/>
                                                </Form.Group>
                                            } */}
                                            <Form.Group key="copy_of_court_order" className={"col-12 editBondStylePDF"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label' style={{display: "block"}}>
                                                    <strong>Court Order</strong></Form.Label>


                                                <a href={bondToEdit['history']["copy_of_court_order_link"]}>{bondToEdit['history']["copy_of_court_order_link"] !== "" && bondToEdit['history']["copy_of_court_order_link"] !== null && bondToEdit['history']["copy_of_court_order_link"] !== undefined ? bondToEdit['history']["court_order_file_name"] : "No file was provided"}
                                                </a>
                                            </Form.Group>
                                            {
                                                bondToEdit.history.decedent_execute_last_will_and_testament === true &&
                                                <Form.Group key="copy_of_last_will_and_testament"
                                                            className={"col-12 editBondStylePDF"}
                                                            controlId="formBasicEmail">
                                                    <Form.Label
                                                        className={formIk.values['bond_amount'] >= 1000000 ? 'required-label' : ''}
                                                        style={{display: "block"}}>
                                                        <strong>Last Will & Testament</strong></Form.Label>
                                                    <a href={bondToEdit['history']["copy_of_last_will_and_testament_link"]}>{bondToEdit['history']["copy_of_last_will_and_testament_link"] !== "" && bondToEdit['history']["copy_of_last_will_and_testament_link"] !== null && bondToEdit['history']["copy_of_last_will_and_testament_link"] !== undefined ? bondToEdit['history']["last_will_and_testament_file_name"] : "No file was provided"}
                                                    </a>
                                                </Form.Group>
                                            }
                                            <Form.Group key="other_document" className={"col-12 editBondStylePDF"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label' style={{display: "block"}}>
                                                    <strong>Other Document</strong></Form.Label>
                                                <a href={bondToEdit['history']["other_document_link"]}>{bondToEdit['history']["other_document_link"] !== "" && bondToEdit['history']["other_document_link"] !== null && bondToEdit['history']["other_document_link"] !== undefined ? bondToEdit['history']["other_document_file_name"] : "No file was provided"}
                                                </a>
                                            </Form.Group>
                                            <Form.Group key="user_comment"
                                                        className="col-12  editBondStyleMinHeight"
                                                        controlId="formBasicEmail">
                                                <Form.Label style={{display: "block"}}><strong>Note</strong></Form.Label>
                                                <CustomLongInput disabled={true} required={false} className="border-5"
                                                                 name="user_comment-history" id="user_comment-input-history"
                                                                 value={bondToEdit['history']['user_comment']}
                                                                 onChange={(value) => {
                                                                     console.log("user_comment", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                                {/*<div className="text-error">{formIK.errors["user_comment"]}</div>*/}
                                            </Form.Group>
                                        </>
                                    }
                                    {
                                        bondToEdit.history.personal_representatives.length > 0 &&
                                        <>
                                            {
                                                bondToEdit.history.personal_representatives.map((pr, index) => {
                                                    return (
                                                        <>
                                                            <h3 style={{marginBottom: "0px", marginTop: "1rem"}}>Personal
                                                                Representative {index + 1}</h3>
                                                            <Form.Group key={"name.history-" + index}
                                                                        className={"col-12 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label className='required-label'><strong>Name of
                                                                    Personal Rep</strong></Form.Label>
                                                                <CustomTextInput disabled={true} required={true}
                                                                                 name={"personal_representative[" + index + "].name"}
                                                                                 className={"form-control"}
                                                                                 id={"personal_representative.history.[" + index + "].name-input"}
                                                                                 value={pr['name']} onChange={(value) => {
                                                                    console.log(value);
                                                                    setIsUpdateRequired(true);
                                                                }}/>
                                                            </Form.Group>
                                                            {
                                                                bondToEdit.history.e_signed_indemnity === true && (
                                                                    <Form.Group key={"email-" + index}
                                                                                className={"col-12 editBondStyle"}
                                                                                controlId="formBasicEmail">
                                                                        <Form.Label
                                                                            className='required-label'><strong>Email</strong></Form.Label>
                                                                        <CustomTextInput disabled={true} required={true}
                                                                                         name={"personal_representative[" + index + "].email"}
                                                                                         className={"form-control"}
                                                                                         id={"personal_representative[" + index + "].email-input"}
                                                                                         value={pr['email']}
                                                                                         onChange={(value) => {
                                                                                             console.log(value);
                                                                                             setIsUpdateRequired(true);
                                                                                         }}/>
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            <Form.Group key={"is_us_citizen.history-" + index}
                                                                        className={"col-12 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label className='required-label'><strong>Is US
                                                                    Citizen</strong></Form.Label>
                                                                <CustomSelectInput disabled={true}
                                                                                   name={"personal_representative[" + index + "].is_us_citizen"}
                                                                                   value={pr['is_us_citizen']}
                                                                                   onChange={(value) => {
                                                                                       console.log(value);
                                                                                       setIsUpdateRequired(true);
                                                                                   }}
                                                                                   options={lineItemInputs.filter((li) => li.name === 'is_us_citizen')[0].options}/>
                                                            </Form.Group>
                                                            <Form.Group key={"address.history-" + index}
                                                                        className={"col-12 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label className='required-label'><strong>Street
                                                                    Address for Personal Rep</strong></Form.Label>
                                                                <CustomTextInput disabled={true} required={true}
                                                                                 name={"personal_representative.history[" + index + "].address"}
                                                                                 className={"form-control"}
                                                                                 id={"personal_representative.history[" + index + "].address-input"}
                                                                                 value={pr['address']}
                                                                                 onChange={(value) => {
                                                                                     console.log(value);
                                                                                     setIsUpdateRequired(true);
                                                                                 }}/>
                                                            </Form.Group>
                                                            {
                                                                bondToEdit['history']["personal_representatives"][index]['is_us_citizen'] === true && (
                                                                    <Form.Group key={"city.history-" + index}
                                                                                className={"col-12 editBondStyle"}
                                                                                controlId="formBasicEmail">
                                                                        <Form.Label className='required-label'><strong>City/Town
                                                                            for Personal Rep</strong></Form.Label>
                                                                        <CustomTextInput disabled={true} required={true}
                                                                                         name={"personal_representative.history[" + index + "].city"}
                                                                                         className={"form-control"}
                                                                                         id={"personal_representative.history[" + index + "].city-input"}
                                                                                         value={pr['city']}
                                                                                         onChange={(value) => {
                                                                                             console.log(value);
                                                                                             setIsUpdateRequired(true);
                                                                                         }}/>
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                bondToEdit['history']["personal_representatives"][index]['is_us_citizen'] === true && (
                                                                    <Form.Group key={"state.history-" + index}
                                                                                className={"col-12 editBondStyle"}
                                                                                controlId="formBasicEmail">
                                                                        <Form.Label
                                                                            className='required-label'><strong>State/Province/Region</strong></Form.Label>
                                                                        <CustomSelectInput disabled={true}
                                                                                           name={"personal_representative.history[" + index + "].state"}
                                                                                           value={pr['state']}
                                                                                           onChange={(value) => {
                                                                                               console.log(value);
                                                                                               setIsUpdateRequired(true);
                                                                                           }}
                                                                                           options={lineItemInputs.filter((li) => li.name === 'state')[0].options}/>
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                bondToEdit['history']["personal_representatives"][index]['is_us_citizen'] === true && (
                                                                    <Form.Group key={"zip_code.history-" + index}
                                                                                className={"col-12 editBondStyle"}
                                                                                controlId="formBasicEmail">
                                                                        <Form.Label className='required-label'><strong>ZIP/Postal
                                                                            Code</strong></Form.Label>
                                                                        <ZipCode disabled={true} required={true}
                                                                                 name="zip_code.history"
                                                                                 className={"form-control"}
                                                                                 id="zip_code.history-input"
                                                                                 value={pr['zip_code']}
                                                                                 onChange={(value) => {
                                                                                     console.log(value);
                                                                                     setIsUpdateRequired(true);
                                                                                 }}/>
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                bondToEdit['history']["personal_representatives"][index]['is_us_citizen'] === false && (
                                                                    <Form.Group key={"citizenship.history-" + index}
                                                                                className={"col-12 editBondStyle"}
                                                                                controlId="formBasicEmail">
                                                                        <Form.Label className='required-label'><strong>If NO,
                                                                            what is their citizenship or primary
                                                                            residency?</strong></Form.Label>
                                                                        <CustomSelectInput disabled={true}
                                                                                           name={"personal_representative.history[" + index + "].citizenship"}
                                                                                           value={pr['citizenship']}
                                                                                           onChange={(value) => {
                                                                                               console.log(value);
                                                                                               setIsUpdateRequired(true);
                                                                                           }}
                                                                                           options={lineItemInputs.filter((li) => li.name === 'citizenship')[0].options}/>
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                bondToEdit['history']["personal_representatives"][index]['citizenship'] === 'Others' && (
                                                                    <Form.Group key={"other_country.history-" + index}
                                                                                className={"col-12 editBondStyle"}
                                                                                controlId="formBasicEmail">
                                                                        <Form.Label className='required-label'><strong>Other
                                                                            Country for Personal Rep</strong></Form.Label>
                                                                        <CustomSelectInput disabled={true}
                                                                                           name={"personal_representative.history[" + index + "].other_country"}
                                                                                           value={pr['other_country']}
                                                                                           onChange={(value) => {
                                                                                               console.log(value);
                                                                                               setIsUpdateRequired(true);
                                                                                           }}
                                                                                           options={lineItemInputs.filter((li) => li.name === 'other_country')[0].options}/>
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                (bondToEdit['history']["personal_representatives"][index]['is_us_citizen'] === true && bondToEdit['history']['bond_amount'] >= 250000) && (
                                                                    <Form.Group key={"zip_code-" + index}
                                                                                className={"col-12 editBondStyle"}
                                                                                controlId="formBasicEmail">
                                                                        <Form.Label className='required-label'><strong>If YES to
                                                                            US Citizen, what is their Social Security
                                                                            Number?</strong></Form.Label>
                                                                        <PatternFormat className={"form-control"}
                                                                                       name={"personal_representative.history[" + index + "].ssn"}
                                                                                       id={"ssn-input.history-" + index}
                                                                                       defaultValue={pr['ssn']}
                                                                                       format="###-##-####" allowEmptyFormatting
                                                                                       mask="#"
                                                                                       disabled={true} onChange={(value) => {
                                                                            console.log(value.target.value);
                                                                            setIsUpdateRequired(true);
                                                                        }}/>
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                bondToEdit['history']['bond_amount'] > 50000 && (
                                                                    <Form.Group key={"occupation.history-" + index}
                                                                                className={"col-12  editBondStyle"}
                                                                                controlId="formBasicEmail">
                                                                        <Form.Label><strong>Occupation of Personal Rep</strong></Form.Label>
                                                                        <CustomTextInput disabled={true} required={false}
                                                                                         name={"personal_representative[" + index + "].occupation"}
                                                                                         className={"form-control"}
                                                                                         id={"personal_representative.history[" + index + "].occupation-input"}
                                                                                         value={pr['occupation']}
                                                                                         onChange={(value) => {
                                                                                             console.log(value);
                                                                                             setIsUpdateRequired(true);
                                                                                         }}/>
                                                                    </Form.Group>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                    <div style={{textAlign: "right", marginTop: "1.6%", padding: "1%"}}>
                                        <button style={{
                                            marginRight: "0.5%",
                                            color: "white",
                                            backgroundColor: "white",
                                            borderColor: "white"
                                        }}
                                                className="btn btn-danger" onClick={(e) => {
                                            e.preventDefault();
                                        }}></button>
                                    </div>
                                </div>
                            </Col>
                        )
                    }

                    <Col>
                        <Form onSubmit={formIk.handleSubmit} style={{display: 'inline-block'}}
                              className={"col-12"}>

                            {normalInputs.length > 0 && bondToEdit &&
                                <>
                                    {
                                        has_history === true && <h5 style={{textAlign: "center"}}>Updated</h5>
                                    }
                                    <Form.Group key="user_first_name"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className="required-label"><strong>First Name</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={true} name={"user_first_name"}
                                                         className={"form-control"} id={"user_first_name-input"}
                                                         value={bondToEdit["user_first_name"]} onChange={(value) => {
                                            formIk.setFieldValue("user_first_name", value);
                                            setIsUpdateRequired(true);
                                        }}/>
                                        {formIk.errors["user_first_name"] && (
                                            <div
                                                className="text-error">{formIk.errors["user_first_name"]}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group key={"user_last_name"}
                                                className={has_history === true ? "col-12  editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className="required-label"><strong>Last Name</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={true} name={"user_last_name"}
                                                         className={"form-control"} id={"user_last_name-input"}
                                                         value={bondToEdit["user_last_name"]} onChange={(value) => {
                                            formIk.setFieldValue("user_last_name", value);
                                            setIsUpdateRequired(true);
                                        }}/>
                                        {formIk.errors["user_last_name"] && (
                                            <div
                                                className="text-error">{formIk.errors["user_last_name"]}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group key="user_email"
                                                className={has_history === true ? "col-12  editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className="required-label"><strong>Email</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={true} name={"user_email"}
                                                         className={"form-control"} id={"user_email-input"}
                                                         value={bondToEdit["user_email"]} onChange={(value) => {
                                            formIk.setFieldValue("user_email", value);
                                            setIsUpdateRequired(true);
                                        }}/>
                                        {formIk.errors["user_email"] && (
                                            <div
                                                className="text-error">{formIk.errors["user_email"]}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group key="phone_number"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyleHeight"}
                                                controlId="formBasicEmail">
                                        <Form.Label className="required-label"><strong>Phone
                                            Number</strong></Form.Label>
                                        {/*<Form.Control type="text" size="sm" onBlur={formIk.handleBlur}*/}
                                        {/*              onChange={formIk.handleChange}*/}
                                        {/*              name={"phone_number"}*/}
                                        {/*              defaultValue={formIk.values.phone_number}/>*/}
                                        <USPhoneNumberWithoutCountryCodeUniversal
                                            disabled={bondToEdit.is_approved !== 'IN_PROCESS'} required={true}
                                            className={"form-control"} name="phone_numer"
                                            id="phone_number-input"
                                            value={bondToEdit['phone_number']}
                                            onChange={(value) => {
                                                if (value === "(___) ___ ____") {
                                                    formIk.errors['phone_number'] = "Please provide a valid phone number.";
                                                } else {
                                                    delete formIk.errors['phone_number'];
                                                }
                                                formIk.setFieldValue("phone_number", value);
                                                setIsUpdateRequired(true);
                                            }}/>
                                        {formIk.errors["phone_number"] && (
                                            <div
                                                className="text-error">{formIk.errors["phone_number"]}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group key="bond_type"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Bond Type</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="bond_type" value={bondToEdit['bond_type']}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("bond_type", value);
                                                               let sub_category_opt = normalInputs.filter((input) => input.name === "bond_sub_category")[0].options;
                                                               sub_category_opt = sub_category_opt.filter((opt) => opt.filter_values.includes(value));
                                                               setBondSubCategoryOptions(sub_category_opt);
                                                               formIk.setFieldValue("bond_sub_category", "");
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((input) => input.name === "bond_type")[0].options}/>
                                        {formIk.errors["bond_type"] && (
                                            <div className="text-error">{formIk.errors["bond_type"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="bond_sub_category"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Bond Sub
                                            Category</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="bond_sub_category"
                                                           value={bondToEdit['bond_sub_category']}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("bond_sub_category", value);
                                                               setIsUpdateRequired(true);
                                                           }
                                                           }
                                                           options={bondSubCategoryOptions}/>
                                        {formIk.errors["bond_sub_category"] && (
                                            <div className="text-error">{formIk.errors["bond_sub_category"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="state"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>State</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="state" value={bondToEdit['state']}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("state", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((input) => input.name === "state")[0].options}/>
                                        {formIk.errors["state"] && (
                                            <div className="text-error">{formIk.errors["state"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="county"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>County</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="county" value={bondToEdit['county']}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("county", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((input) => input.name === "county")[0].options.filter(opt => opt.filter_values.includes(formIk.values.state))}/>
                                        {formIk.errors["county"] && (
                                            <div className="text-error">{formIk.errors["county"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="bond_amount"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Bond Amount</strong></Form.Label>
                                        <CustomAmountInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           required={true} name="bond_amount"
                                                           className={"form-control"} id="bond_amount-input"
                                                           decimalScale={0}
                                                           value={bondToEdit['bond_amount']} onChange={(value) => {
                                            console.log(value)
                                            if (isNaN(value)) {
                                                setPremiumVal(0);
                                                formIk.setFieldValue("premium", 0);
                                            } else {
                                                let premium = 0;
                                                let amount = value;
                                                if (amount < 2000) {
                                                    premium = premium + (amount * 10) / 1000;
                                                } else {
                                                    premium = premium + (2000 * 10) / 1000;
                                                    amount = amount - 2000;
                                                    if (amount < 48000) {
                                                        premium = premium + (amount * 5) / 1000;
                                                    } else {
                                                        premium = premium + (48000 * 5) / 1000;
                                                        amount = amount - 48000;
                                                        if (amount < 150000) {
                                                            premium = premium + (amount * 4) / 1000;
                                                        } else {
                                                            premium = premium + (150000 * 4) / 1000;
                                                            amount = amount - 150000;
                                                            if (amount < 300000) {
                                                                premium = premium + (amount * 2.5) / 1000;
                                                            } else {
                                                                premium = premium + (300000 * 2.5) / 1000;
                                                                amount = amount - 300000;
                                                                if (amount < 500000) {
                                                                    premium = premium + (amount) / 1000;
                                                                } else {
                                                                    premium = premium + (500000) / 1000;
                                                                    amount = amount - 500000;
                                                                    if (amount < 50000000) {
                                                                        premium = premium + (amount * 0.5) / 1000;
                                                                    } else {
                                                                        premium = premium + (500000 * 0.5) / 1000;
                                                                        amount = amount - 500000;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                console.log(premium, premium)
                                                if (premium < 100) {
                                                    setPremiumVal(100);
                                                    formIk.setFieldValue("premium", 100);
                                                } else {
                                                    setPremiumVal(parseFloat(premium.toFixed(2)));
                                                    formIk.setFieldValue("premium", parseFloat(premium.toFixed(2)));
                                                }
                                            }

                                            formIk.setFieldValue("bond_amount", value);
                                            setIsUpdateRequired(true);
                                        }
                                        }/>
                                        {formIk.errors["bond_amount"] && formIk.touched["bond_amount"] && (
                                            <div className="text-error">{formIk.errors["bond_amount"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key={premiumVal}
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Premium
                                            Amount</strong></Form.Label>
                                        <CustomAmountInput disabled={true} required={true} name="premium"
                                                           className={"form-control"} id="premium-input"
                                                           decimalScale={2}
                                                           value={premiumVal}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("premium", value);
                                                               setIsUpdateRequired(true);
                                                           }
                                                           }/>
                                        {formIk.errors["premium"] && formIk.touched["premium"] && (
                                            <div className="text-error">{formIk.errors["premium"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="deceased_legal_first_name"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Deceased Legal First
                                            Name</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={true} name="deceased_legal_first_name"
                                                         className={"form-control"} id="deceased_legal_first_name-input"
                                                         value={bondToEdit['deceased_legal_first_name']}
                                                         onChange={(value) => {
                                                             formIk.setFieldValue("deceased_legal_first_name", value);
                                                             setIsUpdateRequired(true);
                                                         }}/>
                                        {formIk.errors["deceased_legal_first_name"] && formIk.touched["deceased_legal_first_name"] && (
                                            <div
                                                className="text-error">{formIk.errors["deceased_legal_first_name"]}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group key="deceased_legal_middle_name"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label><strong>Deceased Legal Middle
                                            Name</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={false} name="deceased_legal_middle_name"
                                                         className={"form-control"}
                                                         id="deceased_legal_middle_name-input"
                                                         value={bondToEdit['deceased_legal_middle_name']}
                                                         onChange={(value) => {
                                                             formIk.setFieldValue("deceased_legal_middle_name", value);
                                                             setIsUpdateRequired(true);
                                                         }}/>
                                        {formIk.errors["deceased_legal_middle_name"] && formIk.touched["deceased_legal_middle_name"] && (
                                            <div
                                                className="text-error">{formIk.errors["deceased_legal_middle_name"]}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group key="deceased_legal_last_name"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Deceased Legal Last
                                            Name</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={true} name="deceased_legal_last_name"
                                                         className={"form-control"} id="deceased_legal_last_name-input"
                                                         value={bondToEdit['deceased_legal_last_name']}
                                                         onChange={(value) => {
                                                             formIk.setFieldValue("deceased_legal_last_name", value);
                                                             setIsUpdateRequired(true);
                                                         }}/>
                                        {formIk.errors["deceased_legal_last_name"] && formIk.touched["deceased_legal_last_name"] && (
                                            <div
                                                className="text-error">{formIk.errors["deceased_legal_last_name"]}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group key="deceased_legal_suffix"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label><strong>Deceased Legal Suffix</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={false} name="deceased_legal_suffix"
                                                         className={"form-control"} id="deceased_legal_suffix-input"
                                                         value={bondToEdit['deceased_legal_suffix']}
                                                         onChange={(value) => {
                                                             formIk.setFieldValue("deceased_legal_suffix", value);
                                                             setIsUpdateRequired(true);
                                                         }}/>
                                        {formIk.errors["deceased_legal_suffix"] && formIk.touched["deceased_legal_suffix"] && (
                                            <div className="text-error">{formIk.errors["deceased_legal_suffix"]}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group key="alias_deceased_legal_name"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label><strong>Deceased Aliases</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={false} name="alias_deceased_legal_name"
                                                         className={"form-control"} id="alias_deceased_legal_name-input"
                                                         value={bondToEdit['alias_deceased_legal_name']}
                                                         onChange={(value) => {
                                                             formIk.setFieldValue("alias_deceased_legal_name", value);
                                                             setIsUpdateRequired(true);
                                                         }}/>
                                        {formIk.errors["alias_deceased_legal_name"] && formIk.touched["alias_deceased_legal_name"] && (
                                            <div
                                                className="text-error">{formIk.errors["alias_deceased_legal_name"]}</div>)}
                                    </Form.Group>

                                    <Form.Group key="date_of_death"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Date Of
                                            Death</strong></Form.Label>
                                        <DateNormalInput disabled={bondToEdit['is_approved'] !== 'IN_PROCESS'}
                                                         required={true} name="date_of_death"
                                                         className={"form-control"} id="date_of_death-input"
                                                         value={formatDateUTCMDY(bondToEdit['date_of_death'])}
                                                         onChange={(value) => {
                                                             if (value === null || value === "" || value === "NaN-NaN-NaN" || new Date(value).toString() === 'Invalid Date') {
                                                                 formIk.errors['date_of_death'] = "Date of death is required.";
                                                             } else if (new Date(value) > new Date()) {
                                                                 formIk.errors['date_of_death'] = "Date of death cannot be in future.";
                                                             } else {
                                                                 delete formIk.errors['date_of_death'];
                                                             }
                                                             console.log("Date: ", value);
                                                             if (value === '01-01-1970') {
                                                                 formIk.setFieldValue("date_of_death", '');
                                                             } else {
                                                                 formIk.setFieldValue("date_of_death", formatUTCDate(value))
                                                             }
                                                             setIsUpdateRequired(true);
                                                         }}/>
                                        {formIk.errors["date_of_death"] && formIk.touched["date_of_death"] && (
                                            <div className="text-error">{formIk.errors["date_of_death"]}</div>
                                        )}
                                    </Form.Group>

                                    {
                                        formIk.values.county === 'Pasco' && (
                                            <Form.Group key="has_judge_order"
                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Has Judge Stearn’s issued his
                                                    order requiring bond yet?</strong></Form.Label>
                                                <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                   name="has_judge_order"
                                                                   value={bondToEdit['has_judge_order'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {

                                                                       formIk.setFieldValue("has_judge_order", value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={[{label: 'Yes', value: 'yes'}, {
                                                                       label: 'No',
                                                                       value: 'no'
                                                                   }]}/>
                                                {formIk.errors["has_judge_order"] && (
                                                    <div className="text-error">{formIk.errors["has_judge_order"]}</div>
                                                )}
                                            </Form.Group>
                                        )
                                    }
                                    {/*(Examples: Pinellas County FL format = (22-012345-ES) which is compose from: Year(YY)-6_Digit_Sequence#-Court_Type(ES, GD). Pasco County FL uses this format: Year-CourtType-Seq#-PartyIdentifier-BranchLocation)*/}
                                    <Form.Group key="court_case_number"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label><strong>Court Case Number</strong></Form.Label>
                                        <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={false} name="court_case_number"
                                                         className={"form-control"} id="court_case_number-input"
                                                         value={bondToEdit['court_case_number']}
                                                         onChange={(value) => {
                                                             formIk.setFieldValue("court_case_number", value);
                                                             setIsUpdateRequired(true);
                                                         }}/>
                                        {formIk.errors["court_case_number"] && formIk.touched["court_case_number"] && (
                                            <div className="text-error">{formIk.errors["court_case_number"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="number_of_personal_representative"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Number Of Personal
                                            Representative</strong></Form.Label>
                                        <CustomNumberInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           required={true}
                                                           min="1"
                                                           max="4"
                                                           name="number_of_personal_representative"
                                                           className={"form-control"}
                                                           id={"number_of_personal_representative-input"}
                                                           value={bondToEdit['number_of_personal_representative']}
                                                           onChange={(value) => {
                                                               changePersonalRepresentatives(parseInt(value));
                                                               formIk.setFieldValue("number_of_personal_representative", parseInt(value));
                                                               setIsUpdateRequired(true);
                                                           }}/>
                                        {formIk.errors["number_of_personal_representative"] && (
                                            <div
                                                className="text-error">{formIk.errors["number_of_personal_representative"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="estimated_estate_assets"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label><strong>Estimated Estate Assets</strong></Form.Label>
                                        <CustomAmountInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           required={true} name="estimated_estate_assets"
                                                           className={"form-control"} id="estimated_estate_assets-input"
                                                           decimalScale={0}
                                                           value={bondToEdit['estimated_estate_assets']}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("estimated_estate_assets", value);
                                                               setIsUpdateRequired(true);
                                                           }
                                                           }/>
                                        {formIk.errors["estimated_estate_assets"] && formIk.touched["estimated_estate_assets"] && (
                                            <div className="text-error">{formIk.errors["estimated_estate_assets"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="estate_has_ongoing_business"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Will this estate include an
                                            ongoing business?</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="estate_has_ongoing_business"
                                                           value={bondToEdit['estate_has_ongoing_business'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("estate_has_ongoing_business", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((input) => input.name === "estate_has_ongoing_business")[0].options}/>
                                        {formIk.errors["estate_has_ongoing_business"] && (
                                            <div
                                                className="text-error">{formIk.errors["estate_has_ongoing_business"]}</div>
                                        )}
                                    </Form.Group>

                                    {
                                        formIk.values['estate_has_ongoing_business'] === 'yes' &&
                                        <>
                                            <Form.Group key="type_of_business_in_operation"
                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'>
                                                    <strong>If YES, what type of business will be in operation</strong></Form.Label>
                                                <CustomTextInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                 required={false} name="type_of_business_in_operation"
                                                                 className={"form-control"}
                                                                 id="type_of_business_in_operation-input"
                                                                 value={bondToEdit['type_of_business_in_operation']}
                                                                 onChange={(value) => {
                                                                     formIk.setFieldValue("type_of_business_in_operation", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}/>
                                                {formIk.errors["type_of_business_in_operation"] && formIk.touched["type_of_business_in_operation"] && (
                                                    <div
                                                        className="text-error">{formIk.errors["type_of_business_in_operation"]}</div>
                                                )}
                                            </Form.Group>
                                        </>
                                    }

                                    <Form.Group key="personal_representative_indebited_to_estate"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Are any of the Personal
                                            Representatives indebted to the Estate?</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="personal_representative_indebited_to_estate"
                                                           value={bondToEdit['personal_representative_indebited_to_estate'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("personal_representative_indebited_to_estate", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((input) => input.name === "personal_representative_indebited_to_estate")[0].options}/>
                                        {formIk.errors["personal_representative_indebited_to_estate"] && (
                                            <div
                                                className="text-error">{formIk.errors["personal_representative_indebited_to_estate"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="decedent_execute_last_will_and_testament"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Did the Decedent execute a Last
                                            Will & Testament?</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="decedent_execute_last_will_and_testament"
                                                           value={bondToEdit['decedent_execute_last_will_and_testament'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("decedent_execute_last_will_and_testament", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((input) => input.name === "decedent_execute_last_will_and_testament")[0].options}/>
                                        {formIk.errors["decedent_execute_last_will_and_testament"] && (
                                            <div
                                                className="text-error">{formIk.errors["decedent_execute_last_will_and_testament"]}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="applicant_replace_any_previous_fiduciary_in_this_estate"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Does applicant replace any
                                            previous fiduciary in this Estate?</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="applicant_replace_any_previous_fiduciary_in_this_estate"
                                                           value={bondToEdit['applicant_replace_any_previous_fiduciary_in_this_estate'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("applicant_replace_any_previous_fiduciary_in_this_estate", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((input) => input.name === "applicant_replace_any_previous_fiduciary_in_this_estate")[0].options}/>
                                        {formIk.errors["applicant_replace_any_previous_fiduciary_in_this_estate"] && (
                                            <div
                                                className="text-error">{formIk.errors["applicant_replace_any_previous_fiduciary_in_this_estate"]}</div>
                                        )}
                                    </Form.Group>
                                    {
                                        formIk.values['applicant_replace_any_previous_fiduciary_in_this_estate'] === 'yes' && (
                                            <Form.Group key="previous_fiduciaries" className="mb-3"
                                                        controlId="formBasicEmail">
                                                <Form.Label><strong>If there have been previous fiduciaries, name them here
                                                    and
                                                    explain each one.</strong></Form.Label>
                                                <CustomLongInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                 required={false} name="previous_fiduciaries"
                                                                 className={"form-control"}
                                                                 id={"previous_fiduciaries-input"}
                                                                 value={bondToEdit['previous_fiduciaries']}
                                                                 onChange={(value) => {
                                                                     formIk.setFieldValue("previous_fiduciaries", value);
                                                                     setIsUpdateRequired(true);
                                                                 }}
                                                />
                                                {formIk.errors["previous_fiduciaries"] && formIk.touched["previous_fiduciaries"] && (
                                                    <div
                                                        className="text-error">{formIk.errors["previous_fiduciaries"]}</div>
                                                )}
                                            </Form.Group>
                                        )
                                    }

                                    <Form.Group key={"declined_by_another"}
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Is this bond declined by any
                                            other court?</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name={"declined_by_another"}
                                                           value={bondToEdit['declined_by_another'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("declined_by_another", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((li) => li.name === 'declined_by_another')[0].options}/>
                                        {formIk.errors["declined_by_another"] && (
                                            <div
                                                className="text-error">{formIk.errors['declined_by_another']}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key={"ever_bankrupt"}
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Ever
                                            bankrupt</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name={"ever_bankrupt"}
                                                           value={bondToEdit['ever_bankrupt'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("ever_bankrupt", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((li) => li.name === 'ever_bankrupt')[0].options}/>
                                        {formIk.errors["ever_bankrupt"] && (
                                            <div
                                                className="text-error">{formIk.errors['ever_bankrupt']}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key={"replace_previous_bond"}
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Does this bond replace any other
                                            bond?</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name={"replace_previous_bond"}
                                                           value={bondToEdit['replace_previous_bond'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("replace_previous_bond", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((li) => li.name === 'replace_previous_bond')[0].options}/>
                                        {formIk.errors["replace_previous_bond"] && (
                                            <div
                                                className="text-error">{formIk.errors['replace_previous_bond']}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key={"lawsuits_judgment_liens"}
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Lawsuits Judgment Liens</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name={"lawsuits_judgment_liens"}
                                                           value={bondToEdit['lawsuits_judgment_liens'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("lawsuits_judgment_liens", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((li) => li.name === 'lawsuits_judgment_liens')[0].options}/>
                                        {formIk.errors["lawsuits_judgment_liens"] && (
                                            <div
                                                className="text-error">{formIk.errors['lawsuits_judgment_liens']}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key={"any_disputes"}
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Any
                                            Disputes</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name={"any_disputes"}
                                                           value={bondToEdit['any_disputes'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("any_disputes", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((li) => li.name === 'any_disputes')[0].options}/>
                                        {formIk.errors["any_disputes"] && (
                                            <div
                                                className="text-error">{formIk.errors['any_disputes']}</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="is_required_on_demand"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label className='required-label'><strong>Is this bond required on the
                                            demand of an interested party other than the Court?</strong></Form.Label>
                                        <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                           name="is_required_on_demand"
                                                           value={bondToEdit['is_required_on_demand'] === true ? 'yes' : 'no'}
                                                           onChange={(value) => {
                                                               formIk.setFieldValue("is_required_on_demand", value);
                                                               setIsUpdateRequired(true);
                                                           }}
                                                           options={normalInputs.filter((input) => input.name === "is_required_on_demand")[0].options}/>
                                        {formIk.errors["is_required_on_demand"] && (
                                            <div className="text-error">{formIk.errors["is_required_on_demand"]}</div>
                                        )}
                                    </Form.Group>

                                    {
                                        formIk.values['is_required_on_demand'] === 'yes' &&
                                        <Form.Group key="interested_parties" className="mb-3"
                                                    controlId="formBasicEmail">
                                            <Form.Label className='required-label'>
                                                <strong>If YES, please list interested parties and give a brief
                                                    explanation.</strong></Form.Label>
                                            <CustomLongInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                             required={false} name="interested_parties"
                                                             className={"form-control"} id={"interested_parties-input"}
                                                             value={bondToEdit['interested_parties']}
                                                             onChange={(value) => {
                                                                 formIk.setFieldValue("interested_parties", value);
                                                                 setIsUpdateRequired(true);
                                                             }}
                                            />
                                            {formIk.errors["interested_parties"] && formIk.touched["interested_parties"] && (
                                                <div className="text-error">{formIk.errors["interested_parties"]}</div>
                                            )}
                                        </Form.Group>
                                    }
                                    {
                                        formIk.values.bond_amount > 50000 && (
                                            <Form.Group key="e_signed_indemnity"
                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                        controlId="formBasicEmail">
                                                <Form.Label className='required-label'><strong>Do you want an E-signed
                                                    indemnity form?</strong></Form.Label>
                                                <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                   name="e_signed_indemnity"
                                                                   value={bondToEdit['e_signed_indemnity'] === true ? 'yes' : 'no'}
                                                                   onChange={(value) => {
                                                                       formIk.setFieldValue("e_signed_indemnity", value);
                                                                       setIsUpdateRequired(true);
                                                                   }}
                                                                   options={normalInputs.filter((input) => input.name === "e_signed_indemnity")[0].options}
                                                />
                                                <div className="text-error">{formIk.errors["e_signed_indemnity"]}</div>
                                            </Form.Group>
                                        )
                                    }
                                    {
                                        <Form.Group key="law_firm"
                                                    className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                    controlId="formBasicEmail">
                                            <Form.Label id="law-firm-input" className='required-label'><strong>Link to
                                                Law Firm on Bond Request</strong></Form.Label>
                                            {bondToEdit.guest_law_firm_name && <p className='text-green-700 text-md'>Law Firm Entered by Guest User: <span className='text-gray-800 font-semibold'>{bondToEdit.guest_law_firm_name}</span></p>}
                                            <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                               name="law_firm"
                                                               value={bondToEdit['law_firm'] ? bondToEdit['law_firm']['id'] : null}
                                                               onChange={(value) => {
                                                                   formIk.setFieldValue("law_firm", value);
                                                                   setIsUpdateRequired(true);
                                                               }}
                                                               options={lawFirms}/>
                                            {formIk.errors["law_firm"] && (
                                                <div className="text-error">{formIk.errors["law_firm"]}</div>
                                            )}
                                        </Form.Group>
                                    }

                                    {
                                        formIk.values['law_firm'] !== "" &&
                                        <Form.Group key="law_firm_address"
                                                    className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                    controlId="formBasicEmail">
                                            <Form.Label className='required-label' id="law-firm-address-input"><strong>Linked Law Firm
                                                Address</strong></Form.Label>
                                                {bondToEdit.guest_law_firm_address && <p className='text-green-700 text-md'>Law Firm Address Entered by Guest User: <span className='text-gray-800 font-semibold'>{bondToEdit.guest_law_firm_address}</span></p>}
                                            <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                               name="law_firm_address"
                                                               value={bondToEdit['law_firm_address'] ? bondToEdit['law_firm_address']['id'] : null}
                                                               onChange={(value) => {
                                                                   formIk.setFieldValue("law_firm_address", value);
                                                                   setIsUpdateRequired(true);
                                                               }}
                                                               options={lawFirmAddresses[formIk.values['law_firm']]}/>
                                            {formIk.errors["law_firm_address"] && (
                                                <div className="text-error">{formIk.errors["law_firm_address"]}</div>
                                            )}
                                        </Form.Group>
                                    }

                                    {
                                        formIk.values['law_firm'] !== "" &&
                                        <Form.Group key="contact"
                                                    className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                    controlId="formBasicEmail">
                                            <Form.Label className='required-label' id="law-firm-contact-input"><strong>Attorney of
                                                Record</strong></Form.Label>
                                                {bondToEdit.guest_law_firm_contact && <p className='text-green-700 text-md'>Law Firm Attorney Entered by Guest User: <span className='text-gray-800 font-semibold'>{bondToEdit.guest_law_firm_contact}</span></p>}
                                            <CustomSelectInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                               name="contact"
                                                               value={bondToEdit['contact'] ? bondToEdit['contact']['id'] : null}
                                                               onChange={(value) => {
                                                                   formIk.setFieldValue("contact", value);
                                                                   setIsUpdateRequired(true);
                                                               }}
                                                               options={lawFirmContacts[formIk.values['law_firm']]}/>
                                            {formIk.errors["contact"] && (
                                                <div className="text-error">{formIk.errors["contact"]}</div>
                                            )}
                                        </Form.Group>
                                    }

                                    <Form.Group key="copy_of_court_order"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label><strong>Court Order</strong></Form.Label>
                                        <a href={bondToEdit['copy_of_court_order_link']}>{bondToEdit['copy_of_court_order_link'] !== "" && bondToEdit['copy_of_court_order_link'] !== null && bondToEdit['copy_of_court_order_link'] !== undefined ? bondToEdit['court_order_file_name'] : "No file was provided"}
                                        </a>
                                        <input type="file" disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                               accept="application/pdf" name="copy_of_court_order"
                                               onChange={(event) => {
                                                   formIk.setFieldValue("copy_of_court_order", event.currentTarget.files[0]);
                                                   setIsUpdateRequired(true);
                                               }}/>
                                        {
                                            formIk.errors["copy_of_court_order"] &&
                                            <div className="text-error">{formIk.errors["copy_of_court_order"]}</div>
                                        }
                                    </Form.Group>
                                    {
                                        formIk.values['decedent_execute_last_will_and_testament'] === 'yes' &&
                                        <Form.Group key="copy_of_last_will_and_testament"
                                                    className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                    controlId="formBasicEmail"
                                        >
                                            <Form.Label
                                                className={formIk.values['bond_amount'] >= 1000000 ? 'required-label' : ''}
                                                style={{display: "inline-block"}}>
                                                <strong>Last Will & Testament</strong></Form.Label>
                                            <a href={bondToEdit['copy_of_last_will_and_testament_link']}>
                                                {
                                                    bondToEdit['copy_of_last_will_and_testament_link'] !== "" &&
                                                    bondToEdit['copy_of_last_will_and_testament_link'] !== null &&
                                                    bondToEdit['copy_of_last_will_and_testament_link'] !== undefined ? bondToEdit['last_will_and_testament_file_name'] : "No file was provided"

                                                }
                                            </a>
                                            <input type="file" disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                   accept="application/pdf" name="copy_of_last_will_and_testament"
                                                   onChange={(event) => {
                                                       formIk.setFieldValue("copy_of_last_will_and_testament", event.currentTarget.files[0]);
                                                       setIsUpdateRequired(true);
                                                   }}/>
                                            {
                                                formIk.errors["copy_of_last_will_and_testament"] &&
                                                <div
                                                    className="text-error">{formIk.errors["copy_of_last_will_and_testament"]}</div>
                                            }
                                        </Form.Group>
                                    }
                                    <Form.Group key="other_document"
                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                controlId="formBasicEmail">
                                        <Form.Label>
                                            <strong>Other Document</strong></Form.Label>
                                        <a href={bondToEdit['other_document_link']}>
                                            {
                                                bondToEdit['other_document_link'] !== "" && bondToEdit['other_document_link'] !== null &&
                                                bondToEdit['other_document_link'] !== undefined ? bondToEdit['other_document_file_name'] : "No file was provided"
                                            }
                                        </a>
                                        <input type="file" disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                               accept="application/pdf" name="other_document"
                                               onChange={(event) => {
                                                   formIk.setFieldValue("other_document", event.currentTarget.files[0]);
                                                   setIsUpdateRequired(true);
                                               }}/>
                                        {
                                            formIk.errors["copy_of_court_order"] &&
                                            <div className="text-error">{formIk.errors["other_document"]}</div>
                                        }
                                    </Form.Group>
                                    <Form.Group key={formIk.errors["user_comment"]}
                                                className="col-12 editBondStyle" controlId="formBasicEmail">
                                        <Form.Label style={{display: "block"}}><strong>Note</strong></Form.Label>
                                        <CustomLongInput disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                         required={false} className="border-5"
                                                         name="user_comment" id="user_comment-input"
                                                         value={formIk.values['user_comment']} onChange={(value) => {
                                            formIk.setFieldValue("user_comment", value);
                                            setIsUpdateRequired(true);
                                        }}/>
                                        <div className="text-error">{formIk.errors["user_comment"]}</div>
                                    </Form.Group>
                                </>
                            }
                            {
                                formIk.values.personal_representative.length > 0 &&
                                <>
                                    {
                                        formIk.values.personal_representative.map((pr, index) => {
                                            return (
                                                <>
                                                    <h3 style={{marginBottom: "0px", marginTop: "1rem"}}>Personal
                                                        Representative {index + 1}</h3>
                                                    <Form.Group key={"name-" + index}
                                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                controlId="formBasicEmail"
                                                    >
                                                        <Form.Label className='required-label'><strong>Name of Personal
                                                            Rep</strong></Form.Label>
                                                        <CustomTextInput
                                                            disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                            required={true}
                                                            name={"personal_representative[" + index + "].name"}
                                                            className={"form-control"}
                                                            id={"personal_representative[" + index + "].name-input"}
                                                            value={pr['name']} onChange={(value) => {
                                                            delete formIk.errors["personal_representative"];
                                                            formIk.setFieldValue("personal_representative[" + index + "].name", value);
                                                            setIsUpdateRequired(true);
                                                        }}/>
                                                        {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                            <div
                                                                className="text-error">{formIk.errors["personal_representative"][index]['name']}</div>
                                                        )}
                                                    </Form.Group>
                                                    {
                                                        formIk.values.e_signed_indemnity === 'yes' && (
                                                            <Form.Group key={"email-" + index}
                                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                        controlId="formBasicEmail"
                                                            >
                                                                <Form.Label
                                                                    className='required-label'><strong>Email</strong></Form.Label>
                                                                <CustomTextInput
                                                                    disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                    required={true}
                                                                    name={"personal_representative[" + index + "].email"}
                                                                    className={"form-control"}
                                                                    id={"personal_representative[" + index + "].email-input"}
                                                                    value={pr['email']} onChange={(value) => {
                                                                    formIk.setFieldValue("personal_representative[" + index + "].email", value);
                                                                    setIsUpdateRequired(true);
                                                                }}/>
                                                                {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length >= 1 && formIk.errors["personal_representative"][index] !== undefined && (
                                                                    <div
                                                                        className="text-error">{formIk.errors["personal_representative"][index]['email']}</div>
                                                                )}
                                                            </Form.Group>
                                                        )
                                                    }
                                                    <Form.Group key={"is_us_citizen-" + index}
                                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                controlId="formBasicEmail">
                                                        <Form.Label className='required-label'><strong>Is US
                                                            Citizen</strong></Form.Label>
                                                        <CustomSelectInput
                                                            disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                            name={"personal_representative[" + index + "].is_us_citizen"}
                                                            value={pr['is_us_citizen']}
                                                            onChange={(value) => {
                                                                formIk.setFieldValue("personal_representative[" + index + "].is_us_citizen", value);
                                                                delete formIk.errors["personal_representative"];
                                                                if (value === 'no') {
                                                                    formIk.setFieldValue("personal_representative[" + index + "].city", "");
                                                                    formIk.setFieldValue("personal_representative[" + index + "].state", "");
                                                                }
                                                                setIsUpdateRequired(true);
                                                            }}
                                                            options={lineItemInputs.filter((li) => li.name === 'is_us_citizen')[0].options}/>
                                                        {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                            <div
                                                                className="text-error">{formIk.errors["personal_representative"][index]['is_us_citizen']}</div>
                                                        )}
                                                    </Form.Group>
                                                    {
                                                        bondToEdit.is_approved === 'IN_PROCESS' && formIk.values["personal_representative"][index]['is_us_citizen'] === 'yes' && (
                                                            <Row className="editBondStyle">
                                                                <GoogleAutoComplete placeholder="Search Address..."
                                                                                    callback={(address, city, state, zip_code) => {
                                                                                        addressAutoFillCallBack(address, city, state, zip_code, index);
                                                                                        setIsUpdateRequired(true);
                                                                                    }}/>
                                                            </Row>
                                                        )
                                                    }
                                                    <Form.Group key={"address-" + index}
                                                                className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                controlId="formBasicEmail"
                                                    >
                                                        <Form.Label className='required-label'><strong>Street Address
                                                            for Personal Rep</strong></Form.Label>
                                                        <CustomTextInput
                                                            disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                            required={true}
                                                            name={"personal_representative[" + index + "].address"}
                                                            className={"form-control"}
                                                            id={"personal_representative[" + index + "].address-input"}
                                                            value={pr['address']} onChange={(value) => {
                                                            delete formIk.errors["personal_representative"];
                                                            formIk.setFieldValue("personal_representative[" + index + "].address", value);
                                                            setIsUpdateRequired(true);
                                                        }}/>
                                                        {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                            <div
                                                                className="text-error">{formIk.errors["personal_representative"][index]['address']}</div>
                                                        )}
                                                    </Form.Group>
                                                    {
                                                        formIk.values["personal_representative"][index]['is_us_citizen'] === 'yes' && (
                                                            <Form.Group key={"city-" + index}
                                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label className='required-label'><strong>City/Town for
                                                                    Personal Rep</strong></Form.Label>
                                                                <CustomTextInput
                                                                    disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                    required={true}
                                                                    name={"personal_representative[" + index + "].city"}
                                                                    className={"form-control"}
                                                                    id={"personal_representative[" + index + "].city-input"}
                                                                    value={pr['city']} onChange={(value) => {
                                                                    delete formIk.errors["personal_representative"];
                                                                    formIk.setFieldValue("personal_representative[" + index + "].city", value);
                                                                    setIsUpdateRequired(true);
                                                                }}/>
                                                                {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                                    <div
                                                                        className="text-error">{formIk.errors["personal_representative"][index]['city']}</div>
                                                                )}
                                                            </Form.Group>
                                                        )
                                                    }
                                                    {
                                                        formIk.values["personal_representative"][index]['is_us_citizen'] === 'yes' && (
                                                            <Form.Group key={"state-" + index}
                                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label
                                                                    className='required-label'><strong>State/Province/Region</strong></Form.Label>
                                                                <CustomSelectInput
                                                                    disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                    name={"personal_representative[" + index + "].state"}
                                                                    value={pr['state']}
                                                                    onChange={(value) => {
                                                                        delete formIk.errors["personal_representative"];
                                                                        formIk.setFieldValue("personal_representative[" + index + "].state", value);
                                                                        setIsUpdateRequired(true);
                                                                    }}
                                                                    options={lineItemInputs.filter((li) => li.name === 'state')[0].options}/>
                                                                {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                                    <div
                                                                        className="text-error">{formIk.errors["personal_representative"][index]['state']}</div>
                                                                )}
                                                            </Form.Group>
                                                        )
                                                    }
                                                    {
                                                        formIk.values["personal_representative"][index]['is_us_citizen'] === 'yes' && (
                                                            <Form.Group key={"zip_code-" + index}
                                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label className='required-label'><strong>ZIP/Postal
                                                                    Code</strong></Form.Label>
                                                                <ZipCode disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                         required={true} name="zip_code"
                                                                         className={"form-control"} id="zip_code-input"
                                                                         value={pr['zip_code']}
                                                                         onChange={(value) => {
                                                                             delete formIk.errors["personal_representative"];
                                                                             formIk.setFieldValue("personal_representative[" + index + "].zip_code", value);
                                                                             setIsUpdateRequired(true);
                                                                         }}/>
                                                                {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                                    <div
                                                                        className="text-error">{formIk.errors["personal_representative"][index]['zip_code']}</div>
                                                                )}
                                                            </Form.Group>
                                                        )
                                                    }
                                                    {
                                                        formIk.values["personal_representative"][index]['is_us_citizen'] === 'no' && (
                                                            <Form.Group key={"citizenship-" + index}
                                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label className='required-label'><strong>If NO, what
                                                                    is their citizenship or primary
                                                                    residency?</strong></Form.Label>
                                                                <CustomSelectInput
                                                                    disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                    name={"personal_representative[" + index + "].citizenship"}
                                                                    value={pr['citizenship']}
                                                                    onChange={(value) => {
                                                                        formIk.setFieldValue("personal_representative[" + index + "].citizenship", value);
                                                                        delete formIk.errors["personal_representative"];
                                                                        setIsUpdateRequired(true);
                                                                    }}
                                                                    options={lineItemInputs.filter((li) => li.name === 'citizenship')[0].options}/>
                                                                {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                                    <div
                                                                        className="text-error">{formIk.errors["personal_representative"][index]['citizenship']}</div>
                                                                )}
                                                            </Form.Group>
                                                        )
                                                    }
                                                    {
                                                        formIk.values["personal_representative"][index]['citizenship'] === 'Others' && (
                                                            <Form.Group key={"other_country-" + index}
                                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label className='required-label'><strong>Other Country
                                                                    for Personal Rep</strong></Form.Label>
                                                                <CustomSelectInput
                                                                    disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                    name={"personal_representative[" + index + "].other_country"}
                                                                    value={pr['other_country']}
                                                                    onChange={(value) => {
                                                                        delete formIk.errors["personal_representative"];
                                                                        formIk.setFieldValue("personal_representative[" + index + "].citizenship", value);
                                                                        setIsUpdateRequired(true);
                                                                    }}
                                                                    options={lineItemInputs.filter((li) => li.name === 'other_country')[0].options}/>
                                                                {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                                    <div
                                                                        className="text-error">{formIk.errors["personal_representative"][index]['other_country']}</div>
                                                                )}
                                                            </Form.Group>
                                                        )
                                                    }
                                                    {
                                                        (formIk.values["personal_representative"][index]['is_us_citizen'] === 'yes' && formIk.values['bond_amount'] >= 250000) && (
                                                            <Form.Group key={"zip_code-" + index}
                                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label className='required-label'><strong>If YES to US
                                                                    Citizen, what is their Social Security Number?</strong></Form.Label>
                                                                <PatternFormat className={"form-control"}
                                                                               name={"personal_representative[" + index + "].ssn"}
                                                                               id={"ssn-input-" + index}
                                                                               defaultValue={pr['ssn']}
                                                                               format="###-##-####" allowEmptyFormatting
                                                                               mask="#"
                                                                               disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                               onChange={(value) => {
                                                                                   delete formIk.errors["personal_representative"];
                                                                                   formIk.setFieldValue("personal_representative[" + index + "].ssn", value.target.value);
                                                                                   setIsUpdateRequired(true);
                                                                               }}/>
                                                                {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                                    <div
                                                                        className="text-error">{formIk.errors["personal_representative"][index]['ssn']}</div>
                                                                )}
                                                            </Form.Group>
                                                        )
                                                    }
                                                    {
                                                        formIk.values['bond_amount'] > 50000 && (
                                                            <Form.Group key={"occupation-" + index}
                                                                        className={has_history === true ? "col-12 editBondStyle" : "col-6 editBondStyle"}
                                                                        controlId="formBasicEmail">
                                                                <Form.Label><strong>Occupation of Personal
                                                                    Rep</strong></Form.Label>
                                                                <CustomTextInput
                                                                    disabled={bondToEdit.is_approved !== 'IN_PROCESS'}
                                                                    required={false}
                                                                    name={"personal_representative[" + index + "].occupation"}
                                                                    className={"form-control"}
                                                                    id={"personal_representative[" + index + "].occupation-input"}
                                                                    value={pr['occupation']} onChange={(value) => {
                                                                    delete formIk.errors["personal_representative"];
                                                                    formIk.setFieldValue("personal_representative[" + index + "].occupation", value);
                                                                    setIsUpdateRequired(true);
                                                                }}/>
                                                                {formIk.errors.hasOwnProperty("personal_representative") && formIk.errors["personal_representative"].length > 0 && formIk.errors["personal_representative"][index] && (
                                                                    <div
                                                                        className="text-error">{formIk.errors["personal_representative"][index]['occupation']}</div>
                                                                )}
                                                            </Form.Group>
                                                        )
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }
                        </Form>
                    </Col>
                </Row>
                <div style={{textAlign: "right", marginTop: "1%", padding: "1%"}}>
                    {
                        bondToEdit.is_approved === 'IN_PROCESS' && (
                            <>
                                {
                                    hasUserPermission(user_permission, "show_approve_bond_button") && !isUpdateRequired &&
                                    <button style={{marginRight: "0.5%"}} onClick={async (event) => {
                                        event.preventDefault();
                                        console.log(formIk.values, formIk.errors);
                                        // // const submit_result = await formIk.submitForm();
                                        // if (submit_result !== false && Object.keys(formIk.errors).length === 0) {
                                        //     approveBond();
                                        // }
                                        approveBond();
                                    }} className="btn btn-primary">Approve</button>
                                }
                                <button className="btn btn-success" style={{marginRight: "0.5%"}}
                                        onClick={(event) => {

                                            formIk.submitForm();
                                        }}>Update
                                </button>
                                {
                                    hasUserPermission(user_permission, "show_reject_bond_button") && !isUpdateRequired &&
                                    <button style={{marginRight: "0.5%"}} onClick={(event) => {
                                        event.preventDefault();
                                        rejectBond();
                                    }} className="btn btn-warning">Reject</button>
                                }
                            </>
                        )
                    }
                    <button style={{marginRight: "0.5%"}} className="btn btn-danger" onClick={(e) => {
                        e.preventDefault();
                        props.closeCallBack();
                    }}>Close
                    </button>
                </div>
            </LoadingOverlay>
        </div>
    )
}

BondEditForm.propTypes = {
    bond_id: PropTypes.number.isRequired,
    closeCallBack: PropTypes.func.isRequired,
    successCallBack: PropTypes.func.isRequired,
    failCallBack: PropTypes.func.isRequired,
};


export default BondEditForm;
