import React, { useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import { addLawFirmContactBackend, updateLawFirmContactBackend } from "../../services/company_management";
import { addLawFirmContact, updateLawFirmContact } from "../../store/company_management";
import {addNewLawFirmContact, removeLawFirmData} from "../../store/bond";
import {month_opts} from "../../constants";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomTextInput from "../../global_components/inputs/text";
import CustomMultiSelectInput from "../../global_components/inputs/multi-select-field";
import USPhoneNumberWithoutCountryCodeUniversal from "../../global_components/inputs/us_phone_number_without_country_code";
import {hasUserPermission} from "../../utils/user_permission";


export default function LawFirmContactPopup(props) {
    const dispatch = useDispatch();
    const lawFirmContactsInStore = useSelector((state) => state.companyManagement.law_firm_contacts);
    const lawFirmsInStore = useSelector((state) => state.companyManagement.law_firms);
    const lawSchoolsInStore = useSelector((state) => state.companyManagement.law_schools);
    const [loading, setLoading] = useState(false);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const isValidate = hasUserPermission(user_permission,'ignore_validation') ;
    let law_firm_contact_details = {};
    if(props.law_firm_contact_id !== 0){
        law_firm_contact_details = lawFirmContactsInStore.filter((law_firm_contact) => law_firm_contact.id === props.law_firm_contact_id)[0];
    }

        
    const getLawFirmOptions = () => {
        let lawFirmOpts = [];
        lawFirmsInStore.map((law_firm)=> lawFirmOpts.push({label: `${law_firm.name}`, value: law_firm.id}))
        return lawFirmOpts;
    }

    const getLawSchoolOptions = () => {
        let lawSchoolOpts = [];
        lawSchoolsInStore.map((law_school)=> lawSchoolOpts.push({label: `${law_school.name}`, value: law_school.id}))
        return lawSchoolOpts;
    }


    const getInitialValues = () => {
        if (props.law_firm_contact_id !== 0){
            return {full_name: law_firm_contact_details.full_name, preferred_name: law_firm_contact_details.preferred_name, 
                email: law_firm_contact_details.email, phone_number: law_firm_contact_details.phone_number,
                law_firm: law_firm_contact_details.law_firm,
                law_school: law_firm_contact_details.law_school,
                role: law_firm_contact_details.role, bar: law_firm_contact_details.bar,
                is_active: law_firm_contact_details.is_active}
        }else{
            return {full_name: '', preferred_name: '', email: '', phone_number: '', law_firm: [], law_school: '', role: '', 
                bar: '', is_active: true}
        }
    }

    const getValidation = () => {
        if (!isValidate){
            return Yup.object({
                full_name: Yup.string().required('FullName is required.'),
                preferred_name: Yup.string().nullable(),
                email: Yup.string().matches(/\S+@\S+\.\S+/ , 'Please enter a valid email.').required('Email is required.'),
                phone_number: Yup.string().required('Phone number is required.').matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378"),
                law_firm: Yup.array().of(Yup.number()).min(1, "Law Firm is required."),
                law_school: Yup.number().when("role", {
                    is: val => (val && val === 'ATTORNEY'),
                    then: Yup.number().required("Law school is required.")
                }),
                role: Yup.string().oneOf(['ATTORNEY', 'PARALEGAL', 'LEGAL ASSISTANT', 'CLERICAL'], 'Please select a valid role.').required('Role of contact is required.'),
                bar: Yup.string().when("role", {
                    is: val => (val && val === 'ATTORNEY'),
                    then: Yup.string().required("BAR number is required.")
                }),
                is_active: Yup.boolean().oneOf([true, false], 'Active status is required.'),
            })
        }else{
            return Yup.object({
                full_name: Yup.string().required('FullName is required.'),
                preferred_name: Yup.string().nullable(),
                email: Yup.string().matches(/\S+@\S+\.\S+/ , 'Please enter a valid email.').nullable(),
                phone_number: Yup.string().matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378").nullable(),
                law_firm: Yup.array().of(Yup.number()).min(1, "Law Firm is required."),
                law_school: Yup.number().nullable(),
                role: Yup.string().oneOf(['ATTORNEY', 'PARALEGAL', 'LEGAL ASSISTANT', 'CLERICAL'], 'Please select a valid role.').nullable(),
                bar: Yup.string().nullable(),
            })
        }
    }

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            if (props.law_firm_contact_id!==0){
                await updateLawFirmContactBackend(props.law_firm_contact_id, values).then((res)=>{
                    if(res === 200){
                        props.showSuccessAlert(true);
                        props.setSuccessMessage('Law Firm Contact updated successfully');
                        dispatch(updateLawFirmContact({
                                            id: props.law_firm_contact_id, full_name: values.full_name, 
                                            preferred_name: values.preferred_name, email: values.email, 
                                            phone_number: values.phone_number,
                                            law_firm: values.law_firm, law_school: values.law_school, role: values.role, 
                                            bar: values.bar, birth_month: values.birth_month, birth_day: values.birth_day, 
                                            is_active: values.is_active
                                        }))
                            dispatch(removeLawFirmData());

                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(0);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(0);
                })
            }else{
                await addLawFirmContactBackend(values).then((res)=>{
                    if (res.status === 201){
                        const data = res.data;
                        props.showSuccessAlert(true);
                        props.setSuccessMessage('Law Firm Contact created successfully.');
                        dispatch(addLawFirmContact(data))
                        console.log(values.role)
                        if(values.role === 'ATTORNEY'){
                            dispatch(addNewLawFirmContact({id: data.id, name: data.full_name, law_firms: values.law_firm}))
                        }
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(false);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(false);
                })
            }
        },
        validationSchema:getValidation()
    });
    const [isActiveCheckbox, setIsActiveCheckbox] = useState(props.law_firm_contact_id !== 0 ? law_firm_contact_details.is_active : true);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Law Firm Contact
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key="full_name" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className='required-label'><strong>Full Name</strong>
                            </Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='full_name'
                                          defaultValue={props.law_firm_contact_id !== 0 ? law_firm_contact_details.full_name : ''}/>
                            {formIk.errors['full_name'] && formIk.touched['full_name'] && (
                                <div className="text-error">{formIk.errors['full_name']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="preferred_name" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label><strong>Preferred Name</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='preferred_name'
                                          defaultValue={props.law_firm_contact_id !== 0 ? law_firm_contact_details.preferred_name : ''}/>
                            {formIk.errors['preferred_name'] && formIk.touched['preferred_name'] && (
                                <div className="text-error">{formIk.errors['preferred_name']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key="email" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={!isValidate ? 'required-label' : ''}>
                                <strong>Email</strong>
                            </Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                          name='email' defaultValue={props.law_firm_contact_id!==0 ? law_firm_contact_details.email : ''}/>
                            {formIk.errors['email'] && formIk.touched['email'] && (
                                <div className="text-error">{formIk.errors['email']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="phone_number" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={!isValidate ? 'required-label' : ''}>
                                <strong>Phone Number</strong>
                            </Form.Label>
                            {/*<Form.Control type="text" size="sm" onBlur={formIk.handleBlur}*/}
                            {/*              onChange={formIk.handleChange}*/}
                            {/*              name='phone_number'*/}
                            {/*              defaultValue={formIk.values.phone_number}/>*/}
                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                                      className="form-control"
                                                                      name="phone_number" id="phone_number-input"
                                                                      value={props.law_firm_contact_id !== 0 ? law_firm_contact_details.phone_number : ''}
                                                                      onChange={(val) => {
                                                                          if(val === "(___) ___ ____"){
                                                                              val = "";
                                                                          }
                                                                          formIk.setFieldValue('phone_number', val);
                                                                      }}
                            />
                            {formIk.errors['phone_number'] && (
                                <div className="text-error">{formIk.errors["phone_number"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key="law_firm" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className='required-label'><strong>Law Firms</strong></Form.Label>

                            <CustomMultiSelectInput
                                disabled={false}
                                name="law_firm"
                                onChange={
                                    (value)=>formIk.setFieldValue('law_firm', value)
                                }
                                options={getLawFirmOptions()}
                                value={props.law_firm_contact_id !== 0 ? law_firm_contact_details.law_firm : []}
                            />
                            {formIk.errors['law_firm'] && (
                                <div className="text-error">{formIk.errors['law_firm']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key={"role"} className="col-12"
                                    controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={!isValidate ? 'required-label' : ''}>
                                <strong>Role</strong>
                            </Form.Label>
                            <CustomSelectInput disabled={false} name={"role"}
                                                value={formIk.values.role}
                                                onChange={(value)=>{formIk.setFieldValue("role", value);}}
                                                options={[{value: 'ATTORNEY', label: 'Attorney'}, {value: 'PARALEGAL', label: 'Paralegal'},
                                                    {value: 'LEGAL ASSISTANT', label: 'Legal Assistant'}, {value: 'CLERICAL', label: 'Clerical'}]}/>
                            {formIk.errors["role"] && (
                                <div className="text-error">{formIk.errors["role"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                    {
                        formIk.values.role === "ATTORNEY" ? (
                            <Col>
                                <Form.Group key={"bar"} className="col-12"
                                            controlId="formBasicEmail"
                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                    <Form.Label className={!isValidate ? 'required-label' : ''}>
                                        <strong>BAR Association Number</strong>
                                    </Form.Label>
                                    <CustomTextInput disabled={false} required={true} className="form-control"
                                                        name={"bar"}
                                                        id={"bar-input"} value={formIk.values.bar}
                                                        onChange={(value)=>formIk.setFieldValue("bar", value)}
                                                        onBlur={formIk.handleBlur}/>
                                    {formIk.errors["bar"] && formIk.touched["bar"] && (
                                        <div className="text-error">{formIk.errors["bar"]}</div>
                                    )}
                                </Form.Group>
                            </Col>
                        ) : (
                            <Col></Col>
                        )
                    }
                </Row>
                {
                    formIk.values.role === "ATTORNEY" && (
                        <Row>
                            <Col>
                                <Form.Group key={"law_school"} className="col-12"
                                            controlId="formBasicEmail"
                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                    <Form.Label className={!isValidate ? 'required-label' : ''}>
                                        <strong>Law School</strong>
                                    </Form.Label>
                                    <CustomSelectInput disabled={false} name={"law_school"}
                                                        value={formIk.values.law_school}
                                                        onChange={(value)=>{formIk.setFieldValue("law_school", value);}}
                                                        options={getLawSchoolOptions()}/>
                                    {formIk.errors["law_school"] && (
                                        <div className="text-error">{formIk.errors["law_school"]}</div>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                }
                {props.law_firm_contact_id !== 0 &&
                    <Row>
                        <Col>
                            <Form.Group key={'is_active'} controlId="formFileSm" className="mb-3">
                                <Form.Label className={'required-label'}><strong>Active</strong></Form.Label>
                                <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                        onChange={() => {
                                            {
                                                formIk.setFieldValue('is_active', !isActiveCheckbox);
                                                setIsActiveCheckbox(!isActiveCheckbox);
                                            }
                                        }}
                                        name='is_active' checked={isActiveCheckbox}/>
                                {formIk.errors['is_active'] && formIk.touched['is_active'] && (
                                    <div className="text-error">{formIk.errors['is_active']}</div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => {
                            console.log(formIk.values)
                            formIk.submitForm();
                        }}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

LawFirmContactPopup.propTypes = {
    law_firm_contact_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    showSuccessAlert: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    showFailureAlert: PropTypes.func.isRequired,
    setFailureMessage: PropTypes.func.isRequired,
}
