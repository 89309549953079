import '../../../styles/login.css';
import CusNavBar from "../../../global_components/navbar";
import {Card, CardContent, CircularProgress, Grid} from "@mui/material";
import Footer from "../../../global_components/footer";
import AddEditUserComp from "./add_edit_user"
import UserLawFirms from "./user_law_firms"
import {Row, Col} from "react-bootstrap";
import {API_URL} from "../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {log} from "../../../utils/logger";
import {useLocation, useNavigate} from "react-router";
import {
    addVermostLawFirmBackend,
    addVermostUserBackend,
    addVermostUserLawFirm,
    deleteUserLawFirmBackend, editVermostLawFirmBackend,
    editVermostUserLawFirm,
    getUserLawFirms,
    getVermostLawFirms, getVermostUsersPagination,
    updateVermostUserBackend
} from "../../../services/company_management";
import ActiveAccountManagementPopup from "../../popups/vermost/account_management_active_popup";
import {addLawFirms, addUserLawFirms, addLawFirm, updateLawFirm} from "../../../store/company_management";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import GoBack from "../../../utils/go_back_button";
import {hasUserPermission} from "../../../utils/user_permission";
import AddLawFirmPopup from "../vermost_popups/add_law_firm_popup";
import Header from "../../../global_components/header";
import {exportToExcel} from "../../../utils/excel_export";
import UserLawFirmInsertPopup from "../vermost_popups/add_edit_user_lawfirm_popup";



const BaseAddEditUser = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userRole, setUserRole] = React.useState("");
    const routeParams = useParams();
    const [loading, setLoading] = React.useState(true);
    const [tableLoading, setTableLoading] = React.useState(true);
    const [user, setUser] = React.useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [lawFirmEditID, setLawFirmEditID] = useState(0);
    const [editID, setEditID] = useState(0);
    const [showInsertPopup, setShowInsertPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const lawFirmList = useSelector((state) => state.companyManagement.law_firms);

    const goBack = () => {
        navigate(-1); // This will navigate back to the previous page
    };

    const ActionType = {
        edit: 'edit',
        insert: 'insert',
        delete: 'delete',
        download: 'download',
        refresh: 'refresh',
        new_user: 'new_user',
        create_law_firm: 'create_law_firm',
        default: 'default'
    }
    // Datagrid Pagination
    if (!localStorage.getItem("user_law_firms_pagesize")) {
        localStorage.setItem("user_law_firms_pagesize", 10);
    }
    const [controller, setController] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("user_law_firms_pagesize"))
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);
    // Datagrid Filter
    const [rowCount, setRowCount] = useState(0);
    const [queryOptions, setQueryOptions] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptions({filterModel: {...filterModel.items}}), 1000);
    }, []);
    const [prevFilter, setPrevFilter] = useState("");
    //Datagrid Sort
    const [queryOptionsSort, setQueryOptionsSort] = useState({});
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSort({sortModel: [...sortModel]});
    }, []);
    const [prevSort, setPrevSort] = useState("");
    const [lawFirms, setLawFirms] = useState([]);
    const [deleteLawFirmId, setDeleteLawFirmId] = useState(0);
    const [deleteText, setDeleteText] = useState('');
    const {enqueueSnackbar} = useSnackbar();
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');
    const hasAddPermission = hasUserPermission(user_permission, 'show_table_add_button')
    const [showSearchLawFirm, setShowSearchLawFirm] = useState(false);
    const [tempCreatedLawFirm, setTempCreatedLawFirm] = useState(0);

    let isInternalFlow = localStorage.getItem("isInternalFlow") === "true";

    const loadAllLawFirmList = async () => {
        let response = await getVermostLawFirms('', '', '', '', true);
        if (response.status === 200) {
            const result = await response.json();
            dispatch(addLawFirms(result.data));
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }

    const getUser = async () => {
        if (routeParams && routeParams.userID !== '0') {
            const url = `/user/vermost_user_detail/${routeParams.userID}`
            try {
                const token = localStorage.getItem('userdata');
                const response = await fetch(`${API_URL}${url}`, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 200) {
                    const result = await response.json();
                    user.email = result.email;
                    user.first_name = result.first_name;
                    user.last_name = result.last_name;
                    user.is_portal_admin = result.is_portal_admin;
                    user.phone_number = result.phone_number;
                    user.is_active = result.is_active;
                    user.user_role_name = result.user_role_name;
                    user.user_role_id = result.user_role_id;
                    user.position = result.position;
                    user.position_value = result.position_value;
                    user.law_school = result.law_school;
                    user.law_school_name = result.law_school_name;
                    user.bar = result.bar;
                    user.deactivation_reason = result.deactivation_reason;
                    user.extension = result.extension;
                    user.preferred_name = result.preferred_name;
                    user.middle_name = result.middle_name;
                    user.prefix = result.prefix;
                    user.suffix = result.suffix;
                    setUser({...user, user})
                } else {
                    const result = await response.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const refreshTable = async (is_refresh) => {
        let filter = ""
        if (queryOptions.filterModel) {
            if (queryOptions.filterModel[0] != undefined)
                filter = queryOptions.filterModel[0].field + "-" + queryOptions.filterModel[0].operator + "-" + queryOptions.filterModel[0].value;
            else
                filter = "is_active-is-true";
        }

        let sort = ""
        if (queryOptionsSort.sortModel) {
            if (queryOptionsSort.sortModel.length > 0) {
                if (prevSort) {
                    sort = prevSort
                }
                let sortModel = queryOptionsSort.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSort !== sort) {
                    setPrevSort(sort)
                } else {
                    if (queryOptions.filterModel) {
                        sort = ""
                    }
                }
            }
        }

        if(!isInternalFlow){
            let response = await getUserLawFirms(routeParams.userID, controller.page, controller.pageSize, filter, sort);
            if (response.status === 200) {
                    const result = await response.json();
                    setLawFirms(result.data);
                    setRowCount(result.total);
                    dispatch(addUserLawFirms(result.data));
            if (is_refresh)
                    enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
            } else {
                   const result = await response.json();
                   enqueueSnackbar(result, {variant: "error"});
                   }
        }
        
    }

    const deleteLawFirm = async (lawFirmId, showPopup) => {
        if (showPopup) {
            setDeleteText('User Law Firm');
            setDeleteLawFirmId(lawFirmId);
            return 0;
        }
        let response = await deleteUserLawFirmBackend(lawFirmId);
        if (response.status === 200) {
            await refreshTable(false);
            enqueueSnackbar('User Law Firm is active field changed successfully.', {variant: "success"});
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }

    const onCallbackUser = async (actionType, values, id) => {
        let response = "";
        switch (actionType) {
            case ActionType.edit:
                response = await updateVermostUserBackend(id, values);
                if (response.status === 200) {
                    enqueueSnackbar("User updated successfully.", {variant: "success"});
                    if (props.lawFirmFromComponent !== undefined && props.lawFirmFromComponent !== null) {
                        props.closeWindow(false)
                    } else {
                        goBack()
                    }
                } else {
                    const result = await response.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
                break;
            case ActionType.insert:
                if (values.selectedLawFirm.law_firm === undefined || values.selectedLawFirm.law_firm === null || values.selectedLawFirm.location === undefined || values.selectedLawFirm.location === null) {
                    break;
                }
                response = await addVermostUserBackend(values);
                if (response.status === 200) {
                    const result = await response.json();
                    enqueueSnackbar("User created successfully.", {variant: "success"});
                    if (props.lawFirmFromComponent !== undefined && props.lawFirmFromComponent !== null) {
                        props.closeWindow(false)
                    } else {
                        goBack()
                    }

                } else {
                    const result = await response.json();
                    enqueueSnackbar(result.includes("username") ? "User with this username already exists." : result, {variant: "error"});
                }
                break;
            case ActionType.new_user:
                log("valıe", values);
        }

    }

    const onCallbackLawFirms = async (actionType, obj) => {
        log("obj,", obj);
        log("actionType,", actionType);
        switch (actionType) {
            case ActionType.edit:
                setShowEditPopup(true);
                setLawFirmEditID(obj.law_firm);
                setEditID(obj.id);
                break;
            case ActionType.insert:
                setShowInsertPopup(true);
                setLawFirmEditID(0);
                setEditID(0);
                break;
            case ActionType.delete:
                await deleteLawFirm(obj.id, true);
                break;
            case ActionType.refresh:
                await refreshTable(true);
                break;
            case ActionType.download:
                log("download2");
                await exportUserData();
                break;

        }
    }

    const exportUserData = async () => {
        let filter = "";
        if (queryOptions.filterModel) {
            if (queryOptions.filterModel[0] != undefined)
                filter = queryOptions.filterModel[0].field + "-" + queryOptions.filterModel[0].operator + "-" + queryOptions.filterModel[0].value;
            else
                filter = "is_active-is-true";
        }

        let sort = "";
        if (queryOptionsSort.sortModel) {
            if (queryOptionsSort.sortModel.length > 0) {
                if (prevSort) {
                    sort = prevSort;
                }
                let sortModel = queryOptionsSort.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSort !== sort) {
                    setPrevSort(sort);
                } else {
                    if (queryOptions.filterModel) {
                        sort = "";
                    }
                }
            }
        }
        let excelData = await getUserLawFirms(routeParams.userID, '', '', filter, sort);
        if (excelData.status === 200) {
            const result = await excelData.json();
            exportToExcel(result.data, "UserLawFirm");
        } else {
            const result = await excelData.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }

    const onActionCallbackLawFirms = async (actionType, obj, id) => {
        let formData = new FormData();
        switch (actionType) {
            case ActionType.edit:
                setShowEditPopup(false);
                // obj['user'] = routeParams.userID
                if (obj["physical_address"] === 0) {
                    delete obj["physical_address"]
                }
                if (obj["mailing_address"] === 0) {
                    delete obj["mailing_address"]
                }
                if (obj["billing_address"] === 0) {
                    delete obj["billing_address"]
                }
                formData = new FormData();
                Object.keys(obj).forEach((key) => {
                    formData.append(key, obj[key]);
                });

                let responseEdit = await editVermostLawFirmBackend(lawFirmEditID, formData);
                if (responseEdit.status === 200) {
                    await refreshTable(false);
                    let result = await responseEdit.json();
                    dispatch(updateLawFirm(result.data));
                    enqueueSnackbar('User law firm updated successfully.', {variant: "success"});
                } else {
                    const result = await responseEdit.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
                break;
            case ActionType.insert:
                let responseAdd = await addVermostUserLawFirm(routeParams.userID, obj);
                if (responseAdd.status === 200) {
                    await refreshTable(false);
                    enqueueSnackbar('User law firm addedd successfully.', {variant: "success"});
                } else {
                    const result = await responseAdd.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
                setShowInsertPopup(false);
                break;
            case ActionType.create_law_firm:
                formData = new FormData();
                Object.keys(obj).forEach((key) => {
                    if (key !== 'locations') {
                        formData.append(key, obj[key]);
                    } else {
                        let locationsString = JSON.stringify(obj["locations"]);
                        formData.append("locations", locationsString);
                    }
                });
                let response = await addVermostLawFirmBackend(formData);
                if (response.status === 201) {
                    let law_firm_data = await response.json();
                    dispatch(addLawFirm(law_firm_data.data));
                    setShowSearchLawFirm(false);
                    setTempCreatedLawFirm(law_firm_data.data.id);
                } else {
                    const result = await response.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
                break;
            case ActionType.delete:
                await deleteLawFirm();
                break;
            case ActionType.download:
                log("download1");
                break;

        }
    }


    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        if (props.routeFromComponent !== undefined && props.routeFromComponent !== null) {
            routeParams.userID = props.routeFromComponent;
        }
        if (routeParams && routeParams.userID && routeParams.userID !== '0') {
            setIsEdit(true);
            if (loading)
                await getUser();
        }
        setLoading(false);

        if(!isInternalFlow){
            await loadAllLawFirmList();
        }
    }, [user]);

    useEffect(async () => {
        setTableLoading(true);
        await refreshTable(false);
        setTableLoading(false);
    }, [controller, queryOptions, queryOptionsSort]);

    return (
        <>

            {(props.routeFromComponent !== undefined && props.routeFromComponent !== null) ? <></> :
                <>
                    {routeParams.userID !== "0" && <GoBack
                        pageUrl={location.state !== undefined && location.state !== null ? `${location?.state?.history}` : "/user_list"}></GoBack>
                    }
                    <Header text={routeParams.userID === "0" ? "Create New Internal User" : "Edit User"}></Header>
                </>
            }
            {loading ?
                <CircularProgress/> :
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card sx={{width: '100%', height: '100%', padding: "0", margin: "0"}}>
                                <CardContent sx={{width: '100%', margin: "0"}}>
                                    <Row>
                                        {
                                            routeParams.userID === "0" ?
                                                (<Col className={"col-12"}>
                                                        <AddEditUserComp data={user} id={routeParams.userID}
                                                                         oncallback={onCallbackUser}
                                                                         lawFirmEditID={lawFirmEditID}
                                                                         editID={editID}
                                                                         routeFromComponent={props.routeFromComponent}
                                                                         lawFirmFromComponent={props.lawFirmFromComponent}
                                                                         tempCreatedLawFirm={tempCreatedLawFirm}
                                                                         showCreateLawFirmPopup={setShowSearchLawFirm}></AddEditUserComp>
                                                    </Col>
                                                ) :
                                                (<>
                                                    <Col className={"col-xl-5"}>
                                                        <AddEditUserComp data={user} id={routeParams.userID}
                                                                         routeFromComponent={props.routeFromComponent}
                                                                         lawFirmFromComponent={props.lawFirmFromComponent}
                                                                         oncallback={onCallbackUser}></AddEditUserComp>
                                                    </Col>
                                                    {!isInternalFlow && (
                                                    <Col className={"col-xl-7"}>
                                                        <UserLawFirms user_id={routeParams.userID}
                                                                      data={lawFirms}
                                                                      rowCount={rowCount}
                                                                      paginationModel={controller}
                                                                      onPaginationModelChange={gatedSetController}
                                                                      onFilterModelChange={onFilterChange}
                                                                      onSortModelChange={handleSortModelChange}
                                                                      loading={tableLoading}
                                                                      lawFirmEditID={lawFirmEditID}
                                                                      editID={editID}
                                                                      userEditPage={true}
                                                                      onActionCallback={onActionCallbackLawFirms}
                                                                      onCallback={onCallbackLawFirms}
                                                                      isEdit={isEdit}
                                                                      addPermission={hasAddPermission}
                                                                      deletePermission={hasDeletePermission}
                                                                      updatePermission={hasUpdatePermission}
                                                                      showCreateLawFirmPopup={setShowSearchLawFirm}
                                                                      filterModel={{
                                                                          items: [{
                                                                              field: 'is_active',
                                                                              operator: 'is',
                                                                              value: true
                                                                          }],
                                                                      }}>
                                                        </UserLawFirms>
                                                        {
                                                            deleteLawFirmId !== 0 &&
                                                            <ActiveAccountManagementPopup show={true}
                                                                                          closePopup={setDeleteLawFirmId}
                                                                                          delete_callback={deleteLawFirm}
                                                                                          delete_id={deleteLawFirmId}
                                                                                          delete_text={deleteText}/>
                                                        }

                                                    </Col>
                                                    )}
                                                </>)
                                        }
                                        {
                                            showSearchLawFirm &&
                                            <AddLawFirmPopup show={true} closePopup={setShowSearchLawFirm}
                                                             oncallback={onActionCallbackLawFirms}
                                                             userEditPage={true}
                                                             callback={{}}
                                                             id={0}
                                                             data={{}}
                                            />
                                        }
                                        {showEditPopup && (
                                            <AddLawFirmPopup show={true} closePopup={setShowEditPopup}
                                                             oncallback={onActionCallbackLawFirms}
                                                             userEditPage={true}
                                                             id={lawFirmEditID}
                                                             data={lawFirmList.find(item => item.id === lawFirmEditID)}
                                                             callback={{}}/>

                                        )
                                        }
                                        {showInsertPopup &&
                                            <UserLawFirmInsertPopup show={true}
                                                                    lawFirmEditID={lawFirmEditID}
                                                                    editID={editID}
                                                                    lawFirmData={lawFirmList}
                                                                    closePopup={setShowInsertPopup}
                                                                    callback={onActionCallbackLawFirms}
                                                                    onCallBack={onCallbackLawFirms}
                                                                    userEditPage={true}
                                                                    routeParamsId={routeParams.userID}
                                                                    tempCreatedLawFirm={tempCreatedLawFirm}
                                                                    showCreateLawFirmPopup={setShowSearchLawFirm}/>
                                        }
                                    </Row>
                                    <br></br><br></br><br></br><br></br><br></br>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <br/>
                </>
            }
        </>
    )
};
export default BaseAddEditUser;
