import React, {useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import {Card, CardContent, Container} from "@mui/material";
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {ArrowLeft, ArrowRight} from "react-bootstrap-icons";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomTextInput from "../../global_components/inputs/text";
import CustomDatePicker from "../../global_components/custom_date_picker";
import {formatDateMDY} from "../../services/helper";
import CellPhone from "../../global_components/inputs/cell_phone";
import ZipCode from "../../global_components/inputs/zip_code";
import PatternFormat from "react-number-format";
import CityState from "city-and-state";
import { BsChevronDown } from "react-icons/bs";
import "../../styles/main.css";
import Collapsible from 'react-collapsible';
import GoogleAutoComplete from "../../global_components/inputs/google-auto-complete";
import "../../index.css";
import DateNormalInput from "../../global_components/inputs/date-normal-input";
import {Logout} from "../../utils/user_logout";
import {useSnackbar} from "notistack";
import {
    removeCompany,
    removeDepartmentApprovementLevel,
    removeDepartments,
    removeSelectedDepartment, removeSelectedPermissionAP,
    removeToken
} from "../../store/auth";
import {removeCategories, removeInvoicesDashboard} from "../../store/dashboard";
import {removeProcess, setCurrentActive} from "../../store/processes";
import {removeAccountNumbers, removeAllInvoices} from "../../store/invoices";
import {
    removeAPPermissions, removeBondNumbers,
    removeDepartmentsCM,
    removeLawFirmBranches,
    removeLawFirmContacts,
    removeLawFirmProfessionalGuardians,
    removeLawFirms,
    removeLawSchools,
    removePOPermissions,
    removeProcessPermissions,
    removeSuperPOUsers,
    removeUsers
} from "../../store/company_management";
import {removeDocuments} from "../../store/document_approval";
import {removeProcessDetails, removeProcessHistory, removeProcessInputs} from "../../store/capital_project";
import {
    removeBondProcessDetails,
    removeBondProcessHistory,
    removeBondProcessInputs,
    removeLawFirmData
} from "../../store/bond";
import {useNavigate} from "react-router";

export default function Applicant(props){
    const cityState = new CityState();
    const [refreshAddress, setRefreshAddress] = useState(false);
    const [refreshPriorAddress, setRefreshPriorAddress] = useState(false);
    const [refreshMailingAddress, setRefreshMailingAddress] = useState(false);
    const companyData = useSelector((state)=>state.auth.company);
    let agencyZoomPrePopulated = JSON.parse(localStorage.getItem('agencyZoomPrePopulated'));
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const addressAutoFillCallBack = async (address, city, state, zip_code) => {
        //console.log(address, city, state, zip_code);
        await props.formIK.setFieldValue('state', state);
        await props.formIK.setFieldValue('address', address);
        await props.formIK.setFieldValue('zip', zip_code);
        await props.formIK.setFieldValue('city', city);
        setRefreshAddress(!refreshAddress);
    }

    const priorAddressAutoFillCallBack = async (address, city, state, zip_code) => {
        //console.log(address, city, state, zip_code);
        await props.formIK.setFieldValue('prior_state', state);
        await props.formIK.setFieldValue('prior_address', address);
        await props.formIK.setFieldValue('prior_zip', zip_code);
        await props.formIK.setFieldValue('prior_city', city);
        setRefreshPriorAddress(!refreshPriorAddress);
    }

    const mailingAddressAutoFillCallBack = async (address, city, state, zip_code) => {
        //console.log(address, city, state, zip_code);
        await props.formIK.setFieldValue('mailing_state', state);
        await props.formIK.setFieldValue('mailing_address', address);
        await props.formIK.setFieldValue('mailing_zip', zip_code);
        await props.formIK.setFieldValue('mailing_city', city);
        setRefreshMailingAddress(!refreshMailingAddress);
    }

    const logout = ()=>{
        enqueueSnackbar('You are logged out. Redirecting to login page in 3 seconds.', {variant: "error"});
        setTimeout(() => {
            localStorage.clear();
            dispatch(removeToken());
            dispatch(removeCompany());
            dispatch(removeInvoicesDashboard());
            dispatch(removeCategories());
            dispatch(removeProcess());
            dispatch(setCurrentActive());
            dispatch(removeAllInvoices());
            dispatch(removeAccountNumbers());
            dispatch(removeDepartments());
            dispatch(removeSelectedDepartment());
            dispatch(removeDepartmentApprovementLevel());
            dispatch(removeSelectedPermissionAP());
            dispatch(removeUsers());
            dispatch(removeDepartmentsCM());
            dispatch(removeAPPermissions());
            dispatch(removePOPermissions());
            dispatch(removeSuperPOUsers());
            dispatch(removeProcessPermissions());
            dispatch(removeDocuments());
            dispatch(removeProcessHistory());
            dispatch(removeProcessDetails());
            dispatch(removeProcessInputs());
            dispatch(removeBondProcessInputs());
            dispatch(removeBondProcessDetails());
            dispatch(removeBondProcessHistory());
            dispatch(removeLawFirmData());
            dispatch(removeLawFirms());
            dispatch(removeLawFirmContacts());
            dispatch(removeLawFirmBranches());
            dispatch(removeLawSchools());
            dispatch(removeLawFirmProfessionalGuardians());
            dispatch(removeBondNumbers());
            navigate("/");

        }, 3000);
    }

    const validate_data = () => {
        if (!localStorage.getItem('userdata')) {
            logout();
            return;
        }
        let error = false;

        // if (companyData.name !== "Coastal Alabama Insurance"){
        //     if(props.formIK.values.client_type === ""){
        //         props.formIK.errors['client_type'] = 'Client Type is required.';
        //         error = true;
        //     }
        // }

        // if(props.formIK.values.client_type === "Renewal" && companyData.name !== "LTN Insurance"){
        //     if(props.formIK.values.agency_zoom_id === "" || props.formIK.values.agency_zoom_id === 0){
        //         props.formIK.errors['agency_zoom_id'] = 'Agency Zoom ID is required.';
        //         error = true;
        //     }
        // }

        // if(props.formIK.values.client_type === "New Business" ){
        //     if(props.formIK.values.leadSourceId === ""){
        //         props.formIK.errors['leadSourceId'] = 'Lead Source is required.';
        //         error = true;
        //     }
        // }
        if(props.formIK.values.first_name === ""){
            props.formIK.errors['first_name'] = 'First name is required.';
            error = true;
        }
        // if(props.formIK.values.middle_name === ""){
        //     props.formIK.errors['middle_name'] = 'Middle name is required.';
        //     error = true;
        // }
        if(props.formIK.values.last_name === ""){
            props.formIK.errors['last_name'] = 'Last name is required.';
            error = true;
        }
        if(props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.sage_sure === true || props.formIK.values.openly === true ||
            props.formIK.values.branch === true){
            if(props.formIK.values.date_of_birth === ""){
                props.formIK.errors['date_of_birth'] = 'Date of birth is required.';
                error = true;
            }else if(new Date(props.formIK.values.date_of_birth) >= new Date()){
                props.formIK.errors['date_of_birth'] = 'Date of birth cannot be today or in future.';
                error = true;
            }
        }
        if (props.formIK.values.orion === true){
            if(props.formIK.values.cell_phone === "+1 (___) ___ ____" ||
                props.formIK.values.cell_phone === null ||
                props.formIK.values.cell_phone === "" ||
                props.formIK.values.cell_phone === undefined || props.formIK.values.cell_phone.includes('_')) {
                props.formIK.errors['cell_phone'] = 'Cell phone is required.';
                error = true;
            }
        }
        if (props.formIK.values.asi_progressive === true){
            if(props.formIK.values.gender === "") {
                props.formIK.errors['gender'] = 'Gender is required.';
                error = true;
            }
        }
        if (props.formIK.values.asi_progressive === true){
            if(props.formIK.values.gender === "") {
                props.formIK.errors['gender'] = 'Gender is required.';
                error = true;
            }
        }
        if (props.formIK.values.asi_progressive === true){
            if(props.formIK.values.marital_status === "") {
                props.formIK.errors['marital_status'] = 'Marital status is required.';
                error = true;
            }
            if(props.formIK.values.marital_status === "Married") {
                if(props.formIK.values.SpouseFirstName === ""){
                    props.formIK.errors['SpouseFirstName'] = 'Spouse First Name is required.';
                    error = true;
                }
                if(props.formIK.values.SpouseLastName === ""){
                    props.formIK.errors['SpouseLastName'] = 'SpouseLastName is required.';
                    error = true;
                }
                if(props.formIK.values.SpouseBirthDate === "" || new Date(props.formIK.values.SpouseBirthDate).toString() === 'Invalid Date'){
                    props.formIK.errors['SpouseBirthDate'] = 'Spouse Birth Date is required.';
                    error = true;
                }
                if(props.formIK.values.SpouseGender === ""){
                    props.formIK.errors['SpouseGender'] = 'Spouse Gender is required.';
                    error = true;
                }

            }
        }
        // if (props.formIK.values.openly === true){
        //     if(props.formIK.values.last_digit_of_ssn === "") {
        //         props.formIK.errors['last_digit_of_ssn'] = 'Last 4 digits of SSN is required.';
        //         error = true;
        //     }
        // }
        if(props.formIK.values.address === "") {
            props.formIK.errors['address'] = 'Address is required.';
            error = true;
        }
        if(props.formIK.values.state === "") {
            props.formIK.errors['state'] = 'State is required.';
            error = true;
        }
        if(props.formIK.values.city === "") {
            props.formIK.errors['city'] = 'City is required.';
            error = true;
        }
        if(props.formIK.values.zip === "") {
            props.formIK.errors['zip'] = 'Zip Code is required.';
            error = true;
        }
        if(props.formIK.values.same_mailing_address === 'No'){
            if(props.formIK.values.mailing_address === "") {
                props.formIK.errors['mailing_address'] = 'Mailing Address is required.';
                error = true;
            }
            if(props.formIK.values.mailing_city === "") {
                props.formIK.errors['mailing_city'] = 'Mailing City is required.';
                error = true;
            }
            if(props.formIK.values.mailing_state === "") {
                props.formIK.errors['mailing_state'] = 'Mailing State is required.';
                error = true;
            }
            if(props.formIK.values.mailing_zip === "") {
                props.formIK.errors['mailing_zip'] = 'Mailing Zip is required.';
                error = true;
            }
        }
        if (props.formIK.values.asi_progressive === true){
            if(props.formIK.values.current_address_liv_duration === "") {
                props.formIK.errors['current_address_liv_duration'] = 'This field is required.';
                error = true;
            }
        }
        if (((props.formIK.values.sage_sure === true ||
                    props.formIK.values.asi_progressive === true) &&
                props.formIK.values['current_address_liv_duration'] === "2 months or less") ||
            props.formIK.values.branch === true){
            if(props.formIK.values.prior_address === "") {
                props.formIK.errors['prior_address'] = 'Prior Address is required.';
                error = true;
            }
            if(props.formIK.values.prior_city === "") {
                props.formIK.errors['prior_city'] = 'Prior City is required.';
                error = true;
            }
            if(props.formIK.values.prior_state === "") {
                props.formIK.errors['prior_state'] = 'Prior State is required.';
                error = true;
            }
            if(props.formIK.values.prior_zip === "") {
                props.formIK.errors['prior_zip'] = 'Prior Zip is required.';
                error = true;
            }
        }
        if (props.formIK.values.sage_sure === true){
            if(props.formIK.values.insurance_score_estimate === "") {
                props.formIK.errors['insurance_score_estimate'] = 'Insurance score estimate is required.';
                error = true;
            }
        }
        if(props.formIK.values.branch === true){
            if(props.formIK.values.current_auto_limit_bipd === "") {
                props.formIK.errors['current_auto_limit_bipd'] = 'Current Auto Limit BIPD is required.';
                error = true;
            }
            if(props.formIK.values.auto_insurance_in_past_31_days === "") {
                props.formIK.errors['auto_insurance_in_past_31_days'] = 'Auto Insurance In Past 31 Days is required.';
                error = true;
            }
            if(props.formIK.values.continuous_auto_coverage_years === "") {
                props.formIK.errors['continuous_auto_coverage_years'] = 'Continuous Auto Coverage Years is required.';
                error = true;
            }
        }
        if(error === true){
            window.scroll(0,0);
            console.log(props.formIK.errors)
        }else{
            props.changeStep(props.progress+props.step);
        }
    }

    return(
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button onClick={()=>props.changeStep(props.progress-props.step)} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={()=>validate_data()} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{ maxWidth: "75%"}} style={{marginBottom: "2%", overflow: "visible"}}>

                            <CardContent>
                                <Row style={{marginBottom: "1%"}}>
                                    <h4>Applicant Information</h4>
                                </Row>
                                {
                                    companyData.name !== "Coastal Alabama Insurance" && (
                                        <Row>
                                            {/*<Col>*/}
                                            {/*    <Form.Group key="client_type" controlId="formBasicEmail"*/}
                                            {/*                style={{padding: "0px 15px 0px 0px"}}>*/}
                                            {/*        <Form.Label className='required-label'><strong>Client Type</strong></Form.Label>*/}
                                            {/*        <CustomSelectInput disabled={false} name="client_type"*/}
                                            {/*                           value={props.formIK.values.client_type !== undefined ? props.formIK.values.client_type : ""}*/}
                                            {/*                           onChange={(value)=>{*/}
                                            {/*                               if (value === ""){*/}
                                            {/*                                   props.formIK.errors['client_type'] = "Client Type is required.";*/}
                                            {/*                               }else{*/}
                                            {/*                                   delete props.formIK.errors['client_type'];*/}
                                            {/*                               }*/}
                                            {/*                               props.formIK.setFieldValue("client_type", value)*/}
                                            {/*                           }}*/}
                                            {/*                           options={[{label: 'New Business', value: 'New Business'},*/}
                                            {/*                               {label: 'Send email only', value: 'Send email only'}]}/>*/}
                                            {/*        {props.formIK.errors["client_type"] && (*/}
                                            {/*            <div className="text-error">{props.formIK.errors["client_type"]}</div>*/}
                                            {/*        )}*/}
                                            {/*    </Form.Group>*/}
                                            {/*</Col>*/}
                                            {/*{*/}
                                            {/*    (props.formIK.values.client_type === "" || props.formIK.values.client_type === "Send email only") && (*/}
                                            {/*        <Col></Col>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    props.formIK.values.client_type === "Renewal" && (*/}
                                            {/*        <Col>*/}
                                            {/*            <Form.Group key="agency_zoom_id" controlId="formBasicEmail"*/}
                                            {/*                        style={{padding: "0px 15px 0px 0px"}}>*/}
                                            {/*                <Form.Label className={companyData.name !== "LTN Insurance" ? 'required-label' : ""}><strong>Agency Zoom ID</strong></Form.Label>*/}
                                            {/*                <CustomNumberInput disabled={false} required={true} name="agency_zoom_id"*/}
                                            {/*                                   className={"form-control"}*/}
                                            {/*                                   id="agency_zoom_id-input"*/}
                                            {/*                                   value={props.formIK.values.agency_zoom_id}*/}
                                            {/*                                   onChange={(value)=>{*/}
                                            {/*                                       if (companyData.name !== 'LTN Insurance' && value === ""){*/}
                                            {/*                                           props.formIK.errors['agency_zoom_id'] = "Agency Zoom ID is required.";*/}
                                            {/*                                       }else{*/}
                                            {/*                                           delete props.formIK.errors['agency_zoom_id'];*/}
                                            {/*                                       }*/}
                                            {/*                                       props.formIK.setFieldValue("agency_zoom_id", value);*/}
                                            {/*                                   }}/>*/}
                                            {/*                <div className="text-error">{props.formIK.errors["agency_zoom_id"]}</div>*/}
                                            {/*            </Form.Group>*/}
                                            {/*        </Col>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    props.formIK.values.client_type === "New Business" && (*/}
                                            {/*        <Col>*/}
                                            {/*            <Form.Group key="leadSourceId" controlId="formBasicEmail"*/}
                                            {/*                        style={{padding: "0px 15px 0px 0px"}}>*/}
                                            {/*                <Form.Label className='required-label'><strong>Lead Source</strong></Form.Label>*/}
                                            {/*                <CustomSelectInput disabled={false} name="gender"*/}
                                            {/*                                   value={props.formIK.values.leadSourceId !== undefined ? props.formIK.values.leadSourceId : ""}*/}
                                            {/*                                   onChange={(value)=>{*/}
                                            {/*                                       if (value === ""){*/}
                                            {/*                                           props.formIK.errors['leadSourceId'] = "Lead Source is required.";*/}
                                            {/*                                       }else{*/}
                                            {/*                                           delete props.formIK.errors['leadSourceId'];*/}
                                            {/*                                       }*/}
                                            {/*                                       props.formIK.setFieldValue("leadSourceId", value)*/}
                                            {/*                                   }}*/}
                                            {/*                                   options={portal_insurance_zoom_lead_sources[companyData.name]}/>*/}
                                            {/*                {props.formIK.errors["leadSourceId"] && (*/}
                                            {/*                    <div className="text-error">{props.formIK.errors["leadSourceId"]}</div>*/}
                                            {/*                )}*/}
                                            {/*            </Form.Group>*/}
                                            {/*        </Col>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                        </Row>
                                    )
                                }
                                <Row>
                                    <Col key={props.formIK.errors["first_name"]}>
                                        {/*<Form.Group key="first_name" controlId="formBasicEmail"*/}
                                        {/*            style={{padding: "0px 15px 0px 0px"}}>*/}
                                        {/*    <Form.Label className='required-label'><strong>First Name</strong></Form.Label>*/}
                                        {/*    <CustomTextInput disabled={false} required={true} name="first_name"*/}
                                        {/*                     className={"form-control"} id="first_name-input"*/}
                                        {/*                     value={props.formIK.values.first_name}*/}
                                        {/*                     onChange={(value)=>{*/}
                                        {/*                         if (value === ""){*/}
                                        {/*                             props.formIK.errors['first_name'] = "First name is required.";*/}
                                        {/*                         }else{*/}
                                        {/*                             delete props.formIK.errors['first_name'];*/}
                                        {/*                         }*/}
                                        {/*                         props.formIK.setFieldValue("first_name", value);*/}
                                        {/*                     }}/>*/}
                                        {/*        <div className="text-error">{props.formIK.errors["first_name"]}</div>*/}
                                        {/*</Form.Group>*/}
                                        {/*<Form.Group key="last_name" controlId="formBasicEmail"*/}
                                        {/*            style={{padding: "0px 15px 0px 0px"}}>*/}
                                        {/*    <Form.Label className='required-label'><strong>Last Name</strong></Form.Label>*/}
                                        {/*    <CustomTextInput disabled={false} required={true} name="last_name"*/}
                                        {/*                     className={"form-control"} id="last_name-input"*/}
                                        {/*                     value={props.formIK.values.last_name}*/}
                                        {/*                     onChange={(value)=>{*/}
                                        {/*                         if (value === ""){*/}
                                        {/*                             props.formIK.errors['last_name'] = "Last name is required.";*/}
                                        {/*                         }else{*/}
                                        {/*                             delete props.formIK.errors['last_name'];*/}
                                        {/*                         }*/}
                                        {/*                         props.formIK.setFieldValue("last_name", value);*/}
                                        {/*                     }}/>*/}
                                        {/*    {props.formIK.errors["last_name"] && (*/}
                                        {/*        <div className="text-error">{props.formIK.errors["last_name"]}</div>*/}
                                        {/*    )}*/}
                                        {/*</Form.Group>*/}
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <Form.Group key="gender" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Gender</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="gender"
                                                                       value={props.formIK.values.gender !== undefined ? props.formIK.values.gender : ""}
                                                                       onChange={(value)=>{
                                                                           if (value === ""){
                                                                               props.formIK.errors['gender'] = "Gender is required.";
                                                                           }else{
                                                                               delete props.formIK.errors['gender'];
                                                                           }
                                                                           props.formIK.setFieldValue("gender", value)
                                                                       }}
                                                                       options={[{label: 'Male', value: 'Male'},
                                                                           {label: 'Female', value: 'Female'}]}/>
                                                    {props.formIK.errors["gender"] && (
                                                        <div className="text-error">{props.formIK.errors["gender"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            props.formIK.values.openly === true && (
                                                <Form.Group key="last_digit_of_ssn" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label><strong>Last 4 Digits Of SSN (Only required for Openly)</strong></Form.Label>
                                                    <PatternFormat className="form-control danger-input" name="last_digit_of_ssn"
                                                                   id="last_digit_of_ssn-input"
                                                                   defaultValue={props.formIK.values.last_digit_of_ssn}
                                                                   format="####" allowEmptyFormatting mask="#"
                                                                   disabled={false} onChange={(value)=> {
                                                        if (value === ""){
                                                            props.formIK.errors['last_digit_of_ssn'] = "Last 4 digits of SSN is required.";
                                                        }else{
                                                            delete props.formIK.errors['last_digit_of_ssn'];
                                                        }
                                                        props.formIK.setFieldValue("last_digit_of_ssn", value.target.value);
                                                    }}/>
                                                    {props.formIK.errors["last_digit_of_ssn"] && (
                                                        <div className="text-error">{props.formIK.errors["last_digit_of_ssn"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key="same_mailing_address" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px", marginTop: "6%"}}>
                                            <Form.Label className='required-label'><strong>Is property address same as mailing address?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="same_mailing_address"
                                                               value={props.formIK.values.same_mailing_address !== undefined ? props.formIK.values.same_mailing_address : ""}
                                                               onChange={(value)=> {
                                                                   if(value === ""){
                                                                       props.formIK.errors['same_mailing_address'] = "Mailing Address Status is required.";
                                                                   }else{
                                                                       delete props.formIK.errors['same_mailing_address'];
                                                                   }
                                                                   props.formIK.setFieldValue("same_mailing_address", value);
                                                               }}
                                                               options={[{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}]}/>
                                            {props.formIK.errors["same_mailing_address"] && (
                                                <div className="text-error">{props.formIK.errors["same_mailing_address"]}</div>
                                            )}
                                        </Form.Group>
                                        {/*{*/}
                                        {/*    props.formIK.values.marital_status === "Married" && (*/}
                                        {/*        <>*/}
                                        {/*            */}
                                        {/*        </>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                    </Col>
                                    <Col>
                                        {/*<Form.Group key="middle_name" controlId="formBasicEmail"*/}
                                        {/*            style={{padding: "0px 15px 0px 0px"}}>*/}
                                        {/*    <Form.Label><strong>Middle Name</strong></Form.Label>*/}
                                        {/*    <CustomTextInput disabled={false} required={false} name="middle_name"*/}
                                        {/*                     className={"form-control"} id="middle_name-input"*/}
                                        {/*                     value={props.formIK.values.middle_name}*/}
                                        {/*                     onChange={(value)=>{*/}
                                        {/*                         // if(value === ""){*/}
                                        {/*                         //     props.formIK.errors['middle_name'] = "Middle name is required.";*/}
                                        {/*                         // }else{*/}
                                        {/*                         //     delete props.formIK.errors['middle_name'];*/}
                                        {/*                         // }*/}
                                        {/*                         props.formIK.setFieldValue("middle_name", value);*/}
                                        {/*                     }}/>*/}
                                        {/*    /!*{props.formIK.errors["middle_name"] && (*!/*/}
                                        {/*    /!*    <div className="text-error">{props.formIK.errors["middle_name"]}</div>*!/*/}
                                        {/*    /!*)}*!/*/}
                                        {/*</Form.Group>*/}
                                        {
                                            props.formIK.values.orion === true && (
                                                <Form.Group key="cell_phone" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Cell Phone</strong></Form.Label>
                                                    <CellPhone disabled={false} required={false} name="cell_phone"
                                                               className={agencyZoomPrePopulated.includes('cell_phone') ? 'form-control prePopulated' : 'form-control'}
                                                               id="cell_phone-input"
                                                               value={props.formIK.values.cell_phone}
                                                               onChange={(value)=>{
                                                                     if (value === "+1 (___) ___ ____"){
                                                                         props.formIK.errors['cell_phone'] = "Cell phone is required.";
                                                                     }else{
                                                                         delete props.formIK.errors['cell_phone'];
                                                                     }
                                                                     console.log(value)
                                                                     props.formIK.setFieldValue("cell_phone", value);
                                                               }}/>
                                                    {props.formIK.errors["cell_phone"] && (
                                                        <div className="text-error">{props.formIK.errors["cell_phone"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <Form.Group key="current_address_liv_duration" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>How long has the insured lived at their current address?</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="current_address_liv_duration"
                                                                       value={props.formIK.values.current_address_liv_duration !== undefined ? props.formIK.values.current_address_liv_duration : ""}
                                                                       onChange={(value)=> {
                                                                           if(value === ""){
                                                                               props.formIK.errors['current_address_liv_duration'] = "This field is required.";
                                                                           }else{
                                                                               delete props.formIK.errors['current_address_liv_duration'];
                                                                           }
                                                                           props.formIK.setFieldValue("current_address_liv_duration", value);
                                                                       }}
                                                                       options={[{label: '2 months or less', value: '2 months or less'},
                                                                           {label: 'More than 2 months but less than 1 year', value: 'More than 2 months but less than 1 year'}, {label: '1 year or more', value: '1 year or more'}]}/>
                                                    {props.formIK.errors["current_address_liv_duration"] && (
                                                        <div className="text-error">{props.formIK.errors["current_address_liv_duration"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <Form.Group key="marital_status" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Marital Status</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="marital_status"
                                                                       className={agencyZoomPrePopulated.includes('marital_status') ? 'prePopulated' : ''}
                                                                       value={props.formIK.values.marital_status !== undefined ? props.formIK.values.marital_status : ""}
                                                                       onChange={(value)=> {
                                                                           if (value === ""){
                                                                               props.formIK.errors['marital_status'] = "Marital status is required.";
                                                                           }else{
                                                                               delete props.formIK.errors['marital_status'];
                                                                           }
                                                                           props.formIK.setFieldValue("marital_status", value);
                                                                       }}
                                                                       options={[{label: "Single", value: "Single"}, {label: "Married", value: "Married"},
                                                                           {label: "Separated", value: "Separated"}, {label: "Widowed", value: "Widowed"},
                                                                           {label: "Divorced", value: "Divorced"}]}/>
                                                    {props.formIK.errors["marital_status"] && (
                                                        <div className="text-error">{props.formIK.errors["marital_status"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {/*{*/}
                                        {/*    (props.formIK.values.orion === true ||*/}
                                        {/*        props.formIK.values.asi_progressive === true ||*/}
                                        {/*        props.formIK.values.sage_sure === true ||*/}
                                        {/*        props.formIK.values.openly === true ||*/}
                                        {/*        props.formIK.values.branch === true) && (*/}
                                        {/*        <Form.Group key="date_of_birth" controlId="formBasicEmail"*/}
                                        {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                        {/*            <Form.Label className='required-label'><strong>Date Of Birth</strong></Form.Label>*/}
                                        {/*            <CustomDatePicker className="form-control" name="date_of_birth"*/}
                                        {/*                              id="date_of_birth-input"*/}
                                        {/*                              value={props.formIK.values.date_of_birth !== "" ?*/}
                                        {/*                                  props.formIK.values.date_of_birth :*/}
                                        {/*                                  formatDateMDY(new Date())} format="MM-dd-y"*/}
                                        {/*                              onChange={(value) => {*/}
                                        {/*                                  if(value === ""){*/}
                                        {/*                                      props.formIK.errors['date_of_birth'] = "Date of birth is required.";*/}
                                        {/*                                  }else if(value >= new Date()){*/}
                                        {/*                                      props.formIK.errors['date_of_birth'] = "Date of birth cannot be today or in future.";*/}
                                        {/*                                  }else{*/}
                                        {/*                                      delete props.formIK.errors['date_of_birth'];*/}
                                        {/*                                  }*/}
                                        {/*                                  props.formIK.setFieldValue("date_of_birth", formatDateMDY(value))*/}
                                        {/*                              }} disabled={false}*/}
                                        {/*                              required={true}/>*/}
                                        {/*            {props.formIK.errors["date_of_birth"] && (*/}
                                        {/*                <div className="text-error">{props.formIK.errors["date_of_birth"]}</div>*/}
                                        {/*            )}*/}
                                        {/*        </Form.Group>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    props.formIK.values.marital_status === "Married" && (*/}
                                        {/*        <>*/}
                                        {/*            */}
                                        {/*        </>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                    </Col>
                                </Row>
                                {/*<Row style={{padding: "0px 15px"}}>*/}
                                {/*    <Form.Label className="required-label"><strong>Address</strong></Form.Label>*/}
                                {/*    <GoogleAutoComplete placeholder="Search Address..."*/}
                                {/*                        callback={addressAutoFillCallBack} />*/}
                                {/*</Row>*/}
                                {/*<Row>*/}
                                {/*    <Col style={{marginTop: "4%"}}>*/}
                                {/*        <Form.Group key={refreshAddress} className="mb-3" controlId="formBasicEmail">*/}

                                {/*            <CustomTextInput disabled={false} required={false} name="address"*/}
                                {/*                             className={"form-control"} id={"address-input"}*/}
                                {/*                             value={props.formIK.values.address}*/}
                                {/*                             onChange={(value)=>{*/}
                                {/*                                 if (value === ""){*/}
                                {/*                                     props.formIK.errors['address'] = "Address is required.";*/}
                                {/*                                 }else{*/}
                                {/*                                     delete props.formIK.errors['address'];*/}
                                {/*                                 }*/}
                                {/*                                 props.formIK.setFieldValue("address", value);*/}
                                {/*                             }}*/}
                                {/*            />*/}
                                {/*            {props.formIK.errors["address"] && (*/}
                                {/*                <div className="text-error">{props.formIK.errors["address"]}</div>*/}
                                {/*            )}*/}
                                {/*        </Form.Group>*/}
                                {/*        <Form.Group key="state" controlId="formBasicEmail"*/}
                                {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                {/*            <Form.Label className='required-label'><strong>State</strong></Form.Label>*/}
                                {/*            <CustomSelectInput disabled={false} name="state"*/}
                                {/*                               value={props.formIK.values.state !== undefined ? props.formIK.values.state : ""}*/}
                                {/*                               onChange={(value)=> {*/}
                                {/*                                   if (value === ""){*/}
                                {/*                                       props.formIK.errors['state'] = "State is required.";*/}
                                {/*                                   }else{*/}
                                {/*                                       delete props.formIK.errors['state'];*/}
                                {/*                                   }*/}
                                {/*                                   props.formIK.setFieldValue("state", value);*/}
                                {/*                               }}*/}
                                {/*                               options={cityState.getStates().sort().map((state)=> {*/}
                                {/*                                   return {*/}
                                {/*                                       label: state, value: state*/}
                                {/*                                   }*/}
                                {/*                               })}/>*/}
                                {/*            {props.formIK.errors["state"] && (*/}
                                {/*                <div className="text-error">{props.formIK.errors["state"]}</div>*/}
                                {/*            )}*/}
                                {/*        </Form.Group>*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <Form.Group key={refreshAddress} controlId="formBasicEmail"*/}
                                {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                {/*            <Form.Label className='required-label'><strong>Zip Code</strong></Form.Label>*/}
                                {/*            <ZipCode disabled={false} required={true} name="zip"*/}
                                {/*                     className={"form-control"} id="zip-input"*/}
                                {/*                     value={props.formIK.values.zip}*/}
                                {/*                     onChange={(value)=>{*/}
                                {/*                         if (value === "#####"){*/}
                                {/*                             props.formIK.errors['zip'] = "Zip code is required.";*/}
                                {/*                         }else{*/}
                                {/*                             delete props.formIK.errors['zip'];*/}
                                {/*                         }*/}
                                {/*                         props.formIK.setFieldValue("zip", value);*/}
                                {/*                     }}/>*/}
                                {/*            {props.formIK.errors["zip"] && (*/}
                                {/*                <div className="text-error">{props.formIK.errors["zip"]}</div>*/}
                                {/*            )}*/}
                                {/*        </Form.Group>*/}
                                {/*        <Form.Group key="city" controlId="formBasicEmail"*/}
                                {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                {/*            <Form.Label className='required-label'><strong>City</strong></Form.Label>*/}
                                {/*            <CustomSelectInput disabled={false} name="city"*/}
                                {/*                               value={props.formIK.values.city !== undefined ? props.formIK.values.city : ""}*/}
                                {/*                               onChange={(value)=> {*/}
                                {/*                                   if (value === ""){*/}
                                {/*                                       props.formIK.errors['city'] = "City is required.";*/}
                                {/*                                   }else{*/}
                                {/*                                       delete props.formIK.errors['city'];*/}
                                {/*                                   }*/}
                                {/*                                   props.formIK.setFieldValue("city", value)*/}
                                {/*                               }}*/}
                                {/*                               options={props.formIK.values.state !== "" ? cityState.getCitiesOfState(props.formIK.values.state).map((city)=>{return {label: city.city, value: city.city}}).sort((a, b)=>(a.value > b.value ? 1 : -1)) : []}/>*/}
                                {/*            {props.formIK.errors["city"] && (*/}
                                {/*                <div className="text-error">{props.formIK.errors["city"]}</div>*/}
                                {/*            )}*/}
                                {/*        </Form.Group>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        */}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        /!*{*!/*/}
                                {/*        /!*    props.formIK.values.sage_sure === true && (*!/*/}
                                {/*        /!*        <Form.Group key="insurance_score_estimate" controlId="formBasicEmail"*!/*/}
                                {/*        /!*                    style={{padding: "0px 15px 0px 0px"}}>*!/*/}
                                {/*        /!*            <Form.Label className='required-label' style={{marginBottom: "1%"}}>Insurance score estimate</Form.Label>*!/*/}
                                {/*        /!*            <CustomSelectInput disabled={false} name="insurance_score_estimate"*!/*/}
                                {/*        /!*                               value={props.formIK.values.insurance_score_estimate !== undefined ? props.formIK.values.insurance_score_estimate : ""}*!/*/}
                                {/*        /!*                               onChange={(value)=> {*!/*/}
                                {/*        /!*                                   if (value === ""){*!/*/}
                                {/*        /!*                                       props.formIK.errors['insurance_score_estimate'] = "Insurance score is required.";*!/*/}
                                {/*        /!*                                   }else{*!/*/}
                                {/*        /!*                                       delete props.formIK.errors['insurance_score_estimate'];*!/*/}
                                {/*        /!*                                   }*!/*/}
                                {/*        /!*                                   props.formIK.setFieldValue("insurance_score_estimate", value);*!/*/}
                                {/*        /!*                               }}*!/*/}
                                {/*        /!*                               options={[]}/>*!/*/}
                                {/*        /!*            {props.formIK.errors["current_address_liv_dinsurance_score_estimateuration"] && (*!/*/}
                                {/*        /!*                <div className="text-error">{props.formIK.errors["insurance_score_estimate"]}</div>*!/*/}
                                {/*        /!*            )}*!/*/}
                                {/*        /!*        </Form.Group>*!/*/}
                                {/*        /!*    )*!/*/}
                                {/*        /!*}*!/*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {
                                    props.formIK.values.marital_status === "Married" && (
                                        <>
                                            <hr/>
                                            <Row>
                                                <Col>
                                                    <Form.Group key="SpouseFirstName" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Spouse First Name</strong></Form.Label>
                                                        <CustomTextInput disabled={false} required={true} name="SpouseFirstName"
                                                                         className={"form-control"} id="SpouseFirstName-input"
                                                                         value={props.formIK.values.SpouseFirstName}
                                                                         onChange={(value)=>{
                                                                             if (value === ""){
                                                                                 props.formIK.errors['SpouseFirstName'] = "Spouse First Name is required.";
                                                                             }else{
                                                                                 delete props.formIK.errors['SpouseFirstName'];
                                                                             }
                                                                             props.formIK.setFieldValue("SpouseFirstName", value);
                                                                         }}/>
                                                        <div className="text-error">{props.formIK.errors["SpouseFirstName"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="SpouseLastName" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Spouse Last Name</strong></Form.Label>
                                                        <CustomTextInput disabled={false} required={true} name="SpouseLastName"
                                                                         className={"form-control"} id="SpouseLastName-input"
                                                                         value={props.formIK.values.SpouseLastName}
                                                                         onChange={(value)=>{
                                                                             if (value === ""){
                                                                                 props.formIK.errors['SpouseLastName'] = "Spouse Last Name is required.";
                                                                             }else{
                                                                                 delete props.formIK.errors['SpouseLastName'];
                                                                             }
                                                                             props.formIK.setFieldValue("SpouseLastName", value);
                                                                         }}/>
                                                        <div className="text-error">{props.formIK.errors["SpouseLastName"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="SpouseGender" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Spouse Gender</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="SpouseGender"
                                                                           value={props.formIK.values.SpouseGender !== undefined ? props.formIK.values.SpouseGender : ""}
                                                                           onChange={(value)=>{
                                                                               if (value === ""){
                                                                                   props.formIK.errors['SpouseGender'] = "Spouse Gender is required.";
                                                                               }else{
                                                                                   delete props.formIK.errors['SpouseGender'];
                                                                               }
                                                                               props.formIK.setFieldValue("SpouseGender", value)
                                                                           }}
                                                                           options={[{label: 'Male', value: 'Male'},
                                                                               {label: 'Female', value: 'Female'}]}/>
                                                        {props.formIK.errors["SpouseGender"] && (
                                                            <div className="text-error">{props.formIK.errors["SpouseGender"]}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group key="SpouseMiddleName" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label><strong>Spouse Middle Name</strong></Form.Label>
                                                        <CustomTextInput disabled={false} required={true} name="SpouseMiddleName"
                                                                         className={"form-control"} id="SpouseMiddleName-input"
                                                                         value={props.formIK.values.SpouseMiddleName}
                                                                         onChange={(value)=>{
                                                                             props.formIK.setFieldValue("SpouseMiddleName", value);
                                                                         }}/>
                                                        <div className="text-error">{props.formIK.errors["SpouseMiddleName"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="SpouseBirthDate" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Spouse Date Of Birth</strong></Form.Label>
                                                        <DateNormalInput disabled={false} required={true} name="SpouseBirthDate"
                                                                         className={'form-control'}
                                                                         id="SpouseBirthDate-input"
                                                                         value={props.formIK.values.SpouseBirthDate}
                                                                         onChange={(value)=>{
                                                                             if(value === ""){
                                                                                 props.formIK.errors['SpouseBirthDate'] = "Spouse date of birth is required.";
                                                                             }else if(value >= new Date()){
                                                                                 props.formIK.errors['SpouseBirthDate'] = "Spouse date of birth cannot be today or in future.";
                                                                             }else{
                                                                                 delete props.formIK.errors['SpouseBirthDate'];
                                                                             }
                                                                             props.formIK.setFieldValue("SpouseBirthDate", formatDateMDY(value));
                                                                         }}/>
                                                        {/*<CustomDatePicker className="form-control" name="SpouseBirthDate"*/}
                                                        {/*                  id="SpouseBirthDate-input"*/}
                                                        {/*                  value={props.formIK.values.SpouseBirthDate !== "" ?*/}
                                                        {/*                      props.formIK.values.SpouseBirthDate :*/}
                                                        {/*                      formatDateMDY(new Date())} format="MM-dd-y"*/}
                                                        {/*                  onChange={(value) => {*/}
                                                        {/*                      if(value === ""){*/}
                                                        {/*                          props.formIK.errors['SpouseBirthDate'] = "Spouse Date of birth is required.";*/}
                                                        {/*                      }else if(value >= new Date()){*/}
                                                        {/*                          props.formIK.errors['SpouseBirthDate'] = "Spouse Date of birth cannot be today or in future.";*/}
                                                        {/*                      }else{*/}
                                                        {/*                          delete props.formIK.errors['SpouseBirthDate'];*/}
                                                        {/*                      }*/}
                                                        {/*                      props.formIK.setFieldValue("SpouseBirthDate", formatDateMDY(value))*/}
                                                        {/*                  }} disabled={false}*/}
                                                        {/*                  required={true}/>*/}
                                                        {props.formIK.errors["SpouseBirthDate"] && (
                                                            <div className="text-error">{props.formIK.errors["SpouseBirthDate"]}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <hr/>
                                        </>
                                    )
                                }
                                {
                                    props.formIK.values.same_mailing_address === 'No' && (
                                        <>
                                            <Row style={{padding: "0px 15px", marginTop: '2%'}}>
                                                <GoogleAutoComplete placeholder="Search Mailing Address..."
                                                                    callback={mailingAddressAutoFillCallBack} />
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group key={props.formIK.values.mailing_address} controlId="formBasicEmail">
                                                        <Form.Label className="required-label"><strong>Mailing Address</strong></Form.Label>
                                                        <CustomTextInput disabled={false} required={false} name="mailing_address"
                                                                         className={"form-control"} id={"mailing_address-input"}
                                                                         value={props.formIK.values.mailing_address}
                                                                         onChange={(value)=>{
                                                                             if (value === ""){
                                                                                 props.formIK.errors['mailing_address'] = "Mailing Address is required.";
                                                                             }else{
                                                                                 delete props.formIK.errors['mailing_address'];
                                                                             }
                                                                             props.formIK.setFieldValue("mailing_address", value);
                                                                         }}
                                                        />
                                                        {props.formIK.errors["mailing_address"] && (
                                                            <div className="text-error">{props.formIK.errors["mailing_address"]}</div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group key="mailing_state" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Mailing State</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="mailing_state"
                                                                           value={props.formIK.values.mailing_state !== undefined ? props.formIK.values.mailing_state : ""}
                                                                           onChange={(value)=> {
                                                                               if (value === ""){
                                                                                   props.formIK.errors['mailing_state'] = "Mailing State is required.";
                                                                               }else{
                                                                                   delete props.formIK.errors['mailing_state'];
                                                                               }
                                                                               props.formIK.setFieldValue("mailing_state", value);
                                                                           }}
                                                                           options={cityState.getStates().sort().map((state)=>{return {label: state, value: state}})}/>
                                                        {props.formIK.errors["mailing_state"] && (
                                                            <div className="text-error">{props.formIK.errors["mailing_state"]}</div>
                                                        )}
                                                    </Form.Group>

                                                </Col>
                                                <Col>
                                                    <Form.Group key={props.formIK.values.mailing_zip} controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Mailing Zip Code</strong></Form.Label>
                                                        <ZipCode disabled={false} required={true} name="mailing_zip"
                                                                 className={"form-control"} id="mailing_zip-input"
                                                                 value={props.formIK.values.mailing_zip}
                                                                 onChange={(value)=>{
                                                                     if (value === "#####"){
                                                                         props.formIK.errors['mailing_zip'] = "Zip code is required.";
                                                                     }else{
                                                                         delete props.formIK.errors['mailing_zip'];
                                                                     }
                                                                     props.formIK.setFieldValue("mailing_zip", value);
                                                                 }}/>
                                                        {props.formIK.errors["mailing_zip"] && (
                                                            <div className="text-error">{props.formIK.errors["mailing_zip"]}</div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group key="mailing_city" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Mailing City</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="mailing_city"
                                                                           value={props.formIK.values.mailing_city !== undefined ? props.formIK.values.mailing_city : ""}
                                                                           onChange={(value)=> {
                                                                               if (value === ""){
                                                                                   props.formIK.errors['mailing_city'] = "Mailing City is required.";
                                                                               }else{
                                                                                   delete props.formIK.errors['mailing_city'];
                                                                               }
                                                                               props.formIK.setFieldValue("mailing_city", value)
                                                                           }}
                                                                           options={props.formIK.values.mailing_state !== "" ? cityState.getCitiesOfState(props.formIK.values.mailing_state).map((city)=>{return {label: city.city, value: city.city}}).sort((a, b)=>(a.value > b.value ? 1 : -1)) : []}/>
                                                        {props.formIK.errors["mailing_city"] && (
                                                            <div className="text-error">{props.formIK.errors["mailing_city"]}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }
                                {/*{*/}
                                {/*    (((props.formIK.values.sage_sure === true ||*/}
                                {/*                props.formIK.values.asi_progressive === true) &&*/}
                                {/*            props.formIK.values['current_address_liv_duration'] === "2 months or less") ||*/}
                                {/*        props.formIK.values.branch === true) &&*/}
                                {/*    <>*/}
                                {/*        <Row style={{padding: "0px 15px"}}>*/}
                                {/*            <Form.Label className="required-label"><strong>Prior Address</strong></Form.Label>*/}
                                {/*            <GoogleAutoComplete placeholder="Search Prior Address..."*/}
                                {/*                                callback={priorAddressAutoFillCallBack} />*/}
                                {/*        </Row>*/}
                                {/*        <Row>*/}
                                {/*            <Col style={{marginTop: "4%"}}>*/}
                                {/*                <Form.Group key={refreshPriorAddress} className="mb-3" controlId="formBasicEmail">*/}
                                {/*                    <CustomTextInput disabled={false} required={false} name="prior_address"*/}
                                {/*                                     className={"form-control"} id={"prior_address-input"}*/}
                                {/*                                     value={props.formIK.values.prior_address}*/}
                                {/*                                     onChange={(value)=>{*/}
                                {/*                                         if (value === ""){*/}
                                {/*                                             props.formIK.errors['prior_address'] = "Prior address is required.";*/}
                                {/*                                         }else{*/}
                                {/*                                             delete props.formIK.errors['prior_address'];*/}
                                {/*                                         }*/}
                                {/*                                         props.formIK.setFieldValue("prior_address", value);*/}
                                {/*                                     }}*/}
                                {/*                    />*/}
                                {/*                    {props.formIK.errors["prior_address"] && (*/}
                                {/*                        <div className="text-error">{props.formIK.errors["prior_address"]}</div>*/}
                                {/*                    )}*/}
                                {/*                </Form.Group>*/}
                                {/*                <Form.Group key="prior_state" controlId="formBasicEmail"*/}
                                {/*                            style={{padding: "0px 15px 0px 0px"}}>*/}
                                {/*                    <Form.Label className='required-label'><strong>Prior State</strong></Form.Label>*/}
                                {/*                    <CustomSelectInput disabled={false} name="prior_state"*/}
                                {/*                                       value={props.formIK.values.prior_state !== undefined ? props.formIK.values.prior_state : ""}*/}
                                {/*                                       onChange={(value)=> {*/}
                                {/*                                           if (value === ""){*/}
                                {/*                                               props.formIK.errors['prior_state'] = "Prior State is required.";*/}
                                {/*                                           }else{*/}
                                {/*                                               delete props.formIK.errors['prior_state'];*/}
                                {/*                                           }*/}
                                {/*                                           props.formIK.setFieldValue("prior_state", value);*/}
                                {/*                                       }}*/}
                                {/*                                       options={cityState.getStates().sort().map((state)=>{return {label: state, value: state}})}/>*/}
                                {/*                    {props.formIK.errors["prior_state"] && (*/}
                                {/*                        <div className="text-error">{props.formIK.errors["prior_state"]}</div>*/}
                                {/*                    )}*/}
                                {/*                </Form.Group>*/}
                                {/*            </Col>*/}
                                {/*            <Col>*/}
                                {/*                <Form.Group key={refreshPriorAddress} controlId="formBasicEmail"*/}
                                {/*                            style={{padding: "0px 15px 0px 0px"}}>*/}
                                {/*                    <Form.Label className='required-label'><strong>Prior Zip Code</strong></Form.Label>*/}
                                {/*                    <ZipCode disabled={false} required={true} name="prior_zip"*/}
                                {/*                             className={"form-control"} id="prior_zip-input"*/}
                                {/*                             value={props.formIK.values.prior_zip}*/}
                                {/*                             onChange={(value)=>{*/}
                                {/*                                 if (value === "#####"){*/}
                                {/*                                     props.formIK.errors['prior_zip'] = "Prior Zip code is required.";*/}
                                {/*                                 }else{*/}
                                {/*                                     delete props.formIK.errors['prior_zip'];*/}
                                {/*                                 }*/}
                                {/*                                 props.formIK.setFieldValue("prior_zip", value);*/}
                                {/*                             }}/>*/}
                                {/*                    {props.formIK.errors["prior_zip"] && (*/}
                                {/*                        <div className="text-error">{props.formIK.errors["prior_zip"]}</div>*/}
                                {/*                    )}*/}
                                {/*                </Form.Group>*/}
                                {/*                <Form.Group key="prior_city" controlId="formBasicEmail"*/}
                                {/*                            style={{padding: "0px 15px 0px 0px"}}>*/}
                                {/*                    <Form.Label className='required-label'><strong>Prior City</strong></Form.Label>*/}
                                {/*                    <CustomSelectInput disabled={false} name="prior_city"*/}
                                {/*                                       value={props.formIK.values.prior_city !== undefined ? props.formIK.values.prior_city : ""}*/}
                                {/*                                       onChange={(value)=> {*/}
                                {/*                                           if (value === ""){*/}
                                {/*                                               props.formIK.errors['prior_city'] = "Prior City is required.";*/}
                                {/*                                           }else{*/}
                                {/*                                               delete props.formIK.errors['prior_city'];*/}
                                {/*                                           }*/}
                                {/*                                           props.formIK.setFieldValue("prior_city", value)*/}
                                {/*                                       }}*/}
                                {/*                                       options={props.formIK.values.prior_state !== '' ? cityState.getCitiesOfState(props.formIK.values.prior_state).map((city)=>{return {label: city.city, value: city.city}}).sort((a, b)=>(a.value > b.value ? 1 : -1)) : []}/>*/}
                                {/*                    {props.formIK.errors["prior_city"] && (*/}
                                {/*                        <div className="text-error">{props.formIK.errors["prior_city"]}</div>*/}
                                {/*                    )}*/}
                                {/*                </Form.Group>*/}
                                {/*            </Col>*/}
                                {/*        </Row>*/}
                                {/*    </>*/}
                                {/*}*/}


                                <Row style={{marginTop: "1%"}}>
                                    <Collapsible trigger={["Default Fields", <BsChevronDown />]} triggerStyle={{background: '#e3f2fd'}}>
                                        <>
                                            {
                                                props.formIK.values.openly === true && (
                                                    <Row>
                                                        <Col>
                                                            <Form.Group key="insurance_cancelled_last_3_years" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className='required-label' style={{marginBottom: "1%"}}><strong>Home, Condo or Renters Insurance cancelled in last 3 years?</strong></Form.Label>
                                                                <CustomSelectInput disabled={false} name="insurance_cancelled_last_3_years"
                                                                                   value={props.formIK.values.insurance_cancelled_last_3_years !== undefined ? props.formIK.values.insurance_cancelled_last_3_years : ""}
                                                                                   onChange={(value)=> {
                                                                                       if (value === ""){
                                                                                           props.formIK.errors['insurance_cancelled_last_3_years'] = "Home, Condo or Renters Insurance cancelled in last 3 years? is required.";
                                                                                       }else{
                                                                                           delete props.formIK.errors['insurance_cancelled_last_3_years'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("insurance_cancelled_last_3_years", value);
                                                                                   }}
                                                                                   options={[{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}]}/>
                                                                {props.formIK.errors["insurance_cancelled_last_3_years"] && (
                                                                    <div className="text-error">{props.formIK.errors["insurance_cancelled_last_3_years"]}</div>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group key="occupation" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className='required-label' style={{marginBottom: "1%"}}><strong>Occupation</strong></Form.Label>
                                                                <CustomSelectInput disabled={false} name="occupation"
                                                                                   value={props.formIK.values.occupation !== undefined ? props.formIK.values.occupation : ""}
                                                                                   onChange={(value)=> {
                                                                                       if (value === ""){
                                                                                           props.formIK.errors['occupation'] = "Occupation is required.";
                                                                                       }else{
                                                                                           delete props.formIK.errors['occupation'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("occupation", value);
                                                                                   }}
                                                                                   options={[{label: 'None', value: 'None'}, {label: 'Armed Services', value: 'Armed Services'},
                                                                                       {label: 'School Teacher', value: 'School Teacher'}, {label: 'Police', value: 'Police'},
                                                                                       {label: 'Firefighter', value: 'Firefighter'}]}/>
                                                                {props.formIK.errors["occupation"] && (
                                                                    <div className="text-error">{props.formIK.errors["occupation"]}</div>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            {
                                                props.formIK.values.branch === true && (
                                                    <>
                                                        {/*<Row>*/}
                                                            {/*<Col>*/}
                                                            {/*    <Form.Group key="current_auto_limit_bipd" controlId="formBasicEmail"*/}
                                                            {/*                style={{padding: "0px 15px 0px 0px"}}>*/}
                                                            {/*        <Form.Label className='required-label' style={{marginBottom: "1%"}}><strong>Current Auto Limit BIPD</strong></Form.Label>*/}
                                                            {/*        <CustomSelectInput disabled={false} name="current_auto_limit_bipd"*/}
                                                            {/*                           value={props.formIK.values.current_auto_limit_bipd !== undefined ? props.formIK.values.current_auto_limit_bipd : ""}*/}
                                                            {/*                           onChange={(value)=> {*/}
                                                            {/*                               if (value === ""){*/}
                                                            {/*                                   props.formIK.errors['current_auto_limit_bipd'] = "Current Auto Limit BIPD is required.";*/}
                                                            {/*                               }else{*/}
                                                            {/*                                   delete props.formIK.errors['current_auto_limit_bipd'];*/}
                                                            {/*                               }*/}
                                                            {/*                               props.formIK.setFieldValue("current_auto_limit_bipd", value);*/}
                                                            {/*                           }}*/}
                                                            {/*                           options={[{label: '$50K | $100K | $25K', value: '$50K | $100K | $25K'},*/}
                                                            {/*                               {label: '$50K | $100K | $100K', value: '$50K | $100K | $100K'},*/}
                                                            {/*                               {label: '$100K | $300K | $50K', value: '$100K | $300K | $50K'},*/}
                                                            {/*                               {label: '$250K | $500K | $50K', value: '$250K | $500K | $50K'}]}/>*/}
                                                            {/*        {props.formIK.errors["current_auto_limit_bipd"] && (*/}
                                                            {/*            <div className="text-error">{props.formIK.errors["current_auto_limit_bipd"]}</div>*/}
                                                            {/*        )}*/}
                                                            {/*    </Form.Group>*/}
                                                            {/*</Col>*/}
                                                            {/*<Col>*/}
                                                            {/*    <Form.Group key="auto_insurance_in_past_31_days" controlId="formBasicEmail"*/}
                                                            {/*                style={{padding: "0px 15px 0px 0px"}}>*/}
                                                            {/*        <Form.Label className='required-label' style={{marginBottom: "1%"}}><strong>Auto Insurance In Past 31 Days</strong></Form.Label>*/}
                                                            {/*        <CustomSelectInput disabled={false} name="auto_insurance_in_past_31_days"*/}
                                                            {/*                           value={props.formIK.values.auto_insurance_in_past_31_days !== undefined ? props.formIK.values.auto_insurance_in_past_31_days : ""}*/}
                                                            {/*                           onChange={(value)=> {*/}
                                                            {/*                               if (value === ""){*/}
                                                            {/*                                   props.formIK.errors['auto_insurance_in_past_31_days'] = "Auto Insurance In Past 31 Days is required.";*/}
                                                            {/*                               }else{*/}
                                                            {/*                                   delete props.formIK.errors['auto_insurance_in_past_31_days'];*/}
                                                            {/*                               }*/}
                                                            {/*                               props.formIK.setFieldValue("auto_insurance_in_past_31_days", value);*/}
                                                            {/*                           }}*/}
                                                            {/*                           options={[{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}]}/>*/}
                                                            {/*        {props.formIK.errors["auto_insurance_in_past_31_days"] && (*/}
                                                            {/*            <div className="text-error">{props.formIK.errors["auto_insurance_in_past_31_days"]}</div>*/}
                                                            {/*        )}*/}
                                                            {/*    </Form.Group>*/}
                                                            {/*</Col>*/}
                                                        {/*</Row>*/}
                                                        {/*<Row>*/}
                                                        {/*    <Col>*/}
                                                        {/*        <Form.Group key="continuous_auto_coverage_years" controlId="formBasicEmail"*/}
                                                        {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                                        {/*            <Form.Label className='required-label' style={{marginBottom: "1%"}}><strong>Continuous Auto Coverage Years</strong></Form.Label>*/}
                                                        {/*            <CustomNumberInput disabled={false} required={true} className="form-control"*/}
                                                        {/*                               name="continuous_auto_coverage_years"*/}
                                                        {/*                               id="prior_zip-input" value={props.formIK.values.continuous_auto_coverage_years}*/}
                                                        {/*                               onChange={(value)=>{*/}
                                                        {/*                                   if (value === ""){*/}
                                                        {/*                                       props.formIK.errors['continuous_auto_coverage_years'] = "Continuous Auto Coverage Years is required.";*/}
                                                        {/*                                   }else{*/}
                                                        {/*                                       delete props.formIK.errors['continuous_auto_coverage_years'];*/}
                                                        {/*                                   }*/}
                                                        {/*                                   props.formIK.setFieldValue("continuous_auto_coverage_years", value)*/}
                                                        {/*                               }}/>*/}
                                                        {/*            {props.formIK.errors["continuous_auto_coverage_years"] && (*/}
                                                        {/*                <div className="text-error">{props.formIK.errors["continuous_auto_coverage_years"]}</div>*/}
                                                        {/*            )}*/}
                                                        {/*        </Form.Group>*/}
                                                        {/*    </Col>*/}
                                                        {/*    <Col></Col>*/}
                                                        {/*</Row>*/}
                                                    </>
                                                )
                                            }
                                        </>
                                    </Collapsible>
                                </Row>

                                {/*<Row>*/}
                                {/*    <Form.Group key="address" className="mb-3" controlId="formBasicEmail">*/}
                                {/*        <Form.Label className="required-label">Address</Form.Label>*/}
                                {/*        <CustomLongInput disabled={false} required={false} name="address"*/}
                                {/*                         className={"form-control"} id={"address-input"}*/}
                                {/*                         value={props.formIK.values.address}*/}
                                {/*                         onChange={(value)=>{*/}
                                {/*                             if (value === ""){*/}
                                {/*                                 props.formIK.errors['address'] = "Address is required.";*/}
                                {/*                             }else{*/}
                                {/*                                 delete props.formIK.errors['address'];*/}
                                {/*                             }*/}
                                {/*                             props.formIK.setFieldValue("address", value);*/}
                                {/*                         }}*/}
                                {/*        />*/}
                                {/*        {props.formIK.errors["address"] && (*/}
                                {/*            <div className="text-error">{props.formIK.errors["address"]}</div>*/}
                                {/*        )}*/}
                                {/*    </Form.Group>*/}
                                {/*</Row>*/}
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button onClick={()=>props.changeStep(props.progress-props.step)} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={()=>validate_data()} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

Applicant.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
