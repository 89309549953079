import React, {useState} from "react";
import {BarChart} from '@mui/x-charts/BarChart';
import {Card, CardContent, Typography} from "@mui/material";


function LawFirmBondHistory(props) {

    return (
        <Card sx={{width: '100%', height: '100%'}}>
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    Bond History
                </Typography>
                <hr className="fs-1"/>
                <Typography gutterBottom component="div">
                    <BarChart
                        height={230}
                        series={[
                            {data: props.series, id: 'uvId'},
                        ]}
                        xAxis={[{data: props.labels, label: "Year", scaleType: 'band'}]}
                    />
                </Typography>
            </CardContent>
        </Card>
    )
}

export default LawFirmBondHistory;
