import {API_URL} from "../constants";
import {getAPICall} from "./helper";

export const getProcesses = async (category) => {
    return await getAPICall('/process?category_name=' + category);
}

export const getProcessInputs = async (procesId) => {
    return await getAPICall('/get_process_inputs/' + procesId);
}

export const triggerProcesses = async (processID) => {
    const token = localStorage.getItem('userdata')
    return await fetch(`${API_URL}/process/` + processID, {
        method: "POST",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        },
    })
        .then(async (r) => {
            if (r.status === 200) {
                return true;
            }
            if (r.status === 401) {
                localStorage.clear();
                window.location = "/";
            }
            return null;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        });
}

export const triggerProcessesWithData = async (processID, data, isFile) => {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    if (isFile !== true) {
        header['Content-Type'] = "application/json"
    }
    return await fetch(`${API_URL}/process/` + processID, {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    })
        .then(async (r) => {
            if (r.status === 200) {
                return true;
            }
            if (r.status === 401) {
                localStorage.clear();
                window.location = "/";
            }
            return null;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        });
}

export const filterInputOption = async (input_id, filter_data) => {
    return await getAPICall('/input_options/' + input_id + '?filter_value=' + encodeURIComponent(filter_data));
}

export const saveDraftCapital = async (processID, data, approval_id) => {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"

    return await fetch(`${API_URL}/create_draft_capital_project_history/` + processID + `/` + approval_id, {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    })
        .then(async (r) => {
            if (r.status === 200) {
                return await r.json();
            }
            if (r.status === 401) {
                localStorage.clear();
                window.location = "/";
            }
            return null;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        });
}

export const addCapitalProject = async (processID, data, approval_id) => {
    console.log()
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"
    console.log("approval_id", approval_id)
    if (approval_id) {
        return await fetch(`${API_URL}/submit_draft_capital_project_history/` + processID + `/` + approval_id, {
            method: "POST",
            headers: header,
            body: JSON.stringify(data),
        })
            .then(async (r) => {
                if (r.status === 200) {
                    return await r.json();
                }
                if (r.status === 401) {
                    localStorage.clear();
                    window.location = "/";
                }
                return null;
            })
            .catch((error) => {
                console.log(error.message);
                return null;
            });
    } else {
        return await fetch(`${API_URL}/create_capital_project_history/` + processID, {
            method: "POST",
            headers: header,
            body: JSON.stringify(data),
        })
            .then(async (r) => {
                if (r.status === 200) {
                    return await r.json();
                }
                if (r.status === 401) {
                    localStorage.clear();
                    window.location = "/";
                }
                return null;
            })
            .catch((error) => {
                console.log(error.message);
                return null;
            });
    }

}

export const uploadFiles = async (data, approval_id) => {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    return await fetch(`${API_URL}/upload_files/` + approval_id, {
        method: "POST",
        headers: header,
        body: data,
    })
        .then(async (r) => {
            if (r.status === 200) {
                return true;
            }
            if (r.status === 401) {
                localStorage.clear();
                window.location = "/";
            }
            return null;
        })
        .catch((error) => {
            console.log(error.message);
            return null;
        });
}

export const deleteUploadFile = async (data, approval_id) => {
    console.log("data", data)
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"
    return await fetch(`${API_URL}/delete_uploaded_file/` + approval_id, {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    })
        .then(async (r) => {
            if (r.status === 200) {
                return true;
            }
            return false;
        })
        .catch((error) => {
            console.log(error.message);
            return false;
        });
}

export const approveCapitalProject = async(data, approval_id)=>{

    let post_data = {data: {...data}, approval_record_id: approval_id}
    const token = localStorage.getItem('userdata');
    return await fetch(`${API_URL}/document_approval_approve/`, {
        method: "POST",
        body: JSON.stringify(post_data),
        headers: {
            'Authorization': 'Bearer '+token,
            "Content-Type": "application/json"
        },
    })
        .then(async (r) => {
            if (r.status === 200) {
                return true;
            }
            return false;
        })
        .catch((error) => {
            console.log(error.message);
            return false;
        });

}
