import React, {useEffect, useState, Fragment, useCallback} from 'react';
import {
    getVermostUserColumns, getVermostUsersPagination, getVermostInternalUsers, deleteVermostUserBackend, updateVermostUserBackend
} from "../../../../../services/company_management";
import {addUsers} from "../../../../../store/company_management";
import {useDispatch, useSelector} from "react-redux";
import CusNavBar from "../../../../../global_components/navbar";
import Footer from "../../../../../global_components/footer";
import LoadingOverlay from 'react-loading-overlay';
import ActiveAccountManagementPopup from "../../../../../components/popups/vermost/account_management_active_popup";
import CustomDataGrid from "../../../../../global_components/datatables/custom_datagrid";
import Header from "../../../../../global_components/header";
import {useNavigate} from "react-router";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {exportToExcel} from "../../../../../utils/excel_export";
import {useSnackbar} from "notistack";
import EditUserPopup from "../../../../../components/popups/edit_user_popup";
import AddVermostUserPopup from "../../../../../components/popups/add_vermost_user_popup";
import CusAlert from "../../../../../global_components/alert";
import GoBack from "../../../../../utils/go_back_button";
import {hasUserPermission} from "../../../../../utils/user_permission";
import {log} from "../../../../../utils/logger";

function UserList(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [deleteId, setDeleteId] = useState(0);
    const [deleteText, setDeleteText] = useState('');
    const [userJsonData, setUserJsonData] = useState([]);
    const [addUser, setAddUser] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [userToEdit, setUserToEdit] = useState(0);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');
    const hasAddPermission = hasUserPermission(user_permission, 'show_table_add_button')
    const [pendingApproval, setPendingApproval] = useState(false);

    // Datagrid Pagination
    if (!localStorage.getItem("user_list_pagesize")) {
        localStorage.setItem("user_list_pagesize", 10);
    }
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("user_list_pagesize"))
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);

    // Datagrid Filter
    const [queryOptions, setQueryOptions] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptions({filterModel: {...filterModel.items}}), 1000);
    }, []);

    const [prevFilter, setPrevFilter] = useState("");
    //Datagrid Sort
    const [queryOptionsSort, setQueryOptionsSort] = useState({});
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSort({sortModel: [...sortModel]});
    }, []);
    const [prevSort, setPrevSort] = useState("");

    const {enqueueSnackbar} = useSnackbar();

    const deleteUser = async (userId, showPopup) => {
        if (showPopup) {
            setDeleteText('user');
            setDeleteId(userId);
            return 0;
        }
        setLoadingMessage('Changing User Active field.....');
        setLoading(true);

        let resp = await deleteVermostUserBackend(userId)
        if (resp.status === 200) {
            enqueueSnackbar("User's active field changed successfully.", {variant: "success"});
            setPendingApproval(false);
            await refreshUser(false);
        } else {
            resp = await resp.json();
            enqueueSnackbar(resp, {variant: "error"});
        }
        setLoading(false);
    }

    const userColumns = getVermostUserColumns()

    const refreshUser = async (is_refresh) => {
        setUserLoading(true);
        let filter = getFilterAndSort();
        let response = await getVermostInternalUsers(controller.page, controller.pageSize, filter[0], filter[1]);
        if (response.status === 200) {
            const result = await response.json();
            dispatch(addUsers(result.data));
            setUserJsonData(result.data);
            console.log("result.data", result.data);
            result.data.forEach((item) => {
                if (item.is_new && !item.is_active) {
                    setPendingApproval(true);
                }
            })
            setRowCount(result.total);
            setUserLoading(false);
            if (is_refresh)
                enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
            return result.data;
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
            return 0;
        }
    }

    const onCallbackFun = async (type, obj) => {
        if (type === 'insert') {
            setAddUser(true);
        } else if (type === 'edit') {
            navigate(`/user_management/${obj.id}`)
        } else if (type === 'delete') {
            await deleteUser(obj.id, true);
        } else if (type === 'refresh') {
            await refreshUser(true);
        } else if (type === "download") {
           await exportUserData();
        } else if (type === 'notification') {
            const response = await updateVermostUserBackend(obj.id, {is_new: false, email: obj.email});
            if (response.status === 200) {
                await refreshUser(false);
                enqueueSnackbar("Notification closed.", {variant: "success"});
            } else {
                const result = await response.json();
                enqueueSnackbar(result, {variant: "error"});
            }
        }
    }
    const exportUserData = async () =>{
        const lastFilter = localStorage.getItem('storedFilter')?.split(',');
        let excelData = await getVermostUsersPagination('', '', lastFilter[0], lastFilter[1]);
        if (excelData.status === 200) {
            const result = await excelData.json();
            exportToExcel(result.data, "User");
        } else {
            const result = await excelData.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }
    const getFilterAndSort = () => {
        let filter = ""
        if (queryOptions.filterModel) {
            if (queryOptions.filterModel[0] !== undefined) {
                filter = queryOptions.filterModel[0].field + "-" + queryOptions.filterModel[0].operator + "-" + queryOptions.filterModel[0].value
                setPrevFilter(filter)
            } else {
                filter = prevFilter
            }
        }
        let sort = ""
        if (queryOptionsSort.sortModel) {
            if (queryOptionsSort.sortModel.length > 0) {
                if (prevSort) {
                    sort = prevSort
                }
                let sortModel = queryOptionsSort.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSort !== sort) {
                    setPrevSort(sort)
                } else {
                    if (queryOptions.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        localStorage.setItem('storedFilter', [filter, sort]);
        return [filter, sort]
    }

    useEffect(async () => {
        log("userToEdit,", userToEdit);
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        document.title = "Users";
        await refreshUser(false);
    }, [controller, queryOptions, queryOptionsSort]);

    return (
        <> <LoadingOverlay active={loading} spinner text={loadingMessage}>
            <CusNavBar/>
            <GoBack pageUrl="/account_management"></GoBack>
            <Header text="Internal Users"
                    warning={pendingApproval ? "You can approve pending users by setting their status to active." : ""}/>
            {addUser &&
                <>
                    {userToEdit !== 0 ? (
                            <AddVermostUserPopup show={true} user_id={userToEdit}
                                                 closePopup={userToEdit !== 0 ? setUserToEdit : setAddUser}
                                                 showSuccessAlert={setShowSuccessMessage}
                                                 setSuccessMessage={setSuccessMessage}
                                                 showFailureAlert={setShowFailureMessage}
                                                 setFailureMessage={setFailureMessage}/>
                        ) :
                        (
                            <EditUserPopup show={true} user_id={userToEdit}
                                           closePopup={userToEdit !== 0 ? setUserToEdit : setAddUser}
                                           showSuccessAlert={setShowSuccessMessage}
                                           setSuccessMessage={setSuccessMessage}
                                           showFailureAlert={setShowFailureMessage}
                                           setFailureMessage={setFailureMessage}
                                           vermostUser={true}/>
                        )}
                </>
            }
            {
                deleteId !== 0 && <ActiveAccountManagementPopup show={true} closePopup={setDeleteId}
                                                                delete_callback={deleteUser}
                                                                delete_id={deleteId} delete_text={deleteText}/>
            }
            {
                showSuccessMessage && <CusAlert alertType='success' alertMessage={successMessage}
                                                alertClose={setShowSuccessMessage}/>
            }
            {
                showFailureMessage && <CusAlert alertType='danger' alertMessage={failureMessage}
                                                alertClose={setShowFailureMessage}/>
            }

            <div className="col-12" style={{margin: "auto"}}>
                <CustomDataGrid rows={userJsonData}
                                columns={userColumns}
                                rowCount={rowCount}
                                paginationModel={controller}
                                onPaginationModelChange={gatedSetController}
                                onFilterModelChange={onFilterChange}
                                onSortModelChange={handleSortModelChange}
                                loading={userLoading}
                                isActionsVisible={true}
                                isEdit={true}
                                onCallback={onCallbackFun}
                                addPermission={hasAddPermission}
                                deletePermission={hasDeletePermission}
                                updatePermission={hasUpdatePermission}
                                visibleActionButtons={true}
                                userApproveNotificationDisable={true}
                    // filterModel={{
                    //     items: [{field: 'is_active', operator: 'is', value: true}]
                    // }}
                />
            </div>
        </LoadingOverlay>
            <Footer/>
        </>
    )
}


export default UserList;
