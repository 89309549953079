import React, {useEffect, useState} from 'react';
import '../styles/login.css';
import {useNavigate} from "react-router";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

function APBlock(props) {
    const navigate = useNavigate();
    const companyData = useSelector((state) => state.auth.company);
    useEffect(() => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
    }, [])

    return (
        <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 spaceCard">
            <div className="card">
                <div className="card-body">
                    <button onClick={() => {
                        if (companyData.name === 'FSI-ASI') {
                            navigate('/main_po/invoices')
                        }else{
                            navigate('/account_payable')
                        }
                    }} className="stretched-link">
                        <h4 className="card-title">{props.category_name}</h4>
                    </button>
                    {
                        props.invoices.length > 0 && <ul>
                            {
                                props.invoices.map((invoice, index) => {
                                    if (index < 2) {
                                        return (<li key={index}>{invoice['invoice_details']['seller_name'].value}</li>);
                                    }
                                })
                            }

                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}


export default APBlock;

APBlock.propTypes = {
    category_name: PropTypes.string.isRequired,
    invoices: PropTypes.array.isRequired,
};
