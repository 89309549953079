import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import '../../styles/login.css';
import PropTypes from "prop-types";
import AddBondNumberForm from "../vermost/vermost_utils/add_bond_number_form";



export default function AddBondNumberPopup(props) {
    const [loading, setLoading] = useState(false);
    
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Bond Number
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <AddBondNumberForm 
                        start_num=""
                        end_num=""
                        closePopup= {props.closePopup}
                    />
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(false);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
}

AddBondNumberPopup.prototype = {
    closePopup: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
}
