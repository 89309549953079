import React, {useEffect, useState, Fragment} from 'react';
import {
    getUsers,
    getUserColumns,
    deleteUserBackend,
    getDepartments,
    getDepartmentColumns,
    deleteDepartmentBackend,
    getAPPermissions,
    getAPPermissionColumns,
    deleteAPPermissionBackend,
    getPOPermissions,
    getPOPermissionColumns,
    deletePOPermissionBackend,
    getSuperPOUsers,
    getSuperPOUserColumns,
    getProcessPermissionColumns,
    getProcessPermissions,
    deleteProcessPermissionBackend,
    getLawFirms,
    getLawFirmColumns,
    deleteLawFirmBackend,
    getLawFirmContacts,
    getLawFirmContactColumns,
    deleteLawFirmContactBackend,
    getLawFirmBranches,
    getLawFirmBranchColumns,
    deleteLawFirmBranchBackend,
    getLawSchools,
    getLawSchoolColumns,
    deleteLawSchoolBackend,
    getLawFirmProfessionalGuardians,
    getLawFirmProfessionalGuardianColumns,
    deleteLawFirmProfessionalGuardianBackend, getBondNumbers, getBondNumberColumns,

} from "../services/company_management";
import {
    addUsers,
    removeUser,
    addDepartments,
    removeDepartment,
    addAPPermissions,
    removeAPPermission,
    addPOPermissions,
    removePOPermission,
    addSuperPOUsers,
    addProcessPermissions,
    removeProcessPermission,
    addLawFirms,
    removeLawFirm,
    addLawFirmContacts,
    removeLawFirmContact,
    addLawFirmBranches,
    removeLawFirmBranch,
    addLawSchools,
    removeLawSchool,
    updateVermostUserActiveStatus,
    addLawFirmProfessionalGuardians,
    removeLawFirmProfessionalGuardian, addBondNumbers, removeBondNumbers, removeSuperPOUser, removeAPPermissions,
    removeUsers, removeDepartmentsCM, removePOPermissions, removeSuperPOUsers, removeProcessPermissions, removeLawFirms,
    removeLawFirmContacts, removeLawFirmProfessionalGuardians, removeLawFirmBranches, removeLawSchools

} from "../store/company_management";
import {useDispatch, useSelector} from "react-redux";
import {Row, Tab, Tabs} from "react-bootstrap";
// import LawFirmCusTable from './datatables/law_firms_table';
// import LawFirmContactCusTable from './datatables/law_firm_contacts_table';
// import LawFirmBranchCusTable from './datatables/law_firm_branches_table';
// import LawSchoolCusTable from './datatables/law_schools_table';
import APTableLoading from "./loadings/ap_table_loading";
import CusNavBar from "./navbar";
import Footer from "./footer";
import CusAlert from "./alert";
import LoadingOverlay from 'react-loading-overlay';
import AddVermostUserPopup from '../components/popups/add_vermost_user_popup';
import EditUserPopup from "../components/popups/edit_user_popup";
import EditDepartmentPopup from "../components/popups/edit_department_popup";
import APPermissionPopup from "../components/popups/ap_permission_popup";
import POPermissionPopup from "../components/popups/po_permission_popup";
import SuperPOUserPopup from "../components/popups/super_po_user_popup";
import DeleteCompanyManagementPopup from "../components/popups/company_management_delete_popup";
import AddBondNumberPopup from '../components/popups/add_bond_number_popup';
import LawFirmPopup from '../components/popups/law_firm_popup';
import LawFirmContactPopup from '../components/popups/law_firm_contact_popup';
import LawFirmBranchPopup from '../components/popups/law_firm_branch_popup';
import LawSchoolPopup from '../components/popups/law_school_popup';
import CustomDataGrid from "./simple_data_grid";
import Header from "./header";
import Select from "react-select";
import {addSelectedPermissionAP, removeDepartments} from "../store/auth";
import {useNavigate} from "react-router";
import ProcessPermissionPopup from "../components/popups/process_permission_popup";
import {margin} from '@mui/system';
import LawFirmProfessionalGuardianPopup from "../components/popups/law_firm_professional_guardian_popup";
import {hasUserPermission} from "../utils/user_permission";

function CompanyManagement(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [userLoading, setUserLoading] = useState(true);
    const [firstUserLoading, setFirstUserLoading] = useState(true);
    const [departmentLoading, setDepartmentLoading] = useState(true);
    const [firstDepartmentLoading, setFirstDepartmentLoading] = useState(true);
    const [firstLawSchoolLoading, setFirstLawSchoolLoading] = useState(true);
    const [firstLawFirmLoading, setFirstLawFirmLoading] = useState(true);
    const [poPermissionLoading, setPOPermissionLoading] = useState(false);
    const [superPOUserLoading, setSuperPOUserLoading] = useState(false);
    const [apPermissionLoading, setAPPermissionLoading] = useState(false);
    const [processPermissionLoading, setProcessPermissionLoading] = useState(false);
    const [lawFirmLoading, setLawFirmLoading] = useState(false);
    const [lawFirmContactLoading, setLawFirmContactLoading] = useState(false);
    const [lawFirmProfessionalGuardianLoading, setLawFirmProfessionalGuardianLoading] = useState(false);
    const [lawFirmBranchLoading, setLawFirmBranchLoading] = useState(false);
    const [LawSchoolLoading, setLawSchoolLoading] = useState(false);
    const [bondNumberLoading, setBondNumberLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [failureMessage, setFailureMessage] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('');
    const [userToEdit, setUserToEdit] = useState(0);
    const [departmentToEdit, setDepartmentToEdit] = useState(0);
    const [apPermissionToEdit, setAPPermissionToEdit] = useState(0);
    const [poPermissionToEdit, setPOPermissionToEdit] = useState(0);
    const [superPOUserToEdit, setSuperPOUserToEdit] = useState(0);
    const [processPermissionToEdit, setProcessPermissionToEdit] = useState(0);
    const [lawFirmToEdit, setLawFirmToEdit] = useState(0);
    const [lawFirmContactLoaded, setLawFirmContactLoaded] = useState(false);
    const [lawFirmContactToEdit, setLawFirmContactToEdit] = useState(0);
    const [lawFirmProfessionalGuardianLoaded, setLawFirmProfessionalGuardianLoaded] = useState(false);
    const [lawFirmProfessionalGuardianToEdit, setLawFirmProfessionalGuardianToEdit] = useState(0);
    const [lawFirmBranchToEdit, setLawFirmBranchToEdit] = useState(0);
    const [LawSchoolToEdit, setLawSchoolToEdit] = useState(0);
    const [addDepartment, setAddDepartment] = useState(false);
    const [addUser, setAddUser] = useState(false);
    const [addAPPermission, setAddAPPermission] = useState(false);
    const [addPOPermission, setAddPOPermission] = useState(false);
    const [addPOSuperUser, setAddSuperPOUser] = useState(false);
    const [addProcessPermission, setAddProcessPermission] = useState(false);
    const [addLawFirm, setAddLawFirm] = useState(false);
    const [addLawFirmContact, setAddLawFirmContact] = useState(false);
    const [addLawFirmProfessionalGuardian, setAddLawFirmProfessionalGuardian] = useState(false);
    const [addLawFirmBranch, setAddLawFirmBranch] = useState(false);
    const [addLawSchool, setAddLawSchool] = useState(false);
    const [addBondNumber, setAddBondNumber] = useState(false);
    const [addDepartmentButton, setAddDepartmentButton] = useState(false);
    const [addAPPermissionButton, setAddAPPermissionButton] = useState(false);
    const [addPOPermissionButton, setAddPOPermissionButton] = useState(false);
    const [addSuperPOUserButton, setAddSuperPOUserButton] = useState(false);
    const [addUserButton, setAddUserButton] = useState(true);
    const [addProcessPermissionButton, setAddProcessPermissionButton] = useState(false);
    const [addLawFirmButton, setAddLawFirmButton] = useState(false);
    const [addLawFirmContactButton, setAddLawFirmContactButton] = useState(false);
    const [addLawFirmProfessionalGuardianButton, setAddLawFirmProfessionalGuardianButton] = useState(false);
    const [addLawFirmBranchButton, setAddLawFirmBranchButton] = useState(false);
    const [addLawSchoolButton, setAddLawSchoolButton] = useState(false);
    const [addBondNumberButton, setAddBondNumberButton] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [deleteText, setDeleteText] = useState('');
    const [filterDepartmentId, setFilterDepartmentId] = useState(0);
    const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false);
    const usersInStore = useSelector((state) => state.companyManagement.users);
    const departmentsInStore = useSelector((state) => state.companyManagement.departments);
    const superPOUsersInStore = useSelector((state) => state.companyManagement.super_po_users);
    const company = useSelector((state) => state.auth.company.name)
    const apPermissionsInStore = useSelector((state) => {
        if (state.companyManagement.ap_permissions) {
            return state.companyManagement.ap_permissions.filter((apPerm) => apPerm.department === filterDepartmentId)
        }
        return []
    });
    const allAPPermissionsInStore = useSelector((state) => state.companyManagement.ap_permissions);
    const poPermissionsInStore = useSelector((state) => {
        if (state.companyManagement.po_permissions) {
            return state.companyManagement.po_permissions.filter((poPerm) => poPerm.department === filterDepartmentId);
        }
        return [];
    });
    const allPOPermissionsInStore = useSelector((state) => state.companyManagement.po_permissions);

    const processPermissionsInStore = useSelector((state) => {
        if (state.companyManagement.process_permissions) {
            return state.companyManagement.process_permissions.filter((processPerm) => processPerm.department === filterDepartmentId);
        }
        return [];
    });
    const allProcessPermissionsInStore = useSelector((state) => state.companyManagement.process_permissions);
    const companyManagement = useSelector((state) => state.companyManagement);
    const allLawFirmsInStore = useSelector((state) => state.companyManagement.law_firms);
    const allLawFirmContactsInStore = useSelector((state) => state.companyManagement.law_firm_contacts);
    const allLawFirmProfessionalGuardiansInStore = useSelector((state) => state.companyManagement.law_firm_professional_guardians);
    const allLawFirmBranchesInStore = useSelector((state) => state.companyManagement.law_firm_branches);
    const allLawSchoolsInStore = useSelector((state) => state.companyManagement.law_schools);
    const allBondNumbers = useSelector((state) => state.companyManagement.bond_numbers);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');

    const getDepartmentOptions = () => {
        let depOpts = [];
        departmentsInStore.map((dep) => depOpts.push({label: dep.name, value: dep.id}));
        depOpts.sort(function (a, b) {
            var textA = a.label.toUpperCase();
            var textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        return depOpts;
    }
    const deleteUser = (userId, showPopup) => {
        if (showPopup) {
            setDeleteText('user');
            setDeleteId(userId);
            return 0;
        }
        setLoadingMessage('Deleting User.....');
        setLoading(true);
        deleteUserBackend(userId, company).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('User deleted successfully.');
                if (company === 'Vermost') {
                    dispatch(updateVermostUserActiveStatus({id: userId}));
                } else {
                    dispatch(removeUser({id: userId}));
                }
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete user sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete user sorry for inconvenience.');
        });
    }
    const updateUser = (userId) => {
        setUserToEdit(userId);
    }
    const userColumns = getUserColumns(deleteUser, updateUser, company, hasUpdatePermission, hasDeletePermission)

    const deleteDepartment = (depId, showPopup) => {
        if (showPopup) {
            setDeleteText('department');
            setDeleteId(depId);
            return 0;
        }
        setLoadingMessage('Deleting Department.....');
        setLoading(true);
        deleteDepartmentBackend(depId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('Department deleted successfully.');
                dispatch(removeDepartment({id: depId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete department sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete department sorry for inconvenience.');
        });
    }
    const updateDepartment = (depId) => {
        setDepartmentToEdit(depId);
    }
    const departmentColumns = getDepartmentColumns(deleteDepartment, updateDepartment, hasUpdatePermission, hasDeletePermission);

    const deleteAPPermission = (apPermissionId, showPopup) => {
        if (showPopup) {
            setDeleteText('AP Permission');
            setDeleteId(apPermissionId);
            return 0;
        }
        setLoadingMessage('Deleting AP Permission.....');
        setLoading(true);
        deleteAPPermissionBackend(apPermissionId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('AP Permission deleted successfully.');
                dispatch(removeAPPermission({id: apPermissionId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete AP Permission sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete AP Permission sorry for inconvenience.');
        });
    }
    const updateAPPermission = (apPermissionId) => {
        setAPPermissionToEdit(apPermissionId);
    }
    const apPermissionColumns = getAPPermissionColumns(deleteAPPermission, updateAPPermission, hasUpdatePermission, hasDeletePermission);

    const deletePOPermission = (poPermissionId, showPopup) => {
        if (showPopup) {
            setDeleteText('PO Permission');
            setDeleteId(poPermissionId);
            return 0;
        }
        setLoadingMessage('Deleting AP Permission.....');
        setLoading(true);
        deletePOPermissionBackend(poPermissionId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('PO permission deleted successfully.');
                dispatch(removePOPermission({id: poPermissionId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete PO Permission sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete PO Permission sorry for inconvenience.');
        });
    }
    const updatePOPermission = (poPermissionId) => {
        setPOPermissionToEdit(poPermissionId);
    }
    const poPermissionColumns = getPOPermissionColumns(deletePOPermission, updatePOPermission, hasUpdatePermission, hasDeletePermission);

    const deleteProcessPermission = (processPermissionId, showPopup) => {
        if (showPopup) {
            setDeleteText('Process Permission');
            setDeleteId(processPermissionId);
            return 0;
        }
        setLoadingMessage('Deleting Process Permission.....');
        setLoading(true);
        deleteProcessPermissionBackend(processPermissionId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('Process permission deleted successfully.');
                dispatch(removeProcessPermission({id: processPermissionId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete Process Permission sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete Process Permission sorry for inconvenience.');
        });
    }
    const updateProcessPermission = (processPermissionId) => {
        setProcessPermissionToEdit(processPermissionId);
    }
    const processPermissionColumns = getProcessPermissionColumns(deleteProcessPermission, updateProcessPermission, hasUpdatePermission, hasDeletePermission);

    const updateSuperPOUser = (superPOUserId) => {
        setSuperPOUserToEdit(superPOUserId);
    }
    const superPOUserColumns = getSuperPOUserColumns(updateSuperPOUser, hasUpdatePermission);


    const deleteLawFirm = (lawFirmId, showPopup) => {
        if (showPopup) {
            setDeleteText('Law Firm');
            setDeleteId(lawFirmId);
            return 0;
        }
        setLoadingMessage('Deleting Law Firm.....');
        setLoading(true);
        deleteLawFirmBackend(lawFirmId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('Law Firm deleted successfully.');
                dispatch(removeLawFirm({id: lawFirmId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete Law Firm sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete Law Firm sorry for inconvenience.');
        });
    }
    const updateLawFirm = (lawFirmId) => {
        setLawFirmToEdit(lawFirmId);
    }
    const lawFirmColumns = getLawFirmColumns(deleteLawFirm, updateLawFirm, hasUpdatePermission, hasDeletePermission);


    const deleteLawFirmContact = (lawFirmContactId, showPopup) => {
        if (showPopup) {
            setDeleteText('Law Firm Contact');
            setDeleteId(lawFirmContactId);
            return 0;
        }
        setLoadingMessage('Deleting Law Firm Contact.....');
        setLoading(true);
        deleteLawFirmContactBackend(lawFirmContactId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('Law Firm Contact deleted successfully.');
                dispatch(removeLawFirmContact({id: lawFirmContactId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete Law Firm Contact sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete Law Firm Contact sorry for inconvenience.');
        });
    }
    const updateLawFirmContact = (lawFirmContactId) => {
        setLawFirmContactToEdit(lawFirmContactId);
    }
    const lawFirmContactColumns = getLawFirmContactColumns(deleteLawFirmContact, updateLawFirmContact, hasUpdatePermission, hasDeletePermission);


    const deleteLawFirmProfessionalGuardian = (lawFirmProfessionalGuardianId, showPopup) => {
        if (showPopup) {
            setDeleteText('Professional Guardian');
            setDeleteId(lawFirmProfessionalGuardianId);
            return 0;
        }
        setLoadingMessage('Deleting Professional Guardian.....');
        setLoading(true);
        deleteLawFirmProfessionalGuardianBackend(lawFirmProfessionalGuardianId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('Professional Guardian deleted successfully.');
                dispatch(removeLawFirmProfessionalGuardian({id: lawFirmProfessionalGuardianId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete Professional Guardian sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete Professional Guardian sorry for inconvenience.');
        });
    }
    const updateLawFirmProfessionalGuardian = (lawFirmProfessionalGuardianId) => {
        setLawFirmProfessionalGuardianToEdit(lawFirmProfessionalGuardianId);
    }
    const lawFirmProfessionalGuardianColumns = getLawFirmProfessionalGuardianColumns(deleteLawFirmProfessionalGuardian, updateLawFirmProfessionalGuardian, hasUpdatePermission, hasDeletePermission);


    const deleteLawFirmBranch = (lawFirmBranchId, showPopup) => {
        if (showPopup) {
            setDeleteText('Law Firm Branch');
            setDeleteId(lawFirmBranchId);
            return 0;
        }
        setLoadingMessage('Deleting Law Firm Branch.....');
        setLoading(true);
        deleteLawFirmBranchBackend(lawFirmBranchId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('Law Firm Branch deleted successfully.');
                dispatch(removeLawFirmBranch({id: lawFirmBranchId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete Law Firm Branch sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete Law Firm Branch sorry for inconvenience.');
        });
    }
    const updateLawFirmBranch = (lawFirmBranchId) => {
        setLawFirmBranchToEdit(lawFirmBranchId);
    }
    const lawFirmBranchColumns = getLawFirmBranchColumns(deleteLawFirmBranch, updateLawFirmBranch, hasUpdatePermission, hasDeletePermission);


    const deleteLawSchool = (LawSchoolId, showPopup) => {
        if (showPopup) {
            setDeleteText('Law School');
            setDeleteId(LawSchoolId);
            return 0;
        }
        setLoadingMessage('Deleting Law School.....');
        setLoading(true);
        deleteLawSchoolBackend(LawSchoolId).then((code) => {
            if (code === 200) {
                setShowSuccessMessage(true);
                setSuccessMessage('Law School deleted successfully.');
                dispatch(removeLawSchool({id: LawSchoolId}));
            } else {
                setShowFailureMessage(true);
                setFailureMessage('Unable to delete Law School sorry for inconvenience.');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setShowFailureMessage(true);
            setFailureMessage('Unable to delete Law School sorry for inconvenience.');
        });
    }
    const updateLawSchool = (LawSchoolId) => {
        setLawSchoolToEdit(LawSchoolId);
    }
    const LawSchoolColumns = getLawSchoolColumns(deleteLawSchool, updateLawSchool, hasUpdatePermission, hasDeletePermission);

    const bondNumberColumns = getBondNumberColumns()


    const bnNumber = {
        marginLeft: "10px",
        float: "right"
    };

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        document.title = "Account Management";

        if (userLoading || firstUserLoading) {
            refreshUser(firstUserLoading);
        } else {
            setUserLoading(false);
        }

        if ((company !== 'Vermost' && departmentLoading) || firstDepartmentLoading) {
            refreshDepartment(firstDepartmentLoading);
        } else {
            setDepartmentLoading(false);
        }

        if ((company === 'Vermost'  && LawSchoolLoading) || firstLawSchoolLoading) {
                 refreshLawSchool(firstLawSchoolLoading);
             } else {
                 setLawSchoolLoading(false);
             }
        if ((company === 'Vermost' &&  lawFirmLoading) || firstLawFirmLoading) {
                 refreshLawFirm(firstLawFirmLoading);
             } else {
                 setLawFirmLoading(false);
             }
        // else {    setDepartmentLoading(false);
        // }
        // if (company === 'Oglebay' && allAPPermissionsInStore && allAPPermissionsInStore.length === 0 && apPermissionLoading) {
        //     refreshAPPermission();
        // } else {
        //     setAPPermissionLoading(false);
        // }
        // if (company === 'Oglebay' && allPOPermissionsInStore && allPOPermissionsInStore.length === 0 && poPermissionLoading) {
        //     refreshPOPermission();
        // } else {
        //     setPOPermissionLoading(false);
        // }
        // if (company === 'Oglebay' && superPOUsersInStore && superPOUsersInStore.length === 0 && superPOUserLoading) {
        //     refreshSuperPOUser();
        // } else {
        //     setSuperPOUserLoading(false);
        // }
        //
        // if (company === 'Oglebay' && allProcessPermissionsInStore && allProcessPermissionsInStore.length === 0 && processPermissionLoading) {
        //     refreshProcessPermissions();
        // } else {
        //     setProcessPermissionLoading(false);
        // }
        //
        // if (company === 'Vermost' && allLawFirmsInStore && allLawFirmsInStore.length === 0 && lawFirmLoading) {
        //     refreshLawFirm();
        // } else {
        //     setLawFirmLoading(false);
        // }
        //
        // if (company === 'Vermost' && lawFirmContactLoaded === false) {
        //     refreshLawFirmContacts();
        // } else {
        //     setLawFirmContactLoading(false);
        // }
        //
        // if (company === 'Vermost' && allLawFirmProfessionalGuardiansInStore && allLawFirmProfessionalGuardiansInStore.length === 0 && lawFirmProfessionalGuardianLoading) {
        //     refreshLawFirmProfessionalGuardian();
        // } else {
        //     setLawFirmProfessionalGuardianLoading(false);
        // }
        //
        // if (company === 'Vermost' && allLawFirmBranchesInStore && allLawFirmBranchesInStore.length === 0 && lawFirmBranchLoading) {
        //     refreshLawFirmBranch();
        // } else {
        //     setLawFirmBranchLoading(false);
        // }
        //
        // if (company === 'Vermost' && allLawSchoolsInStore && allLawSchoolsInStore.length === 0 && LawSchoolLoading) {
        //     refreshLawSchool();
        // } else {
        //     setLawSchoolLoading(false);
        // }
        // if (company === 'Vermost' && allBondNumbers && allBondNumbers.length === 0 && bondNumberLoading) {
        //     refreshBondNumber();
        // } else {
        //     setBondNumberLoading(false);
        // }

    });
    const refreshUser = (val) => {
        if (!userLoading || val) {
            setFirstUserLoading(false);
            setUserLoading(true);
            if (company === 'Vermost') {
                getUsers('/bond/add_vermost_user/').then((response) => {
                    dispatch(addUsers(response));
                    setUserLoading(false);
                }).catch((error) => {
                    setUserLoading(false);
                    console.log(error);
                });
            } else {
                getUsers('/user/users/').then((response) => {
                    dispatch(addUsers(response));
                    setUserLoading(false);
                }).catch((error) => {
                    setUserLoading(false);
                    console.log(error);
                });
            }
        }
    }

    const refreshDepartment = (val) => {
        if (!departmentLoading || val) {
            setFirstDepartmentLoading(false);
            setDepartmentLoading(true);
            getDepartments().then((response) => {
                dispatch(removeDepartmentsCM());
                dispatch(addDepartments(response));
                setDepartmentLoading(false);
            }).catch((error) => {
                setDepartmentLoading(false);
                console.log(error);
            });
        }
    }
    const refreshAPPermission = () => {
        if (!apPermissionLoading) {
            setAPPermissionLoading(true);
            getAPPermissions().then((response) => {
                dispatch(removeAPPermissions());
                dispatch(addAPPermissions(response));
                setAPPermissionLoading(false);
            }).catch((error) => {
                setAPPermissionLoading(false);
                console.log(error);
            });
        }
    }
    const refreshPOPermission = () => {
        if (!poPermissionLoading) {
            setPOPermissionLoading(true);
            getPOPermissions().then((response) => {
                dispatch(removePOPermissions());
                dispatch(addPOPermissions(response));
                setPOPermissionLoading(false);
            }).catch((error) => {
                setPOPermissionLoading(false);
                console.log(error);
            });
        }
    }
    const refreshSuperPOUser = () => {
        if (!superPOUserLoading) {
            setSuperPOUserLoading(true);
            getSuperPOUsers().then((response) => {
                dispatch(removeSuperPOUsers());
                dispatch(addSuperPOUsers(response));
                setSuperPOUserLoading(false);
            }).catch((error) => {
                setSuperPOUserLoading(false);
                console.log(error);
            });
        }
    }
    const refreshProcessPermissions = () => {
        if (!processPermissionLoading) {
            setProcessPermissionLoading(true);
            getProcessPermissions().then((response) => {
                dispatch(removeProcessPermissions());
                dispatch(addProcessPermissions(response));
                setProcessPermissionLoading(false);
            }).catch((error) => {
                setProcessPermissionLoading(false);
                console.log(error);
            });
        }
    }
    const refreshLawFirm = (val) => {
        if (!lawFirmLoading || val) {
            setFirstLawFirmLoading(false);
            setLawFirmLoading(true);
            getLawFirms().then((response) => {
                dispatch(removeLawFirms());
                dispatch(addLawFirms(response['data']));
                setLawFirmLoading(false);
            }).catch((error) => {
                setLawFirmLoading(false);
                console.log(error);
            });
        }
    }
    const refreshLawFirmContacts = () => {
        if (!lawFirmContactLoading) {
            setLawFirmContactLoading(true);
            getLawFirmContacts().then((response) => {
                dispatch(removeLawFirmContacts());
                dispatch(addLawFirmContacts(response['data']));
                setLawFirmContactLoading(false);
            }).catch((error) => {
                setLawFirmContactLoading(false);
                console.log(error);
            });
        }
    }
    const refreshLawFirmProfessionalGuardian = () => {
        if (!lawFirmProfessionalGuardianLoading) {
            setLawFirmProfessionalGuardianLoading(true);
            getLawFirmProfessionalGuardians().then((response) => {
                dispatch(removeLawFirmProfessionalGuardians());
                dispatch(addLawFirmProfessionalGuardians(response['data']));
                setLawFirmProfessionalGuardianLoading(false);
            }).catch((error) => {
                setLawFirmProfessionalGuardianLoading(false);
                console.log(error)
            });
        }
    }
    const refreshLawFirmBranch = () => {
        if (!lawFirmBranchLoading) {
            setLawFirmBranchLoading(true);
            getLawFirmBranches().then((response) => {
                dispatch(removeLawFirmBranches());
                dispatch(addLawFirmBranches(response['data']));
                setLawFirmBranchLoading(false);
            }).catch((error) => {
                setLawFirmBranchLoading(false);
                console.log(error);
            });
        }
    }
    const refreshLawSchool = (val) => {
        if (!LawSchoolLoading || val) {
            setFirstLawSchoolLoading(false);
            setLawSchoolLoading(true);
            getLawSchools().then((response) => {
                dispatch(removeLawSchools());
                dispatch(addLawSchools(response['data']));
                setLawSchoolLoading(false);
            }).catch((error) => {
                setLawSchoolLoading(false);
                console.log(error);
            });
        }
    }
    const refreshBondNumber = () => {
        if (!bondNumberLoading) {
            setBondNumberLoading(true);
            getBondNumbers().then((res) => {
                dispatch(removeBondNumbers());
                dispatch(addBondNumbers(res));
                setBondNumberLoading(false);
            }).catch(error => {
                console.log(error);
                setBondNumberLoading(false);
            });
        }
    }

    return (
        <> <LoadingOverlay active={loading} spinner text={loadingMessage}>
            <CusNavBar/>
            <Header text="Account Management"/>
            {
                showSuccessMessage && <CusAlert alertType='success' alertMessage={successMessage}
                                                alertClose={setShowSuccessMessage}/>
            }
            {
                showFailureMessage && <CusAlert alertType='danger' alertMessage={failureMessage}
                                                alertClose={setShowFailureMessage}/>
            }
            {
                deleteId !== 0 && <DeleteCompanyManagementPopup show={true} closePopup={setDeleteId}
                                                                delete_callback={
                                                                    deleteText === 'user' ? deleteUser :
                                                                        deleteText === 'department' ? deleteDepartment :
                                                                            deleteText === 'PO Permission' ? deletePOPermission :
                                                                                deleteText === 'Process Permission' ? deleteProcessPermission :
                                                                                    deleteText === 'AP Permission' ? deleteAPPermission :
                                                                                        deleteText === 'Law Firm' ? deleteLawFirm :
                                                                                            deleteText === 'Law Firm Contact' ? deleteLawFirmContact :
                                                                                                deleteText === 'Law Firm Branch' ? deleteLawFirmBranch :
                                                                                                    deleteText === 'Professional Guardian' ? deleteLawFirmProfessionalGuardian :
                                                                                                        deleteLawSchool}
                                                                delete_id={deleteId} delete_text={deleteText}/>
            }
            {showDepartmentDropdown &&
                <div style={{margin: "1% 0%"}}>
                    <Select
                        placeholder={"Select Department"}
                        className="col-3 small-select-field"
                        name="selected_permission"
                        onChange={async (opt, e) => {
                            setFilterDepartmentId(opt.value);
                        }}
                        options={getDepartmentOptions()}
                        defaultValue={getDepartmentOptions().filter((opt) => opt.value === filterDepartmentId)}
                    />
                </div>
            }
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10" style={{margin: "auto"}}>
                {addBondNumberButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={bnNumber} onClick={() => setAddBondNumber(true)} className="btn btn-warning">Add
                        Bond Number</button>
                }
                {addDepartmentButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddDepartment(true)}
                            className="btn btn-primary">Add
                        Department</button>}
                {addUserButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddUser(true)} className="btn btn-primary">Add
                        User</button>}
                {/*addUserButton && company !== 'Vermost' &&
                    <button style={{float: "right"}} onClick={() => setAddUser(true)} className="btn btn-primary">Add
                        User</button>*/}
                {addAPPermissionButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddAPPermission(true)}
                            className="btn btn-primary">Add
                        AP Permission</button>}
                {addPOPermissionButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddPOPermission(true)}
                            className="btn btn-primary">Add
                        PO Permission</button>}
                {addSuperPOUserButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddSuperPOUser(true)}
                            className="btn btn-primary">Add
                        Super PO User</button>}
                {addProcessPermissionButton &&
                    <button style={{float: "right"}} onClick={() => setAddProcessPermission(true)}
                            className="btn btn-primary">Add
                        Process Permission</button>}
                {addLawFirmButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddLawFirm(true)} className="btn btn-primary">Add
                        Law Firm</button>}
                {addLawFirmContactButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddLawFirmContact(true)}
                            className="btn btn-primary">Add
                        Law Firm Contact</button>}
                {addLawFirmProfessionalGuardianButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddLawFirmProfessionalGuardian(true)}
                            className="btn btn-primary">Add
                        Professional Guardian</button>}
                {addLawFirmBranchButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddLawFirmBranch(true)}
                            className="btn btn-primary">Add
                        Law Firm Address</button>}
                {addLawSchoolButton && hasUserPermission(user_permission, 'show_table_add_button') &&
                    <button style={{float: "right"}} onClick={() => setAddLawSchool(true)}
                            className="btn btn-primary">Add
                        Law School</button>}
                <Tabs defaultActiveKey='user' id="uncontrolled-tab-example" className="mb-3"
                      onSelect={(key) => {
                          if (key === 'department') {
                              console.log("1 refreshDepartment");
                              refreshDepartment();
                              setAddDepartmentButton(true);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'user') {
                              refreshUser();
                              setAddDepartmentButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddUserButton(true);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'ap_permission') {
                              refreshAPPermission();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddPOPermissionButton(false);
                              setAddAPPermissionButton(true);
                              setShowDepartmentDropdown(true);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'po_permission') {
                              refreshPOPermission();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(true);
                              setShowDepartmentDropdown(true);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'super_po_user') {
                              refreshSuperPOUser();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddSuperPOUserButton(true);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'process_permission') {
                              refreshProcessPermissions();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(true);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(true);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'law_firm') {
                              refreshLawFirm();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(true);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'law_firm_contact') {
                              refreshLawFirmContacts();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(true);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'law_firm_professional_guardian') {
                              refreshLawFirmProfessionalGuardian();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(true);
                              setAddBondNumberButton(false);
                          } else if (key === 'law_firm_branch') {
                              refreshLawFirmBranch();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(true);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'law_school') {
                              refreshLawSchool();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(true);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(false);
                          } else if (key === 'bond_numbers') {
                              refreshBondNumber();
                              setAddDepartmentButton(false);
                              setAddUserButton(false);
                              setAddAPPermissionButton(false);
                              setAddPOPermissionButton(false);
                              setShowDepartmentDropdown(false);
                              setAddSuperPOUserButton(false);
                              setAddProcessPermissionButton(false);
                              setAddLawFirmButton(false);
                              setAddLawFirmContactButton(false);
                              setAddLawFirmBranchButton(false);
                              setAddLawSchoolButton(false);
                              setAddLawFirmProfessionalGuardianButton(false);
                              setAddBondNumberButton(true);
                          }
                      }}>
                    <Tab eventKey="user" title="Users">
                        <CustomDataGrid style={{height: "500px"}} rows={usersInStore}
                                        columns={userColumns} pageSize={10}
                                        loading={userLoading}/>
                    </Tab>
                    {company !== 'Vermost' &&
                        <Tab eventKey="department" title="Departments">
                            <CustomDataGrid style={{height: "500px"}}
                                            rows={departmentsInStore.filter(d => d.is_hidden === false)}
                                            columns={departmentColumns} pageSize={10}
                                            loading={departmentLoading}/>
                        </Tab>
                    }
                    {company === 'Oglebay' &&
                        <Tab eventKey="ap_permission" title="AP Permissions">

                            <CustomDataGrid style={{height: "500px"}} rows={apPermissionsInStore}
                                            columns={apPermissionColumns} pageSize={10}
                                            loading={apPermissionLoading} isRequiredDepartment={true}/>
                        </Tab>
                    }
                    {company === 'Oglebay' &&
                        <Tab eventKey="po_permission" title="PO Permissions">

                            <CustomDataGrid style={{height: "500px"}} rows={poPermissionsInStore}
                                            columns={poPermissionColumns} pageSize={10}
                                            loading={poPermissionLoading} isRequiredDepartment={true}/>
                        </Tab>
                    }
                    {company === 'Oglebay' &&
                        <Tab eventKey="process_permission" title="Process Permissions">

                            <CustomDataGrid style={{height: "500px"}} rows={processPermissionsInStore}
                                            columns={processPermissionColumns} pageSize={10}
                                            loading={processPermissionLoading} isRequiredDepartment={true}/>
                        </Tab>
                    }
                    {company === 'Oglebay' &&
                        <Tab eventKey="super_po_user" title="Super PO Users">

                            <CustomDataGrid style={{height: "500px"}} rows={superPOUsersInStore}
                                            columns={superPOUserColumns} pageSize={10}
                                            loading={superPOUserLoading} isRequiredDepartment={true}/>
                        </Tab>
                    }
                    {company === 'Vermost' &&
                        <Tab eventKey="law_firm" title="Law Firm">
                            <CustomDataGrid style={{height: "500px"}} rows={allLawFirmsInStore}
                                            columns={lawFirmColumns} pageSize={10}
                                            loading={lawFirmLoading}/>
                        </Tab>
                    }
                    {company === 'Vermost' &&
                        <Tab eventKey="law_firm_contact" title="Law Firm Contact">

                            <CustomDataGrid style={{height: "500px"}} rows={allLawFirmContactsInStore}
                                            columns={lawFirmContactColumns} pageSize={10}
                                            loading={lawFirmContactLoading}/>
                            {/* <LawFirmContactCusTable columns={lawFirmContactColumns}
                                            reload={true} /> */}
                        </Tab>
                    }
                    {company === 'Vermost' &&
                        <Tab eventKey="law_firm_branch" title="Law Firm Address">

                            <CustomDataGrid style={{height: "500px"}} rows={allLawFirmBranchesInStore}
                                            columns={lawFirmBranchColumns} pageSize={10}
                                            loading={lawFirmBranchLoading}/>
                        </Tab>
                    }
                    {company === 'Vermost' && hasUserPermission(user_permission, 'show_law_school_account_management') &&
                        <Tab eventKey="law_school" title="Law School">
                            <CustomDataGrid style={{height: "500px"}} rows={allLawSchoolsInStore}
                                            columns={LawSchoolColumns} pageSize={10}
                                            loading={LawSchoolLoading}/>

                        </Tab>
                    }
                    {company === 'Vermost' && hasUserPermission(user_permission, 'show_professional_guardian_account_management') &&
                        <Tab eventKey="law_firm_professional_guardian" title="Professional Guardian">

                            <CustomDataGrid style={{height: "500px"}} rows={allLawFirmProfessionalGuardiansInStore}
                                            columns={lawFirmProfessionalGuardianColumns} pageSize={10}
                                            loading={lawFirmProfessionalGuardianLoading}/>

                        </Tab>
                    }
                    {company === 'Vermost' && hasUserPermission(user_permission, 'show_bond_number_account_management') &&
                        <Tab eventKey="bond_numbers" title="Bond Numbers">
                            <CustomDataGrid style={{height: "500px"}} rows={allBondNumbers}
                                            columns={bondNumberColumns} pageSize={10}
                                            loading={bondNumberLoading}/>

                        </Tab>
                    }
                </Tabs>
            </div>
            {addBondNumber &&
                <AddBondNumberPopup show={true} user_id={userToEdit} closePopup={setAddBondNumber}
                                    showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                    showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}/>
            }
            {(userToEdit !== 0 || addUser) && (
                // company === 'Vermost' ? (
                //     <AddVermostUserPopup show={true} user_id={userToEdit} closePopup={userToEdit!==0 ? setUserToEdit : setAddUser}
                //                     showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                //                     showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}/>
                // ) : (
                <EditUserPopup show={true} user_id={userToEdit}
                               closePopup={userToEdit !== 0 ? setUserToEdit : setAddUser}
                               showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                               showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}
                               firstUserLoading={setFirstUserLoading}/>

                // )
            )}
            {(departmentToEdit !== 0 || addDepartment) &&
                <EditDepartmentPopup show={true} dep_id={departmentToEdit}
                                     closePopup={departmentToEdit !== 0 ? setDepartmentToEdit : setAddDepartment}
                                     showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                     showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}
                                     firstDepartmentLoading={setFirstDepartmentLoading}/>
            }
            {(apPermissionToEdit !== 0 || addAPPermission) &&
                <APPermissionPopup show={true} appermission_id={apPermissionToEdit}
                                   closePopup={apPermissionToEdit !== 0 ? setAPPermissionToEdit : setAddAPPermission}
                                   showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                   showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}/>
            }
            {(poPermissionToEdit !== 0 || addPOPermission) &&
                <POPermissionPopup show={true} popermission_id={poPermissionToEdit}
                                   closePopup={poPermissionToEdit !== 0 ? setPOPermissionToEdit : setAddPOPermission}
                                   showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                   showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}/>
            }
            {(processPermissionToEdit !== 0 || addProcessPermission) &&
                <ProcessPermissionPopup show={true} process_permission_id={processPermissionToEdit}
                                        closePopup={processPermissionToEdit !== 0 ? setProcessPermissionToEdit : setAddProcessPermission}
                                        showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                        showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}/>
            }
            {(superPOUserToEdit !== 0 || addPOSuperUser) &&
                <SuperPOUserPopup show={true} superpouser_id={superPOUserToEdit}
                                  closePopup={superPOUserToEdit !== 0 ? setSuperPOUserToEdit : setAddSuperPOUser}
                                  showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                  showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}/>
            }
            {(lawFirmToEdit !== 0 || addLawFirm) &&
                <LawFirmPopup show={true} law_firm_id={lawFirmToEdit}
                              closePopup={lawFirmToEdit !== 0 ? setLawFirmToEdit : setAddLawFirm}
                              showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                              showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}
                              firstLawFirmLoading={setFirstLawFirmLoading}/>
            }
            {(lawFirmContactToEdit !== 0 || addLawFirmContact) &&
                <LawFirmContactPopup show={true} law_firm_contact_id={lawFirmContactToEdit}
                                     closePopup={lawFirmContactToEdit !== 0 ? setLawFirmContactToEdit : setAddLawFirmContact}
                                     showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                     showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}/>
            }
            {(lawFirmBranchToEdit !== 0 || addLawFirmBranch) &&
                <LawFirmBranchPopup show={true} law_firm_branch_id={lawFirmBranchToEdit}
                                    closePopup={lawFirmBranchToEdit !== 0 ? setLawFirmBranchToEdit : setAddLawFirmBranch}
                                    showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                    showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}/>
            }
            {(LawSchoolToEdit !== 0 || addLawSchool) &&
                <LawSchoolPopup show={true} law_school_id={LawSchoolToEdit}
                                closePopup={LawSchoolToEdit !== 0 ? setLawSchoolToEdit : setAddLawSchool}
                                showSuccessAlert={setShowSuccessMessage} setSuccessMessage={setSuccessMessage}
                                showFailureAlert={setShowFailureMessage} setFailureMessage={setFailureMessage}
                                firstLawSchoolLoading = {setFirstLawSchoolLoading}/>
            }
            {(lawFirmProfessionalGuardianToEdit !== 0 || addLawFirmProfessionalGuardian) &&
                <LawFirmProfessionalGuardianPopup show={true}
                                                  law_firm_professional_guardian_id={lawFirmProfessionalGuardianToEdit}
                                                  closePopup={lawFirmProfessionalGuardianToEdit !== 0 ? setLawFirmProfessionalGuardianToEdit : setAddLawFirmProfessionalGuardian}
                                                  showSuccessAlert={setShowSuccessMessage}
                                                  setSuccessMessage={setSuccessMessage}
                                                  showFailureAlert={setShowFailureMessage}
                                                  setFailureMessage={setFailureMessage}/>
            }


        </LoadingOverlay>
            <Footer/>
        </>
    )
}


export default CompanyManagement;
