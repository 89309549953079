import React, { useState } from 'react';
import PropTypes from "prop-types";
import PatternFormat  from "react-number-format";

export default function USPhoneNumberWithoutCountryCodeUniversal(props) {
    const [value, onChange] = useState(props.value);

    return (
        <PatternFormat className={props.className} name={props.name} id={props.id} defaultValue={value}
                       format="(###) ### ####" allowEmptyFormatting mask="_"
                       disabled={props.disabled} onChange={(value)=> {
                           onChange(value.target.value);
                           props.onChange(value.target.value);
                       }}/>
    );
}

USPhoneNumberWithoutCountryCodeUniversal.propTypes = {
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
