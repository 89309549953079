import {getAPICall, getAPICallv2} from './helper';
import React from "react";
import {API_URL, email_url} from "../constants";
import {log} from "../utils/logger";

export async function getUsers(url) {
    return await getAPICall(url);
}

export async function getVermostUsersPagination(page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/add_vermost_user/?page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function getVermostInternalUsers(page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/vermost_internal_users/?page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function getVermostLawFirmUsersPagination(id, type, page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/list_law_firm_users/${id}?&position_type=${type}&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function addVermostLawFirmUser(id, data) {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"
    const response = await fetch(`${API_URL}/bond/add_law_firm_user/${id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return await response;
}

export async function addWebAdministrator(id, data) {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"
    const response = await fetch(`${API_URL}/bond/add_web_administrator/${id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return await response;
}

export async function addVermostUserLawFirm(id, data) {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"
    const response = await fetch(`${API_URL}/bond/add_user_law_firm/${id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}

export async function editVermostUserLawFirm(id, data) {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"
    const response = await fetch(`${API_URL}/bond/edit_user_law_firm/${id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}

export async function editVermostLawFirmUser(id, editID, data) {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"
    const response = await fetch(`${API_URL}/bond/edit_law_firm_user/${editID}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }
    console.log("editVermostLawFirmUser", response)
    return response;
}

export async function getVermostLawFirmLocationsPagination(id, page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/list_law_firm_locations/${id}?&&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function getVermostLawFirmLocations(id) {
    return await getAPICall(`/bond/list_law_firm_locations/${id}`);
}

export async function getVermostLocations(page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/location_list/?&&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function deleteVermostLocationBackend(id) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/delete_location/${id}`, {
        method: "POST",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}

export async function addVermostLawFirmLocation(law_firm_id, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/add_law_firm_location/${law_firm_id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}

export async function editVermostLawFirmLocation(location_id, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/edit_law_firm_location/${location_id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}


export async function getVermostLocationUsersPagination(id, type, page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/list_location_users/${id}?&position_type=${type}&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function addLocationUser(id, data) {
    const token = localStorage.getItem('userdata');
    let header = {'Authorization': 'Bearer ' + token};
    header['Content-Type'] = "application/json"
    const response = await fetch(`${API_URL}/bond/add_location_user/${id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return await response;
}

export function getUserColumns(deleteFun, updateFun, company, updatePermission, deletePermission) {
    let cols = [
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'first_name',
            headerName: 'First Name',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        }
    ]
    if (company === 'Vermost') {
        cols.push(
            {
                field: 'user_role_name',
                headerName: 'User Role',
                minWidth: 100, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'is_active',
                headerName: 'Is Active',
                minWidth: 100,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                type: 'boolean'
            },
        )
    } else {
        cols.push(
            {
                field: 'department',
                headerName: 'Department',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'user_role_name',
                headerName: 'User Role',
                minWidth: 100, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
        )
    }
    cols.push(
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (
                    <div>
                        {
                            updatePermission &&
                            <button onClick={() => updateFun(params.row.id)} className="btn btn-primary">Update</button>
                        }
                        {
                            deletePermission &&
                            <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
                                    className="btn btn-danger">Delete</button>
                        }

                    </div>

                )
            },
        })
    return cols
}

const customFilterOperators = {
    string: [
        {
            value: 'contains',
            label: 'contains',
        },
    ],
};

export function getVermostUserColumns() {
    let cols = [
        {
            field: 'full_name',
            headerName: 'Name',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            filter: customFilterOperators.string
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            filter: customFilterOperators.string
        },
        {
            field: 'phone_number',
            headerName: 'Phone',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            filter: customFilterOperators.string
        },
        {
            field: 'user_role_name',
            headerName: 'Permission Level',
            minWidth: 100, maxWidth: 1000, flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            filter: customFilterOperators.string
        },
        {
            field: 'position',
            headerName: 'Position',
            minWidth: 100, maxWidth: 1000, flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            filter: customFilterOperators.string
        },
        {
            field: 'is_active',
            headerName: 'Active',
            minWidth: 80,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
            sortable: false,
            filter: customFilterOperators.string
        },
        {
            field: 'id',
            headerName: '',
            minWidth: 200,
            maxWidth: 1000,
            align: 'right',
            disableColumnMenu: true,
            flex: 1,
            sortable: false,
            editable: false,
            headerAlign: 'right',
            filterable: false,
            headerClassName: 'header-bold'
        }
    ]
    return cols
}

export async function deleteUserBackend(userId, company) {
    let url = `${API_URL}/user/users/` + userId;
    if (company === 'Vermost') {
        url = `${API_URL}/bond/add_vermost_user/?user_id=` + userId;
    }
    const token = localStorage.getItem('userdata');
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function deleteVermostUserBackend(userId) {
    let url = `${API_URL}/bond/add_vermost_user/?user_id=` + userId;
    const token = localStorage.getItem('userdata');
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}

export async function updateUserBackend(userId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/users/${userId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function getDepartments() {
    return await getAPICall('/departments/');
}

export async function getUserRoles() {
    return await getAPICall('/user/get_user_roles/');
}

export async function getAllLawSchools() {
    return await getAPICall('/bond/list_all_law_schools/');
}

export async function getUserLawFirms(user_id, page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/list_user_law_firms/${user_id}?&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export function getDepartmentColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'name',
            headerName: 'Department Name',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'company',
            headerName: 'Company Name',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'code',
            headerName: 'Department Code',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (params.row.code !== undefined && params.row.code !== null ? params.row.code.replace('.0', '') : '')
            },
        },
        {
            field: 'po_approval_threshold',
            headerName: 'PO Amount Threshold',
            minWidth: 120, maxWidth: 1000, flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (
                    <div>
                        {updatePermission && <button onClick={() => updateFun(params.row.id)}
                                                     className="btn btn-primary">Update</button>
                        }
                        {deletePermission &&
                            <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
                                    className="btn btn-danger">Delete
                            </button>
                        }
                    </div>)
            },
        },

    ]
}

export async function deleteDepartmentBackend(depId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/departments/` + depId, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function updateDepartmentBackend(depId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/departments/${depId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function addDepartmentBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/departments/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return await response.json();
}

export async function add_user_email(recipient, sender_name, company_id) {
    const data = {
        emailBody: "<p>Hi,</p><p>You have been invited to join Blue Ocean portal by " + sender_name + ".</p><p>Please click on the following link to complete the registration process:</p><a href='https://portal.blueoceansp.com/add_user?cID=" + company_id + "&eID=" + recipient + "'>https://portal.blueoceansp.com/add_user</a><p>Regards,</p><p>Blue Ocean Team.</p>",
        emailSubject: "Invitation to join Blue Ocean portal.",
        emailStyle: null,
        recipientsTo: `${recipient};`,
        recipientsCC: null,
        recipientsBCC: null,
        attachments: null
    }


    const response = await fetch(`${email_url}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function addUserBackend(data) {
    const response = await fetch(`${API_URL}/user/users/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function getAPPermissions() {
    return await getAPICall('/ap_permissions/');
}

export async function deleteAPPermissionBackend(apPermissionId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap_permissions/${apPermissionId}`, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export function getAPPermissionColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'user_name',
            headerName: 'User',
            minWidth: 250,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'department_name', headerName: 'Department', minWidth: 180, maxWidth: 1000, flex: 1, editable: false,
            headerClassName: 'header-bold',
        },
        {
            field: 'approvement_level',
            headerName: 'Approvement Level',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
        },
        {
            field: 'modify_permission',
            headerName: 'Can Modify',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'posting_permission',
            headerName: 'Can Post',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'reject_permission',
            headerName: 'Can Reject',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'delete_permission',
            headerName: 'Can Delete',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (
                    <div>
                        {updatePermission && <button onClick={() => updateFun(params.row.id)}
                                                     className="btn btn-primary">Update</button>
                        }
                        {deletePermission &&
                            <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
                                    className="btn btn-danger">Delete
                            </button>
                        }
                    </div>)
            },
        },

    ]
}

export async function addAPPermissionBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap_permissions/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return await response.json();
}

export async function updateAPPermissionBackend(apPermissionId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap_permissions/${apPermissionId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function getPOPermissions() {
    return await getAPICall('/user/po_permissions/');
}

export async function deletePOPermissionBackend(poPermissionId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/po_permissions/${poPermissionId}`, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export function getPOPermissionColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'user_name',
            headerName: 'User',
            minWidth: 250,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'department_name', headerName: 'Department', minWidth: 180, maxWidth: 1000, flex: 1, editable: false,
            headerClassName: 'header-bold',
        },
        {
            field: 'view_account_permission',
            headerName: 'Can View Account',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (
                    <div>
                        <div>
                            {updatePermission && <button onClick={() => updateFun(params.row.id)}
                                                         className="btn btn-primary">Update</button>
                            }
                            {deletePermission &&
                                <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
                                        className="btn btn-danger">Delete
                                </button>
                            }
                        </div>
                    </div>)
            },
        },

    ]
}

export async function addPOPermissionBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/po_permissions/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return await response.json();
}

export async function updatePOPermissionBackend(poPermissionId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/po_permissions/${poPermissionId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export function getProcessPermissionColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'user_name',
            headerName: 'User',
            minWidth: 250,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'department_name', headerName: 'Department', minWidth: 180, maxWidth: 1000, flex: 1, editable: false,
            headerClassName: 'header-bold',
        },
        {
            field: 'trigger_permission',
            headerName: 'Can Trigger Process',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (
                    <div>
                        <div>
                            {updatePermission && <button onClick={() => updateFun(params.row.id)}
                                                         className="btn btn-primary">Update</button>
                            }
                            {deletePermission &&
                                <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
                                        className="btn btn-danger">Delete
                                </button>
                            }
                        </div>
                    </div>)
            },
        },

    ]
}

export async function getProcessPermissions() {
    return await getAPICall('/user/process_permissions/');
}

export async function addProcessPermissionBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/process_permissions/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return await response.json();
}

export async function updateProcessPermissionBackend(processPermissionId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/process_permissions/${processPermissionId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function deleteProcessPermissionBackend(processPermissionId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/process_permissions/${processPermissionId}`, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function getSuperPOUsers() {
    return await getAPICall('/user/super_po_users/');
}

export function getSuperPOUserColumns(updateFun, updatePermission) {
    return [
        {
            field: 'user_name',
            headerName: 'User',
            minWidth: 250,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'is_super_po', headerName: 'Super PO user', minWidth: 120, maxWidth: 1000, flex: 1, editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'id',
            headerName: 'Action',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (<div>
                    {updatePermission && <button style={{marginLeft: "5%"}} onClick={() => updateFun(params.row.id)}
                                                 className="btn btn-primary">Update</button>
                    }
                </div>)
            },
        },

    ]
}

export async function addSuperPOUserBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/super_po_users/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return await response.json();
}

export async function updateSuperPOUserBackend(superPOUserId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/super_po_users/${superPOUserId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function validateUserEmail(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/user/users/user_email_check/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    }).catch((error) => {
        return error
    });
    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }
    if (response.status === 200) {
        return response.status
    } else {
        return await response.json();
    }
}


export async function getLawFirms() {
    return await getAPICall(`/bond/law_firms/`);
}

export async function getVermostLawFirms(page, pageSize, filter, sort, is_new_control) {
    return await getAPICallv2(`/bond/vermost_law_firms/?&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}&is_new_control=${is_new_control}`);
}

export async function getUserLawFirm(id) {
    return await getAPICall(`/bond/get_user_law_firm/${id}`);
}

export function getLawFirmColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'is_active',
            headerName: 'Active',
            minWidth: 50,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
            sortable: false,
        },
        {
            field: 'id',
            headerName: '',
            minWidth: 250,
            maxWidth: 1000,
            align: 'right',
            disableColumnMenu: true,
            flex: 1,
            sortable: false,
            editable: false,
            headerAlign: 'right',
            filterable: false,
            headerClassName: 'header-bold'
        }
        // {
        //     field: 'id',
        //     headerName: 'Actions',
        //     minWidth: 180,
        //     maxWidth: 1000,
        //     sortable: false,
        //     filterable: false,
        //     flex: 1,
        //     editable: false,
        //     align: 'right',
        //     headerClassName: 'header-bold',
        //     renderCell: (params) => {
        //         return (
        //             <div>
        //                 {updatePermission && <button onClick={() => updateFun(params.row.id)}
        //                                              className="btn btn-primary">Update</button>
        //                 }
        //                 {deletePermission &&
        //                     <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
        //                             className="btn btn-danger">Delete
        //                     </button>
        //                 }
        //             </div>
        //         )
        //     },
        // },

    ]
}


export async function addVermostLawFirmBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/vermost_law_firms/`, {
        method: "POST",
        body: data,
        headers: {
            'Authorization': 'Bearer ' + token,
            // "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    // if (!response.ok) {
    //     const message = `An error has occured: ${response.status}`;
    //     throw new Error(message);
    // } else {
    //     const res = await response.json();
    //     res['status'] = response.status;
    //     return res;
    // }
    return response;
}

export async function editVermostLawFirmBackend(lawFirmId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/vermost_law_firms/${lawFirmId}/`, {
        method: "PATCH",
        body: data,
        headers: {
            'Authorization': 'Bearer ' + token,
            // "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    } else {
        log("response,", response);
        const res = await response;
        return res;
    }
}

export async function addLawFirmBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/law_firms/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    } else {
        const res = await response.json();
        res['status'] = response.status;
        return res;
    }
}


export async function deleteLawFirmBackend(lawFirmId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/vermost_law_firms/` + lawFirmId, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}


export async function deleteVermostLawFirmBackend(lawFirmId) {
    // const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/delete_law_firm/` + lawFirmId, {
        method: "DELETE",
        headers: {
            // 'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}

export async function updateLawFirmBackend(lawFirmId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/law_firms/${lawFirmId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}


export async function getLawFirmContacts() {
    return await getAPICall(`/bond/contacts/`);
}


export function getLawFirmContactColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'full_name',
            headerName: 'Full Name',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'phone_number',
            headerName: 'Phone Number',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'role',
            headerName: 'Role',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'bar',
            headerName: 'Bar',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (
                    <div>
                        {updatePermission && <button onClick={() => updateFun(params.row.id)}
                                                     className="btn btn-primary">Update</button>
                        }
                        {deletePermission &&
                            <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
                                    className="btn btn-danger">Delete
                            </button>
                        }

                    </div>
                )
            },
        },

    ]
}


export async function deleteLawFirmContactBackend(lawFirmContactId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/contacts/` + lawFirmContactId, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}


export async function addLawFirmContactBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/contacts/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    } else {
        const res = await response.json();
        res['status'] = response.status;
        return res;
    }

}


export async function updateLawFirmContactBackend(lawFirmContactId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/contacts/${lawFirmContactId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}


export async function getLawFirmProfessionalGuardians() {
    return await getAPICall(`/bond/professional_guardians/`);
}

export async function getLawFirmProfessionalGuardiansPagination(page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/professional_guardians/?&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}


export async function deleteLawFirmProfessionalGuardianBackend(lawFirmProfessionalGuardianId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/professional_guardians/` + lawFirmProfessionalGuardianId, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}


export async function addLawFirmProfessionalGuardianBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/professional_guardians/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    } else {
        const res = await response.json();
        res['status'] = response.status;
        return res;
    }

}


export async function updateLawFirmProfessionalGuardianBackend(lawFirmProfessionalGuardianId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/professional_guardians/${lawFirmProfessionalGuardianId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}


export function getLawFirmProfessionalGuardianColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'full_name',
            headerName: 'Full Name',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'phone_number',
            headerName: 'Phone Number',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        // {
        //     field: 'role',
        //     headerName: 'Role',
        //     minWidth: 150,
        //     maxWidth: 1000,
        //     flex: 1,
        //     editable: false,
        //     headerClassName: 'header-bold'
        // },
        // {
        //     field: 'oppg',
        //     headerName: 'OPPG',
        //     minWidth: 120,
        //     maxWidth: 1000,
        //     flex: 1,
        //     editable: false,
        //     headerClassName: 'header-bold'
        // },
        {
            field: 'is_active',
            headerName: 'Active',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
            sortable: false,
        },
        {
            field: 'id',
            headerName: '',
            minWidth: 180,
            maxWidth: 1000,
            align: 'right',
            flex: 1,
            sortable: false,
            editable: false,
            headerAlign: 'right',
            filterable: false,
            headerClassName: 'header-bold'
        }
        // {
        //     field: 'id',
        //     headerName: 'Actions',
        //     minWidth: 180,
        //     maxWidth: 1000,
        //     flex: 1,
        //     sortable: false,
        //     editable: false,
        //     headerAlign: 'right',
        //     filterable: false,
        //     headerClassName: 'header-bold',
        //     renderCell: (params) => {
        //         return (
        //             <div>
        //                 {updatePermission && <button onClick={() => updateFun(params.row.id)}
        //                                              className="btn btn-primary">Update</button>
        //                 }
        //                 {deletePermission &&
        //                     <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
        //                             className="btn btn-danger">Delete
        //                     </button>
        //                 }
        //
        //             </div>
        //         )
        //     },
        // },

    ]
}


export async function getLawFirmBranches() {
    return await getAPICall(`/bond/law_firm_addresses/`);
}


export function getLawFirmBranchColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'law_firm_name',
            headerName: 'Law Firm',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
        },
        {
            field: 'law_firm_customer_number',
            headerName: 'Law Firm Customer Number',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
        },
        {
            field: 'address',
            headerName: 'Address',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'city',
            headerName: 'City',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'state',
            headerName: 'State',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'zip_code',
            headerName: 'Zip Code',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (
                    <div>
                        {updatePermission && <button onClick={() => updateFun(params.row.id)}
                                                     className="btn btn-primary">Update</button>
                        }
                        {deletePermission &&
                            <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
                                    className="btn btn-danger">Delete
                            </button>
                        }
                    </div>
                )
            },
        },

    ]
}


export async function deleteLawFirmBranchBackend(lawFirmBranchId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/law_firm_addresses/` + lawFirmBranchId, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}


export async function addLawFirmBranchBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/law_firm_addresses/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    } else {
        const res = await response.json();
        res['status'] = response.status;
        return res;
    }
}


export async function updateLawFirmBranchBackend(lawFirmBranchId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/law_firm_addresses/${lawFirmBranchId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}


export async function getLawSchools() {
    return await getAPICall(`/bond/law_schools/`);
}

export async function getBondNumbers() {
    return await getAPICall(`/bond/list_bond_number/`);
}

export async function getBondNumbersPagination(page, pageSize, filter, sort) {
    return await getAPICallv2(`/bond/list_bond_number/?&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export function getLawSchoolColumns(deleteFun, updateFun, updatePermission, deletePermission) {
    return [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'is_active',
            headerName: 'Is Active',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return (
                    <div>
                        {updatePermission && <button onClick={() => updateFun(params.row.id)}
                                                     className="btn btn-primary">Update</button>
                        }
                        {deletePermission &&
                            <button style={{marginLeft: "5%"}} onClick={() => deleteFun(params.row.id, true)}
                                    className="btn btn-danger">Delete
                            </button>
                        }
                    </div>
                )
            },
        },

    ]
}


export function getBondNumberColumns() {
    return [
        {
            field: 'bond_number',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            headerName: 'Bond Number',
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'is_used',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            headerName: 'Is Used',
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
        },
        {
            field: 'created_at',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            headerName: 'Created At',
            editable: false,
            headerClassName: 'header-bold',
            valueFormatter: params => !params ? "" : new Date(params?.value).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })
        },
        {
            field: 'updated_at',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            headerName: 'Updated At',
            editable: false,
            headerClassName: 'header-bold',
            valueFormatter: params => !params ? "" : new Date(params?.value).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })
        },
        {
            field: 'used_by',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            headerName: 'Used By',
            editable: false,
            headerClassName: 'header-bold',
        },
        {
            field: 'id',
            flex: 1,
            sortable: false,
            editable: false,
            headerAlign: 'right',
            filterable: false,
            headerClassName: 'header-bold',
        },

    ]
}


export async function deleteLawSchoolBackend(lawSchoolId) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/law_schools/` + lawSchoolId, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}


export async function addLawSchoolBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/law_schools/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    } else {
        const res = await response.json();
        res['status'] = response.status;
        return res;
    }
}


export async function updateLawSchoolBackend(lawSchoolId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/law_schools/${lawSchoolId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response.status;
}

export async function addVermostUserBackend(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/add_vermost_user/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    return response;
}

export async function updateVermostUserBackend(userId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/edit_vermost_user/${userId}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    return response;
}

export async function deleteUserLawFirmBackend(lawFirmId, data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/bond/delete_user_law_firm/${lawFirmId}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });

    return response;
}

export async function getLastBond(lawFirmId) {
    return await getAPICallv2(`/bond/get_last_bond_date/${lawFirmId}`);
}

export async function getBondHistory(lawFirmId) {
    return await getAPICallv2(`/bond/get_bond_history/${lawFirmId}`);
}

export function getVermostLocationColumns() {
    let cols = [
        {
            field: 'law_firm_name',
            headerName: 'Law Firm',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'name',
            headerName: 'Location Name',
            minWidth: 180,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'customer_number',
            headerName: 'Customer Number',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'fax',
            headerName: 'Fax',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'address',
            headerName: 'Address',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'city',
            headerName: 'City',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'county',
            headerName: 'County',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'country',
            headerName: 'Country',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'state',
            headerName: 'State',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'zip_code',
            headerName: 'Zip Code',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold'
        },
        {
            field: 'is_active',
            headerName: 'Active',
            minWidth: 100,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            type: 'boolean',
            sortable: false,
        },
        {
            field: 'id',
            headerName: '',
            minWidth: 180,
            maxWidth: 1000,
            align: 'right',
            flex: 1,
            sortable: false,
            editable: false,
            headerAlign: 'right',
            filterable: false,
            headerClassName: 'header-bold'
        }
    ]
    return cols
}