import * as XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import {log} from "./logger";
import {enqueueSnackbar} from "notistack";

export function exportToExcel(excelData, fileName) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
    const fileExtension = ".xlsx";
    try {
        enqueueSnackbar('Preparing the file.', { variant:"info" });
        log("lawFirmJsonData,",excelData)
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        enqueueSnackbar('File is ready.', { variant:"success" });
    } catch {
        enqueueSnackbar('File could not be created.', { variant:"error" });
        log("error")
    }
}