import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import CustomDataGrid from "../../../global_components/datatables/custom_datagrid";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {hasUserPermission} from "../../../utils/user_permission";
import AddLawFirmLocationPopup from "../vermost_popups/add_law_firm_location_popup";
import {API_URL} from "../../../constants";
import {log} from "../../../utils/logger";
import {enqueueSnackbar} from "notistack";

function LocationList(props) {
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');
    const hasAddPermission = hasUserPermission(user_permission, 'show_table_add_button')
    const super_user = useSelector((state) => state.auth.user.user_role_name) === "SuperAdmin";

    const getColumns = () => {
        let cols = [
            {
                field: 'name',
                headerName: 'Locations',
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
            },
            super_user && {
                field: 'customer_number',
                headerName: 'Customer Number',
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
            },
            {
                field: 'address',
                headerName: 'Address',
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
            },
            {
                field: 'city',
                headerName: 'City',
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
            },
            {
                field: 'county',
                headerName: 'County',
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
            },
            {
                field: 'state',
                headerName: 'State',
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
            },
            {
                field: 'id',
                headerName: '',
                minWidth: 180,
                maxWidth: 1000,
                disableColumnMenu: true,
                align: 'right',
                disableColumnMenu: true,
                flex: 1,
                sortable: false,
                editable: false,
                headerAlign: 'right',
                filterable: false,
                headerClassName: 'header-bold'
            },

        ]
        return cols;
    }
    const columns = getColumns();

    return (
        <div>
            <CustomDataGrid columns={columns}
                            rows={props.data}
                            isHeight={true}
                            autoHeight={true}
                            rowAutoHeight={() => "auto"}
                            estimatedRowHeight={() => 100}
                            disableRowSelectionOnClick={true}
                            hideFooter={true}
                            isEdit={props.isEdit}
                            isActionsVisible={true}
                            visibleActionButtons={true}
                            onCallback={props.onCallback}
                            rowCount={props.rowCount}
                            paginationModel={props.paginationModel}
                            onPaginationModelChange={props.onPaginationModelChange}
                            onFilterModelChange={props.onFilterModelChange}
                            onSortModelChange={props.onSortModelChange}
                            loading={props.loading}
                            addPermission={hasAddPermission}
                            deletePermission={hasDeletePermission}
                            updatePermission={hasUpdatePermission}
            />
            {props.showLocationInsertPopup &&
                (

                    <>
                        <AddLawFirmLocationPopup show={true} data={props.editData}
                                                 id={props.editID ? props.editID : 0}
                                                 oncallback={props.onActionCallback}
                                                 closePopup={props.closePopup}></AddLawFirmLocationPopup>
                    </>
                )
            }

        </div>
    )
}

CustomDataGrid.propTypes = {
    onCallback: PropTypes.func
}
export default LocationList;
