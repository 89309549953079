import React, {useCallback, useEffect, useState} from 'react';
import {Spinner, Tab, Tabs} from "react-bootstrap";
import '../styles/login.css';
import {useNavigate} from "react-router";
import {
    formatFailedData,
    formatNewData,
    formatPendingData,
    getColumns,
    getDepartmentApprovementLevel,
    getFailedColumns,
    getFailedInvoicesPagination,
    getInvoicesPagination,
    getPendingColumns,
    getPendingInvoicesPagination
} from "../services/accounts";
import CusTable from "../global_components/datatables/tablev2";
import HistoryCusTable from "./posted_tablev2"
import Footer from "./footer";
import {InvoiceEditForm} from "./ap_invoice_edit_form";
import APTableLoading from "./loadings/ap_table_loading";
import APEditFormLoading from "./loadings/ap_edit_form_loading";
import CusAlert from "./alert";
import CusNavBar from "./navbar";

import {useDispatch, useSelector} from "react-redux";
import Select from 'react-select';
import {addDepartmentApprovementLevel, addSelectedPermissionAP} from '../store/auth';
import {addInvoices, moveFailedToPosted, removeInvoices, updateInvoice} from "../store/invoices";
import Header from "./header";
import {API_URL} from "../constants";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import * as XLSX from "sheetjs-style";
import {createSvgIcon, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import * as FileSaver from "file-saver";
import {useSnackbar} from "notistack";

const ExportIcon = createSvgIcon(
    <path
        d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"/>,
    'SaveAlt',
);

function AccountsPayable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [manualReload, setManualReload] = useState(false);
    const [jsonData, setJsonData] = useState([]);
    const [pendingJsonData, setPendingJsonData] = useState([]);
    const [failedJsonData, setFailedJsonData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [pendingColumns, setPendingColumns] = useState([]);
    const [failedColumns, setFailedColumns] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [pendingLoading, setPendingLoading] = useState(true);
    const [failedLoading, setFailedLoading] = useState(true);
    const [editInvoice, setEditInvoice] = useState([]);
    const [historyEditInvoice, setHistoryEditInvoice] = useState([]);
    const [pendingEditInvoice, setPendingEditInvoice] = useState([]);
    const [failedEditInvoice, setFailedEditInvoice] = useState([]);
    const [editInvoiceLoading, setEditInvoiceLoading] = useState(false);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [showFailPopUp, setShowFailPopUp] = useState(false);
    const [failPopUpText, setFailPopUpText] = useState("Failed to update invoice.");
    const [dataLoaded, setDataLoaded] = useState(false);
    const [excelExportData, setExcelExportData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    // Datagrid Pagination
    if (!localStorage.getItem("accounts_payable_datagrid_pagesize")) {
        localStorage.setItem("accounts_payable_datagrid_pagesize", JSON.stringify({
            'active': 10, 'pending': 10, 'failed': 10, 'posted': 10
        }));
    }

    const [activeLoading, setActiveLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0, pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['active']
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);
    const [pendingTableLoading, setPendingTableLoading] = useState(true);
    const [rowPendingCount, setRowPendingCount] = useState(0);
    const [controllerPending, setControllerPending] = useState({
        page: 0, pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['pending']
    });
    const gatedSetControllerPending = useCallback((value) => {
        setControllerPending(value);
    }, []);
    const [failedTableLoading, setFailedTableLoading] = useState(true);
    const [rowFailedCount, setRowFailedCount] = useState(0);
    const [controllerFailed, setControllerFailed] = useState({
        page: 0, pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['failed']
    });
    const gatedSetControllerFailed = useCallback((value) => {
        setControllerFailed(value);
    }, []);
    const handlePageSizeChange = (pageSize) => {
        setController({
            page: 0, pageSize: pageSize
        })
    }
    // Datagrid Filter
    const [queryOptionsActive, setQueryOptionsActive] = useState({});
    const onFilterChangeActive = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptionsActive({filterModel: {...filterModel.items}}), 1000);

    }, []);
    const [queryOptionsPending, setQueryOptionsPending] = useState({});
    const onFilterChangePending = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptionsPending({filterModel: {...filterModel.items}}), 1000);
    }, []);
    const [queryOptionsFailed, setQueryOptionsFailed] = useState({});
    const onFilterChangeFailed = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptionsFailed({filterModel: {...filterModel.items}}), 1000);
    }, []);
    //Datagrid Sort
    const [prevFilterActive, setPrevFilterActive] = useState("");
    const [queryOptionsSortActive, setQueryOptionsSortActive] = useState({});
    const handleSortModelChangeActive = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortActive({sortModel: [...sortModel]});
    }, []);
    const [prevSortActive, setPrevSortActive] = useState("");
    const [queryOptionsSortPending, setQueryOptionsSortPending] = useState({});
    const handleSortModelChangePending = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortPending({sortModel: [...sortModel]});
    }, []);
    const [prevSortPending, setPrevSortPending] = useState("");
    const [queryOptionsSortFailed, setQueryOptionsSortFailed] = useState({});
    const handleSortModelChangeFailed = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortFailed({sortModel: [...sortModel]});
    }, []);
    const [prevSortFailed, setPrevSortFailed] = useState("");
    const [loadingExcel, setLoadingExcel] = useState(false)
    const userGroups = useSelector((state) => state.auth.user.groups);
    const userDepartments = useSelector((state) => state.auth.user.departments);
    const userPermissions = useSelector((state => {
        const departments = state.auth.user.departments;
        const permissions = [];
        Object.keys(departments).map((id) => {
            const current_dep_perms = departments[id].ap_permissions;
            if (current_dep_perms.includes('post') && current_dep_perms.includes('put') && !permissions.includes('Post')) {
                permissions.push('Post');
            }
            if (current_dep_perms.includes('put') && !current_dep_perms.includes('post') && !permissions.includes('Approve')) {
                permissions.push('Approve');
            }
            if (current_dep_perms.includes('post') && !permissions.includes('Post')) {
                permissions.push('Post');
            }
        });
        return permissions;
    }));
    const currentPermission = useSelector((state) => state.auth.user.current_selected_permission_ap);
    const selectedDepartment = useSelector((state) => state.auth.user.departments.hasOwnProperty(state.auth.user.current_selected_department) ? state.auth.user.departments[state.auth.user.current_selected_department] : {});
    let isReader = Object.keys(selectedDepartment).length > 0 ? !selectedDepartment['ap_permissions'].includes("post") && !selectedDepartment['ap_permissions'].includes("put") && !userGroups.includes("BlueOcean") : true;

    const permissionOptions = () => {
        let opts = [];
        userPermissions.map((perm) => {
            opts.push({value: perm, label: perm});
        })
        return opts;
    }
    const changeEditInvoice = async (id) => {
        setEditInvoiceLoading(true);
        setEditInvoice([]);
        setHistoryEditInvoice([]);
        setPendingEditInvoice([]);
        setFailedEditInvoice([]);
        const active_inovices_in_store = JSON.parse(localStorage.getItem('invoices'));
        setTimeout(() => {
            if (active_inovices_in_store) {
                const editData = active_inovices_in_store.filter((data) => {
                    if (data.id === id) {
                        return data;
                    }
                });
                setEditInvoice(editData);
                setEditInvoiceLoading(false);
                window.scroll(0, 0)
            }
            setEditInvoiceLoading(false);
        }, 500);
    }

    const changeHistoryEditInvoice = async (id) => {
        setEditInvoice([]);
        setHistoryEditInvoice([]);
        setPendingEditInvoice([]);
        setFailedEditInvoice([]);
        setEditInvoiceLoading(true);
        const data = JSON.parse(localStorage.getItem('history_invoices'));
        const editData = data.filter((data) => {
            if (data !== null) {
                if (data.id === id) {
                    return data;
                }
            }
        });
        setHistoryEditInvoice(editData);
        setEditInvoiceLoading(false);
        window.scroll(0, 0)
    }

    const changePendingEditInvoice = async (id) => {
        setEditInvoice([]);
        setHistoryEditInvoice([]);
        setPendingEditInvoice([]);
        setFailedEditInvoice([]);
        setEditInvoiceLoading(true);
        const data = JSON.parse(localStorage.getItem('pending_invoices'));
        const editData = data.filter((data) => {
            if (data.id === id) {
                return data;
            }
        });
        setPendingEditInvoice(editData);
        setEditInvoiceLoading(false);
        window.scroll(0, 0)
    }

    const changeFailedEditInvoice = async (id) => {
        setEditInvoice([]);
        setHistoryEditInvoice([]);
        setPendingEditInvoice([]);
        setFailedEditInvoice([]);
        setEditInvoiceLoading(true);
        const data = JSON.parse(localStorage.getItem('failed_invoices'));
        const editData = data.filter((data) => {
            if (data.id === id) {
                return data;
            }
        });
        setFailedEditInvoice(editData);
        setEditInvoiceLoading(false);
        window.scroll(0, 0)
    }

    const getPendingFilterAndSort = () => {
        let filter = ""
        if (queryOptionsPending.filterModel) {
            filter = queryOptionsPending.filterModel[0].field + "-" + queryOptionsPending.filterModel[0].operator + "-" + queryOptionsPending.filterModel[0].value
        }

        let sort = ""
        if (queryOptionsSortPending.sortModel) {
            if (queryOptionsSortPending.sortModel.length > 0) {
                if (prevSortPending) {
                    sort = prevSortPending
                }
                let sortModel = queryOptionsSortPending.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortPending !== sort) {
                    setPrevSortPending(sort)
                } else {
                    if (queryOptionsPending.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        return [filter, sort]
    }

    const setPendingData = async () => {
        setPendingTableLoading(true);
        let filter = getPendingFilterAndSort();
        let data = await getPendingInvoicesPagination(controllerPending.page, controllerPending.pageSize, filter[0], filter[1]);
        setPendingTableLoading(true);
        setRowPendingCount(data.total);
        dispatch(addInvoices({
            permissionType: userPermissions.length === 1 ? userPermissions[0] : currentPermission,
            invoiceType: 'pending',
            invoices: data.data
        }))
        if (data.total === 0) {
            setPendingJsonData([]);
            // setPendingColumns([]);
            setPendingLoading(false);
            setPendingTableLoading(false);
        } else {
            localStorage.setItem('pending_invoices', JSON.stringify(data.data));
            const formatedData = formatPendingData(data.data);
            setPendingJsonData(formatedData);
            let cols = getPendingColumns(data.data);
            cols.push({
                field: 'posted_by',
                headerName: 'Posted By',
                sortable: true,
                minWidth: 100,
                maxWidth: 1000,
                flex: 1,
                headerClassName: 'header-bold right-column',
                sortID: cols.length + 1
            })
            cols = [...cols.slice(0, 1), {
                field: 'id',
                headerName: 'Document',
                sortable: false,
                minWidth: 110,
                maxWidth: 1000,
                flex: 1,
                headerClassName: 'header-bold right-column',
                renderCell: (params) => {
                    return <button onClick={() => {
                        changePendingEditInvoice(params.row.id);
                    }}
                                   className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                              inline-flex items-center">
                        <span>{'View'}</span>
                    </button>;
                },
                sortID: 1
            }, ...cols.slice(1)]
            setPendingColumns(cols);
            setPendingLoading(false);
            setPendingTableLoading(false);
        }
    }

    const moveInvoiceToPosted = async (id) => {
        // setFailedLoading(true);
        setFailedTableLoading(true);
        const token = localStorage.getItem('userdata');
        await fetch(`${API_URL}/ap/failed/`, {
            method: "PATCH", body: JSON.stringify({"invoice_id": id}), headers: {
                'Authorization': 'Bearer ' + token, "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                dispatch(moveFailedToPosted({
                    permissionType: userPermissions.length === 1 ? userPermissions[0] : currentPermission,
                    invoice_id: id
                }))
                dispatch(removeInvoices({
                    permissionType: userPermissions.length === 1 ? userPermissions[0] : currentPermission,
                    invoicesToRemove: [id],
                    invoiceType: 'failed'
                }))
                setShowSuccessPopUp(true);
                manualReloadTables()
            } else {
                setShowFailPopUp(true);
            }
            setFailedLoading(false);
            setFailedTableLoading(false);
        }).catch((error) => {
            console.log(error);
            setShowFailPopUp(true);
            setFailedLoading(false);
            setFailedTableLoading(false);
        })

    }

    const getFailedFilterAndSort = () => {
        let filter = ""
        if (queryOptionsFailed.filterModel) {
            filter = queryOptionsFailed.filterModel[0].field + "-" + queryOptionsFailed.filterModel[0].operator + "-" + queryOptionsFailed.filterModel[0].value
        }
        let sort = ""
        if (queryOptionsSortFailed.sortModel) {
            if (queryOptionsSortFailed.sortModel.length > 0) {
                if (prevSortFailed) {
                    sort = prevSortFailed
                }
                let sortModel = queryOptionsSortFailed.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortFailed !== sort) {
                    setPrevSortFailed(sort)
                } else {
                    if (queryOptionsFailed.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        return [filter, sort]
    }

    const setFailedData = async () => {
        setFailedTableLoading(true);
        let filter = getFailedFilterAndSort();
        let data = await getFailedInvoicesPagination(controllerFailed.page, controllerFailed.pageSize, filter[0], filter[1]);
        setRowFailedCount(data.total)
        dispatch(addInvoices({
            permissionType: userPermissions.length === 1 ? userPermissions[0] : currentPermission,
            invoiceType: 'failed',
            invoices: data.data
        }));
        if (data.total === 0) {
            setFailedJsonData([]);
            // setFailedColumns([]);
            setFailedLoading(false);
            setFailedTableLoading(false);
        } else {
            localStorage.setItem('failed_invoices', JSON.stringify(data.data));
            const formatedData = formatFailedData(data.data);
            setFailedJsonData(formatedData);
            let cols = getFailedColumns(data.data);
            cols.push({
                field: 'posted_by',
                headerName: 'Posted By',
                sortable: true,
                minWidth: 100,
                maxWidth: 1000,
                flex: 1,
                headerClassName: 'header-bold right-column',
                sortID: cols.length + 1
            })
            cols = [...cols.slice(0, 1), {
                field: 'move_failed',
                headerName: 'Move to Posted',
                sortable: false,
                minWidth: 200,
                maxWidth: 1000,
                flex: 1,
                headerClassName: 'header-bold right-column',
                renderCell: (params) => {
                    return <button onClick={() => {
                        moveInvoiceToPosted(params.row.id);
                    }}
                                   className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                              inline-flex items-center">
                        <span>{'Move to Posted'}</span>
                    </button>;
                },
                sortID: 1
            }, {
                field: 'id',
                headerName: 'Document',
                sortable: false,
                minWidth: 110,
                maxWidth: 1000,
                flex: 1,
                headerClassName: 'header-bold right-column',
                renderCell: (params) => {
                    return <button onClick={() => {
                        changeFailedEditInvoice(params.row.id);
                    }}
                                   className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                              inline-flex items-center">
                        <span>{'View'}</span>
                    </button>;
                },
                sortID: 1
            }, ...cols.slice(1)]
            setFailedColumns(cols);
            setFailedLoading(false);
            setFailedTableLoading(false);
        }
    }

    const getActiveFilterAndSort = () => {
        let filter = ""
        if (queryOptionsActive.filterModel) {
            if (queryOptionsActive.filterModel[0] !== undefined) {
                filter = queryOptionsActive.filterModel[0].field + "-" + queryOptionsActive.filterModel[0].operator + "-" + queryOptionsActive.filterModel[0].value
                setPrevFilterActive(filter)
            } else {
                filter = prevFilterActive
            }
        }
        let sort = ""
        if (queryOptionsSortActive.sortModel) {
            if (queryOptionsSortActive.sortModel.length > 0) {
                if (prevSortActive) {
                    sort = prevSortActive
                }
                let sortModel = queryOptionsSortActive.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortActive !== sort) {
                    setPrevSortActive(sort)
                } else {
                    if (queryOptionsActive.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        return [filter, sort]
    }
    const setInvoiceData = async (permissionType) => {
        setActiveLoading(true);
        let filter = getActiveFilterAndSort();
        let data = await getInvoicesPagination(permissionType, controller.page, controller.pageSize, filter[0], filter[1]);
        setRowCount(data.total);
        dispatch(addInvoices({permissionType: permissionType, invoiceType: 'active', invoices: data.data}))
        if (data.total === 0) {
            // setTableColumns([]);
            localStorage.setItem('invoices', JSON.stringify([]));
            setJsonData([]);
            setActiveLoading(false);
            setTableLoading(false);
        } else {
            setTableLoading(false);
            setActiveLoading(false);
            localStorage.setItem('invoices', JSON.stringify(data.data));
            const formatedData = formatNewData(data.data);
            setJsonData(formatedData);
            let cols = getColumns(data.data);
            cols.push({
                field: 'id',
                headerName: isReader ? 'View' : 'Edit',
                sortable: false,
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                headerClassName: 'header-bold right-column',
                renderCell: (params) => {
                    return <button onClick={() => {
                        changeEditInvoice(params.row.id);
                    }}
                                   className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                              inline-flex items-center">
                        <span>{isReader ? 'View' : 'Edit'}</span>
                    </button>;
                },
                sortID: 1
            })
            setTableColumns(cols);
        }
    }
    const setLocalStorage = () => {
        localStorage.setItem("accounts_payable_datagrid_pagesize", JSON.stringify({
            'active': controller.pageSize,
            'pending': controllerPending.pageSize,
            'failed': controllerFailed.pageSize,
            'posted': JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['posted'],
        }));
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        if (!dataLoaded) {
            getDepartmentApprovementLevel(Object.keys(userDepartments).join(',')).then((response) => {
                dispatch(addDepartmentApprovementLevel({approvement_levels: response}));
            }).catch((error) => {
                console.log(error);
            });
        }
        setPendingData();
        if (userPermissions.length === 1) {
            dispatch(addSelectedPermissionAP(userPermissions[0]));
            dispatch(addSelectedPermissionAP(userPermissions[0]));
            setInvoiceData(userPermissions[0]);
        }
        setLocalStorage();
        setDataLoaded(true);
    }, [controllerPending, queryOptionsPending, queryOptionsSortPending])

    useEffect(async () => {
        setLocalStorage();
        setFailedData(currentPermission);
    }, [controllerFailed, queryOptionsFailed, queryOptionsSortFailed])

    useEffect(async () => {
        if (currentPermission !== '') {
            setLocalStorage();
            setInvoiceData(currentPermission);
            setDataLoaded(true);
        }
    }, [controller, currentPermission, queryOptionsActive, queryOptionsSortActive])

    const tableReload = (permissionType, invoiceType, invoicesToRemove, data, reject = false) => {
        setDataLoaded(true);

        if (currentPermission !== 'Post' || reject === true) {
            console.log("if")
            setDataLoaded(false);
            dispatch(removeInvoices(data));
        } else {
            console.log("else")
            setDataLoaded(false);
            dispatch(updateInvoice(data));
        }
    }

    const postingTableReload = (data) => {
        setDataLoaded(false);
        dispatch(removeInvoices(data));
    }

    const manualRelaodActiveTable = () => {
        setActiveLoading(true);
        setController({
            page: 0, pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['active']
        });
        setQueryOptionsActive({})
        setQueryOptionsSortActive({})
        setInvoiceData(currentPermission);
    }

    const manualReloadTables = () => {
        setDataLoaded(false);
        setManualReload(true);
        setTableLoading(true);
        setController({
            page: 0, pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['active']
        });
        setQueryOptionsActive({})
        setQueryOptionsSortActive({})
        setInvoiceData(currentPermission);
        setFailedLoading(true);
        setControllerFailed({
            page: 0, pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['failed']
        });
        setQueryOptionsFailed({})
        setQueryOptionsSortFailed({})
        setFailedData();
        setPendingLoading(true);
        setControllerPending({
            page: 0, pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['pending']
        })
        setQueryOptionsPending({})
        setQueryOptionsSortPending({})
        setPendingData();
    }

    const getExcelData = async (status) => {
        let response = {}
        if (status === "active") {
            let filter = getActiveFilterAndSort()
            response = await getInvoicesPagination(currentPermission, "", "", filter[0], filter[1]);

        } else if (status === "pending") {
            let filter = getPendingFilterAndSort()
            response = await getPendingInvoicesPagination("", "", filter[0], filter[1]);

        } else if (status === "failed") {
            let filter = getFailedFilterAndSort();
            response = await getFailedInvoicesPagination("", "", filter[0], filter[1]);
        }
        return response.data;
    }

    const sortFormat = (excelData, status) => {
        let columnList = [];
        if (status === "active") {
            columnList = tableColumns;
        }
        else  if (status === "pending") {
            columnList = pendingColumns;
        }
        else  if (status === "failed") {
            columnList = failedColumns;
        }
        let totalResult = []
        Object.entries(excelData).forEach((val) => {
            let tempObj = {};
            let  entries = Object.entries(val[1]['invoice_details']);
            let result = {}
            columnList.forEach(function (key) {
                var found = false;
                entries = entries.filter(function (item) {
                    if (!found && item[0] === key.field) {
                        if(key.field === 'id'){
                            result[item[0]] = item[1];
                        }
                        else{
                            result[item[1].label] = item[1].value;
                        }
                        found = true;
                        return false;
                    } else if (!found && key.field === 'approvement_label') {
                        result["Approval Stage"] = val[1]['approvement_label'];
                        found = true;
                        return false;
                    } else if (!found && key.field === 'department_name') {
                        result["Department"] = val[1]['department_name'];
                        found = true;
                        return false;
                    } else if (!found && key.field === 'status') {
                        result["Status"] = val[1]['status'];
                        found = true;
                        return false;
                    } else
                        return true;
                })
            })
            totalResult.push(result)
        });

        return totalResult;
    }

    const changeFormat = (original, status) => {
        return sortFormat(original, status);
    }
    const ExportExcel = (status) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
        const fileExtension = ".xlsx";
        const exportToExcel = async () => {
            try {
                enqueueSnackbar('Preparing the file.', { variant:"info" });
                setLoadingExcel(true);
                let excelData = await getExcelData(status.status)
                const ws = XLSX.utils.json_to_sheet(changeFormat(excelData, status.status));
                const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
                const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, status.fileName + fileExtension);
                enqueueSnackbar('File is ready.', { variant:"success" });
                setLoadingExcel(false);
            } catch {
                enqueueSnackbar('File could not be created.', { variant:"error" });
                setLoadingExcel(false);
            }
        }
        const buttonBaseProps = {
            color: 'primary',
            size: 'small',
            startIcon: <ExportIcon/>,
        };
        return (<>
            <Tooltip title="Excel Export">
                {
                    !loadingExcel && (<Button {...buttonBaseProps}
                                              onClick={(e) => exportToExcel(status.fileName)} color="primary"
                                              style={{cursor: "pointer", fontSize: 14}}>
                            Export
                        </Button>
                    )}
                {loadingExcel &&
                    (
                        <Button variant="contained" disabled>
                            <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                            Preparing Excel...
                        </Button>
                    )
                }
            </Tooltip>
        </>)

    }
    return (<>
        <CusNavBar/>
        <Header text="Accounts Payable"/>
        {showSuccessPopUp && <CusAlert alertType='success' alertMessage='Invoice Updated successfully.'
                                       alertClose={setShowSuccessPopUp}/>}
        {// showFailPopUp && <CusAlert alertType='danger' alertMessage='Failed to update invoice.'
            //                            alertClose={setShowFailPopUp}/>
            showFailPopUp && <CusAlert alertType='danger' alertMessage={failPopUpText}
                                       alertClose={setShowFailPopUp}/>}

        <div style={{marginTop: "1%"}}>
            <h4 style={{display: "inline-block", marginRight: "2%"}}>Role:</h4>
            <Select
                placeholder={"Select Permission"}
                className="col-3 small-select-field"
                name="selected_permission"
                onChange={async (opt) => {
                    setDataLoaded(false);
                    dispatch(addSelectedPermissionAP(opt.value));
                }}
                options={permissionOptions()}
                isDisabled={editInvoice.length !== 0 || failedEditInvoice.length !== 0 || pendingEditInvoice.length !== 0 || historyEditInvoice.length !== 0 || userPermissions.length === 1}
                defaultValue={userPermissions.length === 1 ? userPermissions[0] : permissionOptions().filter((opt) => opt.value === currentPermission)}
            />
        </div>

        <hr className="col-3 col-md-2"/>
        {editInvoiceLoading && <APEditFormLoading/>}
        {editInvoice && editInvoice.length > 0 &&
            <InvoiceEditForm invoice={editInvoice} setEdtInv={changeEditInvoice}
                             editCallback={manualRelaodActiveTable} success={setShowSuccessPopUp}
                             failure={setShowFailPopUp} failureMessage={setFailPopUpText} type="active"/>}
        {historyEditInvoice && historyEditInvoice.length > 0 &&
            <InvoiceEditForm invoice={historyEditInvoice} setEdtInv={setHistoryEditInvoice}
                             editCallback={tableReload}
                             success={setShowSuccessPopUp} failure={setShowFailPopUp}
                             failureMessage={setFailPopUpText} type="history"/>}

        {failedEditInvoice && failedEditInvoice.length > 0 &&
            <InvoiceEditForm invoice={failedEditInvoice} setEdtInv={setFailedEditInvoice} editCallback={tableReload}
                             success={setShowSuccessPopUp} failure={setShowFailPopUp}
                             failureMessage={setFailPopUpText} type="failed"/>}

        {pendingEditInvoice && pendingEditInvoice.length > 0 &&
            <InvoiceEditForm invoice={pendingEditInvoice} setEdtInv={setPendingEditInvoice}
                             editCallback={tableReload}
                             success={setShowSuccessPopUp} failure={setShowFailPopUp}
                             failureMessage={setFailPopUpText} type="pending"/>}

        {(currentPermission !== "") && (<>
            <button style={{float: "right"}} onClick={() => manualReloadTables()}
                    className="btn btn-primary">Reload Invoices
            </button>
            <Tabs defaultActiveKey="active_invoices" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="active_invoices" title="Active Invoices">
                    <div style={{"height": "650px"}}>
                        {!tableLoading ? <>
                            <ExportExcel status={"active"}
                                         fileName={"Active_Invoices"}></ExportExcel>

                            <CusTable columns={tableColumns}
                                      editCallback={tableReload}
                                      data={jsonData}
                                      rowCount={rowCount}
                                      paginationModel={controller}
                                      onPaginationModelChange={gatedSetController}
                                      onFilterModelChange={onFilterChangeActive}
                                      onSortModelChange={handleSortModelChangeActive}
                                      loading={activeLoading}
                                      success={setShowSuccessPopUp}
                                      failure={setShowFailPopUp}
                                      type="active"
                                      postingReload={postingTableReload}
                                      reloadTable={manualReloadTables}
                            />

                        </> : <APTableLoading/>}
                    </div>
                </Tab>
                <Tab eventKey="invoices_pending" title="Pending Invoices">
                    {!pendingLoading ? <>
                        <ExportExcel status={"pending"}
                                     fileName={"Pending_Invoices"}></ExportExcel>
                        <CusTable columns={pendingColumns}
                                  editCallback={tableReload}
                                  data={pendingJsonData}
                                  rowCount={rowPendingCount}
                                  paginationModel={controllerPending}
                                  onPaginationModelChange={gatedSetControllerPending}
                                  onFilterModelChange={onFilterChangePending}
                                  onSortModelChange={handleSortModelChangePending}
                                  loading={pendingTableLoading}
                                  success={setShowSuccessPopUp}
                                  failure={setShowFailPopUp} type="pending"
                                  postingReload={postingTableReload}/>
                    </> : <APTableLoading/>}
                </Tab>
                <Tab eventKey="posted_invoices" title="Posted Invoices">
                    {currentPermission !== "" && <>

                        <HistoryCusTable
                            editCallback={changeHistoryEditInvoice}
                            reload={manualReload}
                            changeManualReload={setManualReload}/>
                    </>}
                </Tab>
                <Tab eventKey="failed_invoices" title="Failed Invoices">
                    {!failedLoading ? <>
                        <ExportExcel status={"failed"}
                                     fileName={"Failed_Invoices"}></ExportExcel>
                        <CusTable columns={failedColumns}
                                  editCallback={tableReload}
                                  data={failedJsonData}
                                  rowCount={rowFailedCount}
                                  paginationModel={controllerFailed}
                                  onPaginationModelChange={gatedSetControllerFailed}
                                  onFilterModelChange={onFilterChangeFailed}
                                  onSortModelChange={handleSortModelChangeFailed}
                                  loading={failedTableLoading}
                                  success={setShowSuccessPopUp}
                                  failure={setShowFailPopUp} type="failed"
                                  postingReload={postingTableReload}/>
                    </> : <APTableLoading/>}
                </Tab>
            </Tabs>
        </>)}
        <Footer/>
    </>)
}


export default AccountsPayable;
