import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import "../../index.css";
import {
    Card,
    CardContent,
    Checkbox,
    Container,
    FormControlLabel,
} from "@mui/material";
import { ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import CustomTextInput from "../../global_components/inputs/text";
import USPhoneNumberWithoutCountryCodeUniversal from "../../global_components/inputs/us_phone_number_without_country_code";


export default function PersonalInformation({formIK, step, progress, changeStep}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [hasError, setHasError] = useState(false);

    const validate = () => {
        let error = false;
        if(formIK.values.user_first_name === "" || formIK.values.bond_type === undefined){
            formIK.errors['user_first_name'] = 'User First Name is required.';
            error = true;
        }
        if(formIK.values.user_last_name === "" || formIK.values.user_last_name === undefined){
            formIK.errors['user_last_name'] = 'User Last Name is required.';
            error = true;
        }
        if(formIK.values.user_email === "" || formIK.values.user_email === undefined){
            formIK.errors['user_email'] = 'User Email is required.';
            error = true;
        }
        const regex = new RegExp(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm)
        if(formIK.values.phone_number === "" || formIK.values.phone_number === undefined){ //|| !formIK.values.phone_number.match(regex)){
            formIK.errors['phone_number'] = 'Phone Number is required.';
            error = true;
        }else if(!formIK.values.phone_number.match(regex)){
            formIK.errors['phone_number'] = 'Please provide a valid phone number.';
            error = true;
        }
        if(error === true){
            window.scroll(0,0);
            setHasError(true)
            console.log(formIK.errors)
        }else{
            changeStep(progress+step);
        }
    }

    useEffect(()=>{

    });

    return(
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e)=>{
                                    e.preventDefault();
                                    changeStep(progress-step);
                                }}  className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e)=> {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={ false } className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{ maxWidth: "50%" }} style={{marginBottom: "2%"}}>

                            <CardContent>
                                <h4>Requestor Information</h4>
                                <Row>
                                    <Col>
                                        <Form.Group key="first_name" className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>First Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name={"user_first_name"}
                                                             className={"form-control"} id={"user_first_name-input"}
                                                             value={formIK.values["user_first_name"]} onChange={(value)=>{
                                                formIK.setFieldValue("user_first_name", value);
                                            }}/>
                                            {formIK.errors["user_first_name"] && (
                                                <div
                                                    className="text-error">{formIK.errors["user_first_name"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="user_last_name" className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Last Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name={"user_last_name"}
                                                             className={"form-control"} id={"user_last_name-input"}
                                                             value={formIK.values["user_last_name"]} onChange={(value)=>{
                                                formIK.setFieldValue("user_last_name", value);
                                            }}/>
                                            {formIK.errors["user_last_name"] && (
                                                <div
                                                    className="text-error">{formIK.errors["user_last_name"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="user_email" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Email</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name={"user_email"}
                                                             className={"form-control"} id={"user_email-input"}
                                                             value={formIK.values["user_email"]} onChange={(value)=>{
                                                formIK.setFieldValue("user_email", value);
                                            }}/>
                                            {formIK.errors["user_email"] && (
                                                <div
                                                    className="text-error">{formIK.errors["user_email"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="phone_number" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Phone Number</strong></Form.Label>
                                            {/*<Form.Control type="text" size="sm" onBlur={formIK.handleBlur}*/}
                                            {/*              onChange={formIK.handleChange}*/}
                                            {/*              name='phone_number'*/}
                                            {/*              defaultValue={formIK.values.phone_number}/>*/}
                                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                                                      className={"form-control"}
                                                                                      name="phone_number"
                                                                                      id="phone_number-input"
                                                                                      value={formIK.values.phone_number}
                                                                                      onChange={(value)=>{
                                                                                          delete formIK.errors['phone_number'];
                                                                                          formIK.setFieldValue("phone_number", value);
                                                                                      }} />
                                            {formIK.errors["phone_number"] && (
                                                <div
                                                    className="text-error">{formIK.errors["phone_number"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e)=>{
                                    e.preventDefault();
                                    changeStep(progress-step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e)=> {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={ false } className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

PersonalInformation.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
