import React from 'react';
import PropTypes from "prop-types";
import {DataGrid} from "@mui/x-data-grid";

export default function CustomDataGrid(props) {

    return (
        <>
            {props.rows.length === 0 && !props.loading ?
                <label style={{
                    textAlign: "center",
                    paddingTop: "10%",
                    width: "100%",
                    height: "500px"
                }}>{props.isRequiredDepartment ? "No rows, Please select department." : "No rows"}</label> :
                <DataGrid
                    style={props.style}
                    rows={props.rows}
                    columns={props.columns}
                    pageSize={props.pageSize}
                    loading={props.loading}

                />

            }

        </>
    );
}

CustomDataGrid.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    style: PropTypes.object.isRequired,
    pageSize: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    isRequiredDepartment: PropTypes.bool,
};
