import PropTypes from "prop-types";
import {Form} from "react-bootstrap";
import React, {useState, useRef, useMemo, useEffect} from "react";
import {useFormik, FormikProvider, FieldArray} from "formik";
import '../styles/login.css';
import * as Yup from "yup";
import {titleCase, updateInvoices, compare, addInvoiceComment} from "../services/accounts";
import LoadingOverlay from 'react-loading-overlay';
import {API_URL, notRequiredKeys} from "../constants";
import {useSelector, useDispatch} from "react-redux";
import {updateInvoiceComments, setAccountNumbers, setPaymentTerms} from "../store/invoices";
import NumberFormat from 'react-number-format';
import RejectInvoicePopup from "../components/popups/reject_invoice_popup";
import DeleteInvoicePopup from "../components/popups/delete_invoice_popup";
import CustomDatePicker from "./custom_date_picker";
import JoditEditor from "jodit-react";
import Select from "react-select";
import {useSnackbar} from "notistack";
import APCheckAmountPopup from "../components/popups/ap_check_amount_popup";
import {log} from "../utils/logger";


export function InvoiceEditForm(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [comment, setComment] = useState('');
    const [showCommentEditor, setShowCommentEditor] = useState(false);
    const [lineItems, setLineItems] = useState([]);
    const [accountNumberOption, setAccountNumberOption] = useState([]);
    const [paymentTermsOption, setPaymentTermsOption] = useState([]);
    const [docTypeOption, setDocTypeOption] = useState([{label: "Invoice", value: "Invoice"}, {
        label: "Credit Memo",
        value: "Credit Memo"
    }]);
    const [checkAmount, setCheckAmount] = useState(false);
    const [checkAmountValue, setCheckAmountValue] = useState(0);

    const {enqueueSnackbar} = useSnackbar();

    const editor = useRef(null);
    let newComment = '';
    const [content, setContent] = useState("");
    const config = {
        readonly: false,
        height: 200,
        placeholder: "Write a comment..."
    };
    const handleUpdate = (event) => {
        const editorContent = event.target.innerHTML;
        setContent(editorContent);
    };
    const [showRejectionPopup, setShowRejectionPopup] = useState(false);
    const [showDeletionPopup, setShowDeletionPopup] = useState(false);
    const [allComment, setAllComment] = useState([]);
    const [poClosedState, setPOClosedState] = useState(props.invoice[0]['po_closed']);
    const [poDocType, setPODocType] = useState(props.invoice[0].doc_type);

    const [differentAccountNumber, setDifferentAccountNumber] = useState(props.invoice[0]['is_multi_client_number']);
    const userGroups = useSelector((state) => state.auth.user.groups);
    const selectedDepartment = useSelector((state) => state.auth.user.departments.hasOwnProperty(
        props.invoice[0].department) ? state.auth.user.departments[props.invoice[0].department]
        : {});
    // const currentDepartmentId = useSelector((state) => state.auth.user.current_selected_department);
    const account_number = useSelector((state) => state.invoices.account_numbers);
    const payment_terms = useSelector((state) => state.invoices.payment_terms);
    const currentPermission = useSelector((state) => state.auth.user.current_selected_permission_ap);
    const currentDepartmentApprovementLevels = useSelector((state) => state.auth.user.department_approvement_level[props.invoice[0].department]);

    const nextInvoice = useSelector((state) => {
        return state.invoices.invoices[currentPermission]['active'].filter((inv) => {
            if (inv.id !== props.invoice[0].id) {
                return inv;
            }
        })
    });
    let keyObjs = Object.keys(props.invoice[0]['invoice_details']).map((key) => {
        if (!notRequiredKeys.includes(key) && key !== 'id') {
            return {key: key, sortID: props.invoice[0]['invoice_details'][key].column_order}
        }
    });
    keyObjs = keyObjs.filter((keyObj) => keyObj !== undefined);
    keyObjs.sort(compare);

    useEffect(async () => {
        if (props.invoice[0]['comments'])
            setAllComment(props.invoice[0]['comments'])
        setLineItems(props.invoice[0]['line_items']);
        setCheckAmountValue(props.invoice[0]['invoice_details']['invoice_amount'].value)
        if (formIk.errors.hasOwnProperty("line_items")) {
            delete formIk.errors['line_items'];
        }
        if (account_number?.length === 0) {
            const token = localStorage.getItem('userdata');
            const response = await fetch(`${API_URL}/account_number_options/`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                },
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });

            if (response.status === 200) {
                const data = await response.json()
                dispatch(setAccountNumbers(data));
                setLoading(false);
                setAccountNumberOption(data);
            }
        } else {
            setLoading(false);
            setAccountNumberOption(account_number);
        }
        if (payment_terms?.length === 0) {
            const token = localStorage.getItem('userdata');
            const response = await fetch(`${API_URL}/payment_terms_options/`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                },
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });

            if (response.status === 200) {
                const data = await response.json()
                dispatch(setPaymentTerms(data));
                setLoading(false);
                setPaymentTermsOption(data);
            }
        } else {
            setLoading(false);
            setPaymentTermsOption(payment_terms);
        }
    }, [props.invoice[0].id]);
    const getInitialValues = () => {
        const initialValues = {};
        const lineItemInitVals = [];
        const keys = Object.keys(props.invoice[0]['invoice_details']);
        keys.forEach((key) => {
            if (!notRequiredKeys.includes(key)) {
                if (props.invoice[0]['invoice_details'][key].value !== '' && props.invoice[0]['invoice_details'][key].value !== undefined && props.invoice[0]['invoice_details'][key].value !== null) {
                    initialValues[key] = props.invoice[0]['invoice_details'][key].value;
                } else if (props.invoice[0]['invoice_details'][key]['is_date'] === true) {
                    initialValues[key] = new Date().toISOString().split('T')[0];
                } else {
                    initialValues[key] = '';
                }
            }
        });
        props.invoice[0]['line_items'].forEach((line_item) => {
            const item_keys = Object.keys(line_item);
            const temp_line_item = {};
            item_keys.forEach((item_key) => {
                if (item_key !== 'id') {
                    temp_line_item[item_key] = line_item[item_key];
                }
            });
            lineItemInitVals.push(temp_line_item);
        });
        if (lineItemInitVals.length > 0) {
            initialValues['line_items'] = [];
            initialValues['line_items'] = lineItemInitVals;
        }
        lineItemInitVals['po_closed'] = poClosedState;
        lineItemInitVals['doc_type'] = poDocType;
        return initialValues;
    }

    const initVals = getInitialValues();
    const getValidation = () => {
        const keys = Object.keys(props.invoice[0]['invoice_details']);
        const validation = {};
        keys.forEach((key) => {
            if (!notRequiredKeys.includes(key) && key !== 'id') {
                validation[key] = props.invoice[0]['invoice_details'][key].is_required ?
                    Yup.string().required(titleCase(props.invoice[0]['invoice_details'][key]['label']) + ' is required.')
                    : Yup.string()
            }
        });
        validation['po_closed'] = Yup.boolean().oneOf([true, false], 'PO closed status required.')
        validation['is_multi_client_number'] = Yup.boolean().oneOf([true, false], 'Multi account number status required.')
        validation['line_items'] = Yup.array().of(
            Yup.object().shape({
                description: Yup.string().nullable(), // these constraints take precedence
                amount: Yup.string().nullable(), // these constraints take precedence
                client_number: Yup.string().nullable(), // these constraints take precedence
            })
        );
        return validation;
    }

    const formIk = useFormik({
        initialValues: initVals,
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLoading(true);
            let line_item_total = getLineItemTotal();
            let data = {};
            let {line_items, ...x} = values;
            data['id'] = props.invoice[0].id;
            data['line_items'] = JSON.parse(JSON.stringify(lineItems));
            data['invoice_details'] = {...x, 'id': props.invoice[0]['invoice_details']['id']};
            if (data['line_items'] !== undefined && data['line_items'].length > 0) {
                data['line_items'] = [...data['line_items'].filter((item, idx) => {
                    if ((item['description'] !== null && item['description'] !== undefined && item['description'] !== '') && (item['amount'] !== null && item['amount'] !== undefined && item['amount'] !== '' && !Number.isNaN(item['amount']))) {
                        if (typeof item['amount'] === 'string') {
                            item['amount'] = item['amount'].replaceAll('$', '').replaceAll('$ ', '')
                                .replaceAll(',', '');
                            item['amount'] = parseFloat(item['amount']);
                            return item
                        } else {
                            return item;
                        }
                    }
                })]
            } else {
                data['line_items'] = [];
            }
            if (differentAccountNumber === true && data["line_items"].length === 0) {
                formIk.errors['line_items'] = "There should be at-least one line item with all fields filled if you check different account number for each line items."
                enqueueSnackbar('There should be at-least one line item with all fields filled.', {variant: "error"});
                setSubmitting(false);
                setLoading(false);
                const myElement = document.getElementById("line_items_error");
                const topPos = myElement.offsetTop;
                window.scroll(0, topPos);
                document.getElementById('scrolling_div').scrollTop = 0;
                return;
            }

            if (differentAccountNumber === true) {
                for (let i = 0; i < data['line_items'].length; i++) {
                    if (data['line_items'][i]['acc_number'] === null || data['line_items'][i]['acc_number'] === undefined || data['line_items'][i]['acc_number'] === '') {
                        formIk.errors['line_items'] = "An account number should be assigned to all line items."
                        enqueueSnackbar('An account number should be assigned to all line items.', {variant: "error"});
                        setSubmitting(false);
                        setLoading(false);
                        const myElement = document.getElementById("line_items_error");
                        const topPos = myElement.offsetTop;
                        window.scroll(0, topPos);
                        document.getElementById('scrolling_div').scrollTop = 0;
                        return;
                    }
                }
            }

            if (differentAccountNumber === true && data["line_items"]) {
                data['line_items'] = [...data['line_items'].filter((item, idx) => {
                    if ((item['acc_number'] !== null && item['acc_number'] !== undefined && item['acc_number'] !== '')) {
                        item['acc_number'] = item['acc_number'].replaceAll("-", "");
                        return item;
                    }
                })];
            }
            for (const [key, value] of Object.entries(data['invoice_details'])) {
                if (value !== null && value !== undefined && value !== '') {
                    if (typeof value === 'string') {
                        data['invoice_details'][key] = value.replace('$ ', '')
                            .replace('$', '').replace(',', '');
                        if (key === 'client_number') {
                            data['invoice_details'][key] = data['invoice_details'][key].replaceAll("-", "");
                        } else if (props.invoice[0]['invoice_details'][key].is_amount) {
                            data['invoice_details'][key] = parseFloat(data['invoice_details'][key]);
                        }
                    }
                } else {
                    delete data['invoice_details'][key];
                }
            }
            if (differentAccountNumber === true && data['line_items'].length > 0 && parseFloat(line_item_total) !== (data['invoice_details']['invoice_amount'])) {
                formIk.errors['line_items'] = "Line item total must match invoice amount.";
                enqueueSnackbar('Line item total must match invoice amount.', {variant: "error"});
                setSubmitting(false);
                setLoading(false);
                const myElement = document.getElementById("line_items_error");
                const topPos = myElement.offsetTop;
                window.scroll(0, topPos);
                document.getElementById('scrolling_div').scrollTop = 0;
                return;
            }

            data['approvement_level'] = props.invoice[0]['approvement_level'];
            data['po_closed'] = poClosedState;
            data['doc_type'] = poDocType;
            data['is_multi_client_number'] = differentAccountNumber;

            updateInvoices(data).then(async (response) => {
                if (response === 200) {
                    props.editCallback(true);
                    setLoading(false);
                    props.success(true);
                    if (nextInvoice !== undefined && nextInvoice.length > 0) {
                        props.setEdtInv(nextInvoice[0].id);
                    } else {
                        props.setEdtInv([]);
                    }

                } else if (response === 403) {
                    console.log("403", response)
                    console.log("No permission to update invoice with user selected acc number")
                    props.failure(true);
                    props.failureMessage("No permission to update invoice with user selected acc number");
                    setSubmitting(false);
                    setLoading(false);
                } else {
                    props.failure(true);
                    setLoading(false);
                }
                setSubmitting(false);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                props.failure(true);
                setSubmitting(false);
                setLoading(false);
            });
        },
        validationSchema: Yup.object(getValidation())
    })
    const getLineItemTotal = () => {
        let total = 0.00;
        lineItems.map((item) => total += parseFloat(item['amount']));
        return total.toFixed(2);
    }


    const rejectCallBack = () => {
        // props.editCallback({
        //     permissionType: currentPermission, invoiceType: props.type, invoicesToRemove:
        //         [props.invoice[0].id], data: {}
        // }, true);
        props.editCallback(true);
        if (nextInvoice !== undefined && nextInvoice.length > 0) {
            props.setEdtInv(nextInvoice[0].id);
        } else {
            props.setEdtInv([]);
        }
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const checkAmountCallBack = (e) => {
        if (e !== null && e !== undefined && e) {
            formIk.submitForm();
        }
    }
    return (
        <>
            {
                !showCommentEditor &&
                <div style={{textAlign: "right"}}>
                    <button className="btn btn-success" onClick={(e) => {
                        e.preventDefault();
                        setShowCommentEditor(true);
                    }
                    }>Add Comment
                    </button>
                </div>
            }
            {
                checkAmount &&
                <APCheckAmountPopup show={true} amount={formIk.values.invoice_amount} onCallback={checkAmountCallBack}
                                    closePopup={setCheckAmount}/>
            }
            <LoadingOverlay
                active={loading}
                spinner
                text='Updating Invoice...'
            >
                <div className="row" style={{height: "1300px", marginBottom: "30px", marginTop: "30px"}}>
                    {
                        showCommentEditor && props.type === "active" && (
                            <div style={{textAlign: "left", padding: "20px"}}>
                                {/* <input placeholder="Comment Text..." type="text" className="form-control" defaultValue={comment} onChange={evt => setComment(evt.target.value)}/> */}
                                {/*<textarea placeholder="Comment Text..." type="text" className="form-control"*/}
                                {/*          defaultValue={comment} onChange={evt => setComment(evt.target.value)}></textarea>*/}
                                <JoditEditor
                                    ref={editor}
                                    config={config}
                                    onChange={(newContent) => {
                                        newComment = newContent;
                                    }}
                                />
                                {/*<div dangerouslySetInnerHTML={{ __html: content }} />*/}
                                <button className="btn btn-success" style={{marginTop: "10px"}} onClick={(e) => {
                                    e.preventDefault();
                                    if (newComment === '' || newComment === undefined) {
                                        alert('Comment text is required.');
                                        return;
                                    }
                                    setLoading(true);
                                    addInvoiceComment(props.invoice[0].id, newComment).then((response) => {
                                        // setComment('');
                                        setAllComment(response);
                                        dispatch(updateInvoiceComments({
                                            id: props.invoice[0].id,
                                            permissionType: currentPermission, invoiceType: 'active', comments: response
                                        }))
                                        newComment = '';
                                        setLoading(false);
                                        setShowCommentEditor(false);
                                    }).catch((error) => {
                                        setComment('');
                                        console.log(error);
                                        setLoading(false);
                                        setShowCommentEditor(false);
                                    })
                                }}>Add Comment
                                </button>
                                <button style={{marginLeft: "10px", marginTop: "10px"}} className="btn btn-danger"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowCommentEditor(false);
                                        }}>Cancel
                                </button>
                            </div>
                        )
                    }
                    {
                        allComment.length > 0 &&
                        <div className="col-12" style={{height: "500px", overflowY: "scroll", marginBottom: "15px"}}>
                            {
                                (allComment.length > 0) ?
                                    <>
                                        <h4 style={{marginTop: "10px"}}>Comments:</h4>
                                        <hr className="col-12"/>
                                    </> : <></>
                            }
                            {
                                allComment.length > 0 && (
                                    allComment.map((comment) => {
                                        return (
                                            <div>
                                                <p style={{
                                                    fontSize: "15px",
                                                    fontStyle: "italic",
                                                    margin: "0px !important"
                                                }}>{comment.user} - <span className="comment-span"><h5
                                                    style={{display: "inline"}}
                                                    dangerouslySetInnerHTML={{__html: comment.comment}}/></span>
                                                </p>
                                                {/*<h5 dangerouslySetInnerHTML={{ __html: comment.comment }} />*/}
                                                <hr className="col-12"/>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    }
                    <div className="col-lg-4 col-sm-12" style={{height: "800px", overflowY: "scroll"}}
                         id="scrolling_div">
                        {props.type === "active" && account_number.length !== 0 &&
                            <>
                                <Form onSubmit={formIk.handleSubmit}>
                                    {
                                        keyObjs.map(({key}) => {
                                            if (!notRequiredKeys.includes(key.key)) {
                                                if (key === 'client_number') {
                                                    return (
                                                        <div className="form-group mb-2"
                                                             key={key + props.invoice[0]['invoice_details'][key].value}>

                                                            <label htmlFor={key}
                                                                   className={props.invoice[0]['invoice_details'][key].is_required === true ? 'required-label' : ''}><b>{titleCase(props.invoice[0]['invoice_details'][key]['label'])}</b></label>

                                                            <Select
                                                                name={key}
                                                                onChange={(opt, e) => {
                                                                    formIk.setFieldValue(key, opt.value);
                                                                    if (formIk.errors.hasOwnProperty("line_items")) {
                                                                        delete formIk.errors['line_items'];
                                                                    }
                                                                }}
                                                                options={accountNumberOption}
                                                                defaultValue={account_number.filter((opt) => opt.value.replaceAll("-", "") === props.invoice[0]['invoice_details'][key].value)[0]}
                                                            />
                                                            {formIk.errors[key] && formIk.touched[key] && (
                                                                <div className="text-error">{formIk.errors[key]}</div>

                                                            )}
                                                        </div>
                                                    )
                                                } else if (props.invoice[0]['invoice_details'][key]['is_date'] === true) {
                                                    return (
                                                        <div className="form-group mb-2"
                                                             key={key + props.invoice[0]['invoice_details'][key].value}>
                                                            <label
                                                                htmlFor={key}
                                                                className={props.invoice[0]['invoice_details'][key].is_required === true ? 'required-label' : ''}><b>{titleCase(props.invoice[0]['invoice_details'][key]['label'])}</b></label>
                                                            <CustomDatePicker className="form-control"
                                                                              name={key} id={key}
                                                                              value={props.invoice[0]['invoice_details'][key].value ? props.invoice[0]['invoice_details'][key].value : formatDate(new Date)}
                                                                              onChange={(value) => {
                                                                                  if (formatDate(value) === '1970-01-01') {
                                                                                      formIk.setFieldValue(key, '');
                                                                                  } else {
                                                                                      formIk.setFieldValue(key, formatDate(value))
                                                                                  }
                                                                                  if (formIk.errors.hasOwnProperty("line_items")) {
                                                                                      delete formIk.errors['line_items'];
                                                                                  }
                                                                              }} format="y-MM-dd"
                                                                              required={props.invoice[0]['invoice_details'][key].is_required}/>
                                                            {formIk.errors[key] && formIk.touched[key] && (
                                                                <div className="text-error">{formIk.errors[key]}</div>
                                                            )}
                                                        </div>
                                                    )
                                                } else if (props.invoice[0]['invoice_details'][key]['is_amount'] === true) {
                                                    return (
                                                        <div className="form-group mb-2"
                                                             key={key + props.invoice[0]['invoice_details'][key].value}>
                                                            <label htmlFor={key}
                                                                   className={props.invoice[0]['invoice_details'][key].is_required === true ?
                                                                       'required-label' : ''}><b>{titleCase(props.invoice[0]['invoice_details'][key]['label'])}</b></label>
                                                            <NumberFormat prefix={'$ '}
                                                                          type="text" className="form-control"
                                                                          decimalScale={2} fixedDecimalScale={true}
                                                                          thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                                                          decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                                                          name={key} id={key}
                                                                          defaultValue={props.invoice[0]['invoice_details'][key].value}
                                                                          onBlur={formIk.handleBlur}
                                                                          disabled={key === 'invoice_amount' ? differentAccountNumber : false}
                                                                          onChange={(event) => {

                                                                              formIk.handleChange(event);
                                                                              if (formIk.errors.hasOwnProperty("line_items")) {
                                                                                  delete formIk.errors['line_items'];
                                                                              }
                                                                              if(key === 'invoice_amount') {
                                                                                  if(navigator.languages[0] === "en-US") {
                                                                                      setCheckAmountValue(parseFloat(event.target.value.split(' ')[1].replace(",","").replace(".",",")))
                                                                                  }else {
                                                                                      setCheckAmountValue(parseFloat(event.target.value.split(' ')[1].replace(".","")))
                                                                                  }
                                                                              }
                                                                          }}/>
                                                            {formIk.errors[key] && formIk.touched[key] && (
                                                                <div className="text-error">{formIk.errors[key]}</div>
                                                            )}
                                                        </div>)
                                                } else if (key === 'payment_terms') {
                                                    return (
                                                        <div className="form-group mb-2"
                                                             key={key + props.invoice[0]['invoice_details'][key].value}>

                                                            <label htmlFor={key}
                                                                   className={props.invoice[0]['invoice_details'][key].is_required === true ? 'required-label' : ''}><b>{titleCase(props.invoice[0]['invoice_details'][key]['label'])}</b></label>

                                                            <Select
                                                                name={key}
                                                                onChange={(opt, e) => {
                                                                    formIk.setFieldValue(key, opt.value);
                                                                    if (formIk.errors.hasOwnProperty("line_items")) {
                                                                        delete formIk.errors['line_items'];
                                                                    }
                                                                }}
                                                                options={paymentTermsOption}
                                                                defaultValue={payment_terms.filter((opt) => opt.value === props.invoice[0]['invoice_details'][key].value)[0]}
                                                            />
                                                            {formIk.errors[key] && formIk.touched[key] && (
                                                                <div className="text-error">{formIk.errors[key]}</div>

                                                            )}
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="form-group mb-2"
                                                             key={key + props.invoice[0]['invoice_details'][key].value}>
                                                            <label
                                                                htmlFor={key}
                                                                className={props.invoice[0]['invoice_details'][key].is_required === true ? 'required-label' : ''}><b>{titleCase(props.invoice[0]['invoice_details'][key]['label'])}</b></label>
                                                            <input type="text" className="form-control"
                                                                   name={key}
                                                                   id={key}
                                                                   defaultValue={props.invoice[0]['invoice_details'][key].value}
                                                                   onBlur={formIk.handleBlur}
                                                                   onChange={(event) => {
                                                                       formIk.handleChange(event);
                                                                       if (formIk.errors.hasOwnProperty("line_items")) {
                                                                           delete formIk.errors['line_items'];
                                                                       }
                                                                   }}/>
                                                            {formIk.errors[key] && formIk.touched[key] && (
                                                                <div className="text-error">{formIk.errors[key]}</div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                    <div
                                        className="form-group mb-2"
                                        key={"doc_type"}>
                                        <label
                                            htmlFor={"doc_type"}><b>Document Type</b></label>
                                        <Select
                                            name={"doc_type"}
                                            defaultValue={props.invoice[0].doc_type === undefined ? docTypeOption[0] : docTypeOption.filter((fil) => fil.value === props.invoice[0].doc_type)[0]}
                                            onChange={(opt, e) => {
                                                formIk.setFieldValue("doc_type");
                                                setPODocType(opt.value);
                                            }}
                                            options={docTypeOption}
                                        />
                                        {formIk.errors["doc_type"] && formIk.touched["doc_type"] && (
                                            <div
                                                className="text-error">{formIk.errors["doc_type"]}</div>
                                        )}
                                    </div>
                                    <div
                                        className="form-group mb-2"
                                        key={"is_multi_client_number"}>
                                        <label
                                            htmlFor={"is_multi_client_number"}><b>Add Account Number Per Line
                                            Item</b></label>
                                        <input style={{marginLeft: "3%"}} type="checkbox" size="sm"
                                               onBlur={formIk.handleBlur}
                                               onChange={() => {
                                                   setDifferentAccountNumber(!differentAccountNumber);
                                                   if (formIk.errors.hasOwnProperty("line_items")) {
                                                       delete formIk.errors['line_items'];
                                                   }
                                                   if (!differentAccountNumber) {
                                                       setLineItems([...lineItems, {
                                                           id: 0,
                                                           description: '',
                                                           amount: 0.00,
                                                           acc_number: ''
                                                       }]);
                                                   } else {
                                                       setLineItems([]);
                                                   }

                                               }}
                                               name='is_multi_client_number' id='is_multi_client_number'
                                               checked={differentAccountNumber}/>
                                        {formIk.errors["is_multi_client_number"] && formIk.touched["is_multi_client_number"] && (
                                            <div
                                                className="text-error">{formIk.errors["is_multi_client_number"]}</div>
                                        )}
                                    </div>
                                    {formIk.errors.hasOwnProperty("line_items") && formIk.errors["line_items"] && (
                                        <div id="line_items_error" className="text-error">
                                            <b>{formIk.errors["line_items"]}</b></div>
                                    )}
                                    {
                                        differentAccountNumber && lineItems.length > 0 && <p><b>Line Items</b></p>
                                    }
                                    {
                                        differentAccountNumber && lineItems &&
                                        lineItems.map((item, idx) => {
                                            return (<div className="line-item-border"><p className="line-item-text">Line
                                                Item {idx + 1}</p>
                                                <button className="btn btn-danger line-item-btn" onClick={(e) => {
                                                    e.preventDefault();
                                                    let state_line_items = JSON.parse(JSON.stringify(lineItems))
                                                    state_line_items.splice(idx, 1);
                                                    setLineItems(state_line_items);
                                                    if (formIk.errors.hasOwnProperty("line_items")) {
                                                        delete formIk.errors['line_items'];
                                                    }
                                                }}>Remove
                                                </button>
                                                {
                                                    Object.entries(item).map(([key, value]) => {
                                                        if (key !== 'id') {
                                                            if (key === 'amount') {
                                                                return (
                                                                    <div style={{marginLeft: "20px"}}
                                                                         className="form-group mb-2"
                                                                         key={"line_items[" + idx + "]." + key + value}>
                                                                        <label
                                                                            htmlFor={"line_items[" + idx + "]." + key}><b>{titleCase(key)}</b></label>
                                                                        <NumberFormat thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                                                                      decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                                                                      prefix={'$ '} type="text"
                                                                                      className="form-control"
                                                                                      decimalScale={2}
                                                                                      fixedDecimalScale={true}
                                                                                      name={"line_items[" + idx + "]." + key}
                                                                                      id={"line_items[" + idx + "]." + key}
                                                                                      defaultValue={value}
                                                                                      onBlur={(event) => {
                                                                                          formIk.handleBlur(event);
                                                                                          if (formIk.errors.hasOwnProperty("line_items")) {
                                                                                              delete formIk.errors['line_items'];
                                                                                          }
                                                                                          let state_line_items = JSON.parse(JSON.stringify(lineItems));
                                                                                          state_line_items[idx]['amount'] = parseFloat(event.target.value.replace("$ ", "").replace(',', ''));
                                                                                          setLineItems(state_line_items);
                                                                                      }}
                                                                                      key={"line_items[" + idx + "]." + key + value}
                                                                                      onChange={formIk.handleChange}/>
                                                                        {formIk.errors.hasOwnProperty("line_items") && formIk.errors["line_items"].length > 0 && formIk.errors["line_items"][idx] && (
                                                                            <div
                                                                                className="text-error">{formIk.errors["line_items"][idx][key]}</div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            } else if (key === 'acc_number') {
                                                                return (<div style={{marginLeft: "20px"}}
                                                                             className="form-group mb-2"
                                                                             key={"line_items[" + idx + "]." + key}>
                                                                    {differentAccountNumber === true &&
                                                                        <>
                                                                            <label
                                                                                htmlFor={"line_items[" + idx + "]." + key}><b>{titleCase(key)}</b></label>
                                                                            <Select
                                                                                name={"line_items[" + idx + "]." + key}
                                                                                onChange={(opt, e) => {
                                                                                    formIk.setFieldValue("line_items[" + idx + "]." + key, opt.value);
                                                                                    if (formIk.errors.hasOwnProperty("line_items")) {
                                                                                        delete formIk.errors['line_items'];
                                                                                    }
                                                                                    let state_line_items = JSON.parse(JSON.stringify(lineItems));
                                                                                    state_line_items[idx]['acc_number'] = opt.value;
                                                                                    setLineItems(state_line_items);
                                                                                }}
                                                                                options={accountNumberOption}
                                                                                defaultValue={account_number.filter((opt) => opt.value.replaceAll("-", "") === value)[0]}
                                                                            />
                                                                            {formIk.errors.hasOwnProperty("line_items") && formIk.errors["line_items"].length > 0 && formIk.errors["line_items"][idx] && (
                                                                                <div
                                                                                    className="text-error">{formIk.errors["line_items"][idx][key]}</div>
                                                                            )}
                                                                        </>
                                                                    }
                                                                </div>)
                                                            } else {
                                                                return (
                                                                    <div style={{marginLeft: "20px"}}
                                                                         className="form-group mb-2"
                                                                         key={"line_items[" + idx + "]." + key}>
                                                                        <label
                                                                            htmlFor={"line_items[" + idx + "]." + key}><b>{titleCase(key)}</b></label>
                                                                        <input type="text" className="form-control"
                                                                               name={"line_items[" + idx + "]." + key}
                                                                               id={"line_items[" + idx + "]." + key}
                                                                               defaultValue={value} onBlur={(event) => {
                                                                            formIk.handleBlur(event);
                                                                            if (formIk.errors.hasOwnProperty("line_items")) {
                                                                                delete formIk.errors['line_items'];
                                                                            }
                                                                            let state_line_items = JSON.parse(JSON.stringify(lineItems));
                                                                            state_line_items[idx]['description'] = event.target.value;
                                                                            setLineItems(state_line_items);
                                                                        }}
                                                                               key={"line_items[" + idx + "]." + key + value}
                                                                               onChange={formIk.handleChange}/>
                                                                        {formIk.errors.hasOwnProperty("line_items") && formIk.errors["line_items"].length > 0 && formIk.errors["line_items"][idx] && (
                                                                            <div
                                                                                className="text-error">{formIk.errors["line_items"][idx][key]}</div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    })}</div>)
                                        })
                                    }
                                    {
                                        differentAccountNumber && lineItems.length > 0 &&
                                        <p style={{textAlign: "left"}}><b>Line Items Total: $ {getLineItemTotal()}</b>
                                        </p>
                                    }
                                    {differentAccountNumber && props.type === "active" &&
                                        <div style={{textAlign: "right"}}>
                                            <button style={{marginLeft: "5px"}} className="btn btn-primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (formIk.errors.hasOwnProperty("line_items")) {
                                                            delete formIk.errors['line_items'];
                                                        }
                                                        setLineItems([...lineItems, {
                                                            id: 0,
                                                            description: '',
                                                            amount: 0.00,
                                                            acc_number: ''
                                                        }]);
                                                    }
                                                    }>Add Line Item
                                            </button>
                                        </div>}

                                    <div style={{marginLeft: "20px"}}
                                         className="form-group mb-2"
                                         key={"po_closed"}>
                                        <label
                                            htmlFor={"po_closed"}><b>PO Closed</b></label>
                                        <input style={{marginLeft: "3%"}} type="checkbox" size="sm"
                                               onBlur={formIk.handleBlur}
                                               onChange={() => {
                                                   setPOClosedState(!poClosedState);
                                                   if (formIk.errors.hasOwnProperty("line_items")) {
                                                       delete formIk.errors['line_items'];
                                                   }
                                               }}
                                               name='po_closed' checked={poClosedState}/>
                                        {formIk.errors["po_closed"] && formIk.touched["po_closed"] && (
                                            <div
                                                className="text-error">{formIk.errors["po_closed"]}</div>
                                        )}
                                    </div>

                                    {props.type === "active" &&
                                        <button disabled={loading} onClick={(e) => {
                                            if (Object.keys(formIk.errors).length > 0) {
                                                console.log(formIk.errors)
                                                const error_keys = Object.keys(formIk.errors)
                                                if (error_keys[0] !== 'line_items') {
                                                    const myElement = document.getElementById(error_keys[0]);
                                                    const topPos = myElement.offsetTop;
                                                    window.scroll(0, topPos);
                                                    document.getElementById('scrolling_div').scrollTop = topPos - 20;
                                                    document.getElementById(Object.keys(formIk.errors)[0]).focus();
                                                }
                                            }
                                            log("checkAmountValue,",checkAmountValue);
                                            if (checkAmountValue >= 25000) {
                                                e.preventDefault();
                                                setCheckAmount(true);
                                            } else {
                                                formIk.submitForm();
                                            }

                                        }}
                                                className="btn btn-primary">Confirm</button>}
                                    <button className="btn btn-danger" onClick={() => {
                                        props.setEdtInv([]);
                                    }} style={{marginLeft: "5px"}}>Close
                                    </button>
                                    {
                                        selectedDepartment['ap_permissions'].includes("patch") && (
                                            <button className="btn btn-warning" onClick={(e) => {
                                                e.preventDefault();
                                                setShowRejectionPopup(true);
                                            }} style={{marginLeft: "5px"}}>Reject
                                            </button>
                                        )
                                    }
                                    {
                                        selectedDepartment['ap_permissions'].includes("delete") && (
                                            <button className="btn btn-danger" onClick={(e) => {
                                                e.preventDefault();
                                                setShowDeletionPopup(true);
                                            }} style={{marginLeft: "5px"}}>Delete
                                            </button>
                                        )
                                    }
                                    {
                                        showDeletionPopup &&
                                        <DeleteInvoicePopup show={true} closePopup={setShowDeletionPopup}
                                                            invoice_id={props.invoice[0].id}
                                                            reject_inovice_callback={rejectCallBack}/>
                                    }
                                    {
                                        showRejectionPopup &&
                                        <RejectInvoicePopup show={true} closePopup={setShowRejectionPopup}
                                                            invoice_id={props.invoice[0].id}
                                                            approvement_levels={currentDepartmentApprovementLevels}
                                                            reject_inovice_callback={rejectCallBack}/>
                                    }
                                </Form>
                            </>
                        }
                        {
                            props.type !== "active" &&
                            <button className="btn btn-danger" onClick={() => {
                                props.setEdtInv([]);
                            }} style={{marginLeft: "5px"}}>Close
                            </button>
                        }
                    </div>
                    <iframe className="col-lg-8 col-sm-12"
                            src={props.invoice[0].download_link} width="100%">
                    </iframe>

                </div>
            </LoadingOverlay>
        </>
    )
}


InvoiceEditForm.propTypes = {
    invoice: PropTypes.array.isRequired,
    setEdtInv: PropTypes.func.isRequired,
    editCallback: PropTypes.func.isRequired,
    success: PropTypes.func.isRequired,
    failure: PropTypes.func.isRequired,
    failureMessage: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
}
