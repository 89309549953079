import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    users: [],
    locations: [],
    departments: [],
    po_permissions: [],
    ap_permissions: [],
    super_po_users: [],
    process_permissions: [],
    law_firms: [],
    law_firm_contacts: [],
    law_firm_professional_guardians: [],
    law_firm_branches: [],
    law_schools: [],
    bond_numbers: [],
    user_law_firms: [],
}

const companyManagementSlice = createSlice({
    name: 'company_management',
    initialState,
    reducers: {
        addUser(state, action) {
            state.users = [action.payload, ...state.users]
        },
        addUsers(state, action) {
            state.users = action.payload;
        },
        removeUser(state, action) {
            state.users = state.users.filter((user) => user.id !== action.payload.id)
        },
        removeUsers(state, action) {
            state.users = [];
        },
        updateUser(state, action) {
            state.users = state.users.map((user) => {
                if (user.id === action.payload.id) {
                    return {
                        id: action.payload.id, email: action.payload.email, first_name: action.payload.first_name,
                        last_name: action.payload.last_name, department: action.payload.department,
                        is_portal_admin: action.payload.is_portal_admin, buyer_id: action.payload.buyer_id,
                        is_super_po: user.is_super_po,
                        user_role_name: action.payload.user_role_name,
                        user_role_id: action.payload.user_role_id
                    }
                }
                return user
            })
            state.ap_permissions = state.ap_permissions.map((ap_permission) => {
                if (ap_permission.user === action.payload.id) {
                    ap_permission.user_name = `${action.payload.email}`
                }
                return ap_permission
            })
            state.po_permissions = state.po_permissions.map((po_permission) => {
                if (po_permission.user === action.payload.id) {
                    po_permission.user_name = `${action.payload.email}`
                }
                return po_permission
            })
            state.super_po_users = state.super_po_users.map((super_po_user) => {
                if (super_po_user.id === action.payload.id) {
                    super_po_user.user_name = `${action.payload.email}`
                }
                return super_po_user
            })
            state.process_permissions = state.process_permissions.map((process_permission) => {
                if (process_permission.id === action.payload.id) {
                    process_permission.user_name = `${action.payload.email}`
                }
                return process_permission
            })
        },
        updateVermostUserActiveStatus(state, action) {
            state.users = state.users.map((user) => {
                if (user.id === action.payload.id) {
                    user.is_active = false;
                }
                return user
            })
        },
        updateVermostUser(state, action) {
            state.users = state.users.map((user) => {
                if (user.id === action.payload.id) {
                    return {
                        id: action.payload.id,
                        email: action.payload.email,
                        first_name: action.payload.first_name,
                        last_name: action.payload.last_name,
                        phone_number: action.payload.phone_number,
                        username: action.payload.username,
                        is_active: action.payload.is_active,
                        is_portal_admin: action.payload.is_portal_admin,
                        is_portal_super_admin: action.payload?.is_portal_super_admin,
                        user_role_name: action.payload.user_role_name,
                        user_role_id: action.payload.user_role_id,
                        // user_role: action.payload.user_role,

                    }
                }
                return user
            })
        },
        addLocations(state, action) {
            state.locations = action.payload;
        },
        updateUserDepartmentName(state, action) {
            state.users = state.users.map((user) => {
                if (user.department === action.payload.department) {
                    user.department = action.payload.new_department;
                }
                return user
            });
        },
        addDepartments(state, action) {
            state.departments = action.payload;
        },
        removeDepartment(state, action) {
            const dep_to_remove = state.departments.filter((department) => department.id === action.payload.id)[0]
            state.departments = state.departments.filter((department) => department.id !== action.payload.id);
            state.users = state.users.map((user) => {
                if (user.department === dep_to_remove.name) {
                    user.department = '';
                }
                return user;
            })
        },
        removeDepartmentsCM(state, action) {
            state.departments = [];
        },
        updateDepartment(state, action) {
            state.departments = state.departments.map((dep) => {
                if (dep.id === action.payload.id) {
                    return {
                        id: action.payload.id, company: action.payload.company, name: action.payload.name,
                        code: action.payload.code
                        , po_approval_threshold: action.payload.po_approval_threshold
                    }
                }
                return dep
            })
        },
        addNewDepartment(state, action) {
            state.departments.push(action.payload);
        },
        addAPPermissions(state, action) {
            state.ap_permissions = action.payload;
        },
        removeAPPermission(state, action) {
            state.ap_permissions = state.ap_permissions.filter((ap_permission) => ap_permission.id !== action.payload.id)
        },
        removeAPPermissions(state, action) {
            state.ap_permissions = [];
        },
        addNewAPPermission(state, action) {
            state.ap_permissions.push(action.payload);
        },
        updateAPPermission(state, action) {
            state.ap_permissions = state.ap_permissions.map((ap_permission) => {
                if (ap_permission.id === action.payload.id) {
                    return {...action.payload}
                }
                return ap_permission
            })
        },
        addPOPermissions(state, action) {
            state.po_permissions = action.payload;
        },
        removePOPermission(state, action) {
            state.po_permissions = state.po_permissions.filter((po_permission) => po_permission.id !== action.payload.id)
        },
        removePOPermissions(state, action) {
            state.po_permissions = [];
        },
        addNewPOPermission(state, action) {
            state.po_permissions.push(action.payload);
        },
        updatePOPermission(state, action) {
            state.po_permissions = state.po_permissions.map((po_permission) => {
                if (po_permission.id === action.payload.id) {
                    return {...action.payload}
                }
                return po_permission
            })
        },
        updatePOAPDepartment(state, action) {
            state.po_permissions = state.po_permissions.map((po_permission) => {
                if (po_permission.department_name === action.payload.department) {
                    po_permission.department_name = action.payload.new_department;
                }
                return po_permission
            });
            state.ap_permissions = state.ap_permissions.map((ap_permission) => {
                if (ap_permission.department_name === action.payload.department) {
                    ap_permission.department_name = action.payload.new_department;
                }
                return ap_permission
            })
            state.process_permissions = state.process_permissions.map((process_permission) => {
                if (process_permission.department_name === action.payload.department) {
                    process_permission.department_name = action.payload.new_department;
                }
                return process_permission
            })
        },
        updatePOAPUserName(state, action) {
            state.po_permissions = state.po_permissions.map((po_permission) => {
                if (po_permission.user === action.payload.user_id) {
                    po_permission.user_name = action.payload.user_name;
                }
                return po_permission
            });
            state.ap_permissions = state.ap_permissions.map((ap_permission) => {
                if (ap_permission.user === action.payload.user_id) {
                    ap_permission.user_name = action.payload.user_name;
                }
                return ap_permission
            })
            state.process_permissions = state.process_permissions.map((process_permission) => {
                if (process_permission.user === action.payload.user_id) {
                    process_permission.user_name = action.payload.user_name;
                }
                return process_permission
            })
        },
        addSuperPOUsers(state, action) {
            state.super_po_users = action.payload;
        },
        addNewSuperPOUser(state, action) {
            state.super_po_users.push(action.payload);
        },
        removeSuperPOUser(state, action) {
            state.super_po_users = state.super_po_users.filter((super_po_user) => super_po_user.id !== action.payload.id)
        },
        removeSuperPOUsers(state, action) {
            state.super_po_users = [];
        },
        updateSuperPOUser(state, action) {
            state.super_po_users = state.super_po_users.map((super_po_user) => {
                if (super_po_user.id === action.payload.id) {
                    super_po_user.is_super_po = action.payload.is_super_po;
                }
                return super_po_user
            });
        },
        addProcessPermissions(state, action) {
            state.process_permissions = action.payload;
        },
        removeProcessPermission(state, action) {
            state.process_permissions = state.process_permissions.filter((process_permission) => process_permission.id !== action.payload.id)
        },
        removeProcessPermissions(state, action) {
            state.process_permissions = [];
        },
        addNewProcessPermission(state, action) {
            state.process_permissions.push(action.payload);
        },
        updateProcessPermission(state, action) {
            state.process_permissions = state.process_permissions.map((process_permission) => {
                if (process_permission.id === action.payload.id) {
                    return {...action.payload}
                }
                return process_permission
            })
        },
        addLawFirm(state, action) {
            state.law_firms = [action.payload, ...state.law_firms]
            // state.law_firms.push(action.payload);
        },
        addLawFirms(state, action) {
            state.law_firms = action.payload;
        },
        updateLawFirm(state, action) {
            state.law_firms = state.law_firms.map((law_firm) => {
                if (law_firm.id === action.payload.id) {
                    return {...action.payload}
                }
                return law_firm
            })
        },
        removeLawFirm(state, action) {
            state.law_firms = state.law_firms.filter((law_firm) => law_firm.id !== action.payload.id)
        },
        removeLawFirms(state, action) {
            state.law_firms = [];
        },
        addLawFirmContact(state, action) {
            state.law_firm_contacts = [action.payload, ...state.law_firm_contacts]
            // state.law_firm_contacts.push(action.payload);
        },
        addLawFirmContacts(state, action) {
            state.law_firm_contacts = action.payload;
        },
        updateLawFirmContact(state, action) {
            state.law_firm_contacts = state.law_firm_contacts.map((law_firm_contact) => {
                if (law_firm_contact.id === action.payload.id) {
                    return {...action.payload}
                }
                return law_firm_contact
            })
        },
        removeLawFirmContact(state, action) {
            state.law_firm_contacts = state.law_firm_contacts.filter((law_firm_contact) => law_firm_contact.id !== action.payload.id)
        },
        removeLawFirmContacts(state, action) {
            state.law_firm_contacts = [];
        },
        addLawFirmProfessionalGuardian(state, action) {
            state.law_firm_professional_guardians = [action.payload, ...state.law_firm_professional_guardians];
        },
        addLawFirmProfessionalGuardians(state, action) {
            state.law_firm_professional_guardians = action.payload;
        },
        updateLawFirmProfessionalGuardian(state, action) {
            state.law_firm_professional_guardians = state.law_firm_professional_guardians.map((law_firm_professional_guardian) => {
                if (law_firm_professional_guardian.id === action.payload.id) {
                    return {...action.payload}
                }
                return law_firm_professional_guardian
            })
        },
        removeLawFirmProfessionalGuardian(state, action) {
            state.law_firm_professional_guardians = state.law_firm_professional_guardians.filter((law_firm_professional_guardian) => law_firm_professional_guardian.id !== action.payload.id)
        },
        removeLawFirmProfessionalGuardians(state, action) {
            state.law_firm_professional_guardians = [];
        },
        addLawFirmBranch(state, action) {
            state.law_firm_branches = [action.payload, ...state.law_firm_branches]
            // state.law_firm_branches.push(action.payload);
        },
        addLawFirmBranches(state, action) {
            state.law_firm_branches = action.payload;
        },
        updateLawFirmBranch(state, action) {
            state.law_firm_branches = state.law_firm_branches.map((law_firm_branch) => {
                if (law_firm_branch.id === action.payload.id) {
                    return {...action.payload}
                }
                return law_firm_branch
            })
        },
        removeLawFirmBranch(state, action) {
            state.law_firm_branches = state.law_firm_branches.filter((law_firm_branch) => law_firm_branch.id !== action.payload.id)
        },
        removeLawFirmBranches(state, action) {
            state.law_firm_branches = [];
        },
        addLawSchool(state, action) {
            state.law_schools = [action.payload, ...state.law_schools]
            // state.law_schools.push(action.payload);
        },
        addLawSchools(state, action) {
            state.law_schools = action.payload;
        },
        updateLawSchool(state, action) {
            state.law_schools = state.law_schools.map((law_school) => {
                if (law_school.id === action.payload.id) {
                    return {...action.payload}
                }
                return law_school
            })
        },
        removeLawSchool(state, action) {
            state.law_schools = state.law_schools.filter((law_school) => law_school.id !== action.payload.id)
        },
        removeLawSchools(state, action) {
            state.law_schools = [];
        },
        addBondNumbers(state, action) {
            state.bond_numbers = action.payload;
        },
        removeBondNumbers(state, action) {
            state.bond_numbers = [];
        },
        addUserLawFirms(state, action) {
            state.user_law_firms = action.payload;
        },
    }
});

export const {
    addUser,
    addUsers,
    removeUser,
    removeUsers,
    updateUser,
    updateVermostUserActiveStatus,
    updateVermostUser,
    addLocations,
    updateUserDepartmentName,
    addDepartments,
    removeDepartment,
    removeDepartmentsCM,
    updateDepartment,
    addNewDepartment,
    addAPPermissions,
    removeAPPermission,
    removeAPPermissions,
    addNewAPPermission,
    updateAPPermission,
    addPOPermissions,
    removePOPermission,
    removePOPermissions,
    addNewPOPermission,
    updatePOPermission,
    updatePOAPDepartment,
    updatePOAPUserName,
    addSuperPOUsers,
    addNewSuperPOUser,
    removeSuperPOUsers,
    updateSuperPOUser,
    removeSuperPOUser,
    addProcessPermissions,
    removeProcessPermission,
    removeProcessPermissions,
    addNewProcessPermission,
    updateProcessPermission,
    addLawFirm,
    addLawFirms,
    updateLawFirm,
    removeLawFirm,
    removeLawFirms,
    addLawFirmContact,
    addLawFirmContacts,
    updateLawFirmContact,
    removeLawFirmContact,
    removeLawFirmContacts,
    addLawFirmBranch,
    addLawFirmBranches,
    updateLawFirmBranch,
    removeLawFirmBranch,
    removeLawFirmBranches,
    addLawSchool,
    addLawSchools,
    updateLawSchool,
    removeLawSchool,
    removeLawSchools,
    addLawFirmProfessionalGuardian,
    addLawFirmProfessionalGuardians,
    removeLawFirmProfessionalGuardian,
    removeLawFirmProfessionalGuardians,
    updateLawFirmProfessionalGuardian,
    addBondNumbers,
    removeBondNumbers,
    addUserLawFirms,
} = companyManagementSlice.actions;

export default companyManagementSlice.reducer;
