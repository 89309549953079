import React, { useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addSuperPOUserBackend,
    updateSuperPOUserBackend
} from "../../services/company_management";
import {addNewSuperPOUser, updateSuperPOUser, removeSuperPOUser} from "../../store/company_management";
import Select from "react-select";

export default function SuperPOUserPopup(props) {
    const dispatch = useDispatch();
    const userInStore = useSelector((state) => state.companyManagement.users.filter((user)=>user.is_super_po === false));
    const allUserInStore = useSelector((state) => state.companyManagement.users);
    const superPOUsersInStore = useSelector((state) => state.companyManagement.super_po_users);
    let selectedSuperPoUser = {};
    let selectedUser = {};
    if(props.superpouser_id!==0){
        selectedSuperPoUser = superPOUsersInStore.filter((superPOUser)=>superPOUser.id === props.superpouser_id)[0];
    }
    if (selectedSuperPoUser){
        selectedUser = allUserInStore.filter((user)=>user.id === selectedSuperPoUser.id)[0];
    }
    const [loading, setLoading] = useState(false);
    const [isSuperPO, setIsSuperPO] = useState(Object.keys(selectedSuperPoUser).length>0 ?
        selectedSuperPoUser.is_super_po : false);
    const getUserOptions = () => {
        let userOpts = [];
        if (props.superpouser_id === 0){
            userInStore.map((user)=> userOpts.push({label: `${user.email}`, value: user.id}));
        }else{
            allUserInStore.map((user)=> userOpts.push({label: `${user.email}`, value: user.id}))
        }

        return userOpts;
    }

    const getValidations = () => {
        if(props.superpouser_id !== 0){
            return Yup.object({id: Yup.string().required('User is required.'),
                is_super_po: Yup.boolean().oneOf([true, false], 'Super PO status is required.'),})
        }else{
            return Yup.object({id: Yup.string().required('User is required.'),
                is_super_po: Yup.boolean().oneOf([true], 'Super PO status is required.'),})
        }
    }

    const formIk = useFormik({
        initialValues: {id: Object.keys(selectedSuperPoUser).length>0 ? selectedSuperPoUser.id : '',
            is_super_po: Object.keys(selectedSuperPoUser).length>0 ? selectedSuperPoUser.is_super_po : false,
        },
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            if (props.superpouser_id !== 0){
                await updateSuperPOUserBackend(props.superpouser_id, values).then((res)=>{
                    if(res === 200){
                        props.showSuccessAlert(true);
                        props.setSuccessMessage('Super PO user updated successfully');
                        setLoading(false);
                        props.closePopup(0);
                        if (!values.is_super_po){
                            dispatch(removeSuperPOUser({...values}))
                        }else{
                            dispatch(updateSuperPOUser({...values}))
                        }
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }

                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(0);
                })
            }else{
                await addSuperPOUserBackend(values).then((res)=>{
                    props.showSuccessAlert(true);
                    props.setSuccessMessage('Super PO user added successfully');
                    dispatch(addNewSuperPOUser({...res}));
                    setLoading(false);
                    props.closePopup(false);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(false);
                })
            }
        },
        validationSchema: getValidations()
    });
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Super PO User Details.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key={'id'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}>User</Form.Label>
                            <Select
                                placeholder={"Select User"}
                                name="id"
                                onChange={(opt, e) => {
                                    formIk.setFieldValue('id', opt.value);
                                }}
                                isDisabled={props.superpouser_id!==0}
                                options={getUserOptions()}
                                defaultValue={(props.superpouser_id !== 0 && selectedUser) ?
                                    {
                                        label: `${selectedUser.email}`,
                                        value: selectedUser.id
                                    } : ''}
                            />
                            {formIk.errors['id'] && (
                                <div className="text-error">{formIk.errors['id']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col style={{paddingTop: "5%"}}>
                        <Form.Group key={'is_super_po'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}>Is Super PO User</Form.Label>
                            <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                   onChange={() => {
                                       setIsSuperPO(!isSuperPO);
                                       formIk.setFieldValue('is_super_po', !isSuperPO)
                                   }}
                                   name='is_super_po' checked={isSuperPO}/>
                            {formIk.errors['is_super_po'] && formIk.touched['is_super_po'] && (
                                <div className="text-error">{formIk.errors['is_super_po']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => formIk.submitForm()}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

SuperPOUserPopup.propTypes = {
    superpouser_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    showSuccessAlert: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    showFailureAlert: PropTypes.func.isRequired,
    setFailureMessage: PropTypes.func.isRequired,
}
