import { API_URL, notRequiredKeys } from "../../../../constants";
import React from "react";
import {Badge} from "react-bootstrap";
import {getAPICall, getAPICallv2} from './helper';

export async function getInvoicesPagination(permissionType, page, pageSize, filter, sort) {
    return await getAPICall(`/ap/invoices/?permission_type=${permissionType}&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function getInvoices(status, page, pageSize, filter, sort) {
    return await getAPICallv2(`/ap/invoice_list/?status=${status}&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function getInvoiceDetail(id) {
    return await getAPICallv2(`/ap/invoice_detail/${id}`);
}

export async function getPOHeader(po_number) {
    return await getAPICallv2(`/ap/get_po_header/${po_number}`);
}

export async function getFailedInvoices(page, pageSize, filter, sort) {
    return await getAPICallv2(`/ap/failed_invoice_list/?&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function getSuppliersBackend() {
    return await getAPICall(`/ap/get_suppliers/`);
}

export async function getDashboardInvoices(depID) {
    return await getAPICall('/ap/invoices/?dep_id=' + depID);
}


export async function getHistoryInvoices(page, limit, filter, sort_field, sort_sort_type) {
    let url = `/ap/posted/?page=${page}&limit=${limit}&filter=${filter}`;

    url = `${url}&order_by_col=${sort_field}&order_by_direction=${sort_sort_type}`
    return await getAPICall(url);
}

export async function getHistoryInvoicesPagination(page, pageSize, filter, sort) {
    let url = `/ap/posted/?&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`;
    return await getAPICall(url);
}

export async function getFailedInvoicesPagination(page, pageSize, filter, sort) {
    return await getAPICall(`/ap/failed/?&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function getPendingInvoicesPagination(page, pageSize, filter, sort) {
    return await getAPICall(`/ap/pending/?&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function getDepartmentApprovementLevel(depID) {
    return await getAPICall('/ap/approvement_levels/?department_id=' + depID);
}

export async function addInvoiceComment(invoice_id, comment_text) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap/invoices/add_comment/`, {
        method: "POST",
        body: JSON.stringify({invoice_id: invoice_id, comment_text: comment_text}),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        },
    })

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200) {
        return await response.json();
    }
}


export async function rejectInvoice(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap/invoices/`, {
        method: "PATCH",
        body: JSON.stringify([data]),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        },
    })

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200) {
        return true;
    }
}


export async function deleteInvoice(invoice_id) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap/invoices/${invoice_id}`, {
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        },
    })

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200) {
        return true;
    }
}


export async function postInvoices(data) {

    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap/process/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        },
    })
    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200) {
        return response.status;
    }
}

export async function postAllInvoices(permission_Type) {

    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap/invoices/post_all/?permission_type=${permission_Type}`, {
        method: "POST",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        },
    })
    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200) {
        return response.status;
    }
}

export async function updateInvoices(data) {

    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap/invoices/`, {
        method: "PUT",
        body: JSON.stringify([data]),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    })
    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }


    return response.status;

}

export async function postInvoice(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/ap/invoice_post/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        },
    })
    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;
}

function reformatDate(dateValue, locale) {

    if (!locale || locale === '') {
        locale = 'en-US';
    }

    var options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };

    return new Date(dateValue).toLocaleDateString(locale, options).replace(/\./g, '/');
}

export const titleCase = (str) => {
    let splitStr = str.toLowerCase().replace('_', ' ').split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export const getColumns = (data, isFSI, isActive) => {
    let userLocale = navigator.language || 'en-US';
    let dataCols = [];
    const keys = Object.keys(data[0]['invoice_details']);
    keys.forEach((k) => {
        if (!notRequiredKeys.includes(k)) {
            if (k === 'invoice_amount' || (k === 'total_tax' && (isFSI === null || isFSI === undefined))) {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        renderCell: (params) => {
                            const value = params.row[k];
                            let formattedValue;

                            if (value === 0) {
                                formattedValue = "-";
                            } else {
                                formattedValue = value.toLocaleString(userLocale, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                });
                            }

                            return value === 0 ? '-' : '$ ' + formattedValue;
                        }
                    }
                );
            } else if (isFSI) {
                if (isActive !== undefined && isActive) {
                    if (k !== 'total_tax' && k !== 'post_date') {
                        let titleValue = titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' '))
                        if (data[0]['invoice_details'][k]['label'] === 'Acc Number') {
                            titleValue = 'GL Account';
                        } else if (data[0]['invoice_details'][k]['label'] === 'Vendor Doc Num') {
                            titleValue = 'Invoice Number';
                        }

                        dataCols.push(
                            {
                                field: k,
                                headerName: titleValue,
                                minWidth: 150,
                                maxWidth: 1000,
                                flex: 1,
                                editable: false,
                                headerClassName: 'header-bold',
                                sortID: data[0]['invoice_details'][k].column_order + 1,
                                headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                                align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                            }
                        );
                    }
                } else {
                    if (k !== 'total_tax') {
                        let titleValue = titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' '))
                        if (data[0]['invoice_details'][k]['label'] === 'Acc Number') {
                            titleValue = 'GL Account';
                        } else if (data[0]['invoice_details'][k]['label'] === 'Vendor Doc Num') {
                            titleValue = 'Invoice Number';
                        }

                        dataCols.push(
                            {
                                field: k,
                                headerName: titleValue,
                                minWidth: 150,
                                maxWidth: 1000,
                                flex: 1,
                                editable: false,
                                headerClassName: 'header-bold',
                                sortID: data[0]['invoice_details'][k].column_order + 1,
                                headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                                align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                            }
                        );
                    }
                }

            } else {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                    }
                );
            }
        }
    });
    if (isFSI === null || isFSI === undefined) {
        if (dataCols.length > 0) {
            let client_col = dataCols.filter((col) => col.field === 'client_number');
            if (client_col.length > 0) {
                let client_col_and_onwards = dataCols.filter((col) => col.sortID >= client_col[0].sortID);
                dataCols.push(
                    {
                        field: 'department_name',
                        headerName: 'Department',
                        minWidth: 120,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: client_col[0].sortID,
                        headerAlign: 'right',
                        align: 'right',
                    }
                );
                client_col_and_onwards.map((col) => col.sortID += 1)
            }
        } else {
            dataCols.push(
                {
                    field: 'department_name',
                    headerName: 'Department',
                    minWidth: 120,
                    maxWidth: 1000,
                    flex: 1,
                    editable: false,
                    headerClassName: 'header-bold',
                    sortID: dataCols.length + 1,
                    headerAlign: 'right',
                    align: 'right',
                }
            );
        }
    }
    return dataCols;
}

export const getHistoryColumns = (data) => {
    let userLocale = navigator.language || 'en-US';
    let dataCols = [];
    dataCols.push(
        {
            field: 'status', headerName: 'Status', minWidth: 150, maxWidth: 1000, flex: 1, editable: false,
            headerClassName: 'header-bold', sortID: -1,
            headerAlign: 'center', align: 'center', renderCell: (params) => {
                return (
                    <h6>
                        <Badge pill bg="success">
                            {params.row.status}
                        </Badge>
                    </h6>
                );
            },
            filterable: false
        }
    );

    const keys = Object.keys(data[0]['invoice_details']);
    keys.forEach((k) => {
        if (!notRequiredKeys.includes(k)) {
            if (k === 'invoice_amount' || k === 'total_tax') {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        renderCell: (params) => {
                            const value = params.row[k];
                            let formattedValue;

                            if (value === 0) {
                                formattedValue = "-";
                            } else {
                                formattedValue = value.toLocaleString(userLocale, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                });
                            }

                            return value === 0 ? '-' : '$ ' + formattedValue;
                        }
                    }
                );
            } else {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                    }
                );
            }
        }
    });
    if (dataCols.length > 0) {
        let client_col = dataCols.filter((col) => col.field === 'client_number');
        if (client_col.length > 0) {
            let client_col_and_onwards = dataCols.filter((col) => col.sortID >= client_col[0].sortID);
            dataCols.push(
                {
                    field: 'department_name',
                    headerName: 'Department',
                    minWidth: 120,
                    maxWidth: 1000,
                    flex: 1,
                    editable: false,
                    headerClassName: 'header-bold',
                    sortID: client_col[0].sortID,
                    headerAlign: 'right',
                    align: 'right',
                    filterable: true
                }
            );
            client_col_and_onwards.map((col) => col.sortID += 1)
        }
    } else {
        dataCols.push(
            {
                field: 'department_name',
                headerName: 'Department',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                sortID: dataCols.length + 1,
                headerAlign: 'right',
                align: 'right',
                filterable: true
            }
        );
    }
    return dataCols;
}

export const getPostedColumns = (data) => {
    let userLocale = navigator.language || 'en-US';
    let dataCols = [];

    const keys = Object.keys(data[0]['invoice_details']);
    keys.forEach((k) => {
        if (!notRequiredKeys.includes(k)) {
            if (k === 'invoice_amount' || k === 'total_tax') {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        renderCell: (params) => {
                            const value = params.row[k];
                            let formattedValue;

                            if (value === 0) {
                                formattedValue = "-";
                            } else {
                                formattedValue = value.toLocaleString(userLocale, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                });
                            }

                            return value === 0 ? '-' : '$ ' + formattedValue;
                        }
                    }
                );
            } else {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                    }
                );
            }
        }
    });
    if (dataCols.length > 0) {
        let client_col = dataCols.filter((col) => col.field === 'client_number');
        if (client_col.length > 0) {
            let client_col_and_onwards = dataCols.filter((col) => col.sortID >= client_col[0].sortID);
            dataCols.push(
                {
                    field: 'department_name',
                    headerName: 'Department',
                    minWidth: 120,
                    maxWidth: 1000,
                    flex: 1,
                    editable: false,
                    headerClassName: 'header-bold',
                    sortID: client_col[0].sortID,
                    headerAlign: 'right',
                    align: 'right',
                    filterable: true
                }
            );
            client_col_and_onwards.map((col) => col.sortID += 1)
        }
    } else {
        dataCols.push(
            {
                field: 'department_name',
                headerName: 'Department',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                sortID: dataCols.length + 1,
                headerAlign: 'right',
                align: 'right',
                filterable: true
            }
        );
    }
    return dataCols;
}


export const getFailedColumns = (data) => {
    let userLocale = navigator.language || 'en-US';
    let dataCols = [];
    dataCols.push(
        {
            field: 'status', headerName: 'Status', minWidth: 200, maxWidth: 1000, flex: 1, editable: false,
            headerClassName: 'header-bold', sortID: -1,
            headerAlign: 'center', align: 'center',
            renderCell: (params) => {
                return (
                    <h6>
                        <Badge pill bg="danger">
                            {params.row.status}
                        </Badge>
                    </h6>
                );
            },
        }
    );
    const keys = Object.keys(data[0]['invoice_details']);
    keys.forEach((k) => {
        if (!notRequiredKeys.includes(k)) {
            if (k === 'invoice_amount' || k === 'total_tax') {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        renderCell: (params) => {
                            const value = params.row[k];
                            let formattedValue;

                            if (value === 0) {
                                formattedValue = "-";
                            } else {
                                formattedValue = value.toLocaleString(userLocale, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                });
                            }

                            return value === 0 ? '-' : '$ ' + formattedValue;
                        }
                    }
                );
            } else {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                    }
                );
            }
        }
    });
    if (dataCols.length > 0) {
        let client_col = dataCols.filter((col) => col.field === 'client_number');
        if (client_col.length > 0) {
            let client_col_and_onwards = dataCols.filter((col) => col.sortID >= client_col[0].sortID);
            dataCols.push(
                {
                    field: 'department_name',
                    headerName: 'Department',
                    minWidth: 120,
                    maxWidth: 1000,
                    flex: 1,
                    editable: false,
                    headerClassName: 'header-bold',
                    sortID: client_col[0].sortID,
                    headerAlign: 'right',
                    align: 'right',
                }
            );
            client_col_and_onwards.map((col) => col.sortID += 1)
        }
    } else {
        dataCols.push(
            {
                field: 'department_name',
                headerName: 'Department',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                sortID: dataCols.length + 1,
                headerAlign: 'right',
                align: 'right',
            }
        );
    }
    return dataCols;
}


export const getPendingColumns = (data) => {
    let userLocale = navigator.language || 'en-US';
    let dataCols = [];
    dataCols.push(
        {
            field: 'approvement_label',
            headerName: 'Approval Stage',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            sortID: -2,
            headerAlign: 'right',
            align: 'center',
            renderCell: (params) => {
                return (
                    <h6>
                        <Badge pill bg="warning">
                            {params.row.approvement_label}
                        </Badge>
                    </h6>
                );
            },
        }
    );
    const keys = Object.keys(data[0]['invoice_details']);
    keys.forEach((k) => {
        if (!notRequiredKeys.includes(k)) {
            if (k === 'invoice_amount' || k === 'total_tax') {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        renderCell: (params) => {
                            const value = params.row[k];
                            let formattedValue;

                            if (value === 0) {
                                formattedValue = "-";
                            } else {
                                formattedValue = value.toLocaleString(userLocale, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                });
                            }

                            return value === 0 ? '-' : '$ ' + formattedValue;
                        }
                    }
                );
            } else {
                dataCols.push(
                    {
                        field: k,
                        headerName: titleCase(data[0]['invoice_details'][k]['label'].replace('_', ' ')),
                        minWidth: 150,
                        maxWidth: 1000,
                        flex: 1,
                        editable: false,
                        headerClassName: 'header-bold',
                        sortID: data[0]['invoice_details'][k].column_order + 1,
                        headerAlign: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                        align: (k !== 'seller_name' && k !== 'vendor_id') ? 'right' : 'left',
                    }
                );
            }
        }
    });
    if (dataCols.length > 0) {
        let client_col = dataCols.filter((col) => col.field === 'client_number');
        if (client_col.length > 0) {
            let client_col_and_onwards = dataCols.filter((col) => col.sortID >= client_col[0].sortID);
            dataCols.push(
                {
                    field: 'department_name',
                    headerName: 'Department',
                    minWidth: 120,
                    maxWidth: 1000,
                    flex: 1,
                    editable: false,
                    headerClassName: 'header-bold',
                    sortID: client_col[0].sortID,
                    headerAlign: 'right',
                    align: 'right',
                }
            );
            client_col_and_onwards.map((col) => col.sortID += 1)
        }
    } else {
        dataCols.push(
            {
                field: 'department_name',
                headerName: 'Department',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                sortID: dataCols.length + 1,
                headerAlign: 'right',
                align: 'right',
            }
        );
    }
    return dataCols;
}


export const formatNewData = (data) => {
    let userLocale = navigator.language || 'en-US';
    const keys = Object.keys(data[0]['invoice_details']);
    let formated_data = [];
    data.forEach((row) => {
        let temp_data = {};
        keys.forEach((k) => {
            if (!notRequiredKeys.includes(k)) {
                if (k === 'client_number') {
                    const acc_number = row['invoice_details'][k].value;
                    if (acc_number !== '' && acc_number !== null && acc_number !== undefined) {
                        temp_data[k] = acc_number.slice(0, 4) + "-" + acc_number.slice(4, 6) + "-" + acc_number.slice(6, 9) +
                            "-" + acc_number.slice(9);
                    } else {
                        temp_data[k] = '';
                    }
                }
                if (row['invoice_details'][k]['is_date'] === true) {
                    var dateValue = row['invoice_details'][k].value;
                    if (dateValue !== '' && dateValue !== null) {
                        temp_data[k] = reformatDate(dateValue, userLocale);
                    } else {
                        temp_data[k] = '';
                    }
                } else {
                    if (row['invoice_details'][k]['is_amount'] === true) {
                        var formattedAmount = parseFloat(row['invoice_details'][k].value).toFixed(2);
                        temp_data[k] = parseFloat(formattedAmount)
                    } else if (row['invoice_details'][k].value !== '' && row['invoice_details'][k].value !== null) {
                        temp_data[k] = row['invoice_details'][k].value;
                    } else {
                        temp_data[k] = '';
                    }
                }
            } else if (k === 'id') {
                temp_data[k] = row[k];
            }
        });
        let date1 = new Date(Date.parse(row['created_at']));
        let date2 = new Date();
        let diffInMs = Math.abs(date2 - date1);

        temp_data['delayed'] = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        temp_data['department_name'] = row['department_name'];
        formated_data.push(temp_data);
    });
    return formated_data;
}


export const formatHistoryData = (data) => {
    let userLocale = navigator.language || 'en-US';
    const keys = Object.keys(data[0]['invoice_details']);
    let formated_data = [];
    data.forEach((row) => {
        let temp_data = {};
        keys.forEach((k) => {
            if (!notRequiredKeys.includes(k)) {
                if (k === 'client_number') {
                    const acc_number = row['invoice_details'][k].value;
                    if (acc_number !== '' && acc_number !== null) {
                        temp_data[k] = acc_number.slice(0, 4) + "-" + acc_number.slice(4, 6) + "-" + acc_number.slice(6, 9) +
                            "-" + acc_number.slice(9);
                    } else {
                        temp_data[k] = '';
                    }
                } else if (row['invoice_details'][k]['is_date'] === true) {
                    var dateValue = row['invoice_details'][k].value;
                    if (dateValue !== '' && dateValue !== null) {
                        temp_data[k] = reformatDate(dateValue, userLocale);
                    } else {
                        temp_data[k] = '';
                    }
                } else if (row['invoice_details'][k]['is_amount'] === true) {
                    var formattedAmount = parseFloat(row['invoice_details'][k].value).toFixed(2);
                    temp_data[k] = parseFloat(formattedAmount)
                } else {
                    temp_data[k] = row['invoice_details'][k].value;
                }
            } else if (k === 'id') {
                temp_data[k] = row[k];
            } else if (k === 'created_at') {
                temp_data[k] = Date.parse(row[k]);
            }
        });
        temp_data['status'] = row['status'].charAt(0).toUpperCase() + row['status'].slice(1);
        temp_data['department_name'] = row['department_name'];
        formated_data.push(temp_data);
    });
    return formated_data;
}


export const formatFailedData = (data) => {
    let userLocale = navigator.language || 'en-US';
    const keys = Object.keys(data[0]['invoice_details']);
    let formated_data = [];
    data.forEach((row) => {
        let temp_data = {};
        keys.forEach((k) => {
            if (!notRequiredKeys.includes(k)) {
                if (k === 'client_number') {
                    const acc_number = row['invoice_details'][k].value;
                    if (acc_number !== '' && acc_number !== null) {
                        temp_data[k] = acc_number.slice(0, 4) + "-" + acc_number.slice(4, 6) + "-" + acc_number.slice(6, 9) +
                            "-" + acc_number.slice(9);
                    } else {
                        temp_data[k] = '';
                    }
                } else if (row['invoice_details'][k]['is_date'] === true) {
                    var dateValue = row['invoice_details'][k].value;
                    if (dateValue !== '' && dateValue !== null) {
                        temp_data[k] = reformatDate(dateValue, userLocale);
                    } else {
                        temp_data[k] = '';
                    }
                } else if (row['invoice_details'][k]['is_amount'] === true) {
                    var formattedAmount = parseFloat(row['invoice_details'][k].value).toFixed(2);
                    temp_data[k] = parseFloat(formattedAmount)
                } else {
                    temp_data[k] = row['invoice_details'][k].value;
                }
            } else if (k === 'id') {
                temp_data[k] = row[k];
            }
        });
        temp_data['status'] = row['status'].charAt(0).toUpperCase() + row['status'].slice(1);
        temp_data['department_name'] = row['department_name'];
        formated_data.push(temp_data);
    });
    return formated_data;
}


export const formatPendingData = (data) => {
    let userLocale = navigator.language || 'en-US';
    const keys = Object.keys(data[0]['invoice_details']);
    let formated_data = [];
    data.forEach((row) => {
        let temp_data = {};
        keys.forEach((k) => {
            if (!notRequiredKeys.includes(k)) {
                if (k === 'client_number') {
                    const acc_number = row['invoice_details'][k].value;
                    if (acc_number !== '' && acc_number !== null) {
                        temp_data[k] = acc_number.slice(0, 4) + "-" + acc_number.slice(4, 6) + "-" + acc_number.slice(6, 9) +
                            "-" + acc_number.slice(9);
                    } else {
                        temp_data[k] = '';
                    }
                } else if (row['invoice_details'][k]['is_date'] === true) {
                    var dateValue = row['invoice_details'][k].value;
                    if (dateValue !== '' && dateValue !== null) {
                        temp_data[k] = reformatDate(dateValue, userLocale);
                    } else {
                        temp_data[k] = '';
                    }
                } else if (row['invoice_details'][k]['is_amount'] === true) {
                    var formattedAmount = parseFloat(row['invoice_details'][k].value).toFixed(2);
                    temp_data[k] = parseFloat(formattedAmount)
                } else {
                    temp_data[k] = row['invoice_details'][k].value;
                }
            } else if (k === 'id') {
                temp_data[k] = row[k];
            }
        });
        temp_data['status'] = row['status'].charAt(0).toUpperCase() + row['status'].slice(1);
        temp_data['approvement_label'] = row["approvement_label"];
        temp_data['department_name'] = row['department_name'];
        formated_data.push(temp_data);
    });
    return formated_data;
}


export function compare(a, b) {
    if (a.sortID < b.sortID) {
        return -1;
    }
    if (a.sortID > b.sortID) {
        return 1;
    }
    return 0;
}
