import {createSlice} from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const initialState = {
    user: {
        auth_token: '',
        user_id: 0,
        groups: [],
        first_name: '',
        last_name: '',
        buyer_id: '',
        primary_department: '',
        departments: {},
        current_selected_department: 0,
        current_selected_permission_ap: '',
        email: '',
        phone_number: '',
        department_approvement_level: {},
        po_approval_threshold: 0,
        extra_attrs: {},
        is_admin: false,
        is_new: false,
        is_super_admin: false,
        should_reset_password: false,
        user_role_name: '',
        user_role_permission: [],
        position:''
    },
    company: {
        address: '',
        id: 0,
        logo: '',
        name: '',
        sector: '',
        extra_attrs: {},


    },
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addToken(state, action) {
            state.user.auth_token = action.payload;
        },
        addUserInformation(state, action) {
            state.user.user_id = action.payload['user_id'];
            state.user.groups = (action.payload)['user_groups'];
            state.user.first_name = (action.payload)['first_name'];
            state.user.last_name = (action.payload)['last_name'];
            state.user.buyer_id = (action.payload)['buyer_id'];
            state.user.departments = (action.payload)['ap_permissions'];
            state.user.email = (action.payload)['email'];
            state.user.phone_number = (action.payload)['phone_number'];
            state.user.primary_department = (action.payload)['primary_department'];
            state.user.is_admin = (action.payload)['is_portal_admin'];
            state.user.is_new = (action.payload)['is_new'];
            state.user.po_approval_threshold = (action.payload)['po_approval_threshold'];
            if (typeof (action.payload)['extra_attrs'] === 'undefined' || (action.payload)['extra_attrs'] === null) {
                state.user.extra_attrs = {};
            } else {
                state.user.extra_attrs = (action.payload)['extra_attrs'];
            }
            state.user.should_reset_password = (action.payload)['should_reset_password'];
            state.user.is_super_admin = (action.payload)['is_super_admin'];
            state.user.user_role_name = (action.payload)['user_role_name'];
            state.user.user_role_permission = (action.payload)['user_role_permission'];
            state.user.position = (action.payload)['position'];
        },
        updateNames(state, action) {
            state.user.first_name = action.payload['first_name'];
            state.user.last_name = action.payload['last_name'];
        },
        removeToken(state, action) {
            state.user.auth_token = '';
        },
        removeIsNewUser(state, action) {
            state.user.is_new = false;
        },
        removeUserInformation(state, action) {
            state.user.user_id = 0;
            state.user.groups = [];
            state.user.first_name = '';
            state.user.last_name = '';
            state.user.buyer_id = '';
            state.user.primary_department = '';
            state.user.departments = {};
            state.user.current_selected_department = 0;
            state.user.current_selected_permission_ap = '';
            state.user.email = '';
            state.user.phone_number = '';
            state.user.department_approvement_level = {};
            state.user.is_admin = false;
            state.user.is_new = false;
            state.user.po_approval_threshold = 0;
            state.user.extra_attrs = {};
            state.user.should_reset_password = false;
            state.user.is_super_admin = false;
            state.user.user_role_name = '';
            state.user.user_role_permission = [];
            state.user.position = '';
        },
        addCompany(state, action) {
            state.company.address = action.payload.name;
            state.company.id = action.payload.id;
            state.company.logo = action.payload.logo;
            state.company.name = action.payload.name;
            state.company.sector = action.payload.sector;
            state.company.extra_attrs = action.payload.extra_attrs;
            if (typeof action.payload.extra_attrs === 'undefined' || action.payload.extra_attrs === null) {
                state.company.extra_attrs = {};
            } else {
                state.company.extra_attrs = action.payload.extra_attrs;
            }

        },
        updateResetPasswordStatus(state, action) {
            state.user.should_reset_password = false;
        },
        removeCompany(state, action) {
            state.company.address = '';
            state.company.id = 0;
            state.company.logo = '';
            state.company.name = '';
            state.company.sector = '';
            state.company.extra_attrs = {};
        },
        addDepartments(state, action) {
            state.user.departments = action.payload;
        },
        removeDepartments(state, action) {
            state.user.departments = {};
        },
        addSelectedDepartment(state, action) {
            state.user.current_selected_department = action.payload;
        },
        removeSelectedDepartment(state, action) {
            state.user.current_selected_department = 0;
        },
        addSelectedPermissionAP(state, action) {
            state.user.current_selected_permission_ap = action.payload;
        },
        removeSelectedPermissionAP(state, action) {
            state.user.current_selected_permission_ap = '';
        },
        addDepartmentApprovementLevel(state, action) {
            // if (action.payload.depId !== 0) {
            //     if (!state.user.department_approvement_level.hasOwnProperty(action.payload.depId)) {
            //         state.user.department_approvement_level[action.payload.depId] = {};
            //     }
            //     state.user.department_approvement_level[action.payload.depId] = action.payload.approvement_levels;
            // }
            state.user.department_approvement_level = action.payload.approvement_levels;
        },
        removeDepartmentApprovementLevel(state, action) {
            state.user.department_approvement_level = {};
        },
        updatePortalInsuranceVendorCreds(state, action) {
            if (typeof state.user.extra_attrs.portal_insurance_vendor_creds === 'undefined' || state.user.extra_attrs.portal_insurance_vendor_creds === null) {
                state.user.extra_attrs.portal_insurance_vendor_creds = {}
            }
            if (typeof state.user.extra_attrs.portal_insurance_vendor_creds[action.payload.vendor] === 'undefined' || state.user.extra_attrs.portal_insurance_vendor_creds[action.payload.vendor] === null) {
                state.user.extra_attrs.portal_insurance_vendor_creds[action.payload.vendor] = {}
            }
            state.user.extra_attrs.portal_insurance_vendor_creds[action.payload.vendor]['username'] = action.payload.username;
            state.user.extra_attrs.portal_insurance_vendor_creds[action.payload.vendor]['password'] = action.payload.password;
            state.user.extra_attrs.portal_insurance_vendor_creds[action.payload.vendor]['is_active'] = action.payload.is_active;

        }
    }
});

export const {
    addToken,
    removeToken,
    addUserInformation,
    removeUserInformation,
    addCompany,
    removeCompany,
    addSelectedDepartment,
    removeSelectedDepartment,
    removeDepartments,
    removeIsNewUser,
    addDepartmentApprovementLevel,
    removeDepartmentApprovementLevel,
    updateNames,
    addSelectedPermissionAP,
    removeSelectedPermissionAP,
    updatePortalInsuranceVendorCreds,
    updateResetPasswordStatus
} = authSlice.actions;

export default authSlice.reducer;
