import React from "react"
import ContentLoader from "react-content-loader"

const BlockLoading = (props) => (
    <ContentLoader
        speed={2}
        width={320}
        height={124}
        viewBox="0 0 320 124"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="8" rx="3" ry="3" width="100" height="20" />
        <rect x="20" y="40" rx="3" ry="3" width="400" height="10" />
        <rect x="20" y="60" rx="3" ry="3" width="400" height="10" />
        <rect x="20" y="80" rx="3" ry="3" width="400" height="10" />
        <rect x="0" y="100" rx="3" ry="3" width="100" height="50" />
    </ContentLoader>
)

export default BlockLoading;

