import React, { useState } from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import "../../index.css";
import {
    Card,
    CardContent,
    Container,
} from "@mui/material";
import { ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
import { Col, Form, Row } from "react-bootstrap";
import CustomTextInput from "../../global_components/inputs/text";

export default function AttorneyInformation({ formIK, step, progress, changeStep }) {

    // State variables for each part of the address
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [county, setCounty] = useState("");
    const [nearestLandmark, setNearestLandmark] = useState("");
    const [postalCode, setPostalCode] = useState("");

    // Update the concatenated address in formIK values
    const updateAddress = () => {
        const fullAddress = `
  ${address ? `Address: ${address}` : ''}
  ${city ? `, City: ${city}` : ''}
  ${state ? `, State: ${state}` : ''}
  ${county ? `, County: ${county}` : ''}
  ${nearestLandmark ? `, Nearest Landmark: ${nearestLandmark}` : ''}
  ${postalCode ? `, Postal Code: ${postalCode}` : ''}
`.trim().replace(/(^,)|(,$)/g, '');
        formIK.setFieldValue("guest_law_firm_address", fullAddress);
    };

    const validate = () => {
        updateAddress(); // Update the concatenated address before validation
        let errors = {};
        let error = false;

        // Validation for Law Firm
        if (!formIK.values.guest_law_firm_name) {
            errors.guest_law_firm_name = "Law Firm is required.";
            error = true;
        }

        // Validation for Law Firm Address
        if (!formIK.values.guest_law_firm_address) {
            errors.guest_law_firm_address = "Law Firm Address is required.";
            error = true;
        }

        // Validation for Law Firm Contact
        if (!formIK.values.guest_law_firm_contact) {
            errors.guest_law_firm_contact = "Law Firm Contact is required.";
            error = true;
        }

        if (error) {
            formIK.setErrors(errors);
            window.scrollTo(0, 0); // Ensure the user sees the errors
        } else {
            changeStep(progress + step);
        }
    };

    return (
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{ textAlign: "left", width: "50%", display: "inline-block" }}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{ display: "inline" }} /> Back
                                </button>
                            </div>
                            <div style={{ textAlign: "right", width: "50%", display: "inline-block" }}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                    disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{ display: "inline" }} />
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{ justifyContent: "center" }}>
                        <Card sx={{ maxWidth: "50%" }} style={{ marginBottom: "2%" }}>
                            <CardContent>
                                <h4>Attorney Information</h4>
                                <Row>
                                    <Col>

                                    <Form.Group className="col-12" controlId="guest_law_firm_contact">
                                            <Form.Label className='required-label'><strong>Law Firm Attorney</strong></Form.Label>
                                            <CustomTextInput name="guest_law_firm_contact"
                                                value={formIK.values.guest_law_firm_contact}
                                                onChange={(value) => formIK.setFieldValue("guest_law_firm_contact", value)}
                                                className="form-control" />
                                            <div className="text-error">{formIK.errors["guest_law_firm_contact"]}</div>
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="lawFirm">
                                            <Form.Label className='required-label'><strong>Law Firm</strong></Form.Label>
                                            <CustomTextInput name="guest_law_firm_name"
                                                value={formIK.values.guest_law_firm_name}
                                                onChange={(value) => formIK.setFieldValue("guest_law_firm_name", value)}
                                                className="form-control" />
                                            <div className="text-error">{formIK.errors["guest_law_firm_name"]}</div>
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="lawFirmAddress">
                                            <Form.Label className='required-label'><strong>Law Firm Address</strong></Form.Label>
                                            <CustomTextInput name="guest_law_firm_address"
                                                value={formIK.values.guest_law_firm_address}
                                                onChange={(value) => formIK.setFieldValue("guest_law_firm_address", value)}
                                                className="form-control" />
                                            <div className="text-error">{formIK.errors["guest_law_firm_address"]}</div>
                                        </Form.Group>

                                        

                                        <Form.Group className="col-12" controlId="city">
                                            <Form.Label><strong>City</strong></Form.Label>
                                            <CustomTextInput name="city"
                                                value={city}
                                                onChange={(value) => setCity(value)}
                                                className="form-control" />
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="state">
                                            <Form.Label><strong>State</strong></Form.Label>
                                            <CustomTextInput name="state"
                                                value={state}
                                                onChange={(value) => setState(value)}
                                                className="form-control" />
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="county">
                                            <Form.Label><strong>County</strong></Form.Label>
                                            <CustomTextInput name="county"
                                                value={county}
                                                onChange={(value) => setCounty(value)}
                                                className="form-control" />
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="nearestLandmark">
                                            <Form.Label><strong>Nearest Landmark</strong></Form.Label>
                                            <CustomTextInput name="nearestLandmark"
                                                value={nearestLandmark}
                                                onChange={(value) => setNearestLandmark(value)}
                                                className="form-control" />
                                        </Form.Group>

                                        <Form.Group className="col-12" controlId="postalCode">
                                            <Form.Label><strong>Postal Code</strong></Form.Label>
                                            <CustomTextInput name="postalCode"
                                                value={postalCode}
                                                onChange={(value) => setPostalCode(value)}
                                                className="form-control" />
                                        </Form.Group>

                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    );
}

AttorneyInformation.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
