import React, {useEffect, useState} from 'react';
import '../../styles/login.css';
import {useNavigate} from "react-router";
import PropTypes from "prop-types";
import {
    DataGrid
} from '@mui/x-data-grid';
import '../../App.css';
import {postInvoices, postAllInvoices, compare} from "../../services/accounts";
import {useSelector} from "react-redux";

function CusTable2(props) {
    const navigate = useNavigate();
    const currentPermission = useSelector((state) => state.auth.user.current_selected_permission_ap);
    const activeInvoicesInStore = useSelector((state) => {
        if (!state.invoices.invoices.hasOwnProperty(currentPermission)) {
            return [];
        }
        if (!state.invoices.invoices[currentPermission].hasOwnProperty('active')) {
            return [];
        }
        return state.invoices.invoices[currentPermission]['active'];
    });

    let cols = props.columns;
    cols.sort(compare);
    const [postInvoiceIDs, setPostInvoiceIDs] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
    }, [])

    const postInvs = async () => {
        setLoading(true);
        const invoicesToPost = activeInvoicesInStore.filter((invoice) => postInvoiceIDs.includes(invoice.id));
        let response_status = [];
        for (let i = 0; i < invoicesToPost.length; i += 20) {
            const chunk = invoicesToPost.slice(i, i + 20);
            await postInvoices(chunk).then((response) => {
                if (response === 200) {
                    props.reloadTable()

                    response_status.push(true);
                } else {
                    response_status.push(false);

                }
            }).catch((error) => {
                response_status.push(false);
                console.log(error);
            });
        }

        if (response_status.every(res => res === true)) {
            setLoading(false);
            props.success(true);
        } else {
            props.failure(true);
            setLoading(false);
            setPostInvoiceIDs([]);
        }
    }

    const postAllInvs = async () => {
        let response_status = [];
        setLoading(true);
        await postAllInvoices(currentPermission).then((response) => {
            if (response === 200) {
                props.reloadTable()
                response_status.push(true);
            } else {
                response_status.push(false);

            }
        }).catch((error) => {
            response_status.push(false);
            console.log(error);
        });


        if (response_status.every(res => res === true)) {
            setLoading(false);
            props.success(true);
        } else {
            props.failure(true);
            setLoading(false);
        }
    }

    return (
        <div style={{"height": "650px"}}>
            {

                currentPermission === "Post" && props.type === "active" ?
                    <DataGrid
                        checkboxSelection
                        columns={cols}
                        editCallback={props.editCallback}
                        rows={props.data}
                        rowCount={props.rowCount}
                        paginationMode="server"
                        paginationModel={props.paginationModel}
                        pageSizeOptions={[10, 25, 50]}
                        onPaginationModelChange={props.onPaginationModelChange}
                        filterMode="server"
                        onFilterModelChange={props.onFilterModelChange}
                        sortingMode="server"
                        onSortModelChange={props.onSortModelChange}
                        loading={props.loading}
                        onRowSelectionModelChange={(ids) => {
                            setPostInvoiceIDs(ids);
                        }}
                        getRowClassName={(params) => {
                            if (params.row.delayed < 3) {
                                return 'normal-row'
                            } else if (params.row.delayed >= 3 && params.row.delayed < 5) {
                                return 'warning-row'
                            } else if (params.row.delayed >= 5) {
                                return 'danger-row'
                            }
                        }}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                'margin-top': '1em',
                                'margin-bottom': '1em'
                            },
                            '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                'margin-top': '1em',
                                'margin-bottom': '1em'
                            },

                        }}
                        getCellClassName={(params) => {
                            console.log("params.field,",params)
                            if (params.field === 'department_name' || params.value === 'General Admin') {
                                return 'cold';
                            }
                            else{
                                return 'warning-cell';
                            }
                        }}
                        localeText={{
                            footerRowSelected: (count) => postInvoiceIDs.length === 1 ? `${postInvoiceIDs.length} invoice selected` : `${postInvoiceIDs.length} invoices selected`
                        }}

                    /> :
                    <DataGrid
                        rows={props.data}
                        rowCount={props.rowCount}
                        columns={cols}
                        paginationMode="server"
                        // pageSize={props.pageSize}
                        // pageSizeOptions={[props.pageSizeOptions]}
                        paginationModel={props.paginationModel}
                        pageSizeOptions={[10, 25, 50]}
                        onPaginationModelChange={props.onPaginationModelChange}
                        filterMode="server"
                        onFilterModelChange={props.onFilterModelChange}
                        sortingMode="server"
                        onSortModelChange={props.onSortModelChange}
                        loading={props.loading}
                        getRowClassName={(params) => {
                            if (params.row.delayed < 3) {
                                return 'normal-row'
                            } else if (params.row.delayed >= 3 && params.row.delayed < 5) {
                                return 'warning-row'
                            } else if (params.row.delayed >= 5) {
                                return 'danger-row'
                            }
                        }}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                'margin-top': '1em',
                                'margin-bottom': '1em'
                            },
                            '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                'margin-top': '1em',
                                'margin-bottom': '1em'
                            },
                            '& .yellow': {
                                backgroundColor: '#ffffc4',
                                color: '#1a3e72',
                            },

                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'department_name' && params.value === 'Fast Truck') {
                                return 'yellow';
                            }
                        }}
                        localeText={{
                            footerRowSelected: (count) => "1 invoice selected"
                        }}


                    />
            }
            {
                currentPermission === "Post"
                &&
                props.type === "active"
                &&
                <div style={{textAlign: "right", marginTop: "10px"}}>
                    <button
                        disabled={!postInvoiceIDs.length > 0 || loading}
                        onClick={postInvs}
                        className="btn btn-primary"
                        style={{width: "15%", marginRight: "10px"}}
                    >
                        Post Invoices
                    </button>
                    <button
                        disabled={loading}
                        onClick={postAllInvs}
                        className="btn btn-primary"
                        style={{width: "15%"}}
                    >
                        Post All
                    </button>
                </div>
            }

        </div>

    )
}

CusTable2.propTypes = {
    columns: PropTypes.array.isRequired,
    editCallback: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    success: PropTypes.func.isRequired,
    failure: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
};


export default CusTable2;
