import React from 'react';

export function hasUserPermission(list, functionKey) {
    let listPerm = list || [];
    const permission_list = listPerm.filter((perm) => perm.function_key === functionKey)
    let perm = false;
    if (permission_list?.length > 0 )
        perm = true;
    return perm;
}

