import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import StarIcon from '@mui/icons-material/Star';
import {makeStyles} from "@material-ui/core";
import {hasUserPermission} from "../../../utils/user_permission";
import {useSelector} from "react-redux";
import CustomDataGrid from "../../../global_components/datatables/custom_datagrid";
import PropTypes from "prop-types";
import UserInsertPopup from "../vermost_popups/add_edit_user_popup";
import {getUsers} from "../../../services/company_management";
import {log} from "../../../utils/logger";
import {useGridApiRef} from "@mui/x-data-grid";
import {useSnackbar} from "notistack";
import AddEditTeamMember from "../vermost_popups/add_edit_team_member_popup";


const useStyles = makeStyles(theme => ({
    customHoverFocus: {
        color: "#fff700",
        "&:hover, &.Mui-focusVisible": {backgroundColor: "transparent"},
    }
}));

function UserList(props) {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const [userList, setUserList] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');
    const hasAddPermission = hasUserPermission(user_permission, 'show_table_add_button')
    const transformData = (data) => {
        return data.map(item => {
            const isLawFirmAdmin = item.user_role === 'Admin';
            return {
                ...item,
                isLawFirmAdmin
            };
        });
    }
    
    useEffect(async () => {
        await getUsers(`/user/vermost_user_list?law_firm=${props.lawFirmID}`).then((response) => {
            log("vermost_user_list", response)
            setUserList(response);
        }).catch((error) => {
            console.log(error);
        });
    }, []);  

    useEffect(()=>{
        const transformedData = transformData(props.data);
        setRowData(transformedData);
    },[])

    const getUserPosition = (position) => {
        switch (position) {
            case '':
                return 'Web Administrators'
                break;
            case 'ATTORNEY':
                return 'Attorneys'
                break;
            case 'TEAM_MEMBER':
                return 'Team Members'
                break;
        }
    }

    const getColumns = () => {
        let cols = [{
            field: 'user_full_name',
            headerName: getUserPosition(props.title),
            flex: 1,
            editable: false,
            headerClassName: 'header-bold',
            renderCell: (params) => {
                return params.row.is_admin ? <div>
                    {params.row.user_full_name}<IconButton size="small"
                                                           className={classes.customHoverFocus}><StarIcon/></IconButton>
                </div> : <div>
                    {params.row.user_full_name}
                </div>
            }
        }, {
            field: 'is_admin'
        }]

        if (props.isWebAdministrator === undefined || !props.isWebAdministrator) {
            if (props.showLocation !== undefined && props.showLocation) {

                cols.push({
                    field: 'location_name',
                    headerName: 'Location',
                    flex: 1,
                    editable: false,
                    headerClassName: 'header-bold',
                    valueFormatter: ({value}) => {
                        if (!value) {
                            return value;
                        }
                        return props.locationList.filter((loc) => loc.id === value)[0]?.name
                    },

                });
            }
            cols.push({
                field: 'position',
                headerName: 'Position',
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
            });
            cols.push({
                field: 'isLawFirmAdmin',
                headerName: 'Law Firm Admin',
                type: 'boolean',
                flex: 1,
                headerClassName: 'header-bold'
            });
            
            if (props.showBoolean !== undefined && props.showBoolean) {
                cols.push({
                    field: 'is_active', headerName: 'Active', minWidth: 100,
                    maxWidth: 120, flex: 1, type: 'boolean', headerClassName: 'header-bold'
                });
                cols.push({
                    field: 'is_default', headerName: 'Default Attorney', minWidth: 100,
                    maxWidth: 120, flex: 1, type: 'boolean', headerClassName: 'header-bold'
                });
            }
        }

        cols.push({
            field: 'id',
            headerName: '',
            minWidth: 200,
            maxWidth: 1000,
            align: 'right',
            disableColumnMenu: true,
            flex: 1,
            sortable: false,
            editable: false,
            headerAlign: 'right',
            filterable: false,
            headerClassName: 'header-bold'
        });
        return cols;
    }
    const columns = getColumns();
    
    return (
        <div>
            <CustomDataGrid columns={columns}
                            rows={rowData}
                            isHeight={true}
                            autoHeight={true}
                            rowAutoHeight={() => "auto"}
                            estimatedRowHeight={() => 100}
                            hideFooter={true}
                            disableRowSelectionOnClick={true}
                            columnVisibilityModel={{
                                is_admin: false
                            }}
                            isEdit={props.isEdit}
                            visibleActionButtons={true}
                            isActionsVisible={true}
                            onCallback={props.onCallback}
                            rowCount={props.rowCount}
                            paginationModel={props.paginationModel}
                            onPaginationModelChange={props.onPaginationModelChange}
                            onFilterModelChange={props.onFilterModelChange}
                            onSortModelChange={props.onSortModelChange}
                            loading={props.loading}
                            addPermission={hasAddPermission && props.addPermission}
                            deletePermission={hasDeletePermission}
                            updatePermission={hasUpdatePermission}
            />
            {props.showUserInsertPopup &&
                (
                    <UserInsertPopup title={getUserPosition(props.title)} show={true} userID={props.userEditID}
                                     locationList={props.locationList} userData={userList} editID={props.editID}
                                     closePopup={props.setShowUserInsertPopup} callback={props.onActionCallback}
                                     isWebAdministrator={props.isWebAdministrator} showBoolean={props.showBoolean}
                                     showLocation={props.showLocation}/>
                )
            }

        </div>)
}

UserList.propTypes = {
    onCallback: PropTypes.func,
    showUserInsertPopup: PropTypes.bool,
    onActionCallback: PropTypes.func,
    userEditID: PropTypes.number
}
export default UserList;