import React from "react";
import PropTypes from "prop-types";
import {Modal, Button} from "react-bootstrap";
import '../../styles/login.css';

export default function DeleteCompanyManagementPopup(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                Do you really want to delete the {props.delete_text}.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => {
                    props.delete_callback(props.delete_id, false);
                    props.closePopup(0);
                }}>Confirm</Button>
                <Button onClick={(e) =>  props.closePopup(0)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}

DeleteCompanyManagementPopup.propTypes = {
    delete_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    delete_callback: PropTypes.func.isRequired,
    delete_text: PropTypes.string.isRequired,
}
