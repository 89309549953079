import { useState } from "react";
import AppContext from "./AppContext";

const AppState = (props)=>{

    const [invoices, setInvoices] = useState([]);
    const [processesCategoriesNames, setProcessesCategoriesNames] = useState([]);

    return (<AppContext.Provider value={{invoices, setInvoices, processesCategoriesNames, setProcessesCategoriesNames}}>
        {props.children}
    </AppContext.Provider>)

}

export  default AppState;
