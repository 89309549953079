import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "../../App.css";
const GoogleAutoComplete = (props) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["address"]
    };
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            let address = '';
            let zip_code = '';
            let state = '';
            let city = '';
            console.log(place)
            for (const component of place.address_components) {
                const componentType = component.types[0];

                switch (componentType) {
                    case "street_number": {
                        address = `${component.long_name} ${address}`;
                        break;
                    }

                    case "route": {
                        address += component.long_name;
                        break;
                    }

                    case "postal_code": {
                        zip_code = `${component.long_name}`;
                        break;
                    }
                    case "locality":
                        city = component.long_name;
                        break;
                    case "administrative_area_level_1": {
                        state = component.long_name;
                        break;
                    }
                }
            }
            for (const component of place.address_components) {
                const componentType = component.types[0];

                switch (componentType) {
                    case "subpremise": {
                        address += ` ${component.long_name}`;
                        break;
                    }
                }
            }
            props.callback(address, city, state, zip_code);
        });
    }, []);
    return (
        <input style={{border: "1px solid black"}} placeholder={props.placeholder} ref={inputRef} />
    );
};
export default GoogleAutoComplete;

GoogleAutoComplete.propTypes = {
    callback: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired
}

