import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Spinner, Row, Form, Col} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import CustomSelectInput from "../../../global_components/inputs/select-field";
import {getVermostLawFirmLocations} from "../../../services/company_management";
import * as yup from "yup";
import {log} from "../../../utils/logger";
import {Grid} from "@mui/material";
import {API_URL} from "../../../constants";
import {addLawSchool} from "../../../store/bond";

export default function SearchLawFirm(props) {
    const [loading, setLoading] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [loadingLocationList, setLoadingLocationList] = useState(false);
    const [loadingLocationCheck, setLoadingLocationCheck] = useState(false);
    const [selectedLawFirm, setSelectedLawFirm] = useState(0);
    const [createNewLawFirm, setCreateNewLawFirm] = useState(false);
    const lawFirmsInStore = useSelector((state) => state.companyManagement.user_law_firms);
    const super_user = useSelector((state) => state.auth.user.user_role_name) === "SuperAdmin";

    let law_firm_details = {};
    if (props.lawFirmEditID != '0') {
        law_firm_details = lawFirmsInStore.filter((law_firm) => law_firm.id === props.editID)[0];
    }
    const schema = yup.object().shape({
        law_firm: yup.number().required("Law Firm is required"),
        location: yup.number().min(1, "Law Firm Location is required").required("Law Firm Location is required")
    });
    const getInitialValues = () => {
        if (props.lawFirmEditID != '0') {
            return {
                law_firm: law_firm_details.law_firm,
                location: law_firm_details.location,
                is_active: law_firm_details.is_active,
                is_default: law_firm_details.is_default
            }
        } else {
            return {
                law_firm: '',
                location: '',
                is_active: true,
                is_default: false,
            }
        }
    }

    const getLawFirmOptions = () => {
        let options = [];
        props.lawFirmData?.map((lw) => options.push({label: `${lw.name}`, value: lw.id}))
        return options;
    }
    const loadLocationList = async (lawfirm_id) => {
        if (lawfirm_id != 0) {
            fetch(`${API_URL}/bond/list_law_firm_locations/${lawfirm_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async (res) => {
                const response = await res.json();
                let list = response.data
                setLocationList(list);
                setLoadingLocationList(true)
            }).catch((err) => {
                console.log(err);
                setLoadingLocationList(true)
            });
        }
    }
    const getLocationOptions = () => {
        let options = [];
        if (selectedLawFirm != 0 && locationList.length > 0) {
            locationList?.map((loc) => options.push({label: `${loc.name}`, value: loc.id}))
        }
        return options;
    }

    const onCreateLawFirmCallBack = (e, value) => {
        log('e,value: ', e, value);
    }

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            log('search-law-firm,', values);
            if (props.lawFirmEditID != 0) {
                props.callback('edit', values, props.editID);

            } else {
                props.callback('insert', values);
                props.closePopup(false);
            }
            setLoading(false);

        },
        validationSchema: schema
    });

    const selectLawFirmValue = () => {
        if (props.tempCreatedLawFirm !== undefined && props.tempCreatedLawFirm !== 0 && props.lawFirmData !== undefined && props.lawFirmData.length > 0) {
            return props.lawFirmData?.filter((lw) => lw.id === props.tempCreatedLawFirm)[0].id;
        } else if (selectedLawFirm !== 0) {
            return props.lawFirmData?.filter((lw) => lw.id === selectedLawFirm)[0].id;
        } else {
            return (props.lawFirmEditID !== undefined && props.lawFirmEditID != 0 && props.lawFirmData !== undefined && props.lawFirmData.length > 0 ? props.lawFirmData?.filter((lw) => lw.id === props.lawFirmEditID)[0].id : [])
        }
    }
    const selectLawFirmLocationValue = () => {
        return (props.lawFirmEditID != '0' || (props.tempCreatedLawFirm !== undefined && props.tempCreatedLawFirm !== 0)) || selectedLawFirm != 0 && law_firm_details !== undefined && locationList !== undefined && locationList.length > 0 ? locationList?.filter((lw) => lw?.law_firm === selectedLawFirm).length === 1 ? locationList?.filter((lw) => lw?.law_firm === selectedLawFirm)[0]?.id :  [] :  [];
    }
    useEffect(async () => {
        if (props.lawFirmEditID != '0') {
            setSelectedLawFirm(props.lawFirmEditID)
            await loadLocationList(props.lawFirmEditID);
        } else {
            formIk.errors['law_firm'] = "Law Firm is required"
        }
    }, []);

    useEffect(async () => {
        if (props.tempCreatedLawFirm !== undefined && props.tempCreatedLawFirm !== 0) {
            await formIk.setFieldValue('law_firm', props.tempCreatedLawFirm)
            setSelectedLawFirm(props.tempCreatedLawFirm)
            await loadLocationList(props.tempCreatedLawFirm)
        }
    }, [props.tempCreatedLawFirm]);

    useEffect(async () => {
        if (props.lawFirmData !== undefined && props.lawFirmData.length === 1) {
            delete formIk.errors['law_firm'];
            await formIk.setFieldValue('law_firm', props.lawFirmData[0].id)
            setSelectedLawFirm(props.lawFirmData[0].id)
            await loadLocationList(props.lawFirmData[0].id)
        }
    }, [props.lawFirmData?.length === 1]);

    useEffect(async () => {
        if ((props.tempCreatedLawFirm !== undefined && props.tempCreatedLawFirm !== 0 && loadingLocationList)) {
            setLoadingLocationCheck(true);
            await setLocationValue("tempCreatedLawFirm");
        }
        if ((props.lawFirmData !== undefined && props.lawFirmData.length === 1 && loadingLocationList)) {
            setLoadingLocationCheck(true);
            await setLocationValue();
        }
        else if(getLocationOptions()?.length === 1 && loadingLocationList){
            setLoadingLocationCheck(true);
            await setLocationValue("locationOption");
        }
        setLoadingLocationCheck(true);
    }, [loadingLocationList]);

    const setLocationValue = async (type) => {
        if (loadingLocationCheck) {
            setLoadingLocationCheck(false);
            setLoadingLocationList(false);
        }

        if (type === "tempCreatedLawFirm") {
            let selected_loc = locationList?.filter((lw) => lw?.law_firm === props.tempCreatedLawFirm)[0]?.id;
            await formIk.setFieldValue('location', selected_loc);
            let data = {
                law_firm: formIk.values.law_firm,
                location: selected_loc
            }
            props.onCallBack(data);
        }
        else if (type === "locationOption"){
            let selected_loc = getLocationOptions()[0].value;
            await formIk.setFieldValue('location', selected_loc);
            let data = {
                law_firm: formIk.values.law_firm,
                location: selected_loc
            }
            props.onCallBack(data);
        }else {
            let selected_loc = locationList?.filter((lw) => lw?.law_firm === props.lawFirmData[0].id)[0]?.id;
            await formIk.setFieldValue('location', selected_loc);
            let data = {
                law_firm: formIk.values.law_firm,
                location: selected_loc
            }
            props.onCallBack(data);
        }
    }

    return (
        <Form onSubmit={formIk.handleSubmit}>
            <Row>
                <Col sm={6} className={"pe-2"}>
                    <div style={{verticalAlign: "middle", textAlign: "center"}}>
                        <label style={{textAlign: "center"}}><b>Select Law Firm</b></label>
                    </div>


                    <Form.Group key={'law_firm'} controlId="validationFormik01" className="mb-3">
                        <Form.Label className={'required-label'}><strong>Law Firm</strong></Form.Label>
                        <CustomSelectInput
                            disabled={false}
                            name="law_firm"
                            onChange={
                                async (value) => {
                                    delete formIk.errors['law_firm'];
                                    await formIk.setFieldValue('law_firm', value);
                                    setSelectedLawFirm(value);
                                    await loadLocationList(value);
                                }
                            }
                            options={getLawFirmOptions()}
                            value={selectLawFirmValue()}
                        />
                        {formIk.errors['law_firm'] && (
                            <div className="text-error">{formIk.errors['law_firm']}</div>
                        )}
                    </Form.Group>
                    {
                        selectedLawFirm !== 0 &&
                        <Form.Group key={'location'} controlId="validationFormik02" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Location</strong></Form.Label>
                            <CustomSelectInput
                                disabled={false}
                                name="location"
                                onChange={
                                    (value) => {
                                        formIk.setFieldValue('location', value)
                                        let data = {
                                            law_firm: formIk.values.law_firm,
                                            location: value
                                        }
                                        props.onCallBack(data);
                                    }
                                }
                                options={getLocationOptions()}
                                value={selectLawFirmLocationValue()}
                            />
                            {formIk.errors['location'] && (
                                <div className="text-error">{formIk.errors['location']}</div>
                            )}
                        </Form.Group>
                    }
                    {
                        props.lawFirmEditID != "0" && (
                            <>
                                <Form.Group key={'is_active'} controlId="validationFormik033" className="mb-3">
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="is_active"
                                        label="Is Active?"
                                        onChange={formIk.handleChange}
                                        checked={formIk.values.is_active === true}
                                    />
                                </Form.Group>
                                <Form.Group key={'is_default'} controlId="validationFormik034" className="mb-3">
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="is_default"
                                        label="Is Default?"
                                        onChange={formIk.handleChange}
                                        checked={formIk.values.is_default === true}
                                    />
                                </Form.Group>
                            </>
                        )
                    }
                    <div>
                        {
                            props.lawFirmEditID == 0 && (
                                <h5>User will be assigned to Law Firm:<br/>
                                    {props.lawFirmData?.filter((lw) => lw.id === formIk?.values?.law_firm)[0]?.name} - {locationList?.filter((lw) => lw?.id === formIk?.values?.location)[0]?.name}
                                </h5>)
                        }
                    </div>
                    <div>
                        {
                            !loading && props.lawFirmEditID == 0 && props.userEditPage && (
                                <Button variant="primary" className={"w-100"}
                                        onClick={() => {
                                            formIk.submitForm();
                                        }}>Complete Assignment</Button>
                            )
                        }
                        {
                            loading && (
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="lg"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </Button>
                            )
                        }
                    </div>
                </Col>
                {(props.routeFromComponent === undefined || props.routeFromComponent === null) && (props.type === 'register' || super_user) && (<>
                    <Col sm={6} className={"ps-2"}
                         style={{
                             verticalAlign: "middle",
                             textAlign: "center",
                             borderLeft: "2px solid black"
                         }}>
                        <Row>
                            <Col sm={1}></Col>
                            <Col sm={11}><label style={{textAlign: "center"}}><b>Create New Law Firm</b></label></Col>
                        </Row>
                        <Row style={{verticalAlign: "middle", paddingTop: "5%", textAlign: "center"}}>
                            <Col sm={1}>
                                <label><b>OR</b></label>
                            </Col>
                            <Col sm={11}>
                                <Form.Group key={'submit_button'} controlId="validationFormik16">
                                    <Button variant="primary" onClick={() => {
                                        delete formIk.errors['law_firm'];
                                        props.showCreateLawFirmPopup(true);
                                    }}>Create New Law Firm</Button>
                                </Form.Group>
                            </Col>
                        </Row>

                    </Col>
                </>)
                }
            </Row>
            {/*(props.userEditPage) &&
                <Grid item xs={12} container justifyContent="flex-start">
                    <Button type="submit" variant="primary" className={"ms-1"}>Submit </Button>
                </Grid>
            */}
            <br/>
        </Form>
    )
}

SearchLawFirm.propTypes = {
    lawFirmEditID: PropTypes.number,
    callback: PropTypes.func.isRequired
}
