import {makeAutoObservable, runInAction} from "mobx";
import {patchInvoice} from "../../shared/api";
import {getInvoiceDetail} from "../../../../../services/accounts";

class InvoiceStore {
    _invoice = null;
    _invoiceInputs = null;
    status = null;
    fieldsСhanged = false;
    fileldsSaved = false;

    constructor() {
        makeAutoObservable(this);
    }

    setInvoice(invoice) {
        this._invoice = invoice;
    }

    setInvoiceInputs(invoice) {
        this._invoiceInputs = {
            id: invoice.id,
            newInvoiceNumber: invoice.invoice_details.invoice_number,
            invoiceDate: invoice.invoice_details.invoice_date,
            invoiceAmount: invoice.invoice_details.invoice_amount,
            fileName: invoice.file_name,
            status: invoice.status,
            newPoNumber: invoice.invoice_details.po_number,
            invoiceItems: invoice.invoice_items,
            supplierName: invoice.invoice_details.supplier_name,
            supplierCode: invoice.invoice_details.supplier_code,
            supplierCodeVal: invoice.invoice_details.supplier_code_val,
            glAccount: invoice.invoice_details.client_number,
            fsi_po_number: invoice.invoice_details.fsi_po_data,
            sellerName: invoice.invoice_details.seller_name,
            vendorID: invoice.invoice_details.vendor_id,
        };
    }

    replaceCommaWithDot(inputString) {
        return inputString.replace(/\,/g, ".");
    }

    removeDollarAndReplaceCommas(inputString) {
        const processedString = inputString.replace(/\$/g, "").replace(/,/g, ".");
        return processedString;
    }

    async getInvoice(id) {
        runInAction(() => {
            this.status = "pending";
        });
        try {
            let response = await getInvoiceDetail(id);
            this.setInvoice(response);
            this.setInvoiceInputs(response);

            runInAction(() => {
                this.status = "fulfilled";
            });
        } catch (err) {
            runInAction(() => {
                this.status = "error";
            });
            console.log(err);
        }
    }

    patchInputsValues = async () => {
        try {
            const invoice = {
                newInvoiceNumber: this._invoiceInputs?.newInvoiceNumber,
                newPoNumber: this._invoiceInputs?.newPoNumber,
                invoiceDate: this._invoiceInputs?.invoiceDate,
                invoiceAmount: this._invoiceInputs?.invoiceAmount,
                fileName: this._invoiceInputs?.fileName,
                status: this._invoiceInputs?.status,
                invoiceItems: this._invoiceInputs?.invoiceItems,
                supplierCode: this._invoiceInputs?.supplierCode
            };

            if (
                this._invoiceInputs.newInvoiceNumber === this._invoice?.invoice_details.invoice_number
            ) {
                delete invoice.newInvoiceNumber;
            }
            if (this._invoiceInputs.newPoNumber === this._invoice?.invoice_details.po_number) {
                delete invoice.newPoNumber;
            }

            debugger;

            const response = await patchInvoice(this.invoice.id, invoice);

            this.setInvoice(response);
            this.setInvoiceInputs(response);

            runInAction(() => {
                this.fileldsSaved = true;
            });
        } catch (err) {
            console.log(err);
        }
    };

    onChangeFieldHeader(data) {
        console.log("onChangeFieldHeader", data)
        if (data.field === "invoiceAmount") {
            data.value = this.replaceCommaWithDot(data.value);
        }
        // else if (
        //     data.field === "supplierCode"
        // ) {
        //     data.value = {
        //         ...(data.value),
        //         glAccount: this._invoiceInputs?.glAccount,
        //     };
        //     // this._invoiceInputs.supplierCode = data.value.id;
        //     // this.fieldsСhanged = true;
        //     // this.fileldsSaved = false;
        //     // return;
        // }

        this._invoiceInputs[data.field] = data.value;
        this.fieldsСhanged = true;
        this.fileldsSaved = false;
    }

    onChangeInvoiceItem({field, value}, id) {
        const index = this._invoiceInputs?.invoiceItems.findIndex(
            item => item.id === id
        );

        if (index !== undefined) {
            if (field === "unitPrice" || field === "linePrice") {
                const newValue = this.removeDollarAndReplaceCommas(value);
                this._invoiceInputs.invoiceItems[index][field] = newValue
            } else {
                this._invoiceInputs.invoiceItems[index][field] = value
            }

            this.fieldsСhanged = true;
            this.fileldsSaved = false;
        }
    }

    get invoice() {
        return this._invoice;
    }

    getInvoiceInputsItemOnId(id) {
        return this._invoiceInputs?.invoiceItems.find(item => item.id === id);
    }

    get invoiceInputs() {
        return this._invoiceInputs;
    }
}

export const invoiceStore = new InvoiceStore();
