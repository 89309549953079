import React, { useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {addDepartmentBackend, updateDepartmentBackend} from "../../services/company_management";
import {addNewDepartment, updateDepartment, updateUserDepartmentName,
    updatePOAPDepartment} from "../../store/company_management";

export default function EditDepartmentPopup(props) {
    const dispatch = useDispatch();
    const department_details = useSelector((state)=> state.companyManagement.departments.filter((dep)=>
        dep.id === props.dep_id)[0]);
    const company = useSelector((state)=> state.auth.company.id)
    const [loading, setLoading] = useState(false);

    const formIk = useFormik({
        initialValues: {name: props.dep_id!==0 ? department_details.name: '', code:props.dep_id!==0 ? department_details.code : '',
            po_approval_threshold: props.dep_id!==0 ? department_details.po_approval_threshold : ''},
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            if (props.dep_id !== 0){
                await updateDepartmentBackend(props.dep_id, values).then((res)=>{
                    if(res === 200){
                        props.showSuccessAlert(true);
                        props.firstDepartmentLoading(true);
                        props.setSuccessMessage('Department updated successfully');
                        dispatch(updateUserDepartmentName({department:department_details.name, new_department: values['name']}));
                        dispatch(updatePOAPDepartment({department:department_details.name, new_department: values['name']}));
                        dispatch(updateDepartment({id: department_details.id, company: department_details.company,
                            name: values['name'], code: values['code'],
                            po_approval_threshold: values['po_approval_threshold']}));
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(0);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(0);
                })
            }else{
                const data = {...values, company_id: company}
                await addDepartmentBackend(data).then((res)=>{
                    props.showSuccessAlert(true);
                    props.setSuccessMessage('Department created successfully');
                    dispatch(addNewDepartment({id: res.id, company: res.company, name: values['name'],
                        code: values['code'],
                        po_approval_threshold: values['po_approval_threshold']}));
                    setLoading(false);
                    props.closePopup(false);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(false);
                })
            }
        },
        validationSchema:Yup.object({
            name: Yup.string().required('Department name is required.'),
            code: Yup.string().required('Department code is required.').matches('^[0-9]*$', 'Only interger value are allowed.'),
            po_approval_threshold: Yup.number().required('PO Amount threshold is required.'),
        })
    });
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Department Details.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key={'name'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}>Department Name</Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                          name='name' defaultValue={props.dep_id!==0 ? department_details.name : ''}/>
                            {formIk.errors['name'] && formIk.touched['name'] && (
                                <div className="text-error">{formIk.errors['name']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key={'code'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}>Department Code</Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                          name='code' defaultValue={props.dep_id!==0 ? department_details.code : ''}/>
                            {formIk.errors['code'] && formIk.touched['code'] && (
                                <div className="text-error">{formIk.errors['code']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key={'po_approval_threshold'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}>PO Amount Threshold</Form.Label>
                            <Form.Control type="number" size="sm" onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                          name='po_approval_threshold' defaultValue={props.dep_id!==0 ? department_details.po_approval_threshold : ''}/>
                            {formIk.errors['po_approval_threshold'] && formIk.touched['po_approval_threshold'] && (
                                <div className="text-error">{formIk.errors['po_approval_threshold']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => formIk.submitForm()}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

EditDepartmentPopup.propTypes = {
    dep_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    showSuccessAlert: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    showFailureAlert: PropTypes.func.isRequired,
    setFailureMessage: PropTypes.func.isRequired,
    firstDepartmentLoading: PropTypes.func,
}
