export const API_URL = `https://ase-bluocn-prtl-bcknd-dev.azurewebsites.net/api`;
// export const API_URL = `http://localhost:8000/api`;
// export const API_URL = `https://ase-bluocn-prtl-bcknd-test2.azurewebsites.net/api`;



export const email_url = "https://prod-03.eastus2.logic.azure.com:443/workflows/9fe933c8f0b74ee8b38b45f5718b4f9f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=sSGJ3yEDieQIKGidtjgP4cfx9rMX_2iVjMNOt4eMqXk";
export const notRequiredKeys = ['id', 'line_items', 'approvement_level', 'approvement_label', 'company', 'company_name',
    'created_at', 'created_by', 'download_link', 'invoice_details_id', 'modified_at', 'modified_by', 'posted_at', 'posted_by',
    'status', 'file_name', 'department_name'];
export const insurance_companies = ['LTN Insurance', 'Portal Insurance', 'Coastal Alabama Insurance','United Risk Insurance']

export const vendor_companies = [
    {'orion': 'Orion'},
    {'swyfft': 'Swyfft'},
    {'asi_progressive': 'ASI Progressive'},
    {'ssiu': 'SSIU'},
    {'sage_sure': 'Sage Sure'},
    {'openly': 'Openly'},
    {'agency_zoom': 'Agency Zoom'},
    {'branch': 'Branch'}
]

export const portal_insurance_state_options = [{label: 'Alabama', value: 'Alabama'}, {
    label: 'Florida',
    value: 'Florida'
},
    {label: 'Lousina', value: 'Lousina'}, {label: 'Tennesse', value: 'Tennesse'}, {
        label: 'Mississippi',
        value: 'Mississippi'
    }]

export const month_opts = [{label: 'January', value: "1"}, {label: 'February', value: "2"}, {
    label: 'March',
    value: "3"
},
    {label: 'April', value: "4"}, {label: 'May', value: "5"}, {label: 'June', value: "6"}, {label: 'July', value: "7"},
    {label: 'August', value: "8"}, {label: 'September', value: "9"}, {
        label: 'October',
        value: "10"
    }, {label: 'November', value: "11"},
    {label: 'December', value: "12"}]


export const portal_insurance_branch_data_mappings = {
    home: {
        constructionType: 'construction_type',
        exteriorWallType: 'exterior_walls',
        garageType: 'garage',
        ownershipStatus: 'occupancy_type',
        numFullBathrooms: 'number_of_full_bath',
        numHalfBathrooms: 'number_of_half_baths',
        numOccupants: 'number_of_residence',
        numStories: 'number_of_stories',
        purchaseDate: 'purchase_date',
        roofShape: 'roof_shape',
        roofType: 'roof_material',
        roofYear: 'year_roof_installed',
        sqFt: 'square_footage',
        typeOfHome: 'residence_type',
        yearBuilt: 'year_built',
    },
    homeCoverage: {
        coverageA: 'coverage_a_dwelling',
        coverageBPctOfA: 'coverage_b_other_structures',
        coverageCPctOfA: 'coverage_c_personal_property',
        coverageDPctOfA: 'coverage_d_loss_of_use',
        coverageWaterBackup: 'water_back_up_sump_discharge_or_overflow',
        deductibleAllOther: 'all_other_peril_deductible',
        deductibleWindHail: 'hurricane_wind_hail_tornado_deductible',
        windHailExclusion: 'exclude_wind',
    },
    includeUmbrella: 'insured_have_umbrella_policy',
    umbrellaCoverage: {
        presenceOfBusiness: 'onsite_business'
    }
}

export const portal_insurance_city_option = {
    'Alabama': [
        {
            "label": "Abbeville",
            "value": "Abbeville"
        },
        {
            "label": "Adamsville",
            "value": "Adamsville"
        },
        {
            "label": "Addison",
            "value": "Addison"
        },
        {
            "label": "Akron",
            "value": "Akron"
        },
        {
            "label": "Alabaster",
            "value": "Alabaster"
        },
        {
            "label": "Albertville",
            "value": "Albertville"
        },
        {
            "label": "Alexander City",
            "value": "Alexander City"
        },
        {
            "label": "Aliceville",
            "value": "Aliceville"
        },
        {
            "label": "Allgood",
            "value": "Allgood"
        },
        {
            "label": "Altoona",
            "value": "Altoona"
        },
        {
            "label": "Andalusia",
            "value": "Andalusia"
        },
        {
            "label": "Anderson",
            "value": "Anderson"
        },
        {
            "label": "Anniston",
            "value": "Anniston"
        },
        {
            "label": "Arab",
            "value": "Arab"
        },
        {
            "label": "Ardmore",
            "value": "Ardmore"
        },
        {
            "label": "Argo",
            "value": "Argo"
        },
        {
            "label": "Ariton",
            "value": "Ariton"
        },
        {
            "label": "Arley",
            "value": "Arley"
        },
        {
            "label": "Ashford",
            "value": "Ashford"
        },
        {
            "label": "Ashland",
            "value": "Ashland"
        },
        {
            "label": "Ashville",
            "value": "Ashville"
        },
        {
            "label": "Athens",
            "value": "Athens"
        },
        {
            "label": "Atmore",
            "value": "Atmore"
        },
        {
            "label": "Attalla",
            "value": "Attalla"
        },
        {
            "label": "Auburn",
            "value": "Auburn"
        },
        {
            "label": "Autaugaville",
            "value": "Autaugaville"
        },
        {
            "label": "Avon",
            "value": "Avon"
        },
        {
            "label": "Babbie",
            "value": "Babbie"
        },
        {
            "label": "Baileyton",
            "value": "Baileyton"
        },
        {
            "label": "Bakerhill",
            "value": "Bakerhill"
        },
        {
            "label": "Banks",
            "value": "Banks"
        },
        {
            "label": "Bay Minette",
            "value": "Bay Minette"
        },
        {
            "label": "Bayou La Batre",
            "value": "Bayou La Batre"
        },
        {
            "label": "Bear Creek",
            "value": "Bear Creek"
        },
        {
            "label": "Beatrice",
            "value": "Beatrice"
        },
        {
            "label": "Beaverton",
            "value": "Beaverton"
        },
        {
            "label": "Belk",
            "value": "Belk"
        },
        {
            "label": "Benton",
            "value": "Benton"
        },
        {
            "label": "Berlin",
            "value": "Berlin"
        },
        {
            "label": "Berry",
            "value": "Berry"
        },
        {
            "label": "Bessemer",
            "value": "Bessemer"
        },
        {
            "label": "Billingsley",
            "value": "Billingsley"
        },
        {
            "label": "Birmingham",
            "value": "Birmingham"
        },
        {
            "label": "Black",
            "value": "Black"
        },
        {
            "label": "Blountsville",
            "value": "Blountsville"
        },
        {
            "label": "Blue Springs",
            "value": "Blue Springs"
        },
        {
            "label": "Boaz",
            "value": "Boaz"
        },
        {
            "label": "Boligee",
            "value": "Boligee"
        },
        {
            "label": "Bon Air",
            "value": "Bon Air"
        },
        {
            "label": "Bon Secour",
            "value": "Bon Secour"
        },
        {
            "label": "Brantley",
            "value": "Brantley"
        },
        {
            "label": "Brent",
            "value": "Brent"
        },
        {
            "label": "Brewton",
            "value": "Brewton"
        },
        {
            "label": "Bridgeport",
            "value": "Bridgeport"
        },
        {
            "label": "Brighton",
            "value": "Brighton"
        },
        {
            "label": "Brilliant",
            "value": "Brilliant"
        },
        {
            "label": "Brookside",
            "value": "Brookside"
        },
        {
            "label": "Brookwood",
            "value": "Brookwood"
        },
        {
            "label": "Brundidge",
            "value": "Brundidge"
        },
        {
            "label": "Butler",
            "value": "Butler"
        },
        {
            "label": "Calera",
            "value": "Calera"
        },
        {
            "label": "Camden",
            "value": "Camden"
        },
        {
            "label": "Camp Hill",
            "value": "Camp Hill"
        },
        {
            "label": "Carbon Hill",
            "value": "Carbon Hill"
        },
        {
            "label": "Cardiff",
            "value": "Cardiff"
        },
        {
            "label": "Carolina",
            "value": "Carolina"
        },
        {
            "label": "Carrollton",
            "value": "Carrollton"
        },
        {
            "label": "Castleberry",
            "value": "Castleberry"
        },
        {
            "label": "Cedar Bluff",
            "value": "Cedar Bluff"
        },
        {
            "label": "Center Point",
            "value": "Center Point"
        },
        {
            "label": "Centre",
            "value": "Centre"
        },
        {
            "label": "Centreville",
            "value": "Centreville"
        },
        {
            "label": "Chatom",
            "value": "Chatom"
        },
        {
            "label": "Chelsea",
            "value": "Chelsea"
        },
        {
            "label": "Cherokee",
            "value": "Cherokee"
        },
        {
            "label": "Chickasaw",
            "value": "Chickasaw"
        },
        {
            "label": "Childersburg",
            "value": "Childersburg"
        },
        {
            "label": "Citronelle",
            "value": "Citronelle"
        },
        {
            "label": "Clanton",
            "value": "Clanton"
        },
        {
            "label": "Clay",
            "value": "Clay"
        },
        {
            "label": "Clayhatchee",
            "value": "Clayhatchee"
        },
        {
            "label": "Clayton",
            "value": "Clayton"
        },
        {
            "label": "Cleveland",
            "value": "Cleveland"
        },
        {
            "label": "Clio",
            "value": "Clio"
        },
        {
            "label": "Coaling",
            "value": "Coaling"
        },
        {
            "label": "Coffee Springs",
            "value": "Coffee Springs"
        },
        {
            "label": "Coffeeville",
            "value": "Coffeeville"
        },
        {
            "label": "Coker",
            "value": "Coker"
        },
        {
            "label": "Collinsville",
            "value": "Collinsville"
        },
        {
            "label": "Colony",
            "value": "Colony"
        },
        {
            "label": "Columbia",
            "value": "Columbia"
        },
        {
            "label": "Columbiana",
            "value": "Columbiana"
        },
        {
            "label": "Coosada",
            "value": "Coosada"
        },
        {
            "label": "Cordova",
            "value": "Cordova"
        },
        {
            "label": "Cottonwood",
            "value": "Cottonwood"
        },
        {
            "label": "County Line",
            "value": "County Line"
        },
        {
            "label": "Courtland",
            "value": "Courtland"
        },
        {
            "label": "Cowarts",
            "value": "Cowarts"
        },
        {
            "label": "Creola",
            "value": "Creola"
        },
        {
            "label": "Crossville",
            "value": "Crossville"
        },
        {
            "label": "Cuba",
            "value": "Cuba"
        },
        {
            "label": "Cullman",
            "value": "Cullman"
        },
        {
            "label": "Cusseta",
            "value": "Cusseta"
        },
        {
            "label": "Dadeville",
            "value": "Dadeville"
        },
        {
            "label": "Daleville",
            "value": "Daleville"
        },
        {
            "label": "Daphne",
            "value": "Daphne"
        },
        {
            "label": "Dauphin Island",
            "value": "Dauphin Island"
        },
        {
            "label": "Daviston",
            "value": "Daviston"
        },
        {
            "label": "Dayton",
            "value": "Dayton"
        },
        {
            "label": "Deatsville",
            "value": "Deatsville"
        },
        {
            "label": "Decatur",
            "value": "Decatur"
        },
        {
            "label": "Demopolis",
            "value": "Demopolis"
        },
        {
            "label": "Detroit",
            "value": "Detroit"
        },
        {
            "label": "Dodge City",
            "value": "Dodge City"
        },
        {
            "label": "Dora",
            "value": "Dora"
        },
        {
            "label": "Dothan",
            "value": "Dothan"
        },
        {
            "label": "Double Springs",
            "value": "Double Springs"
        },
        {
            "label": "Douglas",
            "value": "Douglas"
        },
        {
            "label": "Dozier",
            "value": "Dozier"
        },
        {
            "label": "Dutton",
            "value": "Dutton"
        },
        {
            "label": "East Brewton",
            "value": "East Brewton"
        },
        {
            "label": "Eclectic",
            "value": "Eclectic"
        },
        {
            "label": "Edwardsville",
            "value": "Edwardsville"
        },
        {
            "label": "Elba",
            "value": "Elba"
        },
        {
            "label": "Elberta",
            "value": "Elberta"
        },
        {
            "label": "Eldridge",
            "value": "Eldridge"
        },
        {
            "label": "Elkmont",
            "value": "Elkmont"
        },
        {
            "label": "Elmore",
            "value": "Elmore"
        },
        {
            "label": "Emelle",
            "value": "Emelle"
        },
        {
            "label": "Enterprise",
            "value": "Enterprise"
        },
        {
            "label": "Epes",
            "value": "Epes"
        },
        {
            "label": "Ethelsville",
            "value": "Ethelsville"
        },
        {
            "label": "Eufaula",
            "value": "Eufaula"
        },
        {
            "label": "Eutaw",
            "value": "Eutaw"
        },
        {
            "label": "Eva",
            "value": "Eva"
        },
        {
            "label": "Evergreen",
            "value": "Evergreen"
        },
        {
            "label": "Excel",
            "value": "Excel"
        },
        {
            "label": "Fairfield",
            "value": "Fairfield"
        },
        {
            "label": "Fairhope",
            "value": "Fairhope"
        },
        {
            "label": "Fairview",
            "value": "Fairview"
        },
        {
            "label": "Falkville",
            "value": "Falkville"
        },
        {
            "label": "Faunsdale",
            "value": "Faunsdale"
        },
        {
            "label": "Fayette",
            "value": "Fayette"
        },
        {
            "label": "Five Points",
            "value": "Five Points"
        },
        {
            "label": "Flomaton",
            "value": "Flomaton"
        },
        {
            "label": "Florala",
            "value": "Florala"
        },
        {
            "label": "Florence",
            "value": "Florence"
        },
        {
            "label": "Foley",
            "value": "Foley"
        },
        {
            "label": "Forkland",
            "value": "Forkland"
        },
        {
            "label": "Fort Deposit",
            "value": "Fort Deposit"
        },
        {
            "label": "Fort Payne",
            "value": "Fort Payne"
        },
        {
            "label": "Franklin",
            "value": "Franklin"
        },
        {
            "label": "Frisco City",
            "value": "Frisco City"
        },
        {
            "label": "Fruithurst",
            "value": "Fruithurst"
        },
        {
            "label": "Fulton",
            "value": "Fulton"
        },
        {
            "label": "Fultondale",
            "value": "Fultondale"
        },
        {
            "label": "Fyffe",
            "value": "Fyffe"
        },
        {
            "label": "Gadsden",
            "value": "Gadsden"
        },
        {
            "label": "Gainesville",
            "value": "Gainesville"
        },
        {
            "label": "Gantt",
            "value": "Gantt"
        },
        {
            "label": "Garden City",
            "value": "Garden City"
        },
        {
            "label": "Gardendale",
            "value": "Gardendale"
        },
        {
            "label": "Gaylesville",
            "value": "Gaylesville"
        },
        {
            "label": "Geiger",
            "value": "Geiger"
        },
        {
            "label": "Geneva",
            "value": "Geneva"
        },
        {
            "label": "Georgiana",
            "value": "Georgiana"
        },
        {
            "label": "Geraldine",
            "value": "Geraldine"
        },
        {
            "label": "Gilbertown",
            "value": "Gilbertown"
        },
        {
            "label": "Glen Allen",
            "value": "Glen Allen"
        },
        {
            "label": "Glencoe",
            "value": "Glencoe"
        },
        {
            "label": "Glenwood",
            "value": "Glenwood"
        },
        {
            "label": "Goldville",
            "value": "Goldville"
        },
        {
            "label": "Good Hope",
            "value": "Good Hope"
        },
        {
            "label": "Goodwater",
            "value": "Goodwater"
        },
        {
            "label": "Gordo",
            "value": "Gordo"
        },
        {
            "label": "Gordon",
            "value": "Gordon"
        },
        {
            "label": "Gordonville",
            "value": "Gordonville"
        },
        {
            "label": "Goshen",
            "value": "Goshen"
        },
        {
            "label": "Grant",
            "value": "Grant"
        },
        {
            "label": "Grand Bay",
            "value": "Grand Bay"
        },
        {
            "label": "Graysville",
            "value": "Graysville"
        },
        {
            "label": "Greensboro",
            "value": "Greensboro"
        },
        {
            "label": "Greenville",
            "value": "Greenville"
        },
        {
            "label": "Grimes",
            "value": "Grimes"
        },
        {
            "label": "Grove Hill",
            "value": "Grove Hill"
        },
        {
            "label": "Gu-Win",
            "value": "Gu-Win"
        },
        {
            "label": "Guin",
            "value": "Guin"
        },
        {
            "label": "Gulf Shores",
            "value": "Gulf Shores"
        },
        {
            "label": "Guntersville",
            "value": "Guntersville"
        },
        {
            "label": "Gurley",
            "value": "Gurley"
        },
        {
            "label": "Hackleburg",
            "value": "Hackleburg"
        },
        {
            "label": "Haleburg",
            "value": "Haleburg"
        },
        {
            "label": "Haleyville",
            "value": "Haleyville"
        },
        {
            "label": "Hamilton",
            "value": "Hamilton"
        },
        {
            "label": "Hammondville",
            "value": "Hammondville"
        },
        {
            "label": "Hanceville",
            "value": "Hanceville"
        },
        {
            "label": "Harpersville",
            "value": "Harpersville"
        },
        {
            "label": "Hartford",
            "value": "Hartford"
        },
        {
            "label": "Hartselle",
            "value": "Hartselle"
        },
        {
            "label": "Hayden",
            "value": "Hayden"
        },
        {
            "label": "Hayneville",
            "value": "Hayneville"
        },
        {
            "label": "Headland",
            "value": "Headland"
        },
        {
            "label": "Heath",
            "value": "Heath"
        },
        {
            "label": "Heflin",
            "value": "Heflin"
        },
        {
            "label": "Helena",
            "value": "Helena"
        },
        {
            "label": "Henagar",
            "value": "Henagar"
        },
        {
            "label": "Highland Lake",
            "value": "Highland Lake"
        },
        {
            "label": "Hillsboro",
            "value": "Hillsboro"
        },
        {
            "label": "Hobson City",
            "value": "Hobson City"
        },
        {
            "label": "Hodges",
            "value": "Hodges"
        },
        {
            "label": "Hokes Bluff",
            "value": "Hokes Bluff"
        },
        {
            "label": "Holly Pond",
            "value": "Holly Pond"
        },
        {
            "label": "Hollywood",
            "value": "Hollywood"
        },
        {
            "label": "Homewood",
            "value": "Homewood"
        },
        {
            "label": "Hoover",
            "value": "Hoover"
        },
        {
            "label": "Horn Hill",
            "value": "Horn Hill"
        },
        {
            "label": "Hueytown",
            "value": "Hueytown"
        },
        {
            "label": "Huntsville",
            "value": "Huntsville"
        },
        {
            "label": "Hurtsboro",
            "value": "Hurtsboro"
        },
        {
            "label": "Hytop",
            "value": "Hytop"
        },
        {
            "label": "Ider",
            "value": "Ider"
        },
        {
            "label": "Indian Springs Village",
            "value": "Indian Springs Village"
        },
        {
            "label": "Irondale",
            "value": "Irondale"
        },
        {
            "label": "Jackson",
            "value": "Jackson"
        },
        {
            "label": "Jackson's Gap",
            "value": "Jackson's Gap"
        },
        {
            "label": "Jacksonville",
            "value": "Jacksonville"
        },
        {
            "label": "Jasper",
            "value": "Jasper"
        },
        {
            "label": "Jemison",
            "value": "Jemison"
        },
        {
            "label": "Kansas",
            "value": "Kansas"
        },
        {
            "label": "Kellyton",
            "value": "Kellyton"
        },
        {
            "label": "Kennedy",
            "value": "Kennedy"
        },
        {
            "label": "Killen",
            "value": "Killen"
        },
        {
            "label": "Kimberly",
            "value": "Kimberly"
        },
        {
            "label": "Kinsey",
            "value": "Kinsey"
        },
        {
            "label": "Kinston",
            "value": "Kinston"
        },
        {
            "label": "LaFayette",
            "value": "LaFayette"
        },
        {
            "label": "Lake View",
            "value": "Lake View"
        },
        {
            "label": "Lakeview",
            "value": "Lakeview"
        },
        {
            "label": "Lanett",
            "value": "Lanett"
        },
        {
            "label": "Langston",
            "value": "Langston"
        },
        {
            "label": "Leeds",
            "value": "Leeds"
        },
        {
            "label": "Leesburg",
            "value": "Leesburg"
        },
        {
            "label": "Leighton",
            "value": "Leighton"
        },
        {
            "label": "Lester",
            "value": "Lester"
        },
        {
            "label": "Level Plains",
            "value": "Level Plains"
        },
        {
            "label": "Lexington",
            "value": "Lexington"
        },
        {
            "label": "Libertyville",
            "value": "Libertyville"
        },
        {
            "label": "Lincoln",
            "value": "Lincoln"
        },
        {
            "label": "Linden",
            "value": "Linden"
        },
        {
            "label": "Lineville",
            "value": "Lineville"
        },
        {
            "label": "Lipscomb",
            "value": "Lipscomb"
        },
        {
            "label": "Lisman",
            "value": "Lisman"
        },
        {
            "label": "Littleville",
            "value": "Littleville"
        },
        {
            "label": "Livingston",
            "value": "Livingston"
        },
        {
            "label": "Loachapoka",
            "value": "Loachapoka"
        },
        {
            "label": "Lockhart",
            "value": "Lockhart"
        },
        {
            "label": "Locust Fork",
            "value": "Locust Fork"
        },
        {
            "label": "Louisville",
            "value": "Louisville"
        },
        {
            "label": "Lowndesboro",
            "value": "Lowndesboro"
        },
        {
            "label": "Loxley",
            "value": "Loxley"
        },
        {
            "label": "Luverne",
            "value": "Luverne"
        },
        {
            "label": "Lynn",
            "value": "Lynn"
        },
        {
            "label": "Madison",
            "value": "Madison"
        },
        {
            "label": "Madrid",
            "value": "Madrid"
        },
        {
            "label": "Magnolia Springs",
            "value": "Magnolia Springs"
        },
        {
            "label": "Malvern",
            "value": "Malvern"
        },
        {
            "label": "Maplesville",
            "value": "Maplesville"
        },
        {
            "label": "Margaret",
            "value": "Margaret"
        },
        {
            "label": "Marion",
            "value": "Marion"
        },
        {
            "label": "Maytown",
            "value": "Maytown"
        },
        {
            "label": "McIntosh",
            "value": "McIntosh"
        },
        {
            "label": "McKenzie",
            "value": "McKenzie"
        },
        {
            "label": "McMullen",
            "value": "McMullen"
        },
        {
            "label": "Memphis",
            "value": "Memphis"
        },
        {
            "label": "Mentone",
            "value": "Mentone"
        },
        {
            "label": "Midfield",
            "value": "Midfield"
        },
        {
            "label": "Midland City",
            "value": "Midland City"
        },
        {
            "label": "Midway",
            "value": "Midway"
        },
        {
            "label": "Millbrook",
            "value": "Millbrook"
        },
        {
            "label": "Millport",
            "value": "Millport"
        },
        {
            "label": "Millry",
            "value": "Millry"
        },
        {
            "label": "Mobile",
            "value": "Mobile"
        },
        {
            "label": "Monroeville",
            "value": "Monroeville"
        },
        {
            "label": "Montevallo",
            "value": "Montevallo"
        },
        {
            "label": "Montgomery",
            "value": "Montgomery"
        },
        {
            "label": "Moody",
            "value": "Moody"
        },
        {
            "label": "Mooresville",
            "value": "Mooresville"
        },
        {
            "label": "Morris",
            "value": "Morris"
        },
        {
            "label": "Mosses",
            "value": "Mosses"
        },
        {
            "label": "Moulton",
            "value": "Moulton"
        },
        {
            "label": "Moundville",
            "value": "Moundville"
        },
        {
            "label": "Mount Vernon",
            "value": "Mount Vernon"
        },
        {
            "label": "Mountain Brook",
            "value": "Mountain Brook"
        },
        {
            "label": "Mulga",
            "value": "Mulga"
        },
        {
            "label": "Munford",
            "value": "Munford"
        },
        {
            "label": "Muscle Shoals",
            "value": "Muscle Shoals"
        },
        {
            "label": "Myrtlewood",
            "value": "Myrtlewood"
        },
        {
            "label": "Napier Field",
            "value": "Napier Field"
        },
        {
            "label": "Natural Bridge",
            "value": "Natural Bridge"
        },
        {
            "label": "Nauvoo",
            "value": "Nauvoo"
        },
        {
            "label": "Nectar",
            "value": "Nectar"
        },
        {
            "label": "Needham",
            "value": "Needham"
        },
        {
            "label": "New Brockton",
            "value": "New Brockton"
        },
        {
            "label": "New Hope",
            "value": "New Hope"
        },
        {
            "label": "New Site",
            "value": "New Site"
        },
        {
            "label": "Newbern",
            "value": "Newbern"
        },
        {
            "label": "Newton",
            "value": "Newton"
        },
        {
            "label": "Newville",
            "value": "Newville"
        },
        {
            "label": "North Courtland",
            "value": "North Courtland"
        },
        {
            "label": "North Johns",
            "value": "North Johns"
        },
        {
            "label": "Northport",
            "value": "Northport"
        },
        {
            "label": "Notasulga",
            "value": "Notasulga"
        },
        {
            "label": "Oak Grove",
            "value": "Oak Grove"
        },
        {
            "label": "Oak Hill",
            "value": "Oak Hill"
        },
        {
            "label": "Oakman",
            "value": "Oakman"
        },
        {
            "label": "Odenville",
            "value": "Odenville"
        },
        {
            "label": "Ohatchee",
            "value": "Ohatchee"
        },
        {
            "label": "Oneonta",
            "value": "Oneonta"
        },
        {
            "label": "Onycha",
            "value": "Onycha"
        },
        {
            "label": "Opelika",
            "value": "Opelika"
        },
        {
            "label": "Opp",
            "value": "Opp"
        },
        {
            "label": "Orange Beach",
            "value": "Orange Beach"
        },
        {
            "label": "Orrville",
            "value": "Orrville"
        },
        {
            "label": "Owens Cross Roads",
            "value": "Owens Cross Roads"
        },
        {
            "label": "Oxford",
            "value": "Oxford"
        },
        {
            "label": "Ozark",
            "value": "Ozark"
        },
        {
            "label": "Paint Rock",
            "value": "Paint Rock"
        },
        {
            "label": "Parrish",
            "value": "Parrish"
        },
        {
            "label": "Pelham",
            "value": "Pelham"
        },
        {
            "label": "Pell City",
            "value": "Pell City"
        },
        {
            "label": "Pennington",
            "value": "Pennington"
        },
        {
            "label": "Perdido Beach",
            "value": "Perdido Beach"
        },
        {
            "label": "Petrey",
            "value": "Petrey"
        },
        {
            "label": "Phenix City",
            "value": "Phenix City"
        },
        {
            "label": "Phil Campbell",
            "value": "Phil Campbell"
        },
        {
            "label": "Pickensville",
            "value": "Pickensville"
        },
        {
            "label": "Piedmont",
            "value": "Piedmont"
        },
        {
            "label": "Pike Road",
            "value": "Pike Road"
        },
        {
            "label": "Pinckard",
            "value": "Pinckard"
        },
        {
            "label": "Pine Apple",
            "value": "Pine Apple"
        },
        {
            "label": "Pine Hill",
            "value": "Pine Hill"
        },
        {
            "label": "Pine Ridge",
            "value": "Pine Ridge"
        },
        {
            "label": "Pinson",
            "value": "Pinson"
        },
        {
            "label": "Pisgah",
            "value": "Pisgah"
        },
        {
            "label": "Pleasant Grove",
            "value": "Pleasant Grove"
        },
        {
            "label": "Pleasant Groves",
            "value": "Pleasant Groves"
        },
        {
            "label": "Pollard",
            "value": "Pollard"
        },
        {
            "label": "Powell",
            "value": "Powell"
        },
        {
            "label": "Prattville",
            "value": "Prattville"
        },
        {
            "label": "Priceville",
            "value": "Priceville"
        },
        {
            "label": "Prichard",
            "value": "Prichard"
        },
        {
            "label": "Providence",
            "value": "Providence"
        },
        {
            "label": "Ragland",
            "value": "Ragland"
        },
        {
            "label": "Rainbow City",
            "value": "Rainbow City"
        },
        {
            "label": "Rainsville",
            "value": "Rainsville"
        },
        {
            "label": "Ranburne",
            "value": "Ranburne"
        },
        {
            "label": "Red Bay",
            "value": "Red Bay"
        },
        {
            "label": "Red Level",
            "value": "Red Level"
        },
        {
            "label": "Reece City",
            "value": "Reece City"
        },
        {
            "label": "Reform",
            "value": "Reform"
        },
        {
            "label": "Rehobeth",
            "value": "Rehobeth"
        },
        {
            "label": "Repton",
            "value": "Repton"
        },
        {
            "label": "Ridgeville",
            "value": "Ridgeville"
        },
        {
            "label": "River Falls",
            "value": "River Falls"
        },
        {
            "label": "Riverside",
            "value": "Riverside"
        },
        {
            "label": "Riverview",
            "value": "Riverview"
        },
        {
            "label": "Roanoke",
            "value": "Roanoke"
        },
        {
            "label": "Robertsdale",
            "value": "Robertsdale"
        },
        {
            "label": "Rockford",
            "value": "Rockford"
        },
        {
            "label": "Rogersville",
            "value": "Rogersville"
        },
        {
            "label": "Rosa",
            "value": "Rosa"
        },
        {
            "label": "Russellville",
            "value": "Russellville"
        },
        {
            "label": "Rutledge",
            "value": "Rutledge"
        },
        {
            "label": "Samson",
            "value": "Samson"
        },
        {
            "label": "Sand Rock",
            "value": "Sand Rock"
        },
        {
            "label": "Sanford",
            "value": "Sanford"
        },
        {
            "label": "Saraland",
            "value": "Saraland"
        },
        {
            "label": "Sardis City",
            "value": "Sardis City"
        },
        {
            "label": "Satsuma",
            "value": "Satsuma"
        },
        {
            "label": "Scottsboro",
            "value": "Scottsboro"
        },
        {
            "label": "Section",
            "value": "Section"
        },
        {
            "label": "Selma",
            "value": "Selma"
        },
        {
            "label": "Sheffield",
            "value": "Sheffield"
        },
        {
            "label": "Shiloh",
            "value": "Shiloh"
        },
        {
            "label": "Shorter",
            "value": "Shorter"
        },
        {
            "label": "Silas",
            "value": "Silas"
        },
        {
            "label": "Silverhill",
            "value": "Silverhill"
        },
        {
            "label": "Sipsey",
            "value": "Sipsey"
        },
        {
            "label": "Skyline",
            "value": "Skyline"
        },
        {
            "label": "Slocomb",
            "value": "Slocomb"
        },
        {
            "label": "Smiths Station",
            "value": "Smiths Station"
        },
        {
            "label": "Snead",
            "value": "Snead"
        },
        {
            "label": "Somerville",
            "value": "Somerville"
        },
        {
            "label": "South Vinemont",
            "value": "South Vinemont"
        },
        {
            "label": "Southside",
            "value": "Southside"
        },
        {
            "label": "Spanish Fort",
            "value": "Spanish Fort"
        },
        {
            "label": "Springville",
            "value": "Springville"
        },
        {
            "label": "St. Florian",
            "value": "St. Florian"
        },
        {
            "label": "Steele",
            "value": "Steele"
        },
        {
            "label": "Stevenson",
            "value": "Stevenson"
        },
        {
            "label": "Sulligent",
            "value": "Sulligent"
        },
        {
            "label": "Sumiton",
            "value": "Sumiton"
        },
        {
            "label": "Summerdale",
            "value": "Summerdale"
        },
        {
            "label": "Susan Moore",
            "value": "Susan Moore"
        },
        {
            "label": "Sweet Water",
            "value": "Sweet Water"
        },
        {
            "label": "Sylacauga",
            "value": "Sylacauga"
        },
        {
            "label": "Sylvan Springs",
            "value": "Sylvan Springs"
        },
        {
            "label": "Sylvania",
            "value": "Sylvania"
        },
        {
            "label": "Talladega Springs",
            "value": "Talladega Springs"
        },
        {
            "label": "Talladega",
            "value": "Talladega"
        },
        {
            "label": "Tallassee",
            "value": "Tallassee"
        },
        {
            "label": "Tarrant",
            "value": "Tarrant"
        },
        {
            "label": "Taylor",
            "value": "Taylor"
        },
        {
            "label": "Theodore",
            "value": "Theodore"
        },
        {
            "label": "Thomaston",
            "value": "Thomaston"
        },
        {
            "label": "Thomasville",
            "value": "Thomasville"
        },
        {
            "label": "Thorsby",
            "value": "Thorsby"
        },
        {
            "label": "Town Creek",
            "value": "Town Creek"
        },
        {
            "label": "Toxey",
            "value": "Toxey"
        },
        {
            "label": "Trafford",
            "value": "Trafford"
        },
        {
            "label": "Triana",
            "value": "Triana"
        },
        {
            "label": "Trinity",
            "value": "Trinity"
        },
        {
            "label": "Troy",
            "value": "Troy"
        },
        {
            "label": "Trussville",
            "value": "Trussville"
        },
        {
            "label": "Tuscaloosa",
            "value": "Tuscaloosa"
        },
        {
            "label": "Tuscumbia",
            "value": "Tuscumbia"
        },
        {
            "label": "Tuskegee",
            "value": "Tuskegee"
        },
        {
            "label": "Twin",
            "value": "Twin"
        },
        {
            "label": "Union",
            "value": "Union"
        },
        {
            "label": "Union Grove",
            "value": "Union Grove"
        },
        {
            "label": "Union Springs",
            "value": "Union Springs"
        },
        {
            "label": "Uniontown",
            "value": "Uniontown"
        },
        {
            "label": "Valley",
            "value": "Valley"
        },
        {
            "label": "Valley Grande",
            "value": "Valley Grande"
        },
        {
            "label": "Valley Head",
            "value": "Valley Head"
        },
        {
            "label": "Vance",
            "value": "Vance"
        },
        {
            "label": "Vernon",
            "value": "Vernon"
        },
        {
            "label": "Vestavia Hills",
            "value": "Vestavia Hills"
        },
        {
            "label": "Vina",
            "value": "Vina"
        },
        {
            "label": "Vincent",
            "value": "Vincent"
        },
        {
            "label": "Vredenburgh",
            "value": "Vredenburgh"
        },
        {
            "label": "Wadley",
            "value": "Wadley"
        },
        {
            "label": "Waldo",
            "value": "Waldo"
        },
        {
            "label": "Walnut Grove",
            "value": "Walnut Grove"
        },
        {
            "label": "Warrior",
            "value": "Warrior"
        },
        {
            "label": "Waterloo",
            "value": "Waterloo"
        },
        {
            "label": "Waverly",
            "value": "Waverly"
        },
        {
            "label": "Weaver",
            "value": "Weaver"
        },
        {
            "label": "Webb",
            "value": "Webb"
        },
        {
            "label": "Wedowee",
            "value": "Wedowee"
        },
        {
            "label": "West Blocton",
            "value": "West Blocton"
        },
        {
            "label": "West Jefferson",
            "value": "West Jefferson"
        },
        {
            "label": "West Point",
            "value": "West Point"
        },
        {
            "label": "Westover",
            "value": "Westover"
        },
        {
            "label": "Wetumpka",
            "value": "Wetumpka"
        },
        {
            "label": "White Hall",
            "value": "White Hall"
        },
        {
            "label": "Wilsonville",
            "value": "Wilsonville"
        },
        {
            "label": "Wilton",
            "value": "Wilton"
        },
        {
            "label": "Winfield",
            "value": "Winfield"
        },
        {
            "label": "Woodland",
            "value": "Woodland"
        },
        {
            "label": "Woodstock",
            "value": "Woodstock"
        },
        {
            "label": "Woodville",
            "value": "Woodville"
        },
        {
            "label": "Yellow Bluff",
            "value": "Yellow Bluff"
        },
        {
            "label": "York",
            "value": "York"
        }
    ], 'Florida': [
        {
            "label": "Alachua",
            "value": "Alachua"
        },
        {
            "label": "Altamonte Springs",
            "value": "Altamonte Springs"
        },
        {
            "label": "Anna Maria",
            "value": "Anna Maria"
        },
        {
            "label": "Apalachicola",
            "value": "Apalachicola"
        },
        {
            "label": "Apopka",
            "value": "Apopka"
        },
        {
            "label": "Arcadia",
            "value": "Arcadia"
        },
        {
            "label": "Archer",
            "value": "Archer"
        },
        {
            "label": "Atlantic Beach",
            "value": "Atlantic Beach"
        },
        {
            "label": "Atlantis",
            "value": "Atlantis"
        },
        {
            "label": "Auburndale",
            "value": "Auburndale"
        },
        {
            "label": "Aventura",
            "value": "Aventura"
        },
        {
            "label": "Avon Park",
            "value": "Avon Park"
        },
        {
            "label": "Bartow",
            "value": "Bartow"
        },
        {
            "label": "Bay Lake",
            "value": "Bay Lake"
        },
        {
            "label": "Belle Glade",
            "value": "Belle Glade"
        },
        {
            "label": "Belle Isle",
            "value": "Belle Isle"
        },
        {
            "label": "Belleair Beach",
            "value": "Belleair Beach"
        },
        {
            "label": "Belleair Bluffs",
            "value": "Belleair Bluffs"
        },
        {
            "label": "Belleview",
            "value": "Belleview"
        },
        {
            "label": "Blountstown",
            "value": "Blountstown"
        },
        {
            "label": "Boca Raton",
            "value": "Boca Raton"
        },
        {
            "label": "Bonifay",
            "value": "Bonifay"
        },
        {
            "label": "Bonita Springs",
            "value": "Bonita Springs"
        },
        {
            "label": "Bowling Green",
            "value": "Bowling Green"
        },
        {
            "label": "Boynton Beach",
            "value": "Boynton Beach"
        },
        {
            "label": "Bradenton",
            "value": "Bradenton"
        },
        {
            "label": "Bradenton Beach",
            "value": "Bradenton Beach"
        },
        {
            "label": "Bristol",
            "value": "Bristol"
        },
        {
            "label": "Brooksville",
            "value": "Brooksville"
        },
        {
            "label": "Bunnell",
            "value": "Bunnell"
        },
        {
            "label": "Bushnell",
            "value": "Bushnell"
        },
        {
            "label": "Callaway",
            "value": "Callaway"
        },
        {
            "label": "Cape Canaveral",
            "value": "Cape Canaveral"
        },
        {
            "label": "Cape Coral",
            "value": "Cape Coral"
        },
        {
            "label": "Carrabelle",
            "value": "Carrabelle"
        },
        {
            "label": "Casselberry",
            "value": "Casselberry"
        },
        {
            "label": "Cedar Key",
            "value": "Cedar Key"
        },
        {
            "label": "Center Hill",
            "value": "Center Hill"
        },
        {
            "label": "Chattahoochee",
            "value": "Chattahoochee"
        },
        {
            "label": "Chiefland",
            "value": "Chiefland"
        },
        {
            "label": "Chipley",
            "value": "Chipley"
        },
        {
            "label": "Clearwater",
            "value": "Clearwater"
        },
        {
            "label": "Clermont",
            "value": "Clermont"
        },
        {
            "label": "Clewiston",
            "value": "Clewiston"
        },
        {
            "label": "Cocoa",
            "value": "Cocoa"
        },
        {
            "label": "Cocoa Beach",
            "value": "Cocoa Beach"
        },
        {
            "label": "Coconut Creek",
            "value": "Coconut Creek"
        },
        {
            "label": "Coleman",
            "value": "Coleman"
        },
        {
            "label": "Cooper City",
            "value": "Cooper City"
        },
        {
            "label": "Coral Gables",
            "value": "Coral Gables"
        },
        {
            "label": "Coral Springs",
            "value": "Coral Springs"
        },
        {
            "label": "Cottondale",
            "value": "Cottondale"
        },
        {
            "label": "Crescent City",
            "value": "Crescent City"
        },
        {
            "label": "Crestview",
            "value": "Crestview"
        },
        {
            "label": "Crystal River",
            "value": "Crystal River"
        },
        {
            "label": "Dade City",
            "value": "Dade City"
        },
        {
            "label": "Dania Beach",
            "value": "Dania Beach"
        },
        {
            "label": "Davenport",
            "value": "Davenport"
        },
        {
            "label": "Daytona Beach",
            "value": "Daytona Beach"
        },
        {
            "label": "Daytona Beach Shores",
            "value": "Daytona Beach Shores"
        },
        {
            "label": "DeBary",
            "value": "DeBary"
        },
        {
            "label": "Deerfield Beach",
            "value": "Deerfield Beach"
        },
        {
            "label": "DeFuniak Springs",
            "value": "DeFuniak Springs"
        },
        {
            "label": "DeLand",
            "value": "DeLand"
        },
        {
            "label": "Delray Beach",
            "value": "Delray Beach"
        },
        {
            "label": "Deltona",
            "value": "Deltona"
        },
        {
            "label": "Destin",
            "value": "Destin"
        },
        {
            "label": "Doral",
            "value": "Doral"
        },
        {
            "label": "Dunedin",
            "value": "Dunedin"
        },
        {
            "label": "Dunnellon",
            "value": "Dunnellon"
        },
        {
            "label": "Eagle Lake",
            "value": "Eagle Lake"
        },
        {
            "label": "Edgewater",
            "value": "Edgewater"
        },
        {
            "label": "Edgewood",
            "value": "Edgewood"
        },
        {
            "label": "Esto",
            "value": "Esto"
        },
        {
            "label": "Eustis",
            "value": "Eustis"
        },
        {
            "label": "Everglades City",
            "value": "Everglades City"
        },
        {
            "label": "Fanning Springs",
            "value": "Fanning Springs"
        },
        {
            "label": "Fellsmere",
            "value": "Fellsmere"
        },
        {
            "label": "Fernandina Beach",
            "value": "Fernandina Beach"
        },
        {
            "label": "Flagler Beach",
            "value": "Flagler Beach"
        },
        {
            "label": "Florida City",
            "value": "Florida City"
        },
        {
            "label": "Fort Lauderdale",
            "value": "Fort Lauderdale"
        },
        {
            "label": "Fort Meade",
            "value": "Fort Meade"
        },
        {
            "label": "Fort Myers",
            "value": "Fort Myers"
        },
        {
            "label": "Fort Pierce",
            "value": "Fort Pierce"
        },
        {
            "label": "Fort Walton Beach",
            "value": "Fort Walton Beach"
        },
        {
            "label": "Freeport",
            "value": "Freeport"
        },
        {
            "label": "Frostproof",
            "value": "Frostproof"
        },
        {
            "label": "Fruitland Park",
            "value": "Fruitland Park"
        },
        {
            "label": "Gainesville",
            "value": "Gainesville"
        },
        {
            "label": "Graceville",
            "value": "Graceville"
        },
        {
            "label": "Green Cove Springs",
            "value": "Green Cove Springs"
        },
        {
            "label": "Greenacres",
            "value": "Greenacres"
        },
        {
            "label": "Gretna",
            "value": "Gretna"
        },
        {
            "label": "Groveland",
            "value": "Groveland"
        },
        {
            "label": "Gulf Breeze",
            "value": "Gulf Breeze"
        },
        {
            "label": "Gulfport",
            "value": "Gulfport"
        },
        {
            "label": "Haines City",
            "value": "Haines City"
        },
        {
            "label": "Hallandale Beach",
            "value": "Hallandale Beach"
        },
        {
            "label": "Hampton",
            "value": "Hampton"
        },
        {
            "label": "Hawthorne",
            "value": "Hawthorne"
        },
        {
            "label": "Hialeah",
            "value": "Hialeah"
        },
        {
            "label": "Hialeah Gardens",
            "value": "Hialeah Gardens"
        },
        {
            "label": "High Springs",
            "value": "High Springs"
        },
        {
            "label": "Holly Hill",
            "value": "Holly Hill"
        },
        {
            "label": "Hollywood",
            "value": "Hollywood"
        },
        {
            "label": "Holmes Beach",
            "value": "Holmes Beach"
        },
        {
            "label": "Homestead",
            "value": "Homestead"
        },
        {
            "label": "Indian Harbor Beach",
            "value": "Indian Harbor Beach"
        },
        {
            "label": "Indian Rocks Beach",
            "value": "Indian Rocks Beach"
        },
        {
            "label": "Inverness",
            "value": "Inverness"
        },
        {
            "label": "Islandia",
            "value": "Islandia"
        },
        {
            "label": "Jacksonville",
            "value": "Jacksonville"
        },
        {
            "label": "Jacksonville Beach",
            "value": "Jacksonville Beach"
        },
        {
            "label": "Jacob City",
            "value": "Jacob City"
        },
        {
            "label": "Jasper",
            "value": "Jasper"
        },
        {
            "label": "Key Colony Beach",
            "value": "Key Colony Beach"
        },
        {
            "label": "Key West",
            "value": "Key West"
        },
        {
            "label": "Keystone Heights",
            "value": "Keystone Heights"
        },
        {
            "label": "Kissimmee",
            "value": "Kissimmee"
        },
        {
            "label": "LaBelle",
            "value": "LaBelle"
        },
        {
            "label": "Lake Alfred",
            "value": "Lake Alfred"
        },
        {
            "label": "Lake Buena Vista",
            "value": "Lake Buena Vista"
        },
        {
            "label": "Lake Butler",
            "value": "Lake Butler"
        },
        {
            "label": "Lake City",
            "value": "Lake City"
        },
        {
            "label": "Lake Helen",
            "value": "Lake Helen"
        },
        {
            "label": "Lake Mary",
            "value": "Lake Mary"
        },
        {
            "label": "Lake Wales",
            "value": "Lake Wales"
        },
        {
            "label": "Lake Worth",
            "value": "Lake Worth"
        },
        {
            "label": "Lakeland",
            "value": "Lakeland"
        },
        {
            "label": "Largo",
            "value": "Largo"
        },
        {
            "label": "Lauderdale Lakes",
            "value": "Lauderdale Lakes"
        },
        {
            "label": "Lauderhill",
            "value": "Lauderhill"
        },
        {
            "label": "Laurel Hill",
            "value": "Laurel Hill"
        },
        {
            "label": "Lawtey",
            "value": "Lawtey"
        },
        {
            "label": "Layton",
            "value": "Layton"
        },
        {
            "label": "Leesburg",
            "value": "Leesburg"
        },
        {
            "label": "Lighthouse Point",
            "value": "Lighthouse Point"
        },
        {
            "label": "Live Oak",
            "value": "Live Oak"
        },
        {
            "label": "Longwood",
            "value": "Longwood"
        },
        {
            "label": "Lynn Haven",
            "value": "Lynn Haven"
        },
        {
            "label": "Macclenny",
            "value": "Macclenny"
        },
        {
            "label": "Madeira Beach",
            "value": "Madeira Beach"
        },
        {
            "label": "Madison",
            "value": "Madison"
        },
        {
            "label": "Maitland",
            "value": "Maitland"
        },
        {
            "label": "Marathon",
            "value": "Marathon"
        },
        {
            "label": "Marco Island",
            "value": "Marco Island"
        },
        {
            "label": "Margate",
            "value": "Margate"
        },
        {
            "label": "Marianna",
            "value": "Marianna"
        },
        {
            "label": "Mary Esther",
            "value": "Mary Esther"
        },
        {
            "label": "Mascotte",
            "value": "Mascotte"
        },
        {
            "label": "Melbourne",
            "value": "Melbourne"
        },
        {
            "label": "Mexico Beach",
            "value": "Mexico Beach"
        },
        {
            "label": "Miami",
            "value": "Miami"
        },
        {
            "label": "Miami Beach",
            "value": "Miami Beach"
        },
        {
            "label": "Miami Gardens",
            "value": "Miami Gardens"
        },
        {
            "label": "Miami Springs",
            "value": "Miami Springs"
        },
        {
            "label": "Midway",
            "value": "Midway"
        },
        {
            "label": "Milton",
            "value": "Milton"
        },
        {
            "label": "Minneola",
            "value": "Minneola"
        },
        {
            "label": "Miramar",
            "value": "Miramar"
        },
        {
            "label": "Monticello",
            "value": "Monticello"
        },
        {
            "label": "Moore Haven",
            "value": "Moore Haven"
        },
        {
            "label": "Mount Dora",
            "value": "Mount Dora"
        },
        {
            "label": "Mulberry",
            "value": "Mulberry"
        },
        {
            "label": "Naples",
            "value": "Naples"
        },
        {
            "label": "Neptune Beach",
            "value": "Neptune Beach"
        },
        {
            "label": "New Port Richey",
            "value": "New Port Richey"
        },
        {
            "label": "New Smyrna Beach",
            "value": "New Smyrna Beach"
        },
        {
            "label": "Newberry",
            "value": "Newberry"
        },
        {
            "label": "Niceville",
            "value": "Niceville"
        },
        {
            "label": "North Bay Village",
            "value": "North Bay Village"
        },
        {
            "label": "North Lauderdale",
            "value": "North Lauderdale"
        },
        {
            "label": "North Miami",
            "value": "North Miami"
        },
        {
            "label": "North Miami Beach",
            "value": "North Miami Beach"
        },
        {
            "label": "North Port",
            "value": "North Port"
        },
        {
            "label": "Oak Hill",
            "value": "Oak Hill"
        },
        {
            "label": "Oakland Park",
            "value": "Oakland Park"
        },
        {
            "label": "Ocala",
            "value": "Ocala"
        },
        {
            "label": "Ocoee",
            "value": "Ocoee"
        },
        {
            "label": "Okeechobee",
            "value": "Okeechobee"
        },
        {
            "label": "Oldsmar",
            "value": "Oldsmar"
        },
        {
            "label": "Opa-locka",
            "value": "Opa-locka"
        },
        {
            "label": "Orange City",
            "value": "Orange City"
        },
        {
            "label": "Orlando",
            "value": "Orlando"
        },
        {
            "label": "Ormond Beach",
            "value": "Ormond Beach"
        },
        {
            "label": "Oviedo",
            "value": "Oviedo"
        },
        {
            "label": "Pahokee",
            "value": "Pahokee"
        },
        {
            "label": "Palatka",
            "value": "Palatka"
        },
        {
            "label": "Palm Bay",
            "value": "Palm Bay"
        },
        {
            "label": "Palm Beach Gardens",
            "value": "Palm Beach Gardens"
        },
        {
            "label": "Palm Coast",
            "value": "Palm Coast"
        },
        {
            "label": "Palmetto",
            "value": "Palmetto"
        },
        {
            "label": "Panama City",
            "value": "Panama City"
        },
        {
            "label": "Panama City Beach",
            "value": "Panama City Beach"
        },
        {
            "label": "Parker",
            "value": "Parker"
        },
        {
            "label": "Parkland",
            "value": "Parkland"
        },
        {
            "label": "Paxton",
            "value": "Paxton"
        },
        {
            "label": "Pembroke Pines",
            "value": "Pembroke Pines"
        },
        {
            "label": "Pensacola",
            "value": "Pensacola"
        },
        {
            "label": "Perry",
            "value": "Perry"
        },
        {
            "label": "Pierson",
            "value": "Pierson"
        },
        {
            "label": "Pinellas Park",
            "value": "Pinellas Park"
        },
        {
            "label": "Plant City",
            "value": "Plant City"
        },
        {
            "label": "Plantation",
            "value": "Plantation"
        },
        {
            "label": "Polk City",
            "value": "Polk City"
        },
        {
            "label": "Pompano Beach",
            "value": "Pompano Beach"
        },
        {
            "label": "Port Orange",
            "value": "Port Orange"
        },
        {
            "label": "Port Richey",
            "value": "Port Richey"
        },
        {
            "label": "Port St. Joe",
            "value": "Port St. Joe"
        },
        {
            "label": "Port St. Lucie",
            "value": "Port St. Lucie"
        },
        {
            "label": "Punta Gorda",
            "value": "Punta Gorda"
        },
        {
            "label": "Quincy",
            "value": "Quincy"
        },
        {
            "label": "Reddick",
            "value": "Reddick"
        },
        {
            "label": "Redington Beach",
            "value": "Redington Beach"
        },
        {
            "label": "Redington Shores",
            "value": "Redington Shores"
        },
        {
            "label": "Riviera Beach",
            "value": "Riviera Beach"
        },
        {
            "label": "Rockledge",
            "value": "Rockledge"
        },
        {
            "label": "Safety Harbor",
            "value": "Safety Harbor"
        },
        {
            "label": "San Antonio",
            "value": "San Antonio"
        },
        {
            "label": "Sanford",
            "value": "Sanford"
        },
        {
            "label": "Sanibel",
            "value": "Sanibel"
        },
        {
            "label": "Sarasota",
            "value": "Sarasota"
        },
        {
            "label": "Satellite Beach",
            "value": "Satellite Beach"
        },
        {
            "label": "Sebastian",
            "value": "Sebastian"
        },
        {
            "label": "Sebring",
            "value": "Sebring"
        },
        {
            "label": "Seminole",
            "value": "Seminole"
        },
        {
            "label": "Shalimar",
            "value": "Shalimar"
        },
        {
            "label": "Sneads",
            "value": "Sneads"
        },
        {
            "label": "Sopchoppy",
            "value": "Sopchoppy"
        },
        {
            "label": "South Bay",
            "value": "South Bay"
        },
        {
            "label": "South Daytona",
            "value": "South Daytona"
        },
        {
            "label": "South Miami",
            "value": "South Miami"
        },
        {
            "label": "South Pasadena",
            "value": "South Pasadena"
        },
        {
            "label": "Springfield",
            "value": "Springfield"
        },
        {
            "label": "St. Augustine",
            "value": "St. Augustine"
        },
        {
            "label": "St. Augustine Beach",
            "value": "St. Augustine Beach"
        },
        {
            "label": "St. Cloud",
            "value": "St. Cloud"
        },
        {
            "label": "St. Leo",
            "value": "St. Leo"
        },
        {
            "label": "St. Marks",
            "value": "St. Marks"
        },
        {
            "label": "St. Pete Beach",
            "value": "St. Pete Beach"
        },
        {
            "label": "St. Petersburg",
            "value": "St. Petersburg"
        },
        {
            "label": "Starke",
            "value": "Starke"
        },
        {
            "label": "Stuart",
            "value": "Stuart"
        },
        {
            "label": "Sunny Isles Beach",
            "value": "Sunny Isles Beach"
        },
        {
            "label": "Sunrise",
            "value": "Sunrise"
        },
        {
            "label": "Sweetwater",
            "value": "Sweetwater"
        },
        {
            "label": "Tallahassee #",
            "value": "Tallahassee #"
        },
        {
            "label": "Tamarac",
            "value": "Tamarac"
        },
        {
            "label": "Tampa",
            "value": "Tampa"
        },
        {
            "label": "Tarpon Springs",
            "value": "Tarpon Springs"
        },
        {
            "label": "Tavares",
            "value": "Tavares"
        },
        {
            "label": "Temple Terrace",
            "value": "Temple Terrace"
        },
        {
            "label": "Titusville",
            "value": "Titusville"
        },
        {
            "label": "Treasure Island",
            "value": "Treasure Island"
        },
        {
            "label": "Umatilla",
            "value": "Umatilla"
        },
        {
            "label": "Valparaiso",
            "value": "Valparaiso"
        },
        {
            "label": "Venice",
            "value": "Venice"
        },
        {
            "label": "Vernon",
            "value": "Vernon"
        },
        {
            "label": "Vero Beach",
            "value": "Vero Beach"
        },
        {
            "label": "Waldo",
            "value": "Waldo"
        },
        {
            "label": "Wauchula",
            "value": "Wauchula"
        },
        {
            "label": "Webster",
            "value": "Webster"
        },
        {
            "label": "Weeki Wachee",
            "value": "Weeki Wachee"
        },
        {
            "label": "West Melbourne",
            "value": "West Melbourne"
        },
        {
            "label": "West Miami",
            "value": "West Miami"
        },
        {
            "label": "West Palm Beach",
            "value": "West Palm Beach"
        },
        {
            "label": "West Park",
            "value": "West Park"
        },
        {
            "label": "Weston",
            "value": "Weston"
        },
        {
            "label": "Westville",
            "value": "Westville"
        },
        {
            "label": "Wewahitchka",
            "value": "Wewahitchka"
        },
        {
            "label": "White Springs",
            "value": "White Springs"
        },
        {
            "label": "Wildwood",
            "value": "Wildwood"
        },
        {
            "label": "Williston",
            "value": "Williston"
        },
        {
            "label": "Wilton Manors",
            "value": "Wilton Manors"
        },
        {
            "label": "Windermere",
            "value": "Windermere"
        },
        {
            "label": "Winter Garden",
            "value": "Winter Garden"
        },
        {
            "label": "Winter Haven",
            "value": "Winter Haven"
        },
        {
            "label": "Winter Park",
            "value": "Winter Park"
        },
        {
            "label": "Winter Springs",
            "value": "Winter Springs"
        },
        {
            "label": "Yankeetown",
            "value": "Yankeetown"
        },
        {
            "label": "Zephyrhills",
            "value": "Zephyrhills"
        },
        {
            "label": "Zolfo Springs",
            "value": "Zolfo Springs"
        }
    ], 'Lousina': [
        {
            "label": "Abbeville",
            "value": "Abbeville"
        },
        {
            "label": "Alexandria",
            "value": "Alexandria"
        },
        {
            "label": "Baker",
            "value": "Baker"
        },
        {
            "label": "Bastrop",
            "value": "Bastrop"
        },
        {
            "label": "Baton Rouge",
            "value": "Baton Rouge"
        },
        {
            "label": "Bogalusa",
            "value": "Bogalusa"
        },
        {
            "label": "Bossier City",
            "value": "Bossier City"
        },
        {
            "label": "Breaux Bridge",
            "value": "Breaux Bridge"
        },
        {
            "label": "Broussard",
            "value": "Broussard"
        },
        {
            "label": "Bunkie",
            "value": "Bunkie"
        },
        {
            "label": "Carencro",
            "value": "Carencro"
        },
        {
            "label": "Central",
            "value": "Central"
        },
        {
            "label": "Covington",
            "value": "Covington"
        },
        {
            "label": "Crowley",
            "value": "Crowley"
        },
        {
            "label": "Denham Springs",
            "value": "Denham Springs"
        },
        {
            "label": "DeQuincy",
            "value": "DeQuincy"
        },
        {
            "label": "DeRidder",
            "value": "DeRidder"
        },
        {
            "label": "Donaldsonville",
            "value": "Donaldsonville"
        },
        {
            "label": "Eunice",
            "value": "Eunice"
        },
        {
            "label": "Fairview Alpha",
            "value": "Fairview Alpha"
        },
        {
            "label": "Franklin",
            "value": "Franklin"
        },
        {
            "label": "Gonzales",
            "value": "Gonzales"
        },
        {
            "label": "Grambling",
            "value": "Grambling"
        },
        {
            "label": "Gretna",
            "value": "Gretna"
        },
        {
            "label": "Hammond",
            "value": "Hammond"
        },
        {
            "label": "Harahan",
            "value": "Harahan"
        },
        {
            "label": "Houma",
            "value": "Houma"
        },
        {
            "label": "Jeanerette",
            "value": "Jeanerette"
        },
        {
            "label": "Jennings",
            "value": "Jennings"
        },
        {
            "label": "Kaplan",
            "value": "Kaplan"
        },
        {
            "label": "Kenner",
            "value": "Kenner"
        },
        {
            "label": "Kraft",
            "value": "Kraft"
        },
        {
            "label": "Lafayette",
            "value": "Lafayette"
        },
        {
            "label": "Lake Charles",
            "value": "Lake Charles"
        },
        {
            "label": "Leesville",
            "value": "Leesville"
        },
        {
            "label": "Mandeville",
            "value": "Mandeville"
        },
        {
            "label": "Mansfield",
            "value": "Mansfield"
        },
        {
            "label": "Marksville",
            "value": "Marksville"
        },
        {
            "label": "Minden",
            "value": "Minden"
        },
        {
            "label": "Monroe",
            "value": "Monroe"
        },
        {
            "label": "Morgan City",
            "value": "Morgan City"
        },
        {
            "label": "New Iberia",
            "value": "New Iberia"
        },
        {
            "label": "New Orleans",
            "value": "New Orleans"
        },
        {
            "label": "New Roads",
            "value": "New Roads"
        },
        {
            "label": "Oakdale",
            "value": "Oakdale"
        },
        {
            "label": "Opelousas",
            "value": "Opelousas"
        },
        {
            "label": "Patterson",
            "value": "Patterson"
        },
        {
            "label": "Pineville",
            "value": "Pineville"
        },
        {
            "label": "Plaquemine",
            "value": "Plaquemine"
        },
        {
            "label": "Ponchatoula",
            "value": "Ponchatoula"
        },
        {
            "label": "Port Allen",
            "value": "Port Allen"
        },
        {
            "label": "Rayne",
            "value": "Rayne"
        },
        {
            "label": "Ruston",
            "value": "Ruston"
        },
        {
            "label": "Scott",
            "value": "Scott"
        },
        {
            "label": "Shreveport",
            "value": "Shreveport"
        },
        {
            "label": "Slidell",
            "value": "Slidell"
        },
        {
            "label": "Springhill",
            "value": "Springhill"
        },
        {
            "label": "St. Gabriel",
            "value": "St. Gabriel"
        },
        {
            "label": "St. Martinville",
            "value": "St. Martinville"
        },
        {
            "label": "Sulphur",
            "value": "Sulphur"
        },
        {
            "label": "Tallulah",
            "value": "Tallulah"
        },
        {
            "label": "Thibodaux",
            "value": "Thibodaux"
        },
        {
            "label": "Vidalia",
            "value": "Vidalia"
        },
        {
            "label": "Ville Platte",
            "value": "Ville Platte"
        },
        {
            "label": "Welcome",
            "value": "Welcome"
        },
        {
            "label": "West Monroe",
            "value": "West Monroe"
        },
        {
            "label": "Westlake",
            "value": "Westlake"
        },
        {
            "label": "Westwego",
            "value": "Westwego"
        },
        {
            "label": "Winnfield",
            "value": "Winnfield"
        },
        {
            "label": "Winnsboro",
            "value": "Winnsboro"
        },
        {
            "label": "Youngsville",
            "value": "Youngsville"
        },
        {
            "label": "Zachary",
            "value": "Zachary"
        }
    ], 'Tennesse': [
        {
            "label": "Adams",
            "value": "Adams"
        },
        {
            "label": "Adamsville",
            "value": "Adamsville"
        },
        {
            "label": "Alamo",
            "value": "Alamo"
        },
        {
            "label": "Alcoa",
            "value": "Alcoa"
        },
        {
            "label": "Alexandria",
            "value": "Alexandria"
        },
        {
            "label": "Algood",
            "value": "Algood"
        },
        {
            "label": "Allardt",
            "value": "Allardt"
        },
        {
            "label": "Altamont",
            "value": "Altamont"
        },
        {
            "label": "Ardmore",
            "value": "Ardmore"
        },
        {
            "label": "Arlington",
            "value": "Arlington"
        },
        {
            "label": "Ashland City",
            "value": "Ashland City"
        },
        {
            "label": "Athens",
            "value": "Athens"
        },
        {
            "label": "Atoka",
            "value": "Atoka"
        },
        {
            "label": "Atwood",
            "value": "Atwood"
        },
        {
            "label": "Auburntown",
            "value": "Auburntown"
        },
        {
            "label": "Baileyton",
            "value": "Baileyton"
        },
        {
            "label": "Baneberry",
            "value": "Baneberry"
        },
        {
            "label": "Bartlett",
            "value": "Bartlett"
        },
        {
            "label": "Baxter",
            "value": "Baxter"
        },
        {
            "label": "Bean Station",
            "value": "Bean Station"
        },
        {
            "label": "Beersheba Springs",
            "value": "Beersheba Springs"
        },
        {
            "label": "Bell Buckle",
            "value": "Bell Buckle"
        },
        {
            "label": "Belle Meade",
            "value": "Belle Meade"
        },
        {
            "label": "Bells",
            "value": "Bells"
        },
        {
            "label": "Benton",
            "value": "Benton"
        },
        {
            "label": "Berry Hill",
            "value": "Berry Hill"
        },
        {
            "label": "Bethel Springs",
            "value": "Bethel Springs"
        },
        {
            "label": "Big Sandy",
            "value": "Big Sandy"
        },
        {
            "label": "Blaine",
            "value": "Blaine"
        },
        {
            "label": "Bluff City",
            "value": "Bluff City"
        },
        {
            "label": "Bolivar",
            "value": "Bolivar"
        },
        {
            "label": "Braden",
            "value": "Braden"
        },
        {
            "label": "Bradford",
            "value": "Bradford"
        },
        {
            "label": "Brentwood",
            "value": "Brentwood"
        },
        {
            "label": "Brighton",
            "value": "Brighton"
        },
        {
            "label": "Bristol",
            "value": "Bristol"
        },
        {
            "label": "Brownsville",
            "value": "Brownsville"
        },
        {
            "label": "Bruceton",
            "value": "Bruceton"
        },
        {
            "label": "Bulls Gap",
            "value": "Bulls Gap"
        },
        {
            "label": "Burlison",
            "value": "Burlison"
        },
        {
            "label": "Burns",
            "value": "Burns"
        },
        {
            "label": "Byrdstown",
            "value": "Byrdstown"
        },
        {
            "label": "Calhoun",
            "value": "Calhoun"
        },
        {
            "label": "Camden",
            "value": "Camden"
        },
        {
            "label": "Carthage",
            "value": "Carthage"
        },
        {
            "label": "Caryville",
            "value": "Caryville"
        },
        {
            "label": "Cedar Hill",
            "value": "Cedar Hill"
        },
        {
            "label": "Celina",
            "value": "Celina"
        },
        {
            "label": "Centertown",
            "value": "Centertown"
        },
        {
            "label": "Centerville",
            "value": "Centerville"
        },
        {
            "label": "Chapel Hill",
            "value": "Chapel Hill"
        },
        {
            "label": "Charleston",
            "value": "Charleston"
        },
        {
            "label": "Charlotte",
            "value": "Charlotte"
        },
        {
            "label": "Chattanooga",
            "value": "Chattanooga"
        },
        {
            "label": "Church Hill",
            "value": "Church Hill"
        },
        {
            "label": "Clarksburg",
            "value": "Clarksburg"
        },
        {
            "label": "Clarksville",
            "value": "Clarksville"
        },
        {
            "label": "Cleveland",
            "value": "Cleveland"
        },
        {
            "label": "Clifton",
            "value": "Clifton"
        },
        {
            "label": "Clinton",
            "value": "Clinton"
        },
        {
            "label": "Coalmont",
            "value": "Coalmont"
        },
        {
            "label": "Collegedale",
            "value": "Collegedale"
        },
        {
            "label": "Collierville",
            "value": "Collierville"
        },
        {
            "label": "Collinwood",
            "value": "Collinwood"
        },
        {
            "label": "Columbia",
            "value": "Columbia"
        },
        {
            "label": "Cookeville",
            "value": "Cookeville"
        },
        {
            "label": "Coopertown",
            "value": "Coopertown"
        },
        {
            "label": "Copperhill",
            "value": "Copperhill"
        },
        {
            "label": "Cornersville",
            "value": "Cornersville"
        },
        {
            "label": "Cottage Grove",
            "value": "Cottage Grove"
        },
        {
            "label": "Covington",
            "value": "Covington"
        },
        {
            "label": "Cowan",
            "value": "Cowan"
        },
        {
            "label": "Crab Orchard",
            "value": "Crab Orchard"
        },
        {
            "label": "Cross Plains",
            "value": "Cross Plains"
        },
        {
            "label": "Crossville",
            "value": "Crossville"
        },
        {
            "label": "Crump",
            "value": "Crump"
        },
        {
            "label": "Cumberland City",
            "value": "Cumberland City"
        },
        {
            "label": "Cumberland Gap",
            "value": "Cumberland Gap"
        },
        {
            "label": "Dandridge",
            "value": "Dandridge"
        },
        {
            "label": "Dayton",
            "value": "Dayton"
        },
        {
            "label": "Decatur",
            "value": "Decatur"
        },
        {
            "label": "Decaturville",
            "value": "Decaturville"
        },
        {
            "label": "Decherd",
            "value": "Decherd"
        },
        {
            "label": "Dickson",
            "value": "Dickson"
        },
        {
            "label": "Dover",
            "value": "Dover"
        },
        {
            "label": "Dowelltown",
            "value": "Dowelltown"
        },
        {
            "label": "Doyle",
            "value": "Doyle"
        },
        {
            "label": "Dresden",
            "value": "Dresden"
        },
        {
            "label": "Ducktown",
            "value": "Ducktown"
        },
        {
            "label": "Dunlap",
            "value": "Dunlap"
        },
        {
            "label": "Dyer",
            "value": "Dyer"
        },
        {
            "label": "Dyersburg",
            "value": "Dyersburg"
        },
        {
            "label": "Eagleville",
            "value": "Eagleville"
        },
        {
            "label": "East Ridge",
            "value": "East Ridge"
        },
        {
            "label": "Eastview",
            "value": "Eastview"
        },
        {
            "label": "Elizabethton",
            "value": "Elizabethton"
        },
        {
            "label": "Elkton",
            "value": "Elkton"
        },
        {
            "label": "Englewood",
            "value": "Englewood"
        },
        {
            "label": "Enville",
            "value": "Enville"
        },
        {
            "label": "Erin",
            "value": "Erin"
        },
        {
            "label": "Erwin",
            "value": "Erwin"
        },
        {
            "label": "Estill Springs",
            "value": "Estill Springs"
        },
        {
            "label": "Ethridge",
            "value": "Ethridge"
        },
        {
            "label": "Etowah",
            "value": "Etowah"
        },
        {
            "label": "Fairview",
            "value": "Fairview"
        },
        {
            "label": "Farragut",
            "value": "Farragut"
        },
        {
            "label": "Fayetteville",
            "value": "Fayetteville"
        },
        {
            "label": "Finger",
            "value": "Finger"
        },
        {
            "label": "Forest Hills",
            "value": "Forest Hills"
        },
        {
            "label": "Franklin",
            "value": "Franklin"
        },
        {
            "label": "Friendship",
            "value": "Friendship"
        },
        {
            "label": "Friendsville",
            "value": "Friendsville"
        },
        {
            "label": "Gadsden",
            "value": "Gadsden"
        },
        {
            "label": "Gainesboro",
            "value": "Gainesboro"
        },
        {
            "label": "Gallatin",
            "value": "Gallatin"
        },
        {
            "label": "Gallaway",
            "value": "Gallaway"
        },
        {
            "label": "Garland",
            "value": "Garland"
        },
        {
            "label": "Gates",
            "value": "Gates"
        },
        {
            "label": "Gatlinburg",
            "value": "Gatlinburg"
        },
        {
            "label": "Germantown",
            "value": "Germantown"
        },
        {
            "label": "Gibson",
            "value": "Gibson"
        },
        {
            "label": "Gilt Edge",
            "value": "Gilt Edge"
        },
        {
            "label": "Gleason",
            "value": "Gleason"
        },
        {
            "label": "Goodlettsville",
            "value": "Goodlettsville"
        },
        {
            "label": "Gordonsville",
            "value": "Gordonsville"
        },
        {
            "label": "Grand Junction",
            "value": "Grand Junction"
        },
        {
            "label": "Graysville",
            "value": "Graysville"
        },
        {
            "label": "Greenback",
            "value": "Greenback"
        },
        {
            "label": "Greenbrier",
            "value": "Greenbrier"
        },
        {
            "label": "Greeneville",
            "value": "Greeneville"
        },
        {
            "label": "Greenfield",
            "value": "Greenfield"
        },
        {
            "label": "Gruetli-Laager",
            "value": "Gruetli-Laager"
        },
        {
            "label": "Guys",
            "value": "Guys"
        },
        {
            "label": "Halls",
            "value": "Halls"
        },
        {
            "label": "Harriman",
            "value": "Harriman"
        },
        {
            "label": "Harrogate",
            "value": "Harrogate"
        },
        {
            "label": "Hartsville",
            "value": "Hartsville"
        },
        {
            "label": "Helenwood",
            "value": "Helenwood"
        },
        {
            "label": "Henderson",
            "value": "Henderson"
        },
        {
            "label": "Hendersonville",
            "value": "Hendersonville"
        },
        {
            "label": "Henning",
            "value": "Henning"
        },
        {
            "label": "Henry",
            "value": "Henry"
        },
        {
            "label": "Hickory Valley",
            "value": "Hickory Valley"
        },
        {
            "label": "Hickory Withe",
            "value": "Hickory Withe"
        },
        {
            "label": "Hohenwald",
            "value": "Hohenwald"
        },
        {
            "label": "Hollow Rock",
            "value": "Hollow Rock"
        },
        {
            "label": "Hornbeak",
            "value": "Hornbeak"
        },
        {
            "label": "Hornsby",
            "value": "Hornsby"
        },
        {
            "label": "Humboldt",
            "value": "Humboldt"
        },
        {
            "label": "Huntingdon",
            "value": "Huntingdon"
        },
        {
            "label": "Huntland",
            "value": "Huntland"
        },
        {
            "label": "Huntsville",
            "value": "Huntsville"
        },
        {
            "label": "Iron City",
            "value": "Iron City"
        },
        {
            "label": "Jacksboro",
            "value": "Jacksboro"
        },
        {
            "label": "Jackson",
            "value": "Jackson"
        },
        {
            "label": "Jamestown",
            "value": "Jamestown"
        },
        {
            "label": "Jasper",
            "value": "Jasper"
        },
        {
            "label": "Jefferson City",
            "value": "Jefferson City"
        },
        {
            "label": "Jellico",
            "value": "Jellico"
        },
        {
            "label": "Johnson City",
            "value": "Johnson City"
        },
        {
            "label": "Jonesborough",
            "value": "Jonesborough"
        },
        {
            "label": "Kenton",
            "value": "Kenton"
        },
        {
            "label": "Kimball",
            "value": "Kimball"
        },
        {
            "label": "Kingsport",
            "value": "Kingsport"
        },
        {
            "label": "Kingston",
            "value": "Kingston"
        },
        {
            "label": "Kingston Springs",
            "value": "Kingston Springs"
        },
        {
            "label": "Knoxville",
            "value": "Knoxville"
        },
        {
            "label": "Lafayette",
            "value": "Lafayette"
        },
        {
            "label": "La Follette",
            "value": "La Follette"
        },
        {
            "label": "LaGrange",
            "value": "LaGrange"
        },
        {
            "label": "Lake City",
            "value": "Lake City"
        },
        {
            "label": "Lakeland",
            "value": "Lakeland"
        },
        {
            "label": "Lakesite",
            "value": "Lakesite"
        },
        {
            "label": "Lakewood",
            "value": "Lakewood"
        },
        {
            "label": "La Vergne",
            "value": "La Vergne"
        },
        {
            "label": "Lawrenceburg",
            "value": "Lawrenceburg"
        },
        {
            "label": "Lebanon",
            "value": "Lebanon"
        },
        {
            "label": "Lenoir City",
            "value": "Lenoir City"
        },
        {
            "label": "Lewisburg",
            "value": "Lewisburg"
        },
        {
            "label": "Lexington",
            "value": "Lexington"
        },
        {
            "label": "Liberty",
            "value": "Liberty"
        },
        {
            "label": "Linden",
            "value": "Linden"
        },
        {
            "label": "Livingston",
            "value": "Livingston"
        },
        {
            "label": "Lobelville",
            "value": "Lobelville"
        },
        {
            "label": "Lookout Mountain",
            "value": "Lookout Mountain"
        },
        {
            "label": "Loretto",
            "value": "Loretto"
        },
        {
            "label": "Loudon",
            "value": "Loudon"
        },
        {
            "label": "Louisville",
            "value": "Louisville"
        },
        {
            "label": "Luttrell",
            "value": "Luttrell"
        },
        {
            "label": "Lynchburg",
            "value": "Lynchburg"
        },
        {
            "label": "Lynnville",
            "value": "Lynnville"
        },
        {
            "label": "Madisonville",
            "value": "Madisonville"
        },
        {
            "label": "Manchester",
            "value": "Manchester"
        },
        {
            "label": "Martin",
            "value": "Martin"
        },
        {
            "label": "Maryville",
            "value": "Maryville"
        },
        {
            "label": "Mason",
            "value": "Mason"
        },
        {
            "label": "Maury City",
            "value": "Maury City"
        },
        {
            "label": "Maynardville",
            "value": "Maynardville"
        },
        {
            "label": "McEwen",
            "value": "McEwen"
        },
        {
            "label": "McKenzie",
            "value": "McKenzie"
        },
        {
            "label": "McLemoresville",
            "value": "McLemoresville"
        },
        {
            "label": "McMinnville",
            "value": "McMinnville"
        },
        {
            "label": "Medina",
            "value": "Medina"
        },
        {
            "label": "Medon",
            "value": "Medon"
        },
        {
            "label": "Memphis",
            "value": "Memphis"
        },
        {
            "label": "Michie",
            "value": "Michie"
        },
        {
            "label": "Middleton",
            "value": "Middleton"
        },
        {
            "label": "Midtown",
            "value": "Midtown"
        },
        {
            "label": "Milan",
            "value": "Milan"
        },
        {
            "label": "Milledgeville",
            "value": "Milledgeville"
        },
        {
            "label": "Millersville",
            "value": "Millersville"
        },
        {
            "label": "Millington",
            "value": "Millington"
        },
        {
            "label": "Minor Hill",
            "value": "Minor Hill"
        },
        {
            "label": "Mitchellville",
            "value": "Mitchellville"
        },
        {
            "label": "Monteagle",
            "value": "Monteagle"
        },
        {
            "label": "Monterey",
            "value": "Monterey"
        },
        {
            "label": "Morrison",
            "value": "Morrison"
        },
        {
            "label": "Morristown",
            "value": "Morristown"
        },
        {
            "label": "Moscow",
            "value": "Moscow"
        },
        {
            "label": "Mosheim",
            "value": "Mosheim"
        },
        {
            "label": "Mount Carmel",
            "value": "Mount Carmel"
        },
        {
            "label": "Mount Juliet",
            "value": "Mount Juliet"
        },
        {
            "label": "Mount Pleasant",
            "value": "Mount Pleasant"
        },
        {
            "label": "Mountain City",
            "value": "Mountain City"
        },
        {
            "label": "Munford",
            "value": "Munford"
        },
        {
            "label": "Murfreesboro",
            "value": "Murfreesboro"
        },
        {
            "label": "Nashville",
            "value": "Nashville"
        },
        {
            "label": "New Hope",
            "value": "New Hope"
        },
        {
            "label": "New Johnsonville",
            "value": "New Johnsonville"
        },
        {
            "label": "New Market",
            "value": "New Market"
        },
        {
            "label": "New Tazewell",
            "value": "New Tazewell"
        },
        {
            "label": "Newbern",
            "value": "Newbern"
        },
        {
            "label": "Newport",
            "value": "Newport"
        },
        {
            "label": "Niota",
            "value": "Niota"
        },
        {
            "label": "Nolensville",
            "value": "Nolensville"
        },
        {
            "label": "Normandy",
            "value": "Normandy"
        },
        {
            "label": "Norris",
            "value": "Norris"
        },
        {
            "label": "Oak Hill",
            "value": "Oak Hill"
        },
        {
            "label": "Oak Ridge",
            "value": "Oak Ridge"
        },
        {
            "label": "Oakdale",
            "value": "Oakdale"
        },
        {
            "label": "Oakland",
            "value": "Oakland"
        },
        {
            "label": "Obion",
            "value": "Obion"
        },
        {
            "label": "Oliver Springs",
            "value": "Oliver Springs"
        },
        {
            "label": "Oneida",
            "value": "Oneida"
        },
        {
            "label": "Orlinda",
            "value": "Orlinda"
        },
        {
            "label": "Orme",
            "value": "Orme"
        },
        {
            "label": "Palmer",
            "value": "Palmer"
        },
        {
            "label": "Paris",
            "value": "Paris"
        },
        {
            "label": "Parker's Crossroads",
            "value": "Parker's Crossroads"
        },
        {
            "label": "Parrottsville",
            "value": "Parrottsville"
        },
        {
            "label": "Parsons",
            "value": "Parsons"
        },
        {
            "label": "Pegram",
            "value": "Pegram"
        },
        {
            "label": "Petersburg",
            "value": "Petersburg"
        },
        {
            "label": "Philadelphia",
            "value": "Philadelphia"
        },
        {
            "label": "Pigeon Forge",
            "value": "Pigeon Forge"
        },
        {
            "label": "Pikeville",
            "value": "Pikeville"
        },
        {
            "label": "Piperton",
            "value": "Piperton"
        },
        {
            "label": "Pittman Center",
            "value": "Pittman Center"
        },
        {
            "label": "Plainview",
            "value": "Plainview"
        },
        {
            "label": "Pleasant Hill",
            "value": "Pleasant Hill"
        },
        {
            "label": "Pleasant View",
            "value": "Pleasant View"
        },
        {
            "label": "Portland",
            "value": "Portland"
        },
        {
            "label": "Powell's Crossroads",
            "value": "Powell's Crossroads"
        },
        {
            "label": "Pulaski",
            "value": "Pulaski"
        },
        {
            "label": "Puryear",
            "value": "Puryear"
        },
        {
            "label": "Ramer",
            "value": "Ramer"
        },
        {
            "label": "Red Bank",
            "value": "Red Bank"
        },
        {
            "label": "Red Boiling Springs",
            "value": "Red Boiling Springs"
        },
        {
            "label": "Ridgely",
            "value": "Ridgely"
        },
        {
            "label": "Ridgeside",
            "value": "Ridgeside"
        },
        {
            "label": "Ridgetop",
            "value": "Ridgetop"
        },
        {
            "label": "Ripley",
            "value": "Ripley"
        },
        {
            "label": "Rives",
            "value": "Rives"
        },
        {
            "label": "Rockford",
            "value": "Rockford"
        },
        {
            "label": "Rockwood",
            "value": "Rockwood"
        },
        {
            "label": "Rogersville",
            "value": "Rogersville"
        },
        {
            "label": "Rossville",
            "value": "Rossville"
        },
        {
            "label": "Rutherford",
            "value": "Rutherford"
        },
        {
            "label": "Rutledge",
            "value": "Rutledge"
        },
        {
            "label": "St. Joseph",
            "value": "St. Joseph"
        },
        {
            "label": "Saltillo",
            "value": "Saltillo"
        },
        {
            "label": "Samburg",
            "value": "Samburg"
        },
        {
            "label": "Sardis",
            "value": "Sardis"
        },
        {
            "label": "Saulsbury",
            "value": "Saulsbury"
        },
        {
            "label": "Savannah",
            "value": "Savannah"
        },
        {
            "label": "Scotts Hill",
            "value": "Scotts Hill"
        },
        {
            "label": "Selmer",
            "value": "Selmer"
        },
        {
            "label": "Sevierville",
            "value": "Sevierville"
        },
        {
            "label": "Sharon",
            "value": "Sharon"
        },
        {
            "label": "Shelbyville",
            "value": "Shelbyville"
        },
        {
            "label": "Signal Mountain",
            "value": "Signal Mountain"
        },
        {
            "label": "Silerton",
            "value": "Silerton"
        },
        {
            "label": "Slayden",
            "value": "Slayden"
        },
        {
            "label": "Smithville",
            "value": "Smithville"
        },
        {
            "label": "Smyrna",
            "value": "Smyrna"
        },
        {
            "label": "Sneedville",
            "value": "Sneedville"
        },
        {
            "label": "Soddy-Daisy",
            "value": "Soddy-Daisy"
        },
        {
            "label": "Somerville",
            "value": "Somerville"
        },
        {
            "label": "South Carthage",
            "value": "South Carthage"
        },
        {
            "label": "South Fulton",
            "value": "South Fulton"
        },
        {
            "label": "South Pittsburg",
            "value": "South Pittsburg"
        },
        {
            "label": "Sparta",
            "value": "Sparta"
        },
        {
            "label": "Spencer",
            "value": "Spencer"
        },
        {
            "label": "Spring City",
            "value": "Spring City"
        },
        {
            "label": "Spring Hill",
            "value": "Spring Hill"
        },
        {
            "label": "Springfield",
            "value": "Springfield"
        },
        {
            "label": "Stanton",
            "value": "Stanton"
        },
        {
            "label": "Stantonville",
            "value": "Stantonville"
        },
        {
            "label": "Sunbright",
            "value": "Sunbright"
        },
        {
            "label": "Surgoinsville",
            "value": "Surgoinsville"
        },
        {
            "label": "Sweetwater",
            "value": "Sweetwater"
        },
        {
            "label": "Tazewell",
            "value": "Tazewell"
        },
        {
            "label": "Tellico Plains",
            "value": "Tellico Plains"
        },
        {
            "label": "Tennessee Ridge",
            "value": "Tennessee Ridge"
        },
        {
            "label": "Thompson's Station",
            "value": "Thompson's Station"
        },
        {
            "label": "Three Way",
            "value": "Three Way"
        },
        {
            "label": "Tiptonville",
            "value": "Tiptonville"
        },
        {
            "label": "Toone",
            "value": "Toone"
        },
        {
            "label": "Townsend",
            "value": "Townsend"
        },
        {
            "label": "Tracy City",
            "value": "Tracy City"
        },
        {
            "label": "Trenton",
            "value": "Trenton"
        },
        {
            "label": "Trezevant",
            "value": "Trezevant"
        },
        {
            "label": "Trimble",
            "value": "Trimble"
        },
        {
            "label": "Troy",
            "value": "Troy"
        },
        {
            "label": "Tullahoma",
            "value": "Tullahoma"
        },
        {
            "label": "Tusculum",
            "value": "Tusculum"
        },
        {
            "label": "Unicoi",
            "value": "Unicoi"
        },
        {
            "label": "Union City",
            "value": "Union City"
        },
        {
            "label": "Vanleer",
            "value": "Vanleer"
        },
        {
            "label": "Viola",
            "value": "Viola"
        },
        {
            "label": "Vonore",
            "value": "Vonore"
        },
        {
            "label": "Walden",
            "value": "Walden"
        },
        {
            "label": "Walnut Grove",
            "value": "Walnut Grove"
        },
        {
            "label": "Wartburg",
            "value": "Wartburg"
        },
        {
            "label": "Wartrace",
            "value": "Wartrace"
        },
        {
            "label": "Watauga",
            "value": "Watauga"
        },
        {
            "label": "Watertown",
            "value": "Watertown"
        },
        {
            "label": "Waverly",
            "value": "Waverly"
        },
        {
            "label": "Waynesboro",
            "value": "Waynesboro"
        },
        {
            "label": "Westmoreland",
            "value": "Westmoreland"
        },
        {
            "label": "White Bluff",
            "value": "White Bluff"
        },
        {
            "label": "White House",
            "value": "White House"
        },
        {
            "label": "White Pine",
            "value": "White Pine"
        },
        {
            "label": "Whiteville",
            "value": "Whiteville"
        },
        {
            "label": "Whitwell",
            "value": "Whitwell"
        },
        {
            "label": "Williston",
            "value": "Williston"
        },
        {
            "label": "Winchester",
            "value": "Winchester"
        },
        {
            "label": "Winfield",
            "value": "Winfield"
        },
        {
            "label": "Woodbury",
            "value": "Woodbury"
        },
        {
            "label": "Woodland Mills",
            "value": "Woodland Mills"
        },
        {
            "label": "Yorkville",
            "value": "Yorkville"
        }
    ],
    'Mississippi': [
        {
            "label": "Jackson",
            "value": "Jackson"
        },
        {
            "label": "Gulfport",
            "value": "Gulfport"
        },
        {
            "label": "Southaven",
            "value": "Southaven"
        },
        {
            "label": "Hattiesburg",
            "value": "Hattiesburg"
        },
        {
            "label": "Biloxi",
            "value": "Biloxi"
        },
        {
            "label": "Meridian",
            "value": "Meridian"
        },
        {
            "label": "Tupelo",
            "value": "Tupelo"
        },
        {
            "label": "Olive Branch",
            "value": "Olive Branch"
        },
        {
            "label": "Greenville",
            "value": "Greenville"
        },
        {
            "label": "Horn Lake",
            "value": "Horn Lake"
        },
        {
            "label": "Pearl",
            "value": "Pearl"
        },
        {
            "label": "Clinton",
            "value": "Clinton"
        },
        {
            "label": "Madison",
            "value": "Madison"
        },
        {
            "label": "Starkville",
            "value": "Starkville"
        },
        {
            "label": "Ridgeland",
            "value": "Ridgeland"
        },
        {
            "label": "Columbus",
            "value": "Columbus"
        },
        {
            "label": "Vicksburg",
            "value": "Vicksburg"
        },
        {
            "label": "Pascagoula",
            "value": "Pascagoula"
        },
        {
            "label": "Brandon",
            "value": "Brandon"
        },
        {
            "label": "Oxford",
            "value": "Oxford"
        },
        {
            "label": "Laurel",
            "value": "Laurel"
        },
        {
            "label": "Gautier",
            "value": "Gautier"
        },
        {
            "label": "Clarksdale",
            "value": "Clarksdale"
        },
        {
            "label": "Ocean Springs",
            "value": "Ocean Springs"
        },
        {
            "label": "Natchez",
            "value": "Natchez"
        },
        {
            "label": "Long Beach",
            "value": "Long Beach"
        },
        {
            "label": "Greenwood",
            "value": "Greenwood"
        },
        {
            "label": "Corinth",
            "value": "Corinth"
        },
        {
            "label": "Hernando",
            "value": "Hernando"
        },
        {
            "label": "Moss Point",
            "value": "Moss Point"
        },
        {
            "label": "Canton",
            "value": "Canton"
        },
        {
            "label": "Grenada",
            "value": "Grenada"
        },
        {
            "label": "McComb",
            "value": "McComb"
        },
        {
            "label": "Brookhaven",
            "value": "Brookhaven"
        },
        {
            "label": "Cleveland",
            "value": "Cleveland"
        },
        {
            "label": "Byram",
            "value": "Byram"
        },
        {
            "label": "Yazoo City",
            "value": "Yazoo City"
        },
        {
            "label": "West Point",
            "value": "West Point"
        },
        {
            "label": "Picayune",
            "value": "Picayune"
        },
        {
            "label": "Petal",
            "value": "Petal"
        },
        {
            "label": "Indianola",
            "value": "Indianola"
        },
        {
            "label": "Bay St. Louis",
            "value": "Bay St. Louis"
        },
        {
            "label": "D'Iberville",
            "value": "D'Iberville"
        },
        {
            "label": "Booneville",
            "value": "Booneville"
        },
        {
            "label": "New Albany",
            "value": "New Albany"
        },
        {
            "label": "Flowood",
            "value": "Flowood"
        },
        {
            "label": "Senatobia",
            "value": "Senatobia"
        },
        {
            "label": "Holly Springs",
            "value": "Holly Springs"
        },
        {
            "label": "Philadelphia",
            "value": "Philadelphia"
        },
        {
            "label": "Batesville",
            "value": "Batesville"
        }
    ]
}


export const portal_insurance_zoom_username = "bradley@portalinsurance.com";
export const portal_insurance_zoom_password = "587Lmy!Sdfg9!Pib";
export const portal_insurance_zoom_login_link = "https://app.agencyzoom.com/v1/api/auth/login";

export const portal_insurance_zoom_lead_sources = {
    'Portal Insurance': [
        {"label": "Accounting  Department", "value": 8864388},
        {"label": "Addison Patterson- Bank of England- Loan Officer", "value": 8879315},
        {"label": "Advanced Mortgage referral", "value": 8832087},
        {"label": "AIUA Facebook Campaign", "value": 217167},
        {"label": "Alfa Agent", "value": 203025},
        {"label": "Alfa Agents", "value": 138303},
        {"label": "ALFA Client - B. Flowers", "value": 202949},
        {"label": "Allison Foote - JPAR Real Estate", "value": 8874655},
        {"label": "Allstate", "value": 202970},
        {"label": "Allstate Romilee ", "value": 171556},
        {"label": "Amanda Middleton", "value": 202985},
        {"label": "Amber Sims", "value": 8818296},
        {"label": "Amber Sims ", "value": 110603}, {
            "label": "Angela Orenstein",
            "value": 217165
        }, {"label": "Angela Ward", "value": 203014}, {
            "label": "Annette Greer",
            "value": 251409
        }, {"label": "Anthony Lopez", "value": 249971}, {
            "label": "Anthony Ridings",
            "value": 249281
        }, {"label": "API Test", "value": 8896376}, {
            "label": "April Barton, Remax",
            "value": 8827866
        }, {"label": "April Barton, Remax ", "value": 110594}, {
            "label": "April Hughes ",
            "value": 114358
        }, {"label": "Ashley Watkins ", "value": 106697}, {
            "label": "Ashley Watkins-Community Bank",
            "value": 202993
        }, {"label": "Autonation Honda Deanne", "value": 202997}, {
            "label": "Baldwin County Home Builders Association",
            "value": 8857740
        }, {"label": "Bancorp South", "value": 8838758}, {
            "label": "Bancorp South ",
            "value": 110614
        }, {"label": "Bank of England", "value": 8885548}, {
            "label": "Bank of England ",
            "value": 217161
        }, {"label": "Barr Group Mortgage", "value": 217157}, {
            "label": "Bellator Lisa Richey ",
            "value": 240782
        }, {"label": "Ben White- Bank of England- Loan Officer", "value": 8888802}, {
            "label": "Beth Nettles",
            "value": 203007
        }, {"label": "Bijan Mohandessi", "value": 267802}, {
            "label": "Bijan Mohandessi ",
            "value": 106748
        }, {"label": "BNI", "value": 110589}, {
            "label": "Bradley Alfa Clients",
            "value": 106701
        }, {"label": "Bradley Facebook Group", "value": 8832096}, {
            "label": "Bradley Flowers",
            "value": 106683
        }, {"label": "Brandon Maye", "value": 217158}, {
            "label": "Brandon Maye ",
            "value": 110606
        }, {"label": "Brantley Rutz ", "value": 106757}, {
            "label": "Brejeannia Gardner",
            "value": 106749
        }, {"label": "Brejeannia Gardner, realtor", "value": 202954}, {
            "label": "Brian Dewberry Midtown Mortgage",
            "value": 203013
        }, {"label": "Brian Kimmel (AMIC)", "value": 8848651}, {
            "label": "Britini Rivers",
            "value": 202960
        }, {"label": "Britini Rivers-Robinson", "value": 113507}, {
            "label": "Bry Shields ",
            "value": 176210
        }, {"label": "Brystal Williams ", "value": 110608}, {
            "label": "Bubba Phillips",
            "value": 8862954
        }, {"label": "Bubba Phillips ", "value": 138037}, {
            "label": "Call-In-Dig Further",
            "value": 8832093
        }, {"label": "Calvin Nettles", "value": 8827867}, {
            "label": "Calvin Nettles ",
            "value": 236357
        }, {"label": "Camille Wilkinson", "value": 1503898}, {
            "label": "Camille Wilkinson ",
            "value": 106762
        }, {"label": "Candice Jones", "value": 236396}, {
            "label": "Careese Fincher ",
            "value": 110604
        }, {"label": "Careese Fincher, Strategic Wealth", "value": 202959}, {
            "label": "Caresse Fincher ",
            "value": 106750
        }, {"label": "Casey Dehle", "value": 125445}, {
            "label": "Casey Kinard",
            "value": 202999
        }, {"label": "Casey Watkins", "value": 110617}, {
            "label": "Cathy Bradley",
            "value": 158794
        }, {"label": "Centers of Influence Podcast", "value": 115955}, {
            "label": "Chad Niblett",
            "value": 8832092
        }, {"label": "Chandler Demouey", "value": 257251}, {
            "label": "Chandler Demouey ",
            "value": 106751
        }, {
            "label": "Cheryl McMillian - Realtor at Better Homes & Gardens",
            "value": 8878483
        }, {"label": "Chris Bogoslofski", "value": 8824191}, {
            "label": "Chris Bogoslofski ",
            "value": 208436
        }, {"label": "Chris Bruner", "value": 129872}, {
            "label": "Chris Collins ",
            "value": 203561
        }, {"label": "Chris Greene", "value": 267628}, {
            "label": "Chris May",
            "value": 176473
        }, {"label": "Christina Long", "value": 8827863}, {
            "label": "Christina Long ",
            "value": 110591
        }, {"label": "Clausdette Johnson", "value": 237066}, {
            "label": "Clear Blue",
            "value": 202964
        }, {"label": "Client Referral", "value": 203003}, {
            "label": "Coastal Alabama Insurance",
            "value": 217166
        }, {"label": "Cody Tatum", "value": 253766}, {"label": "Cody Tatum ", "value": 106752}, {
            "label": "Conversion",
            "value": 202948
        }, {"label": "Courtney Dortch", "value": 203002}, {
            "label": "Cover Wallet",
            "value": 203027
        }, {"label": "Cover Wallet Leads ", "value": 110619}, {
            "label": "Craig Raia",
            "value": 8825446
        }, {"label": "Craig Raia ", "value": 106756}, {
            "label": "Cross-Sale",
            "value": 106680
        }, {"label": "Crystal Robinson- KW Realtor", "value": 8897038}, {
            "label": "Crystal Seambra",
            "value": 199387
        }, {"label": "Cyber Form", "value": 135086}, {"label": "Cy Young", "value": 133873}, {
            "label": "Dana Carthen",
            "value": 202983
        }, {"label": "Daniel Farmer", "value": 8832094}, {
            "label": "Dave Ramsey ",
            "value": 201557
        }, {"label": "David Bradley - Farmers", "value": 202988}, {
            "label": "David Keenum",
            "value": 8827860
        }, {"label": "David Keenum ", "value": 106698}, {
            "label": "David Keenum, E",
            "value": 202945
        }, {"label": "David Kelly", "value": 202969}, {
            "label": "David MacKeller",
            "value": 8827868
        }, {"label": "David MacKeller ", "value": 110588}, {
            "label": "Debbie Guy (Realtor)",
            "value": 8895060
        }, {"label": "Demetrius Reynolds", "value": 110615}, {
            "label": "Demetrius Reynolds, Remax",
            "value": 202953
        }, {"label": "Denise McMichael", "value": 265223}, {
            "label": "Denise McMichael ",
            "value": 106753
        }, {"label": "DHI - Jessica Lee", "value": 8833077}, {
            "label": "Diana Bethea",
            "value": 8861775
        }, {"label": "Diana Bethea Loan Officer ", "value": 8826697}, {
            "label": "Diana Will",
            "value": 106696
        }, {"label": "Direct Mortgage", "value": 202973}, {
            "label": "Direct Mortgage Loans",
            "value": 131980
        }, {"label": "Don Guy ", "value": 202794}, {
            "label": "Donna Gregory",
            "value": 203000
        }, {"label": "Donnie Clark EXP", "value": 8861292}, {
            "label": "Donnie Clark EXP ",
            "value": 8819377
        }, {"label": "Ebony Smith ", "value": 206720}, {
            "label": "Eddie Holmes",
            "value": 5172412
        }, {"label": "Eddie Holmes ", "value": 202990}, {
            "label": "Elizabeth Nettles ",
            "value": 110599
        }, {"label": "Embrace, Joey Reid", "value": 202958}, {
            "label": "Emily Tidwell",
            "value": 113776
        }, {"label": "Emily Tidwell - Keller Williams", "value": 202956}, {
            "label": "Endorsement ",
            "value": 8832085
        }, {"label": "Eric Whitlow EXP Realty", "value": 8820349}, {
            "label": " Erika Taylor",
            "value": 202998
        }, {"label": "Erika Taylor", "value": 8874519}, {
            "label": "Facebook",
            "value": 8896733
        }, {"label": "Facebook Deanne Page", "value": 202984}, {
            "label": "Facebook Lead",
            "value": 265608
        }, {"label": "Facebook Organic", "value": 8827861}, {
            "label": "Fed Nat Book Roll ",
            "value": 8843629
        }, {"label": "Fletcher and Janz Processing Services ", "value": 216644}, {
            "label": "Flynn Christian",
            "value": 8874518
        }, {"label": "Flynn Christian ", "value": 202996}, {
            "label": "FRANCES TATE",
            "value": 206936
        }, {"label": "Friend/Family", "value": 260777}, {
            "label": "Friend/Family ",
            "value": 106700
        }, {"label": "Friends/Family", "value": 202976}, {
            "label": "Gabe Martin",
            "value": 150350
        }, {"label": "Gabe Martin - Alpha", "value": 8832086}, {
            "label": "Game Changers BNI",
            "value": 8825530
        }, {"label": "Game Changers BNI ", "value": 203010}, {
            "label": "Garett Caywood ",
            "value": 8861060
        }, {"label": "Gene Fox", "value": 217164}, {
            "label": "Gene Fox ",
            "value": 117642
        }, {"label": "Geovera Book Roll ", "value": 241159}, {
            "label": "Glisson, Sara L",
            "value": 8850298
        }, {"label": "Glynis Hyde", "value": 8832097}, {
            "label": "Glynis Hyde-Regions",
            "value": 121483
        }, {"label": "GOOGLE", "value": 256623}, {
            "label": "Google ",
            "value": 106744
        }, {"label": "Google Search Call In (NWCS)", "value": 202951}, {
            "label": "Greg Jones",
            "value": 267988
        }, {"label": "Greg Jones ", "value": 112220}, {
            "label": "Gulf Coast Real Estate",
            "value": 8860335
        }, {"label": "Gus Hernandez", "value": 1503897}, {
            "label": "Gus Hernandez ",
            "value": 106755
        }, {"label": "Hannah Harrison", "value": 203022}, {
            "label": "Haylie Barnes ",
            "value": 110612
        }, {
            "label": "Heather DeLapp- JPar Realty- Realtor",
            "value": 8888803
        }, {"label": "Heather Edwards - Nexa Mortgage", "value": 8854261}, {
            "label": "Heather Williams",
            "value": 8839537
        }, {"label": "Homestar Financial Carolyn", "value": 217172}, {
            "label": "Hometown Lenders",
            "value": 117641
        }, {"label": "IAOA", "value": 133333}, {"label": "Instagram", "value": 106672}, {
            "label": "Insurance Guys",
            "value": 140404
        }, {"label": "Insurance Guys Podcast", "value": 170459}, {
            "label": "Intake Form",
            "value": 118481
        }, {"label": "IProtect/Jessica referral", "value": 239705}, {
            "label": "Irby",
            "value": 8832091
        }, {"label": "Iron Bowl Campaign", "value": 8874516}, {
            "label": "Iron Bowl Campaign ",
            "value": 202986
        }, {"label": "Iron Bowl Upload ", "value": 8842721}, {
            "label": "Jacob Powe",
            "value": 8874521
        }, {"label": "Jacob Powe ", "value": 203006}, {
            "label": "Jane Dalida",
            "value": 217159
        }, {"label": "Janet Motley", "value": 8818796}, {
            "label": "Janet Motley ",
            "value": 106760
        }, {"label": "Janet Prince", "value": 191759}, {
            "label": "Jason Will Real Estate",
            "value": 8832098
        }, {"label": "Jasper Dex", "value": 8878474}, {
            "label": "Jazmin Molina",
            "value": 203005
        }, {"label": "Jean Fox ", "value": 205497}, {"label": "Jeff Coleman", "value": 137480}, {
            "label": "Jeff Jones",
            "value": 8827862
        }, {"label": "Jeff Jones ", "value": 192269}, {"label": "Jeff Nelson", "value": 106758}, {
            "label": "Jerry Tims",
            "value": 8857803
        }, {"label": "Jessa Tolentino ", "value": 106687}, {
            "label": "Jessie Brazelton-fiance",
            "value": 261892
        }, {"label": "Jo Apacible", "value": 8906367}, {
            "label": "Joe Hollier",
            "value": 141272
        }, {"label": "Joey Reid ", "value": 110602}, {
            "label": "Joey Reid, Embrace referral",
            "value": 202955
        }, {"label": "John Ross", "value": 8832088}, {
            "label": "Joselyne Ridings",
            "value": 249288
        }, {"label": "Joseph Freeman", "value": 8827870}, {
            "label": "Joseph Freeman ",
            "value": 203021
        }, {"label": "Josh Contact", "value": 8867289}, {
            "label": "Josh Cottrell",
            "value": 217163
        }, {"label": "Josh Daniel", "value": 266021}, {
            "label": "Josh Daniel-Contractors",
            "value": 8846374
        }, {"label": "Josh Lankford", "value": 202946}, {
            "label": "Josh Lankford State Farm",
            "value": 199468
        }, {"label": "Josh West", "value": 244795}, {
            "label": "Josh with Alfa",
            "value": 202971
        }, {"label": "Kaitlyn Knowles EXP Realty", "value": 8892285}, {
            "label": "Kari Anne Piecuch",
            "value": 113508
        }, {"label": "Kat Ternes", "value": 8892535}, {
            "label": "Keller Williams",
            "value": 116410
        }, {"label": "Keller Williams Realty", "value": 203012}, {
            "label": "Kellie Martin",
            "value": 8840651
        }, {"label": "Kellie Martin Synovus Bank", "value": 8823207}, {
            "label": "Kelsey Contact ",
            "value": 8827859
        }, {"label": "Kelsey Green", "value": 8821949}, {
            "label": "Kelsey Natural Market",
            "value": 8897591
        }, {"label": "Kenneth Contact", "value": 8896753}, {
            "label": "Kenneth Referral Group",
            "value": 8820115
        }, {"label": "Kenneth Williamson ", "value": 107664}, {
            "label": "Ken Whitton",
            "value": 203023
        }, {"label": "Kimberly Thompson", "value": 8822539}, {
            "label": "Kim coleman",
            "value": 208962
        }, {"label": "Kim Knowles", "value": 8827869}, {
            "label": "Kim Knowles ",
            "value": 110616
        }, {"label": "Kyle Dahlmann- Remax-  Realtor", "value": 8864335}, {
            "label": "Land Glide ",
            "value": 114279
        }, {"label": "Laurel Flowers", "value": 266391}, {
            "label": "Laurel Flowers ",
            "value": 106699
        }, {"label": "Laurie Roberts", "value": 188633}, {
            "label": "Lee Grandberry ",
            "value": 8831850
        }, {"label": "Lender", "value": 202982}, {
            "label": "Leslie Ezell",
            "value": 8832089
        }, {"label": "Lia Net Guevara", "value": 8841597}, {
            "label": "Lindsey Bembry",
            "value": 8834530
        }, {"label": "Ling Jackson", "value": 135314}, {"label": "LinkedIn", "value": 106674}, {
            "label": "Lisa George",
            "value": 8865071
        }, {"label": "Lisa George ", "value": 231228}, {
            "label": "Lisa Nealy",
            "value": 202992
        }, {"label": "Lori Phelps", "value": 8874515}, {
            "label": "Lori Phelps ",
            "value": 110607
        }, {"label": "Lori Roberts, Realtor", "value": 217168}, {
            "label": "Lorrie Lister ",
            "value": 110609
        }, {"label": "Lyndsey Colbert", "value": 200321}, {
            "label": "Lyndsie Brinkman- Cardinal Financial",
            "value": 256486
        }, {"label": "Maggie Lamorelli ", "value": 106742}, {
            "label": "Magnolia Mortgage",
            "value": 8825832
        }, {"label": "Magnolia Mortgage ", "value": 116411}, {
            "label": "Marc Lange ",
            "value": 110618
        }, {"label": "Margaret Reid", "value": 8832902}, {
            "label": "Margaret Reid ",
            "value": 151544
        }, {"label": "Marine St Lofts", "value": 8902913}, {
            "label": "Mark Cagle ",
            "value": 150349
        }, {"label": "Mark Nolan", "value": 175702}, {
            "label": "Marsha Greer ",
            "value": 110605
        }, {"label": "Marty Kennedy", "value": 263838}, {
            "label": "Mason Bradley",
            "value": 8818885
        }, {"label": "Mason Bradley ", "value": 106747}, {
            "label": "Matt Baker",
            "value": 8874520
        }, {"label": "Matt Baker ", "value": 106759}, {
            "label": "Matt Brokow",
            "value": 8825831
        }, {"label": "Matt Brown", "value": 203024}, {
            "label": "Matt Brown-Allstate",
            "value": 137878
        }, {"label": "Meagan Talbert", "value": 114669}, {
            "label": "Megan's Contact",
            "value": 8860971
        }, {"label": "Megan Cross Sale", "value": 8899778}, {
            "label": "Megan Kenly",
            "value": 8864435
        }, {"label": "Megan Reed", "value": 8857745}, {
            "label": "Melissa Love",
            "value": 8829133
        }, {"label": "Melissa Strickland", "value": 8827865}, {
            "label": "Melissa Strickland ",
            "value": 114668
        }, {"label": "Michael Blisko", "value": 8836769}, {
            "label": "Michael Cutner ",
            "value": 201095
        }, {"label": "Mission 1st", "value": 263819}, {
            "label": "Mission 1st ",
            "value": 143112
        }, {"label": "Mortgage Josh", "value": 8863959}, {
            "label": "Mortgage Josh ",
            "value": 106746
        }, {"label": "Movement Mortage", "value": 202981}, {
            "label": "Natasha contact",
            "value": 123623
        }, {"label": "Natasha May ", "value": 106684}, {
            "label": "Natasha Pass Off",
            "value": 8831007
        }, {"label": "Nathan Hughes", "value": 8892881}, {
            "label": "Nathan Hughes- JPAR",
            "value": 8874404
        }, {"label": "Networking Event", "value": 203017}, {
            "label": "Nick Kenley ",
            "value": 110601
        }, {"label": "Nick Segers, Kloud 7", "value": 203008}, {
            "label": "NWCS Web Form Submission",
            "value": 202978
        }, {"label": "Paige Morse, Port City Realtor", "value": 203018}, {
            "label": "Patton Lewis",
            "value": 139850
        }, {"label": "Personal Auto Only", "value": 216801}, {
            "label": "Personal Property",
            "value": 216797
        }, {"label": "Pleasure Isle Villas Condominium", "value": 8889684}, {
            "label": "Podcast",
            "value": 202963
        }, {"label": "Press 1 Campaign", "value": 202980}, {
            "label": "Primelending",
            "value": 202994
        }, {"label": "Progressive Referral", "value": 8863789}, {
            "label": "Quoted Not Sold ",
            "value": 8888129
        }, {"label": "Rachel Bondoc", "value": 235895}, {
            "label": "Rameh Khazan ",
            "value": 110600
        }, {"label": "Rameh Khazen", "value": 202947}, {
            "label": "Realtor",
            "value": 217169
        }, {"label": "Rebecca Hendrich", "value": 8825303}, {
            "label": "Rebecca Rel - Keller Williams",
            "value": 8906368
        }, {"label": "Referral", "value": 203016}, {
            "label": "Referral - Client",
            "value": 202979
        }, {"label": "Referral - External", "value": 202972}, {
            "label": "Referral by AgencyZoom",
            "value": 106677
        }, {"label": "Referral Carolyn", "value": 203020}, {
            "label": "Referral Deanne",
            "value": 202989
        }, {"label": "Referral from Current Client", "value": 263779}, {
            "label": "Reggie Pullium ",
            "value": 203026
        }, {"label": "Regions", "value": 202995}, {"label": "Renewal Parser", "value": 8853110}, {
            "label": "Rewrite",
            "value": 106682
        }, {"label": "Rhen Bartlett", "value": 8827871}, {
            "label": "Rhen Bartlett ",
            "value": 111081
        }, {"label": "Rhonda Moore, Regions Bank", "value": 203011}, {
            "label": "Rhonda Smith",
            "value": 217170
        }, {"label": "RICH PRESTON", "value": 8854893}, {
            "label": "Rob Austin ALFA",
            "value": 231499
        }, {"label": "Roc Davis ", "value": 110483}, {
            "label": "Rodney Duncan (DK Mortgage)",
            "value": 8889362
        }, {"label": "Ron Latrell", "value": 203015}, {
            "label": "Ron Lattrell",
            "value": 124115
        }, {"label": "Rosa Jerez- Cardinal Financial", "value": 256487}, {
            "label": "Rosia Gibbs- IXL REAL ESTATE",
            "value": 8887141
        }, {"label": "RSA Insurance Group", "value": 256168}, {
            "label": "RSA Insurance Group ",
            "value": 208279
        }, {"label": "RVM  Integration", "value": 8842319}, {
            "label": "Sales Dialer Lead",
            "value": 202974
        }, {"label": "Sam Compton", "value": 202962}, {
            "label": "Sam Thompson",
            "value": 8877581
        }, {"label": "Sandra Hart", "value": 217156}, {
            "label": "Sara Glisson",
            "value": 266375
        }, {"label": "Scott Lindsey", "value": 202991}, {
            "label": "Scottsdale Book Roll",
            "value": 8883852
        }, {"label": "Scott Williams (Keller Williams Realty)", "value": 8866997}, {
            "label": "Sean Bryant",
            "value": 122686
        }, {"label": "Shane Owens", "value": 214566}, {
            "label": "Shaun Foster",
            "value": 217160
        }, {"label": "Sherrie Knotts/Valent Group", "value": 262913}, {
            "label": "State Farm",
            "value": 202961
        }, {"label": "State Farm,Tyler Arrington", "value": 217171}, {
            "label": "Steve Cochran",
            "value": 217162
        }, {"label": "Strategic Wealth", "value": 8832095}, {
            "label": "Sub Broker Agency",
            "value": 202975
        }, {"label": "Switch Campaign", "value": 121362}, {
            "label": "Swyfft ",
            "value": 8841342
        }, {"label": "Sylvia Orona with Geneva", "value": 8832090}, {
            "label": "Tania Thomas",
            "value": 202944
        }, {"label": "Tania Thomas, Elite Real Estate ", "value": 110598}, {
            "label": "Tani Thomas",
            "value": 202952
        }, {"label": "Taylor Russ", "value": 8830256}, {
            "label": "Teddy Williams",
            "value": 8819030
        }, {"label": "thames batre insurance", "value": 8852376}, {
            "label": "The Gill Realty Group",
            "value": 8845192
        }, {"label": "The Malcolm Seals Team - IXL Real Estate", "value": 8885123}, {
            "label": "The Mark Lee Team ",
            "value": 234403
        }, {"label": "The Pillars", "value": 203009}, {
            "label": "Therese Rogan- Keller Williams ",
            "value": 208824
        }, {"label": "Thomas Thomas", "value": 135313}, {
            "label": "Tia Austin- CMG Home Loans",
            "value": 8882556
        }, {"label": "Tiffany Garmany ", "value": 106761}, {
            "label": "Tiffany Graham Regional Homes ",
            "value": 246795
        }, {"label": "Tiffany Perkins ", "value": 158146}, {
            "label": "Tommy Foxworth",
            "value": 8874517
        }, {
            "label": "Tommy Foxworth ",
            "value": 202987
        }, {
            "label": "Tonya Thomas (Realtor) Alabama Real Estate Solutions",
            "value": 8889809
        }, {"label": "Tony Thompson", "value": 8828088}, {
            "label": "Trustmark Bank",
            "value": 202957
        }, {"label": "Tyler Ross", "value": 111975}, {
            "label": "URA (United Risk Agency)",
            "value": 8849630
        }, {"label": "Wade Jones", "value": 8832099}, {
            "label": "Walk-In-Dig Further ",
            "value": 106681
        }, {"label": "Walk In", "value": 202968}, {
            "label": "Website",
            "value": 124114
        }, {"label": "Wellhouse Real Estate ", "value": 175275}, {
            "label": "Winback",
            "value": 106675
        }, {"label": "Winback by AgencyZoom", "value": 106678}, {
            "label": "Wise Insurance ",
            "value": 8879351
        }, {"label": "Zach Schneider", "value": 8874514}, {"label": "Zach Schneider ", "value": 227932}],
    'LTN Insurance': [
        {
            "label": "Aaron Shanklin (MS Ins Agent)",
            "value": 8881734
        },
        {
            "label": "Ad - Other",
            "value": 226824
        },
        {
            "label": "Adrianne Blackwood",
            "value": 8838627
        },
        {
            "label": " Alex Sweeney - SouthState Bank",
            "value": 8835378
        },
        {
            "label": "Amanda Beavers",
            "value": 8851475
        },
        {
            "label": "Amanda Farmer- realtor ",
            "value": 8904854
        },
        {
            "label": "Amy Smith ",
            "value": 8824398
        },
        {
            "label": "Amy Wilt",
            "value": 8833001
        },
        {
            "label": "Ashley Williams - relator ",
            "value": 8859293
        },
        {
            "label": "Aurelia Bryars- Alabama Land and Title ",
            "value": 8881311
        },
        {
            "label": "Beverly Butler ",
            "value": 1503910
        },
        {
            "label": "Bo Nicolas ",
            "value": 262497
        },
        {
            "label": "Brantley Rutz",
            "value": 8853715
        },
        {
            "label": "Brett Jones - Waters Edge Realty",
            "value": 8847465
        },
        {
            "label": "Brittney Boyce",
            "value": 188267
        },
        {
            "label": "Brittney Boyce",
            "value": 204708
        },
        {
            "label": "Brock - State Farm ",
            "value": 8831059
        },
        {
            "label": "Bryan Dewberry ",
            "value": 8848136
        },
        {
            "label": "Call-In",
            "value": 150104
        },
        {
            "label": "Candace Pfab- Realtor",
            "value": 8908025
        },
        {
            "label": "CBMC",
            "value": 184070
        },
        {
            "label": "Chad Hampton",
            "value": 252725
        },
        {
            "label": "Chamber of Commerce",
            "value": 152630
        },
        {
            "label": "Chase Langham- Loan Originator ",
            "value": 8859860
        },
        {
            "label": "Chris May",
            "value": 150108
        },
        {
            "label": "Chuck Kirk",
            "value": 265991
        },
        {
            "label": "Cold Call/ Self Generated",
            "value": 259906
        },
        {
            "label": "Cross-Sale",
            "value": 150105
        },
        {
            "label": "Current Client Referral",
            "value": 150101
        },
        {
            "label": "Customer Referral",
            "value": 8844144
        },
        {
            "label": " Danielle Dewberry - Mission 1st Mortgage",
            "value": 8868435
        },
        {
            "label": "Daphne Cooke- Realtor",
            "value": 8880458
        },
        {
            "label": "David Todd- realtor ",
            "value": 8868967
        },
        {
            "label": "Derek Stillman",
            "value": 188608
        },
        {
            "label": "Elf on the Shelf Giveaway",
            "value": 164526
        },
        {
            "label": "Evan Templeton",
            "value": 208336
        },
        {
            "label": "Evelyn Hall - Waters Edge",
            "value": 8875328
        },
        {
            "label": "Facebook",
            "value": 150098
        },
        {
            "label": "Facebook ConsumerPortal App",
            "value": 226826
        },
        {
            "label": "Friend/family",
            "value": 153068
        },
        {
            "label": "Friends/Family",
            "value": 226830
        },
        {
            "label": "Golf",
            "value": 240162
        },
        {
            "label": "Harrison Louden",
            "value": 8827842
        },
        {
            "label": "Ian Michon",
            "value": 8824167
        },
        {
            "label": "Ian Michon",
            "value": 8825158
        },
        {
            "label": "IAOA Facebook Group",
            "value": 169767
        },
        {
            "label": "In bound Cross Sale",
            "value": 8899694
        },
        {
            "label": "Instagram",
            "value": 150097
        },
        {
            "label": "James Walker- Heritage Resort Realty",
            "value": 8882321
        },
        {
            "label": "Janet Tucker - Waters Edge Realty",
            "value": 8831948
        },
        {
            "label": "Jason Rockett- Loan Officer",
            "value": 8868426
        },
        {
            "label": "Jay Manal",
            "value": 8820091
        },
        {
            "label": "Jennifer Anderson ",
            "value": 8854932
        },
        {
            "label": "Jeremy Stanford",
            "value": 8820863
        },
        {
            "label": "Jessica Bailey - Roberts Brothers",
            "value": 266531
        },
        {
            "label": "Jessica Clarke ",
            "value": 8828905
        },
        {
            "label": "Jim Walker- realtor",
            "value": 8897185
        },
        {
            "label": "Joey Parker - SouthState Bank",
            "value": 8832975
        },
        {
            "label": "John Oldshue",
            "value": 8844283
        },
        {
            "label": "Joline Beedy - Regions Bank",
            "value": 265869
        },
        {
            "label": "Karly Dunlap",
            "value": 204514
        },
        {
            "label": "Kate Wagner",
            "value": 8825586
        },
        {
            "label": "Kathy McClure",
            "value": 8847006
        },
        {
            "label": "Katlyn Sesera",
            "value": 8852742
        },
        {
            "label": "Kay Mashburn - Regions Mortgage ",
            "value": 8831949
        },
        {
            "label": "Keith Singler/Altaworx",
            "value": 8833323
        },
        {
            "label": "Leigh Ann Pennington",
            "value": 8862434
        },
        {
            "label": "LinkedIn",
            "value": 150099
        },
        {
            "label": " Lisa Morris - Home Mortgage of America",
            "value": 8832980
        },
        {
            "label": "Lisa Tompkins",
            "value": 8844282
        },
        {
            "label": "Lori Cash",
            "value": 8837836
        },
        {
            "label": "LTNIns.com",
            "value": 153156
        },
        {
            "label": "LTN Insurance",
            "value": 167961
        },
        {
            "label": "Luke Hayes",
            "value": 226832
        },
        {
            "label": "Mandi LaCroix- realtor",
            "value": 8865054
        },
        {
            "label": "Matt Crocker - Coldwell Banker Rheel",
            "value": 8817547
        },
        {
            "label": "Michelle Kelly ",
            "value": 8833986
        },
        {
            "label": "Michelle Platt ",
            "value": 265990
        },
        {
            "label": "Miranda Fields",
            "value": 8855235
        },
        {
            "label": "Morgan Bradley - Alfa Agent",
            "value": 8903360
        },
        {
            "label": "NDL",
            "value": 151691
        },
        {
            "label": "Neal price",
            "value": 262999
        },
        {
            "label": "Other",
            "value": 226834
        },
        {
            "label": "Past Client",
            "value": 239748
        },
        {
            "label": "Phone",
            "value": 226831
        },
        {
            "label": "Public Relations",
            "value": 226823
        },
        {
            "label": "Rebecca Romash- relator ",
            "value": 8863969
        },
        {
            "label": "Referral - Client",
            "value": 226833
        },
        {
            "label": "Referral - Employee",
            "value": 226828
        },
        {
            "label": "Referral - External",
            "value": 226825
        },
        {
            "label": "Referral - Other",
            "value": 226827
        },
        {
            "label": "Referral - Partner",
            "value": 226829
        },
        {
            "label": "Referral by AgencyZoom",
            "value": 150102
        },
        {
            "label": "Referral from BNI",
            "value": 151126
        },
        {
            "label": "Referral Source Excluded from sales",
            "value": 231876
        },
        {
            "label": "Referred by: Ailey Cometti",
            "value": 264639
        },
        {
            "label": "Referred by: Allyson Odam",
            "value": 210455
        },
        {
            "label": "Referred by: Amanda McAuley",
            "value": 219659
        },
        {
            "label": "Referred by: Bo Blackwell ",
            "value": 198811
        },
        {
            "label": "Referred by: Brandi Fulford",
            "value": 204320
        },
        {
            "label": "Referred by: Brandon Hambright",
            "value": 206102
        },
        {
            "label": "Referred by: Candice Suvari",
            "value": 247143
        },
        {
            "label": "Referred by: Cierra White",
            "value": 204986
        },
        {
            "label": "Referred by: Cody Dearing ",
            "value": 249616
        },
        {
            "label": "Referred by: Cole Tarver",
            "value": 253149
        },
        {
            "label": "Referred by: David Kitchens ",
            "value": 245425
        },
        {
            "label": "Referred by: Donna Dorriety",
            "value": 237641
        },
        {
            "label": "Referred by: Dylan Strachan",
            "value": 204786
        },
        {
            "label": "Referred by: Hannah Harris",
            "value": 204439
        },
        {
            "label": "Referred by: Jackie Bullard",
            "value": 206814
        },
        {
            "label": "Referred by: Jan Volovecky",
            "value": 219855
        },
        {
            "label": "Referred by: Jeff Ross",
            "value": 204640
        },
        {
            "label": "Referred by: Joey Lennicx",
            "value": 215621
        },
        {
            "label": "Referred by: John Fowler",
            "value": 209320
        },
        {
            "label": "Referred by: Karen Moss",
            "value": 211921
        },
        {
            "label": "Referred by: Kelsey Dixon ",
            "value": 200696
        },
        {
            "label": "Referred by: Kim Melton",
            "value": 204319
        },
        {
            "label": "Referred by: Laura Brewer ",
            "value": 260584
        },
        {
            "label": "Referred by: Lauren Burgess",
            "value": 242861
        },
        {
            "label": "Referred by: Lauren Collins ",
            "value": 201044
        },
        {
            "label": "Referred by: Leslie Ezell",
            "value": 202216
        },
        {
            "label": "Referred By: Lisa Musselman ",
            "value": 219293
        },
        {
            "label": "Referred by: Mallory Wilkins",
            "value": 245883
        },
        {
            "label": "Referred by: Matthew Johnston State Farm",
            "value": 249968
        },
        {
            "label": "Referred by: Melissa Surovich",
            "value": 228208
        },
        {
            "label": "Referred by: Melvin Hitchens",
            "value": 217593
        },
        {
            "label": "Referred by: Mike Ori",
            "value": 259875
        },
        {
            "label": "Referred by: Neal Price",
            "value": 201658
        },
        {
            "label": "Referred by: Paula Casallo",
            "value": 242592
        },
        {
            "label": "Referred by: Saijee Ryals",
            "value": 204321
        },
        {
            "label": "Referred by: Sarah Brooks",
            "value": 204253
        },
        {
            "label": "Referred by: Shanna Smith ",
            "value": 260912
        },
        {
            "label": "Referred by: Stacey Herrin  ",
            "value": 199570
        },
        {
            "label": "Referred by: Stevie Cordts",
            "value": 259708
        },
        {
            "label": "Referred by: Terry Shelburne",
            "value": 248480
        },
        {
            "label": "Referred by: Tiffany Wooten",
            "value": 264817
        },
        {
            "label": "Referred By: Trevor Chunn",
            "value": 234760
        },
        {
            "label": "Referred by: Vince Hughes",
            "value": 253223
        },
        {
            "label": "Referred by: Wes Etheridge ",
            "value": 247563
        },
        {
            "label": "Referred by: Wesley Latham",
            "value": 246841
        },
        {
            "label": "Referred by: William Dumas",
            "value": 211418
        },
        {
            "label": "Referred by Aaron Jensen",
            "value": 160373
        },
        {
            "label": "Referred by Adam Rue",
            "value": 152628
        },
        {
            "label": "Referred by Adriane Blackwood",
            "value": 176056
        },
        {
            "label": "Referred by a friend (of LTN)",
            "value": 8881315
        },
        {
            "label": "Referred by Alex Hutto",
            "value": 176101
        },
        {
            "label": "Referred by Alison Ward",
            "value": 178841
        },
        {
            "label": "Referred by Amber Beavers",
            "value": 186703
        },
        {
            "label": "Referred by Amber Frasier",
            "value": 238593
        },
        {
            "label": "Referred by Andrew Lewis",
            "value": 180824
        },
        {
            "label": "Referred by Angie Jones",
            "value": 241585
        },
        {
            "label": "Referred by Anne Dorman",
            "value": 185486
        },
        {
            "label": "Referred by Anthony Troyke ",
            "value": 241488
        },
        {
            "label": "Referred by April Hunt",
            "value": 238589
        },
        {
            "label": "Referred by April McCraney",
            "value": 238095
        },
        {
            "label": "Referred by Ashley Rowzee",
            "value": 172618
        },
        {
            "label": "Referred by Autumn Gann",
            "value": 151130
        },
        {
            "label": "Referred by Barr Group Mortgage",
            "value": 195610
        },
        {
            "label": "Referred by Bart Hare",
            "value": 164510
        },
        {
            "label": "Referred by Beckham Wyrick",
            "value": 183291
        },
        {
            "label": "Referred by Becky Geirer",
            "value": 168632
        },
        {
            "label": "Referred by Ben Turner",
            "value": 157524
        },
        {
            "label": "Referred by Bethany Ogden ",
            "value": 236071
        },
        {
            "label": "Referred by Beth Cazalas",
            "value": 171517
        },
        {
            "label": "Referred by Blaine Patin",
            "value": 162235
        },
        {
            "label": "Referred by Bowen Weir",
            "value": 164644
        },
        {
            "label": "Referred by Brad Moore",
            "value": 169764
        },
        {
            "label": "Referred by Brandon Reed",
            "value": 191066
        },
        {
            "label": "Referred by Bree Shores",
            "value": 241271
        },
        {
            "label": "Referred by Brooke Butler",
            "value": 151123
        },
        {
            "label": "Referred by Carley Hooks",
            "value": 243156
        },
        {
            "label": "Referred by Carrie Mackey",
            "value": 172591
        },
        {
            "label": "Referred by Cason Smith",
            "value": 199540
        },
        {
            "label": "Referred by Chad Kirkland",
            "value": 161488
        },
        {
            "label": "Referred by Chase Blackmon",
            "value": 151606
        },
        {
            "label": "Referred by Chase Herrin State Farm",
            "value": 151132
        },
        {
            "label": "Referred by Chase Schmidt",
            "value": 176608
        },
        {
            "label": "Referred by Cherry Wiggins",
            "value": 173207
        },
        {
            "label": "Referred by Cindy Zebryk",
            "value": 194741
        },
        {
            "label": "Referred by Codi Dearing",
            "value": 206144
        },
        {
            "label": "Referred by Cody White (Lad Drago State Farm)",
            "value": 205281
        },
        {
            "label": "Referred by Corey Besteda",
            "value": 219808
        },
        {
            "label": "Referred by Craig Saint",
            "value": 174037
        },
        {
            "label": "Referred by Cullen Milsap",
            "value": 161924
        },
        {
            "label": "Referred by Daniela Nielson",
            "value": 172365
        },
        {
            "label": "Referred by Daniel Pharr ",
            "value": 235589
        },
        {
            "label": "Referred by David Mackellar BOE",
            "value": 169987
        },
        {
            "label": "Referred by David Turk",
            "value": 169233
        },
        {
            "label": "Referred by Derek Stillman",
            "value": 150100
        },
        {
            "label": "Referred by Derrick Payne",
            "value": 160374
        },
        {
            "label": "Referred by Diana Will Movement Mortgage",
            "value": 176069
        },
        {
            "label": "Referred by Dianne Brookerd",
            "value": 151128
        },
        {
            "label": "Referred by Dwayne Crumley",
            "value": 177348
        },
        {
            "label": "Referred by Evan Templeton",
            "value": 160526
        },
        {
            "label": "Referred by Garrett Cox",
            "value": 167224
        },
        {
            "label": "Referred by Georgia Sumlin",
            "value": 151127
        },
        {
            "label": "Referred by Hanna Rudzinsky",
            "value": 170019
        },
        {
            "label": "Referred by Heather DeLapp",
            "value": 157424
        },
        {
            "label": "Referred by Hollie Mackellar",
            "value": 200921
        },
        {
            "label": "Referred by Holly Hayek",
            "value": 189987
        },
        {
            "label": "Referred by Home Front Lending",
            "value": 151124
        },
        {
            "label": "Referred by Integrity Mortgage Group (IMG)",
            "value": 169042
        },
        {
            "label": "Referred by Jacob Toler",
            "value": 235624
        },
        {
            "label": "Referred by Jacob Younce",
            "value": 209264
        },
        {
            "label": "Referred by Jake Devantier",
            "value": 152939
        },
        {
            "label": "Referred by James Delay",
            "value": 238951
        },
        {
            "label": "Referred by Jeff Olson",
            "value": 175563
        },
        {
            "label": "Referred by Jennifer Sage",
            "value": 254337
        },
        {
            "label": "Referred by Jessica Parker",
            "value": 193456
        },
        {
            "label": "Referred by Jessica Sturgis",
            "value": 173044
        },
        {
            "label": "Referred by Jill Delamore",
            "value": 165577
        },
        {
            "label": "Referred by Judd Gillespie",
            "value": 238032
        },
        {
            "label": "Referred by Justin Law",
            "value": 184071
        },
        {
            "label": "Referred by Karen Campbell",
            "value": 161611
        },
        {
            "label": "Referred by Kari Arnold",
            "value": 195418
        },
        {
            "label": "Referred by Kat Gray",
            "value": 8899290
        },
        {
            "label": "Referred by Kathy Statska",
            "value": 162836
        },
        {
            "label": "Referred by KD Edenfield",
            "value": 204371
        },
        {
            "label": "Referred by Kelley Petrey",
            "value": 154977
        },
        {
            "label": "Referred by Kendal Wahlert",
            "value": 158947
        },
        {
            "label": "Referred by Knockout",
            "value": 243184
        },
        {
            "label": "Referred by Kyle Bedwell",
            "value": 255965
        },
        {
            "label": "Referred by Kyle Bedwell ",
            "value": 172016
        },
        {
            "label": "Referred by Lauren Jones",
            "value": 165649
        },
        {
            "label": "Referred by Laurie Weber",
            "value": 192601
        },
        {
            "label": "Referred by Lindsey Wilson",
            "value": 157747
        },
        {
            "label": "Referred by Lori Brown",
            "value": 172617
        },
        {
            "label": "Referred by Luke Hayes",
            "value": 151129
        },
        {
            "label": "Referred by Mandie Duke",
            "value": 169765
        },
        {
            "label": "Referred by Marcus Walker",
            "value": 151125
        },
        {
            "label": "Referred by Maria Cazalas",
            "value": 182541
        },
        {
            "label": "Referred by Mary Megan Parker",
            "value": 210464
        },
        {
            "label": "Referred by Matt Baker",
            "value": 238033
        },
        {
            "label": "Referred by Matt Baker  ",
            "value": 236268
        },
        {
            "label": "Referred by Matt Hollis",
            "value": 175348
        },
        {
            "label": "Referred by Mike Carey",
            "value": 161380
        },
        {
            "label": "Referred by Miranda Maharrey",
            "value": 172225
        },
        {
            "label": "Referred by MissionFirst Mortgage",
            "value": 173695
        },
        {
            "label": "Referred by Morgan Vecsey",
            "value": 184834
        },
        {
            "label": "Referred by Owen Carraway",
            "value": 220027
        },
        {
            "label": "Referred by Pamela Butler",
            "value": 220718
        },
        {
            "label": "Referred by Polly Thumser",
            "value": 195611
        },
        {
            "label": "Referred by Rachel Hartzell",
            "value": 238621
        },
        {
            "label": "Referred by Rachel Reeves",
            "value": 174314
        },
        {
            "label": "Referred by Randon State Farm Office",
            "value": 151131
        },
        {
            "label": "Referred by Randy Branch",
            "value": 244763
        },
        {
            "label": "Referred by Randy Tarr",
            "value": 191065
        },
        {
            "label": "Referred by Renee Vincent",
            "value": 176673
        },
        {
            "label": "Referred by Rick Sharpe",
            "value": 150106
        },
        {
            "label": "Referred by Robby Farmer",
            "value": 235739
        },
        {
            "label": "Referred by Rob Hanner",
            "value": 181303
        },
        {
            "label": "Referred by Sarah Turner",
            "value": 152629
        },
        {
            "label": "Referred by Sheila Baggett",
            "value": 178106
        },
        {
            "label": "Referred by SSIU",
            "value": 241157
        },
        {
            "label": "Referred by Stacey Davis",
            "value": 174036
        },
        {
            "label": "Referred By Steve Pierce State Farm",
            "value": 163918
        },
        {
            "label": "Referred by Taylor Roussel",
            "value": 175347
        },
        {
            "label": "Referred by Terry Onderdonk",
            "value": 183736
        },
        {
            "label": "Referred by Tim Lower",
            "value": 185947
        },
        {
            "label": "Referred by Tina Jones",
            "value": 236638
        },
        {
            "label": "Referred by Tyler Morgan",
            "value": 185946
        },
        {
            "label": "Referred by Zack Cox",
            "value": 8899291
        },
        {
            "label": "Referred by Zack Kimerer",
            "value": 194092
        },
        {
            "label": "Renewal",
            "value": 209941
        },
        {
            "label": "Renewal Cross Sell",
            "value": 8836973
        },
        {
            "label": "Renewal Rewrite",
            "value": 170586
        },
        {
            "label": "Rewrite",
            "value": 150107
        },
        {
            "label": "Ricky Wahlert - Cross Country Mortgage ",
            "value": 8873299
        },
        {
            "label": "RODAISHA MOYE - Cornerstone Mortgage Solutions ",
            "value": 8847751
        },
        {
            "label": "Ross Sykes- Home Front Lending ",
            "value": 8882667
        },
        {
            "label": "Ruthie Thompson - Waters Edge ",
            "value": 8848042
        },
        {
            "label": "Ryley Corliss",
            "value": 8832485
        },
        {
            "label": "Sam Langham ",
            "value": 8839814
        },
        {
            "label": "SERTOMA Club",
            "value": 152631
        },
        {
            "label": "Shannon McWhorter",
            "value": 1503909
        },
        {
            "label": "Sheila Jones",
            "value": 8824545
        },
        {
            "label": "Stacy Sloan - Exit Realty ",
            "value": 8819720
        },
        {
            "label": "Stan Szapiel- Realtor ",
            "value": 8906432
        },
        {
            "label": "Stephanie Messinger - FirstBank",
            "value": 265989
        },
        {
            "label": "Steven Walling",
            "value": 165809
        },
        {
            "label": "STM",
            "value": 151692
        },
        {
            "label": "Tabatha Collier",
            "value": 166234
        },
        {
            "label": "Tamra Bryant",
            "value": 8853818
        },
        {
            "label": "Tanya Ratliff - Southstate Bank",
            "value": 265868
        },
        {
            "label": "Tara Mkwamba",
            "value": 8853903
        },
        {
            "label": "Tim Sherrill",
            "value": 8867441
        },
        {
            "label": "Todd Hull - Mobile Bay Realty",
            "value": 8832050
        },
        {
            "label": "Tonya Ralston ",
            "value": 153018
        },
        {
            "label": "Tracie Carter - Realtor",
            "value": 8878521
        },
        {
            "label": "Trail Cam Giveaway",
            "value": 159556
        },
        {
            "label": "Underwriting Issue",
            "value": 237377
        },
        {
            "label": "Walk in",
            "value": 8884551
        },
        {
            "label": "Warren Hopper - BXS Insurance",
            "value": 8829723
        },
        {
            "label": "WednesdayWiches",
            "value": 193178
        },
        {
            "label": "Will Chandler - Regions Mortgage",
            "value": 8845753
        },
        {
            "label": "Win back!  (Prior Client)",
            "value": 8877538
        },
        {
            "label": "Winback by AgencyZoom",
            "value": 150103
        },
        {
            "label": "Zach Davis ",
            "value": 8908101
        },
        {
            "label": "Zach Janes",
            "value": 8827492
        },
        {
            "label": "Zack Cox",
            "value": 153182
        },
        {
            "label": "Zack Videos ",
            "value": 8866049
        }
    ]
}

export const states = [
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['American Samoa', 'AS'],
    ['Arizona', 'AZ'],
    ['Arkansas', 'AR'],
    ['Armed Forces Americas', 'AA'],
    ['Armed Forces Europe', 'AE'],
    ['Armed Forces Pacific', 'AP'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['District Of Columbia', 'DC'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Guam', 'GU'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Marshall Islands', 'MH'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Northern Mariana Islands', 'NP'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Puerto Rico', 'PR'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['US Virgin Islands', 'VI'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
];


export const portal_insurance_history_sources =
    [
        {
            "id": 5,
            "applicant_full_name": "David  Waleri",
            "applicant_address": "954 Church St, Mobile, Alabama, 36604",
            "applicant_cell_phone": "+1 (419) 564 2009",
            "selected_raters": "ORION, ASI PROGRESSIVE, SWYFFT, SSIU, SAGESURE, OPENLY",
            "request_result_quotation_detail": [
                {
                    "rater": "SSIU",
                    "status": "SUCCESS",
                    "premium_amount": 1500,
                    "pdf_url": "aaaa",
                    "message": "",
                    "input_data_link": "https://adlsportalinsuranceprod.blob.core.windows.net/root/test/test.pdf?sp=r&st=2023-03-15T15:28:40Z&se=9999-03-15T23:28:40Z&spr=https&sv=2021-12-02&sr=b&sig=IfMWSU6jUw4TGM8vae4cLvYuRrF9n2C43on2uoMcWAg%3D"
                },
                {
                    "rater": "ORION",
                    "status": "SUCCESS",
                    "premium_amount": 1600,
                    "pdf_url": "sss",
                    "message": "",
                    "input_data_link": "https://adlsportalinsuranceprod.blob.core.windows.net/root/test/test2.pdf?sp=r&st=2023-03-15T15:29:33Z&se=9999-03-15T23:29:33Z&spr=https&sv=2021-12-02&sr=b&sig=Aw7cfWfYNIdtwK7LJAMfWcDU1i3sbEQG8CoMqk%2BGL94%3D"
                }
            ],
            "request_date": "03.11.2023",
            "requesting_user_full_name": "Celeste Downshire",
            "requesting_user_company": "Portal Insurance"
        },
        {
            "id": 6,
            "applicant_full_name": "Diego Molar",
            "applicant_address": "13 Cahaba St, Birmingham, Alabama, 43512",
            "applicant_cell_phone": "+1 (334) 563 4338",
            "selected_raters": "ORION, ASI PROGRESSIVE, SWYFFT, SSIU, SAGESURE, OPENLY",
            "request_result_quotation_detail": [
                {
                    "rater": "SSIU",
                    "status": "SUCCESS",
                    "premium_amount": 1500,
                    "pdf_url": "aaaa",
                    "message": "",
                    "input_data_link": "https://adlsportalinsuranceprod.blob.core.windows.net/root/test/test.pdf?sp=r&st=2023-03-15T15:28:40Z&se=9999-03-15T23:28:40Z&spr=https&sv=2021-12-02&sr=b&sig=IfMWSU6jUw4TGM8vae4cLvYuRrF9n2C43on2uoMcWAg%3D"
                },
                {
                    "rater": "ORION",
                    "status": "SUCCESS",
                    "premium_amount": 1600,
                    "pdf_url": "sss",
                    "message": "",
                    "input_data_link": "https://adlsportalinsuranceprod.blob.core.windows.net/root/test/test.pdf?sp=r&st=2023-03-15T15:28:40Z&se=9999-03-15T23:28:40Z&spr=https&sv=2021-12-02&sr=b&sig=IfMWSU6jUw4TGM8vae4cLvYuRrF9n2C43on2uoMcWAg%3D"
                },
                {
                    "rater": "SAGESURE",
                    "status": "SUCCESS",
                    "premium_amount": 1300,
                    "pdf_url": "sss",
                    "message": "",
                    "input_data_link": "https://adlsportalinsuranceprod.blob.core.windows.net/root/test/test2.pdf?sp=r&st=2023-03-15T15:29:33Z&se=9999-03-15T23:29:33Z&spr=https&sv=2021-12-02&sr=b&sig=Aw7cfWfYNIdtwK7LJAMfWcDU1i3sbEQG8CoMqk%2BGL94%3D"
                },
                {
                    "rater": "OPENLY",
                    "status": "SUCCESS",
                    "premium_amount": 2100,
                    "pdf_url": "sss",
                    "message": "",
                    "input_data_link": "https://adlsportalinsuranceprod.blob.core.windows.net/root/test/test2.pdf?sp=r&st=2023-03-15T15:29:33Z&se=9999-03-15T23:29:33Z&spr=https&sv=2021-12-02&sr=b&sig=Aw7cfWfYNIdtwK7LJAMfWcDU1i3sbEQG8CoMqk%2BGL94%3D"
                }
            ],
            "request_date": "03.15.2023",
            "requesting_user_full_name": "Katie Honor",
            "requesting_user_company": "Portal Insurance"
        },
        {
            "id": 7,
            "applicant_full_name": "Cristian Bilbou",
            "applicant_address": "101 E Beach Blv St, Alabama, 43512",
            "applicant_cell_phone": "+90 (545) 234 5465",
            "selected_raters": "ORION, ASI PROGRESSIVE, SWYFFT, SSIU, SAGESURE, OPENLY",
            "request_result_quotation_detail": [

                {
                    "rater": "ORION",
                    "status": "SUCCESS",
                    "premium_amount": 1400,
                    "pdf_url": "sss",
                    "message": ""
                },
                {
                    "rater": "SAGESURE",
                    "status": "SUCCESS",
                    "premium_amount": 2050,
                    "pdf_url": "sss",
                    "message": ""
                },
                {
                    "rater": "OPENLY",
                    "status": "SUCCESS",
                    "premium_amount": 1560,
                    "pdf_url": "sss",
                    "message": ""
                }
            ],
            "request_date": "03.03.2023",
            "requesting_user_full_name": "Lisa Kisova",
            "requesting_user_company": "Portal Insurance"
        }
    ]