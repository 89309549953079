import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './global_components/login';
import Dashboard from "./global_components/dashboard";
import AccountsPayable from "./global_components/accounts_payable";
import {Row, Col} from "react-bootstrap";
import React, {useState} from "react";
import Process from "./global_components/process";
import AppState from "./context/AppState"
import {Provider, useDispatch} from "react-redux";
import store from "./store/store";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import ProfilePage from "./global_components/profile_page";
import Company_management from "./global_components/company_management";

import VermostUserList from "./components/vermost/vermost_pages/account_management/vermost/user_list";
import VermostLawFirmList from "./components/vermost/vermost_pages/account_management/vermost/law_firm_list";
import VermostLawFirms from "./components/vermost/vermost_pages/account_management/vermost/law_firm";
import VermostLawFirmLocations from "./components/vermost/vermost_pages/account_management/vermost/law_firm_location";
import VermostLocationList from "./components/vermost/vermost_pages/account_management/vermost/location_list";
import VermostBondNumberList from "./components/vermost/vermost_pages/account_management/vermost/bond_number_list";
import VermostProfessionalGuardianList from "./components/vermost/vermost_pages/account_management/vermost/professional_guardian_list";

import CapitalProject from "./global_components/capital_project2";
import AddUser from "./global_components/add_user";
import AddVermostUser from "./components/vermost/vermost_pages/account_management/vermost/add_edit_user";

import jwt_decode from "jwt-decode";

import DocumentApproval from "./global_components/document_approval";
import BondApproval from "./global_components/bond_list";
import InsurancePortal from "./global_components/insurance_portal";
import BondCreateSteps from "./global_components/bond_create_steps";
import UserRegistration from "./components/vermost/vermost_pages/account_management/vermost/user_registeration";
import POHistory from "./components/oglebay/po_history";
import InsurancePortalHistory from "./global_components/insurance_portal_history";
import CapitalProjectCreate from './components/capital_project/CapitalProjectCreate';
import BrowserCacheClean from './global_components/browser_cache_clean';
import Main from "./components/oglebay/po/pages/main";
import POInvoices from "./components/fsi/fsi_list";
import BulkPostingResult from './components/fsi/bulk_posting_result';
import InvoiceDetail from "./components/fsi/fsi_invoice_detail";
import FSI from './components/fsi/fsi_dashboard';
import FsiAccountsPayable from './components/fsi/fsi_accounts_payable';
import FsiCheckrun from './components/fsi/fsi_checkrun';

import VermostLogin from './global_components/vermost_login';
import GuestUserBond from './components/vermost/vermost_pages/account_management/vermost/guest_user_bond';
import VermostDashboard from './global_components/vermost_dashboard';
import VermostApproveParalegal from './components/vermost/vermost_components/vermost_approve_paralegal';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from "react-notifications";
import {SnackbarProvider} from "notistack";
import AccountManagementHome from "./components/vermost/vermost_pages/account_management/vermost/account_management";



function App(effect, deps) {
    const [currentCategory, setCurrentCategory] = useState('');
    let persistor = persistStore(store);
    const checkToken = () => {
        const token = localStorage.getItem('userdata');
        if (token) {
            const decodedToken = jwt_decode(token);
            const current_time = new Date();
            if (decodedToken.exp * 1000 < current_time.getTime()) {
                localStorage.clear();
                window.location = "/";
            }
        }
    }
    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }} autoHideDuration={5000}>
                <PersistGate loading={null} persistor={persistor}>
                    <AppState>
                        <Row className="justify-content-md-center" onClick={() => checkToken()}>
                            <Row style={{width: "100%"}}>
                                <Col>
                                    <div className="Container">
                                        <BrowserRouter>
                                            <Routes>
                                                <Route exact path="/" element={<Login/>}/>
                                                <Route exact path="/vermost" element={<VermostLogin/>}/>
                                                <Route exact path="/vermost_guest_bond" element={<GuestUserBond/>}/>
                                                <Route exact path="/vermost_guest_dashboard" element={<VermostDashboard/>}/>
                                                <Route exact path="/paralegal_approval/:userID" element={<VermostApproveParalegal/>}/>
                                                <Route exact path="/main_po" element={<Main/>}/>
                                                <Route exact path="/main_po/invoices" element={<POInvoices/>}/>
                                                <Route exact path="/invoice/:id" element={<InvoiceDetail/>}/>
                                                <Route exact path="/dashboard"
                                                       element={<Dashboard setCategory={setCurrentCategory}/>}/>
                                                <Route exact path="/process"
                                                       element={<Process category={currentCategory}/>}/>
                                                <Route exact path="/account_payable" element={<AccountsPayable/>}/>
                                                <Route exact path="/profile" element={<ProfilePage/>}/>
                                                <Route exact path="/company_management"
                                                       element={<Company_management/>}/>
                                                <Route exact path="/account_management"
                                                       element={<AccountManagementHome/>}/>
                                                <Route exact path="/user_list" element={<VermostUserList/>}/>
                                                <Route exact path="/law_firm_list" element={<VermostLawFirmList/>}/>
                                                <Route exact path="/bond_number_list" element={<VermostBondNumberList/>}/>
                                                <Route exact path="/professional_guardian_list" element={<VermostProfessionalGuardianList/>}/>
                                                <Route exact path="/law_firm/:id" element={<VermostLawFirms/>}/>
                                                <Route exact path="/law_firm_location/:id/:lawId"
                                                       element={<VermostLawFirmLocations/>}/>
                                                <Route exact path="/add_user" element={<AddUser/>}/>
                                                <Route exact path="/user_management/:userID" element={<AddVermostUser/>}/>
                                                <Route exact path="/document_approval" element={<DocumentApproval/>}/>
                                                <Route exact path="/capital_project" element={<CapitalProject/>}/>
                                                <Route exact path="/capital_project_create/:id"
                                                       element={<CapitalProjectCreate/>}/>
                                                <Route exact path="/create_bond" element={<BondCreateSteps/>}/>
                                                <Route exact path="/bond_history" element={<BondApproval/>}/>
                                                <Route exact path="/insurance_portal" element={<InsurancePortal/>}/>
                                                <Route exact path="/insurance_portal_history"
                                                       element={<InsurancePortalHistory/>}/>
                                                <Route exact path="/register_vermost" element={<UserRegistration/>}/>
                                                <Route exact path="/po_history" element={<POHistory/>}/>
                                                <Route exact path="/browser_cache_clean"
                                                       element={<BrowserCacheClean/>}/>
                                                <Route exact path="/fsi-bulk-posting-result" element={<BulkPostingResult/>}/>
                                                <Route exact path="/fsi" element={<FSI/>}/>
                                                <Route exact path="/fsi-accounts-payable" element={<FsiAccountsPayable/>}/>
                                                <Route exact path="/fsi-checkrun-upload" element={<FsiCheckrun/>}/>
                                            </Routes>
                                            <NotificationContainer/>
                                        </BrowserRouter>
                                    </div>
                                </Col>
                            </Row>
                        </Row>
                    </AppState>
                </PersistGate>
            </SnackbarProvider>
        </Provider>
    );
}

export default App;
