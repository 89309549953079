import {API_URL} from "../constants";
import history from "../global_components/history";


export async function getAPICall(url) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}${url}`, {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });
    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200) {
        return await response.json();
    }
}

export async function getAPICallv2(url) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}${url}`, {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
        },
    });
    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    return response;

}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatUTCDate(date) {
    var d = new Date(date),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + d.getUTCDate(),
        year = d.getUTCFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateMDY(date) {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('-');
}

export function formatDateUTCMDY(date) {
    if (date === null || date === undefined) {
        console.log("UNDEFINED--- formatUTCDate:", " date,", date);
        return '';
    }
    var d = date.split('-');
    let year = d[0];
    let month = d[1];
    let day = d[2];

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('-');
}

export function getInitialValues(inputs) {
    const initialValues = {};
    inputs.forEach((input) => {
        if (input.input_type === "date") {
            initialValues[input.name] = formatDate(new Date());
        } else {
            initialValues[input.name] = "";
        }
    });
    return initialValues;
}

export function formatUTCDateTime(date) {
    let time = date.toString().substring(11);
    var d = new Date(date),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + d.getUTCDate(),
        year = d.getUTCFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    let format = [year, month, day].join('-') + " " + time.substring(0, 8)
    return format;
}

export function formatUTCDateTimeMDY(date) {
    let time = date.toString().substring(11);
    var d = new Date(date),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + d.getUTCDate(),
        year = d.getUTCFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    let format = [month, day, year].join('-') + " " + time.substring(0, 8)
    return format;
}
