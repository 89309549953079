import {Button, Modal} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import LawFirmMain from "../vermost_components/law_firm_main";
import React, {useState} from "react";
import LawFirmLocation from "../vermost_components/law_firm_location";
import {ConfirmationPopup} from "../../../utils/confirmation_popup";
import {useLocation, useNavigate} from "react-router";

export default function AddLawFirmLocationPopup(props) {
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const destroyLawFirm = async () => {
        await setOpenConfirmationPopup(false);
        props.closePopup();
    }
    const handleConfirmationPopup = async () => {
        await setOpenConfirmationPopup(false);
        await destroyLawFirm();
    }
    return (
        <>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.id === 0 ? "Create Law Firm Location" : "Edit Law Firm Location"}
                    </Modal.Title>

                    <Button variant={"warning"} onClick={(e) => {
                        e.preventDefault();
                        setOpenConfirmationPopup(true);
                    }}> <CloseIcon/></Button>

                </Modal.Header>
                <Modal.Body>
                    <LawFirmLocation data={props.data} id={props.id}
                                     oncallback={props.oncallback}></LawFirmLocation>
                </Modal.Body>
            </Modal>

            {openConfirmationPopup &&
                <ConfirmationPopup show={openConfirmationPopup}
                                   setOpenConfirmationPopup={setOpenConfirmationPopup}
                                   title="Do you want to cancel all inputs?"
                                   handleClose={handleConfirmationPopup}
                />
            }
        </>
    )
}