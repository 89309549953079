import React, {useEffect} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import "../../index.css";
import {
    Card,
    CardContent,
    Container,
} from "@mui/material";
import {ArrowRight, ArrowLeft} from 'react-bootstrap-icons';
import {Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomTextInput from "../../global_components/inputs/text";
import ZipCode from "../../global_components/inputs/zip_code";
import PatternFormat from "react-number-format";
import GoogleAutoComplete from "../../global_components/inputs/google-auto-complete";
import { guestData } from './guestData';

export default function PersonalRepresentatives({formIK, step, progress, changeStep}) {
    const processInput = guestData;
    const lineItemInputs = processInput.filter((input) => input.is_line_item === true);


    const validate = () => {
        let error = false;
        let errors = {personal_representative: []};
        for (let i = 0; i < formIK.values.number_of_personal_representative; i++) {
            if (formIK.values.personal_representative[i]['name'] === "" || formIK.values.personal_representative[i]['name'] === undefined) {
                if (errors['personal_representative'][i] === undefined) {
                    errors['personal_representative'][i] = {};
                }
                errors['personal_representative'][i]['name'] = 'Name of Personal Rep is required.';
                error = true;
            }
            
            const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (formIK.values.e_signed_indemnity === 'yes') {
            if (formIK.values.personal_representative[i]['email'] === "" || formIK.values.personal_representative[i]['email'] === undefined) {
                if (errors['personal_representative'][i] === undefined) {
                    errors['personal_representative'][i] = {};
                }
                errors['personal_representative'][i]['email'] = 'Email of Personal Rep is required.';
                error = true;
            } else if (formIK.values.personal_representative[i]['email'] !== "" && !re.test(formIK.values.personal_representative[i]['email'])) {
                if (errors['personal_representative'][i] === undefined) {
                    errors['personal_representative'][i] = {};
                }
                errors['personal_representative'][i]['email'] = 'Invalid email of Personal Rep.';
                error = true;
            }
        } else {
            if (formIK.values.personal_representative[i]['email'] !== "" && !re.test(formIK.values.personal_representative[i]['email'])) {
                if (errors['personal_representative'][i] === undefined) {
                    errors['personal_representative'][i] = {};
                }
                errors['personal_representative'][i]['email'] = 'Invalid email of Personal Rep.';
                error = true;
            }
        }

            if (formIK.values.personal_representative[i]['is_us_citizen'] === "" || formIK.values.personal_representative[i]['is_us_citizen'] === undefined) {
                if (errors['personal_representative'][i] === undefined) {
                    errors['personal_representative'][i] = {};
                }
                errors['personal_representative'][i]['is_us_citizen'] = 'US citizenship status of Personal Rep is required.';
                error = true;
            }
            if (formIK.values.personal_representative[i]['address'] === "" || formIK.values.personal_representative[i]['address'] === undefined) {
                if (errors['personal_representative'][i] === undefined) {
                    errors['personal_representative'][i] = {};
                }
                errors['personal_representative'][i]['address'] = 'Street Address of Personal Rep is required.';
                error = true;
            }
            if (formIK.values.personal_representative[i]['is_us_citizen'] === 'yes') {
                if (formIK.values.personal_representative[i]['city'] === "" || formIK.values.personal_representative[i]['city'] === undefined) {
                    if (errors['personal_representative'][i] === undefined) {
                        errors['personal_representative'][i] = {};
                    }
                    errors['personal_representative'][i]['city'] = 'City/Town for Personal Rep is required.';
                    error = true;
                }
                if (formIK.values.personal_representative[i]['state'] === "" || formIK.values.personal_representative[i]['state'] === undefined) {
                    if (errors['personal_representative'][i] === undefined) {
                        errors['personal_representative'][i] = {};
                    }
                    errors['personal_representative'][i]['state'] = 'State/Province/Region for Personal Rep is required.';
                    error = true;
                }
                if (formIK.values.bond_amount >= 250000) {
                    var re_ssn = new RegExp("^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$");
                    if (formIK.values.personal_representative[i]['ssn'] === "" || formIK.values.personal_representative[i]['ssn'] === undefined || formIK.values.personal_representative[i]['ssn'] === '###-##-####') {
                        if (errors['personal_representative'][i] === undefined) {
                            errors['personal_representative'][i] = {};
                        }
                        errors['personal_representative'][i]['ssn'] = 'SSN for Personal Rep is required.';
                        error = true;
                    }
                    // else if (!re_ssn.test(formIK.values.personal_representative[i]['ssn'])) {
                    //     errors['personal_representative'][i] = {};
                    //     errors['personal_representative'][i]['ssn'] = 'SSN must match this format 123-12-1234.';
                    //     error = true;
                    // }
                }
                if (formIK.values.personal_representative[i]['zip_code'] === "" || formIK.values.personal_representative[i]['zip_code'] === undefined) {
                    if (errors['personal_representative'][i] === undefined) {
                        errors['personal_representative'][i] = {};
                    }
                    errors['personal_representative'][i]['zip_code'] = 'Zip/Postal Code of Personal Rep is required.';
                    error = true;
                }
            } else {
                if (formIK.values.personal_representative[i]['citizenship'] === "" || formIK.values.personal_representative[i]['citizenship'] === undefined) {
                    if (errors['personal_representative'][i] === undefined) {
                        errors['personal_representative'][i] = {};
                    }
                    errors['personal_representative'][i]['citizenship'] = 'Citizenship for Personal Rep is required.';
                    error = true;
                }
                if (formIK.values.personal_representative[i]['citizenship'] === "Others") {
                    if (formIK.values.personal_representative[i]['other_country'] === "" || formIK.values.personal_representative[i]['other_country'] === undefined) {
                        if (errors['personal_representative'][i] === undefined) {
                            errors['personal_representative'][i] = {};
                        }
                        errors['personal_representative'][i]['other_country'] = 'Country for Personal Rep is required.';
                        error = true;
                    }
                }
            }
            // if(formIK.values.bond_amount > 50000){
            //     if(formIK.values.personal_representative[i]['occupation'] === "" || formIK.values.personal_representative[i]['occupation'] === undefined){
            //         if(errors['personal_representative'][i] === undefined){
            //             errors['personal_representative'][i] = {};
            //         }
            //         errors['personal_representative'][i]['occupation'] = 'Occupation of Personal Rep is required.';
            //         error = true;
            //     }
            // }
        }

        console.log(error);
        console.log(errors);

        if (error === true) {
            formIK.setErrors(errors);
            window.scroll(0, 0);
            console.log(formIK.errors)
        } else {
            changeStep(progress + step);
        }

    }

    useEffect(() => {

    });

    const addressAutoFillCallBack = (address, city, state, zip_code, prIndex) => {
        formIK.setFieldValue("personal_representative[" + prIndex + "].address", address);
        formIK.setFieldValue("personal_representative[" + prIndex + "].city", city);
        formIK.setFieldValue("personal_representative[" + prIndex + "].state", state);
        formIK.setFieldValue("personal_representative[" + prIndex + "].zip_code", zip_code);
        formIK.setFieldValue("personal_representative[" + prIndex + "].is_us_citizen", 'no');
        formIK.setFieldValue("personal_representative[" + prIndex + "].is_us_citizen", 'yes');
    }

    console.log(formIK.values.personal_representative);
    return (
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{maxWidth: formIK.values.number_of_personal_representative === 1 ? "50%" : "100%"}}
                              style={{marginBottom: "2%", paddingBottom: "10%"}}>
                            <CardContent>
                                <h4>Personal Representatives</h4>
                                
                                <Row>
                                    <Col style={formIK.values.number_of_personal_representative > 1 ? {
                                        borderRight: "1px solid black",
                                        borderBottom: "1px solid black",
                                        paddingBottom: "1%"
                                    } : {}}>
                                        <h5 style={{textAlign: "center"}}>Personal Representative 1</h5>
                                        <Form.Group key={"name-" + 0} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Name of Personal Rep</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true}
                                                             name={"personal_representative[" + 0 + "].name"}
                                                             className={"form-control"}
                                                             id={"personal_representative[" + 0 + "].name-input"}
                                                             value={formIK.values.personal_representative[0]['name']}
                                                             onChange={(value) => {
                                                                 formIK.setFieldValue("personal_representative[" + 0 + "].name", value);
                                                             }}/>
                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                <div
                                                    className="text-error">{formIK.errors["personal_representative"][0]['name']}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key={"email-" + 0} className="col-12" controlId="formBasicEmail"
            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
    <Form.Label className={formIK.values.e_signed_indemnity === 'yes' ? 'required-label' : ''}>
        <strong>Email</strong>
    </Form.Label>
    <CustomTextInput disabled={false} required={formIK.values.e_signed_indemnity === 'yes'}
                     name={"personal_representative[" + 0 + "].email"}
                     className={"form-control"}
                     id={"personal_representative[" + 0 + "].email-input"}
                     value={formIK.values.personal_representative[0]['email']}
                     onChange={(value) => {
                         formIK.setFieldValue("personal_representative[" + 0 + "].email", value);
                     }}/>
    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
        <div className="text-error">{formIK.errors["personal_representative"][0]['email']}</div>
    )}
</Form.Group>
                                        <Form.Group key={"is_us_citizen-" + 0} className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Is US
                                                Citizen</strong></Form.Label>
                                            <CustomSelectInput disabled={false}
                                                               name={"personal_representative[" + 0 + "].is_us_citizen"}
                                                               value={formIK.values.personal_representative[0]['is_us_citizen']}
                                                               onChange={(value) => {
                                                                   console.log(value)
                                                                   formIK.setFieldValue("personal_representative[" + 0 + "].is_us_citizen", value);
                                                                   if (value === 'no') {
                                                                       formIK.setFieldValue("personal_representative[" + 0 + "].city", "");
                                                                       formIK.setFieldValue("personal_representative[" + 0 + "].state", "");
                                                                   } else {
                                                                       formIK.setFieldValue("personal_representative[" + 0 + "].citizenship", "");
                                                                   }
                                                               }}
                                                               options={lineItemInputs.filter((li) => li.name === 'is_us_citizen')[0].options}/>
                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                <div
                                                    className="text-error">{formIK.errors["personal_representative"][0]['is_us_citizen']}</div>
                                            )}
                                        </Form.Group>
                                        {
                                            formIK.values["personal_representative"][0]['is_us_citizen'] === 'yes' && (
                                                <Row style={{marginTop: "2%", padding: "0px 5% 0px 2.5%"}}>
                                                    <GoogleAutoComplete placeholder="Search Address..."
                                                                        callback={(address, city, state, zip_code) => {
                                                                            addressAutoFillCallBack(address, city, state, zip_code, 0);
                                                                        }}/>
                                                </Row>
                                            )
                                        }
                                        <Form.Group key={"address-" + 0} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Street Address for Personal
                                                Rep</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true}
                                                             name={"personal_representative[" + 0 + "].address"}
                                                             className={"form-control"}
                                                             id={"personal_representative[" + 0 + "].address-input"}
                                                             value={formIK.values["personal_representative"][0]['address']}
                                                             onChange={(value) => {
                                                                 formIK.setFieldValue("personal_representative[" + 0 + "].address", value);
                                                             }}/>
                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                <div
                                                    className="text-error">{formIK.errors["personal_representative"][0]['address']}</div>
                                            )}
                                        </Form.Group>
                                        {
                                            formIK.values["personal_representative"][0]['is_us_citizen'] === 'yes' &&
                                            <Form.Group key={"city-" + 0} className="col-12"
                                                        controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label className='required-label'><strong>City/Town for Personal
                                                    Rep</strong></Form.Label>
                                                <CustomTextInput disabled={false} required={true}
                                                                 name={"personal_representative[" + 0 + "].city"}
                                                                 className={"form-control"}
                                                                 id={"personal_representative[" + 0 + "].city-input"}
                                                                 value={formIK.values["personal_representative"][0]['city']}
                                                                 onChange={(value) => {
                                                                     formIK.setFieldValue("personal_representative[" + 0 + "].city", value);
                                                                 }}/>
                                                {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                    <div
                                                        className="text-error">{formIK.errors["personal_representative"][0]['city']}</div>
                                                )}
                                            </Form.Group>
                                        }
                                        {
                                            formIK.values["personal_representative"][0]['is_us_citizen'] === 'yes' &&
                                            <Form.Group key={"state-" + 0} className="col-12"
                                                        controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label
                                                    className='required-label'><strong>State/Province/Region</strong></Form.Label>
                                                <CustomSelectInput disabled={false}
                                                                   name={"personal_representative[" + 0 + "].state"}
                                                                   value={formIK.values["personal_representative"][0]['state']}
                                                                   onChange={(value) => {
                                                                       formIK.setFieldValue("personal_representative[" + 0 + "].state", value);
                                                                   }}
                                                                   options={lineItemInputs.filter((li) => li.name === 'state')[0].options}/>
                                                {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                    <div
                                                        className="text-error">{formIK.errors["personal_representative"][0]['state']}</div>
                                                )}
                                            </Form.Group>
                                        }
                                        {
                                            formIK.values["personal_representative"][0]['is_us_citizen'] === 'yes' &&
                                            <Form.Group key={"zip_code-" + 0} className="col-12"
                                                        controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label className='required-label'><strong>ZIP/Postal Code</strong></Form.Label>
                                                <ZipCode disabled={false} required={true} name="zip_code"
                                                         className={"form-control"} id="zip_code-input"
                                                         value={formIK.values["personal_representative"][0]['zip_code']}
                                                         onChange={(value) => {
                                                             formIK.setFieldValue("personal_representative[" + 0 + "].zip_code", value);
                                                         }}/>
                                                {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                    <div
                                                        className="text-error">{formIK.errors["personal_representative"][0]['zip_code']}</div>
                                                )}
                                            </Form.Group>
                                        }
                                        {
                                            formIK.values["personal_representative"][0]['is_us_citizen'] === 'no' && (
                                                <Form.Group key={"citizenship-" + 0} className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>If NO, what is their
                                                        citizenship or primary residency?</strong></Form.Label>
                                                    <CustomSelectInput disabled={false}
                                                                       name={"personal_representative[" + 0 + "].citizenship"}
                                                                       value={formIK.values["personal_representative"][0]['citizenship']}
                                                                       onChange={(value) => {
                                                                           formIK.setFieldValue("personal_representative[" + 0 + "].citizenship", value);
                                                                       }}
                                                                       options={lineItemInputs.filter((li) => li.name === 'citizenship')[0].options}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][0]['citizenship']}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            formIK.values["personal_representative"][0]['citizenship'] === 'Others' && (
                                                <Form.Group key={"other_country-" + 0} className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Other Country for
                                                        Personal Rep</strong></Form.Label>
                                                    <CustomSelectInput disabled={false}
                                                                       name={"personal_representative[" + 0 + "].other_country"}
                                                                       value={formIK.values["personal_representative"][0]['other_country']}
                                                                       onChange={(value) => {
                                                                           formIK.setFieldValue("personal_representative[" + 0 + "].other_country", value);
                                                                       }}
                                                                       options={lineItemInputs.filter((li) => li.name === 'other_country')[0].options}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][0]['other_country']}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (formIK.values["personal_representative"][0]['is_us_citizen'] === 'yes' && formIK.values['bond_amount'] >= 250000) && (
                                                <Form.Group key={"ssn-" + 0} className="col-12" controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>If YES to US Citizen,
                                                        what is their Social Security Number?</strong></Form.Label>
                                                    <PatternFormat className={"form-control"}
                                                                   name={"personal_representative[" + 0 + "].ssn"}
                                                                   id={"ssn-input-" + 0}
                                                                   defaultValue={formIK.values["personal_representative"][0]['ssn']}
                                                                   format="###-##-####" allowEmptyFormatting mask="#"
                                                                   disabled={false} onChange={(value) => {
                                                        formIK.setFieldValue("personal_representative[" + 0 + "].ssn", value.target.value);
                                                    }}/>
                                                    {(formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined) ? (
                                                            <div
                                                                className="text-error">{formIK.errors["personal_representative"][0]['ssn']}</div>
                                                        ) :
                                                        <div
                                                            className="text-warning">Warning! SSN must match this format
                                                            123-12-1234.</div>
                                                    }
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            formIK.values['bond_amount'] > 50000 && (
                                                <Form.Group key={"occupation-" + 0} className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label><strong>Occupation of Personal Rep</strong></Form.Label>
                                                    <CustomTextInput disabled={false} required={false}
                                                                     name={"personal_representative[" + 0 + "].occupation"}
                                                                     className={"form-control"}
                                                                     id={"personal_representative[" + 0 + "].occupation-input"}
                                                                     value={formIK.values["personal_representative"][0]['occupation']}
                                                                     onChange={(value) => {
                                                                         formIK.setFieldValue("personal_representative[" + 0 + "].occupation", value);
                                                                     }}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 1 && formIK.errors["personal_representative"][0] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][0]['occupation']}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                    </Col>
                                    {
                                        formIK.values.number_of_personal_representative > 1 && (
                                            <Col style={formIK.values.number_of_personal_representative > 2 ? {
                                                borderBottom: "1px solid black",
                                                paddingBottom: "1%"
                                            } : {}}>
                                                <h5 style={{textAlign: "center"}}>Personal Representative 2</h5>
                                                <Form.Group key={"name-" + 2} className="col-12" controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Name of Personal
                                                        Rep</strong></Form.Label>
                                                    <CustomTextInput disabled={false} required={true}
                                                                     name={"personal_representative[" + 1 + "].name"}
                                                                     className={"form-control"}
                                                                     id={"personal_representative[" + 1 + "].name-input"}
                                                                     value={formIK.values.personal_representative[1]['name']}
                                                                     onChange={(value) => {
                                                                         formIK.setFieldValue("personal_representative[" + 1 + "].name", value);
                                                                     }}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][1]['name']}</div>
                                                    )}
                                                </Form.Group>
                                                {
                                                    formIK.values.e_signed_indemnity === 'yes' && (
                                                        <Form.Group key={"email-" + 1} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label
                                                                className='required-label'><strong>Email</strong></Form.Label>
                                                            <CustomTextInput disabled={false} required={true}
                                                                             name={"personal_representative[" + 1 + "].email"}
                                                                             className={"form-control"}
                                                                             id={"personal_representative[" + 1 + "].email-input"}
                                                                             value={formIK.values.personal_representative[1]['email']}
                                                                             onChange={(value) => {
                                                                                 formIK.setFieldValue("personal_representative[" + 1 + "].email", value);
                                                                             }}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][1]['email']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                <Form.Group key={"is_us_citizen-" + 1} className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Is US
                                                        Citizen</strong></Form.Label>
                                                    <CustomSelectInput disabled={false}
                                                                       name={"personal_representative[" + 1 + "].is_us_citizen"}
                                                                       value={formIK.values.personal_representative[1]['is_us_citizen']}
                                                                       onChange={(value) => {
                                                                           formIK.setFieldValue("personal_representative[" + 1 + "].is_us_citizen", value);
                                                                           if (value === 'no') {
                                                                               formIK.setFieldValue("personal_representative[" + 1 + "].city", "");
                                                                               formIK.setFieldValue("personal_representative[" + 1 + "].state", "");
                                                                           } else {
                                                                               formIK.setFieldValue("personal_representative[" + 1 + "].citizenship", "");
                                                                           }
                                                                       }}
                                                                       options={lineItemInputs.filter((li) => li.name === 'is_us_citizen')[0].options}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][1]['is_us_citizen']}</div>
                                                    )}
                                                </Form.Group>
                                                {
                                                    formIK.values["personal_representative"][1]['is_us_citizen'] === 'yes' && (
                                                        <Row style={{marginTop: "2%", padding: "0px 5% 0px 2.5%"}}>
                                                            <GoogleAutoComplete placeholder="Search Address..."
                                                                                callback={(address, city, state, zip_code) => {
                                                                                    addressAutoFillCallBack(address, city, state, zip_code, 1);
                                                                                }}/>
                                                        </Row>
                                                    )
                                                }
                                                <Form.Group key={"address-" + 1} className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Street Address for
                                                        Personal Rep</strong></Form.Label>
                                                    <CustomTextInput disabled={false} required={true}
                                                                     name={"personal_representative[" + 1 + "].address"}
                                                                     className={"form-control"}
                                                                     id={"personal_representative[" + 1 + "].address-input"}
                                                                     value={formIK.values["personal_representative"][1]['address']}
                                                                     onChange={(value) => {
                                                                         formIK.setFieldValue("personal_representative[" + 1 + "].address", value);
                                                                     }}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][1]['address']}</div>
                                                    )}
                                                </Form.Group>
                                                {
                                                    formIK.values["personal_representative"][1]['is_us_citizen'] === 'yes' &&
                                                    <Form.Group key={"city-" + 1} className="col-12"
                                                                controlId="formBasicEmail"
                                                                style={{
                                                                    display: "inline-grid",
                                                                    padding: "0px 15px 0px 0px"
                                                                }}>
                                                        <Form.Label className='required-label'><strong>City/Town for
                                                            Personal
                                                            Rep</strong></Form.Label>
                                                        <CustomTextInput disabled={false} required={true}
                                                                         name={"personal_representative[" + 1 + "].city"}
                                                                         className={"form-control"}
                                                                         id={"personal_representative[" + 1 + "].city-input"}
                                                                         value={formIK.values["personal_representative"][1]['city']}
                                                                         onChange={(value) => {
                                                                             formIK.setFieldValue("personal_representative[" + 1 + "].city", value);
                                                                         }}/>
                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                            <div
                                                                className="text-error">{formIK.errors["personal_representative"][1]['city']}</div>
                                                        )}
                                                    </Form.Group>
                                                }
                                                {
                                                    formIK.values["personal_representative"][1]['is_us_citizen'] === 'yes' &&
                                                    <Form.Group key={"state-" + 1} className="col-12"
                                                                controlId="formBasicEmail"
                                                                style={{
                                                                    display: "inline-grid",
                                                                    padding: "0px 15px 0px 0px"
                                                                }}>
                                                        <Form.Label
                                                            className='required-label'><strong>State/Province/Region</strong></Form.Label>
                                                        <CustomSelectInput disabled={false}
                                                                           name={"personal_representative[" + 1 + "].state"}
                                                                           value={formIK.values["personal_representative"][1]['state']}
                                                                           onChange={(value) => {
                                                                               formIK.setFieldValue("personal_representative[" + 1 + "].state", value);
                                                                           }}
                                                                           options={lineItemInputs.filter((li) => li.name === 'state')[0].options}/>
                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                            <div
                                                                className="text-error">{formIK.errors["personal_representative"][1]['state']}</div>
                                                        )}
                                                    </Form.Group>
                                                }
                                                {
                                                    formIK.values["personal_representative"][1]['is_us_citizen'] === 'yes' &&
                                                    <Form.Group key={"zip_code-" + 1} className="col-12"
                                                                controlId="formBasicEmail"
                                                                style={{
                                                                    display: "inline-grid",
                                                                    padding: "0px 15px 0px 0px"
                                                                }}>
                                                        <Form.Label className='required-label'><strong>ZIP/Postal
                                                            Code</strong></Form.Label>
                                                        <ZipCode disabled={false} required={true} name="zip_code"
                                                                 className={"form-control"} id="zip_code-input"
                                                                 value={formIK.values["personal_representative"][1]['zip_code']}
                                                                 onChange={(value) => {
                                                                     formIK.setFieldValue("personal_representative[" + 1 + "].zip_code", value);
                                                                 }}/>
                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                            <div
                                                                className="text-error">{formIK.errors["personal_representative"][1]['zip_code']}</div>
                                                        )}
                                                    </Form.Group>
                                                }
                                                {
                                                    formIK.values["personal_representative"][1]['is_us_citizen'] === 'no' && (
                                                        <Form.Group key={"citizenship-" + 1} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label className='required-label'><strong>If NO, what is their
                                                                citizenship or primary residency?</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name={"personal_representative[" + 1 + "].citizenship"}
                                                                               value={formIK.values["personal_representative"][1]['citizenship']}
                                                                               onChange={(value) => {
                                                                                   formIK.setFieldValue("personal_representative[" + 1 + "].citizenship", value);
                                                                               }}
                                                                               options={lineItemInputs.filter((li) => li.name === 'citizenship')[0].options}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][1]['citizenship']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                {
                                                    formIK.values["personal_representative"][1]['citizenship'] === 'Others' && (
                                                        <Form.Group key={"other_country-" + 1} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label className='required-label'><strong>Other Country for
                                                                Personal Rep</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name={"personal_representative[" + 1 + "].other_country"}
                                                                               value={formIK.values["personal_representative"][1]['other_country']}
                                                                               onChange={(value) => {
                                                                                   formIK.setFieldValue("personal_representative[" + 1 + "].other_country", value);
                                                                               }}
                                                                               options={lineItemInputs.filter((li) => li.name === 'other_country')[0].options}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][1]['other_country']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                {
                                                    (formIK.values["personal_representative"][1]['is_us_citizen'] === 'yes' && formIK.values['bond_amount'] >= 250000) && (
                                                        <Form.Group key={"ssn-" + 1} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label className='required-label'><strong>If YES to US Citizen,
                                                                what is their Social Security Number?</strong></Form.Label>
                                                            <PatternFormat className={"form-control"}
                                                                           name={"personal_representative[" + 1 + "].ssn"}
                                                                           id={"ssn-input-" + 1}
                                                                           defaultValue={formIK.values["personal_representative"][1]['ssn']}
                                                                           format="###-##-####" allowEmptyFormatting mask="#"
                                                                           disabled={false} onChange={(value) => {
                                                                formIK.setFieldValue("personal_representative[" + 1 + "].ssn", value.target.value);
                                                            }}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][1]['ssn']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                {
                                                    formIK.values['bond_amount'] > 50000 && (
                                                        <Form.Group key={"occupation-" + 1} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label><strong>Occupation of Personal Rep</strong></Form.Label>
                                                            <CustomTextInput disabled={false} required={false}
                                                                             name={"personal_representative[" + 1 + "].occupation"}
                                                                             className={"form-control"}
                                                                             id={"personal_representative[" + 1 + "].occupation-input"}
                                                                             value={formIK.values["personal_representative"][1]['occupation']}
                                                                             onChange={(value) => {
                                                                                 formIK.setFieldValue("personal_representative[" + 1 + "].occupation", value);
                                                                             }}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 2 && formIK.errors["personal_representative"][1] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][1]['occupation']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                            </Col>
                                        )
                                    }
                                </Row>
                                {
                                    formIK.values.number_of_personal_representative > 2 && (
                                        <Row>
                                            <Col style={{borderRight: "1px solid black"}}>
                                                <h5 style={{textAlign: "center"}}>Personal Representative 3</h5>
                                                <Form.Group key={"name-" + 3} className="col-12" controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Name of Personal
                                                        Rep</strong></Form.Label>
                                                    <CustomTextInput disabled={false} required={true}
                                                                     name={"personal_representative[" + 2 + "].name"}
                                                                     className={"form-control"}
                                                                     id={"personal_representative[" + 2 + "].name-input"}
                                                                     value={formIK.values.personal_representative[2]['name']}
                                                                     onChange={(value) => {
                                                                         formIK.setFieldValue("personal_representative[" + 2 + "].name", value);
                                                                     }}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][2]['name']}</div>
                                                    )}
                                                </Form.Group>
                                                {
                                                    formIK.values.e_signed_indemnity === 'yes' && (
                                                        <Form.Group key={"email-" + 2} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label
                                                                className='required-label'><strong>Email</strong></Form.Label>
                                                            <CustomTextInput disabled={false} required={true}
                                                                             name={"personal_representative[" + 2 + "].email"}
                                                                             className={"form-control"}
                                                                             id={"personal_representative[" + 2 + "].email-input"}
                                                                             value={formIK.values.personal_representative[2]['email']}
                                                                             onChange={(value) => {
                                                                                 formIK.setFieldValue("personal_representative[" + 2 + "].email", value);
                                                                             }}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][2]['email']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                <Form.Group key={"is_us_citizen-" + 2} className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Is US
                                                        Citizen</strong></Form.Label>
                                                    <CustomSelectInput disabled={false}
                                                                       name={"personal_representative[" + 2 + "].is_us_citizen"}
                                                                       value={formIK.values.personal_representative[2]['is_us_citizen']}
                                                                       onChange={(value) => {
                                                                           formIK.setFieldValue("personal_representative[" + 2 + "].is_us_citizen", value);
                                                                           if (value === 'no') {
                                                                               formIK.setFieldValue("personal_representative[" + 2 + "].city", "");
                                                                               formIK.setFieldValue("personal_representative[" + 2 + "].state", "");
                                                                           } else {
                                                                               formIK.setFieldValue("personal_representative[" + 2 + "].citizenship", "");
                                                                           }
                                                                       }}
                                                                       options={lineItemInputs.filter((li) => li.name === 'is_us_citizen')[0].options}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][2]['is_us_citizen']}</div>
                                                    )}
                                                </Form.Group>
                                                {
                                                    formIK.values["personal_representative"][2]['is_us_citizen'] === 'yes' && (
                                                        <Row style={{marginTop: "2%", padding: "0px 5% 0px 2.5%"}}>
                                                            <GoogleAutoComplete placeholder="Search Address..."
                                                                                callback={(address, city, state, zip_code) => {
                                                                                    addressAutoFillCallBack(address, city, state, zip_code, 2);
                                                                                }}/>
                                                        </Row>
                                                    )
                                                }
                                                <Form.Group key={"address-" + 2} className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Street Address for
                                                        Personal Rep</strong></Form.Label>
                                                    <CustomTextInput disabled={false} required={true}
                                                                     name={"personal_representative[" + 2 + "].address"}
                                                                     className={"form-control"}
                                                                     id={"personal_representative[" + 2 + "].address-input"}
                                                                     value={formIK.values["personal_representative"][2]['address']}
                                                                     onChange={(value) => {
                                                                         formIK.setFieldValue("personal_representative[" + 2 + "].address", value);
                                                                     }}/>
                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                        <div
                                                            className="text-error">{formIK.errors["personal_representative"][2]['address']}</div>
                                                    )}
                                                </Form.Group>
                                                {
                                                    formIK.values["personal_representative"][2]['is_us_citizen'] === 'yes' &&
                                                    <Form.Group key={"city-" + 2} className="col-12"
                                                                controlId="formBasicEmail"
                                                                style={{
                                                                    display: "inline-grid",
                                                                    padding: "0px 15px 0px 0px"
                                                                }}>
                                                        <Form.Label className='required-label'><strong>City/Town for
                                                            Personal
                                                            Rep</strong></Form.Label>
                                                        <CustomTextInput disabled={false} required={true}
                                                                         name={"personal_representative[" + 2 + "].city"}
                                                                         className={"form-control"}
                                                                         id={"personal_representative[" + 2 + "].city-input"}
                                                                         value={formIK.values["personal_representative"][2]['city']}
                                                                         onChange={(value) => {
                                                                             formIK.setFieldValue("personal_representative[" + 2 + "].city", value);
                                                                         }}/>
                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                            <div
                                                                className="text-error">{formIK.errors["personal_representative"][2]['city']}</div>
                                                        )}
                                                    </Form.Group>
                                                }
                                                {
                                                    formIK.values["personal_representative"][2]['is_us_citizen'] === 'yes' &&
                                                    <Form.Group key={"state-" + 2} className="col-12"
                                                                controlId="formBasicEmail"
                                                                style={{
                                                                    display: "inline-grid",
                                                                    padding: "0px 15px 0px 0px"
                                                                }}>
                                                        <Form.Label
                                                            className='required-label'><strong>State/Province/Region</strong></Form.Label>
                                                        <CustomSelectInput disabled={false}
                                                                           name={"personal_representative[" + 2 + "].state"}
                                                                           value={formIK.values["personal_representative"][2]['state']}
                                                                           onChange={(value) => {
                                                                               formIK.setFieldValue("personal_representative[" + 2 + "].state", value);
                                                                           }}
                                                                           options={lineItemInputs.filter((li) => li.name === 'state')[0].options}/>
                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                            <div
                                                                className="text-error">{formIK.errors["personal_representative"][2]['state']}</div>
                                                        )}
                                                    </Form.Group>
                                                }
                                                {
                                                    formIK.values["personal_representative"][2]['is_us_citizen'] === 'yes' &&
                                                    <Form.Group key={"zip_code-" + 2} className="col-12"
                                                                controlId="formBasicEmail"
                                                                style={{
                                                                    display: "inline-grid",
                                                                    padding: "0px 15px 0px 0px"
                                                                }}>
                                                        <Form.Label className='required-label'><strong>ZIP/Postal
                                                            Code</strong></Form.Label>
                                                        <ZipCode disabled={false} required={true} name="zip_code"
                                                                 className={"form-control"} id="zip_code-input"
                                                                 value={formIK.values["personal_representative"][2]['zip_code']}
                                                                 onChange={(value) => {
                                                                     formIK.setFieldValue("personal_representative[" + 2 + "].zip_code", value);
                                                                 }}/>
                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                            <div
                                                                className="text-error">{formIK.errors["personal_representative"][2]['zip_code']}</div>
                                                        )}
                                                    </Form.Group>
                                                }
                                                {
                                                    formIK.values["personal_representative"][2]['is_us_citizen'] === 'no' && (
                                                        <Form.Group key={"citizenship-" + 2} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label className='required-label'><strong>If NO, what is their
                                                                citizenship or primary residency?</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name={"personal_representative[" + 2 + "].citizenship"}
                                                                               value={formIK.values["personal_representative"][2]['citizenship']}
                                                                               onChange={(value) => {
                                                                                   formIK.setFieldValue("personal_representative[" + 2 + "].citizenship", value);
                                                                               }}
                                                                               options={lineItemInputs.filter((li) => li.name === 'citizenship')[0].options}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][2]['citizenship']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                {
                                                    formIK.values["personal_representative"][2]['citizenship'] === 'Others' && (
                                                        <Form.Group key={"other_country-" + 2} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label className='required-label'><strong>Other Country for
                                                                Personal Rep</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name={"personal_representative[" + 2 + "].other_country"}
                                                                               value={formIK.values["personal_representative"][2]['other_country']}
                                                                               onChange={(value) => {
                                                                                   formIK.setFieldValue("personal_representative[" + 2 + "].other_country", value);
                                                                               }}
                                                                               options={lineItemInputs.filter((li) => li.name === 'other_country')[0].options}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][2]['other_country']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                {
                                                    (formIK.values["personal_representative"][2]['is_us_citizen'] === 'yes' && formIK.values['bond_amount'] >= 250000) && (
                                                        <Form.Group key={"ssn-" + 2} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label className='required-label'><strong>If YES to US Citizen,
                                                                what is their Social Security Number?</strong></Form.Label>
                                                            <PatternFormat className={"form-control"}
                                                                           name={"personal_representative[" + 2 + "].ssn"}
                                                                           id={"ssn-input-" + 2}
                                                                           defaultValue={formIK.values["personal_representative"][2]['ssn']}
                                                                           format="###-##-####" allowEmptyFormatting mask="#"
                                                                           disabled={false} onChange={(value) => {
                                                                formIK.setFieldValue("personal_representative[" + 2 + "].ssn", value.target.value);
                                                            }}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][2]['ssn']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                {
                                                    formIK.values['bond_amount'] > 50000 && (
                                                        <Form.Group key={"occupation-" + 2} className="col-12"
                                                                    controlId="formBasicEmail"
                                                                    style={{
                                                                        display: "inline-grid",
                                                                        padding: "0px 15px 0px 0px"
                                                                    }}>
                                                            <Form.Label><strong>Occupation of Personal Rep</strong></Form.Label>
                                                            <CustomTextInput disabled={false} required={false}
                                                                             name={"personal_representative[" + 2 + "].occupation"}
                                                                             className={"form-control"}
                                                                             id={"personal_representative[" + 2 + "].occupation-input"}
                                                                             value={formIK.values["personal_representative"][2]['occupation']}
                                                                             onChange={(value) => {
                                                                                 formIK.setFieldValue("personal_representative[" + 2 + "].occupation", value);
                                                                             }}/>
                                                            {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 3 && formIK.errors["personal_representative"][2] !== undefined && (
                                                                <div
                                                                    className="text-error">{formIK.errors["personal_representative"][2]['occupation']}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                            </Col>

                                            <Col>
                                                {
                                                    formIK.values.number_of_personal_representative > 3 && (
                                                        <>
                                                            <h5 style={{textAlign: "center"}}>Personal Representative 4</h5>
                                                            <Form.Group key={"name-" + 4} className="col-12"
                                                                        controlId="formBasicEmail"
                                                                        style={{
                                                                            display: "inline-grid",
                                                                            padding: "0px 15px 0px 0px"
                                                                        }}>
                                                                <Form.Label className='required-label'><strong>Name of Personal
                                                                    Rep</strong></Form.Label>
                                                                <CustomTextInput disabled={false} required={true}
                                                                                 name={"personal_representative[" + 3 + "].name"}
                                                                                 className={"form-control"}
                                                                                 id={"personal_representative[" + 3 + "].name-input"}
                                                                                 value={formIK.values.personal_representative[3]['name']}
                                                                                 onChange={(value) => {
                                                                                     formIK.setFieldValue("personal_representative[" + 3 + "].name", value);
                                                                                 }}/>
                                                                {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                    <div
                                                                        className="text-error">{formIK.errors["personal_representative"][3]['name']}</div>
                                                                )}
                                                            </Form.Group>
                                                            {
                                                                formIK.values.e_signed_indemnity === 'yes' && (
                                                                    <Form.Group key={"email-" + 3} className="col-12"
                                                                                controlId="formBasicEmail"
                                                                                style={{
                                                                                    display: "inline-grid",
                                                                                    padding: "0px 15px 0px 0px"
                                                                                }}>
                                                                        <Form.Label
                                                                            className='required-label'><strong>Email</strong></Form.Label>
                                                                        <CustomTextInput disabled={false} required={true}
                                                                                         name={"personal_representative[" + 3 + "].email"}
                                                                                         className={"form-control"}
                                                                                         id={"personal_representative[" + 3 + "].email-input"}
                                                                                         value={formIK.values.personal_representative[3]['email']}
                                                                                         onChange={(value) => {
                                                                                             formIK.setFieldValue("personal_representative[" + 3 + "].email", value);
                                                                                         }}/>
                                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][2] !== undefined && (
                                                                            <div
                                                                                className="text-error">{formIK.errors["personal_representative"][3]['email']}</div>
                                                                        )}
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            <Form.Group key={"is_us_citizen-" + 3} className="col-12"
                                                                        controlId="formBasicEmail"
                                                                        style={{
                                                                            display: "inline-grid",
                                                                            padding: "0px 15px 0px 0px"
                                                                        }}>
                                                                <Form.Label className='required-label'><strong>Is US
                                                                    Citizen</strong></Form.Label>
                                                                <CustomSelectInput disabled={false}
                                                                                   name={"personal_representative[" + 3 + "].is_us_citizen"}
                                                                                   value={formIK.values.personal_representative[2]['is_us_citizen']}
                                                                                   onChange={(value) => {
                                                                                       formIK.setFieldValue("personal_representative[" + 3 + "].is_us_citizen", value);
                                                                                       if (value === 'no') {
                                                                                           formIK.setFieldValue("personal_representative[" + 3 + "].city", "");
                                                                                           formIK.setFieldValue("personal_representative[" + 3 + "].state", "");
                                                                                       } else {
                                                                                           formIK.setFieldValue("personal_representative[" + 3 + "].citizenship", "");
                                                                                       }
                                                                                   }}
                                                                                   options={lineItemInputs.filter((li) => li.name === 'is_us_citizen')[0].options}/>
                                                                {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                    <div
                                                                        className="text-error">{formIK.errors["personal_representative"][3]['is_us_citizen']}</div>
                                                                )}
                                                            </Form.Group>
                                                            {
                                                                formIK.values["personal_representative"][3]['is_us_citizen'] === 'yes' && (
                                                                    <Row style={{marginTop: "2%", padding: "0px 5% 0px 2.5%"}}>
                                                                        <GoogleAutoComplete placeholder="Search Address..."
                                                                                            callback={(address, city, state, zip_code) => {
                                                                                                addressAutoFillCallBack(address, city, state, zip_code, 3);
                                                                                            }}/>
                                                                    </Row>
                                                                )
                                                            }
                                                            <Form.Group key={"address-" + 3} className="col-12"
                                                                        controlId="formBasicEmail"
                                                                        style={{
                                                                            display: "inline-grid",
                                                                            padding: "0px 15px 0px 0px"
                                                                        }}>
                                                                <Form.Label className='required-label'><strong>Street Address
                                                                    for Personal Rep</strong></Form.Label>
                                                                <CustomTextInput disabled={false} required={true}
                                                                                 name={"personal_representative[" + 3 + "].address"}
                                                                                 className={"form-control"}
                                                                                 id={"personal_representative[" + 3 + "].address-input"}
                                                                                 value={formIK.values["personal_representative"][3]['address']}
                                                                                 onChange={(value) => {
                                                                                     formIK.setFieldValue("personal_representative[" + 3 + "].address", value);
                                                                                 }}/>
                                                                {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                    <div
                                                                        className="text-error">{formIK.errors["personal_representative"][3]['address']}</div>
                                                                )}
                                                            </Form.Group>
                                                            {
                                                                formIK.values["personal_representative"][3]['is_us_citizen'] === 'yes' &&
                                                                <Form.Group key={"city-" + 3} className="col-12"
                                                                            controlId="formBasicEmail"
                                                                            style={{
                                                                                display: "inline-grid",
                                                                                padding: "0px 15px 0px 0px"
                                                                            }}>
                                                                    <Form.Label className='required-label'><strong>City/Town for
                                                                        Personal
                                                                        Rep</strong></Form.Label>
                                                                    <CustomTextInput disabled={false} required={true}
                                                                                     name={"personal_representative[" + 3 + "].city"}
                                                                                     className={"form-control"}
                                                                                     id={"personal_representative[" + 3 + "].city-input"}
                                                                                     value={formIK.values["personal_representative"][3]['city']}
                                                                                     onChange={(value) => {
                                                                                         formIK.setFieldValue("personal_representative[" + 3 + "].city", value);
                                                                                     }}/>
                                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                        <div
                                                                            className="text-error">{formIK.errors["personal_representative"][3]['city']}</div>
                                                                    )}
                                                                </Form.Group>
                                                            }
                                                            {
                                                                formIK.values["personal_representative"][3]['is_us_citizen'] === 'yes' &&
                                                                <Form.Group key={"state-" + 3} className="col-12"
                                                                            controlId="formBasicEmail"
                                                                            style={{
                                                                                display: "inline-grid",
                                                                                padding: "0px 15px 0px 0px"
                                                                            }}>
                                                                    <Form.Label
                                                                        className='required-label'><strong>State/Province/Region</strong></Form.Label>
                                                                    <CustomSelectInput disabled={false}
                                                                                       name={"personal_representative[" + 3 + "].state"}
                                                                                       value={formIK.values["personal_representative"][3]['state']}
                                                                                       onChange={(value) => {
                                                                                           formIK.setFieldValue("personal_representative[" + 3 + "].state", value);
                                                                                       }}
                                                                                       options={lineItemInputs.filter((li) => li.name === 'state')[0].options}/>
                                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                        <div
                                                                            className="text-error">{formIK.errors["personal_representative"][3]['state']}</div>
                                                                    )}
                                                                </Form.Group>
                                                            }
                                                            {
                                                                formIK.values["personal_representative"][3]['is_us_citizen'] === 'yes' &&
                                                                <Form.Group key={"zip_code-" + 3} className="col-12"
                                                                            controlId="formBasicEmail"
                                                                            style={{
                                                                                display: "inline-grid",
                                                                                padding: "0px 15px 0px 0px"
                                                                            }}>
                                                                    <Form.Label className='required-label'><strong>ZIP/Postal
                                                                        Code</strong></Form.Label>
                                                                    <ZipCode disabled={false} required={true}
                                                                             name="zip_code"
                                                                             className={"form-control"}
                                                                             id="zip_code-input"
                                                                             value={formIK.values["personal_representative"][3]['zip_code']}
                                                                             onChange={(value) => {
                                                                                 formIK.setFieldValue("personal_representative[" + 3 + "].zip_code", value);
                                                                             }}/>
                                                                    {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                        <div
                                                                            className="text-error">{formIK.errors["personal_representative"][3]['zip_code']}</div>
                                                                    )}
                                                                </Form.Group>
                                                            }
                                                            {
                                                                formIK.values["personal_representative"][3]['is_us_citizen'] === 'no' && (
                                                                    <Form.Group key={"citizenship-" + 3} className="col-12"
                                                                                controlId="formBasicEmail"
                                                                                style={{
                                                                                    display: "inline-grid",
                                                                                    padding: "0px 15px 0px 0px"
                                                                                }}>
                                                                        <Form.Label className='required-label'><strong>If NO, what
                                                                            is their citizenship or primary
                                                                            residency?</strong></Form.Label>
                                                                        <CustomSelectInput disabled={false}
                                                                                           name={"personal_representative[" + 3 + "].citizenship"}
                                                                                           value={formIK.values["personal_representative"][3]['citizenship']}
                                                                                           onChange={(value) => {
                                                                                               formIK.setFieldValue("personal_representative[" + 3 + "].citizenship", value);
                                                                                           }}
                                                                                           options={lineItemInputs.filter((li) => li.name === 'citizenship')[0].options}/>
                                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                            <div
                                                                                className="text-error">{formIK.errors["personal_representative"][3]['citizenship']}</div>
                                                                        )}
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                formIK.values["personal_representative"][3]['citizenship'] === 'Others' && (
                                                                    <Form.Group key={"other_country-" + 3} className="col-12"
                                                                                controlId="formBasicEmail"
                                                                                style={{
                                                                                    display: "inline-grid",
                                                                                    padding: "0px 15px 0px 0px"
                                                                                }}>
                                                                        <Form.Label className='required-label'><strong>Other Country
                                                                            for Personal Rep</strong></Form.Label>
                                                                        <CustomSelectInput disabled={false}
                                                                                           name={"personal_representative[" + 3 + "].other_country"}
                                                                                           value={formIK.values["personal_representative"][3]['other_country']}
                                                                                           onChange={(value) => {
                                                                                               formIK.setFieldValue("personal_representative[" + 3 + "].other_country", value);
                                                                                           }}
                                                                                           options={lineItemInputs.filter((li) => li.name === 'other_country')[0].options}/>
                                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                            <div
                                                                                className="text-error">{formIK.errors["personal_representative"][3]['other_country']}</div>
                                                                        )}
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                (formIK.values["personal_representative"][3]['is_us_citizen'] === 'yes' && formIK.values['bond_amount'] >= 250000) && (
                                                                    <Form.Group key={"ssn-" + 3} className="col-12"
                                                                                controlId="formBasicEmail"
                                                                                style={{
                                                                                    display: "inline-grid",
                                                                                    padding: "0px 15px 0px 0px"
                                                                                }}>
                                                                        <Form.Label className='required-label'><strong>If YES to US
                                                                            Citizen, what is their Social Security Number?</strong></Form.Label>
                                                                        <PatternFormat className={"form-control"}
                                                                                       name={"personal_representative[" + 3 + "].ssn"}
                                                                                       id={"ssn-input-" + 3}
                                                                                       defaultValue={formIK.values["personal_representative"][3]['ssn']}
                                                                                       format="###-##-####" allowEmptyFormatting
                                                                                       mask="#"
                                                                                       disabled={false} onChange={(value) => {
                                                                            formIK.setFieldValue("personal_representative[" + 3 + "].ssn", value.target.value);
                                                                        }}/>
                                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                            <div
                                                                                className="text-error">{formIK.errors["personal_representative"][3]['ssn']}</div>
                                                                        )}
                                                                    </Form.Group>
                                                                )
                                                            }
                                                            {
                                                                formIK.values['bond_amount'] > 50000 && (
                                                                    <Form.Group key={"occupation-" + 3} className="col-12"
                                                                                controlId="formBasicEmail"
                                                                                style={{
                                                                                    display: "inline-grid",
                                                                                    padding: "0px 15px 0px 0px"
                                                                                }}>
                                                                        <Form.Label><strong>Occupation of Personal
                                                                            Rep</strong></Form.Label>
                                                                        <CustomTextInput disabled={false} required={false}
                                                                                         name={"personal_representative[" + 3 + "].occupation"}
                                                                                         className={"form-control"}
                                                                                         id={"personal_representative[" + 3 + "].occupation-input"}
                                                                                         value={formIK.values["personal_representative"][3]['occupation']}
                                                                                         onChange={(value) => {
                                                                                             formIK.setFieldValue("personal_representative[" + 3 + "].occupation", value);
                                                                                         }}/>
                                                                        {formIK.errors.hasOwnProperty("personal_representative") && formIK.errors["personal_representative"].length >= 4 && formIK.errors["personal_representative"][3] !== undefined && (
                                                                            <div
                                                                                className="text-error">{formIK.errors["personal_representative"][3]['occupation']}</div>
                                                                        )}
                                                                    </Form.Group>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    )
                                }
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

PersonalRepresentatives.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
