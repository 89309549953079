import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";

export function ConfirmationPopup(props) {
    const {enqueueSnackbar} = useSnackbar();

    return <>
        <Dialog open={props.show} onClose={() => {
            props.setOpenConfirmationPopup(false)
        }}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.title}</DialogContentText>
            </DialogContent>
            {props.isDisable ?
                <Button onClick={() => {
                    props.setOpenConfirmationPopup(false);
                }}>Ok</Button> :
                <DialogActions>
                    <Button onClick={() => {
                        props.setOpenConfirmationPopup(false);
                    }}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={async () => {
                        props.handleClose();
                    }}>
                        Confirm
                    </Button>
                </DialogActions>
            }
        </Dialog>
    </>
}