import React, { useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import { addLawFirmBranchBackend, updateLawFirmBranchBackend } from "../../services/company_management";
import { addLawFirmBranch, updateLawFirmBranch } from "../../store/company_management";
import {addNewLawFirmAddress, removeLawFirmData} from "../../store/bond";
import Select from "react-select";


export default function LawFirmBranchPopup(props) {
    const dispatch = useDispatch();
    const lawFirmBranchInStore = useSelector((state) => state.companyManagement.law_firm_branches);
    const lawFirmsInStore = useSelector((state) => state.companyManagement.law_firms);
    const [loading, setLoading] = useState(false);

    let law_firm_branch_details = {};
    let selectedLawFirm = {};
    if(props.law_firm_branch_id !== 0){
        law_firm_branch_details = lawFirmBranchInStore.filter((law_firm_branch) => law_firm_branch.id === props.law_firm_branch_id)[0];
    }
    selectedLawFirm = lawFirmsInStore.filter((law_firm)=>law_firm.id === law_firm_branch_details.law_firm)[0];

        
    const getLawFirmOptions = () => {
        let lawFirmOpts = [];
        lawFirmsInStore.map((law_firm)=> lawFirmOpts.push({label: law_firm.name, value: law_firm.id}))
        return lawFirmOpts;
    }


    const getInitialValues = () => {
        if (props.law_firm_branch_id !== 0){
            return {address: law_firm_branch_details.address, city: law_firm_branch_details.city, 
                state: law_firm_branch_details.state, zip_code: law_firm_branch_details.zip_code,
                law_firm: Object.keys(law_firm_branch_details).length>0 ? law_firm_branch_details.law_firm : '',
                law_firm_name: law_firm_branch_details.law_firm_name, 
                is_mail_addr: law_firm_branch_details.is_mail_addr, is_main_addr: law_firm_branch_details.is_main_addr, 
                is_branch_addr: law_firm_branch_details.is_branch_addr, is_active: law_firm_branch_details.is_active,
                law_firm_customer_number: law_firm_branch_details.law_firm_customer_number}
        }else{
            return {address: '', city: '', state: '', zip_code: '', law_firm: '', law_firm_name: '', is_mail_addr: false, 
                    is_main_addr: false, is_branch_addr: true, is_active: true}
        }
    }

    const getValidation = () => {
        if (props.law_firm_branch_id !== 0){
            return Yup.object({
                address: Yup.string().required('Address is required.'),
                law_firm: Yup.string().required('Law Firm is required.'),
                city: Yup.string().required('City is required.'),
                state: Yup.string().required('State is required.'),
                zip_code: Yup.string().required('Zip Code is required.'),
                is_mail_addr: Yup.boolean().oneOf([true, false], 'Mail Address status is required.'),
                is_main_addr: Yup.boolean().oneOf([true, false], 'Main Address status is required.'),
                is_branch_addr: Yup.boolean().oneOf([true, false], 'Branch Address status is required.'),
                is_active: Yup.boolean().oneOf([true, false], 'Active status is required.'),
            })
        }else{
            return Yup.object({
                address: Yup.string().required('Address is required.'),
                law_firm: Yup.string().required('Law Firm is required.'),
                city: Yup.string().required('City is required.'),
                state: Yup.string().required('State is required.'),
                zip_code: Yup.string().required('Zip Code is required.'),
                is_mail_addr: Yup.boolean().oneOf([true, false], 'Mail Address status is required.'),
                is_main_addr: Yup.boolean().oneOf([true, false], 'Main Address status is required.'),
                is_branch_addr: Yup.boolean().oneOf([true, false], 'Branch Address status is required.'),
            })
        }
    }

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            if (props.law_firm_branch_id!==0){
                await updateLawFirmBranchBackend(props.law_firm_branch_id, values).then((res)=>{
                    if(res === 200){
                        props.showSuccessAlert(true);
                        props.setSuccessMessage('Law Firm Branch updated successfully');
                        dispatch(updateLawFirmBranch({id: props.law_firm_branch_id, law_firm: values.law_firm, 
                            address: values.address, law_firm_name: values.law_firm_name, 
                            city: values.city, state: values.state, zip_code: values.zip_code, 
                            is_mail_addr: values.is_mail_addr, is_main_addr: values.is_main_addr, 
                            is_branch_addr: values.is_branch_addr, is_active: values.is_active,
                            law_firm_customer_number: values.law_firm_customer_number
                        }))
                        dispatch(removeLawFirmData());
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(0);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(0);
                })
            }else{
                await addLawFirmBranchBackend(values).then((res)=>{
                    if (res.status === 201){
                        const data = res.data;
                        props.showSuccessAlert(true);
                        props.setSuccessMessage('Law Firm Branch created successfully.');
                        dispatch(addLawFirmBranch(data))
                        dispatch(addNewLawFirmAddress({law_firm: values.law_firm, address: values.address, id: data.id}))
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(false);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(false);
                })
    

            }

        },
        validationSchema:getValidation()
    });
    const [isMailAddressCheckbox, setIsMailAddressCheckbox] = useState(props.law_firm_branch_id !== 0 ? law_firm_branch_details.is_mail_addr : false);
    const [isMainAddressCheckbox, setIsMainAddressCheckbox] = useState(props.law_firm_branch_id !== 0 ? law_firm_branch_details.is_main_addr : false);
    const [isBranchAddressCheckbox, setIsBranchAddressCheckbox] = useState(props.law_firm_branch_id !== 0 ? law_firm_branch_details.is_branch_addr : true);
    const [isActiveCheckbox, setIsActiveCheckbox] = useState(props.law_firm_branch_id !== 0 ? law_firm_branch_details.is_active : true);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Law Firm Branch
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key="law_firm" className="col-12" controlId="formLawFirm"
                                                style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={'required-label'}><strong>Law Firm</strong></Form.Label>
                            <Select
                                placeholder={"Select Law Firm"}
                                name="law_firm"
                                onChange={(opt, e) => {
                                    formIk.setFieldValue('law_firm', opt.value);
                                    formIk.setFieldValue('law_firm_name', opt.label);
                                }}
                                options={getLawFirmOptions()}
                                defaultValue={(props.law_firm_address_id !== 0 && selectedLawFirm) ?
                                    {
                                        label: selectedLawFirm.name,
                                        value: selectedLawFirm.id
                                    } : ''}
                            />
                            {formIk.errors['law_firm'] && (
                                <div className="text-error">{formIk.errors['law_firm']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                {
                    (props.law_firm_branch_id !== 0 && props.law_firm_branch_id !== undefined) &&
                    <Row>
                        <Col>
                            <Form.Group className="col-12" controlId="formLawFirm"
                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                <Form.Label><strong>Law Firm Customer Number</strong></Form.Label>
                                <Form.Control type="text" size="sm" disabled={true} onBlur={formIk.handleBlur}
                                              onChange={formIk.handleChange}
                                              name='customer_number'
                                              defaultValue={props.law_firm_address_id !== 0 ? law_firm_branch_details.law_firm_customer_number: ''}/>
                            </Form.Group>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col>
                        <Form.Group key="address" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={'required-label'}><strong>Address</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='address'
                                          defaultValue={props.law_firm_address_id !== 0 ? law_firm_branch_details.address : ''}/>
                            {formIk.errors['address'] && formIk.touched['address'] && (
                                <div className="text-error">{formIk.errors['address']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key="city" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={'required-label'}><strong>City</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='city'
                                          defaultValue={props.law_firm_city_id !== 0 ? law_firm_branch_details.city : ''}/>
                            {formIk.errors['city'] && formIk.touched['city'] && (
                                <div className="text-error">{formIk.errors['city']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="state" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={'required-label'}><strong>State</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='state'
                                          defaultValue={props.law_firm_state_id !== 0 ? law_firm_branch_details.state : ''}/>
                            {formIk.errors['state'] && formIk.touched['state'] && (
                                <div className="text-error">{formIk.errors['state']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="zip_code" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={'required-label'}><strong>Zip Code</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='zip_code'
                                          defaultValue={props.law_firm_zip_code_id !== 0 ? law_firm_branch_details.zip_code : ''}/>
                            {formIk.errors['zip_code'] && formIk.touched['zip_code'] && (
                                <div className="text-error">{formIk.errors['zip_code']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key={'is_mail_addr'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Mailing Address</strong></Form.Label>
                            <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                    onChange={() => {
                                        {
                                            formIk.setFieldValue('is_mail_addr', !isMailAddressCheckbox);
                                            setIsMailAddressCheckbox(!isMailAddressCheckbox);
                                        }
                                    }}
                                    name='is_mail_addr' checked={isMailAddressCheckbox}/>
                            {formIk.errors['is_mail_addr'] && formIk.touched['is_mail_addr'] && (
                                <div className="text-error">{formIk.errors['is_mail_addr']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key={'is_main_addr'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Main Address</strong></Form.Label>
                            <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                    onChange={() => {
                                        {
                                            formIk.setFieldValue('is_main_addr', !isMainAddressCheckbox);
                                            setIsMainAddressCheckbox(!isMainAddressCheckbox);
                                        }
                                    }}
                                    name='is_main_addr' checked={isMainAddressCheckbox}/>
                            {formIk.errors['is_main_addr'] && formIk.touched['is_main_addr'] && (
                                <div className="text-error">{formIk.errors['is_main_addr']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key={'is_branch_addr'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Branch Address</strong></Form.Label>
                            <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                    onChange={() => {
                                        {
                                            formIk.setFieldValue('is_branch_addr', !isBranchAddressCheckbox);
                                            setIsBranchAddressCheckbox(!isBranchAddressCheckbox);
                                        }
                                    }}
                                    name='is_branch_addr' checked={isBranchAddressCheckbox}/>
                            {formIk.errors['is_branch_addr'] && formIk.touched['is_branch_addr'] && (
                                <div className="text-error">{formIk.errors['is_branch_addr']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                {props.law_firm_branch_id !== 0 &&
                    <Row>
                        <Col>
                            <Form.Group key={'is_active'} controlId="formFileSm" className="mb-3">
                                <Form.Label className={'required-label'}><strong>Active</strong></Form.Label>
                                <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                        onChange={() => {
                                            {
                                                formIk.setFieldValue('is_active', !isActiveCheckbox);
                                                setIsActiveCheckbox(!isActiveCheckbox);
                                            }
                                        }}
                                        name='is_active' checked={isActiveCheckbox}/>
                                {formIk.errors['is_active'] && formIk.touched['is_active'] && (
                                    <div className="text-error">{formIk.errors['is_active']}</div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => formIk.submitForm()}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

LawFirmBranchPopup.propTypes = {
    law_firm_branch_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    showSuccessAlert: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    showFailureAlert: PropTypes.func.isRequired,
    setFailureMessage: PropTypes.func.isRequired,
}
