import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    categories: [],
    invoices: [],
    reducedCategories: []
}

const categoriesSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        addCategories(state, action) {
            action.payload.map(cat => state.categories.push(cat));
        },
        addCategory(state, action) {
            state.categories.push(action.payload);
        },
        removeDocumentApprovalCategory(state, action) {
            state.categories = state.categories.map((cat) => {
                if (cat.category__name !== 'Document Approval') {
                    return cat
                }
            });
        },
        removeCategories(state, action) {
            state.categories = [];
        },
        addInvoices(state, action) {
            state.invoices = action.payload;
        },
        removeInvoicesDashboard(state, action) {
            state.invoices = [];
        },
        addReducedCategories(state, action) {
            action.payload.map(cat => state.reducedCategories.push(cat));
        },
    }
});

export const {
    addCategories,
    removeCategories,
    addCategory,
    addInvoices,
    removeInvoicesDashboard,
    addReducedCategories
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
