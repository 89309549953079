import React, { useState } from 'react';
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

export default function CustomAmountInput(props) {
    const [value, onChange] = useState(props.value);

    return (
        <NumberFormat thousandSeparator={true} prefix={'$ '} type="text" className={props.className}
                      decimalScale={props.decimalScale === undefined ? 2: props.decimalScale} fixedDecimalScale={true}
                      name={props.name} id={props.id} defaultValue={value} placeholder={props.placeholder}
                      disabled={props.disabled} onChange={(value)=> {
                          onChange(parseFloat(value.target.value.replace('$ ', '').replaceAll(',','')));
                          props.onChange(parseFloat(value.target.value.replace('$ ', '').replaceAll(',','')));
                      }
        } />
    );
}

CustomAmountInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    decimalScale: PropTypes.number,
    placeholder: PropTypes.string,
};
