import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import "../../index.css";
import {
    Card,
    CardContent,
    Container,
} from "@mui/material";
import {ArrowRight, ArrowLeft} from 'react-bootstrap-icons';
import {Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import CustomSelectInput from "../../global_components/inputs/select-field";
import LawFirmAttorneyPopup from "../popups/add_attorney_providing_lawfirm";

export default function AttorneyInformation({formIK, step, progress, changeStep}) {
    const [showAddAttorney, setShowAddAttorney] = useState(false);
    const processInput = useSelector((state) => state.bond.bond_process_input);
    const lawFirms = useSelector((state) => state.bond.law_firms);
    const lawFirmAddresses = useSelector((state) => state.bond.law_firm_addresses);
    const lawFirmContacts = useSelector((state) => state.bond.law_firm_contacts);
    const [contact, setContact] = useState('');
  
    console.log(lawFirms);
    console.log(lawFirmAddresses);
    console.log(lawFirmContacts);
    
    const addAttorneyCallBack = (attorney_id) => {
        formIK.setFieldValue('contact', attorney_id);
    }

    const validate = () => {
        let error = false;
        let errors = {}
        if (formIK.values['law_firm'] === "" || formIK.values['law_firm'] === undefined) {
            errors = {...errors, law_firm: "Law Firm is required."};
            error = true
        }
        if (formIK.values['law_firm_address'] === "" || formIK.values['law_firm_address'] === undefined) {
            errors = {...errors, law_firm_address: "Law Firm Address is required."};
            error = true
        }
        if (formIK.values['contact'] === "" || formIK.values['contact'] === undefined) {
            errors = {...errors, contact: "Law Firm Contact is required."};
            error = true
        }
        if (error === true) {
            formIK.setErrors(errors);
            window.scroll(0, 0);
            console.log(formIK.errors)
        } else {
            changeStep(progress + step);
        }
    }

    return (
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{maxWidth: "50%"}} style={{marginBottom: "2%"}}>

                            <CardContent>
                                <h4>Attorney Information</h4>
                                <Row style={{marginBottom: "40%"}}>
                                    <Col>
                                        {
                                            lawFirms.length > 0 &&
                                            <Form.Group key="law_firm" className="col-12" controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label className='required-label'><strong>Law
                                                    Firm</strong></Form.Label>
                                                <CustomSelectInput disabled={false} name="law_firm"
                                                                   value={lawFirms.length === 1 ? lawFirms[0] : formIK.values["law_firm"]}
                                                                   onChange={(value) => {
                                                                       formIK.setFieldValue("law_firm", value);

                                                                       if (lawFirms.length > 0 && lawFirmAddresses[value].length === 1) {
                                                                           formIK.setFieldValue('law_firm_address', lawFirmAddresses[value][0].value);
                                                                           formIK.setFieldValue('contact', lawFirmContacts[value][0].value)

                                                                       } else {
                                                                           formIK.setFieldValue('law_firm_address', '');
                                                                       }
                                                                   }}
                                                                   options={lawFirms}/>
                                                <div className="text-error">{formIK.errors["law_firm"]}</div>
                                            </Form.Group>
                                        }
                                        {
                                            Object.keys(lawFirmAddresses).length > 0 && formIK.values['law_firm'] !== "" &&
                                            <Form.Group key="law_firm_address" className="col-12"
                                                        controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label className='required-label'><strong>Law Firm Address</strong></Form.Label>
                                                <CustomSelectInput disabled={false} name="law_firm_address"
                                                                   value={lawFirms.length > 0 && lawFirmAddresses[formIK.values['law_firm']].length === 1 ? lawFirmAddresses[formIK.values['law_firm']][0].value : formIK.values["law_firm_address"]}
                                                                   onChange={(value) => {
                                                                       console.log("ormIK.setFieldValue ", value)
                                                                       formIK.setFieldValue("law_firm_address", value);
                                                                   }}
                                                                   options={lawFirmAddresses[formIK.values['law_firm']]}/>
                                                <div className="text-error">{formIK.errors["law_firm_address"]}</div>
                                            </Form.Group>
                                        }
                                        {
                                            Object.keys(lawFirmContacts).length > 0 && formIK.values['law_firm'] !== "" &&
                                            <>
                                                <Form.Group key="contact" className="col-12" controlId="formBasicEmail"
                                                            style={{display: "inline-grid"}}>
                                                    <Form.Label className='required-label'><strong>Attorney of
                                                        Record</strong></Form.Label>
                                                    <Row>
                                                        <Col className="col-8" style={{padding: "0px 0px 0px 10px"}}>
                                                            <CustomSelectInput disabled={false} name="contact"
                                                                               value={lawFirmContacts[formIK.values['law_firm']].length === 1 ? lawFirmContacts[formIK.values['law_firm']][0].value : formIK.values["contact"]}
                                                                               onChange={(value) => {
                                                                                   formIK.setFieldValue("contact", value);
                                                                               }}
                                                                               options={lawFirmContacts[formIK.values['law_firm']]}/>
                                                        </Col>
                                                        <Col className="col-3"
                                                             style={{padding: "0px 0px 0px 0px",marginLeft: "3%"}}>
                                                            <button className="col-12 btn btn-primary" style={{fontSize:"small",height:"100%"}}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowAddAttorney(true);
                                                                    }}>
                                                                Add New Attorney
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                    <div className="text-error">{formIK.errors["contact"]}</div>
                                                </Form.Group>

                                            </>
                                        }
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
            {
                showAddAttorney && <LawFirmAttorneyPopup show={true} closePopup={setShowAddAttorney}
                                                         law_firm_id={formIK.values.law_firm}
                                                         addAttorneyCallBack={addAttorneyCallBack}/>
            }
        </div>
    )
}

AttorneyInformation.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
