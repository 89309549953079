import '../../../../../styles/login.css';
import CusNavBar from "../../../../../global_components/navbar";
import Footer from "../../../../../global_components/footer";
import React from "react";
import BaseAddEditUser from "../../../vermost_components/base_user_page";

const AddEditUser = () => {

    return (
        <>
            <CusNavBar/>


            <BaseAddEditUser></BaseAddEditUser>
            <Footer/>
        </>
    )
};
export default AddEditUser;
