import React, {useCallback, useEffect, useState} from 'react';
import '../styles/login.css';
import {useNavigate} from "react-router";
import PropTypes from "prop-types";
import {DataGrid, GridFilterModel} from '@mui/x-data-grid';
import '../App.css';
import {
    compare,
    formatHistoryData, getFailedInvoicesPagination,
    getHistoryColumns,
    getHistoryInvoicesPagination, getInvoicesPagination, getPendingInvoicesPagination
} from "../services/accounts";
import {useDispatch, useSelector} from "react-redux";
import APTableLoading from "./loadings/ap_table_loading";
import {addInvoices, removeTypeInvoices} from "../store/invoices";
import * as XLSX from "sheetjs-style";
import {createSvgIcon, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import * as FileSaver from "file-saver";
import {notRequiredKeys} from "../constants";
import {useSnackbar} from "notistack";

const ExportIcon = createSvgIcon(
    <path
        d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"/>,
    'SaveAlt',
);

function HistoryCusTable(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentPermission = useSelector((state) => state.auth.user.current_selected_permission_ap);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowClicked, setRowClicked] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false)
    const {enqueueSnackbar} = useSnackbar();

    // Datagrid Pagination
    if (!localStorage.getItem("accounts_payable_datagrid_pagesize")) {
        localStorage.setItem("accounts_payable_datagrid_pagesize", JSON.stringify({
            'active': 10,
            'pending': 10,
            'failed': 10,
            'posted': 10
        }));
    }
    const [postedLoading, setPostedLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0,
        pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['posted']
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);
    // Datagrid Filter
    const [queryOptionsPosted, setQueryOptionsPosted] = useState({});
    const onFilterChangePosted = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptionsPosted({filterModel: {...filterModel.items}}), 1000);
    }, []);
    //Datagrid Sort
    const [queryOptionsSortPosted, setQueryOptionsSortPosted] = useState({});
    const handleSortModelChangePosted = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortPosted({sortModel: [...sortModel]});
    }, []);
    const [prevSortPosted, setPrevSortPosted] = useState("");

    const editHistory = (id) => {
        props.editCallback(id)
    };

    const getFilterAndSort = () => {
        let filter = ""
        if (queryOptionsPosted.filterModel) {
            filter = queryOptionsPosted.filterModel[0].field + "-" +
                queryOptionsPosted.filterModel[0].operator + "-" +
                queryOptionsPosted.filterModel[0].value
        }
        let sort = ""
        if (queryOptionsSortPosted.sortModel) {
            if (queryOptionsSortPosted.sortModel.length > 0) {
                if (prevSortPosted) {
                    sort = prevSortPosted
                }
                let sortModel = queryOptionsSortPosted.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortPosted !== sort) {
                    setPrevSortPosted(sort)
                } else {
                    if (queryOptionsPosted.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        return [filter, sort]
    }

    const setHistoryData = async () => {
        setPostedLoading(true);
        let filter = getFilterAndSort();
        let data = await getHistoryInvoicesPagination(controller.page, controller.pageSize, filter[0], filter[1]);
        setRowCount(data.total);
        dispatch(addInvoices({
            permissionType: currentPermission, invoiceType: 'posted',
            invoices: data['data']
        }))
        localStorage.setItem('history_total_count', data.total);
        let prev_local_invoices = localStorage.getItem('history_invoices');
        if (prev_local_invoices) {
            prev_local_invoices = JSON.parse(prev_local_invoices);
            prev_local_invoices = prev_local_invoices.concat(data['data']);
            localStorage.setItem('history_invoices', JSON.stringify(prev_local_invoices));
        } else {
            localStorage.setItem('history_invoices', JSON.stringify(data['data']));
        }
        dataManipulation(data)
    }

    const dataManipulation = (data) => {
        if (data['data'].length === 0) {
            setData([]);
            setLoading(false);
            setPostedLoading(false);
        } else {
            const formatedData = formatHistoryData(data['data']);
            setData(formatedData);
            let cols = getHistoryColumns(data['data']);
            cols.push({
                field: 'posted_by', headerName: 'Posted By',
                sortable: true,
                width: 100,
                headerClassName: 'header-bold right-column',
                sortID: cols.length + 1,
                filterable: false
            })
            cols = [...cols.slice(0, 1), {
                field: 'id', headerName: 'Document',
                sortable: false,
                width: 110,
                headerClassName: 'header-bold right-column',
                renderCell: (params) => {
                    return (
                        <button onClick={() => editHistory(params.row.id)} className="bg-gray-300 hover:bg-gray-400
                    text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                            <span>{'View'}</span>
                        </button>
                    );
                },
                sortID: 1,
                filterable: false
            }, ...cols.slice(1)]
            setColumns(cols.sort(compare));
            setLoading(false);
            setPostedLoading(false);
        }
    }
    const setLocalStorage = () => {
        localStorage.setItem("accounts_payable_datagrid_pagesize", JSON.stringify({
            'active': JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['active'],
            'pending': JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['pending'],
            'failed': JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['failed'],
            'posted': controller.pageSize
        }));
    }
    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        setLocalStorage();
        await setHistoryData();
    }, [page, currentPermission, controller, queryOptionsPosted, queryOptionsSortPosted])

    useEffect(async () => {
        if (props.reload === true) {
            setData([]);
            setColumns([]);
            setController({
                page: 0,
                pageSize: JSON.parse(localStorage.getItem("accounts_payable_datagrid_pagesize"))['posted']
            });
            setQueryOptionsPosted({})
            setQueryOptionsSortPosted({})
            localStorage.setItem('history_invoices', JSON.stringify([]));
            localStorage.setItem('history_total_count', 0);
            dispatch(removeTypeInvoices({permissionType: currentPermission, invoiceType: 'posted'}));
            setPostedLoading(true);
            await setHistoryData();
            props.changeManualReload(false)
        }
    }, [props.reload])

    const sortFormat = (excelData) => {
        let columnList = columns;

        let totalResult = []
        Object.entries(excelData).forEach((val) => {
            let tempObj = {};
            let entries = Object.entries(val[1]['invoice_details']);
            let result = {}
            columnList.forEach(function (key) {
                var found = false;
                entries = entries.filter(function (item) {
                    if (!found && item[0] === key.field) {
                        if (key.field === 'id') {
                            result[item[0]] = item[1];
                        } else {
                            result[item[1].label] = item[1].value;
                        }
                        found = true;
                        return false;
                    } else if (!found && key.field === 'approvement_label') {
                        result["Approval Stage"] = val[1]['approvement_label'];
                        found = true;
                        return false;
                    } else if (!found && key.field === 'department_name') {
                        result["Department"] = val[1]['department_name'];
                        found = true;
                        return false;
                    } else if (!found && key.field === 'status') {
                        result["Status"] = val[1]['status'];
                        found = true;
                        return false;
                    } else
                        return true;
                })
            })
            totalResult.push(result)
        });

        return totalResult;
    }

    const changeFormat = (original) => {
        return sortFormat(original);
    }

    const ExportExcel = (status) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
        const fileExtension = ".xlsx";
        const exportToExcel = async () => {
            try {
                enqueueSnackbar('Preparing the file.', {variant: "info"});
                setLoadingExcel(true);
                let filter = getFilterAndSort();
                let excelData = await getHistoryInvoicesPagination("", "", filter[0], filter[1]);
                const ws = XLSX.utils.json_to_sheet(changeFormat(excelData.data));
                const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
                const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, status.fileName + fileExtension);
                enqueueSnackbar('File is ready.', {variant: "success"});
                setLoadingExcel(false);
            } catch {
                enqueueSnackbar('File could not be created.', {variant: "error"});
                setLoadingExcel(false);
            }
        }
        const buttonBaseProps = {
            color: 'primary',
            size: 'small',
            startIcon: <ExportIcon/>,
        };

        return (<>
            <Tooltip title="Excel Export">
                <Button {...buttonBaseProps}
                        onClick={(e) => exportToExcel(status.fileName)} color="primary"
                        style={{cursor: "pointer", fontSize: 14}}>
                    Export
                </Button>
            </Tooltip>
        </>)

    }

    const handleRowClick = (params) => {
        setRowClicked(true);
    };

    const CustomFooter = ({selectedRowCount}) => {
        const message =
            selectedRowCount === 1 ? `${selectedRowCount} invoice selected` : `${selectedRowCount} invoices selected`;
        return <div style={{color: 'green', fontWeight: 'bold', fontSize: 'medium', padding: '1em'}}>{message}</div>;
    };

    return (
        loading === true ?
            <APTableLoading/>
            :
            <div style={{"height": "650px"}}>
                <ExportExcel status={"posted"}
                             fileName={"Posted_Invoices"}></ExportExcel>
                <DataGrid
                    rows={data}
                    columns={columns}
                    // pagination
                    pageSizeOptions={[10, 25, 50]}
                    paginationMode="server"
                    filterMode="server"
                    rowCount={rowCount}
                    onPaginationModelChange={gatedSetController}
                    onFilterModelChange={onFilterChangePosted}
                    onSortModelChange={handleSortModelChangePosted}
                    loading={postedLoading}
                    paginationModel={controller}
                    // pageSize={controller.pageSize}
                    // pageSizeOptions={[controller.pageSize]}
                    sx={{
                        '.MuiTablePagination-displayedRows': {
                            'margin-top': '1em',
                            'margin-bottom': '1em'
                        },
                        '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                            'margin-top': '1em',
                            'margin-bottom': '1em'
                        }
                    }}
                    onRowClick={handleRowClick}
                    localeText={{
                        footerRowSelected: (count) => "1 invoice selected"
                    }}
                />
            </div>
    )
}

HistoryCusTable.propTypes = {
    editCallback: PropTypes.func.isRequired,
    reload: PropTypes.bool.isRequired,
    changeManualReload: PropTypes.func.isRequired,
};


export default HistoryCusTable;
