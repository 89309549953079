import axios from "axios";
import cookie from "react-cookies";

const api = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
    "X-CSRFTOKEN": cookie.load("csrftoken")
  }
});

export { api };
