import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';
import {useNavigate} from "react-router";
import '../styles/login.css';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    removeCompany, removeToken, removeSelectedDepartment, removeDepartments, removeDepartmentApprovementLevel,
    removeSelectedPermissionAP
}
    from "../store/auth";
import {removeInvoicesDashboard, removeCategories, addReducedCategories} from "../store/dashboard";
import {removeProcess, setCurrentActive} from '../store/processes';
import {removeAllInvoices, removeAccountNumbers} from '../store/invoices';
import {
    removeDepartmentsCM,
    removeUsers,
    removeAPPermissions,
    removePOPermissions,
    removeSuperPOUsers,
    removeProcessPermissions,
    removeLawFirms,
    removeLawFirmContacts,
    removeLawFirmBranches,
    removeLawSchools, removeLawFirmProfessionalGuardians, removeBondNumbers
} from '../store/company_management';
import {removeDocuments} from '../store/document_approval';
import {removeProcessHistory, removeProcessDetails, removeProcessInputs} from '../store/capital_project';
import {
    removeBondProcessInputs,
    removeBondProcessDetails,
    removeBondProcessHistory,
    removeLawFirmData,
} from '../store/bond';
import GearIcon from '@rsuite/icons/Gear';
import {insurance_companies} from "../constants";
import {hasUserPermission} from "../utils/user_permission";
import ReserveBondBlock from "../components/vermost/reserve_bond_block";
import VermostReserveBondNumberPopup from "../components/popups/vermost_reserve_bond_number_popup";
import { getAPICallv2 } from '../services/helper';

export default function CusNavBar(props) {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.user.auth_token);
    const first_name = useSelector((state) => state.auth.user.first_name);
    const last_name = useSelector((state) => state.auth.user.last_name);
    const email = useSelector((state) => state.auth.user.email);
    const categories = useSelector((state) => state.dashboard.categories);
    const reducedCategories = useSelector((state) => state.dashboard.reducedCategories);
    const companyData = useSelector((state) => state.auth.company);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const location = window.location;
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const [showReserveBondNumberPopup, setShowReserveBondNumberPopup] = useState(false);
    const [oglebayadmins, setOglebayadmins] = useState([]);

    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }

    useEffect(() => {
        const fetchOglebayAdmins = async () => {
            try {
                const oglebayAdminsResponse = await getAPICallv2('/user/oglebay-admins/');
                const result = await oglebayAdminsResponse.json();  // Parse the body as JSON
                setOglebayadmins(result.emails)
            } catch (error) {
                console.error("Error fetching oglebay admins:", error);
            }
        };

        if (token) fetchOglebayAdmins();  // Only fetch if token exists
    }, []);

    useEffect(() => {
        if (reducedCategories.length === 0) {
            const cats = categories.filter((cat, index) => {
                return categories.slice(0, index).findIndex(otherCat => otherCat.category__name === cat.category__name) === -1;
            });
            dispatch(addReducedCategories(cats));
        }
    }, [categories]);

    const goBack = () => {
        navigate(-1); // This will navigate back to the previous page
    };
    

    return (
        <Navbar expand="lg">
            {/*<Container>*/}
            <Navbar.Brand href="/">
                <img alt="Blue Ocean" src="/logo.png" className="d-inline-block align-top brand-logo"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {
                        location.pathname !== '/add_user' && token && <button className='font-bold mr-2' onClick={goBack}>Go Back</button>
                    }
                    {
                        location.pathname !== '/add_user' && <Nav.Link className="active" href="/">Home</Nav.Link>
                    }
                    {
                location.pathname !== '/add_user' &&
                token &&
                oglebayadmins.length > 0 && // Ensures oglebayadmins is loaded
                oglebayadmins.includes(email) &&
                <button
                    className='font-semibold mx-2'
                    onClick={() => navigate('/company_management')}
                >
                    Account Management
                </button>
            }

                    {token && companyData.id !== 0 && insurance_companies.includes(companyData.name) &&
                        <Nav.Link href="/insurance_portal">Create Insurance Quote</Nav.Link>}
                    {token && companyData.id !== 0 && insurance_companies.includes(companyData.name) &&
                        <Nav.Link href="/insurance_portal_history">Insurance Quote History</Nav.Link>}
                    {token && !insurance_companies.includes(companyData.name) &&
                        <NavDropdown title="Processes" align="end" id="collasible-nav-dropdown" show={show}
                                     onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
                            {
                                reducedCategories.length > 0 && (
                                    reducedCategories.map((cat) => {
                                        if (cat['category__name'] === 'Document Approval') {
                                            return <NavDropdown.Item onClick={() => navigate("/document_approval")}
                                                                     href="#">Document Approval</NavDropdown.Item>
                                        } else if (cat['category__name'] === 'Capital Proposal') {
                                            return <NavDropdown.Item onClick={() => navigate("/capital_project")} href="#">Capital
                                                Proposal</NavDropdown.Item>
                                        } else if (cat['category__name'] === 'Request Bond') {
                                            return <NavDropdown.Item onClick={() => navigate("/create_bond")} href="#">Request
                                                Bond</NavDropdown.Item>
                                        } else if (cat['category__name'] === 'Bonds' && hasUserPermission(user_permission, "show_approve_bond_in_dashboard")) {
                                            return <NavDropdown.Item onClick={() => navigate("/bond_history")}
                                                                     href="#">{hasUserPermission(user_permission, "change_bond_name_in_dashboard") ? "Approve Bonds" : "Bonds"}</NavDropdown.Item>
                                        } else if (cat['category__name'] === 'Accounts Payable') {
                                            return <NavDropdown.Item
                                                onClick={() => companyData.name === 'FSI-ASI' ? navigate('/fsi-accounts-payable') : navigate("/account_payable")}
                                                href="#">Accounts Payable</NavDropdown.Item>
                                        } else if (cat['category__name'] !== 'Bond Calculator' && cat['category__name'] !== 'Reserve Bond Number' && cat['category__name'] !== 'Account Management'
                                            && cat['category__name'] !== 'Reserve Bond Number') {
                                            return (<NavDropdown.Item onClick={() => {
                                                localStorage.setItem('category_name', cat['category__name']);
                                                dispatch(setCurrentActive(cat['category__name']));
                                                navigate("/process");
                                            }} href="#">{cat['category__name']} </NavDropdown.Item>)
                                        }
                                    })
                                )
                            }
                        </NavDropdown>
                    }
                    {/* token && <Nav.Link  href="/contact">Contact</Nav.Link> */}
                    {/* {token && !insurance_companies.includes(companyData.name) && companyData.name !== 'FSI-ASI' &&
                        <Nav.Link href={companyData.name === "Vermost" ? "/account_management" : "/company_management"}>Account
                            Management</Nav.Link>} */}
                    {/* { token && <a href={"localhost:8000/admin?auth_token="+token}>Admin Site</a> } */}
                </Nav>
                {companyData.id !== 0 &&
                    (<Nav className="right-nav">
                        <span style={{
                            marginRight: "10px",
                            alignContent: "center"
                        }}>Logged in as: <br/><b>{first_name} {last_name}</b></span>
                        <div>
                            <Nav.Link href="/profile" style={{display: "inline-block"}}><GearIcon
                                style={{fontSize: '1.75em'}}/>
                            </Nav.Link>
                            <img alt="Client Logo" src={companyData.logo}
                                 className="d-inline-block align-top client-logo"/>
                        </div>
                        <Nav.Link className="active sign-out-link" onClick={() => {
                            localStorage.clear();
                            dispatch(removeToken());
                            dispatch(removeCompany());
                            dispatch(removeInvoicesDashboard());
                            dispatch(removeCategories());
                            dispatch(removeProcess());
                            dispatch(setCurrentActive());
                            dispatch(removeAllInvoices());
                            dispatch(removeAccountNumbers());
                            dispatch(removeDepartments());
                            dispatch(removeSelectedDepartment());
                            dispatch(removeDepartmentApprovementLevel());
                            dispatch(removeSelectedPermissionAP());
                            dispatch(removeUsers());
                            dispatch(removeDepartmentsCM());
                            dispatch(removeAPPermissions());
                            dispatch(removePOPermissions());
                            dispatch(removeSuperPOUsers());
                            dispatch(removeProcessPermissions());
                            dispatch(removeDocuments());
                            dispatch(removeProcessHistory());
                            dispatch(removeProcessDetails());
                            dispatch(removeProcessInputs());
                            dispatch(removeBondProcessInputs());
                            dispatch(removeBondProcessDetails());
                            dispatch(removeBondProcessHistory());
                            dispatch(removeLawFirmData());
                            dispatch(removeLawFirms());
                            dispatch(removeLawFirmContacts());
                            dispatch(removeLawFirmBranches());
                            dispatch(removeLawSchools());
                            dispatch(removeLawFirmProfessionalGuardians());
                            dispatch(removeBondNumbers());
                            navigate('/');
                        }} href="/">Sign Out</Nav.Link>
                    </Nav>)
                }
            </Navbar.Collapse>
            {/*</Container>*/}
        </Navbar>
    )
}
