import {getAPICall} from './helper';
import {API_URL} from "../constants";

export async function getLawFirmData() {
    return await getAPICall('/bond/list_law_firms/');
}

export async function getBondList(bond_type) {
    return await getAPICall('/bond/?bond_type=' + bond_type);
}

export async function getBondListPagination(bond_type, page, pageSize, filter, sort) {
    return await getAPICall(`/bond/?bond_type=${bond_type}&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export async function createBond(data) {
    const token = localStorage.getItem('userdata');
    const response = await fetch(`${API_URL}/create_bond/`, {
        method: "POST",
        body: JSON.stringify({
            ...data
        }),
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "multipart/form-data",
        },
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location = "/";
    }

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200) {
        return await response.json();
    }
}