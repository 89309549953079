import {DataGrid} from '@mui/x-data-grid';
import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useNavigate} from "react-router";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import {Tooltip} from "@mui/material";

function CustomDataGrid(props) {
    const navigate = useNavigate();
    const [column, setColumn] = useState([]);

    const renderCell = (params) => {
        return (
            <div>
                {props.visibleActionButtons &&
                    <>
                        {props.userApproveNotificationDisable && params.row.is_new && !params.row.is_active &&
                            <Button size='small' disabled={true}>
                                <Tooltip title="" style={{fontSize: "x-small", color: "red"}}>
                                    Pending <br/> Approval
                                </Tooltip>
                            </Button>
                        }
                        {props.updatePermission &&
                            <Button size='small' onClick={() => props.onCallback("edit", params.row)}> <EditIcon
                                type="button" fontSize="small" color={"primary"}/></Button>
                        }
                        {props.deletePermission &&
                            <Button size='small'>
                                {(params.row.is_active !== undefined && params.row.is_active) ?
                                    <Tooltip title="Click to disable"> <ToggleOnIcon
                                        onClick={() => props.onCallback("delete", params.row)}></ToggleOnIcon>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Click to enable">
                                        <ToggleOffIcon style={{color: 'gray'}}
                                                       onClick={() => props.onCallback("delete", params.row)}></ToggleOffIcon>
                                    </Tooltip>
                                }

                            </Button>
                        }

                    </>
                }
            </div>
        )
    }
    const renderHeader = () => {
        return (
            <div>
                {(props.isEdit && props.disableNewButton === undefined) ?
                    <>

                        <IconButton aria-label="download">
                            <DownloadIcon onClick={() => props.onCallback("download")}/>
                        </IconButton>
                        <IconButton aria-label="refresh">
                            <RefreshIcon onClick={() => props.onCallback("refresh")}/>
                        </IconButton>
                        {props.addPermission &&
                            <Button size='small' variant="contained" onClick={() => props.onCallback("insert")}>
                                <AddIcon
                                    type="button" color={"white"}/>New</Button>
                        }

                    </>
                    :
                    <>
                        <IconButton aria-label="download">
                            <DownloadIcon onClick={() => props.onCallback("download")}/>
                        </IconButton>
                        <IconButton aria-label="refresh">
                            <RefreshIcon onClick={() => props.onCallback("refresh")}/>
                        </IconButton>
                    </>
                }
            </div>
        )
    }
    const setActions = () => {
        if (props.isActionsVisible !== undefined && props.isActionsVisible) {
            let idIndex = returnFieldIndex(props.columns, "id");
            props.columns[idIndex].renderCell = renderCell;
            props.columns[idIndex].renderHeader = renderHeader;
        }
        setColumn(props.columns);
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        setActions();
    }, [])

    const returnFieldIndex = (arr, field) => {
        let index = 0;
        index = arr.findIndex((obj => obj.field === field));
        return index;
    }

    return (
        <div style={props.isHeight ? {} : {"height": "650px"}}>
            <DataGrid
                rows={props.rows}
                columns={column}
                rowCount={props.rowCount}
                paginationMode="server"
                autoHeight={props.autoHeight}
                rowAutoHeight={props.rowAutoHeight}
                estimatedRowHeight={props.estimatedRowHeight}
                columnVisibilityModel={props.columnVisibilityModel}
                paginationModel={props.paginationModel}
                onPaginationModelChange={props.onPaginationModelChange}
                pageSizeOptions={[10, 25, 50]}
                filterMode="server"
                onFilterModelChange={props.onFilterModelChange}
                sortingMode="server"
                onSortModelChange={props.onSortModelChange}
                loading={props.loading}
                disableRowSelectionOnClick={props.disableRowSelectionOnClick}
                sx={{
                    '.MuiTablePagination-displayedRows': {
                        'margin-top': '1em',
                        'margin-bottom': '1em'
                    },
                    '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                        'margin-top': '1em',
                        'margin-bottom': '1em'
                    }
                }}
                // initialState={props.initialState}
                // filterModel={props.filterModel}
            />
        </div>
    )
}

CustomDataGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    rowCount: PropTypes.number.isRequired,
    isHeight: PropTypes.bool,
    estimatedRowHeight: PropTypes.object,
    rowAutoHeight: PropTypes.object,
    columnVisibilityModel: PropTypes.object,
    autoHeight: PropTypes.bool,
    disableRowSelectionOnClick: PropTypes.bool,
    isActionsVisible: PropTypes.bool,
    disableNewButton: PropTypes.bool,
};

export default CustomDataGrid;