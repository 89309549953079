import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Card, CardContent, Checkbox, Container, FormControlLabel} from "@mui/material";
import {Col, Row, Modal, Button, Spinner, Form, Tab, Tabs} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {ArrowLeft, ArrowRight} from "react-bootstrap-icons";
import {API_URL} from "../../../constants";
import { useFormik, Form as FForm, Field } from 'formik';
import CustomNumberInput from '../../../global_components/inputs/number';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { updatePortalInsuranceVendorCreds } from '../../../store/auth';
import {getBondNumbers} from "../../../services/company_management";
import {addBondNumbers,removeBondNumbers} from "../../../store/company_management";


export default function AddBondNumberForm(props){
    const [loading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showAlreadyExistMessage, setShowAlreadyExistMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const dispatch = useDispatch();


    const getValidation = () => {
        const validation = {};
        validation['start_num'] = Yup.number()
                                .positive('Bond Start Number must be Positive')
                                .integer().required('Bond Start Number is Required');
        validation['end_num'] = Yup.number()
                                // .moreThan(Yup.ref('start_num'), 'Bond End Number should be less than Bond Start Number')
                                .positive('Bond End Number must be Positive')
                                .integer().required('Bond End Number is Required');

        return validation;
    }

    const getInitialValues = () => {

        const initialValues = {};
        initialValues['start_num'] = props.start_num ? props.start_num : 0;
        initialValues['end_num'] = props.end_num ? props.end_num : 0;

        return initialValues;
    }
    
    const token = localStorage.getItem('userdata');
    const formik = useFormik({
        initialValues: getInitialValues(),

        onSubmit: async (values, {setSubmitting}) => {

            setLoading(true);
            await fetch(`${API_URL}/bond/create_bond_number/`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Authorization': 'Bearer '+token,
                    "Content-Type": "application/json",
                },
            }).then((response)=>{
                if (response.status === 201){
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                    setTimeout(function () { setShowSuccessMessage(false); props.closePopup(false); }, 5000);
                }
                else if (response.status === 400){
                    setShowAlreadyExistMessage(true);
                    setTimeout(() => setShowAlreadyExistMessage(false), 5000);
                    setTimeout(function () { setShowAlreadyExistMessage(false); props.closePopup(false); }, 5000);
                }
                else{
                    setShowFailureMessage(true);
                    setTimeout(function () { setShowFailureMessage(false); props.closePopup(false); }, 5000);
                    this.props.closePopup();
                }
            }).catch((error)=>{
                console.log(error);
                setShowFailureMessage(true);
                setTimeout(function () { setShowFailureMessage(false); props.closePopup(false); }, 5000);
            });
            setLoading(false);
        },
        validationSchema: Yup.object(getValidation())
    });


    return(
        <div>
            <Form className="">
                <div>
                    <Row>
                        <Form.Group key="start_num" controlId="formBasicUsername" className="form-group mb-2 px-4"
                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className='required-label'><strong>Bond Start Number</strong></Form.Label>
                            <CustomNumberInput disabled={false} required={true} name="start_num"
                                                className={"form-control"} id="start_num-input"
                                                onChange={(value)=>{ formik.setFieldValue('start_num', value);}}
                                                value={ formik.values.start_num }
                                                />
                                {formik.errors['start_num'] && (
                                    <div className="text-error">{formik.errors['start_num']}</div>
                                )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group key="end_num" controlId="formBasicPassword" className="form-group mb-2 px-4"
                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className='required-label'><strong>Bond End Number</strong></Form.Label>                            
                            <CustomNumberInput disabled={false} required={true} name="end_num"
                                                className={"form-control"} id="end_num-input"
                                                onChange={(value)=>{ formik.setFieldValue('end_num', value);}}
                                                value={ formik.values.end_num }
                                                />
                                {formik.errors['end_num'] && (
                                    <div className="text-error">{formik.errors['end_num']}</div>
                                )}
                        </Form.Group>
                    </Row>

                </div>
                {
                    showSuccessMessage && <p className='text-primary px-3'>Bond Number added successfully!!!</p>
                }
                {
                    showAlreadyExistMessage && <p className='text-danger px-3'>Try other range. Entered Bond Number already exist!!!</p>
                }
                {
                    showFailureMessage && <p className='text-danger px-3'>Something went wrong. Bond Number couldn't be added!!!</p>
                }
                {
                    !loading && (
                        <div className="px-3" style={{textAlign: "left", marginTop: "2%"}}>
                            <button className="btn btn-success px-4" onClick={(e)=> {
                                console.log(formik.errors);
                                e.preventDefault();
                                formik.handleSubmit();
                                setTimeout(() => {
                                    getBondNumbers().then((res) => {
                                        dispatch(removeBondNumbers());
                                        dispatch(addBondNumbers(res));
                                    }).catch(error => console.log(error));
                                }, 5000);

                                // formik.submitForm();
                            }} disabled={loading}>Create</button>
                        </div>
                    )
                }
                {
                    loading && (
                        <Button disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
            </Form>
        </div>
    )
}

AddBondNumberForm.propTypes = {
    closePopup: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
}
