import '../../../../../styles/login.css';
import CusNavBar from "../../../../../global_components/navbar";
import {Card, CardContent, Grid} from "@mui/material";
import Footer from "../../../../../global_components/footer";
import {Col, Row} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import LawFirmMain from "../../../vermost_components/law_firm_main";
import LawFirmHistory from "../../../vermost_components/law_firm_history";
import LocationList from "../../../vermost_components/locations";
import LawFirmBondHistory from "../../../vermost_components/law_firm_bond_history";
import {useSelector} from "react-redux";
import UserList from "../../../vermost_components/user_list";
import {API_URL} from "../../../../../constants";
import {useNavigate} from "react-router";
import {GridFilterModel} from "@mui/x-data-grid";
import {
    addVermostLawFirmBackend,
    addVermostLawFirmLocation,
    addVermostLawFirmUser,
    deleteUserLawFirmBackend,
    deleteVermostLocationBackend,
    editVermostLawFirmBackend,
    editVermostLawFirmLocation,
    getBondHistory,
    getLastBond,
    getVermostLawFirmLocationsPagination,
    getVermostLawFirmUsersPagination
} from "../../../../../services/company_management";
import {log} from "../../../../../utils/logger"
import {useSnackbar} from "notistack";
import {formatUTCDateTimeMDY} from "../../../../../services/helper";
import {exportToExcel} from "../../../../../utils/excel_export";
import GoBack from "../../../../../utils/go_back_button";
import LoadingOverlay from 'react-loading-overlay';
import ActiveAccountManagementPopup from "../../../../../components/popups/vermost/account_management_active_popup";
import AddEditTeamMember from "../../../vermost_popups/add_edit_team_member_popup";
import {ConfirmationPopup} from "../../../../../utils/confirmation_popup";

const ActionType = {
    edit: 'edit',
    insert: 'insert',
    delete: 'delete',
    download: 'download',
    refresh: 'refresh'
}

const LawFirm = (props) => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const super_user = useSelector((state) => state.auth.user.is_super_admin);
    const [lawFirm, setLawFirm] = useState({
        logo: "",
        name: "",
        website: "",
        customer_number: "",
        physical_address_name: "",
        physical_address: "",
        mailing_address_name: "",
        mailing_address: "",
        billing_address_name: "",
        billing_address: "",
        accounting_contact_full_name: "",
        accounting_contact_email: "",
        accounting_contact_phone: "",
        is_active: false,
        updated_at: ""
    });
    const [teamMemberList, setTeamMemberList] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [showTeamMemberInsertPopup, setShowTeamMemberInsertPopup] = useState(false);
    const [showTeamMemberEditPopup, setShowTeamMemberEditPopup] = useState(false);
    const [teamMemberEditID, setTeamMemberEditID] = useState(false);
    const [lawFirmUpdateSuccess, setLawFirmUpdateSuccess] = useState(false);
    const [locationAddEditPopup, setLocationAddEditPopup] = useState(false);
    const [showInformationPopup, setShowInformationPopup] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const uData = [5, 4, 3];
    const currentYear = new Date().getFullYear(); // Get the current year
    const xLabels = Array.from({ length: 3 }, (v, i) => `${currentYear - i}`);

    // Datagrid Pagination
    if (!localStorage.getItem("law_firm_team_member_pagesize")) {
        localStorage.setItem("law_firm_team_member_pagesize", 10);
    }
    if (!localStorage.getItem("law_firm_location_pagesize")) {
        localStorage.setItem("law_firm_location_pagesize", 10);
    }
    const [loadingLawFirm, setLoadingLawFirm] = useState(true);
    const [loadingTeamMember, setLoadingTeamMember] = useState(true);
    const [loadingTeamMemberTable, setLoadingTeamMemberTable] = useState(true);
    const [rowCountTeamMember, setRowCountTeamMember] = useState(0);
    const [controllerTeamMember, setControllerTeamMember] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("law_firm_team_member_pagesize"))
    });
    const gatedSetControllerTeamMember = useCallback((value) => {
        setControllerTeamMember(value);
    }, []);
    const [loadingLocation, setLoadingLocation] = useState(true);
    const [loadingLocationTable, setLoadingLocationTable] = useState(true);
    const [rowCountLocation, setRowCountLocation] = useState(0);
    const [controllerLocation, setControllerLocation] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("law_firm_location_pagesize"))
    });
    const gatedSetControllerLocation = useCallback((value) => {
        setControllerLocation(value);
    }, []);
    // Datagrid Filter
    const [queryOptionsTeamMember, setQueryOptionsTeamMember] = useState({});
    const onFilterChangeTeamMember = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptionsTeamMember({filterModel: {...filterModel.items}}), 1000);
    }, []);
    const [prevFilterTeamMember, setPrevFilterTeamMember] = useState("");
    const [queryOptionsLocation, setQueryOptionsLocation] = useState({});
    const onFilterChangeLocation = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptionsLocation({filterModel: {...filterModel.items}}), 1000);
    }, []);
    const [prevFilterLocation, setPrevFilterLocation] = useState("");
    //Datagrid Sort
    const [queryOptionsSortTeamMember, setQueryOptionsSortTeamMember] = useState({});
    const handleSortModelChangeTeamMember = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortTeamMember({sortModel: [...sortModel]});
    }, []);
    const [prevSortTeamMember, setPrevSortTeamMember] = useState("");
    const [queryOptionsSortLocation, setQueryOptionsSortLocation] = useState({});
    const handleSortModelChangeLocation = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortLocation({sortModel: [...sortModel]});
    }, []);
    const [prevSortLocation, setPrevSortLocation] = useState("");

    const [lastBond, setLastBond] = useState("");
    const [bondHistory, setBondHistory] = useState([]);
    const [loadingBondHistory, setLoadingBondHistory] = useState(true);
    const [locationEditID, setLocationEditID] = useState(0);
    const [locationEditData, setLocationEditData] = useState({});

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [deleteText, setDeleteText] = useState('');
    const [deleteUserId, setDeleteUserId] = useState(0);
    const [deleteLocationId, setDeleteLocationId] = useState(0);


    const getLawFirm = async () => {
        const url = `/bond/get_law_firm/${routeParams.id}`
        try {
            const token = localStorage.getItem('userdata');
            const response = await fetch(`${API_URL}${url}`, {
                method: "GET", headers: {
                    'Authorization': 'Bearer ' + token, "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                log('getLawFirm,', response.status);
                const result = await response.json();
                lawFirm.logo = result.logo
                lawFirm.name = result.name
                lawFirm.website = result.website
                lawFirm.customer_number = result.customer_number
                lawFirm.physical_address_name = result.physical_address_name
                lawFirm.physical_address = result.physical_address
                lawFirm.mailing_address_name = result.mailing_address_name
                lawFirm.mailing_address = result.mailing_address
                lawFirm.billing_address_name = result.billing_address_name
                lawFirm.billing_address = result.billing_address
                lawFirm.accounting_contact_full_name = result.accounting_contact_full_name
                lawFirm.accounting_contact_email = result.accounting_contact_email
                lawFirm.accounting_contact_phone = result.accounting_contact_phone
                lawFirm.is_active = result.is_active
                lawFirm.updated_at = result.updated_at
                setLawFirm({...lawFirm, lawFirm});
            } else {
                const result = await response.json();
                enqueueSnackbar(result, {variant: "error"});
            }
        } catch (error) {
            log(error);
        }
    }

    const getTeamMembers = async (id, type, is_refresh) => {
        let filter = ""
        if (queryOptionsTeamMember.filterModel) {
            filter = queryOptionsTeamMember.filterModel[0].field + "-" + queryOptionsTeamMember.filterModel[0].operator + "-" + queryOptionsTeamMember.filterModel[0].value
        }
        let sort = ""
        if (queryOptionsSortTeamMember.sortModel) {
            if (queryOptionsSortTeamMember.sortModel.length > 0) {
                if (prevSortTeamMember) {
                    sort = prevSortTeamMember
                }
                let sortModel = queryOptionsSortTeamMember.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortTeamMember !== sort) {
                    setPrevSortTeamMember(sort)
                } else {
                    if (queryOptionsTeamMember.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        let response = await getVermostLawFirmUsersPagination(id, type, controllerTeamMember.page, controllerTeamMember.pageSize, filter, sort);
        if (response.status === 200) {
            const result = await response.json();
            log("result.data,", result.data);

            setRowCountTeamMember(result.total)
            setTeamMemberList(result.data)
            if (result.data.length === 0) {
                setShowInformationPopup(true);
            } else {
                setShowInformationPopup(false);
            }
            if (is_refresh)
                enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }
    const getLocations = async (id, is_refresh) => {
        let filter = ""
        if (queryOptionsLocation.filterModel) {
            filter = queryOptionsLocation.filterModel[0].field + "-" + queryOptionsLocation.filterModel[0].operator + "-" + queryOptionsLocation.filterModel[0].value
        }
        let sort = ""
        if (queryOptionsSortLocation.sortModel) {
            if (queryOptionsSortLocation.sortModel.length > 0) {
                if (prevSortLocation) {
                    sort = prevSortLocation
                }
                let sortModel = queryOptionsSortLocation.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortLocation !== sort) {
                    setPrevSortLocation(sort)
                } else {
                    if (queryOptionsLocation.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        let response = await getVermostLawFirmLocationsPagination(id, controllerLocation.page, controllerLocation.pageSize, filter, sort);
        if (response.status === 200) {
            const result = await response.json();
            setLocationList(result.data);
            setRowCountLocation(result.total);
            if (is_refresh)
                enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }

    const getLastBondTable = async () => {
        let response = await getLastBond(routeParams.id);
        if (response.status === 200) {
            response = await response.json();
            console.log("response", response)
            setLastBond(formatUTCDateTimeMDY(response[0]));
        } else if (response.status === 204) {
            setLastBond("-");
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }
    const getBondHistoryTable = async () => {
        let response = await getBondHistory(routeParams.id);
        if (response.status === 200) {
            response = await response.json();
            let bond_history = []
            bond_history.push(response.one_year_ago);
            bond_history.push(response.two_yrs_ago);
            bond_history.push(response.three_yrs_ago);
            setBondHistory(...bondHistory, bond_history);
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }
    const closePopup = () => {
        setIsEdit(false);
        setLocationAddEditPopup(false);
        setLocationEditID(0);
        setLocationEditData({});
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        if (routeParams && routeParams.id && routeParams.id !== '0') {
            setIsEdit(true);
            setLoading(true);
            setLoadingMessage('Loading Law Firm');
            if (loadingLawFirm) {
                await getLawFirm();
                setLoadingBondHistory(true);
                await getLastBondTable();
                await getBondHistoryTable();
                setLoadingBondHistory(false);
            }
            setLoading(false);
        }
        setLoadingLawFirm(false);
    }, []);

    useEffect(async () => {
        log('lawFirmUpdateSuccess1,', lawFirmUpdateSuccess);

        if (lawFirmUpdateSuccess) {
            log('lawFirmUpdateSuccess2,', lawFirmUpdateSuccess);
            setLoadingLawFirm(true);
            await getLawFirm();
            setLoadingLawFirm(false);
            setLawFirmUpdateSuccess(false);
        }
    }, [lawFirmUpdateSuccess]);

    useEffect(async () => {
        try {
            if (routeParams && routeParams.id && routeParams.id !== '0') {
                setLoadingTeamMember(true);
                await getTeamMembers(routeParams.id, "TEAM_MEMBERS", false);
            }
            setLoadingTeamMember(false);
            setLoadingTeamMemberTable(false);
        } catch {
            setLoadingTeamMember(false);
            setLoadingTeamMemberTable(false);
        }
    }, [controllerTeamMember, queryOptionsTeamMember, queryOptionsSortTeamMember]);

    useEffect(async () => {
        try {
            if (routeParams && routeParams.id && routeParams.id !== '0') {
                setLoadingLocation(true);
                await getLocations(routeParams.id, false);
            }
            setLoadingLocation(false);
        } catch {
            setLoadingLocation(false);
        }
    }, [controllerLocation, queryOptionsLocation, queryOptionsSortLocation]);

    const onCreateCallback = async (type, values) => {
        try {
            if (ActionType.edit === type) {
                log("val data,", values);
                if (values["physical_address"] === 0) {
                    delete values["physical_address"]
                }
                if (values["mailing_address"] === 0) {
                    delete values["mailing_address"]
                }
                if (values["billing_address"] === 0) {
                    delete values["billing_address"]
                }
                let formData = new FormData();
                Object.keys(values).forEach((key) => {
                    formData.append(key, values[key]);
                });

                var response = await editVermostLawFirmBackend(routeParams.id, formData);
                if (response?.status === 200) {
                    enqueueSnackbar("The law firm update successfully.", {variant: "success"});
                    setLawFirmUpdateSuccess(true);
                } else {
                    const result = await response?.json();
                    enqueueSnackbar(result, {variant: "error"});
                }

            } else if (ActionType.insert === type) {
                let formData = new FormData();
                Object.keys(values).forEach((key) => {
                    if (key !== 'locations') {
                        formData.append(key, values[key]);
                    } else {
                        let locationsString = JSON.stringify(values["locations"]);
                        formData.append("locations", locationsString);
                    }
                });
                let response = await addVermostLawFirmBackend(formData);
                if (response.status === 201) {
                    let new_law_firm = await response.json()
                    window.location.href = `/law_firm/${new_law_firm.data.id}`;
                } else {
                    const result = await response.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
            }
        } catch (error) {
            log(error);
        }

    }
    const refreshLocationTable = async (isRefresh) => {
        setLoadingLocationTable(true);
        await getLocations(routeParams.id, isRefresh)
        setLoadingLocationTable(false);
    }

    const refreshTeamMemberTable = async (isRefresh) => {
        setLoadingTeamMemberTable(true);
        await getTeamMembers(routeParams.id, "TEAM_MEMBERS", isRefresh)
        setLoadingTeamMemberTable(false);
    }

    const deleteTeamMember = async (userId, showPopup) => {
        if (showPopup) {
            setDeleteText('Law Firm Team Member');
            setDeleteUserId(userId);
            return 0;
        }
        let response = await deleteUserLawFirmBackend(userId);
        if (response.status === 200) {
            await refreshTeamMemberTable(false);
            enqueueSnackbar('Law Firm Team Member is active field changed successfully.', {variant: "success"});
            window.location.reload(true);
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }
    const deleteLocation = async (locationId, showPopup) => {
        if (showPopup) {
            setDeleteText('Law Firm Location');
            setDeleteLocationId(locationId);
            return 0;
        }
        let response = await deleteVermostLocationBackend(locationId);
        if (response.status === 200) {
            await refreshLocationTable(false);
            enqueueSnackbar('Law Firm Location is active field changed successfully.', {variant: "success"});
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }


    const onCallbackLocation = async (actionType, obj) => {
        switch (actionType) {
            case ActionType.insert:
                log("obj insert,", obj);
                setLocationAddEditPopup(true);
                //navigate(`/law_firm_location/0/${routeParams.id}`, {state: {history: `/law_firm/${routeParams.id}`}});
                break;
            case ActionType.edit:
                log("obj edit onCallbackLocation,", obj);
                setLocationEditID(obj.id);
                locationEditData.name = obj.name
                locationEditData.customer_number = obj.customer_number
                locationEditData.phone = obj.phone
                locationEditData.fax = obj.fax
                locationEditData.address = obj.address
                locationEditData.city = obj.city
                locationEditData.county = obj.county
                locationEditData.country = obj.country
                locationEditData.state = obj.state
                locationEditData.zip_code = obj.zip_code
                locationEditData.use_seperate_firm_accounting_contact = obj.use_seperate_firm_accounting_contact
                locationEditData.accounting_contact_full_name = obj.accounting_contact_full_name
                locationEditData.accounting_contact_email = obj.accounting_contact_email
                locationEditData.accounting_contact_phone = obj.accounting_contact_phone
                locationEditData.is_active = obj.is_active
                locationEditData.updated_at = obj.updated_at
                setLocationEditData({...locationEditData, locationEditData});
                setLocationAddEditPopup(true);
                break;
            case ActionType.refresh:
                await refreshLocationTable(true);
                break;
            case ActionType.download:
                exportToExcel(locationList, "Law Firm Location");
                break;
            case ActionType.delete:
                await deleteLocation(obj.id, true);
                break;
        }
    }
    const onCallbackTeamMember = async (actionType, obj) => {
        switch (actionType) {
            case ActionType.insert:
                setShowTeamMemberInsertPopup(true);
                break;
            case ActionType.edit:
                setTeamMemberEditID(obj.user);
                setShowTeamMemberEditPopup(true);
                break;
            case ActionType.refresh:
                await refreshTeamMemberTable(true);
                break;
            case ActionType.download:
                exportToExcel(teamMemberList, "Team Member List");
                break;
            case ActionType.delete:
                await deleteTeamMember(obj.id, true);
                break;
        }
    }
    const onActionCallbackTeamMember = async (actionType, newValues, id) => {
        let response;
        switch (actionType) {
            case ActionType.insert:
                log("insert, newValues", newValues);
                response = await addVermostLawFirmUser(routeParams.id, newValues);
                if (response.status === 200) {
                    await refreshTeamMemberTable();
                    enqueueSnackbar("The team member insert successfully.", {variant: "success"});
                } else {
                    const result = await response?.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
                setShowTeamMemberInsertPopup(false);
                break;
            case ActionType.edit:
                log("edit location id", id)
                // user edit function
                setShowTeamMemberEditPopup(false);
                break;
        }
    }
    const onActionCallbackLocation = async (actionType, newValues, id) => {
        let response;
        switch (actionType) {
            case ActionType.insert:
                response = await addVermostLawFirmLocation(routeParams.id, newValues);
                if (response.status === 200) {
                    await getLawFirm();
                    await refreshLocationTable(false);
                    setLawFirmUpdateSuccess(true);
                    enqueueSnackbar("The law firm location insert successfully.", {variant: "success"});
                } else {
                    const result = await response?.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
                setLocationAddEditPopup(false);
                break;
            case ActionType.edit:
                log("edit location id", id)
                response = await editVermostLawFirmLocation(id, newValues);
                if (response.status === 200) {
                    await getLawFirm();
                    await refreshLocationTable(false);
                    setLawFirmUpdateSuccess(true);
                    enqueueSnackbar("The law firm location edit successfully.", {variant: "success"});
                } else {
                    const result = await response?.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
                setLocationAddEditPopup(false);
                break;
        }
    }


    return (<>
            <LoadingOverlay active={loading} spinner text={loadingMessage}>
                <CusNavBar/>
                {routeParams.id !== "0" && <GoBack pageUrl="/law_firm_list"></GoBack>}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{width: '100%', height: '100%', padding: "0", margin: "0"}}>
                            <CardContent sx={{width: '100%', margin: "0"}}>
                                <Row>
                                    {
                                        routeParams.id === "0" ?
                                            (<>
                                                    <Col className={"col-12"}>
                                                        <LawFirmMain data={lawFirm} id={0}
                                                                     oncallback={onCreateCallback}
                                                                     lawFirmCreatePage={true}></LawFirmMain>
                                                    </Col>
                                                </>
                                            ) :
                                            (<>
                                                <Col xl={4}>
                                                    {
                                                        !loadingLawFirm &&
                                                        <LawFirmMain data={lawFirm} id={routeParams.id}
                                                                     oncallback={onCreateCallback}></LawFirmMain>
                                                    }
                                                </Col>
                                                <Col xl={8}>
                                                    <Row className={"mb-3"}>
                                                        {
                                                            !loadingTeamMember &&
                                                            <UserList title="TEAM_MEMBER"
                                                                      data={teamMemberList}
                                                                      locationList={locationList}
                                                                      isEdit={isEdit}
                                                                      addPermission={true}
                                                                      rowCount={rowCountTeamMember}
                                                                      paginationModel={controllerTeamMember}
                                                                      onPaginationModelChange={gatedSetControllerTeamMember}
                                                                      onFilterModelChange={onFilterChangeTeamMember}
                                                                      onSortModelChange={handleSortModelChangeTeamMember}
                                                                      loading={loadingTeamMemberTable}
                                                                      showUserInsertPopup={showTeamMemberInsertPopup}
                                                                      showUserEditPopup={showTeamMemberEditPopup}
                                                                      setShowUserInsertPopup={setShowTeamMemberInsertPopup}
                                                                      setShowUserEditPopup={setShowTeamMemberEditPopup}
                                                                      onCallback={onCallbackTeamMember}
                                                                      onActionCallback={onActionCallbackTeamMember}
                                                                      editID={routeParams.id}
                                                                      isWebAdministrator={false}
                                                                      showBoolean={true}
                                                                      showLocation={true}
                                                                      lawFirmID={routeParams.id}>
                                                            </UserList>
                                                        }
                                                    </Row>
                                                    <Row className={"mb-3"}>
                                                        {!loadingLocation &&
                                                            <LocationList data={locationList}
                                                                          isEdit={isEdit}
                                                                          rowCount={rowCountLocation}
                                                                          paginationModel={controllerLocation}
                                                                          onPaginationModelChange={gatedSetControllerLocation}
                                                                          onFilterModelChange={onFilterChangeLocation}
                                                                          onSortModelChange={handleSortModelChangeLocation}
                                                                          loading={loadingLocation}
                                                                          showLocationInsertPopup={locationAddEditPopup}
                                                                          closePopup={closePopup}
                                                                          editID={locationEditID}
                                                                          editData={locationEditData}
                                                                          onCallback={onCallbackLocation}
                                                                          onActionCallback={onActionCallbackLocation}>
                                                            </LocationList>
                                                        }
                                                    </Row>
                                                    {!loadingBondHistory && super_user && isEdit && <Row>
                                                        <Col className={"col-4"}>
                                                            <LawFirmHistory
                                                                lastUpdate={formatUTCDateTimeMDY(lawFirm.updated_at)}
                                                                lastBond={lastBond}>
                                                            </LawFirmHistory>
                                                        </Col>
                                                        <Col className={"col-8"}>
                                                            {!loadingBondHistory &&
                                                                <LawFirmBondHistory series={bondHistory}
                                                                                    labels={xLabels}>
                                                                </LawFirmBondHistory>}
                                                        </Col>
                                                    </Row>}
                                                </Col>
                                            </>)
                                    }
                                </Row>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {showTeamMemberEditPopup &&
                    (
                        <AddEditTeamMember show={true} editID={teamMemberEditID} lawFirmEditID={routeParams.id}
                                           closePopup={setShowTeamMemberEditPopup}></AddEditTeamMember>
                    )
                }
                <br/>
            </LoadingOverlay>
            {
                deleteUserId !== 0 &&
                <ActiveAccountManagementPopup show={true}
                                              closePopup={setDeleteUserId}
                                              delete_callback={deleteTeamMember}
                                              delete_id={deleteUserId}
                                              delete_text={deleteText}/>
            }
            {
                deleteLocationId !== 0 &&
                <ActiveAccountManagementPopup show={true}
                                              closePopup={setDeleteLocationId}
                                              delete_callback={deleteLocation}
                                              delete_id={deleteLocationId}
                                              delete_text={deleteText}/>
            }
            {
                <ConfirmationPopup show={showInformationPopup}
                                   setOpenConfirmationPopup={setShowInformationPopup}
                                   title="You must add a new user/team member for this Law Firm."
                                   handleClose={{}}
                                   isDisable={true}/>
            }
            <Footer/>
        </>
    )
};

export default LawFirm;