import React, { useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import { addLawSchoolBackend, updateLawSchoolBackend } from "../../services/company_management";
import { addLawSchool, updateLawSchool, removeLawSchool } from "../../store/company_management";
import Select from "react-select";

export default function LawSchoolPopup(props) {
    const dispatch = useDispatch();
    const lawSchoolInStore = useSelector((state) => state.companyManagement.law_schools);
    const [loading, setLoading] = useState(false);

    let law_school_details = {};
    if(props.law_school_id !== 0){
        law_school_details = lawSchoolInStore.filter((law_school) => law_school.id === props.law_school_id)[0];
    }

    const getInitialValues = () => {
        if (props.law_school_id !== 0){
            return {name: law_school_details.name, 
                is_active: law_school_details.is_active}
        }else{
            return {name: '', is_active: true}
        }
    }

    const getValidation = () => {
        if (props.law_school_id !== 0){
            return Yup.object({
                name: Yup.string().required('Law School Name is required.'),
                is_active: Yup.boolean().oneOf([true, false], 'Active status is required.'),
            })
        }else{
            return Yup.object({
                name: Yup.string().required('Law School Name is required.'),
            })
        }
    }

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            if (props.law_school_id!==0){
                await updateLawSchoolBackend(props.law_school_id, values).then((res)=>{
                    if(res === 200){
                        props.showSuccessAlert(true);
                        props.firstLawSchoolLoading(true);
                        props.setSuccessMessage('Law School updated successfully');
                        dispatch(updateLawSchool({id: props.law_school_id,
                            name: values.name, is_active: values.is_active}))

                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(0);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(0);
                })
            }else{
                await addLawSchoolBackend(values).then((res)=>{                                        
                    if (res.status === 201){
                        props.showSuccessAlert(true);
                        props.firstLawSchoolLoading(true);
                        props.setSuccessMessage('Law School created successfully.');
                        dispatch(addLawSchool(res.data))
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(false);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(false);
                })
    

            }
        },
        validationSchema:getValidation()
    });
    const [isActiveCheckbox, setIsActiveCheckbox] = useState(props.law_school_id !== 0 ? law_school_details.is_active : true);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Law School
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key="name" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={'required-label'}><strong>Law School</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='name'
                                          defaultValue={props.law_school_id !== 0 ? law_school_details.name : ''}/>
                            {formIk.errors['name'] && formIk.touched['name'] && (
                                <div className="text-error">{formIk.errors['name']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                {props.law_school_id !== 0 &&
                    <Row>
                        <Col>
                            <Form.Group key={'is_active'} controlId="formFileSm" className="mb-3">
                                <Form.Label className={'required-label'}><strong>Active</strong></Form.Label>
                                <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                        onChange={() => {
                                            {
                                                formIk.setFieldValue('is_active', !isActiveCheckbox);
                                                setIsActiveCheckbox(!isActiveCheckbox);
                                            }
                                        }}
                                        name='is_active' checked={isActiveCheckbox}/>
                                {formIk.errors['is_active'] && formIk.touched['is_active'] && (
                                    <div className="text-error">{formIk.errors['is_active']}</div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => formIk.submitForm()}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

LawSchoolPopup.propTypes = {
    law_school_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    showSuccessAlert: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    showFailureAlert: PropTypes.func.isRequired,
    setFailureMessage: PropTypes.func.isRequired,
    firstLawSchoolLoading: PropTypes.func,
}
