import React, { useState } from 'react';
import PropTypes from "prop-types";

export default function CustomNumberInput(props) {
    const [value, onChange] = useState(props.value);

    return (
        <input type="number" className={props.className} name={props.name} id={props.id} defaultValue={value} min={props.min} max={props.max}
               disabled={props.disabled} onChange={(value)=> { onChange(value.target.value);
            props.onChange(value.target.value);}} onBlur={props.onBlur}/>
    );
}

CustomNumberInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    min: PropTypes.string,
    max: PropTypes.string
};
