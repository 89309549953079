import { API_URL } from "../constants";
import history from '../global_components/history';
import { getAPICall } from './helper';

export async function login(data){
    const response = await fetch(`${API_URL}/token/`, {
                        method: "POST",
                        body: JSON.stringify({
                            ...loginData(data)
                        }),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200){
        return await response.json();
    }
}

export async function login_without_password(data){
    const response = await fetch(`${API_URL}/token_without_password/`, {
        method: "POST",
        body: JSON.stringify({
            ...loginData(data)
        }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    if (response.status === 200){
        return await response.json();
    }
}

export async function logout(){
    // localStorage.removeItem('userdata');
    // localStorage.removeItem('persist:root');
    localStorage.clear();
    history.push('/');
}

export async function getCompany(){
    return await getAPICall('/company_details/');
}

export async function getUserInformation(){
    return await getAPICall('/user/get_user_info/');
}

const loginData = ({
        email: email,
        password: password,
    }) => ({
    email,
    password
});
