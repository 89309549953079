import React, { useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addPOPermissionBackend,
    updatePOPermissionBackend
} from "../../services/company_management";
import {addNewPOPermission, updatePOPermission} from "../../store/company_management";
import Select from "react-select";

export default function POPermissionPopup(props) {
    const dispatch = useDispatch();
    const userInStore = useSelector((state) => state.companyManagement.users);
    const departmentsInStore = useSelector((state) => state.companyManagement.departments);
    const poPermissionsInStore = useSelector((state) => state.companyManagement.po_permissions);
    let selectedPoPermission = {};
    let selectedUser = {};
    let selectedDepartment = {};
    if(props.popermission_id!==0){
        selectedPoPermission = poPermissionsInStore.filter((poPermission)=>poPermission.id === props.popermission_id)[0];
    }
    if (selectedPoPermission){
        selectedUser = userInStore.filter((user)=>user.id === selectedPoPermission.user)[0];
        selectedDepartment = departmentsInStore.filter((department)=>department.id === selectedPoPermission.department)[0];
    }
    const [loading, setLoading] = useState(false);
    const [canViewAccount, setCanViewAccount] = useState(Object.keys(selectedPoPermission).length>0 ?
        selectedPoPermission.view_account_permission: false);
    const getUserOptions = () => {
        let userOpts = [];
        userInStore.map((user)=> userOpts.push({label: `${user.email}`, value: user.id}))
        return userOpts;
    }

    const getDepartmentOptions = () => {
        let depOpts = [];
        departmentsInStore.map((dep)=> depOpts.push({label: dep.name, value: dep.id}))
        return depOpts;
    }

    const formIk = useFormik({
        initialValues: {user_id: Object.keys(selectedPoPermission).length>0 ? selectedPoPermission.user : '',
            department_id: Object.keys(selectedPoPermission).length>0 ? selectedPoPermission.department : '',
            view_account_permission: Object.keys(selectedPoPermission).length>0 ? selectedPoPermission.view_account_permission: false,
            },
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            if (props.popermission_id !== 0){
                const changed_dep = departmentsInStore.filter((dep)=>dep.id===values.department_id)[0];
                const changed_user = userInStore.filter((user)=>user.id===values.user_id)[0];
                await updatePOPermissionBackend(props.popermission_id, values).then((res)=>{
                    if(res === 200){
                        props.showSuccessAlert(true);
                        props.setSuccessMessage('PO permission updated successfully');
                        dispatch(updatePOPermission({id: selectedPoPermission.id,
                            user_name: `${changed_user.email}`, user: changed_user['id'],
                            department_name: changed_dep.name,
                            department: changed_dep.id,
                            view_account_permission: values['view_account_permission']}));
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(0);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(0);
                })
            }else{
                await addPOPermissionBackend(values).then((res)=>{
                    props.showSuccessAlert(true);
                    props.setSuccessMessage('PO permissions added successfully');
                    dispatch(addNewPOPermission({...res}));
                    setLoading(false);
                    props.closePopup(false);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(false);
                })
            }
        },
        validationSchema:Yup.object({
            user_id: Yup.string().required('User is required.'),
            department_id: Yup.string().required('Department is required.'),
            view_account_permission: Yup.boolean().oneOf([true, false], 'Can View Account status is required.'),
        })
    });
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    User Details.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key={'user_id'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}>User</Form.Label>
                            <Select
                                placeholder={"Select User"}
                                name="user_id"
                                onChange={(opt, e) => {
                                    formIk.setFieldValue('user_id', opt.value);
                                }}
                                options={getUserOptions()}
                                defaultValue={(props.popermission_id !== 0 && selectedUser) ?
                                    { label: `${selectedUser.email}`,value: selectedUser.id } : ''}
                            />
                            {formIk.errors['user_id'] && (
                                <div className="text-error">{formIk.errors['user_id']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key={'department_id'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}>Department</Form.Label>
                            <Select
                                placeholder={"Select Department"}
                                name="department_id"
                                onChange={(opt, e) => {
                                    formIk.setFieldValue('department_id', opt.value);
                                }}
                                options={getDepartmentOptions()}
                                defaultValue={(props.popermission_id !== 0 && selectedDepartment) ?
                                    {
                                        label: selectedDepartment.name,
                                        value: selectedDepartment.id
                                    } : ''}
                            />
                            {formIk.errors['department_id'] && (
                                <div className="text-error">{formIk.errors['department_id']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key={'view_account_permission'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={'required-label'}>Can View Accounts</Form.Label>
                            <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                   onChange={() => {
                                       setCanViewAccount(!canViewAccount);
                                       formIk.setFieldValue('view_account_permission', !canViewAccount)
                                   }}
                                   name='view_account_permission' checked={canViewAccount}/>
                            {formIk.errors['view_account_permission'] && formIk.touched['view_account_permission'] && (
                                <div className="text-error">{formIk.errors['view_account_permission']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => formIk.submitForm()}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

POPermissionPopup.propTypes = {
    popermission_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    showSuccessAlert: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    showFailureAlert: PropTypes.func.isRequired,
    setFailureMessage: PropTypes.func.isRequired,
}
