import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {
    deleteLawFirmProfessionalGuardianBackend, deleteUserBackend, deleteVermostUserBackend,
    getLawFirmProfessionalGuardianColumns,
    getLawFirmProfessionalGuardiansPagination,
} from "../../../../../services/company_management";
import {
    addLawFirmProfessionalGuardians,
    removeLawFirmProfessionalGuardian,
    removeLawFirmProfessionalGuardians

} from "../../../../../store/company_management";
import {useNavigate} from "react-router";
import {hasUserPermission} from "../../../../../utils/user_permission";
import CustomDataGrid from "../../../../../global_components/datatables/custom_datagrid";
import Footer from "../../../../../global_components/footer";
import CusNavBar from "../../../../../global_components/navbar";
import Header from "../../../../../global_components/header";
import LawFirmProfessionalGuardianPopup from "../../../../../components/popups/law_firm_professional_guardian_popup";
import {exportToExcel} from "../../../../../utils/excel_export";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import ActiveAccountManagementPopup from "../../../../../components/popups/vermost/account_management_active_popup";
import {useSnackbar} from "notistack";

function ProfessionalGuardianList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [lawFirmProfessionalGuardianLoading, setLawFirmProfessionalGuardianLoading] = useState(false);
    const [lawFirmProfessionalGuardianToEdit, setLawFirmProfessionalGuardianToEdit] = useState(0);
    const [addLawFirmProfessionalGuardian, setAddLawFirmProfessionalGuardian] = useState(false);
    const allLawFirmProfessionalGuardiansInStore = useSelector((state) => state.companyManagement.law_firm_professional_guardians);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');
    const hasAddPermission =hasUserPermission(user_permission, 'show_table_add_button')

    // Datagrid Pagination
    if (!localStorage.getItem("profesional_guard_list_pagesize")) {
        localStorage.setItem("profesional_guard_list_pagesize", 10);
    }
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("profesional_guard_list_pagesize"))
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);
    // Datagrid Filter
    const [queryOptions, setQueryOptions] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptions({filterModel: {...filterModel.items}}), 1000);
    }, []);

    const [prevFilter, setPrevFilter] = useState("");
    //Datagrid Sort
    const [queryOptionsSort, setQueryOptionsSort] = useState({});
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSort({sortModel: [...sortModel]});
    }, []);
    const [prevSort, setPrevSort] = useState("");

    const [professionalGuardiansJsonData, setProfessionalGuardiansJsonData] = useState([]);
    const [deleteId, setDeleteId] = useState(0);
    const [deleteText, setDeleteText] = useState('');
    const {enqueueSnackbar} = useSnackbar();


    const deleteLawFirmProfessionalGuardian = async (lawFirmProfessionalGuardianId, showPopup) => {
        if (showPopup) {
            setDeleteText('professional guardian');
            setDeleteId(lawFirmProfessionalGuardianId);
            return 0;
        }
        setLawFirmProfessionalGuardianLoading(true);
        let response = await deleteVermostUserBackend(lawFirmProfessionalGuardianId)
        if (response.status === 200) {
            enqueueSnackbar("Profesional Guardian is active field changed successfully.", {variant: "success"});
            refreshLawFirmProfessionalGuardian(false);
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }

        setLawFirmProfessionalGuardianLoading(false);
    }
    const updateLawFirmProfessionalGuardian = (lawFirmProfessionalGuardianId) => {
        setLawFirmProfessionalGuardianToEdit(lawFirmProfessionalGuardianId);
    }
    const lawFirmProfessionalGuardianColumns = getLawFirmProfessionalGuardianColumns(deleteLawFirmProfessionalGuardian, updateLawFirmProfessionalGuardian, hasUpdatePermission, hasDeletePermission);


    const refreshLawFirmProfessionalGuardian = async (is_refresh) => {
        setLawFirmProfessionalGuardianLoading(true);
        let filter = ""
        if (queryOptions.filterModel) {
            if (queryOptions.filterModel[0] != undefined)
                filter = queryOptions.filterModel[0].field + "-" + queryOptions.filterModel[0].operator + "-" + queryOptions.filterModel[0].value;
            else
                filter = "is_active-is-true";
        }

        let sort = ""
        if (queryOptionsSort.sortModel) {
            if (queryOptionsSort.sortModel.length > 0) {
                if (prevSort) {
                    sort = prevSort
                }
                let sortModel = queryOptionsSort.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSort !== sort) {
                    setPrevSort(sort)
                } else {
                    if (queryOptions.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        let response = await getLawFirmProfessionalGuardiansPagination(controller.page, controller.pageSize, filter, sort);
        if (response.status === 200) {
            const result = await response.json();
            dispatch(removeLawFirmProfessionalGuardians());
            dispatch(addLawFirmProfessionalGuardians(result.data));
            setProfessionalGuardiansJsonData(result.data);
            setRowCount(result.total);
            if (is_refresh)
                enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
        setLawFirmProfessionalGuardianLoading(false);
    }


    const onCallbackFun = async (type, obj) => {
        if (type === 'insert') {
            navigate("/user_management/0")
        } else if (type === 'refresh') {
            refreshLawFirmProfessionalGuardian(true);
        } else if (type === 'edit') {
            navigate(`/user_management/${obj.id}`)
        } else if (type === "delete") {
            deleteLawFirmProfessionalGuardian(obj.id, true);
        } else if (type === "download") {
            exportToExcel(allLawFirmProfessionalGuardiansInStore, "Professional Guardian");
        }
    }

    useEffect(() => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        document.title = "Proffessional Guardians";

        refreshLawFirmProfessionalGuardian(false);
    }, [controller, queryOptions, queryOptionsSort]);


    return (
        <>
            <CusNavBar/>
            <Header text="Professional Guardians"/>
            {
                deleteId !== 0 && <ActiveAccountManagementPopup show={true} closePopup={setDeleteId}
                                                                delete_callback={deleteLawFirmProfessionalGuardian}
                                                                delete_id={deleteId} delete_text={deleteText}/>
            }
            {hasUserPermission(user_permission, 'show_professional_guardian_account_management') &&
                <div className="col-12" style={{margin: "auto"}}>
                    <CustomDataGrid rows={professionalGuardiansJsonData}
                                    columns={lawFirmProfessionalGuardianColumns}
                                    rowCount={rowCount}
                                    paginationModel={controller}
                                    onPaginationModelChange={gatedSetController}
                                    onFilterModelChange={onFilterChange}
                                    onSortModelChange={handleSortModelChange}
                                    loading={lawFirmProfessionalGuardianLoading}
                                    isActionsVisible={true}
                                    isEdit={true}
                                    onCallback={onCallbackFun}
                                    disableRowSelectionOnClick={true}
                                    addPermission={hasAddPermission}
                                    deletePermission={hasDeletePermission}
                                    updatePermission={hasUpdatePermission}
                                    visibleActionButtons={true}
                                    filterModel={{
                                        items: [{field: 'is_active', operator: 'is', value: true}],
                                    }}/>
                </div>

            }
            {(lawFirmProfessionalGuardianToEdit !== 0 || addLawFirmProfessionalGuardian) &&
                <LawFirmProfessionalGuardianPopup show={true}
                                                  law_firm_professional_guardian_id={lawFirmProfessionalGuardianToEdit}
                                                  closePopup={lawFirmProfessionalGuardianToEdit !== 0 ? setLawFirmProfessionalGuardianToEdit : setAddLawFirmProfessionalGuardian}
                />
            }


            <br/>
            <Footer/>
        </>
    )

}

export default ProfessionalGuardianList;