import { api } from "./api";

const getInvoice = async id => {
  try {
    return await api.get(`invoice/${id}`);
  } catch (err) {
    throw err;
  }
};

const patchInvoice = async (id, data) => {
  try {
    return await api.patch(`invoice/${id}`, data);
  } catch (err) {
    throw err;
  }
};

export { getInvoice, patchInvoice };
