import React, { useState } from 'react';
import PropTypes from "prop-types";

export default function CustomLongInput(props) {
    const [value, onChange] = useState(props.value);

    return (
        <textarea className={props.className} name={props.name} id={props.id} defaultValue={value}
                  disabled={props.disabled} onChange={(value)=> {
                      onChange(value.target.value);
                      props.onChange(value.target.value);
                  }}
                  placeholder={props.placeholder} />
    );
}

CustomLongInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};
