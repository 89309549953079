import React, {useEffect, useState} from 'react';
import '../../styles/login.css';
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import CustomSelectInput from "../../global_components/inputs/select-field";
import {getProcesses, getProcessInputs} from "../../services/process";
import {addBondProcessDetails, addBondProcessInputs} from "../../store/bond";
import {calculatePremium} from "../../utils/vermost_calculator"
import {Form, Row} from "react-bootstrap";
import CustomAmountInput from "../../global_components/inputs/amount";

function BondCalculator(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [premiumBondAmount, setPremiumBondAmount] = useState('');
    const [bondAmount, setBondAmount] = useState('');
    const processInput = useSelector((state) => state.bond.bond_process_input);
    const normalInputs = processInput.filter((input) => input.is_line_item === false);
    const [stateValue, setStateValue] = useState('Florida');
    const [countyValue, setCountyValue] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        if (processInput.length === 0) {
            getProcesses('Request Bond').then((response) => {
                dispatch(addBondProcessDetails(response));
                getProcessInputs(response[0].id).then(async (res) => {
                    dispatch(addBondProcessInputs(res));
                    setLoading(true);
                }).catch((err) => {
                    console.log(err);
                });
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            });
        } else {
            setLoading(true);
        }
    }, [])
    const cleanData = () => {
        setBondAmount('');
        setPremiumBondAmount('');
        setStateValue('Florida');
        setCountyValue('');
    }
    const onInputChange = (event) => {
        if (event.target.value === null) {
            setPremiumBondAmount('');
        } else {
            let getAmount = event.target.value.replace('$ ', '').replaceAll(',', '');
            let premium = calculatePremium(getAmount);

            setPremiumBondAmount('$ ' + Intl.NumberFormat('en-US').format(premium).toString());
            setBondAmount('$ ' + Intl.NumberFormat('en-US').format(getAmount).toString());
        }
    }
    return (<>
        {loading &&
            <div className="col-12 spaceCard bond-creater">
                <div className="card ">
                    <div className="card-body">
                        <ul className="card-text"></ul>
                        <h4 className="card-title">Bond Calculator</h4>

                        <div>
                            <Row>
                                <Form.Group key="bond_amount" className="col-12 mt-2 text-start"
                                            controlId="formBasicEmail">
                                    <Row>
                                        <Form.Label className='col-5'><strong>Bond
                                            Amount</strong></Form.Label>
                                        <input disabled={false} required={false} name="fname"
                                               className={"form-control-bond-calculator col-7"}
                                               id="bondAmount"
                                               value={bondAmount === 0 ? '' : bondAmount.toString()}
                                               onChange={onInputChange}
                                               placeholder="$ 0"
                                        />
                                    </Row>
                                </Form.Group>

                                <Form.Group key="premiumBondAmount" className="col-12 mt-2 text-start"
                                            controlId="formBasicEmail">
                                    <Row>
                                        <Form.Label className='col-5'><strong>Premium Amount</strong></Form.Label>
                                        <input disabled={true} required={false} name="lname"
                                               className={"form-control-bond-calculator form-control-bond-calculator-disabled col-7"}
                                               id="premiumBondAmount"
                                               value={bondAmount === 0 ? '' : premiumBondAmount.toString()}
                                               onChange={() => {
                                               }}
                                               placeholder="$ 0"/>
                                    </Row>
                                </Form.Group>

                            </Row>

                            <div><label key="premiumBondAmount" onClick={cleanData} className="custom-field one">
                                <button type="button" className="btn btn-primary">Clear</button>
                            </label></div>
                        </div>
                    </div>
                </div>
            </div>}
    </>)
}

export default BondCalculator;
