import React, {useState, useEffect, useRef} from 'react';
import Header from "./header";
import Footer from "./footer";
import CusNavBar from "./navbar";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import {Grid, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import Button from '@mui/material/Button';


const ServiceWorkerClean = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister();
      
          if (caches) {
            caches.keys().then(async (names) => {
              await Promise.all(names.map(name => caches.delete(name)));
            });
          }
        });
      }
}

const LocalandSessionStorageClean = () => {
    localStorage.clear()
    sessionStorage.clear()
}

const CookieClean = () => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
}

const BrowserCacheClean = (props) => {

    const newRandomNumbers = Array.from({ length: 20 }, () => ({
        value: Math.floor(Math.random() * 101),
      }));

    const [capitalProject, setCapitalProject] = useState({
        division: "",
    });
    const [testValid, setTestValid] = useState(false);
  
    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        alert('Cache Cleared')
    };

console.log(testValid)

    return (
        <>
            <CusNavBar/>
            <Header text="Browser Cache Clean"/>
            <CleaningServicesIcon onClick={() => {
                                clearCacheData();
                                ServiceWorkerClean();
                                LocalandSessionStorageClean();
                                CookieClean();
            }}  style={{ fontSize: '1.75em' }} />

            <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="division-label">
                            Test Select 
                        </InputLabel>
                        <Select
                            labelId="division-label"
                            label={"Test Select"}
                            id="division-select"
                            error={testValid}
                            value={capitalProject.division}
                            onChange={(e) =>
                                setCapitalProject({
                                    ...capitalProject,
                                    division: e.target.value,
                                })
                            }
                        >
                            {newRandomNumbers.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                            <Button variant="contained" onClick={() => {setTestValid(!testValid) }} fullWidth>
                                Test Validation
                            </Button>
                        </Grid>
            
            <Footer/>
        </>
    )

};


export default BrowserCacheClean;