import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

export default function CustomTextInput(props) {
    const [value, onChange] = useState(props.value);

    useEffect(() => {
        onChange(props.value);
    }, [props.value])

    return (
        <>
                <div className="input-group">
                    <div className="input-group-prepend">
                        {props.aka && <div className="input-group-text input-group-prepend">a/k/a</div>}
                    </div>
                    <input type="text" onBlur={props.onBlur} className={props.className} name={props.name} id={props.id}
                           defaultValue={value}  placeholder={props.placeholder}
                           disabled={props.disabled} onChange={(value) => {
                        onChange(value.target.value);
                        props.onChange(value.target.value);
                    }}/>
                </div>
        </>
    );
}

CustomTextInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    aka: PropTypes.bool
};
