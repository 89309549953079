import React from 'react'
import CusNavBar from '../../global_components/navbar';
import Footer from '../../global_components/footer';
import { useNavigate } from 'react-router';

const FSI = () => {
 const navigate = useNavigate();
  return (
    <div>
        <CusNavBar/>
        <div className='flex flex-col justify-center items-center mt-10'>
            <h1 className='font-bold'>Fluid Sealing International </h1>
        <div className='flex flex-row justify-center items-center gap-x-20 mt-20'>
            <div className='border-2 py-4 px-20 border-blue-700 font-semibold rounded-2xl bg-blue-100 text-blue-800 text-4xl cursor-pointer' onClick={()=>{navigate('/fsi-accounts-payable')}}>Accounts Payable</div>
            <div className='border-2 py-4 px-20 border-blue-700 font-semibold rounded-2xl bg-blue-100 text-blue-800 text-4xl'>Salesman Reporting</div>
        </div>
        </div>
        <Footer/>
      
    </div>
  )
}

export default FSI;
