import React from "react";
import { Button } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import PropsTypes from "prop-types";

const buttonStyles = { textTransform: "lowercase", marginRight: "20px" };

const ApplyChanges = ({ isApplay, applay }) => {
  return (
    <>
      {isApplay ? (
        <Button
          size="small"
          variant="text"
          sx={buttonStyles}
          style={{ marginRight: "40px" }}
          disabled={isApplay}
        >
          changes applied
          <DoneAllIcon sx={{ fontSize: "small", marginLeft: "5px" }} />
        </Button>
      ) : (
        <Button
          size="small"
          variant="text"
          sx={buttonStyles}
          onClick={applay}
          style={{ marginRight: "40px" }}
        >
          apply changes
          <DoneIcon sx={{ fontSize: "small", marginLeft: "5px" }} />
        </Button>
      )}
    </>
  );
};

ApplyChanges.propsTypes = {
  isApplay: PropsTypes.boolean,
  applay: PropsTypes.func
};

export default ApplyChanges;
