import React from 'react';
import CusNavBar from './navbar'; // Ensure the correct path is set for your project
import Footer from './footer'; // Ensure the correct path is set for your project
import '../styles/login.css'; // Adjust the path as needed
import { useNavigate } from 'react-router';

function VermostDashboard() {
    const navigate = useNavigate();
  return (
    <div>
      <CusNavBar />
      <header className="d-flex align-items-center justify-content-center pt-3 pb-3 mb-3 border-bottom">
        <h1>Welcome Guest User</h1>
      </header>
      <div className="container d-flex justify-content-center">
        <div className="row col-6 text-center">
          <h2>Process Manager for Guest User</h2>
          <div 
            className="mt-3 p-3 border rounded text-center bg-blue-100 border-blue-800 text-blue-700 text-2xl font-semibold"
            style={{ cursor: 'pointer' }}
            onClick={() => {navigate("/vermost_guest_bond");}}>
            Request a Bond
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default VermostDashboard;
