import React, {useEffect, useState} from 'react';
import '../styles/login.css';
import '../App.css';
import {useNavigate} from "react-router";
import {getProcesses, triggerProcesses, getProcessInputs} from "../services/process";
import Footer from "./footer";
import CusAlert from "./alert";
import ProcessInputPopup from "../components/popups/process_input_popup";
import PropTypes from "prop-types";
import ButtonLoading from "./loadings/button_loading";
import CusNavBar from "./navbar";
import {useDispatch, useSelector} from "react-redux";
import { addDocuments, removeDocument } from '../store/document_approval';
import LoadingOverlay from 'react-loading-overlay';
import APTableLoading from "./loadings/ap_table_loading";
import {DataGrid} from "@mui/x-data-grid";
import {getDocumentApprovalList} from "../services/document_approval";
import {DocumentApprovalEditForm} from "./document_approval_edit_form";

function DocumentApproval(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [successPopUpMessage, setSuccessPopUpMessage] = useState('');
    const [showFailPopUp, setShowFailPopUp] = useState(false);
    const [failPopUpMessage, setFailPopUpMessage] = useState('');
    const [documentToView, setDocumentToView] = useState(0);
    const documentToApprove = useSelector((state) => state.documentApproval.documents);


    const columns = [
        {
            field: 'id', headerName: 'View Document', sortable: false, flex:1,
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                return (
                    <button onClick={() => { setDocumentToView(0); setDocumentToView(params.row.id) }}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                            inline-flex items-center">
                        <span>View</span>
                    </button>
                );
            }
        },
        {
            field: 'process_name', headerName: 'Process Name', sortable: false, flex:1,
            headerClassName: 'header-bold right-column'
        },
        {
            field: 'last_updated_by_name', headerName: 'Last Updated By', sortable: false, flex:1,
            headerClassName: 'header-bold right-column'
        },
        {
            field: 'initiator', headerName: 'Generated By', sortable: false,flex:1,
            headerClassName: 'header-bold right-column'
        },
        {
            field: 'created_at', headerName: 'Generated At', sortable: false, flex:1,
            headerClassName: 'header-bold right-column'
        }
    ];


    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }

        // if (documentToApprove.length === 0){
        getDocumentApprovalList().then((docs)=>{
            dispatch(addDocuments(docs));
            setLoading(false)
        });

        // }
        document.title = "Document Approval";
    },[])


    const closeEditForm = (documentID=0) => {
        setDocumentToView(0);
        if (documentID !== 0){
            dispatch(removeDocument({id: documentID}));
        }
    }

    return(
        <>
            <LoadingOverlay
                active={loading}
                spinner
                text='Getting documents ...'
            >
                <CusNavBar />
                {
                    showSuccessPopUp && <CusAlert alertType='success' alertMessage={ successPopUpMessage }
                                                  alertClose={setShowSuccessPopUp} />
                }
                {
                    showFailPopUp && <CusAlert alertType='danger' alertMessage={ failPopUpMessage }
                                               alertClose={setShowFailPopUp} />
                }
                <header className="d-flex align-items-center justify-content-between text-dark text-decoration-none
                pt-3 pb-3 mb-3 border-bottom">
                    <div className="fs-1">Document Approval</div>
                </header>

                <div className="my-3">
                    <p className="fs-3 col-md-8">Please click on the view button for the document you would like to
                        process.</p>
                </div>

                <hr className="col-3 col-md-2" />
                {
                    documentToView!==0 && <DocumentApprovalEditForm document_id={documentToView}
                                                                    close_callback={closeEditForm}
                                                                    success_show_fun={setShowSuccessPopUp}
                                                                    success_msg_set_fun={setSuccessPopUpMessage}
                                                                    fail_show_fun={setShowFailPopUp}
                                                                    fail_msg_set_fun={setFailPopUpMessage} />
                }
                {
                    (loading === false) ? (
                        <DataGrid
                            style={{height: "500px"}}
                            rows={documentToApprove}
                            columns={ columns }
                            pageSize={10}
                        />
                    ) : (
                        <APTableLoading />
                    )
                }
                <hr className="col-3 col-md-2" />

            </LoadingOverlay>
            <Footer />
        </>
    )
}

// Process.propTypes = {
//     category: PropTypes.string.isRequired,
// };


export default DocumentApproval;
