import React, {useState} from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner} from "react-bootstrap";
import '../../styles/login.css';
import * as Yup from "yup";
import {Formik, Form as FKForm} from "formik";
import {rejectInvoice} from "../../services/accounts";
import Select from "react-select";

export default function RejectInvoicePopup(props) {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please provide details.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{"comment": "", "approvement_level": ""}}
                    onSubmit={async (values) =>{
                        setLoading(true);
                        values['id'] = props.invoice_id;
                        rejectInvoice(values).then((response)=>{
                            setLoading(false);
                            props.reject_inovice_callback();
                            props.closePopup(false);
                        }).catch((error)=>{
                            setLoading(false);
                            console.log(error);
                            props.closePopup(false);
                        });
                    }
                    }
                    validationSchema= {Yup.object({
                        comment: Yup.string().required('Please provide reason.'),
                        approvement_level: Yup.string().required('Please select approvement level.')
                    })}
                    render={({initialValues, handleSubmit, submitForm, values, handleBlur, handleChange, touched, errors, setFieldValue, setFieldTouched})=>(
                        <FKForm onSubmit={handleSubmit}>
                            {
                                <>
                                    <div className="form-group mb-2" key="comment_input_div">
                                        <label htmlFor="comment" className='required-label'><b>Reason</b></label>
                                        <textarea disabled={loading} className="form-control" name="comment" id="comment"
                                               onBlur={handleBlur} onChange={handleChange}/>
                                        {errors["comment"] && touched["comment"] && (
                                            <div className="text-error">{errors["comment"]}</div>
                                        )}
                                    </div>
                                    <div className="form-group mb-2" key="approvement_level_input_div">
                                        <label htmlFor="approvement_level" className='required-label'><b>Send Back To</b></label>
                                        <Select
                                            name="approvement_level"
                                            onChange={(opt, e) => {
                                                setFieldValue('approvement_level', opt.value);
                                            }}
                                            onBlur={setFieldTouched}
                                            options={props.approvement_levels}
                                        />
                                        {errors["approvement_level"] && (
                                            <div className="text-error">{errors["approvement_level"]}</div>
                                        )}
                                    </div>
                                </>
                            }
                            {
                                !loading && (
                                    <Button onClick={() => submitForm()}>Confirm</Button>
                                )
                            }
                            {
                                loading && (
                                    <Button disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </Button>
                                )
                            }
                        </FKForm>
                        )}
                    />
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(false);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

RejectInvoicePopup.propTypes = {
    invoice_id: PropTypes.number.isRequired,
    approvement_levels: PropTypes.array.isRequired,
    closePopup: PropTypes.func.isRequired,
    reject_inovice_callback: PropTypes.func.isRequired,
}
