import {
    portal_insurance_zoom_login_link,
    portal_insurance_zoom_password,
    portal_insurance_zoom_username
} from "../constants";
import {getAPICall} from "./helper";


export const get_zoom_token = async () => {

    const response = await fetch(`${portal_insurance_zoom_login_link}`, {
        method: "POST",
        body: JSON.stringify({
            "username": portal_insurance_zoom_username,
            "password": portal_insurance_zoom_password
        }),
        headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
        },
    });

    if (response.status === 200) {
        return await response.json();
    }
}


export async function get_insurance_quote_history() {
    return await getAPICall('/portal_insurance/quote_list/');
}

export async function get_insurance_quote_history_pagination(page, pageSize, sort, filter) {
    return await getAPICall(`/portal_insurance/quote_list?&page=${page}&size=${pageSize}&sort=${sort}&filter=${filter}`);
}

export async function get_insurance_vendor_creds() {
    return await getAPICall('/portal_insurance/get_portal_insurance_vendor_creds/');
}