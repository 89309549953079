import PropTypes from "prop-types";
import {Button, Form} from "react-bootstrap";
import React, {useState, useEffect, useRef} from "react";
import {FieldArray, Formik, useFormik, Form as FKForm} from "formik";
import '../styles/login.css';
import * as Yup from "yup"
import {addInvoiceComment, titleCase} from "../services/accounts";
import LoadingOverlay from 'react-loading-overlay';
import {useSelector, useDispatch} from "react-redux";
import NumberFormat from 'react-number-format';
import CustomDatePicker from "./custom_date_picker";
import {API_URL, notRequiredKeys} from "../constants";
import {filterInputOption, triggerProcessesWithData} from "../services/process";
import Select from "react-select";
import RejectDocumentPopup from "../components/popups/document_rejection_popup";
import {updateInvoiceComments} from "../store/invoices";
import {removeDocument, updateDocumentComments} from "../store/document_approval";
import JoditEditor from "jodit-react";
import CustomAmountInput from "./inputs/amount";
import {formatDate} from "../services/helper";
import CustomTextInput from "./inputs/text";
import CustomLongInput from "./inputs/long-text";
import {validateData} from "../services/capital_project";

export function DocumentApprovalEditForm({document_id, close_callback, success_show_fun, success_msg_set_fun,
                                             fail_show_fun, fail_msg_set_fun}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [hasFile, setHasFile] = useState(false);
    const [showRejectionPopup, setShowRejectionPopup] = useState(false);
    const [line_items_total, setLineItemsTotal] = useState(0);
    const [comment, setComment] = useState('');
    const editor = useRef(null);
    const [editorKey, setEditorKey] = useState(Date.now());
    let newComment = '';
    const [content, setContent] = useState("");
    const config = {
        readonly: false,
        height: 200,
        placeholder: "Write a comment..."
    };
    const handleUpdate = (event) => {
        const editorContent = event.target.innerHTML;
        setContent(editorContent);
    };
    const document_to_edit = useSelector((state) => state.documentApproval.documents.filter((doc)=>doc.id===document_id));
    const [stateLineItems, setStateLineItems] = useState(document_to_edit[0]['data']['data'].hasOwnProperty('line_items') ? [...document_to_edit[0]['data']['data']['line_items']] : []);

    useEffect(async ()=>{
        if (document_to_edit){
            if (Object.keys(document_to_edit[0]['data']).includes('file') && document_to_edit[0]['data']['file'] !== ''){
                setHasFile(true);
            }else{
                setHasFile(false);
            }
        }
        // setLoading(false);
    }, [document_to_edit]);

    useEffect(async ()=>{
        if (document_to_edit){
            if (document_to_edit[0]['data']['data'].hasOwnProperty('line_items')){
                setStateLineItems(document_to_edit[0]['data']['data']['line_items']);
            }else{
                setStateLineItems([]);
            }
        }
        // setLoading(false);
    }, [document_id]);

    const getInputOptions = async (inputID, filterValue) => {
        const inputOptions = await filterInputOption(inputID, filterValue);
        return inputOptions;
    }

    const getInitialValues = () => {
        let initialValues = {};
        const lineItemInitVals = [];
        const keys = Object.keys(document_to_edit[0]['data']['data']);
        keys.forEach((key) => {
            if (key !== 'line_items' && key !== 'process_approval_id'){
                initialValues[document_to_edit[0]['data']['data'][key]['name']] = document_to_edit[0]['data']['data'][key]['data'] !== null ? document_to_edit[0]['data']['data'][key]['data'] : '';
            }
        });

        if (document_to_edit[0]['data']['data'].hasOwnProperty('line_items')){
            initialValues['line_items'] = [];
            document_to_edit[0]['data']['data']['line_items'].forEach((line_item) => {
                let temp_item = {}
                line_item.forEach((item)=>{
                    temp_item[item['name']] = item['data'] !== null ? item['data'] : '';
                });
                initialValues['line_items'].push(temp_item);
            });
        }
        return initialValues;
    }

    const getValidation = () => {
        const keys = Object.keys(document_to_edit[0]['data']['data']);
        const validation = {};
        const previous = new Date((new Date()).getTime());
        previous.setDate(previous.getDate() - 1);
        keys.forEach((key) => {
            if (key !== 'line_items' && key !== 'process_approval_id') {
                if ((document_to_edit[0]['data']['data'][key].input_type === 'text' || document_to_edit[0]['data']['data'][key].input_type === 'select') && document_to_edit[0]['data']['data'][key].is_line_item === false) {
                    validation[document_to_edit[0]['data']['data'][key]['name']] = document_to_edit[0]['data']['data'][key].is_required ? Yup.string().required(document_to_edit[0]['data']['data'][key].label + ' is required.') : Yup.string()
                } else if ((document_to_edit[0]['data']['data'][key].input_type === 'number') && document_to_edit[0]['data']['data'][key].is_line_item === false) {
                    validation[document_to_edit[0]['data']['data'][key]['name']] = document_to_edit[0]['data']['data'][key].is_required ? Yup.number().required(document_to_edit[0]['data']['data'][key].label + ' is required.') : Yup.number()
                } else if ((document_to_edit[0]['data']['data'][key].input_type === 'file') && document_to_edit[0]['data']['data'][key].is_line_item === false) {
                    validation[document_to_edit[0]['data']['data'][key]['name']] = document_to_edit[0]['data']['data'][key].is_required ? Yup.mixed().required(document_to_edit[0]['data']['data'][key].label + ' is required.') : Yup.mixed()
                }else if(document_to_edit[0]['data']['data'][key].input_type === "date"){
                    validation[document_to_edit[0]['data']['data'][key]['name']] = !document_to_edit[0]['data']['data'][key].is_required ? Yup.date() : Yup.date().required(`${document_to_edit[0]['data']['data'][key].label} is required`);
                }else if(document_to_edit[0]['data']['data'][key].input_type === "email"){
                    validation[document_to_edit[0]['data']['data'][key]['name']] = document_to_edit[0]['data']['data'][key].is_required  ? Yup.string().email("Field should contain a valid e-mail").required(`${document_to_edit[0]['data']['data'][key].label} is required`) : Yup.string().email("Field should contain a valid e-mail");
                }
            }
        });
        if (document_to_edit[0]['data']['data'].hasOwnProperty('line_items')){
            let temp_line_item = {}
            document_to_edit[0]['data']['data']['line_items'][0].map((input, idx) => {
                if((input.input_type === 'text' || input.input_type === 'select') && input.is_line_item === true){
                    temp_line_item[input.name] = input.is_required ? Yup.string().required(input.label+' is required.') : Yup.string()
                }else if((input.input_type === 'number') && input.is_line_item === true){
                    temp_line_item[input.name] = input.is_required ? Yup.number().required(input.label+' is required.') : Yup.number()
                }else if((input.input_type === 'file') && input.is_line_item === true){
                    temp_line_item[input.name] = input.is_required ? Yup.mixed().required(input.label+' is required.') : Yup.mixed()
                }
            });
            validation["line_items"] = Yup.array().of(
                Yup.object().shape(temp_line_item)
            ).required("Must have a Line Item.").min(1, "Minimum of 1 Line Item is required.");
        }
        return validation;
    }

    const rejectDocument = async(send_back_to = '', comment = '') => {
        let post_data = {approval_record_id: document_id}
        if (send_back_to !== '' && comment !== ''){
            post_data = {...post_data, comment: comment, send_back_to: send_back_to}
        }
        setLoading(true);
        const token = localStorage.getItem('userdata');
        const response = await fetch(`${API_URL}/document_approval_reject/`, {
            method: "POST",
            body: JSON.stringify(post_data),
            headers: {
                'Authorization': 'Bearer '+token,
                "Content-Type": "application/json"
            },
        }).catch((error)=>{
            setLoading(false);
            console.log(error);
            fail_show_fun(true)
            fail_msg_set_fun('Document rejection failed please try again or contact administrator.');
        });
        setLoading(false);
        if (response.status === 200){
            success_show_fun(true)
            success_msg_set_fun('Document rejected successfully.');
            // dispatch(removeDocument({id: document_id}));
            close_callback(document_id);
        }else{
            setLoading(false);
            fail_show_fun(true);
            fail_msg_set_fun('Document rejection failed please try again or contact administrator.');
        }
    }
      
    const rejectButton = () => {
        if(document_to_edit[0].send_back_to.length === 0){
            rejectDocument()
        }else{
            setShowRejectionPopup(true);
        }
    }
    const get_send_back_to_options = () => {
        let options = [];
        document_to_edit[0].send_back_to.forEach((contact)=>{
            options.push({label: contact, value: contact});
        })
        return options;
    }


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Updating Document...'
        >
            <div className="col-12" style={{height: "500px", overflowY: "scroll"}}>
                {
                    (document_to_edit[0]['comments'].length > 0) ?
                        <>
                            <h4 style={{marginTop: "10px"}}>Comments:</h4>
                            <hr className="col-9"/>
                        </> : <></>
                }
                {
                    document_to_edit[0]['comments'].length > 0 && (
                        document_to_edit[0]['comments'].map((comment) => {
                            return (
                                <div>
                                    <p style={{
                                        fontSize: "15px",
                                        fontStyle: "italic",
                                        margin: "0px !important"
                                    }}>{comment.user} - <span style={{
                                        fontSize: "15px",
                                        fontStyle: "italic",
                                        margin: "0px !important"
                                    }}>{comment.created_date}</span></p>
                                    <h5 dangerouslySetInnerHTML={{ __html: comment.comment }} />
                                    <hr className="col-9"/>
                                </div>
                            )
                        })
                    )
                }
                {
                    <div style={{textAlign: "left", padding: "20px"}}>
                        {/*<textarea placeholder="Comment Text..." type="text" className="form-control"*/}
                        {/*          defaultValue={comment} onChange={evt => setComment(evt.target.value)}></textarea>*/}
                        <JoditEditor
                            key={editorKey}
                            ref={editor}
                            config={config}
                            onChange={(newContent) => {newComment=newContent;}}
                        />
                        <button className="btn btn-success" style={{marginTop: "10px"}} onClick={async (e) => {
                            e.preventDefault();
                            if (newComment === '' || newComment === undefined) {
                                alert('Comment text is required.');
                                return;
                            }
                            setLoading(true);
                            const token = localStorage.getItem('userdata');
                            const response = await fetch(`${API_URL}/document_approval_add_comment/`, {
                                method: "POST",
                                body: JSON.stringify({comment: newComment, approval_record_id: document_id}),
                                headers: {
                                    'Authorization': 'Bearer '+token,
                                    "Content-Type": "application/json"
                                },
                            }).catch((error)=>{
                                setLoading(false);
                                console.log(error);
                                fail_show_fun(true)
                                fail_msg_set_fun('Something went wrong while adding comment please try again or contact ' +
                                    'administrator.');
                                setComment('');
                            });
                            if (response.status === 200){
                                setLoading(false);
                                success_show_fun(true);
                                success_msg_set_fun('Comment added successfully.');
                                const new_comments = await response.json();
                                dispatch(updateDocumentComments({id:document_id, comments: new_comments}));
                                setComment('');
                                setEditorKey(Date.now());
                            }else{
                                setLoading(false);
                                fail_show_fun(true);
                                fail_msg_set_fun('Something went wrong while adding comment please try again or contact ' +
                                    'administrator.');
                                setComment('');
                            }
                            setLoading(false);
                        }}>Add Comment
                        </button>
                    </div>
                }
            </div>
            <div className="row" key={document_id} style={{height: "800px", marginBottom: "30px", marginTop: "30px", overflow: "hidden"}}>
                <div className={hasFile ? "col-lg-5 col-sm-12" : "col-lg-12 col-sm-12"}
                     style={{height: "800px", overflowY: "scroll"}} id="scrolling_div">
                    <Formik
                        initialValues={getInitialValues()}
                        onSubmit={async (values, errors) =>
                        {
                            setLoading(true);
                            if (document_to_edit[0]['process_name'] === 'Capital Project'){
                                const error_message = validateData(values);
                                if(Object.keys(error_message).length > 0){
                                    errors.setFieldError(Object.keys(error_message)[0], error_message[Object.keys(error_message)[0]]);
                                    setLoading(false);
                                    return
                                }
                            }
                            let data = JSON.parse(JSON.stringify(document_to_edit[0]['data']));
                            Object.keys(data['data']).forEach((key)=>{
                               if(key !== 'line_items' && key !== 'file' && key !== 'process_approval_id'){
                                    data['data'][key]['data'] = values[data['data'][key]['name']]
                               }
                            });
                            if(Object.keys(data['data']).includes('line_items')){
                                data['data']['line_items'].forEach((submit_line_items, index)=>{
                                    submit_line_items.forEach((input, ind)=>{
                                       data['data']['line_items'][index][ind]['data'] = values['line_items'][index][input.name]
                                    });
                                })
                            }
                            let post_data = {data: {...data}, approval_record_id: document_id}
                            const token = localStorage.getItem('userdata');
                            const response = await fetch(`${API_URL}/document_approval_approve/`, {
                                method: "POST",
                                body: JSON.stringify(post_data),
                                headers: {
                                    'Authorization': 'Bearer '+token,
                                    "Content-Type": "application/json"
                                },
                            }).catch((error)=>{
                                console.log(error);
                                setLoading(false);
                                fail_show_fun(true)
                                fail_msg_set_fun('Document update failed please try again or contact administrator.');
                            });
                            if (response.status === 200){
                                success_show_fun(true)
                                success_msg_set_fun('Document updated successfully.');
                                // dispatch(removeDocument({id: document_id}));
                                close_callback(document_id);
                            }else{
                                setLoading(false);
                                fail_show_fun(true);
                                fail_msg_set_fun('Document update failed please try again or contact administrator.');
                            }
                        }
                        }
                        validationSchema= {Yup.object(getValidation())}
                        render={({initialValues, handleSubmit, submitForm, values, handleBlur, handleChange, touched, errors, setFieldValue, setFieldTouched})=>(
                            <FKForm onSubmit={handleSubmit}>
                                {
                                    Object.keys(document_to_edit[0]['data']['data']).map((input, idx) => {
                                        if (document_to_edit[0]['data']['data'][input].input_type === "text" && document_to_edit[0]['data']['data'][input].is_line_item === false) {
                                            return (<Form.Group key={idx} style={{display:"inline-block", padding:"0px 10px"}} className={!hasFile ? "col-lg-6 col-sm-12" : "col-lg-12 col-sm-12"} controlId="formBasicEmail">
                                                <Form.Label className={document_to_edit[0]['data']['data'][input].is_required === true ? 'required-label' : ''}>{document_to_edit[0]['data']['data'][input].label}</Form.Label>

                                                <Form.Control name={document_to_edit[0]['data']['data'][input].name} type="text"
                                                              placeholder={"Enter " + document_to_edit[0]['data']['data'][input].label}
                                                              onBlur={handleBlur} onChange={handleChange}
                                                              disabled={!document_to_edit[0]['can_edit']}
                                                              defaultValue={document_to_edit[0]['data']['data'][input].data} />
                                                {errors[document_to_edit[0]['data']['data'][input].name] && touched[document_to_edit[0]['data']['data'][input].name] && (
                                                    <div className="text-error">{errors[document_to_edit[0]['data']['data'][input].name]}</div>
                                                )}
                                            </Form.Group>)
                                        }else if(document_to_edit[0]['data']['data'][input].input_type === 'number' && document_to_edit[0]['data']['data'][input].is_line_item === false){
                                            return (<Form.Group key={idx} style={{display:"inline-block", padding:"0px 10px"}} className={!hasFile ? "col-lg-6 col-sm-12" : "col-lg-12 col-sm-12"} controlId="formBasicEmail">
                                                <Form.Label className={document_to_edit[0]['data']['data'][input].is_required === true ? 'required-label' : ''}>{document_to_edit[0]['data']['data'][input].label}</Form.Label>
                                                <Form.Control name={document_to_edit[0]['data']['data'][input].name} type="number"
                                                              placeholder={"Enter " + document_to_edit[0]['data']['data'][input].label}
                                                              onBlur={handleBlur} onChange={handleChange}
                                                              defaultValue={document_to_edit[0]['data']['data'][input].data}
                                                              disabled={!document_to_edit[0]['can_edit']}/>
                                                {errors[document_to_edit[0]['data']['data'][input].name] && touched[document_to_edit[0]['data']['data'][input].name] && (
                                                    <div className="text-error">{errors[document_to_edit[0]['data']['data'][input].name]}</div>
                                                )}
                                            </Form.Group>)
                                        }else if(document_to_edit[0]['data']['data'][input].input_type === 'select' && document_to_edit[0]['data']['data'][input].is_line_item === false){
                                            return (
                                                <Form.Group key={idx} controlId="formFileSm" style={{display:"inline-block", padding:"0px 10px"}} className={!hasFile ? "col-lg-6 col-sm-12" : "col-lg-12 col-sm-12"}>
                                                    <Form.Label className={document_to_edit[0]['data']['data'][input].is_required === true ? 'required-label' : ''}>{document_to_edit[0]['data']['data'][input].label}</Form.Label>
                                                    <Select
                                                        name={document_to_edit[0]['data']['data'][input].name}
                                                        onChange={async (opt, e) => {
                                                            if (document_to_edit[0]['data']['data'][input].name === 'Vendor ID' && document_to_edit[0].process_name === "Create Purchase Order"){
                                                                const vendor_item = document_to_edit[0]['data']['data'].line_items[0].filter((lits)=>lits.name === 'vendor_item');
                                                                setLoading(true);
                                                                const vendor_item_options = await getInputOptions(vendor_item[0].id, opt.value)
                                                                let line_items_const = [...stateLineItems];
                                                                line_items_const.map((lits)=>{
                                                                    lits.map((lit)=>{
                                                                        if (lit.name === 'vendor_item'){
                                                                            lit.options = vendor_item_options;
                                                                        }
                                                                    })
                                                                });
                                                                setStateLineItems(line_items_const);
                                                                setLoading(false);
                                                            }
                                                            setFieldValue(document_to_edit[0]['data']['data'][input].name, opt.value);
                                                        }}
                                                        options={document_to_edit[0]['data']['data'][input].options}
                                                        isDisabled={!document_to_edit[0]['can_edit']}
                                                        defaultValue={document_to_edit[0]['data']['data'][input].options.filter((opt)=> opt['value']===document_to_edit[0]['data']['data'][input].data)[0]}
                                                    />
                                                    {errors[document_to_edit[0]['data']['data'][input].name] && (
                                                        <div className="text-error">{errors[document_to_edit[0]['data']['data'][input].name]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }else if(document_to_edit[0]['data']['data'][input].input_type === 'date' && document_to_edit[0]['data']['data'][input].is_line_item === false){
                                            return (
                                                <Form.Group key={idx} controlId="formBasicEmail"
                                                            style={{display:"inline-block", padding:"0px 10px"}} className={!hasFile ? "col-lg-6 col-sm-12" : "col-lg-12 col-sm-12"}>
                                                    <Form.Label className={document_to_edit[0]['data']['data'][input].is_required === true ? 'required-label' : ''}>
                                                        {document_to_edit[0]['data']['data'][input].label}</Form.Label>
                                                    <CustomDatePicker className="form-control" name={document_to_edit[0]['data']['data'][input].name} id={document_to_edit[0]['data']['data'][input].name+"-input"}
                                                                      value={document_to_edit[0]['data']['data'][input].data === undefined ? formatDate(new Date) : document_to_edit[0]['data']['data'][input].data} format="y-MM-dd"
                                                                      disabled={!document_to_edit[0]['can_edit']}
                                                                      onChange={(value)=>{
                                                                          if (formatDate(value) === '1970-01-01'){
                                                                              setFieldValue(document_to_edit[0]['data']['data'][input].name, '');
                                                                          }else{
                                                                              setFieldValue(document_to_edit[0]['data']['data'][input].name, formatDate(value))
                                                                          }

                                                                      }}
                                                                      required={document_to_edit[0]['data']['data'][input].is_required} />
                                                    {errors[document_to_edit[0]['data']['data'][input].name] && (
                                                        <div className="text-error">{errors[document_to_edit[0]['data']['data'][input].name]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }else if(document_to_edit[0]['data']['data'][input].input_type === 'email' && document_to_edit[0]['data']['data'][input].is_line_item === false){
                                            return (
                                                <Form.Group key={idx} controlId="formBasicEmail"
                                                            style={{display:"inline-block", padding:"0px 10px"}} className={!hasFile ? "col-lg-6 col-sm-12" : "col-lg-12 col-sm-12"}>
                                                    <Form.Label className={document_to_edit[0]['data']['data'][input].is_required === true ? 'required-label' : ''}>
                                                        {document_to_edit[0]['data']['data'][input].label}</Form.Label>
                                                    <CustomTextInput disabled={!document_to_edit[0]['can_edit']}
                                                                     required={document_to_edit[0]['data']['data'][input].is_required}
                                                                     name={document_to_edit[0]['data']['data'][input].name}
                                                                     className={"form-control"} id={document_to_edit[0]['data']['data'][input].name+"-input"}
                                                                     value={document_to_edit[0]['data']['data'][input].data} onChange={(value)=>{
                                                                        setFieldValue(document_to_edit[0]['data']['data'][input].name, value);
                                                                    }}/>
                                                    {errors[document_to_edit[0]['data']['data'][input].name] && (
                                                        <div className="text-error">{errors[document_to_edit[0]['data']['data'][input].name]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }else if(document_to_edit[0]['data']['data'][input].input_type === 'long_text' && document_to_edit[0]['data']['data'][input].is_line_item === false){
                                            return (
                                                <Form.Group key={idx} controlId="formBasicEmail"
                                                            style={{display:"inline-block", padding:"0px 10px"}} className={!hasFile ? "col-lg-6 col-sm-12" : "col-lg-12 col-sm-12"}>
                                                    <Form.Label className={document_to_edit[0]['data']['data'][input].is_required === true ? 'required-label' : ''}>
                                                        {document_to_edit[0]['data']['data'][input].label}</Form.Label>
                                                    <CustomLongInput disabled={!document_to_edit[0]['can_edit']} required={document_to_edit[0]['data']['data'][input].is_required} name={document_to_edit[0]['data']['data'][input].name}
                                                                     className={"form-control"} id={document_to_edit[0]['data']['data'][input].name+"-input"} value={document_to_edit[0]['data']['data'][input].data}
                                                                     onChange={(value)=>{
                                                                         setFieldValue(document_to_edit[0]['data']['data'][input].name, value)
                                                                     }}
                                                                     placeholder={document_to_edit[0]['data']['data'][input].name==="justification" ?
                                                                         "Provide a rationale for undertaking the project.":""}/>
                                                    {errors[document_to_edit[0]['data']['data'][input].name] && (
                                                        <div className="text-error">{errors[document_to_edit[0]['data']['data'][input].name]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }else if(document_to_edit[0]['data']['data'][input].input_type === 'amount' && document_to_edit[0]['data']['data'][input].is_line_item === false){
                                            return (
                                                <Form.Group key={idx} controlId="formBasicEmail"
                                                            style={{display:"inline-block", padding:"0px 10px"}} className={!hasFile ? "col-lg-6 col-sm-12" : "col-lg-12 col-sm-12"}>
                                                    <Form.Label className={document_to_edit[0]['data']['data'][input].is_required === true ? 'required-label' : ''}>
                                                        {document_to_edit[0]['data']['data'][input].label}</Form.Label>
                                                    <CustomAmountInput disabled={!document_to_edit[0]['can_edit']} required={document_to_edit[0]['data']['data'][input].is_required} name={document_to_edit[0]['data']['data'][input].name}
                                                                       className={"form-control"} id={document_to_edit[0]['data']['data'][input].name}
                                                                       value={document_to_edit[0]['data']['data'][input].data}
                                                                       onChange={(value)=>{
                                                                            setFieldValue(document_to_edit[0]['data']['data'][input].name, value);
                                                                       }}/>
                                                    {errors[document_to_edit[0]['data']['data'][input].name] && (
                                                        <div className="text-error">{errors[document_to_edit[0]['data']['data'][input].name]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                    })
                                }
                                {
                                    document_to_edit && Object.keys(document_to_edit[0]['data']['data']).includes('line_items') && (
                                        <FieldArray name="line_items" render={
                                            arrayHelpers => (
                                                stateLineItems.map((line_item, idx) => (
                                                    <div style={{marginTop: "1%"}} key={"document_to_edit[0]['data']['data']['line_items'][" + idx + "]"}>
                                                        <p><b>Line Item {idx + 1}</b></p>
                                                        {/*<p><b>Line Item {idx + 1}</b>{idx === 0 ? <Button onClick={() => {*/}
                                                        {/*    // console.log(document_to_edit[0]['data']['data']['line_items'])*/}
                                                        {/*        let tempLineItem = {};*/}
                                                        {/*        let line_item_list = [];*/}
                                                        {/*        let tempError = {}*/}
                                                        {/*        line_item.map((input, idx) => {*/}
                                                        {/*            tempLineItem[input.name] = "";*/}
                                                        {/*            line_item_list.push({...input, data:''});*/}
                                                        {/*            if (input.is_required === true){*/}
                                                        {/*                tempError[input.name] = input.label +" is required.";*/}
                                                        {/*            }*/}
                                                        {/*        });*/}
                                                        {/*        arrayHelpers.insert(values.line_items.length+1, tempLineItem);*/}
                                                        {/*        setStateLineItems([...stateLineItems, line_item_list]);*/}
                                                        {/*        // console.log(line_items_const);*/}
                                                        {/*        if (errors.line_items !== undefined) {*/}
                                                        {/*            errors.line_items = [...errors.line_items, tempError];*/}
                                                        {/*        }else{*/}
                                                        {/*            errors.line_items = [];*/}
                                                        {/*            values.line_items.forEach((l)=>{*/}
                                                        {/*                errors.line_items.push(undefined);*/}
                                                        {/*            })*/}
                                                        {/*            errors.line_items.push(tempError);*/}
                                                        {/*        }*/}
                                                        {/*    }} variant="light"> Add </Button> :*/}
                                                        {/*    <Button variant="danger" onClick={()=>{*/}
                                                        {/*        console.log(idx)*/}
                                                        {/*        arrayHelpers.remove(idx);*/}
                                                        {/*        // line_items_const = line_items_const.filter((lic, indx)=>indx!==idx)*/}
                                                        {/*        setStateLineItems([...stateLineItems.filter((sli, index)=>index!==idx)])*/}
                                                        {/*        let sum = 0;*/}
                                                        {/*        values.line_items.map((litsum, index)=>{*/}
                                                        {/*            if(index !== idx && litsum["price_per_unit"] !== "" && litsum["quantity_ordered"] !== ""){*/}
                                                        {/*                sum += litsum["quantity_ordered"] * litsum["price_per_unit"]*/}
                                                        {/*            }*/}
                                                        {/*        });*/}
                                                        {/*        setLineItemsTotal(sum);*/}
                                                        {/*    }}> Remove </Button>}</p>*/}
                                                        {
                                                            line_item.map((li, id) => {
                                                                if(li.input_type === 'text'){
                                                                    return (
                                                                        <Form.Group
                                                                            key={"document_to_edit[0]['data']['data']['line_items'][" + idx + "]." + li.name}
                                                                            style={{width: "45%", display: "inline-block", margin: "0% 2%"}}
                                                                            controlId="formFileSm" className="mb-3">
                                                                            <Form.Label className={li.is_required === true ? 'required-label' : ''}>{li.label}</Form.Label>
                                                                            <Form.Control type="text" onBlur={handleBlur}
                                                                                          onChange={handleChange}
                                                                                          name={'line_items[' + idx + '].' + li.name}
                                                                                          defaultValue={li.data}
                                                                                          disabled={!document_to_edit[0]['can_edit']}/>
                                                                            {errors.hasOwnProperty("line_items") && errors["line_items"][idx] !== undefined && errors["line_items"].length>0 && errors["line_items"][idx] !== undefined && errors["line_items"][idx][li.name] && (
                                                                                <div className="text-error">{errors["line_items"][idx][li.name]}</div>
                                                                            )}
                                                                        </Form.Group>
                                                                    )
                                                                }else if(li.input_type === 'select'){
                                                                    if(li.name === 'fund_name_unit_account'){
                                                                        return (
                                                                            <Form.Group key={"document_to_edit[0]['data']['data']['line_items'][" + idx + "]." + li.name} controlId="formFileSm"
                                                                                        className="mb-3"
                                                                                        style={{width: "45%", display:
                                                                                                "inline-block", margin: "0% 2%"}}>
                                                                                <Form.Label className={li.is_required === true ? 'required-label' : ''}>{li.label}</Form.Label>
                                                                                <Select
                                                                                    name={'line_items[' + idx + '].' + li.name}
                                                                                    onChange={(opt, e) => {
                                                                                        setFieldValue('line_items[' + idx + '].fund_name_unit_account', opt.value);
                                                                                    }}
                                                                                    onBlur={setFieldTouched}
                                                                                    options={li.options.filter((opt)=>opt.filter_values.includes(values.line_items[idx].funding_source))}
                                                                                    disabled={!document_to_edit[0]['can_edit']}
                                                                                    defaultValue={li.options.filter((opt)=>opt.value===li.data)[0]}
                                                                                />
                                                                                {errors.hasOwnProperty("line_items") && errors["line_items"][idx] !== undefined && errors["line_items"].length>0 && errors["line_items"][idx] !== null && errors["line_items"][idx][li.name] && (
                                                                                    <div className="text-error">{errors["line_items"][idx][li.name]}</div>
                                                                                )}
                                                                            </Form.Group>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <Form.Group key={"document_to_edit[0]['data']['data']['line_items'][" + idx + "]." + li.name} controlId="formFileSm"
                                                                                    className="mb-3"
                                                                                    style={{width: "45%", display:
                                                                                            "inline-block", margin: "0% 2%"}}>
                                                                            <Form.Label className={li.is_required === true ? 'required-label' : ''}>{li.label}</Form.Label>
                                                                            <Select
                                                                                name={'line_items[' + idx + '].' + li.name}
                                                                                onChange={(opt, e) => {
                                                                                    setFieldValue('line_items[' + idx + '].' + li.name, opt.value);
                                                                                    if(li.name === 'funding_source'){
                                                                                        setFieldValue('line_items[' + idx + '].fund_name_unit_account', '');
                                                                                    }
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                options={li.options}
                                                                                disabled={!document_to_edit[0]['can_edit']}
                                                                                defaultValue={li.options.filter((opt)=>opt.value===li.data)[0]}
                                                                            />
                                                                            {errors.hasOwnProperty("line_items") && errors["line_items"][idx] !== undefined && errors["line_items"].length>0 && errors["line_items"][idx] !== null && errors["line_items"][idx][li.name] && (
                                                                                <div className="text-error">{errors["line_items"][idx][li.name]}</div>
                                                                            )}
                                                                        </Form.Group>
                                                                    )
                                                                }else if(li.input_type === 'number'){
                                                                    return (
                                                                        <Form.Group
                                                                            key={'line_items[' + idx + '].' + li.name}
                                                                            style={{width: "45%", display: "inline-block", margin: "0% 2%"}}
                                                                            controlId="formFileSm" className="mb-3">
                                                                            <Form.Label className={li.is_required === true ? 'required-label' : ''}>{li.label}</Form.Label>
                                                                            <Form.Control type="number" size="sm" onBlur=
                                                                                {handleBlur} onChange={(e)=>{
                                                                                handleChange(e);
                                                                                if(e.target.value !== '' && document_to_edit[0]['process_name'] === 'Create Purchase Order'){
                                                                                    if(e.target.name.split('.')[1] === 'quantity_ordered'){
                                                                                        if(values.line_items[idx]["price_per_unit"] !== ""){
                                                                                            let sum = values.line_items[idx]["price_per_unit"]*e.target.value;
                                                                                            values.line_items.map((litsum, index)=>{
                                                                                                if(index !== idx && litsum["price_per_unit"] !== "" && litsum["quantity_ordered"] !== ""){
                                                                                                    sum += litsum["quantity_ordered"] * litsum["price_per_unit"]
                                                                                                }
                                                                                            });
                                                                                            setLineItemsTotal(sum);
                                                                                        }
                                                                                    }else if(e.target.name.split('.')[1] === 'price_per_unit'){
                                                                                        if(values.line_items[idx]["quantity_ordered"] !== ""){
                                                                                            let sum = e.target.value * values.line_items[idx]["quantity_ordered"];
                                                                                            values.line_items.map((litsum, index)=>{
                                                                                                if(index !== idx && litsum["price_per_unit"] !== "" && litsum["quantity_ordered"] !== ""){
                                                                                                    sum += litsum["quantity_ordered"] * litsum["price_per_unit"]
                                                                                                }
                                                                                            });
                                                                                            setLineItemsTotal(sum);
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            }
                                                                                          name={'line_items[' + idx + '].' + li.name} disabled={!document_to_edit[0]['can_edit']} defaultValue={li.data}/>
                                                                            {errors.hasOwnProperty("line_items") && errors["line_items"][idx] !== undefined && errors["line_items"].length>=0 && errors["line_items"][idx] !== null && errors["line_items"][idx][li.name] && (
                                                                                <div className="text-error">{errors["line_items"][idx][li.name]}</div>
                                                                            )}
                                                                        </Form.Group>
                                                                    )
                                                                }else if(li.input_type === 'amount'){
                                                                    return (
                                                                        <Form.Group key={'line_items[' + idx + '].' + li.name} className="mb-3" controlId="formBasicEmail"
                                                                                    style={{width: "45%", display: "inline-block", margin: "0% 2%"}}>
                                                                            <Form.Label className={li.is_required === true ? 'required-label' : ''}>
                                                                                {li.label}</Form.Label>
                                                                            <CustomAmountInput disabled={!document_to_edit[0]['can_edit']} required={li.is_required} name={'line_items[' + idx + '].' + li.name}
                                                                                               className={"form-control"} id={'line_items[' + idx + '].' + li.name}
                                                                                               value={li.data} onChange={(value)=>{
                                                                                                setFieldValue('line_items[' + idx + '].' + li.name, value);
                                                                                            }}/>
                                                                            {errors.hasOwnProperty("line_items") && errors["line_items"][idx] !== undefined && errors["line_items"].length>=0 && errors["line_items"][idx] !== null && errors["line_items"][idx][li.name] && (
                                                                                <div className="text-error">{errors["line_items"][idx][li.name]}</div>
                                                                            )}
                                                                        </Form.Group>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        {/*{*/}
                                                        {/*    document_to_edit[0]['process_name'] === 'Create Purchase Order' && (*/}
                                                        {/*        <div style={{textAlign:"right", paddingRight:"3.5%"}}>Sub Total: ${stateLineItems[idx].quantity_ordered * stateLineItems[idx].price_per_unit}</div>*/}
                                                        {/*    )*/}
                                                        {/*}*/}
                                                        {typeof errors["line_items"] === 'string' && (
                                                            <div className="text-error">{errors["line_items"]}</div>
                                                        )}
                                                    </div>
                                                    )
                                                )
                                            )
                                        }
                                    />)
                                }
                                {/*{*/}
                                {/*    document_to_edit[0]['process_name'] === 'Create Purchase Order' && line_items_total>0 && <div style={{textAlign:"right"}}>Grand Total: ${line_items_total} </div>*/}
                                {/*}*/}
                                <div style={{marginTop: "1%", textAlign: "right"}}>
                                    <Button style={{marginRight:"1%"}} className="btn btn-warning"
                                            onClick={(event)=>{event.preventDefault(); close_callback()}}>
                                        Cancel</Button>
                                    <Button style={{marginRight:"1%"}} className="btn btn-danger"
                                            onClick={(event)=>{event.preventDefault(); rejectButton()}}>
                                        Reject</Button>
                                    <Button onClick={() => submitForm()}>Approve</Button>
                                </div>
                            </FKForm>
                        )}
                    />
                </div>
                { hasFile &&
                    <iframe className="col-lg-7 col-sm-12" src={document_to_edit[0]['data']['file']} width="100%">
                    </iframe>
                }
                {
                    showRejectionPopup && <RejectDocumentPopup show={true} closePopup={setShowRejectionPopup}
                                                               reject_document_callback={rejectDocument}
                                                               send_back_to_options={get_send_back_to_options()} />
                }
            </div>
        </LoadingOverlay>
    )
}

DocumentApprovalEditForm.propTypes = {
    document_id: PropTypes.number.isRequired,
    close_callback: PropTypes.func.isRequired,
    success_show_fun: PropTypes.func.isRequired,
    success_msg_set_fun: PropTypes.func.isRequired,
    fail_show_fun: PropTypes.func.isRequired,
    fail_msg_set_fun: PropTypes.func.isRequired,
}