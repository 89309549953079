import React, {useCallback, useEffect, useState} from 'react';
import Footer from "../../global_components/footer";
import CusNavBar from "../../global_components/navbar";
import Header from "../../global_components/header";
import { MdArrowBack } from 'react-icons/md';
import APEditFormLoading from "../../global_components/loadings/ap_edit_form_loading";
import {createSvgIcon, Tooltip} from "@mui/material";
import * as XLSX from "sheetjs-style";
import {useSnackbar} from "notistack";
import {Badge, Spinner, Tab, Tabs} from "react-bootstrap";
import CusTable from "../../global_components/datatables/tablev2";
import APTableLoading from "../../global_components/loadings/ap_table_loading";
import {useNavigate} from "react-router";
import Button from "@mui/material/Button";
import {
    formatFailedData,
    getFailedInvoices,
    getInvoices,
} from "../../services/accounts";
import {formatNewData} from "../../services/accounts";
import { getColumns } from "./fsi_temporary/services/accounts";
import * as FileSaver from "file-saver";
import {log} from "../../utils/logger";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {API_URL} from "../../constants";
import debounce from 'lodash/debounce';
import { getInvoiceDetail } from '../../services/accounts';
import { postInvoice } from '../../services/accounts';
import { getIn } from 'formik';
import { Oval } from 'react-loader-spinner';

const FullScreenLoader = ({ isLoading }) => {
    if (!isLoading) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
        <Oval
          color="#FFFFFF" // You can change the spinner color
          height={80}
          width={80}
          secondaryColor="#4fa94d" // Secondary color
        />
      </div>
    );
  };

function CheckboxWithInstantFeedback({ rowId, isSelected, toggleSelection }) {
    // Local state to manage checkbox visual state immediately
    const [checked, setChecked] = useState(isSelected);
    

    const handleChange = () => {
        // Toggle visual state immediately
        setChecked(!checked);
        // Trigger the global state update
        toggleSelection(rowId);
    };

    return (
        <input
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}
        />
    );
}


const DEBOUNCE_DELAY = 7000;

const ExportIcon = createSvgIcon(
    <path
        d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"/>,
    'SaveAlt',
);


function InvoicesList() {
    const token = localStorage.getItem('userdata');
    const [allInvoiceIds, setAllInvoiceIds] = useState([]);
    const [loading, setLoading] = useState(false);

    
    const fetchAllInvoiceIds = async () => {
        // Example fetching logic; adjust according to your actual API
        const response = await fetch(`${API_URL}/ap/invoice_list/?status=approvement`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            const data = await response.json();
            console.log('Fetched all invoice IDs:', data);  // Debugging to check if IDs are correctly fetched
            const ids = data.data.map(item => item.id); // Extracting IDs from each object
            setAllInvoiceIds(ids);
        } else {
            console.error("Failed to fetch all invoice IDs");
        }
    };

    useEffect(() => {
        console.log('All Invoice IDs:', allInvoiceIds);  // Debugging to check if IDs are correctly fetched
    }, [allInvoiceIds]);
    

    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const toggleInvoiceSelection = (invoiceId) => {
        setSelectedInvoices(prevSelected => {
            const isSelected = prevSelected.includes(invoiceId);
            const newSelected = isSelected
                ? prevSelected.filter(id => id !== invoiceId)
                : [...prevSelected, invoiceId];
    
            console.log(`Toggling selection for ID: ${invoiceId}, Currently selected: ${isSelected}, New selection list:`, newSelected);
            return newSelected;
        });
    };

    useEffect(() => {
        console.log("Current selected invoices:", selectedInvoices);
        manualReloadTables()
    }, [selectedInvoices]);  // This effect runs whenever selectedInvoices changes.
    
    const toggleSelectAllInvoices = () => {
        if (selectedInvoices.length < allInvoiceIds.length) {
            setSelectedInvoices(allInvoiceIds);
        } else {
            setSelectedInvoices([]);
        }
    };

    const handlePostSelectedInvoices = async () => {
        setLoading(true);
        const successfulInvoices = [];
        const failedInvoices = [];
    
        for (const id of selectedInvoices) {
            let api_data; // Declare outside to make accessible in both try and catch blocks
            try {
                const invoice_response = await getInvoiceDetail(id);
                if (invoice_response.status === 200) {
                    const invoice_result = await invoice_response.json();
                    api_data = invoice_result; // Initialize here
                    api_data["line_items"] = invoice_result['invoice_items'];
                    const postingResponse = await postInvoice(api_data);
    
                    // Assume a successful response means it was okay to add to successfulInvoices
                    if (postingResponse.ok) {
                        successfulInvoices.push({
                            seller_name: api_data.invoice_details.seller_name,
                            invoice_number: api_data.invoice_details.invoice_number
                        });
                    }
                }
            } catch (error) {
                console.error('Error processing invoice for ID', id, ':', error);
                // api_data might be undefined if error occurred before it was assigned
                if (api_data && api_data.invoice_details) {
                    failedInvoices.push({
                        seller_name: api_data.invoice_details.seller_name,
                        invoice_number: api_data.invoice_details.invoice_number
                    });
                } else {
                    // Handle case where api_data is not available
                    failedInvoices.push({
                        seller_name: "Unknown", // Placeholder or fetch from another source if possible
                        invoice_number: "Unknown"
                    });
                }
            }
        }
        localStorage.setItem("SuccessfulInvoices", JSON.stringify(successfulInvoices));
        localStorage.setItem("FailedInvoices", JSON.stringify(failedInvoices));
        setLoading(false);
        navigate('/fsi-bulk-posting-result');
    }
    

    

    const [editInvoiceLoading, setEditInvoiceLoading] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false)
    const {enqueueSnackbar} = useSnackbar();
    const [jsonData, setJsonData] = useState([]);
    const [postedJsonData, setPostedJsonData] = useState([]);
    const [failedJsonData, setFailedJsonData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [postedColumns, setPostedColumns] = useState([]);
    const [failedColumns, setFailedColumns] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [postedTableLoading, setPostedTableLoading] = useState(true);
    const [failedTableLoading, setFailedTableLoading] = useState(true);
    const [failedLoading, setFailedLoading] = useState(true);
    const navigate = useNavigate();
    const [activeLoading, setActiveLoading] = useState(true);
    const [postedLoading, setPostedLoading] = useState(true);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [showFailPopUp, setShowFailPopUp] = useState(false);

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //Vendor Name to ID Mapping
    const [vendorMap, setVendorMap] = useState([]);
    useEffect(() => {
        async function fetchData() {
            if (!localStorage.getItem('userdata')) {
                navigate("/");
                return; // Stop further execution if no user data.
            }

            try {
                const response = await fetch(`${API_URL}/vendor_id_options/`, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {
                    const data = await response.json();
                    const vendorObject = data.reduce((acc, item) => {
                        acc[item.value] = item.label;
                        return acc;
                    }, {});
                    setVendorMap(vendorObject);  // Directly setting the state here.
                } else {
                    throw new Error('Failed to fetch vendor data');
                }
            } catch (error) {
                console.error('Error fetching vendor data:', error);
            }
        }
        fetchAllInvoiceIds();
        fetchData();
    }, []); // This will run only once when the component mounts.

    const formatInvoiceData = async (data, vendorMap) => {
        return data.reduce((acc, item) => {
            // Update seller_name using vendorMap if it's not provided
            if (!item.seller_name) {
                if (item.vendor_id && vendorMap[item.vendor_id]) {
                    item.seller_name = vendorMap[item.vendor_id];
                } else {
                    item.seller_name = "";  // Assign empty string if no mapping is found
                }
            }
    
            // Add the item to the accumulator
            acc.push(item);
    
            return acc;
        }, []);
    };
    
    //With Duplicate Removal
    // const formatInvoiceData = async (data, vendorMap) => {
    //     const seen = new Map();  
    
    //     return data.reduce((acc, item) => {

    //         if (!item.seller_name) {
    //             if (item.vendor_id && vendorMap[item.vendor_id]) {
    //                 item.seller_name = vendorMap[item.vendor_id];
    //             } else {
    //                 item.seller_name = "";  
    //             }
    //         }
    
    //         const uniqueKey = `${item.invoice_number}-${item.client_number}-${item.po_number}`;
    
            
    //         if (!seen.has(uniqueKey)) {
    //             seen.set(uniqueKey, item);  
    //             acc.push(item);  
    //         }
    
    //         return acc;
    //     }, []);
    // };
    
    

    // Datagrid Pagination
    if (!localStorage.getItem("fsi_invoice_datagrid_pagesize")) {
        localStorage.setItem("fsi_invoice_datagrid_pagesize", JSON.stringify({
            'active': 10, 'posted': 10, 'failed': 10
        }));
    }
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0, pageSize: JSON.parse(localStorage.getItem("fsi_invoice_datagrid_pagesize"))['active']
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);
    const [rowPostedCount, setRowPostedCount] = useState(0);
    const [controllerPosted, setControllerPosted] = useState({
        page: 0, pageSize: JSON.parse(localStorage.getItem("fsi_invoice_datagrid_pagesize"))['posted']
    });
    const gatedSetControllerPosted = useCallback((value) => {
        setControllerPosted(value);
    }, []);
    const [rowFailedCount, setRowFailedCount] = useState(0);
    const [controllerFailed, setControllerFailed] = useState({
        page: 0, pageSize: JSON.parse(localStorage.getItem("fsi_invoice_datagrid_pagesize"))['failed']
    });
    const gatedSetControllerFailed = useCallback((value) => {
        setControllerFailed(value);
    }, []);
    // Datagrid Filter
    const [queryOptionsActive, setQueryOptionsActive] = useState({});
    const onFilterChangeActive = useCallback(
        debounce((filterModel: GridFilterModel) => {
          setQueryOptionsActive({ filterModel: { ...filterModel.items } });
        }, DEBOUNCE_DELAY),
        []
    );
    const [queryOptionsPosted, setQueryOptionsPosted] = useState({});
    const onFilterChangePosted = useCallback(
        debounce((filterModel: GridFilterModel) => {
          setQueryOptionsPosted({ filterModel: { ...filterModel.items } });
        }, DEBOUNCE_DELAY),
        []
      );
    
    const [queryOptionsFailed, setQueryOptionsFailed] = useState({});
    const onFilterChangeFailed = useCallback(
        debounce((filterModel: GridFilterModel) => {
          setQueryOptionsFailed({ filterModel: { ...filterModel.items } });
        }, DEBOUNCE_DELAY),
        []
      );
    //Datagrid Sort
    const [prevFilterActive, setPrevFilterActive] = useState("");
    const [queryOptionsSortActive, setQueryOptionsSortActive] = useState({});
    const handleSortModelChangeActive = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortActive({sortModel: [...sortModel]});
    }, []);
    const [prevSortActive, setPrevSortActive] = useState("");
    const [queryOptionsSortPosted, setQueryOptionsSortPosted] = useState({});
    const handleSortModelChangePosted = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortPosted({sortModel: [...sortModel]});
    }, []);
    const [prevSortPosted, setPrevSortPosted] = useState("");
    const [prevFilterPosted, setPrevFilterPosted] = useState("");
    const [queryOptionsSortFailed, setQueryOptionsSortFailed] = useState({});
    const handleSortModelChangeFailed = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortFailed({sortModel: [...sortModel]});
    }, []);
    const [prevSortFailed, setPrevSortFailed] = useState("");

    const setLocalStorage = () => {
        localStorage.setItem("fsi_invoice_datagrid_pagesize", JSON.stringify({
            'active': controller.pageSize,
            'posted': controllerPosted.pageSize,
            'failed': controllerFailed.pageSize,
        }));
    }
    const getFailedFilterAndSort = () => {
        let filter = ""
        if (queryOptionsFailed.filterModel) {
            filter = queryOptionsFailed.filterModel[0].field + "-" + queryOptionsFailed.filterModel[0].operator + "-" + queryOptionsFailed.filterModel[0].value
        }
        let sort = ""
        if (queryOptionsSortFailed.sortModel) {
            if (queryOptionsSortFailed.sortModel.length > 0) {
                if (prevSortFailed) {
                    sort = prevSortFailed
                }
                let sortModel = queryOptionsSortFailed.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortFailed !== sort) {
                    setPrevSortFailed(sort)
                } else {
                    if (queryOptionsFailed.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        return [filter, sort]
    }
    const getActiveFilterAndSort = () => {
        let filter = ""
        if (queryOptionsActive.filterModel) {
            if (queryOptionsActive.filterModel[0] !== undefined) {
                filter = queryOptionsActive.filterModel[0].field + "-" + queryOptionsActive.filterModel[0].operator + "-" + queryOptionsActive.filterModel[0].value
                setPrevFilterActive(filter)
            } else {
                filter = prevFilterActive
            }
        }
        let sort = ""
        if (queryOptionsSortActive.sortModel) {
            if (queryOptionsSortActive.sortModel.length > 0) {
                if (prevSortActive) {
                    sort = prevSortActive
                }
                let sortModel = queryOptionsSortActive.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortActive !== sort) {
                    setPrevSortActive(sort)
                } else {
                    if (queryOptionsActive.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        return [filter, sort]
    }
    const getPostedFilterAndSort = () => {
        let filter = ""
        if (queryOptionsPosted.filterModel) {
            if (queryOptionsPosted.filterModel[0] !== undefined) {
                filter = queryOptionsPosted.filterModel[0].field + "-" + queryOptionsPosted.filterModel[0].operator + "-" + queryOptionsPosted.filterModel[0].value
                setPrevFilterPosted(filter)
            } else {
                filter = prevFilterPosted
            }
        }
        let sort = ""
        if (queryOptionsSortPosted.sortModel) {
            if (queryOptionsSortPosted.sortModel.length > 0) {
                if (prevSortPosted) {
                    sort = prevSortPosted
                }
                let sortModel = queryOptionsSortPosted.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortPosted !== sort) {
                    setPrevSortPosted(sort)
                } else {
                    if (queryOptionsPosted.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        return [filter, sort]
    }

    const getActiveInvoiceData = async () => {
        setActiveLoading(true);
        setTableLoading(true);
        let filter = getActiveFilterAndSort();
        // Start both fetch operations
        const invoicesPromise = getInvoices("approvement", controller.page, controller.pageSize, filter[0], filter[1]);
    
        try {
            const response = await invoicesPromise;
            if (response.status === 200) {
                let responseData = await response.json();
                setRowCount(responseData.total);
    
                if (responseData.total === 0) {
                    localStorage.setItem('invoices', JSON.stringify([]));
                    setJsonData([]);
                } else {
                    localStorage.setItem('invoices', JSON.stringify(responseData.data));
                    const formattedData = formatNewData(responseData.data);
                    const formattedSellerData = await formatInvoiceData(formattedData, vendorMap);
                    setJsonData(formattedSellerData);
                    let cols = getColumns(responseData.data, true, true);
                    cols.push(
                        {
                            field: 'select',
                            headerName: '',
                            sortable: false,
                            minWidth: 80,
                            maxWidth: 1000,
                            flex: 1,
                            headerClassName: 'header-bold right-column',
                            renderCell: (params) => {
                                return (
                                    <CheckboxWithInstantFeedback
                                        rowId={params.row.id}
                                        isSelected={selectedInvoices.includes(params.row.id)}
                                        toggleSelection={toggleInvoiceSelection}
                                    />
                                );
                            },
                            sortID: 0
                        },                        
                        {
                        field: 'id',
                        headerName: 'View',
                        sortable: false,
                        minWidth: 120,
                        maxWidth: 1000,
                        flex: 1,
                        headerClassName: 'header-bold right-column',
                        renderCell: (params) => {
                            return <button onClick={() => {
                                window.open(`/invoice/${params.row.id}`, '_blank')
                            }}
                                           className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                                  inline-flex items-center">
                                <span>{'View'}</span>
                            </button>;
                        },
                        sortID: 1
                    })
                    setTableColumns(cols);
                }
            } else {
                const result = await response.json();
                enqueueSnackbar(result.message, {variant: "error"});
            }
        } catch (error) {
            console.error('Error loading data:', error);
            enqueueSnackbar('Failed to load data', {variant: "error"});
        } finally {
            setActiveLoading(false);
            setTableLoading(false);
        }
    };
    
    const getPostedInvoiceData = async () => {
        setPostedLoading(true);
        let filter = getPostedFilterAndSort();
        let response = await getInvoices("posted", controllerPosted.page, controllerPosted.pageSize, filter[0], filter[1]);
        if (response.status === 200) {
            let responseData = await response.json();
            setRowPostedCount(responseData.total);
            if (responseData.total === 0) {
                localStorage.setItem('invoices', JSON.stringify([]));
                setPostedJsonData([]);
                setPostedLoading(false);
                setPostedTableLoading(false);
                setPostedColumns([])
            } else {
                setPostedTableLoading(false);
                setPostedLoading(false);
                localStorage.setItem('posted_invoices', JSON.stringify(responseData.data));
                const formatedData = formatNewData(responseData.data);
                setPostedJsonData(formatedData);
                let cols = getColumns(responseData.data, true);
                cols.push({
                    field: 'id',
                    headerName: "View",
                    sortable: false,
                    minWidth: 120,
                    maxWidth: 1000,
                    flex: 1,
                    headerClassName: 'header-bold right-column',
                    renderCell: (params) => {
                        return <button onClick={() => {
                            window.open(`/invoice/${params.row.id}`, '_blank')
                            //navigate(`/invoice/${params.row.id}`);
                        }}
                                       className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                              inline-flex items-center">
                            <span>{'View'}</span>
                        </button>;
                    },
                    sortID: 1
                })
                setPostedColumns(cols);
            }
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }
    const getFailedInvoiceData = async () => {
        setFailedLoading(true);
        let filter = getFailedFilterAndSort();
        let response = await getFailedInvoices(controllerFailed.page, controllerFailed.pageSize, filter[0], filter[1]);
        if (response.status === 200) {
            let responseData = await response.json();
            log("responseData failed", responseData)
            setRowFailedCount(responseData.total)
            if (responseData.total === 0) {
                localStorage.setItem('failed_invoices', JSON.stringify([]));
                setFailedJsonData([]);
                setFailedLoading(false);
                setFailedTableLoading(false);
                setFailedColumns([])
            } else {
                setFailedTableLoading(false);
                setFailedLoading(false);
                localStorage.setItem('failed_invoices', JSON.stringify(responseData.data));
                const formatedData = formatFailedData(responseData.data);
                setFailedJsonData(formatedData);
                let cols = getColumns(responseData.data, true);
                cols.push({
                    field: 'id',
                    headerName: 'View',
                    sortable: false,
                    minWidth: 120,
                    maxWidth: 1000,
                    flex: 1,
                    headerClassName: 'header-bold right-column',
                    renderCell: (params) => {
                        return <button onClick={() => {
                            window.open(`/invoice/${params.row.id}`, '_blank')
                        }}
                                       className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                              inline-flex items-center">
                            <span>{'View'}</span>
                        </button>;
                    },
                    sortID: 1
                })
                cols.push({
                    field: 'status',
                    headerName: 'Status',
                    sortable: false,
                    minWidth: 120,
                    maxWidth: 1000,
                    flex: 1,
                    headerClassName: 'header-bold',
                    sortID: -1,
                    renderCell: (params) => {
                        return (
                            <h6>
                                <Badge pill bg="danger">
                                    {params.row.status}
                                </Badge>
                            </h6>
                        );
                    },
                })
                setFailedColumns(cols);
            }
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }

    }

    const manualReloadTables = async () => {
        setActiveLoading(true);
        setPostedLoading(true);
        setFailedLoading(true);
        await getActiveInvoiceData();
        await getPostedInvoiceData();
        await getFailedInvoiceData();
    }

    const getExcelData = async (status) => {
        let response = {}
        log("status,", status)
        let activeFilter = getActiveFilterAndSort();
        let postedFilter = getPostedFilterAndSort();
        let failedFilter = getFailedFilterAndSort();

        if (status === "active") {
            response = await getInvoices("approvement", controller.page, controller.pageSize, activeFilter[0], activeFilter[1]);
            const responseData = await response.json();
            response = responseData;
            log("response,", responseData);
        } else if (status === "posted") {
            response = await getInvoices("posted", controller.page, controller.pageSize, postedFilter[0], postedFilter[1]);
            const responseData = await response.json();
            response = responseData;
            log("response,", response);
        } else if (status === "failed") {
            // response = await getInvoices("failed");
            let response = await getFailedInvoices(controllerFailed.page, controllerFailed.pageSize, failedFilter[0], failedFilter[1]);
            const responseData = await response.json();
            response = responseData;
        }
        return response;
    }
    const sortFormat = (excelData, status) => {
        let columnList = [];
        if (status === "active") {
            log("tableColumns,", tableColumns)
            columnList = tableColumns;
        } else if (status === "posted") {
            columnList = postedColumns;
        } else if (status === "failed") {
            columnList = failedColumns;
        }
        let totalResult = []
        Object.entries(excelData).forEach((val) => {
            let tempObj = {};
            let entries = Object.entries(val[1]['invoice_details']);
            let result = {}
            columnList.forEach(function (key) {
                var found = false;
                entries = entries.filter(function (item) {
                    if (!found && item[0] === key.field) {
                        if (key.field === 'id') {
                            result[item[0]] = item[1];
                        } else {
                            result[item[1].label] = item[1].value;
                        }
                        found = true;
                        return false;
                    } else if (!found && key.field === 'approvement_label') {
                        result["Approval Stage"] = val[1]['approvement_label'];
                        found = true;
                        return false;
                    } else if (!found && key.field === 'department_name') {
                        result["Department"] = val[1]['department_name'];
                        found = true;
                        return false;
                    } else if (!found && key.field === 'status') {
                        result["Status"] = val[1]['status'];
                        found = true;
                        return false;
                    } else
                        return true;
                })
            })
            log("result,", result)
            totalResult.push(result)
        });

        return totalResult;
    }
    const changeFormat = (original, status) => {
        return sortFormat(original, status);
    }
    const ExportExcel = (status) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
    const fileExtension = ".xlsx";

    const changeFormat = (data) => {
        const seen = new Map(); 
    
        return data.reduce((acc, item) => {
            
            if (!item.invoice_details.seller_name.value) {
                if (item.invoice_details.vendor_id.value && vendorMap[item.invoice_details.vendor_id.value]) {
                    item.invoice_details.seller_name.value = vendorMap[item.invoice_details.vendor_id.value];
                } else {
                    item.invoice_details.seller_name.value = "";  
                }
            }


            if (!seen.has(item.invoice_details.invoice_number.value)) {
                seen.set(item.invoice_details.invoice_number.value, true);  
    
                
                acc.push({
                    ID: item.id,
                    CompanyName: item.company_name,
                    DepartmentName: item.department_name,
                    ApprovementLabel: item.approvement_label,
                    DocumentType: item.doc_type,
                    InvoiceDate: item.invoice_details.invoice_date.value,
                    InvoiceNumber: item.invoice_details.invoice_number.value,
                    VendorName: item.invoice_details.seller_name.value,
                    PO_Number: item.invoice_details.po_number.value,
                    TotalAmount: item.invoice_details.invoice_amount.value,
                    Status: item.status,
                    CreatedAt: item.created_at,
                    PostedAt: item.posted_at || 'N/A',  // Handling null values
                    DownloadLink: item.download_link
                });
            }
            return acc;
        }, []);
    };

    const exportToExcel = async () => {
        try {
            enqueueSnackbar('Preparing the file.', {variant: "info"});
            setLoadingExcel(true);

            let response = await getExcelData(status.status);
    
            if (!response || !response.data) {
                console.error("No data available to export.");
                enqueueSnackbar('No data available for export.', {variant: "error"});
                return; 
            }
    
            let formattedData = changeFormat(response.data, vendorMap);  
            console.log("Formatted Data:", formattedData);  
    
            const ws = XLSX.utils.json_to_sheet(formattedData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, status.fileName + fileExtension);
            enqueueSnackbar('File is ready.', {variant: "success"});
        } catch (error) {
            console.error("Export failed:", error);
            enqueueSnackbar('File could not be created.', {variant: "error"});
        } finally {
            setLoadingExcel(false);
        }
    };
    
        const buttonBaseProps = {
            color: 'primary',
            size: 'small',
            startIcon: <ExportIcon/>,
        };
        return (<>
            <Tooltip title="Excel Export">
                {
                    !loadingExcel && (<Button {...buttonBaseProps}
                                              onClick={(e) => exportToExcel(status.fileName)} color="primary"
                                              style={{cursor: "pointer", fontSize: 14}}>
                            Export
                        </Button>
                    )}
                {loadingExcel &&
                    (
                        <Button variant="contained" disabled>
                            <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                            Preparing Excel...
                        </Button>
                    )
                }
            </Tooltip>
        </>)

    }
    const tableReload = () => {
        getActiveInvoiceData();
    }
    const postedTableReload = () => {
        getPostedInvoiceData();
    }
    const failedTableReload = () => {
        getFailedInvoiceData();
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        setLocalStorage();
        await getActiveInvoiceData();
    }, [controller, queryOptionsActive, queryOptionsSortActive])
    useEffect(async () => {
        setLocalStorage();
        await getPostedInvoiceData();
    }, [controllerPosted, queryOptionsPosted, queryOptionsSortPosted])
    useEffect(async () => {
        setLocalStorage();
        await getFailedInvoiceData();
    }, [controllerFailed, queryOptionsFailed, queryOptionsSortFailed])

    return (
        <>
        <FullScreenLoader isLoading={loading} />
            <CusNavBar/>
            <Button
            style={{ backgroundColor: "gray", color:"white", marginTop: "5px", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row"}}
            className={"ms-1"}
            onClick={() => {navigate("/dashboard");}}
        >
            <MdArrowBack /> Go Back
            </Button>
            <Header text="Invoices List"/>
            {editInvoiceLoading && <APEditFormLoading/>}


            <button style={{float: "right"}} onClick={() => manualReloadTables()}
                    className="btn btn-primary">Reload Invoices
            </button>
            <Tabs defaultActiveKey="active_invoices" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="active_invoices" title="Active Invoices">
                    <div style={{"height": "650px"}}>
                    <Button
    onClick={toggleSelectAllInvoices}
    style={{margin: '10px'}}
>
    {selectedInvoices.length === allInvoiceIds.length ? 'Deselect All' : 'Select All'}
</Button>
                        {!tableLoading ? <>
                            <ExportExcel status={"active"}
                                         fileName={"Active_Invoices"}></ExportExcel>

                            <CusTable columns={tableColumns}
                                      editCallback={tableReload}
                                      data={jsonData}
                                      rowCount={rowCount}
                                      paginationModel={controller}
                                      onPaginationModelChange={gatedSetController}
                                      onFilterModelChange={onFilterChangeActive}
                                      onSortModelChange={handleSortModelChangeActive}
                                      loading={activeLoading}
                                      success={setShowSuccessPopUp}
                                      failure={setShowFailPopUp}
                                      type="active"
                            />

                        </> : <APTableLoading/>}
                    </div>
                    <button onClick={() => handlePostSelectedInvoices()}
                    className="btn btn-primary ml-2 mt-20">Post All Invoices
            </button>
                </Tab>
                <Tab eventKey="posted_invoices" title="Posted Invoices">
                    {!postedTableLoading ? <>
                        <ExportExcel status={"posted"}
                                     fileName={"Posted_Invoices"}></ExportExcel>
                        <CusTable columns={postedColumns}
                                  editCallback={postedTableReload}
                                  data={postedJsonData}
                                  rowCount={rowPostedCount}
                                  paginationModel={controllerPosted}
                                  onPaginationModelChange={gatedSetControllerPosted}
                                  onFilterModelChange={onFilterChangePosted}
                                  onSortModelChange={handleSortModelChangePosted}
                                  loading={postedLoading}
                                  success={setShowSuccessPopUp}
                                  failure={setShowFailPopUp}
                                  type="posted"
                        />

                    </> : <APTableLoading/>}
                </Tab>
                <Tab eventKey="failed_invoices" title="Failed Invoices">
                    {!failedTableLoading ? <>
                        <ExportExcel status={"failed"}
                                     fileName={"Failed_Invoices"}></ExportExcel>
                        <CusTable columns={failedColumns}
                                  editCallback={failedTableReload}
                                  data={failedJsonData}
                                  rowCount={rowFailedCount}
                                  paginationModel={controllerFailed}
                                  onPaginationModelChange={gatedSetControllerFailed}
                                  onFilterModelChange={onFilterChangeFailed}
                                  onSortModelChange={handleSortModelChangeFailed}
                                  loading={failedLoading}
                                  success={setShowSuccessPopUp}
                                  failure={setShowFailPopUp}
                                  type="failed"
                        />
                    </> : <APTableLoading/>}
                </Tab>
            </Tabs>


            <Footer/>
        </>
    )
}

export default InvoicesList;
