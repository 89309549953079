import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import "../../index.css";
import {
    Card,
    CardContent,
    Checkbox,
    Container,
    FormControlLabel,
} from "@mui/material";
import { ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomAmountInput from "../../global_components/inputs/amount";
import CustomTextInput from "../../global_components/inputs/text";
import CustomDatePicker from "../../global_components/custom_date_picker";
import {formatDate} from "../../services/helper";
import CustomNumberInput from "../../global_components/inputs/number";
import CustomLongInput from "../../global_components/inputs/long-text";
import { guestData } from './guestData';

export default function GeneralInformation({formIK, step, progress, changeStep}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [bondSubCategoryOptions, setBondSubCategoryOptions] = useState([]);
    const [premiumVal, setPremiumVal] = useState(0);
    const processInput = guestData;
    const normalInputs = processInput.filter((input)=>input.is_line_item===false);

    const validate = () => {
        let error = false;
        let errors = {};
        if(formIK.values.estate_has_ongoing_business === "" || formIK.values.estate_has_ongoing_business === undefined){
            errors = {...errors, estate_has_ongoing_business: 'Estate Has Ongoing Business is required.'};
            error = true;
        }

        if(formIK.values.estate_has_ongoing_business === "yes"){
            if(formIK.values.type_of_business_in_operation === "" || formIK.values.type_of_business_in_operation === undefined){
                errors = {...errors, type_of_business_in_operation: 'Business explanation is required.'};
                error = true;
            }
        }

        if(formIK.values.personal_representative_indebited_to_estate === "" || formIK.values.personal_representative_indebited_to_estate === undefined){
            errors = {...errors, personal_representative_indebited_to_estate: 'Personal Representative Indebted To Estate is required.'};
            error = true;
        }
        if(formIK.values.decedent_execute_last_will_and_testament === "" || formIK.values.decedent_execute_last_will_and_testament === undefined){
            errors = {...errors, decedent_execute_last_will_and_testament: 'Decedent Execute Last Will And Testament is required.'};
            error = true;
        }
        if(formIK.values.applicant_replace_any_previous_fiduciary_in_this_estate === "" || formIK.values.applicant_replace_any_previous_fiduciary_in_this_estate === undefined){
            errors = {...errors, applicant_replace_any_previous_fiduciary_in_this_estate: 'Applicant Replace Any Previous Fiduciary In This Estate is required.'};
            error = true;
        }
        if(formIK.values.applicant_replace_any_previous_fiduciary_in_this_estate === "yes"){
            if(formIK.values.previous_fiduciaries === "" || formIK.values.previous_fiduciaries === undefined){
                errors = {...errors, previous_fiduciaries: 'Previous fiduciaries explanation is required.'};
                error = true;
            }
        }
        if(formIK.values.declined_by_another === "" || formIK.values.declined_by_another === undefined){
            errors = {...errors, declined_by_another: 'Bankruptcy status is required.'};
            error = true;
        }
        if(formIK.values.ever_bankrupt === "" || formIK.values.ever_bankrupt === undefined){
            errors = {...errors, ever_bankrupt: 'State is required.'};
            error = true;
        }
        if(formIK.values.replace_previous_bond === "" || formIK.values.replace_previous_bond === undefined){
            errors = {...errors, replace_previous_bond: 'Bond replacing status is required.'};
            error = true;
        }
        if(formIK.values.lawsuits_judgment_liens === "" || formIK.values.lawsuits_judgment_liens === undefined){
            errors = {...errors, lawsuits_judgment_liens: 'Lawsuits judgment liens is required.'};
            error = true;
        }
        if(formIK.values.any_disputes === "" || formIK.values.any_disputes === undefined){
            errors = {...errors, any_disputes: 'Dispute with other court status is required.'};
            error = true;
        }
        if(formIK.values.is_required_on_demand === "" || formIK.values.is_required_on_demand === undefined){
            errors = {...errors, is_required_on_demand: 'Is Required On Demand is mandatory field.'};
            error = true;
        }
        if(formIK.values.is_required_on_demand === "yes"){
            if(formIK.values.interested_parties === "" || formIK.values.interested_parties === undefined){
                errors = {...errors, interested_parties: 'Interested parties explanation is mandatory field.'};
                error = true;
            }
        }
        if(formIK.values.bond_amount === 50000){
            if(formIK.values.e_signed_indemnity === "" || formIK.values.e_signed_indemnity === undefined){
                errors = {...errors, e_signed_indemnity: 'This is a mandatory field.'};
                error = true;
            }
        }

        if(error === true){
            formIK.setErrors(errors);
            window.scroll(0,0);
            console.log(formIK.errors);
        }else{
            changeStep(progress+step);
        }
    }

    useEffect(()=>{

    });

    return(
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e)=>{
                                    e.preventDefault();
                                    changeStep(progress-step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e)=> {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={ false } className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{ maxWidth: "50%" }} style={{marginBottom: "2%", paddingBottom: "10%"}}>

                            <CardContent>
                                <h4>General Information</h4>
                                <Row>
                                    <Col>
                                        <Form.Group key="estimated_estate_assets" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label><strong>Estimated Estate Assets</strong></Form.Label>
                                            <CustomAmountInput disabled={false} required={true} name="estimated_estate_assets"
                                                               className={"form-control"} id="estimated_estate_assets-input" decimalScale={2}
                                                               value={formIK.values.estimated_estate_assets} onChange={(value)=>{ formIK.setFieldValue("estimated_estate_assets", parseInt(value)); }
                                            }/>
                                            <div className="text-error">{formIK.errors["estimated_estate_assets"]}</div>
                                        </Form.Group>
                                        <Form.Group key="estate_has_ongoing_business" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Will this estate include an ongoing business?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="estate_has_ongoing_business" value={formIK.values.estate_has_ongoing_business}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("estate_has_ongoing_business", value);
                                                               }} options={normalInputs.filter((input)=>input.name === "estate_has_ongoing_business")[0].options}/>
                                            <div className="text-error">{formIK.errors["estate_has_ongoing_business"]}</div>
                                        </Form.Group>
                                        {
                                            formIK.values['estate_has_ongoing_business'] === 'yes' &&
                                            <>
                                                <Form.Group key="type_of_business_in_operation" className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{
                                                                display: "inline-grid",
                                                                padding: "0px 15px 0px 0px"
                                                            }}>
                                                    <Form.Label className='required-label'>
                                                        <strong>If YES, what type of business will be in
                                                            operation</strong></Form.Label>
                                                    <CustomTextInput disabled={false} required={false}
                                                                     name="type_of_business_in_operation"
                                                                     className={"form-control"}
                                                                     id="type_of_business_in_operation-input" value={formIK.values["type_of_business_in_operation"]}
                                                                     onChange={(value) => {
                                                                         formIK.setFieldValue("type_of_business_in_operation", value);
                                                                     }}/>
                                                    <div className="text-error">{formIK.errors["type_of_business_in_operation"]}</div>
                                                </Form.Group>
                                            </>
                                        }
                                        <Form.Group key="personal_representative_indebited_to_estate" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Are any of the Personal Representatives indebted to the Estate?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="personal_representative_indebited_to_estate" value={formIK.values.personal_representative_indebited_to_estate}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("personal_representative_indebited_to_estate", value);
                                                               }} options={normalInputs.filter((input)=>input.name === "personal_representative_indebited_to_estate")[0].options}/>
                                            <div className="text-error">{formIK.errors["personal_representative_indebited_to_estate"]}</div>
                                        </Form.Group>
                                        <Form.Group key="decedent_execute_last_will_and_testament" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Did the Decedent execute a Last Will & Testament?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="decedent_execute_last_will_and_testament" value={formIK.values.decedent_execute_last_will_and_testament}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("decedent_execute_last_will_and_testament", value);
                                                               }} options={normalInputs.filter((input)=>input.name === "decedent_execute_last_will_and_testament")[0].options}/>
                                            <div className="text-error">{formIK.errors["decedent_execute_last_will_and_testament"]}</div>
                                        </Form.Group>
                                        <Form.Group key="applicant_replace_any_previous_fiduciary_in_this_estate" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Does applicant replace any previous fiduciary in this Estate?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="applicant_replace_any_previous_fiduciary_in_this_estate" value={formIK.values.applicant_replace_any_previous_fiduciary_in_this_estate}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("applicant_replace_any_previous_fiduciary_in_this_estate", value);
                                                               }} options={normalInputs.filter((input)=>input.name === "applicant_replace_any_previous_fiduciary_in_this_estate")[0].options}/>
                                            <div className="text-error">{formIK.errors["applicant_replace_any_previous_fiduciary_in_this_estate"]}</div>
                                        </Form.Group>
                                        {
                                            formIK.values['applicant_replace_any_previous_fiduciary_in_this_estate'] === 'yes' && (
                                                <Form.Group key="previous_fiduciaries" className="col-12" controlId="formBasicEmail">
                                                    <Form.Label><strong>If there have been previous fiduciaries, name them here and explain each one.</strong></Form.Label>
                                                    <CustomLongInput disabled={false} required={false} name="previous_fiduciaries"
                                                                     className={"form-control"} id={"previous_fiduciaries-input"} value={formIK.values.previous_fiduciaries}
                                                                     onChange={(value)=>{
                                                                         formIK.setFieldValue("previous_fiduciaries", value);
                                                                     }}
                                                    />
                                                    <div className="text-error">{formIK.errors["previous_fiduciaries"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key={"declined_by_another"} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Is this bond declined by any other surety?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name={"declined_by_another"} value={formIK.values.declined_by_another}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("declined_by_another", value);
                                                               }}
                                                               options={normalInputs.filter((li)=>li.name==='declined_by_another')[0].options}/>
                                            <div className="text-error">{formIK.errors['declined_by_another']}</div>
                                        </Form.Group>
                                        <Form.Group key={"ever_bankrupt"} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Has any applicant ever been bankrupt or insolvent?
                                            </strong></Form.Label>
                                            <CustomSelectInput disabled={false} name={"ever_bankrupt"} value={formIK.values.ever_bankrupt}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("ever_bankrupt", value);
                                                               }}
                                                               options={normalInputs.filter((li)=>li.name==='ever_bankrupt')[0].options}/>
                                            <div className="text-error">{formIK.errors['ever_bankrupt']}</div>
                                        </Form.Group>
                                        <Form.Group key={"replace_previous_bond"} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Does this bond replace any prior bond?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name={"replace_previous_bond"} value='no'
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("replace_previous_bond", value);
                                                               }}
                                                               options={normalInputs.filter((li)=>li.name==='replace_previous_bond')[0].options}/>
                                            <div className="text-error">{formIK.errors['replace_previous_bond']}</div>
                                        </Form.Group>
                                        <Form.Group key={"lawsuits_judgment_liens"} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Does any applicant have any outstanding lawsuits, judgments, or liens?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name={"lawsuits_judgment_liens"} value={formIK.values.lawsuits_judgment_liens}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("lawsuits_judgment_liens", value);
                                                               }}
                                                               options={normalInputs.filter((li)=>li.name==='lawsuits_judgment_liens')[0].options}/>
                                            <div className="text-error">{formIK.errors['lawsuits_judgment_liens']}</div>
                                        </Form.Group>
                                        <Form.Group key={"any_disputes"} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Any disputes between heirs?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name={"any_disputes"} value={formIK.values.any_disputes}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("any_disputes", value);
                                                               }}
                                                               options={normalInputs.filter((li)=>li.name==='any_disputes')[0].options}/>
                                            <div className="text-error">{formIK.errors['any_disputes']}</div>
                                        </Form.Group>
                                        <Form.Group key="is_required_on_demand" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Is this bond required on the demand of an interested party other than the Court?</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="is_required_on_demand" value={formIK.values.is_required_on_demand}
                                                               onChange={(value)=>{
                                                                   formIK.setFieldValue("is_required_on_demand", value);
                                                               }} options={normalInputs.filter((input)=>input.name === "is_required_on_demand")[0].options}/>
                                            <div className="text-error">{formIK.errors["is_required_on_demand"]}</div>
                                        </Form.Group>
                                        {
                                            formIK.values['is_required_on_demand'] === 'yes' &&
                                            <Form.Group key="interested_parties" className="col-12" controlId="formBasicEmail">
                                                <Form.Label className='required-label'>
                                                    <strong>If YES, please list interested parties and give a brief explanation.</strong></Form.Label>
                                                <CustomLongInput disabled={false} required={false} name="interested_parties"
                                                                 className={"form-control"} id={"interested_parties-input"} value={formIK.values.interested_parties}
                                                                 onChange={(value)=>{
                                                                     formIK.setFieldValue("interested_parties", value);
                                                                 }}
                                                />
                                                <div className="text-error">{formIK.errors["interested_parties"]}</div>
                                            </Form.Group>
                                        }
                                        {
                                            formIK.values.bond_amount > 50000 && (
                                                <Form.Group key="e_signed_indemnity" className="col-12" controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Do you want an E-signed indemnity form?</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="e_signed_indemnity" value={formIK.values.e_signed_indemnity}
                                                                       onChange={(value)=>{
                                                                           formIK.setFieldValue("e_signed_indemnity", value);
                                                                       }} options={normalInputs.filter((input)=>input.name === "e_signed_indemnity")[0].options}/>
                                                    <div className="text-error">{formIK.errors["e_signed_indemnity"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e)=>{
                                    e.preventDefault();
                                    changeStep(progress-step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e)=> {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={ false } className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

GeneralInformation.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
