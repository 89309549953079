import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import PropTypes from "prop-types";

export default function CustomDatePicker(props) {
    const [value, onChange] = useState(new Date(props.value+" 00:00:00"));
    return (
        <DatePicker readOnly={props.disabled} className={props.className} name={props.name} id={props.id} value={value}
                    onChange={(value)=> {
                        onChange(value);
                        props.onChange(value);
                    }} required={props.required} format={props.format}/>
    );
}

CustomDatePicker.propTypes = {
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};
