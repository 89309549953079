import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    processes: {},
    current_active: ''
}

const processCategoriesSlice = createSlice({
    name: 'processCategory',
    initialState,
    reducers: {
        setCurrentActive(state, action){
            state.current_active = action.payload;
        },
        removeCurrentActive(state, action){
            state.current_active = '';
        },
        addProcess(state, action){
            state.processes[action.payload['process_name']] = action.payload['processes'];
        },
        addProcessInputs(state, action){
            state.processes[action.payload['process_name']].map((process, idx)=>{
                if(process.id === action.payload['processID']){
                    state.processes[action.payload['process_name']][idx]['inputs'] = action.payload['processInputs'];
                }
            })
        },
        removeProcess(state, action){
            state.processes = {};
        },
    }
});

export const  {setCurrentActive, removeCurrentActive, addProcess, removeProcess, addProcessInputs} = processCategoriesSlice.actions;

export default processCategoriesSlice.reducer;
