import React from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import {Card, CardContent, Container} from "@mui/material";
import {Col, Form, Row} from "react-bootstrap";
import {ArrowLeft, ArrowRight} from "react-bootstrap-icons";
import "../../index.css";
import CustomTextInput from "../../global_components/inputs/text";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomDatePicker from "../../global_components/custom_date_picker";
import {formatDateMDY} from "../../services/helper";
import PatternFormat from "react-number-format";
import CustomAmountInput from "../../global_components/inputs/amount";
import Collapsible from 'react-collapsible';
import {BsChevronDown} from "react-icons/bs";
import "../../styles/main.css";
import DateNormalInput from "../../global_components/inputs/date-normal-input";
import {Logout} from "../../utils/user_logout";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {
    removeCompany,
    removeDepartmentApprovementLevel,
    removeDepartments,
    removeSelectedDepartment, removeSelectedPermissionAP,
    removeToken
} from "../../store/auth";
import {removeCategories, removeInvoicesDashboard} from "../../store/dashboard";
import {removeProcess, setCurrentActive} from "../../store/processes";
import {removeAccountNumbers, removeAllInvoices} from "../../store/invoices";
import {
    removeAPPermissions, removeBondNumbers,
    removeDepartmentsCM,
    removeLawFirmBranches,
    removeLawFirmContacts,
    removeLawFirmProfessionalGuardians,
    removeLawFirms,
    removeLawSchools,
    removePOPermissions,
    removeProcessPermissions,
    removeSuperPOUsers,
    removeUsers
} from "../../store/company_management";
import {removeDocuments} from "../../store/document_approval";
import {removeProcessDetails, removeProcessHistory, removeProcessInputs} from "../../store/capital_project";
import {
    removeBondProcessDetails,
    removeBondProcessHistory,
    removeBondProcessInputs,
    removeLawFirmData
} from "../../store/bond";
import {useNavigate} from "react-router";

export default function Property(props) {
    const prePopulated = JSON.parse(localStorage.getItem('prePopulatedFields'));
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = ()=>{
        enqueueSnackbar('You are logged out. Redirecting to login page in 3 seconds.', {variant: "error"});
        setTimeout(() => {
            localStorage.clear();
            dispatch(removeToken());
            dispatch(removeCompany());
            dispatch(removeInvoicesDashboard());
            dispatch(removeCategories());
            dispatch(removeProcess());
            dispatch(setCurrentActive());
            dispatch(removeAllInvoices());
            dispatch(removeAccountNumbers());
            dispatch(removeDepartments());
            dispatch(removeSelectedDepartment());
            dispatch(removeDepartmentApprovementLevel());
            dispatch(removeSelectedPermissionAP());
            dispatch(removeUsers());
            dispatch(removeDepartmentsCM());
            dispatch(removeAPPermissions());
            dispatch(removePOPermissions());
            dispatch(removeSuperPOUsers());
            dispatch(removeProcessPermissions());
            dispatch(removeDocuments());
            dispatch(removeProcessHistory());
            dispatch(removeProcessDetails());
            dispatch(removeProcessInputs());
            dispatch(removeBondProcessInputs());
            dispatch(removeBondProcessDetails());
            dispatch(removeBondProcessHistory());
            dispatch(removeLawFirmData());
            dispatch(removeLawFirms());
            dispatch(removeLawFirmContacts());
            dispatch(removeLawFirmBranches());
            dispatch(removeLawSchools());
            dispatch(removeLawFirmProfessionalGuardians());
            dispatch(removeBondNumbers());
            navigate("/");

        }, 3000);
    }

    const validate_data = () => {
        if (!localStorage.getItem('userdata')) {
            logout();
            return;
        }
        let error = false;
        if (props.formIK.values.asi_progressive === true) {
            if (props.formIK.values.is_new_purchase === "") {
                props.formIK.errors['is_new_purchase'] = 'New house status is required.';
                error = true;
            }
            if (props.formIK.values.is_new_purchase === "Yes" && props.formIK.values.purchase_amount === "") {
                props.formIK.errors['purhcase_amount'] = 'Purchase Amount is required.';
                error = true;
            }
            if (props.formIK.values.prior_liability_limit === "") {
                props.formIK.errors['prior_liability_limit'] = 'Prior liability limit is required.';
                error = true;
            }
        }
        if (props.formIK.values.asi_progressive === true) {
            if (props.formIK.values.purchase_date === "" || new Date(props.formIK.values.purchase_date).toString() === 'Invalid Date') {
                props.formIK.errors['purchase_date'] = 'Purchase Date is required.';
                error = true;
            }
            if (props.formIK.values.fire_hydrant === "") {
                props.formIK.errors['fire_hydrant'] = 'Fire Hydrant Within 1000 Feet is required.';
                error = true;
            }
        }
        if (props.formIK.values.square_footage === "") {
            props.formIK.errors['square_footage'] = 'Square Footage is required.';
            error = true;
        }
        if (props.formIK.values.year_built === "" || props.formIK.values.year_built === null || props.formIK.values.year_built === undefined || String(props.formIK.values.year_built).includes('#')) {
            props.formIK.errors['year_built'] = 'Year Built is required.';
            error = true;
        }

        if (props.formIK.values.year_roof_installed === "" || props.formIK.values.year_roof_installed === null || props.formIK.values.year_roof_installed === undefined || String(props.formIK.values.year_roof_installed).includes('#')) {
            props.formIK.errors['year_roof_installed'] = 'Year Roof Installed is required.';
            error = true;
        }

        if (props.formIK.values.sage_sure === true || props.formIK.values.ssiu === true) {
            if (props.formIK.values.year_wiring_installed === "" || props.formIK.values.year_wiring_installed === null || props.formIK.values.year_wiring_installed === undefined || String(props.formIK.values.year_wiring_installed).includes('#')) {
                props.formIK.errors['year_wiring_installed'] = 'Year Wiring Installed is required.';
                error = true;
            }
            if (props.formIK.values.year_pluming_installed === "" || props.formIK.values.year_pluming_installed === null || props.formIK.values.year_pluming_installed === undefined || String(props.formIK.values.year_pluming_installed).includes('#')) {
                props.formIK.errors['year_pluming_installed'] = 'Year Pluming Installed is required.';
                error = true;
            }
            if (props.formIK.values.year_hvac_installed === "" || props.formIK.values.year_hvac_installed === null || props.formIK.values.year_hvac_installed === undefined || String(props.formIK.values.year_hvac_installed).includes('#')) {
                props.formIK.errors['year_hvac_installed'] = 'Year HVAC Installed is required.';
                error = true;
            }
        }

        if (props.formIK.values.sage_sure === true || props.formIK.values.ssiu === true) {
            if (props.formIK.values.year_wiring_installed === "" || String(props.formIK.values.year_wiring_installed) === "####") {
                props.formIK.errors['year_wiring_installed'] = 'Year Wiring Installed is required.';
                error = true;
            }
            if (props.formIK.values.year_pluming_installed === "" || String(props.formIK.values.year_pluming_installed) === "####") {
                props.formIK.errors['year_pluming_installed'] = 'Year Pluming Installed is required.';
                error = true;
            }
            if (props.formIK.values.year_hvac_installed === "" || String(props.formIK.values.year_hvac_installed) === "####") {
                props.formIK.errors['year_hvac_installed'] = 'Year HVAC Installed is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.ssiu === true || props.formIK.values.swyfft === true) {
            if (props.formIK.values.protection_class === "") {
                props.formIK.errors['protection_class'] = 'Protection Class is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.sage_sure === true || props.formIK.values.openly === true ||
            props.formIK.values.branch === true) {
            if (props.formIK.values.residence_type === "") {
                props.formIK.errors['residence_type'] = 'Residence Type is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true || props.formIK.values.branch === true) {
            if (props.formIK.values.number_of_residence === "") {
                props.formIK.errors['number_of_residence'] = 'Number of residents is required.';
                error = true;
            }
        }
        if (props.formIK.values.asi_progressive === true) {
            if (props.formIK.values.household_occupant_under_21 === "") {
                props.formIK.errors['household_occupant_under_21'] = 'Number of occupant under 21 is required.';
                error = true;
            }
        }

        if (props.formIK.values.openly === true) {
            if (props.formIK.values.currently_insured_openly === "") {
                props.formIK.errors['currently_insured_openly'] = 'Currently Insured Openly is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true ||
            props.formIK.values.asi_progressive === true || props.formIK.values.swyfft === true) {
            if (props.formIK.values.currently_insured_other_carriers === "") {
                props.formIK.errors['currently_insured_other_carriers'] = 'Currently Insured Other Carriers is required.';
                error = true;
            }
        }

        if (props.formIK.values.construction_type === "") {
            props.formIK.errors['construction_type'] = 'Construction Type is required.';
            error = true;
        }

        if (props.formIK.values.asi_progressive || props.formIK.values.ssiu || props.formIK.values.openly ||
            props.formIK.values.branch) {
            if (props.formIK.values.exterior_walls === "") {
                props.formIK.errors['exterior_walls'] = 'Exterior Walls is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive || props.formIK.values.ssiu || props.formIK.values.orion) {
            if (props.formIK.values.foundation_type === "") {
                props.formIK.errors['foundation_type'] = 'Foundation Type is required.';
                error = true;
            }
        }

        if (props.formIK.values.openly) {
            if (props.formIK.values.basement === "") {
                props.formIK.errors['basement'] = 'Basement is required.';
                error = true;
            }
        }

        if (props.formIK.values.occupancy_type === "") {
            props.formIK.errors['occupancy_type'] = 'Occupancy Type is required.';
            error = true;
        }

        if (props.formIK.values.roof_material === "") {
            props.formIK.errors['roof_material'] = 'Roof Material is required.';
            error = true;
        }

        if (props.formIK.values.roof_shape === "") {
            props.formIK.errors['roof_shape'] = 'Roof Shape is required.';
            error = true;
        }

        if (props.formIK.values.ssiu === true) {
            if (props.formIK.values.roof_sheathing === "") {
                props.formIK.errors['roof_sheathing'] = 'Roof Sheathing is required.';
                error = true;
            }
            if (props.formIK.values.roof_straps_type === "") {
                props.formIK.errors['roof_straps_type'] = 'Roof Straps Type is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.openly === true || props.formIK.values.ssiu === true ||
            props.formIK.values.sage_sure === true || props.formIK.values.branch === true) {
            if (props.formIK.values.number_of_stories === "") {
                props.formIK.errors['number_of_stories'] = 'Number of Stories is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true || props.formIK.values.openly === true ||
            props.formIK.values.branch === true) {
            if (props.formIK.values.garage === "") {
                props.formIK.errors['garage'] = 'Garage is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true || props.formIK.values.openly === true || props.formIK.values.ssiu === true) {
            if (props.formIK.values.swimming_pool === "") {
                props.formIK.errors['swimming_pool'] = 'Swimming Pool value is required.';
                error = true;
            }
        }

        if (props.formIK.values.openly === true) {
            if (props.formIK.values.solar_panel === "") {
                props.formIK.errors['solar_panel'] = 'Solar Panel value is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true) {
            if (props.formIK.values.bathroom_grade === "") {
                props.formIK.errors['bathroom_grade'] = 'Bathroom Grade value is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true || props.formIK.values.openly === true || props.formIK.values.branch === true) {
            if (props.formIK.values.number_of_full_bath === "") {
                props.formIK.errors['number_of_full_bath'] = 'Number of Full Baths value is required.';
                error = true;
            }
        }
        if (props.formIK.values.asi_progressive === true || props.formIK.values.openly === true) {
            if (props.formIK.values.number_of_half_bath === "") {
                props.formIK.errors['number_of_half_bath'] = 'Number of 1/2 Baths value is required.';
                error = true;
            }
        }

        if (props.formIK.values.openly === true) {
            if (props.formIK.values.number_of_half_bath === "") {
                props.formIK.errors['number_of_half_bath'] = 'Number of 1/2 Baths value is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true) {
            if (props.formIK.values.kitchen_grade === "") {
                props.formIK.errors['kitchen_grade'] = 'Kitchen Grade value is required.';
                error = true;
            }
            if (props.formIK.values.cooling_air === "") {
                props.formIK.errors['cooling_air'] = 'Cooling/Air value is required.';
                error = true;
            }
            if (props.formIK.values.deck === "") {
                props.formIK.errors['deck'] = 'Deck value is required.';
                error = true;
            }
            if (props.formIK.values.deck !== "None" && (props.formIK.values.deck_size === "" || props.formIK.values.deck_size === undefined)) {
                props.formIK.errors['deck_size'] = 'Deck Size value is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true || props.formIK.values.openly === true) {
            if (props.formIK.values.permanent_heat_source === "") {
                props.formIK.errors['permanent_heat_source'] = 'Permanent Heat Source value is required.';
                error = true;
            }
            if (props.formIK.values.own_dog === "") {
                props.formIK.errors['own_dog'] = 'Own Dog value is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true || props.formIK.values.swyfft === true) {
            if (props.formIK.values.ineligible_animal_present === "") {
                props.formIK.errors['ineligible_animal_present'] = 'Ineligible Animal Present value is required.';
                error = true;
            }
        }

        if (props.formIK.values.openly === true) {
            if (props.formIK.values.onsite_business === "") {
                props.formIK.errors['onsite_business'] = 'Onsite Business Status is required.';
                error = true;
            }
        }

        if (props.formIK.values.ssiu === true) {
            if (props.formIK.values.tree_over_structure === "") {
                props.formIK.errors['tree_over_structure'] = 'Any tree hanging over structure? value is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.ssiu === true) {
            if (props.formIK.values.window_and_door_opening_protection === "") {
                props.formIK.errors['window_and_door_opening_protection'] = 'Window & Door Opening Protection value is required.';
                error = true;
            }
            if (props.formIK.values.roof_damage_exclusion === "") {
                props.formIK.errors['roof_damage_exclusion'] = 'Roof Damage Exclusion value is required.';
                error = true;
            }
        }

        if (props.formIK.values.ssiu === true) {
            if (props.formIK.values.burglar_alarm === "") {
                props.formIK.errors['burglar_alarm'] = 'Burglar Alarm value is required.';
                error = true;
            }
            if (props.formIK.values.fire_alarm === "") {
                props.formIK.errors['fire_alarm'] = 'Fire Alarm value is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true) {
            if (props.formIK.values.tankless_water_heater === "") {
                props.formIK.errors['tankless_water_heater'] = 'Tankless Water Heater value is required.';
                error = true;
            }
            if (props.formIK.values.tree_free_yard === "") {
                props.formIK.errors['tree_free_yard'] = 'Tree Free Yard value is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true || props.formIK.values.openly === true) {
            if (props.formIK.values.exclude_wind === "") {
                props.formIK.errors['exclude_wind'] = 'Exclude Wind value is required.';
                error = true;
            }
            if (props.formIK.values.loss_settlement_wind_hail_losses_to_roof === "") {
                props.formIK.errors['loss_settlement_wind_hail_losses_to_roof'] = 'Loss Settlement Wind Hail Losses to Roof value is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true) {
            if (props.formIK.values.residents_smoke === "") {
                props.formIK.errors['residents_smoke'] = 'Residents Smoke value is required.';
                error = true;
            }
            if (props.formIK.values.package_policy === "") {
                props.formIK.errors['package_policy'] = 'Package Policy value is required.';
                error = true;
            }
            if (props.formIK.values.home_update === "") {
                props.formIK.errors['home_update'] = 'Home Update value is required.';
                error = true;
            }
            if (props.formIK.values.home_update === 'Yes' && props.formIK.values.latest_permit_date === "") {
                props.formIK.errors['latest_permit_date'] = 'Latest Permit Date value is required.';
                error = true;
            }
        }

        if (props.formIK.values.ssiu === true) {
            if (props.formIK.values.losses_bankruptcy_or_foreclosures === "") {
                props.formIK.errors['losses_bankruptcy_or_foreclosures'] = 'Losses Bankruptcy or Foreclosures value is required.';
                error = true;
            }
            if (props.formIK.values.flood_insured === "") {
                props.formIK.errors['flood_insured'] = 'Flood Insured value is required.';
                error = true;
            }
            if (props.formIK.values.undergoing_any_renovations === "") {
                props.formIK.errors['undergoing_any_renovations'] = 'Is Risk Undergoing Any Renovations value is required.';
                error = true;
            }
        }

        if (props.formIK.values.ssiu === true || props.formIK.values.openly === true) {
            if (props.formIK.values.building_code_discount === "") {
                props.formIK.errors['building_code_discount'] = 'Building Code Discount value is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.openly === true) {
            if (props.formIK.values.has_current_auto_policy_with_agency === "") {
                props.formIK.errors['has_current_auto_policy_with_agency'] = 'Is Current Auto policy with Agency? value is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true) {
            if (props.formIK.values.fire_claims === "") {
                props.formIK.errors['fire_claims'] = 'Fire Claims value is required.';
                error = true;
            }

            if (props.formIK.values.property_claims === "") {
                props.formIK.errors['property_claims'] = 'Property Claims value is required.';
                error = true;
            }

            if (props.formIK.values.liability_claims === "") {
                props.formIK.errors['liability_claims'] = 'Liability Claims value is required.';
                error = true;
            }
        }

        if (props.formIK.values.asi_progressive === true) {
            if (props.formIK.values.weather_claims === "") {
                props.formIK.errors['weather_claims'] = 'Weather Claims value is required.';
                error = true;
            }
        }

        if (error === true) {
            window.scroll(0, 0);
            console.log(props.formIK.errors);
        } else {
            props.changeStep(props.progress + props.step);
        }
    }

    return (
        <div>
            <Container>
                <Col>
                    <Row style={{marginBottom: '1%'}}>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button onClick={() => props.changeStep(props.progress - props.step)}
                                        className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                {/*<button onClick={()=>props.changeStep(props.progress+34)} className="btn btn-primary">*/}
                                <button onClick={() => validate_data()} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{minWidth: "100%"}} style={{marginBottom: "2%", overflow: "visible"}}>

                            <CardContent>
                                <Row>
                                    <h4>Property</h4>
                                </Row>
                                <Row>
                                    <Col className="property-cols">
                                        <h6>Property Attributes</h6>
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <>
                                                    <Form.Group key="is_new_purchase" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Is this home a new
                                                            purchase?</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="is_new_purchase"
                                                                           value={props.formIK.values.is_new_purchase !== undefined ? props.formIK.values.is_new_purchase : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['is_new_purchase'] = "New house status is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['is_new_purchase'];
                                                                               }
                                                                               props.formIK.setFieldValue("is_new_purchase", value)
                                                                           }}
                                                                           options={[{label: 'Yes', value: 'Yes'},
                                                                               {label: 'No', value: 'No'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["is_new_purchase"]}</div>
                                                    </Form.Group>
                                                    {
                                                        props.formIK.values.is_new_purchase === 'Yes' && (
                                                            <Form.Group key="purchase_amount" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className='required-label'><strong>Purchase
                                                                    Amount</strong></Form.Label>
                                                                <CustomAmountInput disabled={false} required={true}
                                                                                   name="purchase_amount"
                                                                                   className={"form-control"}
                                                                                   id="purchase_amount-input" decimalScale={0}
                                                                                   value="" onChange={(value) => {
                                                                    if (value === "" || isNaN(value)) {
                                                                        props.formIK.errors['purchase_amount'] = "Purchase Amount is required.";
                                                                    } else {
                                                                        delete props.formIK.errors['purchase_amount'];
                                                                    }
                                                                    //console.log(value)
                                                                    props.formIK.setFieldValue("purchase_amount", value);
                                                                }}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["purchase_amount"]}</div>
                                                            </Form.Group>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            (props.formIK.values.asi_progressive === true || props.formIK.values.branch === true) && (
                                                <Form.Group key="purchase_date" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Purchase
                                                        Date</strong></Form.Label>

                                                    <DateNormalInput disabled={false} required={true} name="purchase_date"
                                                                     className={prePopulated.includes('purchase_date') ? 'form-control prePopulated' : 'form-control '}
                                                                     id="purchase_date-input"
                                                                     value={props.formIK.values.purchase_date}
                                                                     onChange={(value) => {
                                                                         if (value === "") {
                                                                             props.formIK.errors['purchase_date'] = "Purchase Date is required.";
                                                                         } else {
                                                                             delete props.formIK.errors['purchase_date'];
                                                                         }
                                                                         props.formIK.setFieldValue("purchase_date", formatDateMDY(value));
                                                                     }}/>

                                                    {/*<CustomDatePicker className={prePopulated.includes('purchase_date') ? 'form-control prePopulated' : 'form-control '} name="purchase_date"*/}
                                                    {/*                  id="purchase_date-input"*/}
                                                    {/*                  value={props.formIK.values.purchase_date !== "" ?*/}
                                                    {/*                      props.formIK.values.purchase_date :*/}
                                                    {/*                      formatDateMDY(new Date())} format="MM-dd-y"*/}
                                                    {/*                  onChange={(value) => {*/}
                                                    {/*                      if(value === ""){*/}
                                                    {/*                          props.formIK.errors['purchase_date'] = "Purchase Date is required.";*/}
                                                    {/*                      }else{*/}
                                                    {/*                          delete props.formIK.errors['purchase_date'];*/}
                                                    {/*                      }*/}
                                                    {/*                      props.formIK.setFieldValue("purchase_date", formatDateMDY(value))*/}
                                                    {/*                  }} disabled={false}*/}
                                                    {/*                  required={true}/>*/}
                                                    <div className="text-error">{props.formIK.errors["purchase_date"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key="square_footage" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Square
                                                Footage</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name="square_footage"
                                                             className={prePopulated.includes('square_footage') ? 'form-control prePopulated' : 'form-control '}
                                                             id="square_footage-input"
                                                             value={props.formIK.values.square_footage}
                                                             onChange={(value) => {
                                                                 if (value === "") {
                                                                     props.formIK.errors['square_footage'] = "Square footage is required.";
                                                                 } else {
                                                                     delete props.formIK.errors['square_footage'];
                                                                 }
                                                                 props.formIK.setFieldValue("square_footage", value);
                                                             }}/>
                                            <div className="text-error">{props.formIK.errors["square_footage"]}</div>
                                        </Form.Group>
                                        <Form.Group key="year_built" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Year
                                                Built</strong></Form.Label>
                                            <PatternFormat
                                                className={prePopulated.includes('year_built') ? 'form-control prePopulated' : 'form-control '}
                                                name="year_built" id="year_built-input" disabled={false}
                                                defaultValue={props.formIK.values.year_built}
                                                format="####" allowEmptyFormatting mask="#"
                                                onChange={(value) => {
                                                    if (value === "") {
                                                        props.formIK.errors['year_built'] = "Year built is required.";
                                                    } else {
                                                        delete props.formIK.errors['year_built'];
                                                        delete props.formIK.errors['year_roof_installed'];
                                                        delete props.formIK.errors['year_wiring_installed'];
                                                        delete props.formIK.errors['year_pluming_installed'];
                                                        delete props.formIK.errors['year_hvac_installed'];
                                                    }
                                                    props.formIK.setFieldValue("year_built", value.target.value);
                                                    props.formIK.setFieldValue("year_roof_installed", value.target.value);
                                                    props.formIK.setFieldValue("year_wiring_installed", value.target.value);
                                                    props.formIK.setFieldValue("year_pluming_installed", value.target.value);
                                                    props.formIK.setFieldValue("year_hvac_installed", value.target.value);
                                                    if (!value.target.value.includes('#')) {
                                                        let currentYear = new Date().getFullYear();
                                                        if (currentYear - parseInt(value.target.value) > 15) {
                                                            props.formIK.setFieldValue('loss_settlement_wind_hail_losses_to_roof', 'Roofing Materials Payment Schedule');
                                                        }
                                                    }
                                                }}/>
                                            {props.formIK.errors["year_built"] && (
                                                <div className="text-error">{props.formIK.errors["year_built"]}</div>
                                            )}
                                        </Form.Group>
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <Form.Group key="fire_hydrant" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Fire Hydrant Within 1000
                                                        Feet</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="fire_hydrant"
                                                                       value={props.formIK.values.fire_hydrant !== undefined ? props.formIK.values.fire_hydrant : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['fire_hydrant'] = "Fire Hydrant Within 1000 Feet is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['fire_hydrant'];
                                                                           }
                                                                           props.formIK.setFieldValue("fire_hydrant", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    <div className="text-error">{props.formIK.errors["fire_hydrant"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        {/*<Form.Group key="year_roof_installed" controlId="formBasicEmail"*/}
                                        {/*            style={{padding: "0px 15px 0px 0px"}}>*/}
                                        <div key={`${props.formIK.values.year_built}-roof-installed-key`}>
                                            <Form.Label className='required-label'><strong>Year Roof Installed</strong></Form.Label>
                                            <PatternFormat
                                                className={prePopulated.includes('year_roof_installed') ? 'form-control prePopulated' : 'form-control '}
                                                name="year_roof_installed"
                                                id="year_roof_installed-input"
                                                defaultValue={props.formIK.values.year_roof_installed}
                                                format="####" allowEmptyFormatting mask="#"
                                                disabled={false} onChange={(value) => {
                                                if (value === "") {
                                                    props.formIK.errors['year_roof_installed'] = "Year roof installed is required.";
                                                } else {
                                                    delete props.formIK.errors['year_roof_installed'];
                                                }
                                                props.formIK.setFieldValue("year_roof_installed", value.target.value);
                                                if (!value.target.value.includes('#')) {
                                                    let currentYear = new Date().getFullYear();
                                                    if (currentYear - parseInt(value.target.value) > 15) {
                                                        props.formIK.setFieldValue('loss_settlement_wind_hail_losses_to_roof', 'Roofing Materials Payment Schedule');
                                                    }
                                                }
                                            }}
                                            />
                                            <div
                                                className="text-error">{props.formIK.errors["year_roof_installed"]}</div>
                                        </div>
                                        {/*</Form.Group>*/}
                                        {
                                            (props.formIK.values.sage_sure === true || props.formIK.values.ssiu === true) && (
                                                <div key={props.formIK.values.year_built}>
                                                    <Form.Group key="year_wiring_installed" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Year Wiring
                                                            Installed</strong></Form.Label>
                                                        <PatternFormat
                                                            className={prePopulated.includes('year_roof_installed') ? 'form-control prePopulated' : 'form-control '}
                                                            name="year_wiring_installed"
                                                            id="year_wiring_installed-input"
                                                            defaultValue={props.formIK.values.year_wiring_installed}
                                                            format="####" allowEmptyFormatting mask="#"
                                                            disabled={false} onChange={(value) => {
                                                            if (value === "") {
                                                                props.formIK.errors['year_wiring_installed'] = "Year wiring installed is required.";
                                                            } else {
                                                                delete props.formIK.errors['year_wiring_installed'];
                                                            }
                                                            props.formIK.setFieldValue("year_wiring_installed", value.target.value);
                                                        }}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["year_wiring_installed"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="year_pluming_installed" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Year Pluming
                                                            Installed</strong></Form.Label>
                                                        <PatternFormat
                                                            className={prePopulated.includes('year_roof_installed') ? 'form-control prePopulated' : 'form-control '}
                                                            name="year_pluming_installed"
                                                            id="year_pluming_installed-input"
                                                            defaultValue={props.formIK.values.year_pluming_installed}
                                                            format="####" allowEmptyFormatting mask="#"
                                                            disabled={false} onChange={(value) => {
                                                            if (value === "") {
                                                                props.formIK.errors['year_pluming_installed'] = "Year pluming installed is required.";
                                                            } else {
                                                                delete props.formIK.errors['year_pluming_installed'];
                                                            }
                                                            props.formIK.setFieldValue("year_pluming_installed", value.target.value);
                                                        }}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["year_pluming_installed"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="year_hvac_installed" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className='required-label'><strong>Year HVAC
                                                            Installed</strong></Form.Label>
                                                        <PatternFormat
                                                            className={prePopulated.includes('year_roof_installed') ? 'form-control prePopulated' : 'form-control '}
                                                            name="year_hvac_installed"
                                                            id="year_hvac_installed-input"
                                                            defaultValue={props.formIK.values.year_hvac_installed}
                                                            format="####" allowEmptyFormatting mask="#"
                                                            disabled={false} onChange={(value) => {
                                                            if (value === "") {
                                                                props.formIK.errors['year_hvac_installed'] = "Year HVAC installed is required.";
                                                            } else {
                                                                delete props.formIK.errors['year_hvac_installed'];
                                                            }
                                                            props.formIK.setFieldValue("year_hvac_installed", value.target.value);
                                                        }}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["year_hvac_installed"]}</div>
                                                    </Form.Group>
                                                </div>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.swyfft === true || props.formIK.values.ssiu === true) && (
                                                <Form.Group key="protection_class" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label
                                                        className={(props.formIK.values.orion === true && props.formIK.values.ssiu === true && props.formIK.values.swyfft === true) ? "required-label" : ""}><strong>Protection
                                                        Class</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="protection_class"
                                                                       value={props.formIK.values.protection_class !== undefined ? props.formIK.values.protection_class : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['protection_class'] = "Protection class is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['protection_class'];
                                                                           }
                                                                           props.formIK.setFieldValue("protection_class", value)
                                                                       }}
                                                                       options={[{label: '1', value: '1'}, {
                                                                           label: '2',
                                                                           value: '2'
                                                                       }, {label: '3', value: '3'},
                                                                           {label: '4', value: '4'}, {
                                                                               label: '5',
                                                                               value: '5'
                                                                           }, {label: '6', value: '6'},
                                                                           {label: '7', value: '7'}, {
                                                                               label: '8',
                                                                               value: '8'
                                                                           }, {label: '9', value: '9'}]}/>
                                                    <div
                                                        className="text-error">{props.formIK.errors["protection_class"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.sage_sure === true || props.formIK.values.openly === true ||
                                                props.formIK.values.branch === true) && (
                                                <Form.Group key="residence_type" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Residence
                                                        Type</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="residence_type"
                                                                       value={props.formIK.values.residence_type !== undefined ? props.formIK.values.residence_type : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['residence_type'] = "Residence type is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['residence_type'];
                                                                           }
                                                                           props.formIK.setFieldValue("residence_type", value)
                                                                       }}
                                                                       options={[{label: 'Apartment', value: 'Apartment'},
                                                                           {
                                                                               label: 'Condo',
                                                                               value: 'Condo'
                                                                           }, {label: 'Duplex', value: 'Duplex'},
                                                                           {
                                                                               label: 'Mobile Home',
                                                                               value: 'Mobile Home'
                                                                           }, {label: 'Quadplex', value: 'Quadplex'},
                                                                           {
                                                                               label: 'Row House, Center',
                                                                               value: 'Row House, Center'
                                                                           }, {
                                                                               label: 'Row House, End',
                                                                               value: 'Row House, End'
                                                                           },
                                                                           {
                                                                               label: 'Single Family Home',
                                                                               value: 'Single Family Home'
                                                                           }, {
                                                                               label: 'Town House, Center',
                                                                               value: 'Town House, Center'
                                                                           },
                                                                           {
                                                                               label: 'Town House, End',
                                                                               value: 'Town House, End'
                                                                           }, {label: 'Triplex', value: 'Triplex'}
                                                                       ]}
                                                                       className={prePopulated.includes('residence_type') ? 'prePopulated' : ''}/>
                                                    <div
                                                        className="text-error">{props.formIK.errors["residence_type"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            props.formIK.values.openly === true && (
                                                <Form.Group key="currently_insured_openly" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Currently insured -
                                                        Openly</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="currently_insured"
                                                                       value={props.formIK.values.currently_insured_openly !== undefined ? props.formIK.values.currently_insured_openly : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['currently_insured_openly'] = "Currently Insured Openly is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['currently_insured_openly'];
                                                                           }
                                                                           props.formIK.setFieldValue("currently_insured_openly", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'},
                                                                           {
                                                                               label: 'Client doesn\'t yet own and not insured',
                                                                               value: 'Client doesn\'t yet own and not insured'
                                                                           },
                                                                           {
                                                                               label: 'Client owns and not insured',
                                                                               value: 'Client owns and not insured'
                                                                           }
                                                                       ]}/>
                                                    <div
                                                        className="text-error">{props.formIK.errors["currently_insured_openly"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.ssiu === true ||
                                                props.formIK.values.sage_sure === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.swyfft === true) && (
                                                <Form.Group key="currently_insured_other_carriers"
                                                            controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Currently insured - Other
                                                        Carriers</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="currently_insured"
                                                                       value={props.formIK.values.currently_insured_other_carriers !== undefined ? props.formIK.values.currently_insured_other_carriers : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['currently_insured_other_carriers'] = "Currently Insured Other Carriers is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['currently_insured_other_carriers'];
                                                                           }
                                                                           props.formIK.setFieldValue("currently_insured_other_carriers", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'},
                                                                           {
                                                                               label: 'Client doesn\'t yet own and not insured',
                                                                               value: 'Client doesn\'t yet own and not insured'
                                                                           },
                                                                           {
                                                                               label: 'Client owns and not insured',
                                                                               value: 'Client owns and not insured'
                                                                           }
                                                                       ]}/>
                                                    <div
                                                        className="text-error">{props.formIK.errors["currently_insured_other_carriers"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key="construction_type" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Construction
                                                Type</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="construction_type"
                                                               value={props.formIK.values.construction_type !== undefined ? props.formIK.values.construction_type : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['construction_type'] = "Construction type is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['construction_type'];
                                                                   }
                                                                   props.formIK.setFieldValue("construction_type", value)
                                                               }}
                                                               options={[{
                                                                   label: 'Frame Non-Masonry Veneer',
                                                                   value: 'Frame Non-Masonry Veneer'
                                                               },
                                                                   {
                                                                       label: 'Wood Frame, with Unknown Siding',
                                                                       value: 'Wood Frame, with Unknown Siding'
                                                                   },
                                                                   {label: 'Masonry', value: 'Masonry'},
                                                                   {label: 'Masonry/Veneer', value: 'Masonry/Veneer'},
                                                                   {label: 'Steel', value: 'Steel'}
                                                               ]}
                                                               className={prePopulated.includes('construction_type') ? 'prePopulated' : ''}/>
                                            <div className="text-error">{props.formIK.errors["construction_type"]}</div>
                                        </Form.Group>
                                        {
                                            (props.formIK.values.asi_progressive === true || props.formIK.values.ssiu === true ||
                                                props.formIK.values.openly === true || props.formIK.values.branch === true) && (
                                                <Form.Group key="exterior_walls" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Exterior
                                                        Walls</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="construction_type"
                                                                       value={props.formIK.values.exterior_walls !== undefined ? props.formIK.values.exterior_walls : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['exterior_walls'] = "Exterior Walls is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['exterior_walls'];
                                                                           }
                                                                           props.formIK.setFieldValue("exterior_walls", value)
                                                                       }}
                                                                       options={[{
                                                                           label: 'Vinyl Siding',
                                                                           value: 'Vinyl Siding'
                                                                       }, {label: 'Wood', value: 'Wood'},
                                                                           {
                                                                               label: 'Stucco',
                                                                               value: 'Stucco'
                                                                           }, {
                                                                               label: 'Hardboard Siding',
                                                                               value: 'Hardboard Siding'
                                                                           },
                                                                           {
                                                                               label: 'EIFS or Dryvit Siding',
                                                                               value: 'EIFS or Dryvit Siding'
                                                                           }, {
                                                                               label: 'Brick Veneer',
                                                                               value: 'Brick Veneer'
                                                                           },
                                                                           {
                                                                               label: 'Stone',
                                                                               value: 'Stone'
                                                                           }, {
                                                                               label: 'Asbestos Siding',
                                                                               value: 'Asbestos Siding'
                                                                           },
                                                                           {
                                                                               label: 'Wood Shake Siding',
                                                                               value: 'Wood Shake Siding'
                                                                           }, {label: 'Solid Brick', value: 'Solid Brick'},
                                                                           {
                                                                               label: 'Aluminum Siding',
                                                                               value: 'Aluminum Siding'
                                                                           },
                                                                           {
                                                                               label: 'Clapboard',
                                                                               value: 'Clapboard'
                                                                           }, {
                                                                               label: 'Cement Fiber Shingles',
                                                                               value: 'Cement Fiber Shingles'
                                                                           },
                                                                           {
                                                                               label: 'Masonite Siding',
                                                                               value: 'Masonite Siding'
                                                                           },
                                                                           {label: 'Logs, Solid', value: 'Logs, Solid'},
                                                                           {
                                                                               label: 'Hardi Plank Siding',
                                                                               value: 'Hardi Plank Siding'
                                                                           }
                                                                       ]}
                                                                       className={prePopulated.includes('exterior_walls') ? 'prePopulated' : ''}/>
                                                    <div
                                                        className="text-error">{props.formIK.errors["exterior_walls"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key="foundation_type" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Foundation
                                                Type</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="foundation_type"
                                                               value={props.formIK.values.foundation_type !== undefined ? props.formIK.values.foundation_type : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['foundation_type'] = "Foundation type is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['foundation_type'];
                                                                   }
                                                                   props.formIK.setFieldValue("foundation_type", value)
                                                               }}
                                                               options={[{
                                                                   label: 'Slab',
                                                                   value: 'Slab'
                                                               }, {label: 'Basement', value: 'Basement'},
                                                                   {label: 'Crawl Space', value: 'Crawl Space'},
                                                                   {label: 'Piers', value: 'Piers'}]}/>
                                            <div className="text-error">{props.formIK.errors["foundation_type"]}</div>
                                        </Form.Group>
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.openly === true) && (
                                                <Form.Group key="basement" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label><strong>Basement</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="basement"
                                                                       value={props.formIK.values.basement !== undefined ? props.formIK.values.basement : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['basement'] = "Basement is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['basement'];
                                                                           }
                                                                           props.formIK.setFieldValue("basement", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    <div className="text-error">{props.formIK.errors["basement"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key="occupancy_type" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Occupancy
                                                Type</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="occupancy_type"
                                                               value={props.formIK.values.occupancy_type !== undefined ? props.formIK.values.occupancy_type : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['occupancy_type'] = "Occupancy Type is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['occupancy_type'];
                                                                   }
                                                                   props.formIK.setFieldValue("occupancy_type", value)
                                                               }}
                                                               options={[{
                                                                   label: 'Primary',
                                                                   value: 'Primary'
                                                               }, {label: 'Secondary', value: 'Secondary'},
                                                                   {
                                                                       label: 'Seasonal',
                                                                       value: 'Seasonal'
                                                                   }, {
                                                                       label: 'Rental',
                                                                       value: 'Rental'
                                                                   }, {label: 'Vacant', value: 'Vacant'}
                                                               ]}
                                                               className={prePopulated.includes('occupancy_type') ? 'prePopulated' : ''}/>
                                            <div className="text-error">{props.formIK.errors["occupancy_type"]}</div>
                                        </Form.Group>
                                        <Form.Group key="roof_material" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Roof
                                                Material</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="roof_material"
                                                               value={props.formIK.values.roof_material !== undefined ? props.formIK.values.roof_material : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['roof_material'] = "Roof Material is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['roof_material'];
                                                                   }
                                                                   props.formIK.setFieldValue("roof_material", value)
                                                               }}
                                                               options={[{
                                                                   label: 'Shingles, Architectural',
                                                                   value: 'Shingles, Architectural'
                                                               }, {label: '3 Tab', value: '3 Tab'},
                                                                   {
                                                                       label: 'Clay Tile',
                                                                       value: 'Clay Tile'
                                                                   }, {
                                                                       label: 'Metal',
                                                                       value: 'Metal'
                                                                   }, {label: 'Wood Shingle', value: 'Wood Shingle'}]}/>
                                            <div className="text-error">{props.formIK.errors["roof_material"]}</div>
                                        </Form.Group>
                                        <Form.Group key="roof_shape" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Roof
                                                Shape</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="roof_shape"
                                                               value={props.formIK.values.roof_shape !== undefined ? props.formIK.values.roof_shape : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['roof_shape'] = "Roof Shape is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['roof_shape'];
                                                                   }
                                                                   props.formIK.setFieldValue("roof_shape", value)
                                                               }}
                                                               options={[{
                                                                   label: 'Gable',
                                                                   value: 'Gable'
                                                               }, {label: 'Hip', value: 'Hip'},
                                                                   {label: 'Flat', value: 'Flat'}]}
                                                               className={prePopulated.includes('roof_shape') ? 'prePopulated' : ''}/>
                                            <div className="text-error">{props.formIK.errors["roof_shape"]}</div>
                                        </Form.Group>
                                        {
                                            props.formIK.values.ssiu === true && (
                                                <>
                                                    <Form.Group key="roof_sheathing" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Roof
                                                            Sheathing</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="roof_sheathing"
                                                                           value={props.formIK.values.roof_sheathing !== undefined ? props.formIK.values.roof_sheathing : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['roof_sheathing'] = "Roof Sheathing is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['roof_sheathing'];
                                                                               }
                                                                               props.formIK.setFieldValue("roof_sheathing", value)
                                                                           }}
                                                                           options={[{label: 'Unknown', value: 'Unknown'},
                                                                               {
                                                                                   label: 'Dimensional Lumber / Tongue & Groove',
                                                                                   value: 'Dimensional Lumber / Tongue & Groove'
                                                                               },
                                                                               {
                                                                                   label: 'Batten Decking / Skipped Sheathing',
                                                                                   value: 'Batten Decking / Skipped Sheathing'
                                                                               },
                                                                               {
                                                                                   label: '6d Nails –Any Nail Schedule',
                                                                                   value: '6d Nails –Any Nail Schedule'
                                                                               },
                                                                               {
                                                                                   label: '8d Nails –Minimum Nail Schedule',
                                                                                   value: '8d Nails –Minimum Nail Schedule'
                                                                               },
                                                                               {
                                                                                   label: '8d Nails –High Wind Schedule',
                                                                                   value: '8d Nails –High Wind Schedule'
                                                                               },
                                                                               {
                                                                                   label: '10d Nails –High Wind Schedule',
                                                                                   value: '10d Nails –High Wind Schedule'
                                                                               }]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["roof_sheathing"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="roof_straps_type" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Roof straps
                                                            Type</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="roof_straps_type"
                                                                           value={props.formIK.values.roof_straps_type !== undefined ? props.formIK.values.roof_straps_type : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['roof_straps_type'] = "Roof straps Type is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['roof_straps_type'];
                                                                               }
                                                                               props.formIK.setFieldValue("roof_straps_type", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Unknown',
                                                                               value: 'Unknown'
                                                                           }, {
                                                                               label: 'Toe nailing / No anchorage',
                                                                               value: 'Toe nailing / No anchorage'
                                                                           },
                                                                               {
                                                                                   label: 'Clips',
                                                                                   value: 'Clips'
                                                                               }, {
                                                                                   label: 'Single wraps',
                                                                                   value: 'Single wraps'
                                                                               },
                                                                               {
                                                                                   label: 'Double wraps',
                                                                                   value: 'Double wraps'
                                                                               }, {
                                                                                   label: 'Structural',
                                                                                   value: 'Structural'
                                                                               }]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["roof_straps_type"]}</div>
                                                    </Form.Group>
                                                </>
                                            )
                                        }
                                        {
                                            (props.formIK.values.asi_progressive === true || props.formIK.values.sage_sure === true ||
                                                props.formIK.values.ssiu === true || props.formIK.values.openly === true ||
                                                props.formIK.values.branch === true) && (
                                                <>
                                                    <Form.Group key="number_of_stories" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Number of
                                                            Stories</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="number_of_stories"
                                                                           value={props.formIK.values.number_of_stories !== undefined ? props.formIK.values.number_of_stories : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['number_of_stories'] = "Number of Stories is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['number_of_stories'];
                                                                               }
                                                                               props.formIK.setFieldValue("number_of_stories", value)
                                                                           }}
                                                                           options={[{
                                                                               label: '1',
                                                                               value: '1'
                                                                           }, {label: '1.5', value: '1.5'},
                                                                               {label: '2', value: '2'}, {
                                                                                   label: '2.5',
                                                                                   value: '2.5'
                                                                               },
                                                                               {label: '3', value: '3'}, {
                                                                                   label: '4',
                                                                                   value: '4'
                                                                               }, {label: '5', value: '5'},
                                                                               {label: '5+', value: '5+'}]}
                                                                           className={prePopulated.includes('number_of_stories') ? 'prePopulated' : ''}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["number_of_stories"]}</div>
                                                    </Form.Group>
                                                </>
                                            )
                                        }
                                        {
                                            (props.formIK.values.asi_progressive === true || props.formIK.values.swyfft === true ||
                                                props.formIK.values.openly === true || props.formIK.values.branch === true) && (
                                                <Form.Group key="garage" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label
                                                        className="required-label"><strong>Garage</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="garage"
                                                                       value={props.formIK.values.garage !== undefined ? props.formIK.values.garage : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['garage'] = "Garage is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['garage'];
                                                                           }
                                                                           props.formIK.setFieldValue("garage", value)
                                                                       }}
                                                                       options={[{
                                                                           label: 'None',
                                                                           value: 'None'
                                                                       }, {
                                                                           label: '1-Car-Attached',
                                                                           value: '1-Car-Attached'
                                                                       },
                                                                           {
                                                                               label: '2-Car-Attached',
                                                                               value: '2-Car-Attached'
                                                                           }, {
                                                                               label: '3-Car-Attached',
                                                                               value: '3-Car-Attached'
                                                                           },
                                                                           {
                                                                               label: '1-Car-Built-in',
                                                                               value: '1-Car-Built-in'
                                                                           }, {
                                                                               label: '2-Car-Built-in',
                                                                               value: '2-Car-Built-in'
                                                                           },
                                                                           {
                                                                               label: '3-Car-Built-in',
                                                                               value: '3-Car-Built-in'
                                                                           }, {
                                                                               label: '1-Car-Carport',
                                                                               value: '1-Car-Carport'
                                                                           },
                                                                           {label: '2-Car-Carport', value: '2-Car-Carport'}
                                                                       ]}
                                                                       className={prePopulated.includes('garage') ? 'prePopulated' : ''}/>
                                                    <div className="text-error">{props.formIK.errors["garage"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.asi_progressive === true || props.formIK.values.ssiu === true ||
                                                props.formIK.values.openly === true) && (
                                                <Form.Group key="swimming_pool" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Swimming
                                                        Pool</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="swimming_pool"
                                                                       value={props.formIK.values.swimming_pool !== undefined ? props.formIK.values.swimming_pool : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['swimming_pool'] = "Swimming pool is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['swimming_pool'];
                                                                           }
                                                                           props.formIK.setFieldValue("swimming_pool", value)
                                                                       }}
                                                                       options={[{
                                                                           label: 'None',
                                                                           value: 'None'
                                                                       }, {
                                                                           label: 'Inground 300-600 sq ft',
                                                                           value: 'Inground 300-600 sq ft'
                                                                       },
                                                                           {
                                                                               label: 'Inground Large > 600 sq ft',
                                                                               value: 'Inground Large > 600 sq ft'
                                                                           },
                                                                           {label: 'Above Ground', value: 'Above Ground'}
                                                                       ]}/>
                                                    <div className="text-error">{props.formIK.errors["swimming_pool"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <>
                                                    <Form.Group key="bathroom_grade" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Bathroom
                                                            Grade</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="bathroom_grade"
                                                                           value={props.formIK.values.bathroom_grade !== undefined ? props.formIK.values.bathroom_grade : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['bathroom_grade'] = "Bathroom Grade is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['bathroom_grade'];
                                                                               }
                                                                               props.formIK.setFieldValue("bathroom_grade", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Builder\'s Grade',
                                                                               value: 'Builder\'s Grade'
                                                                           }, {label: 'Custom', value: 'Custom'},
                                                                               {
                                                                                   label: 'Designer',
                                                                                   value: 'Designer'
                                                                               }, {
                                                                                   label: 'Semi-Custom',
                                                                                   value: 'Semi-Custom'
                                                                               }]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["bathroom_grade"]}</div>
                                                    </Form.Group>
                                                </>
                                            )
                                        }
                                        {
                                            (props.formIK.values.asi_progressive === true || props.formIK.values.sage_sure === true ||
                                                props.formIK.values.openly === true || props.formIK.values.branch === true) && (
                                                <>
                                                    <Form.Group key="number_of_full_bath" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Number of Full
                                                            Baths</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="number_of_full_bath"
                                                                           value={props.formIK.values.number_of_full_bath !== undefined ? props.formIK.values.number_of_full_bath : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['number_of_full_bath'] = "Number of Full Baths is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['number_of_full_bath'];
                                                                               }
                                                                               props.formIK.setFieldValue("number_of_full_bath", value)
                                                                           }}
                                                                           options={[{label: '1', value: '1'}, {
                                                                               label: '2',
                                                                               value: '2'
                                                                           }, {label: '3', value: '3'},
                                                                               {label: '4', value: '4'}, {
                                                                                   label: '5',
                                                                                   value: '5'
                                                                               }, {label: '6+', value: '6+'}]}
                                                                           className={prePopulated.includes('number_of_full_bath') ? 'prePopulated' : ''}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["number_of_full_bath"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="number_of_half_bath" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label><strong>Number of 1/2 Baths</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="number_of_half_baths"
                                                                           value={props.formIK.values.number_of_half_bath !== undefined ? props.formIK.values.number_of_half_bath : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['number_of_half_bath'] = "Number of 1/2 Baths is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['number_of_half_bath'];
                                                                               }
                                                                               props.formIK.setFieldValue("number_of_half_bath", value)
                                                                           }}
                                                                           options={[{label: '0', value: '0'}, {
                                                                               label: '1',
                                                                               value: '1'
                                                                           }, {label: '2', value: '2'}, {
                                                                               label: '3',
                                                                               value: '3'
                                                                           },
                                                                               {label: '4', value: '4'}, {
                                                                                   label: '5',
                                                                                   value: '5'
                                                                               }, {label: '6+', value: '6+'}]}
                                                                           className={prePopulated.includes('number_of_half_baths') ? 'prePopulated' : ''}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["number_of_half_bath"]}</div>
                                                    </Form.Group>
                                                </>
                                            )
                                        }
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <>
                                                    <Form.Group key="kitchen_grade" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Kitchen
                                                            Grade</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="kitchen_grade"
                                                                           value={props.formIK.values.kitchen_grade !== undefined ? props.formIK.values.kitchen_grade : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['kitchen_grade'] = "Kitchen Grade is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['kitchen_grade'];
                                                                               }
                                                                               props.formIK.setFieldValue("kitchen_grade", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Builder\'s Grade',
                                                                               value: 'Builder\'s Grade'
                                                                           }, {label: 'Custom', value: 'Custom'},
                                                                               {
                                                                                   label: 'Designer',
                                                                                   value: 'Designer'
                                                                               }, {
                                                                                   label: 'Semi-Custom',
                                                                                   value: 'Semi-Custom'
                                                                               }]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["kitchen_grade"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="cooling_air" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Cooling/Air</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="cooling_air"
                                                                           value={props.formIK.values.cooling_air !== undefined ? props.formIK.values.cooling_air : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['cooling_air'] = "Cooling/Air is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['cooling_air'];
                                                                               }
                                                                               props.formIK.setFieldValue("cooling_air", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'None',
                                                                               value: 'None'
                                                                           }, {
                                                                               label: 'Central Air Conditioning',
                                                                               value: 'Central Air Conditioning'
                                                                           },
                                                                               {
                                                                                   label: 'Evaporative Cooler',
                                                                                   value: 'Evaporative Cooler'
                                                                               }, {
                                                                                   label: 'Whole House Fan',
                                                                                   value: 'Whole House Fan'
                                                                               },
                                                                               {
                                                                                   label: 'Heat Pump, HE (System)',
                                                                                   value: 'Heat Pump, HE (System)'
                                                                               }, {
                                                                                   label: 'Humidifier, Furnace',
                                                                                   value: 'Humidifier, Furnace'
                                                                               }]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["cooling_air"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="permanent_heat_source" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Permanent Heat
                                                            Source</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="permanent_heat_source"
                                                                           value={props.formIK.values.permanent_heat_source !== undefined ? props.formIK.values.permanent_heat_source : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['permanent_heat_source'] = "Permanent Heat Source is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['permanent_heat_source'];
                                                                               }
                                                                               props.formIK.setFieldValue("permanent_heat_source", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'None',
                                                                               value: 'None'
                                                                           }, {
                                                                               label: 'Radiant Floor Heating',
                                                                               value: 'Radiant Floor Heating'
                                                                           },
                                                                               {
                                                                                   label: 'Geothermal, Closed Loop',
                                                                                   value: 'Geothermal, Closed Loop'
                                                                               }, {
                                                                                   label: 'Geothermal, Open Loop',
                                                                                   value: 'Geothermal, Open Loop'
                                                                               },
                                                                               {
                                                                                   label: 'Electric',
                                                                                   value: 'Electric'
                                                                               }, {
                                                                                   label: 'Gas Forced Air',
                                                                                   value: 'Gas Forced Air'
                                                                               },
                                                                               {
                                                                                   label: 'Heat Pump',
                                                                                   value: 'Heat Pump'
                                                                               }, {
                                                                                   label: 'Oil Forced Air',
                                                                                   value: 'Oil Forced Air'
                                                                               },
                                                                               {
                                                                                   label: 'Propane Gas Forced Air',
                                                                                   value: 'Propane Gas Forced Air'
                                                                               }, {
                                                                                   label: 'Gas Hot Water',
                                                                                   value: 'Gas Hot Water'
                                                                               },
                                                                               {
                                                                                   label: 'Oil Hot Water',
                                                                                   value: 'Oil Hot Water'
                                                                               }, {
                                                                                   label: 'Propane Gas Hot Water',
                                                                                   value: 'Propane Gas Hot Water'
                                                                               }]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["permanent_heat_source"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="deck" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label
                                                            className="required-label"><strong>Deck</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="deck"
                                                                           value={props.formIK.values.deck !== undefined ? props.formIK.values.deck : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['deck'] = "Deck is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['deck'];
                                                                               }
                                                                               props.formIK.setFieldValue("deck", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'None',
                                                                               value: 'None'
                                                                           }, {label: 'Wood Deck', value: 'Wood Deck'},
                                                                               {
                                                                                   label: 'Redwood Deck',
                                                                                   value: 'Redwood Deck'
                                                                               }, {
                                                                                   label: 'Composite Deck',
                                                                                   value: 'Composite Deck'
                                                                               }]}/>
                                                        <div className="text-error">{props.formIK.errors["deck"]}</div>
                                                    </Form.Group>
                                                    {
                                                        (props.formIK.values.deck !== "" && props.formIK.values.deck !== "None") && (
                                                            <Form.Group key="deck_size" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className="required-label"><strong>Deck (sq.
                                                                    ft.)</strong></Form.Label>
                                                                <CustomTextInput disabled={false} required={true}
                                                                                 name="deck_size"
                                                                                 className={"form-control"} id="deck_size-input"
                                                                                 value={props.formIK.values.deck_size}
                                                                                 onChange={(value) => {
                                                                                     if (value === "") {
                                                                                         props.formIK.errors['deck_size'] = "Deck Size is required.";
                                                                                     } else {
                                                                                         delete props.formIK.errors['deck_size'];
                                                                                     }
                                                                                     props.formIK.setFieldValue("deck_size", value);
                                                                                 }}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["deck_size"]}</div>
                                                            </Form.Group>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <Form.Group key="own_dog" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Own
                                                        Dog</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="own_dog"
                                                                       value={props.formIK.values.own_dog !== undefined ? props.formIK.values.own_dog : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['own_dog'] = "Own dog is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['own_dog'];
                                                                           }
                                                                           props.formIK.setFieldValue("own_dog", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    <div className="text-error">{props.formIK.errors["own_dog"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        <Collapsible trigger={["Default Fields", <BsChevronDown/>]}
                                                     triggerStyle={{background: '#e3f2fd'}}>
                                            {
                                                props.formIK.values.asi_progressive === true &&
                                                <Form.Group key="prior_liability_limit" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Prior Liability
                                                        Limit</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="is_new_purchase"
                                                                       value={props.formIK.values.prior_liability_limit !== undefined ? props.formIK.values.prior_liability_limit : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['prior_liability_limit'] = "Prior liability limit is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['prior_liability_limit'];
                                                                           }
                                                                           props.formIK.setFieldValue("prior_liability_limit", value)
                                                                       }}
                                                                       options={[{
                                                                           label: 'Less than $300,000',
                                                                           value: 'Less than $300,000'
                                                                       },
                                                                           {label: '$300,000', value: '$300,000'},
                                                                           {
                                                                               label: 'Greater than $300,000',
                                                                               value: 'Greater than $300,000'
                                                                           },
                                                                           {
                                                                               label: 'First Time Home Buyer',
                                                                               value: 'First Time Home Buyer'
                                                                           },
                                                                           {
                                                                               label: 'Lapse in Coverage (30 days or less)',
                                                                               value: 'Lapse in Coverage (30 days or less)'
                                                                           },
                                                                           {
                                                                               label: 'No Prior or Lapse Greater than 30 Days',
                                                                               value: 'No Prior or Lapse Greater than 30 Days'
                                                                           }
                                                                       ]}/>
                                                    <div
                                                        className="text-error">{props.formIK.errors["prior_liability_limit"]}</div>
                                                </Form.Group>
                                            }
                                            {
                                                (props.formIK.values.asi_progressive === true || props.formIK.values.branch === true) && (
                                                    <>
                                                        <Form.Group key="number_of_residence" controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Number of
                                                                household residents</strong></Form.Label>
                                                            <CustomSelectInput disabled={false} name="number_of_residence"
                                                                               value={props.formIK.values.number_of_residence !== undefined ? props.formIK.values.number_of_residence : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['number_of_residence'] = "Number of household residents are required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['number_of_residence'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("number_of_residence", value)
                                                                               }}
                                                                               options={[{label: '0', value: '0'},
                                                                                   {label: '1', value: '1'}, {
                                                                                       label: '2',
                                                                                       value: '2'
                                                                                   },
                                                                                   {label: '3', value: '3'}, {
                                                                                       label: '4',
                                                                                       value: '4'
                                                                                   },
                                                                                   {label: '5', value: '5'}, {
                                                                                       label: '6',
                                                                                       value: '6'
                                                                                   },
                                                                                   {label: '7', value: '7'}, {
                                                                                       label: '8',
                                                                                       value: '8'
                                                                                   },
                                                                                   {label: '9 or more', value: '9 or more'}
                                                                               ]}
                                                                               className={prePopulated.includes('number_of_residence') ? 'prePopulated' : ''}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["number_of_residence"]}</div>
                                                        </Form.Group>
                                                        <Form.Group key="household_occupant_under_21"
                                                                    controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Number of
                                                                household occupants under the age of
                                                                21</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name="household_occupant_under_21"
                                                                               value={props.formIK.values.household_occupant_under_21 !== undefined ? props.formIK.values.household_occupant_under_21 : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['household_occupant_under_21'] = "Number of household occupants under the age of 21 is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['household_occupant_under_21'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("household_occupant_under_21", value)
                                                                               }}
                                                                               options={[{label: '0', value: '0'},
                                                                                   {label: '1', value: '1'}, {
                                                                                       label: '2',
                                                                                       value: '2'
                                                                                   }
                                                                               ]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["household_occupant_under_21"]}</div>
                                                        </Form.Group>
                                                    </>
                                                )
                                            }
                                            {
                                                props.formIK.values.asi_progressive === true && (
                                                    <>
                                                        <Form.Group key="woodburning_stove" controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label><strong>Woodburning Stove</strong></Form.Label>
                                                            <CustomSelectInput disabled={false} name="woodburning_stove"
                                                                               value={props.formIK.values.woodburning_stove !== undefined ? props.formIK.values.woodburning_stove : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['woodburning_stove'] = "Woodburning Stove is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['woodburning_stove'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("woodburning_stove", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["woodburning_stove"]}</div>
                                                        </Form.Group>
                                                    </>
                                                )
                                            }
                                            {
                                                props.formIK.values.openly === true && (
                                                    <>
                                                        <Form.Group key="solar_panel" controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Solar
                                                                Panels</strong></Form.Label>
                                                            <CustomSelectInput disabled={false} name="bathroom_grade"
                                                                               value={props.formIK.values.solar_panel !== undefined ? props.formIK.values.solar_panel : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['solar_panel'] = "Solar Panels is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['solar_panel'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("solar_panel", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["solar_panel"]}</div>
                                                        </Form.Group>
                                                    </>
                                                )
                                            }
                                            {
                                                (props.formIK.values.asi_progressive === true || props.formIK.values.swyfft === true) && (
                                                    <Form.Group key="ineligible_animal_present" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Ineligible Animal
                                                            Present</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="ineligible_animal_present"
                                                                           value={props.formIK.values.ineligible_animal_present !== undefined ? props.formIK.values.ineligible_animal_present : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['ineligible_animal_present'] = "Ineligible Animal Present is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['ineligible_animal_present'];
                                                                               }
                                                                               props.formIK.setFieldValue("ineligible_animal_present", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["ineligible_animal_present"]}</div>
                                                    </Form.Group>
                                                )
                                            }
                                            {
                                                props.formIK.values.openly === true && (
                                                    <Form.Group key="onsite_business" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Is there a business
                                                            with customers on site?</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="onsite_business"
                                                                           className={prePopulated.includes('onsite_business') ? 'prePopulated' : ''}
                                                                           value={props.formIK.values.onsite_business !== undefined ? props.formIK.values.onsite_business : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['onsite_business'] = "Is there a business with customers on site? is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['onsite_business'];
                                                                               }
                                                                               props.formIK.setFieldValue("onsite_business", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["onsite_business"]}</div>
                                                    </Form.Group>
                                                )
                                            }
                                            {
                                                props.formIK.values.ssiu === true && (
                                                    <Form.Group key="tree_over_structure" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Any tree hanging over
                                                            structure?</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="tree_over_structure"
                                                                           value={props.formIK.values.tree_over_structure !== undefined ? props.formIK.values.tree_over_structure : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['tree_over_structure'] = "Any tree hanging over structure? is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['tree_over_structure'];
                                                                               }
                                                                               props.formIK.setFieldValue("tree_over_structure", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["tree_over_structure"]}</div>
                                                    </Form.Group>
                                                )
                                            }
                                        </Collapsible>
                                    </Col>
                                    <Col className="property-cols">
                                        <h6>Discounts</h6>
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.ssiu === true) && (
                                                <Form.Group key="window_and_door_opening_protection"
                                                            controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Window & Door Opening
                                                        Protection</strong></Form.Label>
                                                    <CustomSelectInput disabled={false}
                                                                       name="window_and_door_opening_protection"
                                                                       value={props.formIK.values.window_and_door_opening_protection !== undefined ? props.formIK.values.window_and_door_opening_protection : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['window_and_door_opening_protection'] = "Window & Door Opening Protection is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['window_and_door_opening_protection'];
                                                                           }
                                                                           props.formIK.setFieldValue("window_and_door_opening_protection", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    <div
                                                        className="text-error">{props.formIK.errors["window_and_door_opening_protection"]}</div>
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.ssiu === true || props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) && (
                                                <>
                                                    <Form.Group key="burglar_alarm" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label><strong>Burglar Alarm</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="burglar_alarm"
                                                                           value={props.formIK.values.burglar_alarm !== undefined ? props.formIK.values.burglar_alarm : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['burglar_alarm'] = "Burglar Alarm is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['burglar_alarm'];
                                                                               }
                                                                               props.formIK.setFieldValue("burglar_alarm", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'None',
                                                                               value: 'None'
                                                                           }, {label: 'Local Alarm', value: 'Local Alarm'},
                                                                               {
                                                                                   label: 'Reporting Alarm',
                                                                                   value: 'Reporting Alarm'
                                                                               }]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["burglar_alarm"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="fire_alarm" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label><strong>Fire Alarm</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="fire_alarm"
                                                                           value={props.formIK.values.fire_alarm !== undefined ? props.formIK.values.fire_alarm : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['fire_alarm'] = "Fire Alarm is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['fire_alarm'];
                                                                               }
                                                                               props.formIK.setFieldValue("fire_alarm", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'None',
                                                                               value: 'None'
                                                                           }, {label: 'Local Alarm', value: 'Local Alarm'},
                                                                               {
                                                                                   label: 'Reporting Alarm',
                                                                                   value: 'Reporting Alarm'
                                                                               }]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["fire_alarm"]}</div>
                                                    </Form.Group>
                                                </>
                                            )
                                        }
                                        {
                                            props.formIK.values.asi_progressive === true && (
                                                <>
                                                    <Form.Group key="package_policy" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Package
                                                            Policy</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="package_policy"
                                                                           value={props.formIK.values.package_policy !== undefined ? props.formIK.values.package_policy : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['package_policy'] = "Does insured have an Umbrella policy with any company? is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['package_policy'];
                                                                               }
                                                                               props.formIK.setFieldValue("package_policy", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'None',
                                                                               value: 'None'
                                                                           }, {
                                                                               label: 'Progressive Auto 250/500',
                                                                               value: 'Progressive Auto 250/500'
                                                                           },
                                                                               {
                                                                                   label: 'Progressive Auto 100/300',
                                                                                   value: 'Progressive Auto 100/300'
                                                                               },
                                                                               {
                                                                                   label: 'Progressive Auto 50/100',
                                                                                   value: 'Progressive Auto 50/100'
                                                                               },
                                                                               {
                                                                                   label: 'Progressive Auto Less Than 50/100',
                                                                                   value: 'Progressive Auto Less Than 50/100'
                                                                               }, {label: 'Flood', value: 'Flood'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["package_policy"]}</div>
                                                    </Form.Group>
                                                    <Form.Group key="home_update" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Home Update</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="home_update"
                                                                           value={props.formIK.values.home_update !== undefined ? props.formIK.values.home_update : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['home_update'] = "Home Update is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['home_update'];
                                                                               }
                                                                               props.formIK.setFieldValue("home_update", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["home_update"]}</div>
                                                    </Form.Group>
                                                    {
                                                        props.formIK.values.home_update === 'Yes' && (
                                                            <Form.Group key="latest_permit_date" controlId="formBasicEmail"
                                                                        style={{
                                                                            padding: "0px 15px 0px 0px",
                                                                            minHeight: "100px"
                                                                        }}>
                                                                <Form.Label className='required-label'><strong>Latest Permit
                                                                    Date</strong></Form.Label>
                                                                <CustomDatePicker className="form-control"
                                                                                  name="latest_permit_date"
                                                                                  id="latest_permit_date-input"
                                                                                  value={props.formIK.values.latest_permit_date !== "" ?
                                                                                      props.formIK.values.latest_permit_date :
                                                                                      formatDateMDY(new Date())}
                                                                                  format="MM-dd-y"
                                                                                  onChange={(value) => {
                                                                                      if (value === "") {
                                                                                          props.formIK.errors['latest_permit_date'] = "Latest Permit Date is required.";
                                                                                      } else if (value >= new Date()) {
                                                                                          props.formIK.errors['latest_permit_date'] = "Latest Permit Date cannot be today or in future.";
                                                                                      } else {
                                                                                          delete props.formIK.errors['latest_permit_date'];
                                                                                      }
                                                                                      props.formIK.setFieldValue("latest_permit_date", formatDateMDY(value))
                                                                                  }} disabled={false}
                                                                                  required={true}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["latest_permit_date"]}</div>
                                                            </Form.Group>
                                                        )
                                                    }
                                                    {
                                                        props.formIK.values.ssiu === true && (
                                                            <>
                                                                <Form.Group key="flood_insured" controlId="formBasicEmail"
                                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                                    <Form.Label className="required-label"><strong>Does this
                                                                        risk have flood insurance through the NFIP or Private
                                                                        Flood Market?</strong></Form.Label>
                                                                    <CustomSelectInput disabled={false} name="flood_insured"
                                                                                       value={props.formIK.values.flood_insured !== undefined ? props.formIK.values.flood_insured : ""}
                                                                                       onChange={(value) => {
                                                                                           if (value === "") {
                                                                                               props.formIK.errors['flood_insured'] = "Does this risk have flood insurance through the NFIP or Private Flood Market? is required.";
                                                                                           } else {
                                                                                               delete props.formIK.errors['flood_insured'];
                                                                                           }
                                                                                           props.formIK.setFieldValue("flood_insured", value)
                                                                                       }}
                                                                                       options={[{
                                                                                           label: 'Yes',
                                                                                           value: 'Yes'
                                                                                       }, {label: 'No', value: 'No'}]}/>
                                                                    <div
                                                                        className="text-error">{props.formIK.errors["flood_insured"]}</div>
                                                                </Form.Group>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (props.formIK.values.orion === true || props.formIK.values.swyfft === true
                                                            || props.formIK.values.ssiu === true) && (
                                                            <Form.Group key="building_code_discount" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label><strong>Building Code Discount</strong></Form.Label>
                                                                <CustomSelectInput disabled={false}
                                                                                   name="building_code_discount"
                                                                                   value={props.formIK.values.building_code_discount !== undefined ? props.formIK.values.building_code_discount : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['building_code_discount'] = "Building Code Discount is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['building_code_discount'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("building_code_discount", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: 'None',
                                                                                       value: 'None'
                                                                                   }, {
                                                                                       label: 'Bronze - Renovation',
                                                                                       value: 'Bronze - Renovation'
                                                                                   },
                                                                                       {
                                                                                           label: 'Bronze - New Construction',
                                                                                           value: 'Bronze - New Construction'
                                                                                       },
                                                                                       {
                                                                                           label: 'Silver - Renovation',
                                                                                           value: 'Silver - Renovation'
                                                                                       },
                                                                                       {
                                                                                           label: 'Silver - New Construction',
                                                                                           value: 'Silver - New Construction'
                                                                                       },
                                                                                       {
                                                                                           label: 'Gold - Renovation',
                                                                                           value: 'Gold - Renovation'
                                                                                       },
                                                                                       {
                                                                                           label: 'Gold - New Construction',
                                                                                           value: 'Gold - New Construction'
                                                                                       },
                                                                                       {
                                                                                           label: 'Fortified for Safer Living Discount',
                                                                                           value: 'Fortified for Safer Living Discount'
                                                                                       },
                                                                                       {
                                                                                           label: '2006 IRC Building Code Discount - Year built 2007 and newer',
                                                                                           value: '2006 IRC Building Code Discount - Year built 2007 and newer'
                                                                                       }]}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["building_code_discount"]}</div>
                                                            </Form.Group>
                                                        )
                                                    }
                                                    {
                                                        (props.formIK.values.orion === true || props.formIK.values.sage_sure === true
                                                            || props.formIK.values.openly === true) && (
                                                            <Form.Group key="has_current_auto_policy_with_agency"
                                                                        controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className="required-label"><strong>Is current Auto
                                                                    Policy with Agency?</strong></Form.Label>
                                                                <CustomSelectInput disabled={false}
                                                                                   name="has_current_auto_policy_with_agency"
                                                                                   value={props.formIK.values.has_current_auto_policy_with_agency !== undefined ? props.formIK.values.has_current_auto_policy_with_agency : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['has_current_auto_policy_with_agency'] = "Is current Auto Policy with Agency? is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['has_current_auto_policy_with_agency'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("has_current_auto_policy_with_agency", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: 'Yes',
                                                                                       value: 'Yes'
                                                                                   }, {label: 'No', value: 'No'}]}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["has_current_auto_policy_with_agency"]}</div>
                                                            </Form.Group>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        <Collapsible trigger={["Default Fields", <BsChevronDown/>]}
                                                     triggerStyle={{background: '#e3f2fd'}}>
                                            {
                                                (props.formIK.values.orion === true) && (
                                                    <Form.Group key="tankless_water_heater" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Tankless Water
                                                            Heater</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="tankless_water_heater"
                                                                           value={props.formIK.values.tankless_water_heater !== undefined ? props.formIK.values.tankless_water_heater : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['tankless_water_heater'] = "Tankless Water Heater is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['tankless_water_heater'];
                                                                               }
                                                                               props.formIK.setFieldValue("tankless_water_heater", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["tankless_water_heater"]}</div>
                                                    </Form.Group>
                                                )
                                            }
                                            {
                                                (props.formIK.values.orion === true || props.formIK.values.ssiu === true) && (
                                                    <>
                                                        <Form.Group key="tree_free_yard" controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Tree Free
                                                                Yard</strong></Form.Label>
                                                            <CustomSelectInput disabled={false} name="tree_free_yard"
                                                                               value={props.formIK.values.tree_free_yard !== undefined ? props.formIK.values.tree_free_yard : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['tree_free_yard'] = "Tree Free Yard is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['tree_free_yard'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("tree_free_yard", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["tree_free_yard"]}</div>
                                                        </Form.Group>
                                                        <Form.Group key="roof_damage_exclusion" controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Roof Damage
                                                                Exclusion</strong></Form.Label>
                                                            <CustomSelectInput disabled={false} name="roof_damage_exclusion"
                                                                               value={props.formIK.values.roof_damage_exclusion !== undefined ? props.formIK.values.roof_damage_exclusion : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['roof_damage_exclusion'] = "Roof Damage Exclusion is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['roof_damage_exclusion'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("roof_damage_exclusion", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["roof_damage_exclusion"]}</div>
                                                        </Form.Group>
                                                    </>
                                                )
                                            }
                                            {
                                                (props.formIK.values.asi_progressive === true || props.formIK.values.openly === true ||
                                                    props.formIK.values.branch === true) && (
                                                    <Form.Group key="exclude_wind" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Exclude Wind</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="exclude_wind"
                                                                           className={prePopulated.includes('exclude_wind') ? 'prePopulated' : ''}
                                                                           value={props.formIK.values.exclude_wind !== undefined ? props.formIK.values.exclude_wind : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['exclude_wind'] = "Exclude Wind is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['exclude_wind'];
                                                                               }
                                                                               props.formIK.setFieldValue("exclude_wind", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["exclude_wind"]}</div>
                                                    </Form.Group>
                                                )
                                            }
                                            {
                                                props.formIK.values.asi_progressive === true && (
                                                    <>
                                                        <Form.Group key="loss_settlement_wind_hail_losses_to_roof"
                                                                    controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Loss
                                                                Settlement-Wind/Hail Losses to Roof</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name="loss_settlement_wind_hail_losses_to_roof"
                                                                               value={props.formIK.values.loss_settlement_wind_hail_losses_to_roof !== undefined ? props.formIK.values.loss_settlement_wind_hail_losses_to_roof : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['loss_settlement_wind_hail_losses_to_roof'] = "Loss Settlement-Wind/Hail Losses to Roof is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['loss_settlement_wind_hail_losses_to_roof'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("loss_settlement_wind_hail_losses_to_roof", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Replacement Cost',
                                                                                   value: 'Replacement Cost'
                                                                               },
                                                                                   {
                                                                                       label: 'Roofing Materials Payment Schedule',
                                                                                       value: 'Roofing Materials Payment Schedule'
                                                                                   }]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["loss_settlement_wind_hail_losses_to_roof"]}</div>
                                                        </Form.Group>
                                                        <Form.Group key="residents_smoke" controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Do any residents
                                                                smoke?</strong></Form.Label>
                                                            <CustomSelectInput disabled={false} name="residents_smoke"
                                                                               value={props.formIK.values.residents_smoke !== undefined ? props.formIK.values.residents_smoke : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['residents_smoke'] = "Do any residents smoke? is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['residents_smoke'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("residents_smoke", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["residents_smoke"]}</div>
                                                        </Form.Group>
                                                        <Form.Group key="insured_have_umbrella_policy"
                                                                    controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label><strong>Does insured have an Umbrella policy with
                                                                any company?</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name="insured_have_umbrella_policy"
                                                                               className={prePopulated.includes('insured_have_umbrella_policy') ? 'prePopulated' : ''}
                                                                               value={props.formIK.values.insured_have_umbrella_policy !== undefined ? props.formIK.values.insured_have_umbrella_policy : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['insured_have_umbrella_policy'] = "Does insured have an Umbrella policy with any company? is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['insured_have_umbrella_policy'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("insured_have_umbrella_policy", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["insured_have_umbrella_policy"]}</div>
                                                        </Form.Group>
                                                    </>
                                                )
                                            }
                                            {
                                                props.formIK.values.ssiu === true && (
                                                    <>
                                                        <Form.Group key="losses_bankruptcy_or_foreclosures"
                                                                    controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Any Losses,
                                                                Bankruptcy or Foreclosures</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name="losses_bankruptcy_or_foreclosures"
                                                                               value={props.formIK.values.losses_bankruptcy_or_foreclosures !== undefined ? props.formIK.values.losses_bankruptcy_or_foreclosures : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['losses_bankruptcy_or_foreclosures'] = "Any Losses, Bankruptcy or Foreclosures is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['losses_bankruptcy_or_foreclosures'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("losses_bankruptcy_or_foreclosures", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["losses_bankruptcy_or_foreclosures"]}</div>
                                                        </Form.Group>
                                                        <Form.Group key="undergoing_any_renovations"
                                                                    controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label className="required-label"><strong>Is risk
                                                                undergoing any renovations?</strong></Form.Label>
                                                            <CustomSelectInput disabled={false}
                                                                               name="undergoing_any_renovations"
                                                                               value={props.formIK.values.undergoing_any_renovations !== undefined ? props.formIK.values.undergoing_any_renovations : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['undergoing_any_renovations'] = "Is risk undergoing any renovations? is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['undergoing_any_renovations'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("undergoing_any_renovations", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            <div
                                                                className="text-error">{props.formIK.errors["undergoing_any_renovations"]}</div>
                                                        </Form.Group>
                                                    </>
                                                )
                                            }
                                            {
                                                (props.formIK.values.orion === true || props.formIK.values.swyfft === true
                                                    || props.formIK.values.ssiu === true || props.formIK.values.asi_progressive === true) && (
                                                    <Form.Group key="secured_community_discount" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label><strong>Secured Community Discount</strong></Form.Label>
                                                        <CustomSelectInput disabled={false}
                                                                           name="secured_community_discount"
                                                                           value={props.formIK.values.secured_community_discount !== undefined ? props.formIK.values.secured_community_discount : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['secured_community_discount'] = "Secured Community Discount is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['secured_community_discount'];
                                                                               }
                                                                               props.formIK.setFieldValue("secured_community_discount", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        <div
                                                            className="text-error">{props.formIK.errors["secured_community_discount"]}</div>
                                                    </Form.Group>
                                                )
                                            }
                                        </Collapsible>
                                    </Col>
                                    <Col>
                                        <>
                                            <h6>Claims</h6>
                                            <Collapsible trigger={["Default Fields", <BsChevronDown/>]}
                                                         triggerStyle={{background: '#e3f2fd'}}>
                                                {
                                                    (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true
                                                        || props.formIK.values.swyfft === true) && (
                                                        <>
                                                            <Form.Group key="fire_claims" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className="required-label"><strong>Fire
                                                                    Claims</strong></Form.Label>
                                                                <CustomSelectInput disabled={false} name="fire_claims"
                                                                                   value={props.formIK.values.fire_claims !== undefined ? props.formIK.values.fire_claims : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['fire_claims'] = "Fire Claims is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['fire_claims'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("fire_claims", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: '0 Claim',
                                                                                       value: '0 Claim'
                                                                                   }, {
                                                                                       label: '1 Claim',
                                                                                       value: '1 Claim'
                                                                                   },
                                                                                       {
                                                                                           label: '2 Claim',
                                                                                           value: '2 Claim'
                                                                                       }, {
                                                                                           label: '3 Claim',
                                                                                           value: '3 Claim'
                                                                                       }, {
                                                                                           label: '4 Claim',
                                                                                           value: '4 Claim'
                                                                                       },
                                                                                       {
                                                                                           label: '5 or More Claims',
                                                                                           value: '5 or More Claims'
                                                                                       }]}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["fire_claims"]}</div>

                                                            </Form.Group>
                                                            <Form.Group key="property_claims" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className="required-label"><strong>Property
                                                                    Claims</strong></Form.Label>
                                                                <CustomSelectInput disabled={false}
                                                                                   name="property_claims"
                                                                                   value={props.formIK.values.property_claims !== undefined ? props.formIK.values.property_claims : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['property_claims'] = "Property Claims is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['property_claims'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("property_claims", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: '0 Claim',
                                                                                       value: '0 Claim'
                                                                                   }, {
                                                                                       label: '1 Claim',
                                                                                       value: '1 Claim'
                                                                                   },
                                                                                       {
                                                                                           label: '2 Claim',
                                                                                           value: '2 Claim'
                                                                                       }, {
                                                                                           label: '3 Claim',
                                                                                           value: '3 Claim'
                                                                                       }, {
                                                                                           label: '4 Claim',
                                                                                           value: '4 Claim'
                                                                                       },
                                                                                       {
                                                                                           label: '5 or More Claims',
                                                                                           value: '5 or More Claims'
                                                                                       }]}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["property_claims"]}</div>
                                                            </Form.Group>
                                                            <Form.Group key="liability_claims"
                                                                        controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className="required-label"><strong>Liability
                                                                    Claims</strong></Form.Label>
                                                                <CustomSelectInput disabled={false}
                                                                                   name="liability_claims"
                                                                                   value={props.formIK.values.liability_claims !== undefined ? props.formIK.values.liability_claims : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['liability_claims'] = "Liability Claims is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['liability_claims'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("liability_claims", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: '0 Claim',
                                                                                       value: '0 Claim'
                                                                                   }, {
                                                                                       label: '1 Claim',
                                                                                       value: '1 Claim'
                                                                                   },
                                                                                       {
                                                                                           label: '2 Claim',
                                                                                           value: '2 Claim'
                                                                                       }, {
                                                                                           label: '3 Claim',
                                                                                           value: '3 Claim'
                                                                                       }, {
                                                                                           label: '4 Claim',
                                                                                           value: '4 Claim'
                                                                                       },
                                                                                       {
                                                                                           label: '5 or More Claims',
                                                                                           value: '5 or More Claims'
                                                                                       }]}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["liability_claims"]}</div>
                                                            </Form.Group>
                                                            <Form.Group key="weather_claims" controlId="formBasicEmail"
                                                                        style={{
                                                                            padding: "0px 15px 0px 0px",
                                                                            marginBottom: "50vh !important"
                                                                        }}>
                                                                <Form.Label className="required-label"><strong>Weather
                                                                    Claims</strong></Form.Label>
                                                                <CustomSelectInput disabled={false}
                                                                                   name="weather_claims"
                                                                                   value={props.formIK.values.weather_claims !== undefined ? props.formIK.values.weather_claims : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['weather_claims'] = "Weather Claims is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['weather_claims'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("weather_claims", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: '0 Claim',
                                                                                       value: '0 Claim'
                                                                                   }, {
                                                                                       label: '1 Claim',
                                                                                       value: '1 Claim'
                                                                                   },
                                                                                       {
                                                                                           label: '2 Claim',
                                                                                           value: '2 Claim'
                                                                                       }, {
                                                                                           label: '3 Claim',
                                                                                           value: '3 Claim'
                                                                                       }, {
                                                                                           label: '4 Claim',
                                                                                           value: '4 Claim'
                                                                                       },
                                                                                       {
                                                                                           label: '5 or More Claims',
                                                                                           value: '5 or More Claims'
                                                                                       }]}/>
                                                                <div
                                                                    className="text-error">{props.formIK.errors["weather_claims"]}</div>

                                                            </Form.Group>
                                                        </>

                                                    )
                                                }
                                            </Collapsible>
                                        </>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button onClick={() => props.changeStep(props.progress - props.step)}
                                        className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                {/*<button onClick={()=>props.changeStep(props.progress+34)} className="btn btn-primary">*/}
                                <button onClick={() => validate_data()} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

Property.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
