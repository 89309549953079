import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Row, Form, Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import CustomSelectInput from "../../../global_components/inputs/select-field";
import * as yup from "yup";
import {log} from "../../../utils/logger";
import {useSnackbar} from "notistack";
import Link from "@mui/material/Link";
import {useLocation, useNavigate} from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import {ConfirmationPopup} from "../../../utils/confirmation_popup";
import BaseAddEditUser from "../vermost_components/base_user_page";
import {useParams} from "react-router-dom";

export default function AddEditTeamMember(props) {
    const {enqueueSnackbar} = useSnackbar();
    const super_user = useSelector((state) => state.auth.user.user_role_name) === "SuperAdmin";
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const routeParams = useParams();


    const destroyLawFirm = async () => {
        await setOpenConfirmationPopup(false);
        props.closePopup(false);
    }
    const handleConfirmationPopup = async () => {
        await setOpenConfirmationPopup(false);
        await destroyLawFirm();
    }

    return (
        <>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {(props.editID !== undefined && props.editID !== null) ? "Edit User" : "Create New Law Firm User"}
                    </Modal.Title>
                    <Button variant={"warning"} onClick={(e) => {
                        e.preventDefault();
                        setOpenConfirmationPopup(true);
                    }}> <CloseIcon/></Button>
                </Modal.Header>
                <Modal.Body>

                    {
                        (props.editID !== undefined && props.editID !== null) ?
                            <BaseAddEditUser closeWindow={props.closePopup} routeFromComponent={props.editID}
                                             lawFirmFromComponent={props.lawFirmEditID}></BaseAddEditUser>

                            : <BaseAddEditUser closeWindow={props.closePopup} routeFromComponent="0" onClose={destroyLawFirm}
                                               lawFirmFromComponent={props.lawFirmEditID}></BaseAddEditUser>
                    }

                </Modal.Body>

            </Modal>

            <ConfirmationPopup show={openConfirmationPopup}
                               setOpenConfirmationPopup={setOpenConfirmationPopup}
                               title="Do you want to cancel all inputs?"
                               handleClose={handleConfirmationPopup}/>

        </>
    )
}


