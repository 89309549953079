import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {updateUserBackend, add_user_email, validateUserEmail} from "../../services/company_management";
import {updateUser, updatePOAPUserName} from "../../store/company_management";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {getAllLawFirmData} from "../../services/bond";
import {addLawFirm, addLawSchool, updateLawFirm} from "../../store/bond";
import { addUser } from "../../store/company_management";
import { updateVermostUser } from "../../store/company_management";
import Input from "@material-ui/core/Input";
import Select from "react-select";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomTextInput from "../../global_components/inputs/text";
import CustomEmailInput from "../../global_components/inputs/email";
import PhoneNumberUniversal from "../../global_components/inputs/phone_number_universal";
import CustomMultiSelectInput from "../../global_components/inputs/multi-select-field";
import CustomNumberInput from "../../global_components/inputs/number";
import {API_URL, month_opts} from "../../constants";


export default function AddVermostUserPopup(props) {
    const dispatch = useDispatch();
    const userInStore = useSelector((state) => state.companyManagement.users);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [error, setError] = useState(false);
    const [overlayLoading, setOverlayLoading] = useState(false);
    const [overlayMessage, setOverlayMessage] = useState('');
    const lawFirms = useSelector((state)=>state.bond.law_firms);
    const lawSchools = useSelector((state)=>state.bond.law_schools);


    let user_details = {};
    if(props.user_id!==0){
        user_details = userInStore.filter((user) => user.id === props.user_id)[0];
    }
    const departmentsInStore = useSelector((state) => state.companyManagement.departments);
    let selectedDepartment = {};
    if(props.user_id!==0){
        selectedDepartment = departmentsInStore.filter((department)=>department.name === user_details.department)[0];
    }
    const [loading, setLoading] = useState(false);
    const company = useSelector((state)=> state.auth.company.id)

    const getDepartmentOptions = () => {
        let depOpts = [];
        departmentsInStore.map((dep)=> depOpts.push({label: dep.name, value: dep.id}))
        return depOpts;
    }

    const getInitialValues = () => {
        if (props.user_id !== 0){
            return {email: user_details.email, first_name: user_details.first_name,
                last_name: user_details.last_name, username: user_details.last_name, 
                password: user_details.password, confirm_password: user_details.password,
                phone_number: user_details.phone_number, 
                law_firm: user_details.law_firm, law_firm_association: user_details.law_firm_association, 
                role: user_details.role, birth_month: user_details.birth_month, 
                birth_day: user_details.birth_day, bar: user_details.bar, law_school: user_details.law_school,
                is_portal_admin: user_details.is_portal_admin,
                can_create_standard_po: user_details.can_create_standard_po}
        }else{
            return {department_id: '', email: '', first_name: '', last_name: '', username: '', 
                law_firm: [], law_firm_association: 'no', 
                role: '', birth_month: '', birth_day: '', bar: '', law_school: '',
                is_portal_admin: false, can_create_standard_po: false
            }
        }
    }

    const getValidation = () => {
        if (props.user_id !== 0){
            return Yup.object({
                first_name: Yup.string().required('First name is required'),
                last_name: Yup.string().required('Last name is required'),
                email: Yup.string().email('Invalid email address').required('Email is required'),
                username: Yup.string().required('Username is required'),
                password: Yup.string().required('Password is required').matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
                confirm_password: Yup.string().required('Confirm password is required').when("password", {
                    is: val => (val && val.length > 0),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Confirm password must match Password."
                    )
                }),
                phone_number: Yup.string().required('Phone number is required.'),//.matches(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/, "Enter a valid phone number i.e. 15045951378")
                law_firm: Yup.array().of(Yup.number()).when('law_firm_association', (val)=> {
                    if(val === 'yes')
                        return Yup.array().of(Yup.number()).min(1, "Law firm is required.")
                }),
                law_firm_association: Yup.string().oneOf(['yes', 'no'], 'Please select your law firm association status.').required('Please select your law firm association status.'),
                role: Yup.string().when("law_firm_association", {
                    is: val => (val && val === 'yes'),
                    then: Yup.string().oneOf(['ATTORNEY', 'PARALEGAL', 'LEGAL ASSISTANT', 'CLERICAL'], 'Please select a valid role.').required('Role of contact is required.')
                }),
                birth_month: Yup.number().when("law_firm_association", {
                    is: val => (val && val === 'yes'),
                    then: Yup.number().oneOf([1,2,3,4,5,6,7,8,9,10,11,12], 'Invalid birth month. Please enter from 1 to 12').required('Birth month is required.')
                }),
                birth_day: Yup.number().when("law_firm_association", {
                    is: val => (val && val === "yes"),
                    then: Yup.number().oneOf([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31], 'Invalid birth day. Please enter from 1 to 31').required('Birth day is required.')
                }),
                bar: Yup.string().when("role", {
                    is: val => (val && val === 'ATTORNEY'),
                    then: Yup.string().required("BAR number is required.")
                }),
                law_school: Yup.number().when("role", {
                    is: val => (val && val === 'ATTORNEY'),
                    then: Yup.number().required("Law school is required.")
                }),
                is_portal_admin: Yup.boolean().oneOf([true, false], 'Portal admin status is required.'),
                can_create_standard_po: Yup.boolean().oneOf([true, false], 'Portal admin status is required.'),
            })
        }else{
            return Yup.object({
                first_name: Yup.string().required('First name is required'),
                last_name: Yup.string().required('Last name is required'),
                email: Yup.string().email('Invalid email address').required('Email is required'),
                username: Yup.string().required('Username is required'),
                phone_number: Yup.string().required('Phone number is required.'),//.matches(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/, "Enter a valid phone number i.e. 15045951378")
                law_firm: Yup.array().of(Yup.number()).when('law_firm_association', (val)=> {
                    if(val === 'yes')
                        return Yup.array().of(Yup.number()).min(1, "Law firm is required.")
                }),
                law_firm_association: Yup.string().oneOf(['yes', 'no'], 'Please select your law firm association status.').required('Please select your law firm association status.'),
                role: Yup.string().when("law_firm_association", {
                    is: val => (val && val === 'yes'),
                    then: Yup.string().oneOf(['ATTORNEY', 'PARALEGAL', 'LEGAL ASSISTANT', 'CLERICAL'], 'Please select a valid role.').required('Role of contact is required.')
                }),
                birth_month: Yup.number().when("law_firm_association", {
                    is: val => (val && val === 'yes'),
                    then: Yup.number().oneOf([1,2,3,4,5,6,7,8,9,10,11,12], 'Invalid birth month. Please enter from 1 to 12').required('Birth month is required.')
                }),
                birth_day: Yup.number().when("law_firm_association", {
                    is: val => (val && val === "yes"),
                    then: Yup.number().oneOf([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31], 'Invalid birth day. Please enter from 1 to 31').required('Birth day is required.')
                }),
                bar: Yup.string().when("role", {
                    is: val => (val && val === 'ATTORNEY'),
                    then: Yup.string().required("BAR number is required.")
                }),
                law_school: Yup.number().when("role", {
                    is: val => (val && val === 'ATTORNEY'),
                    then: Yup.number().required("Law school is required.")
                }),
                is_portal_admin: Yup.boolean().oneOf([true, false], 'Portal admin status is required.'),
                can_create_standard_po: Yup.boolean().oneOf([true, false], 'Portal admin status is required.'),
            })
        }
    }

    useEffect(async() => {
        if(lawFirms.length === 0){
            setLoading(true);
            fetch(`${API_URL}/bond/list_all_law_firms/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async (res)=> {
                const law_firm_data = await res.json();
                dispatch(addLawFirm(law_firm_data));
                setOverlayLoading(false);
            }).catch((err)=>{
                console.log(err);
                setOverlayLoading(false);
            });
            fetch(`${API_URL}/bond/list_all_law_schools/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async (res)=> {
                const law_school_data = await res.json();
                console.log(law_school_data)
                dispatch(addLawSchool(law_school_data));
                setOverlayLoading(false);
            }).catch((err)=>{
                console.log(err);
                setOverlayLoading(false);
            });
        }
    },[])

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            const changed_dep = departmentsInStore.filter((dep)=>dep.id===values.department_id)[0];
            console.log("VALUES: ", values);
            setLoading(true);
            if (props.user_id!==0){
                await updateUserBackend(props.user_id, values).then((res)=>{
                    if(res === 200){
                        props.showSuccessAlert(true);
                        props.setSuccessMessage('User updated successfully');
                        dispatch(updateVermostUser({id: props.user_id, email: values.email,  first_name: values.first_name,
                            last_name: values.last_name, phone_number: values.phone_number}));
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(0);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(0);
                })
            }else{
                let data = {}
                data.first_name = values.first_name;
                data.last_name = values.last_name;
                data.email = values.email;
                data.username = values.username;
                data.password = values.password;
                data.law_firm = values.law_firm;
                data.law_firm_association = values.law_firm_association;
                data.phone_number = values.phone_number;
                data.contact_info = {}
                data.contact_info.role = values.role;
                data.contact_info.birth_month = values.birth_month;
                data.contact_info.birth_day = values.birth_day;
                data.contact_info.bar = values.bar;
                data.contact_info.law_school = values.law_school;
                data.contact_info.phone_number = values.phone_number;
                console.log(data)
    
                const token = localStorage.getItem('userdata');                
                fetch(`${API_URL}/bond/add_vermost_user/`, {
                    method: "POST",
                    body: JSON.stringify({
                        ...data
                    }),
                    headers: {
                        'Authorization': 'Bearer '+token,
                        "Content-Type": "application/json",
                    },
                }).then(async (res)=> {
                    const response = await res.json()
                    if(res.status === 201){
                        props.showSuccessAlert(true);
                        props.setSuccessMessage('User Added successfully');
                        data.id = response.data
                        dispatch(addUser({...data}))
                    }else{
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    } 
                    setLoading(false);
                    props.closePopup(false);
                }).catch((error)=> {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(false);
                })
        
            }
        },
        validationSchema:getValidation()
    });
    const [isPortalAdminCheckbox, setIsPortalAdminCheckbox] = useState(props.user_id !== 0 ? user_details.is_portal_admin : false);
    const [canCreateStandardPO, setCanCreateStandardPO] = useState(props.user_id !== 0 ? user_details.can_create_standard_po : false);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key="first_name" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className="required-label"><strong>First Name</strong></Form.Label>
                            <CustomTextInput disabled={false} required={true} className="form-control"
                                                name="first_name" id="first_name-input" value={formIk.values.first_name}
                                                onChange={(value)=>formIk.setFieldValue("first_name", value)}
                                                onBlur={formIk.handleBlur}/>
                            {formIk.errors["first_name"] && formIk.touched["first_name"] && (
                                <div className="text-error">{formIk.errors["first_name"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="last_name" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className="required-label"><strong>Last Name</strong></Form.Label>
                            <CustomTextInput disabled={false} required={true} className="form-control"
                                                name="last_name" id="last_name-input" value={formIk.values.last_name}
                                                onChange={(value)=>formIk.setFieldValue("last_name", value)}
                                                onBlur={formIk.handleBlur}/>
                            {formIk.errors["last_name"] && formIk.touched["last_name"] && (
                                <div className="text-error">{formIk.errors["last_name"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key={error} className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className="required-label"><strong>Email</strong></Form.Label>
                            <CustomEmailInput disabled={false} required={true} className={"form-control"}
                                                name="email" id="email-input" value={formIk.values.email}
                                                onChange={(value)=>{formIk.setFieldValue("email", value)}}
                                                onBlur={formIk.handleBlur}/>
                            {formIk.errors["email"] && formIk.touched["email"] && (
                                <div className="text-error">{formIk.errors["email"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="username" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className="required-label"><strong>Username</strong></Form.Label>
                            <CustomTextInput disabled={false} required={true} className="form-control"
                                                name="username" id="username-input" value={formIk.values.username}
                                                onChange={(value)=>formIk.setFieldValue("username", value)}
                                                onBlur={formIk.handleBlur}/>
                            {formIk.errors["username"] && formIk.touched["username"] && (
                                <div className="text-error">{formIk.errors["username"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                { props.user_id === 0 && 
                    <Row>
                        <Col>
                            <Form.Group key="password" className="col-12" controlId="formBasicEmail"
                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                <Form.Label className="required-label"><strong>Password</strong></Form.Label>
                                <Input style={{padding: "1%", color: "black"}}
                                        name="password" id="password" placeholder="••••••••"
                                        className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500
                                        focus:border-blue-500 block w-full p-2.5 dark:border-gray-500
                                        dark:placeholder-gray-400 dark:text-white"
                                        type={showNewPassword ? "text" : "password"}
                                        onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                        defaultValue={formIk.values.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={()=>setShowNewPassword(!showNewPassword)}
                                                    onMouseDown={(e)=>e.preventDefault()}
                                                >
                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                />
                                {formIk.errors["password"] && formIk.touched["password"] && (
                                    <div className="text-error">{formIk.errors["password"]}</div>
                                )}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group key="confirm_password" className="col-12" controlId="formBasicEmail"
                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                <Form.Label className="required-label"><strong>Confirm Password</strong></Form.Label>
                                <Input style={{padding: "1%", color: "black"}}
                                        name="confirm_password" id="confirm_password" placeholder="••••••••"
                                        className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500
                                        focus:border-blue-500 block w-full p-2.5 dark:border-gray-500
                                        dark:placeholder-gray-400 dark:text-white"
                                        type={showConfirmPassword ? "text" : "password"}
                                        onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                        defaultValue={formIk.values.confirm_password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseDown={(e)=>e.preventDefault()}
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                />
                                {formIk.errors["confirm_password"] && formIk.touched["confirm_password"] && (
                                    <div className="text-error">{formIk.errors["confirm_password"]}</div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <Form.Group key="phone_number" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className="required-label"><strong>Phone Number</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange} name="phone_number"
                                          defaultValue={formIk.values.phone_number}/>
                            {/*<PhoneNumberUniversal disabled={false} required={true} className="form-control"*/}
                            {/*                        name="phone_number" id="phone_number-input" value={formIk.values.phone_number}*/}
                            {/*                        onChange={(val) => {*/}
                            {/*                            if(val === "+_ (___) ___ ____"){*/}
                            {/*                                val = "";*/}
                            {/*                            }*/}
                            {/*                            formIk.setFieldValue('phone_number', val.replaceAll(" ", "").replaceAll("", "").replaceAll("(", "").replaceAll(")", ""));*/}
                            {/*                        }}/>*/}
                            {formIk.errors["phone_number"] && formIk.touched["phone_number"] && (
                                <div className="text-error">{formIk.errors["phone_number"]}</div>
                            )}
                        </Form.Group>
                    </Col>

                    <Form.Control type="hidden" name='law_firm_association' defaultValue="no"/>

                    {/* <Col>
                        <Form.Group key={formIk.errors["law_firm_association"]} className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className="required-label"><strong>Are you associated with a Law firm?</strong></Form.Label>
                            <CustomSelectInput disabled={false} name="law_firm_association"
                                                value={formIk.values['law_firm_association']}
                                                onChange={(value)=>{
                                                    formIk.setFieldValue("law_firm_association", value);
                                                }} options={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}]}/>
                            {formIk.errors["law_firm_association"] && (
                                <div className="text-error">{formIk.errors["law_firm_association"]}</div>
                            )}
                        </Form.Group>
                    </Col> */}
                </Row>
                {
                    formIk.values.law_firm_association === 'yes' && (
                        <Row>
                            <Col>
                                <Form.Group key={formIk.errors["law_firm"]} className="col-12"
                                            controlId="formBasicEmail"
                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                    <Form.Label className="required-label"><strong>Law
                                        Firm</strong></Form.Label>
                                        <CustomMultiSelectInput disabled={false} name="law_firm"
                                                                value={formIk.values.law_firm}
                                                                onChange={(value) => formIk.setFieldValue('law_firm', value)}
                                                                options={lawFirms}/>
                                    {formIk.errors["law_firm"] && (
                                        <div className="text-error">{formIk.errors["law_firm"]}</div>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                }
                {
                    formIk.values.law_firm_association === 'yes' && (
                        <>
                            <Row>
                                <Col>
                                    <Form.Group key={"role"} className="col-12"
                                                controlId="formBasicEmail"
                                                style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label className="required-label"><strong>Role</strong></Form.Label>
                                        <CustomSelectInput disabled={false} name={"role"}
                                                            value={formIk.values.role}
                                                            onChange={(value)=>{formIk.setFieldValue("role", value);}}
                                                            options={[{value: 'ATTORNEY', label: 'Attorney'}, {value: 'PARALEGAL', label: 'Paralegal'},
                                                                {value: 'LEGAL ASSISTANT', label: 'Legal Assistant'}, {value: 'CLERICAL', label: 'Clerical'}]}/>
                                        {formIk.errors["role"] && (
                                            <div className="text-error">{formIk.errors["role"]}</div>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group key={"birth_day"} className="col-12"
                                                controlId="formBasicEmail"
                                                style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label className="required-label"><strong>Birth Day</strong></Form.Label>
                                        <CustomNumberInput disabled={false} required={true} className="form-control"
                                                            name={"birth_day"}
                                                            id={"birth_day-input"} value={formIk.values.birth_day}
                                                            onChange={(value)=>formIk.setFieldValue("birth_day", value)}
                                                            onBlur={formIk.handleBlur}/>
                                        {formIk.errors["birth_day"] && formIk.touched["birth_day"] && (
                                            <div className="text-error">{formIk.errors["birth_day"]}</div>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group key={"birth_month"} className="col-12"
                                                controlId="formBasicEmail"
                                                style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label className="required-label"><strong>Birth Month</strong></Form.Label>
                                        <CustomSelectInput disabled={false} name={"birth_month"} value={formIk.values.birth_month}
                                                            onChange={(val)=>formIk.setFieldValue("birth_month", val)}
                                                            options={month_opts}/>
                                        {formIk.errors["birth_month"] && formIk.touched["birth_month"] && (
                                            <div className="text-error">{formIk.errors["birth_month"]}</div>
                                        )}
                                    </Form.Group>
                                </Col>
                                {
                                    formIk.values.role === "ATTORNEY" ? (
                                        <Col>
                                            <Form.Group key={"bar"} className="col-12"
                                                        controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label className="required-label"><strong>BAR Association Number</strong></Form.Label>
                                                <CustomTextInput disabled={false} required={true} className="form-control"
                                                                    name={"bar"}
                                                                    id={"bar-input"} value={formIk.values.bar}
                                                                    onChange={(value)=>formIk.setFieldValue("bar", value)}
                                                                    onBlur={formIk.handleBlur}/>
                                                {formIk.errors["bar"] && formIk.touched["bar"] && (
                                                    <div className="text-error">{formIk.errors["bar"]}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    ) : (
                                        <Col></Col>
                                    )
                                }
                            </Row>
                            {
                                formIk.values.role === "ATTORNEY" && (
                                    <Row>
                                        <Col>
                                            <Form.Group key={"law_school"} className="col-12"
                                                        controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label className="required-label"><strong>Law School</strong></Form.Label>
                                                <CustomSelectInput disabled={false} name={"law_school"}
                                                                    value={formIk.values.law_school}
                                                                    onChange={(value)=>{formIk.setFieldValue("law_school", value);}}
                                                                    options={lawSchools}/>
                                                {formIk.errors["law_school"] && (
                                                    <div className="text-error">{formIk.errors["law_school"]}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Row>
                                <Col style={{paddingTop: "5%"}}>
                                    <Form.Group key={'is_portal_admin'} controlId="formFileSm" className="mb-3">
                                        <Form.Label className={'required-label'}><strong>Is Portal Admin</strong></Form.Label>
                                        <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                            onChange={() => {
                                                {
                                                    formIk.setFieldValue('is_portal_admin', !isPortalAdminCheckbox);
                                                    setIsPortalAdminCheckbox(!isPortalAdminCheckbox);
                                                }
                                            }}
                                            name='is_portal_admin' checked={isPortalAdminCheckbox}/>
                                        {formIk.errors['is_portal_admin'] && formIk.touched['is_portal_admin'] && (
                                            <div className="text-error">{formIk.errors['is_portal_admin']}</div>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group key={'can_create_standard_po'} controlId="formFileSm" className="mb-3">
                                        <Form.Label className={'required-label'}><strong>Can Create Standard PO</strong></Form.Label>
                                        <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                            onChange={() => {
                                                {
                                                    formIk.setFieldValue('can_create_standard_po', !canCreateStandardPO);
                                                    setCanCreateStandardPO(!canCreateStandardPO);
                                                }
                                            }}
                                            name='can_create_standard_po' checked={canCreateStandardPO}/>
                                        {formIk.errors['can_create_standard_po'] && formIk.touched['can_create_standard_po'] && (
                                            <div className="text-error">{formIk.errors['can_create_standard_po']}</div>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => formIk.submitForm()}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

AddVermostUserPopup.propTypes = {
    user_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    showSuccessAlert: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    showFailureAlert: PropTypes.func.isRequired,
    setFailureMessage: PropTypes.func.isRequired,
}
