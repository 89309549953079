import React, { useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form} from "react-bootstrap";
import '../../styles/login.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import CustomTextInput from "../../global_components/inputs/text";
import { useForm, Controller } from "react-hook-form";
import {API_URL} from "../../constants";

export default function ForgotPasswordPopup(props) {
    const [loading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailMessage, setShowFailMessage] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const initialVals = {email: ''};
    const schema = Yup.object({
        email: Yup.string().email('Should be a valid email.').required('Email is required.'),
    }).required();

    const { control, handleSubmit, setValue, setError, formState: { errors }, clearErrors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialVals
    });
    const onSubmit = data => {
        setLoading(true);
        console.log(data);
        fetch(`${API_URL}/user/users/user_forgot_password/`, {
            method: "POST",
            body: JSON.stringify({email: data.email}),
            headers: {"Content-Type": "application/json",},
        }).then(async (res)=>{
            if(res.status === 200){
                setShowSuccessMessage(true);
                setTimeout(()=>setShowSuccessMessage(false), 3000);
            }else{
                const d = await res.json();
                setFailMessage(d['email']);
                setShowFailMessage(true);
                setTimeout(()=>setShowFailMessage(false), 3000);
            }
            setLoading(false);
        }).catch((err)=> {
            console.log(err);
            setFailMessage('Something went wrong while resetting password.');
            setShowFailMessage(true);
            setTimeout(()=>setShowFailMessage(false), 3000);
            setLoading(false);
        })
    };
    console.log(errors)
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Enter your email.
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                        <Form.Label className='required-label'><strong>Email</strong></Form.Label>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => <CustomTextInput
                                disabled={false} required={true} name="email"
                                className={"form-control"} id="email-input"
                                value={initialVals.email}
                                onChange={(value)=>{
                                    clearErrors('email');
                                    setValue("email", value);
                                }}/>
                            }
                        />
                        {errors.email?.type === 'required' && <p role="alert">Email is required.</p>}
                        {errors.email?.type === 'email' && <p role="alert">Please provide a valid email.</p>}
                        {showFailMessage && <p role="alert">{failMessage}</p>}
                        {
                            showSuccessMessage && <p>Password reset successfully and sent to your email address.</p>
                        }
                </Modal.Body>
                <Modal.Footer>
                    {
                        !loading && (
                            <Button variant="success" type="submit">Submit</Button>
                        )
                    }
                    {
                        loading && (
                            <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </Button>
                        )
                    }
                    {
                        !loading && (
                            <Button onClick={(e) => {
                                e.preventDefault();
                                props.closePopup(false);
                            }}>Cancel</Button>
                        )
                    }
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ForgotPasswordPopup.propTypes = {
    closePopup: PropTypes.func.isRequired,
}
