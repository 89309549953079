import {useNavigate} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from "@mui/material/Button";

function GoBack(props) {
    const navigate = useNavigate();
    return (
        <Button onClick={() => navigate(props.pageUrl)}><ArrowBackIcon/>go back</Button>
    )
}

export default GoBack;