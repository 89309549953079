import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import '../../../styles/login.css';
import {
    add_user_email,
    deleteLawFirmBackend,
    deleteVermostLawFirmBackend,
    getAllLawSchools,
    getUserRoles, validateUserEmail
} from "../../../services/company_management";
import USPhoneNumberWithoutCountryCodeUniversal from "../../../global_components/inputs/us_phone_number_without_country_code";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Tooltip
} from "@mui/material";
import * as yup from "yup";
import {useFormik} from "formik";
import {log} from "../../../utils/logger";
import CustomSelectInput from "../../../global_components/inputs/select-field";
import {useDispatch, useSelector} from "react-redux";
import SearchLawFirm from "./search_law_firm";
import * as Yup from "yup";
import {useLocation, useNavigate} from "react-router";
import {removeLawFirm} from "../../../store/company_management";
import {useSnackbar} from "notistack";
import {ConfirmationPopup} from "../../../utils/confirmation_popup";
import {API_URL} from "../../../constants";
import {addLawSchool} from "../../../store/bond";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {useSearchParams} from "react-router-dom";


const AddEditUser = (props) => {
        const dispatch = useDispatch();
        const [searchParams, setSearchParams] = useSearchParams();
        const [userRoleList, setUserRoleList] = useState([]);
        const [userRoleListLoading, setUserRoleListLoading] = useState(false);
        const [loading, setLoading] = React.useState(true);
        const [isActive, setIsActive] = React.useState(true);
        const [isDefault, setIsDefault] = useState(false);  
        const [isParalegalService, setIsParalegalService] = useState(false); 
        const [paralegalValue, setParalegalValue] = useState(null);  
        const [attorneyControl, setAttorneyControl] = React.useState(false);
        const [paralegalControl, setParalegalControl] = React.useState(false);
        const [lawSchools, setLawSchools] = React.useState([]);
        const [validPhone, setValidPhone] = useState(false);
        const [validPosition, setValidPosition] = useState(false);
        const [validPermissionLevel, setValidPermissionLevel] = useState(false);
        const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
        const navigate = useNavigate();
        const location = useLocation();
        const {enqueueSnackbar} = useSnackbar();
        const [showNewPassword, setShowNewPassword] = useState(false);
        const [showConfirmPassword, setShowConfirmPassword] = useState(false);
        const [openUserActiveConfirmationPopup, setOpenUserActiveConfirmationPopup] = useState(false);
        const [openUserActiveValue, setOpenUserActiveValue] = useState(false);
        const [showSubmitButton, setShowSubmitButton] = useState(false);
        const lawFirmList = useSelector((state) => state.companyManagement.law_firms);
        console.log(lawFirmList)
        console.log(props.lawFirmFromComponent)
        const [selectedLawFirm, setSelectedLawFirm] = useState({});
        const super_user = useSelector((state) => state.auth.user.user_role_name) === "SuperAdmin";
        const admin_user = useSelector((state) => state.auth.user.user_role_name) === "Admin";
        const userPosition = useSelector((state) => state.auth.user.position);
        let isInternalFlow = localStorage.getItem("isInternalFlow") === "true";

        console.log(isInternalFlow);

        const userPositionListFunction = () => {
            let positionList = [];
            if(!isInternalFlow){
                positionList.push({label: 'Attorney', value: 'ATTORNEY'});
                positionList.push({label: 'Paralegal', value: 'PARALEGAL'});
                positionList.push({label: 'Legal Assistant', value: 'LEGAL ASSISTANT'});
                positionList.push({label: 'Clerical', value: 'CLERICAL'});
            }

            if (userPosition === 'INTERNAL' || userPosition === 'SYSTEM ADMIN' || super_user) {
                if(isInternalFlow){
                positionList.push({label: 'Internal', value: 'INTERNAL'});
                positionList.push({label: 'System Admin', value: 'SYSTEM ADMIN'});
            }
            }
            return positionList;
        }
        const userPositionList = userPositionListFunction();
        
        const reasonList = [
            {label: 'No longer practicing', value: 'No longer practicing'},
            {label: ' Law firm change', value: ' Law firm change'}
        ]
        const email_id = searchParams.get('eID') || '';


        const schema = yup.object().shape(
            props.type === 'register' ?
                attorneyControl ? {
                        first_name: yup.string().required(`First Name is required`),
                        last_name: yup.string().required(`Last Name is required`),
                        email: yup.string().matches(/\S+@\S+\.\S+/, 'Please enter a valid email.').required('Email is required.'),
                        position: Yup.string().oneOf(['ATTORNEY', 'INTERNAL', 'SYSTEM ADMIN', 'PARALEGAL', 'LEGAL ASSISTANT', 'CLERICAL'], 'Position is required').required('Position is required'),
                        bar: yup.string().required(`Bar is required`),
                        password: Yup.string().required('Password is required').matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                        ),
                        confirm_password: Yup.string().required('Confirm password is required').when("password", {
                            is: val => (val && val.length > 0),
                            then: Yup.string().oneOf(
                                [Yup.ref("password")],
                                "Confirm password must match Password."
                            )
                        }),
                    }
                    :
                    {
                        first_name: yup.string().required(`First Name is required`),
                        last_name: yup.string().required(`Last Name is required`),
                        email: yup.string().matches(/\S+@\S+\.\S+/, 'Please enter a valid email.').required('Email is required.'),
                        position: Yup.string().oneOf(['ATTORNEY', 'INTERNAL', 'SYSTEM ADMIN', 'PARALEGAL', 'LEGAL ASSISTANT', 'CLERICAL'], 'Position is required').required('Position is required'),
                        password: Yup.string().required('Password is required').matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                        ),
                        confirm_password: Yup.string().required('Confirm password is required').when("password", {
                            is: val => (val && val.length > 0),
                            then: Yup.string().oneOf(
                                [Yup.ref("password")],
                                "Confirm password must match Password."
                            )
                        }),
                    } :
                (attorneyControl ? {
                        first_name: yup.string().required(`First Name is required`),
                        last_name: yup.string().required(`Last Name is required`),
                        email: yup.string().matches(/\S+@\S+\.\S+/, 'Please enter a valid email.').required('Email is required.'),
                        user_role_id: Yup.string().oneOf(['1', '2', '3'], 'Permission is required').required('Permission is required'),
                        position: Yup.string().oneOf(['ATTORNEY', 'INTERNAL', 'SYSTEM ADMIN', 'PARALEGAL', 'LEGAL ASSISTANT', 'CLERICAL'], 'Position is required').required('Position is required'),
                        bar: yup.string().required(`Bar is required`),
                    }
                    :
                    {
                        first_name: yup.string().required(`First Name is required`),
                        last_name: yup.string().required(`Last Name is required`),
                        email: yup.string().matches(/\S+@\S+\.\S+/, 'Please enter a valid email.').required('Email is required.'),
                        user_role_id: Yup.string().oneOf(['1', '2', '3'], 'Permission is required').required('Permission is required'),
                        position: Yup.string().oneOf(['ATTORNEY', 'INTERNAL', 'SYSTEM ADMIN', 'PARALEGAL', 'LEGAL ASSISTANT', 'CLERICAL'], 'Position is required').required('Position is required'),
                    })
        );

        useEffect(async () => {
            setLoading(true);
            if (props.id !== "0") {
            const url = `/bond/get_paralegal_value/${props.id}`;
            try {
                const token = localStorage.getItem('userdata');
                const response = await fetch(`${API_URL}${url}`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    const {isLegalRepresentativeValue} = result;
                    setParalegalValue(isLegalRepresentativeValue)
                } 
            } catch (error) {
                console.error('Failed to fetch:', error);
                enqueueSnackbar('An error occurred', { variant: 'error' });
            }
                setValidPosition(true);
                setValidPermissionLevel(true);

                if (props.data?.position_value === 'Attorney') {
                    setAttorneyControl(true);
                    await loadLawSchools();
                }
                if (props.data?.position_value === 'Paralegal') {
                    setParalegalControl(true);
                }
                setIsActive(props.data.is_active);
            }

            if (props.type !== 'register' && userRoleList !== null && userRoleList !== undefined && userRoleList.length === 0 && !userRoleListLoading) {
                setUserRoleListLoading(true);
                let userRoleOpts = [];
                getUserRoles().then((response) => {
                    response.map((role) => userRoleOpts.push({label: role.role_name, value: role.id}));
                    setUserRoleListLoading(false);
                }).catch((error) => {
                    setUserRoleListLoading(false);
                    console.log(error);
                });
                setUserRoleList(userRoleOpts);
            }
            console.log('user roles', userRoleList);
            if(!isInternalFlow){
                setUserRoleList([{ label: "User", value: 1 }, { label: "Admin", value: 2 }]);
            }
            setLoading(false);
        }, []);

        const handleApproveReject = async (status) => {
            const token = localStorage.getItem('userdata');
            let postData = {};
            if(status === true){
                postData = {"status":"approve"}
            }
            else{
                postData = {"status":"reject"}
            }
            try {
                const response = await fetch(`${API_URL}/bond/approve_reject_paralegal/${props.id}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData),
                });
    
            } catch (error) {
                
            }
        };

        // useEffect(()=>{
        //     handleApproveReject(paralegalValue);
        // },[paralegalValue])
    

        const loadLawSchools = async () => {
            if (lawSchools !== null && lawSchools !== undefined && lawSchools.length === 0) {
                fetch(`${API_URL}/bond/list_all_law_schools/`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(async (res) => {
                    const response = await res.json();
                    dispatch(addLawSchool(response));
                    setLawSchools(response.law_schools)
                }).catch((err) => {
                    setUserRoleListLoading(false);
                    console.log(err);
                });
                // getAllLawSchools().then((response) => {
                //     console.log("getAllLawSchools response", response)
                //     setLawSchools(response.law_schools)
                // }).catch((error) => {
                //     setUserRoleListLoading(false);
                //     console.log(error);
                // });
            }
        }

        const getInitialValues = () => {
            if (props.id !== '0') {
                let data = props.data;
                return {
                    prefix: data.prefix,
                    first_name: data.first_name,
                    middle_name: data.middle_name,
                    last_name: data.last_name,
                    suffix: data.suffix,
                    preferred_name: data.preferred_name,
                    email: data.email,
                    phone_number: data.phone_number,
                    extension: data.extension,
                    user_role_id: data.user_role_id,
                    position: data.position,
                    position_value: data.position_value,
                    law_school: data.law_school,
                    bar: data.bar,
                    is_active: data.is_active,
                    deactivation_reason: data.deactivation_reason,
                    is_portal_super_admin: data.is_portal_super_admin
                }
            } else {
                return {
                    prefix: "",
                    first_name: "",
                    middle_name: "",
                    last_name: "",
                    suffix: "",
                    preferred_name: "",
                    email: email_id ? email_id : "",
                    phone_number: "",
                    extension: "",
                    user_role_id: 0,
                    position: 0,
                    position_value: "",
                    law_school: 0,
                    bar: "",
                    is_active: true,
                    deactivation_reason: 0,
                    is_portal_super_admin: false
                }
            }
        }

        const lawOnCallBack = (e) => {
            log("e,", e);
            setSelectedLawFirm(e);
        }

        const destroyLawFirm = async () => {
            await setOpenConfirmationPopup(false);
            if (props.comeFromComponent) {
                props.closeTeamMemberPopup(false);
            } else {

                if (props.tempCreatedLawFirm !== 0) {
                    let response = await deleteVermostLawFirmBackend(props.tempCreatedLawFirm);
                    if (response.status === 200) {
                        if(props.type !== "register"){
                            props.closeTeamMemberPopup(false);
                        }
                        props.type === "register" ? window.location.reload() : navigate(location.state !== undefined && location.state !== null ? `${location?.state?.history}` : "/user_list");
                    }
                }
                props.type === "register" ? window.location.reload() : navigate(location.state !== undefined && location.state !== null ? `${location?.state?.history}` : "/user_list");
            }
        }
        const handleConfirmationPopup = async () => {
            await setOpenConfirmationPopup(false);
            await destroyLawFirm();
        }
        const changeUserActiveStatus = () => {
            setOpenUserActiveConfirmationPopup(false);
            formik.setFieldValue('is_active', openUserActiveValue);
            setIsActive(openUserActiveValue);
        }
        /* const handleClose = () => setOpenConfirmationPopup(false);
         const handleConfirm = () => {
             //onConfirm();
             //handleClose();
         };*/
        const formik = useFormik({
                initialValues: getInitialValues(),
                onSubmit: async (values, {setSubmitting, resetForm}) => {
                    values.is_default = isDefault;
                    values.is_paralegal_service = isParalegalService; 
                    setSubmitting(true);
                    log("add edit user values,", values);
                    log("props.id,", props.id);
                    if (props.id !== undefined && props.id !== '0' && props.id !== 0) {
                        formik.resetForm({values: ''});
                        handleApproveReject(paralegalValue);
                        props.oncallback('edit', values, props.id);
                    } else {
                        if (props.type === 'register') {
                            values["selectedLawFirm"] = selectedLawFirm;
                            props.oncallback('insert', values);
                        } else if (props.lawFirmFromComponent !== undefined && props.lawFirmFromComponent !== null) {
                            values["selectedLawFirm"] = selectedLawFirm;
                            props.oncallback('insert', values);
                        } else {
                            validateUserEmail({email: values.email}).then((response) => {
                                log("response2,", response);
                                if (response === 200) {
                                    values["selectedLawFirm"] = selectedLawFirm;
                                    props.oncallback('insert', values);
                                } else {
                                    if (response?.detail) {
                                        enqueueSnackbar(response.detail, {variant: "error", autoHideDuration: 8000});
                                        formik.errors['email'] = response?.detail;
                                        setLoading(false);
                                    } else {
                                        enqueueSnackbar(response['email'][0], {variant: "error", autoHideDuration: 8000});
                                        formik.errors['email'] = response['email'][0];
                                        setLoading(false);
                                    }
                                }
                            }).catch((error) => {
                                console.log(error);
                                formik.errors['email'] = error['email'][0]
                                setLoading(false);
                            })
                        }
                    }

                    // props.oncallback(values);
                    setSubmitting(false);
                }
                ,
                validationSchema: schema
            }
        );

        useEffect(()=>{
            if(isInternalFlow){
                setSelectedLawFirm({ law_firm: 528, location: 634 })
            }
            
        }, [])


        return (
            <>
                {
                    loading ?
                        <CircularProgress/> :
                        <>
                            <h1>{formik.values.first_name} {formik.values.last_name}</h1>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Group key={'prefix'} controlId="validationFormik01" className="mb-1">
                                            <Form.Label><strong>Prefix</strong></Form.Label>
                                            <Form.Control type="text" size="sm"
                                                          onChange={(event) => {
                                                              setShowSubmitButton(true);
                                                              formik.handleChange(event)
                                                          }
                                                          }
                                                          name='prefix'
                                                          value={formik.values.prefix}
                                                          isInvalid={!!formik.errors.prefix}/>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.prefix}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {props.id === "0" && <><Col></Col><Col></Col><Col></Col></>}

                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group key={'first_name'} controlId="validationFormik02" className="mb-1">
                                            <Form.Label className={'required-label'}><strong>First
                                                Name</strong></Form.Label>
                                            <Form.Control type="text" size="sm"
                                                          onChange={(event) => {
                                                              setShowSubmitButton(true);
                                                              formik.handleChange(event)
                                                          }
                                                          }
                                                          name='first_name'
                                                          value={formik.values.first_name}
                                                          isInvalid={!!formik.errors.first_name}/>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.first_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>


                                    </Col>
                                    <Col>
                                        <Form.Group key={'middle_name'} controlId="validationFormik03" className="mb-1">
                                            <Form.Label><strong>Middle Name</strong></Form.Label>
                                            <Form.Control type="text" size="sm"
                                                          onChange={(event) => {
                                                              setShowSubmitButton(true);
                                                              formik.handleChange(event)
                                                          }
                                                          }
                                                          name='middle_name'
                                                          value={formik.values.middle_name}
                                                          isInvalid={!!formik.errors.middle_name}/>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.middle_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col>
                                        <Form.Group key={'last_name'} controlId="validationFormik04" className="mb-1">
                                            <Form.Label className={'required-label'}><strong>Last Name</strong></Form.Label>
                                            <Form.Control type="text" size="sm"
                                                          onChange={(event) => {
                                                              setShowSubmitButton(true);
                                                              formik.handleChange(event)
                                                          }
                                                          }
                                                          name='last_name'
                                                          value={formik.values.last_name}
                                                          isInvalid={!!formik.errors.last_name}/>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.last_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group key={'preferred_name'} controlId="validationFormik06" className="mb-3">
                                            <Form.Label><strong>Preferred Name</strong></Form.Label>
                                            <Form.Control type="text" size="sm"
                                                          onChange={(event) => {
                                                              setShowSubmitButton(true);
                                                              formik.handleChange(event)
                                                          }
                                                          }
                                                          name='preferred_name'
                                                          value={formik.values.preferred_name}
                                                          isInvalid={!!formik.errors.preferred_name}/>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.preferred_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {!isInternalFlow && (
    <Col>
        <Form.Group key={'suffix'} controlId="validationFormik05" className="mb-3">
            <Form.Label><strong>Suffix</strong></Form.Label>
            <CustomSelectInput size="sm"
                               onChange={(value) => {
                                   setShowSubmitButton(true);
                                   formik.setFieldValue('suffix', value);
                               }}
                               name='suffix'
                               value={formik.values.suffix ? formik.values.suffix : ''}
                               options={[
                                   { value: '', label: 'Select suffix' },
                                   { value: 'Sr.', label: 'Sr.' },
                                   { value: 'Jr.', label: 'Jr.' },
                                   { value: 'I', label: 'I' },
                                   { value: 'II', label: 'II' },
                                   { value: 'III', label: 'III' },
                                   { value: 'IV', label: 'IV' }
                               ]}
                               disabled={false}/>
            {!!formik.errors.suffix && (
                <div className="text-error">{formik.errors.suffix}</div>
            )}
        </Form.Group>
    </Col>
)}
                                    {props.id === "0" && <><Col></Col></>}
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group key={'email'} controlId="validationFormik07" className="mb-3">
                                            <Form.Label className={'required-label'}><strong>Email</strong></Form.Label>
                                            <Form.Control type="text" size="sm"
                                                          onChange={(event) => {
                                                              setShowSubmitButton(true);
                                                              formik.handleChange(event)
                                                          }
                                                          }
                                                          name='email'
                                                          value={formik.values.email}
                                                          disabled={props.type === 'register'}
                                                          isInvalid={!!formik.errors.email}/>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    {props.id === "0" && <><Col></Col><Col></Col></>}
                                </Row>
                                <Row>
                                <Col>
                                        <Form.Group key={'phone_number'} controlId="validationFormik08" className="mb-3">
                                            <Form.Label><strong>Phone
                                                Number</strong></Form.Label><br></br>
                                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                                                      onChange={(val) => {
                                                                                          if (val.match(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378")) {
                                                                                              setValidPhone(true);
                                                                                          } else {
                                                                                              setValidPhone(false);
                                                                                          }
                                                                                          formik.values.phone_number = val;
                                                                                          setShowSubmitButton(true);
                                                                                      }}
                                                                                      name='phone_number'
                                                                                      id='phone_number'
                                                                                      className={"form-control"}
                                                                                      value={formik.values.phone_number}/>

                                        </Form.Group>


                                    </Col>
                                    {!isInternalFlow &&(<Col>
                                        <Form.Group key={'extension'} controlId="validationFormik09" className="mb-3">
                                            <Form.Label><strong>Extension</strong></Form.Label>
                                            <Form.Control type="text" size="sm"
                                                          onChange={(event) => {
                                                              setShowSubmitButton(true);
                                                              formik.handleChange(event)
                                                          }
                                                          }
                                                          name='extension'
                                                          value={formik.values.extension}
                                                          isInvalid={!!formik.errors.extension}/>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.extension}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>)}
                                    {props.id === "0" && <><Col></Col></>}
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group key={'position'} controlId="validationFormik11" className="mb-3">
                                            <Form.Label className={'required-label'}><strong>Position</strong></Form.Label>
                                            <CustomSelectInput type="text" size="sm"
                                                               onChange={
                                                                   (value) => {
                                                                       if (value === 'ATTORNEY') {
                                                                           setAttorneyControl(true);
                                                                           setParalegalControl(false);
                                                                           loadLawSchools();
                                                                       } else if (value === 'PARALEGAL'){
                                                                           setParalegalControl(true);
                                                                           setAttorneyControl(false);
                                                                       }
                                                                       else{
                                                                        setAttorneyControl(false);
                                                                        setParalegalControl(false);
                                                                       }
                                                                       formik.setFieldValue('position', value);
                                                                       setValidPosition(true);
                                                                       setShowSubmitButton(true);
                                                                   }
                                                               }
                                                               name='position'
                                                               value={userPositionList?.length !== 0 ? userPositionList.filter(item => item?.value === formik.values.position)[0]?.value : []}
                                                               options={userPositionList} disabled={false}/>
                                            {!validPosition && formik.errors["position"] && (
                                                <div className="text-error">{formik.errors["position"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        {props.type !== 'register' &&
                                            <Form.Group key={'user_role_id'} controlId="validationFormik10"
                                                        className="mb-1">
                                                <Form.Label className={'required-label'}><strong>Permission
                                                    Level</strong></Form.Label>
                                                <CustomSelectInput type="text" size="sm"
                                                                   onChange={
                                                                       (value) => {
                                                                           formik.setFieldValue('user_role_id', value);
                                                                           setValidPermissionLevel(true);
                                                                           setShowSubmitButton(true);
                                                                       }
                                                                   }
                                                                   name='user_role_id'
                                                                   value={userRoleList?.length !== 0 ? userRoleList.filter(item => item?.value === formik.values.user_role_id)[0]?.value : []}
                                                                   options={userRoleList}
                                                                   disabled={false}/>
                                                {!validPermissionLevel && formik.errors["user_role_id"] && (
                                                    <div className="text-error">{formik.errors["user_role_id"]}</div>
                                                )}
                                            </Form.Group>
                                        }
                                    </Col>

                                    {props.id === "0" && <><Col></Col><Col></Col></>}

                                </Row>
                                <Row>

                                    <Col>
                                        {
                                            attorneyControl ?
                                                <Form.Group key={'bar'} controlId="validationFormik13"
                                                            className="mb-3">
                                                    <Form.Label className={'required-label'}><strong>Bar
                                                        Number</strong></Form.Label>
                                                    <Form.Control type="text" size="sm"
                                                                  onChange={(event) => {
                                                                      setShowSubmitButton(true);
                                                                      formik.handleChange(event)
                                                                  }
                                                                  }
                                                                  name='bar'
                                                                  value={formik.values.bar}
                                                                  isInvalid={!!formik.errors.bar}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.bar}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                : <></>
                                        }

                                    </Col>
                                    {props.id === "0" && <><Col></Col><Col></Col><Col></Col></>}
                                </Row>
                                <Row>

                                    <Col>
                                        {
                                            attorneyControl ?
                                                <Form.Group key={'law_school'} controlId="validationFormik12"
                                                            className="mb-3">
                                                    <Form.Label><strong>Law School</strong></Form.Label>
                                                    <CustomSelectInput type="text" size="sm"
                                                                       onChange={
                                                                           (value) => {
                                                                               formik.setFieldValue('law_school', value);
                                                                               setShowSubmitButton(true);
                                                                           }
                                                                       }
                                                                       name='law_school'
                                                                       value={lawSchools?.length !== 0 ? lawSchools.filter(item => item?.value === formik.values.law_school)[0]?.value : []}
                                                                       options={lawSchools}
                                                                       disabled={false}/>
                                                </Form.Group> : <></>
                                        }
                                    </Col>
                                    {props.id === "0" && <><Col></Col><Col></Col><Col></Col></>}
                                </Row>
                                {props.type === 'register' &&
                                    <Row>
                                        <Col>
                                            <Form.Group key="password" className="col-12" controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label
                                                    className="required-label"><strong>Password</strong></Form.Label>
                                                <Input style={{padding: "1%", color: "black"}}
                                                       name="password" id="password" placeholder="••••••••"
                                                       className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500
                                                   focus:border-blue-500 block w-full p-2.5 dark:border-gray-500
                                                   dark:placeholder-gray-400 dark:text-white"
                                                       type={showNewPassword ? "text" : "password"}
                                                       onBlur={formik.handleBlur} onChange={(event) => {
                                                    setShowSubmitButton(true);
                                                    formik.handleChange(event)
                                                }
                                                }
                                                       defaultValue={formik.values.password}
                                                       endAdornment={
                                                           <InputAdornment position="end">
                                                               <IconButton
                                                                   onClick={() => setShowNewPassword(!showNewPassword)}
                                                                   onMouseDown={(e) => e.preventDefault()}
                                                               >
                                                                   {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       }
                                                />
                                                {formik.errors["password"] && formik.touched["password"] && (
                                                    <div className="text-error">{formik.errors["password"]}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group key="confirm_password" className="col-12"
                                                        controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label className="required-label"><strong>Confirm
                                                    Password</strong></Form.Label>
                                                <Input style={{padding: "1%", color: "black"}}
                                                       name="confirm_password" id="confirm_password"
                                                       placeholder="••••••••"
                                                       className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500
                                                   focus:border-blue-500 block w-full p-2.5 dark:border-gray-500
                                                   dark:placeholder-gray-400 dark:text-white"
                                                       type={showConfirmPassword ? "text" : "password"}
                                                       onBlur={formik.handleBlur} onChange={(event) => {
                                                    setShowSubmitButton(true);
                                                    formik.handleChange(event)
                                                }
                                                }
                                                       defaultValue={formik.values.confirm_password}
                                                       endAdornment={
                                                           <InputAdornment position="end">
                                                               <IconButton
                                                                   onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                   onMouseDown={(e) => e.preventDefault()}
                                                               >
                                                                   {showConfirmPassword ? <Visibility/> :
                                                                       <VisibilityOff/>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       }
                                                />
                                                {formik.errors["confirm_password"] && formik.touched["confirm_password"] && (
                                                    <div
                                                        className="text-error">{formik.errors["confirm_password"]}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }
                                {props.id !== "0" && (super_user) &&
                                    <Form.Group key={'is_active'} controlId="validationFormik14" className="mb-3">
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="is_active"
                                            label="Is Active"
                                            onChange={
                                                (value) => {
                                                    setOpenUserActiveValue(value.target.checked);
                                                    setOpenUserActiveConfirmationPopup(true);
                                                    setShowSubmitButton(true);
                                                }
                                            }
                                            checked={isActive}
                                        />

                                    </Form.Group>
                                }
                                {props.id === "0" && attorneyControl &&
                                    <Form.Group key={'is_default'} controlId="" className="mb-3">
                                        <Form.Check
                                          type="switch"
                                          id="is_default"
                                          label="Is Default?"
                                          onChange={(e) => setIsDefault(e.target.checked)}
                                          checked={isDefault}
                                        />

                                    </Form.Group>
                                }
                                {props.id === "0" && paralegalControl &&
                                    <Form.Group key={'is_paralegal_service'} controlId="" className="mb-3">
                                        <Form.Check
                                          type="switch"
                                          id="is_paralegal_service"
                                          label="Legal Services Professional for multiple law firms."
                                          onChange={(e) => setIsParalegalService(e.target.checked)}
                                          checked={isParalegalService}
                                        />
                                        <p className="text-xs text-gray-600">We have received your request for access and will review it shortly. You will receive a follow-up email once the review process is complete.</p>

                                    </Form.Group>
                                }
                                {props.id !== "0" && paralegalControl &&
                                    <Form.Group key={'paralegal_value'} controlId="" className="mb-3">
                                        <Form.Check
                                          type="switch"
                                          id="paralegal_value"
                                          label="Legal Service Professional"
                                          onChange={(e) => {
                                            setParalegalValue(e.target.checked);
                                            setShowSubmitButton(true);
                                          }} 
                                          checked={paralegalValue}
                                        />

                                    </Form.Group>
                                }
                                <br/>
                                <hr className="fs-1"/>

                                {((props.routeFromComponent === undefined || props.routeFromComponent === null) && props.id === "0" && !isInternalFlow) &&
                                    <> <br/><br/>
                                        <SearchLawFirm
                                            show={true}
                                            lawFirmEditID={0}
                                            editID={0}
                                            type={props.type}
                                            lawFirmData={lawFirmList}
                                            callback={props.oncallback}
                                            onCallBack={lawOnCallBack}
                                            tempCreatedLawFirm={props.tempCreatedLawFirm}
                                            showCreateLawFirmPopup={props.showCreateLawFirmPopup}></SearchLawFirm>
                                    </>
                                }
                                {(props.routeFromComponent !== undefined && props.routeFromComponent !== null && props.routeFromComponent == '0' && props.lawFirmFromComponent != '0') &&
                                    <>
                                        <SearchLawFirm
                                            show={true}
                                            lawFirmEditID={0}
                                            editID={0}
                                            lawFirmData={lawFirmList.filter((list) => list.id == props.lawFirmFromComponent)}
                                            callback={props.oncallback}
                                            onCallBack={lawOnCallBack}
                                            routeFromComponent={props.routeFromComponent}
                                            showCreateLawFirmPopup={props.showCreateLawFirmPopup}></SearchLawFirm>
                                    </>
                                }
                                {
                                    (!isActive && attorneyControl) ?
                                        <Form.Group key={'deactivation_reason'} controlId="validationFormik15"
                                                    className="mb-3">
                                            <Form.Label><strong>Reason</strong></Form.Label>
                                            <CustomSelectInput type="text" size="sm"
                                                               onChange={
                                                                   (value) => {
                                                                       formik.setFieldValue('deactivation_reason', value);
                                                                       setShowSubmitButton(true);
                                                                   }
                                                               }
                                                               name='deactivation_reason'
                                                               value={reasonList?.length !== 0 ? reasonList.filter(item => item?.value === formik.values.deactivation_reason)[0]?.value : []}
                                                               options={reasonList}
                                                               disabled={false}/>
                                        </Form.Group>
                                        : <></>
                                }<br/><br/>
                                {(props.routeFromComponent === undefined || props.routeFromComponent === null) && (props.id === "0") ?
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Tooltip
                                            title={<span
                                                style={{fontSize: '16px'}}>Discard all entered data</span>}>
                                            <Button size="lg" variant="warning" onClick={() => {
                                                setOpenConfirmationPopup(true)
                                            }}
                                                    className={"ms-1"}>Cancel </Button>
                                        </Tooltip>
                                        {props.type === 'register' ?
                                            <Tooltip
                                                title={<span style={{fontSize: '16px'}}>Click here to complete your registration.</span>}>
                                                <Button size="lg" type="submit"
                                                        variant="primary"
                                                        className={"ms-1"}>Complete Registration </Button>
                                            </Tooltip>
                                            :
                                            <Tooltip
                                                title={<span style={{fontSize: '16px'}}>Click here to go to the User's page. You can add more law firms there.</span>}>
                                                <Button size="lg" type="submit"
                                                        variant="primary"
                                                        className={"ms-1"}>Continue </Button>
                                            </Tooltip>
                                        }
                                    </div>
                                    :
                                    <>
                                        {
                                            showSubmitButton && <Grid item xs={12} container justifyContent="flex-end">
                                                <Button size="lg" type="submit" variant="primary"
                                                        className={"ms-1"}>Submit </Button>
                                            </Grid>
                                        }
                                    </>
                                }
                            </Form>


                            {

                                <ConfirmationPopup show={openConfirmationPopup}
                                                   setOpenConfirmationPopup={setOpenConfirmationPopup}
                                                   title="Do you want to cancel all inputs?"
                                                   handleClose={handleConfirmationPopup}/>

                            }
                            {
                                <ConfirmationPopup show={openUserActiveConfirmationPopup}
                                                   setOpenConfirmationPopup={setOpenUserActiveConfirmationPopup}
                                                   title="This action will affect the user's ability to log in to the site. Are you sure?"
                                                   handleClose={changeUserActiveStatus}/>
                            }
                        </>
                }
            </>
        )
    }
;
export default AddEditUser;
