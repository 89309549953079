import React, { useState } from 'react';
import PropTypes from "prop-types";
import Select from "react-select";

export default function CustomSelectInput(props) {
    const [value, onChange] = useState(props.value);
    const styles = {
        menuList: styles => {
            return {
                ...styles,
                maxHeight: 175
            };
        }
    }

    return (
        <Select
            name={props.name} isDisabled={props.disabled}
            className={props.className}
            onChange={async (opt, e) => {
                onChange(opt.value);
                props.onChange(opt.value);
            }}
            options={props.options}
            defaultValue={props.options !== undefined ? props.options.filter((option)=>option.value === props.value)[0] : []}
            styles={styles}
            key={props.value}
        />
    );
}

CustomSelectInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
};
