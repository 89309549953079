import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import "../../index.css";
import {
    Card,
    CardContent,
    Checkbox,
    Container,
    FormControlLabel,
} from "@mui/material";
import {ArrowRight, ArrowLeft} from 'react-bootstrap-icons';
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomAmountInput from "../../global_components/inputs/amount";
import CustomTextInput from "../../global_components/inputs/text";
import CustomDatePicker from "../../global_components/custom_date_picker";
import {formatDate} from "../../services/helper";
import CustomNumberInput from "../../global_components/inputs/number";
import CustomLongInput from "../../global_components/inputs/long-text";

export default function DocumentUpload({formIK, step, progress, changeStep}) {
    const validate = () => {
        let error = false;
        let errors = {};
        // if (formIK.values['estate_has_ongoing_business'] === 'yes') {
        //     if(formIK.values['copy_of_court_order'] === ""){
        //         formIK.errors['copy_of_court_order'] = 'Please provide court order.';
        //         error = true;
        //     }
        // }
        if (formIK.values['decedent_execute_last_will_and_testament'] === 'yes' && formIK.values['bond_amount'] >= 1000000) {
            if (formIK.values['copy_of_last_will_and_testament'] === "") {
                errors = {
                    ...errors,
                    copy_of_last_will_and_testament: 'Please provide copy of last will and testament.'
                };
                error = true;
            }
        }
        if (error === true) {
            formIK.setErrors(errors);
            window.scroll(0, 0);
            console.log(formIK.errors);
        } else {
            console.log(formIK.values)
            console.log(formIK.errors)
            formIK.submitForm();
        }
    }

    useEffect(() => {

    });

    return (
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Submit <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{maxWidth: "50%"}} style={{marginBottom: "2%"}}>

                            <CardContent>
                                <h4>Document Upload</h4>
                                <Row>
                                    <Col>
                                        {/*{*/}
                                        {/*    formIK.values['estate_has_ongoing_business'] === 'no' && formIK.values['decedent_execute_last_will_and_testament'] === 'no' && (*/}
                                        {/*        <p>No document is required. Click on submit to submit bond request.</p>*/}
                                        {/*    )*/}
                                        {/*}*/}

                                        <Form.Group key={formIK.errors["copy_of_court_order"]}
                                                    className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label style={{display: "block"}}>
                                                <strong>Court Order</strong></Form.Label>
                                            <input type="file" disabled={false} accept="application/pdf"
                                                   name="copy_of_court_order"
                                                   onChange={(event) => {
                                                       formIK.setFieldValue("copy_of_court_order", event.currentTarget.files[0]);
                                                   }}/>
                                            <div className="text-error">{formIK.errors["copy_of_court_order"]}</div>
                                        </Form.Group>
                                        {
                                            formIK.values['decedent_execute_last_will_and_testament'] === 'yes' &&
                                            <Form.Group key={formIK.errors["copy_of_last_will_and_testament"]}
                                                        className="col-12" controlId="formBasicEmail"
                                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                <Form.Label
                                                    className={formIK.values['bond_amount'] >= 1000000 ? 'required-label' : ''}
                                                    style={{display: "block"}}>
                                                    <strong>Last Will & Testament</strong></Form.Label>
                                                <input type="file" disabled={false} accept="application/pdf"
                                                       name="copy_of_last_will_and_testament"
                                                       onChange={(event) => {
                                                           formIK.setFieldValue("copy_of_last_will_and_testament", event.currentTarget.files[0]);
                                                       }}/>
                                                <div
                                                    className="text-error">{formIK.errors["copy_of_last_will_and_testament"]}</div>
                                            </Form.Group>
                                        }
                                        <Form.Group key={formIK.errors["other_document"]}
                                                    className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label style={{display: "block"}}>
                                                <strong>Other Document</strong></Form.Label>
                                            <input type="file" disabled={false} accept="application/pdf"
                                                   name="other_document"
                                                   onChange={(event) => {
                                                       formIK.setFieldValue("other_document", event.currentTarget.files[0]);
                                                   }}/>
                                            <div className="text-error">{formIK.errors["other_document"]}</div>
                                        </Form.Group>
                                        <Form.Group key={formIK.errors["user_comment"]}
                                                    className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label style={{display: "block"}}><strong>Note</strong></Form.Label>
                                            <CustomLongInput disabled={false} required={false} className="border-5"
                                                             name="user_comment" id="user_comment-input"
                                                             value={formIK.values['user_comment']}
                                                             onChange={(value) => {
                                                                 formIK.setFieldValue("user_comment", value);
                                                             }}/>
                                            <div className="text-error">{formIK.errors["user_comment"]}</div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0} onClick={(e) => {
                                    e.preventDefault();
                                    changeStep(progress - step);
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Submit <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

DocumentUpload.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
