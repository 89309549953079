import React, { useState, useEffect } from 'react';
import CusNavBar from '../../global_components/navbar';
import * as XLSX from 'xlsx';
import { getAPICallv2 } from '../../services/helper';
import { API_URL } from '../../constants';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

const FsiCheckrun = () => {
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [tableOneData, setTableOneData] = useState([]);
  const [tableTwoData, setTableTwoData] = useState([]);
  const [tableThreeData, setTableThreeData] = useState([]); // New state for paid invoices
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [postedInvoices, setPostedInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]); // New state for paid invoices
  const [matchingInvoiceIds, setMatchingInvoiceIds] = useState([]);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAPICallv2(`/ap/invoice_list/?status=posted`);
        if (response && typeof response.json === 'function') {
          const data = await response.json();
          setPostedInvoices(data.data);
        } else {
          console.error("Invalid response format. Response:", response);
        }

        // Fetching paid invoices
        const paidResponse = await getAPICallv2(`/ap/invoice_list/?status=paid`);
        if (paidResponse && typeof paidResponse.json === 'function') {
          const paidData = await paidResponse.json();
          setPaidInvoices(paidData.data);
        } else {
          console.error("Invalid response format for paid invoices:", paidResponse);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Process all rows from the Excel file, including the check_number column
      const allTableData = jsonData.map(row => ({
        Vendor: row['payee'],
        VendorId: row['vendor_code'],
        Invoice: row['detail_number'],
        Amount: row['total_amount'],
        CheckNumber: row['check_number']
      }));

      // Compare with posted invoices first
      compareWithPostedData(allTableData);
    };
    reader.readAsArrayBuffer(file);
  };

  const compareWithPostedData = (allTableData) => {
    const matches = [];
    const nonMatches = [];
    const invoiceIdCheckNumberPairs = [];

    console.log("Starting comparison with postedInvoices...");
    console.log("All Table Data:", allTableData);
    console.log("Posted Invoices:", postedInvoices);

    allTableData.forEach(row => {
        const match = postedInvoices.find(posted =>
            posted.invoice_details.invoice_number.value.trim() === row.Invoice && posted.invoice_details.vendor_id.value === row.VendorId
        );

        if (match) {
            console.log(`Match found for Invoice: ${row.Invoice}, Vendor: ${row.Vendor}`);
            matches.push({ ...row, id: match.id });
            invoiceIdCheckNumberPairs.push({ invoiceId: match.id, checkNumber: row.CheckNumber });
        } else {
            console.log(`No match found for Invoice: ${row.Invoice}, Vendor: ${row.Vendor}`);
            nonMatches.push(row);
        }
    });

    console.log("Matches:", matches);
    console.log("Non-Matches:", nonMatches);
    console.log("Invoice ID and Check Number Pairs:", invoiceIdCheckNumberPairs);

    setTableOneData(matches);
    setTableTwoData(nonMatches);

    // Proceed with further comparisons if necessary
    console.log("Proceeding to compare nonMatches with paidInvoices...");
    compareWithPaidInvoices(nonMatches);

    setMatchingInvoiceIds(invoiceIdCheckNumberPairs);
};


  const compareWithPaidInvoices = (nonMatches) => {
    const matchedPaid = [];
    const remainingNonMatches = [];

    nonMatches.forEach(row => {
      const match = paidInvoices.find(paid =>
        paid.invoice_details.invoice_number.value === row.Invoice && paid.invoice_details.seller_name.value === row.Vendor
      );

      if (match) {
        matchedPaid.push(row); // If a match is found in paid invoices, move to matchedPaid
      } else {
        remainingNonMatches.push(row); // Else, keep in non-posted (tableTwoData)
      }
    });

    setTableTwoData(remainingNonMatches); // Update tableTwoData with unmatched data
    setTableThreeData(matchedPaid); // Set matched paid invoices to tableThreeData
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
  };

  const handleMarkAsPaid = async () => {
    setLoading(true);
    const apiUrl = `${API_URL}/ap/mark-as-paid`;

    const requestBody = {
      batchNo: description,
      invoices: matchingInvoiceIds
    };

    try {
      const token = localStorage.getItem('userdata');
      const response = await fetch(`${API_URL}/ap/invoices/move-to-paid`, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
        });

      if (response.ok) {
        enqueueSnackbar('Successfully marked as paid:', {variant:'success'});
        setLoading(false);
        navigate('/fsi-accounts-payable');
      } else {
        console.error('Failed to mark as paid. Status:', response.status);
      }
    } catch (error) {
      console.error('Error making API call:', error);
      setLoading(false);
    }
  };

  return (
    <div className='min-h-screen flex flex-col'>
      <CusNavBar />
      <main className="flex-grow">
      <div className="flex flex-col justify-center items-center mt-10">
        <h1 className="font-bold text-2xl text-gray-800 my-4">Upload Checkrun File</h1>

        <form onSubmit={handleSubmit} className="w-full p-8 mb-4 max-w-3xl min-h-[45vh] bg-gray-50 rounded-2xl border-2 border-gray-200">
          <div className="mb-6">
            <label htmlFor="description" className="block text-gray-700 font-semibold mb-2">
              Check Batch Number:
            </label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Enter a description"
              required
            />
          </div>

          <div className="mb-6">
            <label htmlFor="fileUpload" className="block text-gray-700 font-semibold mb-2">
              Upload File:
            </label>
            <input
              type="file"
              id="fileUpload"
              onChange={handleFileChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1"
          >
            Upload
          </button>
        </form>

        {formSubmitted && tableOneData.length > 0 && (
          <div className="mt-2 w-full max-w-3xl mb-16">
            <h2 className="font-bold text-xl text-green-700 mb-4">Invoices which are Posted:</h2>
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-2">#</th>
                  <th className="border border-gray-300 p-2">Vendor</th>
                  <th className="border border-gray-300 p-2">Invoice</th>
                  <th className="border border-gray-300 p-2">Amount</th>
                </tr>
              </thead>
              <tbody>
                {tableOneData.map((row, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 p-2">{index + 1}</td>
                    <td className="border border-gray-300 p-2">{row.Vendor}</td>
                    <td className="border border-gray-300 p-2">{row.Invoice}</td>
                    <td className="border border-gray-300 p-2">{row.Amount}</td>
                  </tr>
                ))}
                <tr>
                  <td className="border border-gray-300 p-2 font-bold" colSpan="3">Total</td>
                  <td className="border border-gray-300 p-2 font-bold">
                    {tableOneData.reduce((total, row) => total + parseFloat(row.Amount || 0), 0).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              onClick={handleMarkAsPaid}
              className="w-full mt-4 bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1"
            >
              {loading ? (
                <div className="flex justify-center items-center h-full"><Oval type="Oval" color="#FFFFFF" height={20} width={20} /></div>
              ) : (
                'Mark as Paid'
              )}
            </button>
          </div>
        )}

{formSubmitted && tableTwoData.length > 0 && (
  <div className="mt-2 w-full max-w-3xl">
    <h2 className="font-bold text-xl text-red-700 mb-4">Invoices which are not Posted:</h2>
    <table className="w-full border-collapse border border-gray-300">
      <thead>
        <tr>
          <th className="border border-gray-300 p-2">#</th>
          <th className="border border-gray-300 p-2">Vendor</th>
          <th className="border border-gray-300 p-2">Invoice</th>
          <th className="border border-gray-300 p-2">Amount</th> {/* Added Amount column */}
        </tr>
      </thead>
      <tbody>
        {tableTwoData.map((row, index) => (
          <tr key={index}>
            <td className="border border-gray-300 p-2">{index + 1}</td>
            <td className="border border-gray-300 p-2">{row.Vendor}</td>
            <td className="border border-gray-300 p-2">{row.Invoice}</td>
            <td className="border border-gray-300 p-2">{row.Amount}</td> {/* Added Amount value */}
          </tr>
        ))}
        <tr>
          <td className="border border-gray-300 p-2 font-bold" colSpan="3">Total</td>
          <td className="border border-gray-300 p-2 font-bold">
            {tableTwoData.reduce((total, row) => total + parseFloat(row.Amount || 0), 0).toFixed(2)}
          </td> {/* Calculating and displaying the total */}
        </tr>
      </tbody>
    </table>
  </div>
)}

{formSubmitted && tableThreeData.length > 0 && (
  <div className="mt-8 w-full max-w-3xl">
    <h2 className="font-bold text-xl text-yellow-700 mb-4">Invoices which are already PAID through the system:</h2>
    <table className="w-full border-collapse border border-gray-300">
      <thead>
        <tr>
          <th className="border border-gray-300 p-2">#</th>
          <th className="border border-gray-300 p-2">Vendor</th>
          <th className="border border-gray-300 p-2">Invoice</th>
          <th className="border border-gray-300 p-2">Amount</th> {/* Added Amount column */}
        </tr>
      </thead>
      <tbody>
        {tableThreeData.map((row, index) => (
          <tr key={index}>
            <td className="border border-gray-300 p-2">{index + 1}</td>
            <td className="border border-gray-300 p-2">{row.Vendor}</td>
            <td className="border border-gray-300 p-2">{row.Invoice}</td>
            <td className="border border-gray-300 p-2">{row.Amount}</td> {/* Added Amount value */}
          </tr>
        ))}
        <tr>
          <td className="border border-gray-300 p-2 font-bold" colSpan="3">Total</td>
          <td className="border border-gray-300 p-2 font-bold">
            {tableThreeData.reduce((total, row) => total + parseFloat(row.Amount || 0), 0).toFixed(2)}
          </td> {/* Calculating and displaying the total */}
        </tr>
      </tbody>
    </table>
  </div>
)}
      </div>
  </main>

      
  <footer className="text-center py-4 text-muted border-t-2 mt-4 border-gray-300 bg-slate-50">
    Created by Blue Ocean Strategic Partners &middot; &copy; {new Date().getFullYear()} | For support please send an email to our <a href="mailto:support@blueoceansp.atlassian.net">Help Desk</a>.
  </footer>
    </div>
  );
};

export default FsiCheckrun;
