import React from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import {Card, CardContent, Container} from "@mui/material";
import {Col, Form, Row} from "react-bootstrap";
import {ArrowLeft, ArrowRight} from "react-bootstrap-icons";
import CustomAmountInput from "../../global_components/inputs/amount";
import CustomSelectInput from "../../global_components/inputs/select-field";
import Collapsible from 'react-collapsible';
import {BsChevronDown} from "react-icons/bs";
import "../../styles/main.css";
import {Logout} from "../../utils/user_logout";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {
    removeCompany,
    removeDepartmentApprovementLevel,
    removeDepartments,
    removeSelectedDepartment, removeSelectedPermissionAP,
    removeToken
} from "../../store/auth";
import {removeCategories, removeInvoicesDashboard} from "../../store/dashboard";
import {removeProcess, setCurrentActive} from "../../store/processes";
import {removeAccountNumbers, removeAllInvoices} from "../../store/invoices";
import {
    removeAPPermissions, removeBondNumbers,
    removeDepartmentsCM,
    removeLawFirmBranches,
    removeLawFirmContacts,
    removeLawFirmProfessionalGuardians,
    removeLawFirms,
    removeLawSchools,
    removePOPermissions,
    removeProcessPermissions,
    removeSuperPOUsers,
    removeUsers
} from "../../store/company_management";
import {removeDocuments} from "../../store/document_approval";
import {removeProcessDetails, removeProcessHistory, removeProcessInputs} from "../../store/capital_project";
import {
    removeBondProcessDetails,
    removeBondProcessHistory,
    removeBondProcessInputs,
    removeLawFirmData
} from "../../store/bond";
import {useNavigate} from "react-router";

export default function Coverage(props) {
    const prePopulated = JSON.parse(localStorage.getItem('prePopulatedFields'));
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const coverage_b_options = [];
    if (props.formIK.values.orion === true || props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true) {
        coverage_b_options.push({label: '0% of Cov A', value: '0% of Cov A'});
        props.formIK.values.coverage_b_other_structures = '0% of Cov A';
    }
    coverage_b_options.push({label: '2% of Cov A', value: '2% of Cov A'});
    coverage_b_options.push({label: '10% of Cov A', value: '10% of Cov A'});
    coverage_b_options.push({label: '20% of Cov A', value: '20% of Cov A'});
    coverage_b_options.push({label: '30% of Cov A', value: '30% of Cov A'});
    coverage_b_options.push({label: '40% of Cov A', value: '40% of Cov A'});
    coverage_b_options.push({label: '50% of Cov A', value: '50% of Cov A'});
    const logout = ()=>{
        enqueueSnackbar('You are logged out. Redirecting to login page in 3 seconds.', {variant: "error"});
        setTimeout(() => {
            localStorage.clear();
            dispatch(removeToken());
            dispatch(removeCompany());
            dispatch(removeInvoicesDashboard());
            dispatch(removeCategories());
            dispatch(removeProcess());
            dispatch(setCurrentActive());
            dispatch(removeAllInvoices());
            dispatch(removeAccountNumbers());
            dispatch(removeDepartments());
            dispatch(removeSelectedDepartment());
            dispatch(removeDepartmentApprovementLevel());
            dispatch(removeSelectedPermissionAP());
            dispatch(removeUsers());
            dispatch(removeDepartmentsCM());
            dispatch(removeAPPermissions());
            dispatch(removePOPermissions());
            dispatch(removeSuperPOUsers());
            dispatch(removeProcessPermissions());
            dispatch(removeDocuments());
            dispatch(removeProcessHistory());
            dispatch(removeProcessDetails());
            dispatch(removeProcessInputs());
            dispatch(removeBondProcessInputs());
            dispatch(removeBondProcessDetails());
            dispatch(removeBondProcessHistory());
            dispatch(removeLawFirmData());
            dispatch(removeLawFirms());
            dispatch(removeLawFirmContacts());
            dispatch(removeLawFirmBranches());
            dispatch(removeLawSchools());
            dispatch(removeLawFirmProfessionalGuardians());
            dispatch(removeBondNumbers());
            navigate("/");

        }, 3000);
    }

    const validate_data = () => {
        if (!localStorage.getItem('userdata')) {
            logout();
            return;
        }
        let error = false;
        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.ssiu === true) {
            if (props.formIK.values.coverage_a_dwelling === "" || isNaN(props.formIK.values.coverage_a_dwelling)) {
                props.formIK.errors['coverage_a_dwelling'] = 'Coverage A Dwelling is required.';
                error = true;
            }
        }
        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.ssiu === true || props.formIK.values.openly === true) {
            if (props.formIK.values.all_other_peril_deductible === "") {
                props.formIK.errors['all_other_peril_deductible'] = 'All Other Peril Deductible is required.';
                error = true;
            }
        }


        if (props.formIK.values.hurricane_wind_hail_tornado_deductible === "") {
            props.formIK.errors['hurricane_wind_hail_tornado_deductible'] = 'Hurricane/Wind/Hail or Tornado Deductible is required.';
            error = true;
        }

        if (props.formIK.values.coverage_c_personal_property === "") {
            props.formIK.errors['coverage_c_personal_property'] = 'Coverage C - Personal Property is required.';
            error = true;
        }

        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true || props.formIK.values.openlly === true) {
            if (props.formIK.values.coverage_d_loss_of_use === "") {
                props.formIK.errors['coverage_d_loss_of_use'] = 'Coverage D - Loss of Use is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.ssiu === true || props.formIK.values.swyfft === true || props.formIK.values.openly === true) {
            if (props.formIK.values.coverage_e_personal_liability === "") {
                props.formIK.errors['coverage_e_personal_liability'] = 'Coverage E - Personal Liability is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.ssiu === true || props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) {
            if (props.formIK.values.coverage_f_medical_payments === "") {
                props.formIK.errors['coverage_f_medical_payments'] = 'Coverage F - Medical Payments is required.';
                error = true;
            }
        }

        if (props.formIK.values.ssiu === true) {
            if (props.formIK.values.limited_fungi_mold_and_bacteria_coverage === "") {
                props.formIK.errors['limited_fungi_mold_and_bacteria_coverage'] = 'Limited Fungi, Mold and Bacteria Coverage is required.';
                error = true;
            }
        }
        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.ssiu === true || props.formIK.values.openly === true) {
            if (props.formIK.values.water_back_up_sump_discharge_or_overflow === "") {
                props.formIK.errors['water_back_up_sump_discharge_or_overflow'] = 'Water Back Up & Sump Discharge or Overflow is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) {
            if (props.formIK.values.ordinance_or_law === "") {
                props.formIK.errors['ordinance_or_law'] = 'Ordinance Or Law is required.';
                error = true;
            }
        }

        if (props.formIK.values.increased_replacement_cost_on_dwelling === true) {
            if (props.formIK.values.increased_replacement_cost_on_dwelling === "") {
                props.formIK.errors['increased_replacement_cost_on_dwelling'] = 'Increased Replacement Cost on Dwelling is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
            props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) {
            if (props.formIK.values.coverage_c_personal_property_replacement_cost === "") {
                props.formIK.errors['coverage_c_personal_property_replacement_cost'] = 'Coverage - C Personal Property Replacement Cost is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true || props.formIK.values.openly === true ||
            props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) {
            if (props.formIK.values.equipment_breakdown === "") {
                props.formIK.errors['equipment_breakdown'] = 'Equipment Breakdown is required.';
                error = true;
            }
        }

        if (props.formIK.values.ssiu === true) {
            if (props.formIK.values.flood === "") {
                props.formIK.errors['flood'] = 'Flood is required.';
                error = true;
            }
            if (props.formIK.values.extended_rc_125 === "") {
                props.formIK.errors['extended_rc_125'] = 'Extended RC 125% is required.';
                error = true;
            }
            if (props.formIK.values.home_system_protection === "") {
                props.formIK.errors['home_system_protection'] = 'Home System Protection is required.';
                error = true;
            }
            if (props.formIK.values.water_damage_sublimit === "") {
                props.formIK.errors['water_damage_sublimit'] = 'Water Damage Sub-limit is required.';
                error = true;
            }
        }

        if (props.formIK.values.openly === true || props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true) {
            if (props.formIK.values.earthquake === "") {
                props.formIK.errors['earthquake'] = 'Earthquake is required.';
                error = true;
            }
            if (props.formIK.values.service_line === "") {
                props.formIK.errors['service_line'] = 'Service Line is required.';
                error = true;
            }
        }

        if (props.formIK.values.orion === true) {
            if (props.formIK.values.fortified_roof_endorsement === "") {
                props.formIK.errors['fortified_roof_endorsement'] = 'Fortified Roof Endorsement is required.';
                error = true;
            }
        }

        if (props.formIK.values.openly === true) {
            if (props.formIK.values.under_construction === "") {
                props.formIK.errors['under_construction'] = 'Under Construction is required.';
                error = true;
            }
            if (props.formIK.values.personal_cyber === "") {
                props.formIK.errors['personal_cyber'] = 'Personal Cyber is required.';
                error = true;
            }
            if (props.formIK.values.home_sharing_participation === "") {
                props.formIK.errors['home_sharing_participation'] = 'Home Sharing Participation is required.';
                error = true;
            }
        }

        if (error === true) {
            window.scroll(0, 0);
            console.log(props.formIK.errors);
        } else {
            props.formIK.submitForm();
        }
    }

    return (
        <div>
            <Container>
                <Col>
                    <Row style={{marginBottom: '1%'}}>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button onClick={() => props.changeStep(props.progress - props.step)}
                                        disabled={props.progress === 0}
                                        className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={() => validate_data()}
                                        className="btn btn-primary" disabled={props.loading === true}>
                                    Confirm <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Card sx={{minWidth: "100%", minHeight: "1325px"}} style={{marginBottom: "2%"}}>
                            <CardContent>
                                <Row>
                                    <h4>Coverage</h4>
                                </Row>
                                <Row>
                                    <Col className="property-cols">
                                        <h6>Primary Coverages</h6>
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true || props.formIK.values.swyfft === true) && (
                                                <Form.Group key="coverage_a_dwelling" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Coverage A -
                                                        Dwelling</strong></Form.Label>
                                                    <CustomAmountInput disabled={false} required={true}
                                                                       name="coverage_a_dwelling"
                                                                       className={prePopulated.includes('coverage_a_dwelling') ? 'form-control prePopulated' : 'form-control '}
                                                                       id="coverage_a_dwelling-input" decimalScale={0}
                                                                       value={props.formIK.values['coverage_a_dwelling'] !== "" ? props.formIK.values['coverage_a_dwelling'] : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "" || isNaN(value)) {
                                                                               props.formIK.errors['coverage_a_dwelling'] = "Coverage A - Dwelling is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['coverage_a_dwelling'];
                                                                           }
                                                                           props.formIK.setFieldValue("coverage_a_dwelling", value);
                                                                       }}/>
                                                    {props.formIK.errors["coverage_a_dwelling"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["coverage_a_dwelling"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key="all_other_peril_deductible" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>All Other Peril
                                                Deductible</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="all_other_peril_deductible"
                                                               className={prePopulated.includes('all_other_peril_deductible') ? 'prePopulated' : ''}
                                                               value={props.formIK.values.all_other_peril_deductible !== undefined ? props.formIK.values.all_other_peril_deductible : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['all_other_peril_deductible'] = "All Other Peril Deductible is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['all_other_peril_deductible'];
                                                                   }
                                                                   props.formIK.setFieldValue("all_other_peril_deductible", value)
                                                               }}
                                                               options={[{
                                                                   label: '$1,000',
                                                                   value: '$1,000'
                                                               }, {label: '$1,500', value: '$1,500'},
                                                                   {label: '$2,500', value: '$2,500'}, {
                                                                       label: '$5,000',
                                                                       value: '$5,000'
                                                                   }, {label: '1% of Cov A', value: '1% of Cov A'},
                                                                   {
                                                                       label: '2% of Cov A',
                                                                       value: '2% of Cov A'
                                                                   }, {label: '3% of Cov A', value: '3% of Cov A'},
                                                                   {label: '5% of Cov A', value: '5% of Cov A'}]}/>
                                            {props.formIK.errors["all_other_peril_deductible"] && (
                                                <div
                                                    className="text-error">{props.formIK.errors["all_other_peril_deductible"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="hurricane_wind_hail_tornado_deductible"
                                                    controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Hurricane/Wind/Hail or
                                                Tornado Deductible</strong></Form.Label>
                                            <CustomSelectInput disabled={false}
                                                               name="hurricane_wind_hail_tornado_deductible"
                                                               value={props.formIK.values.hurricane_wind_hail_tornado_deductible !== undefined ? props.formIK.values.hurricane_wind_hail_tornado_deductible : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['hurricane_wind_hail_tornado_deductible'] = "Hurricane/Wind/Hail or Tornado Deductible is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['hurricane_wind_hail_tornado_deductible'];
                                                                   }
                                                                   props.formIK.setFieldValue("hurricane_wind_hail_tornado_deductible", value)
                                                               }}
                                                               options={[{label: '$1,500', value: '$1,500'},
                                                                   {label: '$2,500', value: '$2,500'}, {
                                                                       label: '$5,000',
                                                                       value: '$5,000'
                                                                   }, {label: '1% of Cov A', value: '1% of Cov A'},
                                                                   {
                                                                       label: '2% of Cov A',
                                                                       value: '2% of Cov A'
                                                                   }, {label: '3% of Cov A', value: '3% of Cov A'},
                                                                   {label: '5% of Cov A', value: '5% of Cov A'}]}/>
                                            {props.formIK.errors["hurricane_wind_hail_tornado_deductible"] && (
                                                <div
                                                    className="text-error">{props.formIK.errors["hurricane_wind_hail_tornado_deductible"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="coverage_b_other_structures" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Coverage B - Other
                                                Structures</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="coverage_b_other_structures"
                                                               className={prePopulated.includes('coverage_b_other_structures') ? 'prePopulated' : ''}
                                                               value={props.formIK.values.coverage_b_other_structures !== undefined ? props.formIK.values.coverage_b_other_structures : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['coverage_b_other_structures'] = "Coverage B - Other Structures is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['coverage_b_other_structures'];
                                                                   }
                                                                   props.formIK.setFieldValue("coverage_b_other_structures", value)
                                                               }}

                                                               options={coverage_b_options}/>
                                            {props.formIK.errors["coverage_b_other_structures"] && (
                                                <div
                                                    className="text-error">{props.formIK.errors["coverage_b_other_structures"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="coverage_c_personal_property" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Coverage C - Personal
                                                Property</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="coverage_c_personal_property"
                                                               className={prePopulated.includes('coverage_c_personal_property') ? 'prePopulated' : ''}
                                                               value={props.formIK.values.coverage_c_personal_property !== undefined ? props.formIK.values.coverage_c_personal_property : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['coverage_c_personal_property'] = "Coverage C - Personal Property is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['coverage_c_personal_property'];
                                                                   }
                                                                   props.formIK.setFieldValue("coverage_c_personal_property", value)
                                                               }}
                                                               options={[{
                                                                   label: '0% of Cov A',
                                                                   value: '0% of Cov A'
                                                               }, {label: '5% of Cov A', value: '5% of Cov A'},
                                                                   {
                                                                       label: '10% of Cov A',
                                                                       value: '10% of Cov A'
                                                                   }, {label: '15% of Cov A', value: '15% of Cov A'},
                                                                   {
                                                                       label: '20% of Cov A',
                                                                       value: '20% of Cov A'
                                                                   }, {label: '25% of Cov A', value: '25% of Cov A'},
                                                                   {
                                                                       label: '30% of Cov A',
                                                                       value: '30% of Cov A'
                                                                   }, {label: '35% of Cov A', value: '35% of Cov A'},
                                                                   {
                                                                       label: '40% of Cov A',
                                                                       value: '40% of Cov A'
                                                                   }, {label: '45% of Cov A', value: '45% of Cov A'},
                                                                   {
                                                                       label: '50% of Cov A',
                                                                       value: '50% of Cov A'
                                                                   }, {label: '55% of Cov A', value: '55% of Cov A'},
                                                                   {
                                                                       label: '60% of Cov A',
                                                                       value: '60% of Cov A'
                                                                   }, {label: '65% of Cov A', value: '65% of Cov A'},
                                                                   {label: '70% of Cov A', value: '70% of Cov A'}]}/>
                                            {props.formIK.errors["coverage_c_personal_property"] && (
                                                <div
                                                    className="text-error">{props.formIK.errors["coverage_c_personal_property"]}</div>
                                            )}
                                        </Form.Group>
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true ||
                                                props.formIK.values.openly === true) && (
                                                <Form.Group key="coverage_d_loss_of_use" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Coverage D - Loss of
                                                        Use</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="coverage_d_loss_of_use"
                                                                       className={prePopulated.includes('coverage_d_loss_of_use') ? 'prePopulated' : ''}
                                                                       value={props.formIK.values.coverage_d_loss_of_use !== undefined ? props.formIK.values.coverage_d_loss_of_use : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['coverage_d_loss_of_use'] = "Coverage D - Loss of Use is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['coverage_d_loss_of_use'];
                                                                           }
                                                                           props.formIK.setFieldValue("coverage_d_loss_of_use", value)
                                                                       }}
                                                                       options={[{
                                                                           label: '10% of Cov A',
                                                                           value: '10% of Cov A'
                                                                       }, {label: '20% of Cov A', value: '20% of Cov A'}]}/>
                                                    {props.formIK.errors["coverage_d_loss_of_use"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["coverage_d_loss_of_use"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.ssiu === true || props.formIK.values.swyfft === true ||
                                                props.formIK.values.openly === true) && (
                                                <Form.Group key="coverage_e_personal_liability" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Coverage E - Personal
                                                        Liability</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="coverage_e_personal_liability"
                                                                       value={props.formIK.values.coverage_e_personal_liability !== undefined ? props.formIK.values.coverage_e_personal_liability : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['coverage_e_personal_liability'] = "Coverage E - Personal Liability is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['coverage_e_personal_liability'];
                                                                           }
                                                                           props.formIK.setFieldValue("coverage_e_personal_liability", value)
                                                                       }}
                                                                       options={[{
                                                                           label: '$100,000',
                                                                           value: '$100,000'
                                                                       }, {label: '$300,000', value: '$300,000'},
                                                                           {label: '$500,000', value: '$500,000'}]}/>
                                                    {props.formIK.errors["coverage_e_personal_liability"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["coverage_e_personal_liability"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.ssiu === true || props.formIK.values.swyfft === true ||
                                                props.formIK.values.sage_sure === true) && (
                                                <Form.Group key="coverage_f_medical_payments" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Coverage F - Medical
                                                        Payments</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="coverage_f_medical_payments"
                                                                       value={props.formIK.values.coverage_f_medical_payments !== undefined ? props.formIK.values.coverage_f_medical_payments : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['coverage_f_medical_payments'] = "Coverage F - Medical Payments is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['coverage_f_medical_payments'];
                                                                           }
                                                                           props.formIK.setFieldValue("coverage_f_medical_payments", value)
                                                                       }}
                                                                       options={[{
                                                                           label: '$1,000',
                                                                           value: '$1,000'
                                                                       }, {label: '$2,500', value: '$2,500'},
                                                                           {label: '$5,000', value: '$5,000'}]}/>
                                                    {props.formIK.errors["coverage_f_medical_payments"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["coverage_f_medical_payments"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                    </Col>
                                    <Col className="property-cols">
                                        <h6>Additional Coverages</h6>
                                        {/*{*/}
                                        {/*    (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||*/}
                                        {/*        props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true) && (*/}
                                        {/*        <Form.Group key="loss_assessment_coverage" controlId="formBasicEmail"*/}
                                        {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                        {/*            <Form.Label>Loss Assessment Coverage</Form.Label>*/}
                                        {/*            <CustomTextInput disabled={false} required={false} name="loss_assessment_coverage"*/}
                                        {/*                             className={"form-control"} id="loss_assessment_coverage-input"*/}
                                        {/*                             value={props.formIK.values.loss_assessment_coverage}*/}
                                        {/*                             onChange={(value)=>{*/}
                                        {/*                                 if(value === ""){*/}
                                        {/*                                     props.formIK.errors['loss_assessment_coverage'] = "Loss Assessment Coverage is required.";*/}
                                        {/*                                 }else{*/}
                                        {/*                                     delete props.formIK.errors['loss_assessment_coverage'];*/}
                                        {/*                                 }*/}
                                        {/*                                 props.formIK.setFieldValue("loss_assessment_coverage", value);*/}
                                        {/*                             }}/>*/}
                                        {/*            {props.formIK.errors["loss_assessment_coverage"] && (*/}
                                        {/*                <div className="text-error">{props.formIK.errors["loss_assessment_coverage"]}</div>*/}
                                        {/*            )}*/}
                                        {/*        </Form.Group>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true) && (*/}
                                        {/*        <Form.Group key="special_computer_coverage" controlId="formBasicEmail"*/}
                                        {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                        {/*            <Form.Label>Special Computer Coverage</Form.Label>*/}
                                        {/*            <CustomTextInput disabled={false} required={false} name="special_computer_coverage"*/}
                                        {/*                             className={"form-control"} id="special_computer_coverage-input"*/}
                                        {/*                             value={props.formIK.values.special_computer_coverage}*/}
                                        {/*                             onChange={(value)=>{*/}
                                        {/*                                 if(value === ""){*/}
                                        {/*                                     props.formIK.errors['special_computer_coverage'] = "Special Computer Coverage is required.";*/}
                                        {/*                                 }else{*/}
                                        {/*                                     delete props.formIK.errors['special_computer_coverage'];*/}
                                        {/*                                 }*/}
                                        {/*                                 props.formIK.setFieldValue("special_computer_coverage", value);*/}
                                        {/*                             }}/>*/}
                                        {/*            {props.formIK.errors["special_computer_coverage"] && (*/}
                                        {/*                <div className="text-error">{props.formIK.errors["special_computer_coverage"]}</div>*/}
                                        {/*            )}*/}
                                        {/*        </Form.Group>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||*/}
                                        {/*        props.formIK.values.openly === true) && (*/}
                                        {/*        <Form.Group key="jewelry_watches_furs_increased_limits" controlId="formBasicEmail"*/}
                                        {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                        {/*            <Form.Label>Jewelry, Watches & Furs - Increased Limits</Form.Label>*/}
                                        {/*            <CustomTextInput disabled={false} required={false} name="jewelry_watches_furs_increased_limits"*/}
                                        {/*                             className={"form-control"} id="jewelry_watches_furs_increased_limits-input"*/}
                                        {/*                             value={props.formIK.values.jewelry_watches_furs_increased_limits}*/}
                                        {/*                             onChange={(value)=>{*/}
                                        {/*                                 if(value === ""){*/}
                                        {/*                                     props.formIK.errors['jewelry_watches_furs_increased_limits'] = "Jewelry, Watches & Furs - Increased Limits is required.";*/}
                                        {/*                                 }else{*/}
                                        {/*                                     delete props.formIK.errors['jewelry_watches_furs_increased_limits'];*/}
                                        {/*                                 }*/}
                                        {/*                                 props.formIK.setFieldValue("jewelry_watches_furs_increased_limits", value);*/}
                                        {/*                             }}/>*/}
                                        {/*            {props.formIK.errors["jewelry_watches_furs_increased_limits"] && (*/}
                                        {/*                <div className="text-error">{props.formIK.errors["jewelry_watches_furs_increased_limits"]}</div>*/}
                                        {/*            )}*/}
                                        {/*        </Form.Group>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    (props.formIK.values.orion === true) && (*/}
                                        {/*        <Form.Group key="silverware_goldware_pewterware_increased_limits" controlId="formBasicEmail"*/}
                                        {/*                    style={{padding: "0px 15px 0px 0px"}}>*/}
                                        {/*            <Form.Label>Silverware, Goldware, Pewterware - Increased Limits</Form.Label>*/}
                                        {/*            <CustomTextInput disabled={false} required={false} name="silverware_goldware_pewterware_increased_limits"*/}
                                        {/*                             className={"form-control"} id="silverware_goldware_pewterware_increased_limits-input"*/}
                                        {/*                             value={props.formIK.values.silverware_goldware_pewterware_increased_limits}*/}
                                        {/*                             onChange={(value)=>{*/}
                                        {/*                                 if(value === ""){*/}
                                        {/*                                     props.formIK.errors['silverware_goldware_pewterware_increased_limits'] = "Silverware, Goldware, Pewterware - Increased Limits is required.";*/}
                                        {/*                                 }else{*/}
                                        {/*                                     delete props.formIK.errors['silverware_goldware_pewterware_increased_limits'];*/}
                                        {/*                                 }*/}
                                        {/*                                 props.formIK.setFieldValue("silverware_goldware_pewterware_increased_limits", value);*/}
                                        {/*                             }}/>*/}
                                        {/*            {props.formIK.errors["silverware_goldware_pewterware_increased_limits"] && (*/}
                                        {/*                <div className="text-error">{props.formIK.errors["silverware_goldware_pewterware_increased_limits"]}</div>*/}
                                        {/*            )}*/}
                                        {/*        </Form.Group>*/}
                                        {/*    )*/}
                                        {/*}*/}
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.ssiu === true) && (
                                                <Form.Group key="limited_fungi_mold_and_bacteria_coverage"
                                                            controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label><strong>Limited Fungi, Mold and Bacteria
                                                        Coverage</strong></Form.Label>
                                                    <CustomSelectInput disabled={false}
                                                                       name="limited_fungi_mold_and_bacteria_coverage"
                                                                       value={props.formIK.values.limited_fungi_mold_and_bacteria_coverage !== undefined ? props.formIK.values.limited_fungi_mold_and_bacteria_coverage : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['limited_fungi_mold_and_bacteria_coverage'] = "Limited Fungi, Mold and Bacteria Coverage is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['limited_fungi_mold_and_bacteria_coverage'];
                                                                           }
                                                                           props.formIK.setFieldValue("limited_fungi_mold_and_bacteria_coverage", value)
                                                                       }}
                                                                       options={[{
                                                                           label: 'None',
                                                                           value: 'None'
                                                                       }, {label: '$5,000', value: '$5,000'},
                                                                           {
                                                                               label: '$10,000',
                                                                               value: '$10,000'
                                                                           }, {label: '$25,000', value: '$25,000'}]}/>
                                                    {props.formIK.errors["limited_fungi_mold_and_bacteria_coverage"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["limited_fungi_mold_and_bacteria_coverage"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key="water_back_up_sump_discharge_or_overflow"
                                                    controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className="required-label"><strong>Water Back Up & Sump
                                                Discharge or Overflow</strong></Form.Label>
                                            <CustomSelectInput disabled={false}
                                                               name="water_back_up_sump_discharge_or_overflow"
                                                               className={prePopulated.includes('water_back_up_sump_discharge_or_overflow') ? 'prePopulated' : ''}
                                                               value={props.formIK.values.water_back_up_sump_discharge_or_overflow !== undefined ? props.formIK.values.water_back_up_sump_discharge_or_overflow : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['water_back_up_sump_discharge_or_overflow'] = "Water Back Up & Sump Discharge or Overflow is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['water_back_up_sump_discharge_or_overflow'];
                                                                   }
                                                                   props.formIK.setFieldValue("water_back_up_sump_discharge_or_overflow", value)
                                                               }}
                                                               options={[{
                                                                   label: 'None',
                                                                   value: 'None'
                                                               }, {label: '$5,000', value: '$5,000'},
                                                                   {
                                                                       label: '$10,000',
                                                                       value: '$10,000'
                                                                   }, {label: '$25,000', value: '$25,000'}]}/>
                                            {props.formIK.errors["water_back_up_sump_discharge_or_overflow"] && (
                                                <div
                                                    className="text-error">{props.formIK.errors["water_back_up_sump_discharge_or_overflow"]}</div>
                                            )}
                                        </Form.Group>
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) && (
                                                <Form.Group key="ordinance_or_law" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Ordinance or Law</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="ordinance_or_law"
                                                                       value={props.formIK.values.ordinance_or_law !== undefined ? props.formIK.values.ordinance_or_law : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['ordinance_or_law'] = "Ordinance or Law is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['ordinance_or_law'];
                                                                           }
                                                                           props.formIK.setFieldValue("ordinance_or_law", value)
                                                                       }}
                                                                       options={[{
                                                                           label: 'None',
                                                                           value: 'None'
                                                                       }, {label: '10%', value: '10%'},
                                                                           {label: '25%', value: '25%'}]}/>
                                                    {props.formIK.errors["ordinance_or_law"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["ordinance_or_law"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                    </Col>
                                    <Col>
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.ssiu === true ||
                                                props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) && (
                                                <Form.Group key="identity_theft_protection" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label><strong>Identity Theft Protection</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="identity_theft_protection"
                                                                       value={props.formIK.values.identity_theft_protection !== undefined ? props.formIK.values.identity_theft_protection : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['identity_theft_protection'] = "Identity Theft Protection is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['identity_theft_protection'];
                                                                           }
                                                                           props.formIK.setFieldValue("identity_theft_protection", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    {props.formIK.errors["identity_theft_protection"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["identity_theft_protection"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true || props.formIK.values.sage_sure === true) && (
                                                <Form.Group key="increased_replacement_cost_on_dwelling"
                                                            controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label
                                                        className={props.formIK.values.asi_progressive === true ? "required-label" : ""}><strong>Increased
                                                        Replacement Cost on Dwelling</strong></Form.Label>
                                                    <CustomSelectInput disabled={false}
                                                                       name="increased_replacement_cost_on_dwelling"
                                                                       value={props.formIK.values.increased_replacement_cost_on_dwelling !== undefined ? props.formIK.values.increased_replacement_cost_on_dwelling : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['increased_replacement_cost_on_dwelling'] = "Increased Replacement Cost on Dwelling is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['increased_replacement_cost_on_dwelling'];
                                                                           }
                                                                           props.formIK.setFieldValue("increased_replacement_cost_on_dwelling", value)
                                                                       }}
                                                                       options={[{
                                                                           label: 'None',
                                                                           value: 'None'
                                                                       }, {label: '25% of Cov A', value: '25% of Cov A'},
                                                                           {
                                                                               label: '50% of Cov A',
                                                                               value: '50% of Cov A'
                                                                           }]}/>
                                                    {props.formIK.errors["increased_replacement_cost_on_dwelling"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["increased_replacement_cost_on_dwelling"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) && (
                                                <Form.Group key="personal_injury_coverage" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label><strong>Personal Injury Coverage</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="personal_injury_coverage"
                                                                       value={props.formIK.values.personal_injury_coverage !== undefined ? props.formIK.values.personal_injury_coverage : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['personal_injury_coverage'] = "Personal Injury Coverage is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['personal_injury_coverage'];
                                                                           }
                                                                           props.formIK.setFieldValue("personal_injury_coverage", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    {props.formIK.errors["personal_injury_coverage"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["personal_injury_coverage"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true || props.formIK.values.asi_progressive === true ||
                                                props.formIK.values.swyfft === true || props.formIK.values.sage_sure === true) && (
                                                <>
                                                    <Form.Group key="coverage_c_personal_property_replacement_cost"
                                                                controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Coverage C - Personal
                                                            Property Replacement Cost</strong></Form.Label>
                                                        <CustomSelectInput disabled={false}
                                                                           name="coverage_c_personal_property_replacement_cost"
                                                                           value={props.formIK.values.coverage_c_personal_property_replacement_cost !== undefined ? props.formIK.values.coverage_c_personal_property_replacement_cost : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['coverage_c_personal_property_replacement_cost'] = "Coverage C - Personal Property Replacement Cost is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['coverage_c_personal_property_replacement_cost'];
                                                                               }
                                                                               props.formIK.setFieldValue("coverage_c_personal_property_replacement_cost", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        {props.formIK.errors["coverage_c_personal_property_replacement_cost"] && (
                                                            <div
                                                                className="text-error">{props.formIK.errors["coverage_c_personal_property_replacement_cost"]}</div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group key="equipment_breakdown" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label
                                                            className={props.formIK.values.orion === true ? "required-label" : ""}><strong>Equipment
                                                            Breakdown</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="equipment_breakdown"
                                                                           value={props.formIK.values.equipment_breakdown !== undefined ? props.formIK.values.equipment_breakdown : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['equipment_breakdown'] = "Equipment Breakdown is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['equipment_breakdown'];
                                                                               }
                                                                               props.formIK.setFieldValue("equipment_breakdown", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'None',
                                                                               value: 'None'
                                                                           }, {
                                                                               label: '$25,000/$50,000',
                                                                               value: '$25,000/$50,000'
                                                                           }]}/>
                                                        {props.formIK.errors["equipment_breakdown"] && (
                                                            <div
                                                                className="text-error">{props.formIK.errors["equipment_breakdown"]}</div>
                                                        )}
                                                    </Form.Group>
                                                </>
                                            )
                                        }
                                        {
                                            props.formIK.values.ssiu === true && (
                                                <Form.Group key="flood" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label
                                                        className="required-label"><strong>Flood</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="equipment_breakdown"
                                                                       value={props.formIK.values.flood !== undefined ? props.formIK.values.flood : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['flood'] = "Flood is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['flood'];
                                                                           }
                                                                           props.formIK.setFieldValue("flood", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    {props.formIK.errors["flood"] && (
                                                        <div className="text-error">{props.formIK.errors["flood"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            props.formIK.values.ssiu === true && (
                                                <>
                                                    <Form.Group key="extended_rc_125" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Extended RC
                                                            125%</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="extended_rc_125"
                                                                           value={props.formIK.values.extended_rc_125 !== undefined ? props.formIK.values.extended_rc_125 : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['extended_rc_125'] = "Extended RC 125% is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['extended_rc_125'];
                                                                               }
                                                                               props.formIK.setFieldValue("extended_rc_125", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        {props.formIK.errors["extended_rc_125"] && (
                                                            <div
                                                                className="text-error">{props.formIK.errors["extended_rc_125"]}</div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group key="home_system_protection" controlId="formBasicEmail"
                                                                style={{padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className="required-label"><strong>Home system
                                                            protection</strong></Form.Label>
                                                        <CustomSelectInput disabled={false} name="home_system_protection"
                                                                           value={props.formIK.values.home_system_protection !== undefined ? props.formIK.values.home_system_protection : ""}
                                                                           onChange={(value) => {
                                                                               if (value === "") {
                                                                                   props.formIK.errors['home_system_protection'] = "Home system protection is required.";
                                                                               } else {
                                                                                   delete props.formIK.errors['home_system_protection'];
                                                                               }
                                                                               props.formIK.setFieldValue("home_system_protection", value)
                                                                           }}
                                                                           options={[{
                                                                               label: 'Yes',
                                                                               value: 'Yes'
                                                                           }, {label: 'No', value: 'No'}]}/>
                                                        {props.formIK.errors["home_system_protection"] && (
                                                            <div
                                                                className="text-error">{props.formIK.errors["home_system_protection"]}</div>
                                                        )}
                                                    </Form.Group>
                                                </>
                                            )
                                        }
                                        {
                                            (props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true ||
                                                props.formIK.values.openly === true) && (
                                                <Form.Group key="service_line" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Service
                                                        line</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="service_line"
                                                                       value={props.formIK.values.service_line !== undefined ? props.formIK.values.service_line : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['service_line'] = "Service line is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['service_line'];
                                                                           }
                                                                           props.formIK.setFieldValue("service_line", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    {props.formIK.errors["service_line"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["service_line"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.ssiu === true) && (
                                                <Form.Group key="water_damage_sublimit" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Water damage
                                                        sublimit</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="water_damage_sublimit"
                                                                       value={props.formIK.values.water_damage_sublimit !== undefined ? props.formIK.values.water_damage_sublimit : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['water_damage_sublimit'] = "Water damage sublimit is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['water_damage_sublimit'];
                                                                           }
                                                                           props.formIK.setFieldValue("water_damage_sublimit", value)
                                                                       }}
                                                                       options={[{
                                                                           label: 'None',
                                                                           value: 'None'
                                                                       }, {label: '$10,000', value: '$10,000'},
                                                                           {
                                                                               label: '$25,000',
                                                                               value: '$25,000'
                                                                           }, {label: '$50,000', value: '$50,000'}]}/>
                                                    {props.formIK.errors["water_damage_sublimit"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["water_damage_sublimit"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (props.formIK.values.orion === true) && (
                                                <Form.Group key="fortified_roof_endorsement" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className="required-label"><strong>Fortified Roof
                                                        Endorsement</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="fortified_roof_endorsement"
                                                                       value={props.formIK.values.fortified_roof_endorsement !== undefined ? props.formIK.values.fortified_roof_endorsement : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['fortified_roof_endorsement'] = "Fortified Roof Endorsement is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['fortified_roof_endorsement'];
                                                                           }
                                                                           props.formIK.setFieldValue("fortified_roof_endorsement", value)
                                                                       }}
                                                                       options={[{label: 'Yes', value: 'Yes'}, {
                                                                           label: 'No',
                                                                           value: 'No'
                                                                       }]}/>
                                                    {props.formIK.errors["fortified_roof_endorsement"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["fortified_roof_endorsement"]}</div>
                                                    )}
                                                </Form.Group>
                                            )
                                        }
                                        <Collapsible trigger={["Default Fields", <BsChevronDown/>]}
                                                     triggerStyle={{background: '#e3f2fd', marginTop: "30px"}}>
                                            <>
                                                {
                                                    (props.formIK.values.ssiu === true || props.formIK.values.sage_sure === true ||
                                                        props.formIK.values.openly === true) && (
                                                        <Form.Group key="earthquake" controlId="formBasicEmail"
                                                                    style={{padding: "0px 15px 0px 0px"}}>
                                                            <Form.Label
                                                                className="required-label"><strong>Earthquake</strong></Form.Label>
                                                            <CustomSelectInput disabled={false} name="earthquake"
                                                                               value={props.formIK.values.earthquake !== undefined ? props.formIK.values.earthquake : ""}
                                                                               onChange={(value) => {
                                                                                   if (value === "") {
                                                                                       props.formIK.errors['earthquake'] = "Earthquake is required.";
                                                                                   } else {
                                                                                       delete props.formIK.errors['earthquake'];
                                                                                   }
                                                                                   props.formIK.setFieldValue("earthquake", value)
                                                                               }}
                                                                               options={[{
                                                                                   label: 'Yes',
                                                                                   value: 'Yes'
                                                                               }, {label: 'No', value: 'No'}]}/>
                                                            {props.formIK.errors["earthquake"] && (
                                                                <div
                                                                    className="text-error">{props.formIK.errors["earthquake"]}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                }
                                                {
                                                    props.formIK.values.openly === true && (
                                                        <>
                                                            <Form.Group key="under_construction" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className="required-label"><strong>Under
                                                                    Construction</strong></Form.Label>
                                                                <CustomSelectInput disabled={false}
                                                                                   name="under_construction"
                                                                                   value={props.formIK.values.under_construction !== undefined ? props.formIK.values.under_construction : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['under_construction'] = "Under Construction is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['under_construction'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("under_construction", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: 'Yes',
                                                                                       value: 'Yes'
                                                                                   }, {label: 'No', value: 'No'}]}/>
                                                                {props.formIK.errors["under_construction"] && (
                                                                    <div
                                                                        className="text-error">{props.formIK.errors["under_construction"]}</div>
                                                                )}
                                                            </Form.Group>
                                                            <Form.Group key="personal_cyber" controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className="required-label"><strong>Personal
                                                                    Cyber</strong></Form.Label>
                                                                <CustomSelectInput disabled={false} name="personal_cyber"
                                                                                   value={props.formIK.values.personal_cyber !== undefined ? props.formIK.values.personal_cyber : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['personal_cyber'] = "Personal Cyber is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['personal_cyber'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("personal_cyber", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: 'Yes',
                                                                                       value: 'Yes'
                                                                                   }, {label: 'No', value: 'No'}]}/>
                                                                {props.formIK.errors["personal_cyber"] && (
                                                                    <div
                                                                        className="text-error">{props.formIK.errors["personal_cyber"]}</div>
                                                                )}
                                                            </Form.Group>
                                                            <Form.Group key="home_sharing_participation"
                                                                        controlId="formBasicEmail"
                                                                        style={{padding: "0px 15px 0px 0px"}}>
                                                                <Form.Label className="required-label"><strong>Homesharing
                                                                    Participation</strong></Form.Label>
                                                                <CustomSelectInput disabled={false} name="personal_cyber"
                                                                                   value={props.formIK.values.home_sharing_participation !== undefined ? props.formIK.values.home_sharing_participation : ""}
                                                                                   onChange={(value) => {
                                                                                       if (value === "") {
                                                                                           props.formIK.errors['home_sharing_participation'] = "Homesharing Participation is required.";
                                                                                       } else {
                                                                                           delete props.formIK.errors['home_sharing_participation'];
                                                                                       }
                                                                                       props.formIK.setFieldValue("home_sharing_participation", value)
                                                                                   }}
                                                                                   options={[{
                                                                                       label: 'No',
                                                                                       value: 'No'
                                                                                   }, {
                                                                                       label: 'Yes, less than 10 days per year',
                                                                                       value: 'Yes, less than 10 days per year'
                                                                                   },
                                                                                       {
                                                                                           label: 'Yes, 10-30 days per year',
                                                                                           value: 'Yes, 10-30 days per year'
                                                                                       }, {
                                                                                           label: 'Yes, 31-90 days per year',
                                                                                           value: 'Yes, 31-90 days per year'
                                                                                       },
                                                                                       {
                                                                                           label: 'Yes, 91-180 days per year',
                                                                                           value: 'Yes, 91-180 days per year'
                                                                                       }, {
                                                                                           label: 'Yes, 180+ days per year',
                                                                                           value: 'Yes, 180+ days per year'
                                                                                       }]}/>
                                                                {props.formIK.errors["home_sharing_participation"] && (
                                                                    <div
                                                                        className="text-error">{props.formIK.errors["home_sharing_participation"]}</div>
                                                                )}
                                                            </Form.Group>
                                                        </>
                                                    )
                                                }
                                            </>
                                        </Collapsible>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button onClick={() => props.changeStep(props.progress - props.step)}
                                        disabled={props.progress === 0}
                                        className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                {/*<button onClick={()=>props.formIK.submitForm()}*/}
                                <button onClick={() => validate_data()}
                                        className="btn btn-primary" disabled={props.loading === true}>
                                    Confirm <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

Coverage.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired
};
