import React from 'react';
import CusNavBar from '../../global_components/navbar';
import Footer from '../../global_components/footer';
import Button from "@mui/material/Button";
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router';

const BulkPostingResult = () => {
  let successfulInvoices = [];
  let failedInvoices = [];

  const navigate = useNavigate();

  // Safely parse JSON from localStorage
  try {
    successfulInvoices = JSON.parse(localStorage.getItem('SuccessfulInvoices')) || [];
  } catch (error) {
    console.error('Error parsing successful invoices:', error);
    successfulInvoices = []; // Default to an empty array in case of error
  }

  try {
    failedInvoices = JSON.parse(localStorage.getItem('FailedInvoices')) || [];
  } catch (error) {
    console.error('Error parsing failed invoices:', error);
    failedInvoices = []; // Default to an empty array in case of error
  }

  return (
    <div>
      <CusNavBar />
      <Button
            style={{ backgroundColor: "gray", color:"white", marginTop: "5px", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row"}}
            className={"ms-1"}
            onClick={() => {navigate("/dashboard");}}
        >
            <MdArrowBack /> Go Back to Home
      </Button>
      <div>
        <h1 className='text-2xl font-bold text-center mt-8'>Bulk Posting Result</h1>
      </div>

      <div className='flex flex-wrap justify-center gap-x-10 mt-8 px-4'>
        <div className='w-full md:w-1/2'>
          <h2 className='text-xl font-medoim text-center mb-4'>Successfully sent for <span className='font-bold'>Posting</span></h2>
          <table className='table-auto w-full'>
            <thead className='bg-gray-200'>
              <tr>
                <th className='px-4 py-2'>Seller Name</th>
                <th className='px-4 py-2'>Invoice Number</th>
              </tr>
            </thead>
            <tbody>
              {successfulInvoices.map((invoice, index) => (
                <tr key={index} className='bg-white'>
                  <td className='border px-4 py-2'>{invoice.seller_name}</td>
                  <td className='border px-4 py-2'>{invoice.invoice_number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='w-full md:w-1/2'>
          <h2 className='text-xl font-semibold text-center mb-4'>Posting of these invoices <span className='font-bold'>Failed</span></h2>
          <table className='table-auto w-full'>
            <thead className='bg-gray-200'>
              <tr>
                <th className='px-4 py-2'>Seller Name</th>
                <th className='px-4 py-2'>Invoice Number</th>
              </tr>
            </thead>
            <tbody>
              {failedInvoices.map((invoice, index) => (
                <tr key={index} className='bg-white'>
                  <td className='border px-4 py-2'>{invoice.seller_name}</td>
                  <td className='border px-4 py-2'>{invoice.invoice_number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default BulkPostingResult;
