import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import "../index.css"
import {ProgressBar, Step} from "react-step-progress-bar";
import CusNavBar from "./navbar";
import {Container} from "@mui/material";
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import CarrierSelection from "../components/insurance_portal/carrier_selection";
import {useFormik} from "formik";
import {API_URL, insurance_companies} from "../constants";
import * as Yup from "yup";
import Applicant from "../components/insurance_portal/applicant";
import Property from "../components/insurance_portal/property";
import Coverage from "../components/insurance_portal/coverage";
import LoadingOverlay from 'react-loading-overlay';
import CusAlert from "./alert";
import General from "../components/insurance_portal/general";
import InsuranceAlert from "../components/insurance_portal/insurance_alert"
import Footer from "./footer";
import {useSearchParams} from "react-router-dom";
import {Logout} from "../utils/user_logout";
import {
    removeCompany,
    removeDepartmentApprovementLevel,
    removeDepartments,
    removeSelectedDepartment, removeSelectedPermissionAP,
    removeToken
} from "../store/auth";
import {removeCategories, removeInvoicesDashboard} from "../store/dashboard";
import {removeProcess, setCurrentActive} from "../store/processes";
import {removeAccountNumbers, removeAllInvoices} from "../store/invoices";
import {
    removeAPPermissions, removeBondNumbers,
    removeDepartmentsCM,
    removeLawFirmBranches,
    removeLawFirmContacts,
    removeLawFirmProfessionalGuardians,
    removeLawFirms,
    removeLawSchools,
    removePOPermissions,
    removeProcessPermissions,
    removeSuperPOUsers,
    removeUsers
} from "../store/company_management";
import {removeDocuments} from "../store/document_approval";
import {removeProcessDetails, removeProcessHistory, removeProcessInputs} from "../store/capital_project";
import {
    removeBondProcessDetails,
    removeBondProcessHistory,
    removeBondProcessInputs,
    removeLawFirmData
} from "../store/bond";
import {useSnackbar} from "notistack";
export default function InsurancePortal(props) {
    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const step = 25;
    const [progress, setProgress] = useState(0);
    const companyData = useSelector((state) => state.auth.company);
    const email = useSelector((state) => state.auth.user.email);
    const portalInsuranceCompanyAPIKeys = useSelector((state) => state.auth.company.extra_attrs.hasOwnProperty('portal_insurance_company_api_key') ? state.auth.company.extra_attrs.portal_insurance_company_api_key : {});
    const portalInsuranceVendorCreds = useSelector((state) => state.auth.user.extra_attrs.hasOwnProperty('portal_insurance_vendor_creds') ? state.auth.user.extra_attrs.portal_insurance_vendor_creds : {});
    const [showVendorCreds, setShowVendorCreds] = useState(true);
    const [searchParams] = useSearchParams();
    const existingCustomer = searchParams.get('existingCustomer') === 'true';
    const lead_id = searchParams.get('lead_id');
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    useEffect(() => {
        // setLoading(true);
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        if (!insurance_companies.includes(companyData.name)) {
            navigate("/");
        }


        document.title = "Portal Insurance";


    }, []);


    const getInitialValues = () => {
        let initialValues = {}
        initialValues['orion'] = false;
        initialValues['swyfft'] = false;
        initialValues['asi_progressive'] = false;
        initialValues['ssiu'] = false;
        initialValues['sage_sure'] = false;
        initialValues['openly'] = false;
        initialValues['branch'] = false;
        initialValues['agency_zoom_id'] = lead_id;
        // initialValues['agency_zoom_id'] = (localStorage.getItem('lead_id') !== null && localStorage.getItem('lead_id') !== undefined) ? parseInt(localStorage.getItem('lead_id')) : "";
        initialValues['client_type'] = "Renewal";
        initialValues['leadSourceId'] = "";
        initialValues['first_name'] = "";
        initialValues['middle_name'] = "";
        initialValues['last_name'] = "";
        initialValues['cell_phone'] = "";
        initialValues['date_of_birth'] = "";
        initialValues['gender'] = "";
        initialValues['last_digit_of_ssn'] = "";
        initialValues['city'] = "";
        initialValues['state'] = "";
        initialValues['zip'] = "";
        initialValues['address'] = "";
        initialValues['prior_city'] = "";
        initialValues['prior_state'] = "";
        initialValues['prior_zip'] = "";
        initialValues['prior_address'] = "";
        initialValues['mailing_city'] = "";
        initialValues['mailing_state'] = "";
        initialValues['mailing_zip'] = "";
        initialValues['mailing_address'] = "";
        initialValues['current_address_liv_duration'] = "2 months or less";
        initialValues['insurance_score_estimate'] = "No";
        initialValues['insurance_cancelled_last_3_years'] = "No";
        initialValues['occupation'] = "None";
        initialValues['same_mailing_address'] = "Yes";
        initialValues['marital_status'] = "";
        initialValues['SpouseFirstName'] = "";
        initialValues['SpouseMiddleName'] = "";
        initialValues['SpouseLastName'] = "";
        initialValues['SpouseBirthDate'] = "";
        initialValues['SpouseGender'] = "";
        initialValues['is_new_purchase'] = "No";
        initialValues['purchase_amount'] = "";
        initialValues['prior_liability_limit'] = "$300,000";
        initialValues['purchase_date'] = "";
        initialValues['square_footage'] = "";
        initialValues['year_built'] = "";
        initialValues['fire_hydrant'] = "Yes";
        initialValues['protection_class'] = "";
        initialValues['residence_type'] = "Single Family Home";
        initialValues['number_of_residence'] = "2";
        initialValues['household_occupant_under_21'] = "0";
        initialValues['currently_insured_openly'] = "";
        initialValues['currently_insured_other_carriers'] = "Yes";
        initialValues['construction_type'] = "";
        initialValues['exterior_walls'] = "";
        initialValues['foundation_type'] = "Slab";
        initialValues['basement'] = "No";
        initialValues['occupancy_type'] = "Primary";
        initialValues['roof_material'] = "3 Tab";
        initialValues['roof_shape'] = "Gable";
        initialValues['roof_sheathing'] = "";
        initialValues['roof_straps_type'] = "";
        initialValues['roof_deck_attachment'] = "";
        initialValues['roof_anchorage'] = "";
        initialValues['number_of_stories'] = "1";
        initialValues['garage'] = "2-Car-Attached";
        initialValues['swimming_pool'] = "None";
        initialValues['woodburning_stove'] = "No";
        initialValues['solar_panel'] = "No";
        initialValues['bathroom_grade'] = "Builder's Grade";
        initialValues['number_of_full_bath'] = "2";
        initialValues['number_of_half_bath'] = "0";
        initialValues['kitchen_grade'] = "Builder's Grade";
        initialValues['cooling_air'] = "Central Air Conditioning";
        initialValues['permanent_heat_source'] = "Gas Forced Air";
        initialValues['deck'] = "None";
        initialValues['deck_size'] = "";
        initialValues['year_roof_installed'] = "";
        initialValues['year_wiring_installed'] = "";
        initialValues['year_pluming_installed'] = "";
        initialValues['year_hvac_installed'] = "";
        initialValues['own_dog'] = "No";
        initialValues['ineligible_animal_present'] = "No";
        initialValues['onsite_business'] = "No";
        initialValues['tree_over_structure'] = "No";
        initialValues['window_and_door_opening_protection'] = "No";
        initialValues['burglar_alarm'] = "None";
        initialValues['fire_alarm'] = "None";
        initialValues['tankless_water_heater'] = "No";
        initialValues['sprinkler_system'] = "";
        initialValues['tree_free_yard'] = "No";
        initialValues['roof_damage_exclusion'] = "No";
        initialValues['exclude_wind'] = "No";
        initialValues['hurricane_wind_rated_roof_covering'] = "";
        initialValues['secondary_water_resistance'] = "";
        initialValues['existing_damage'] = "";
        initialValues['loss_settlement_wind_hail_losses_to_roof'] = "Replacement Cost";
        initialValues['residents_smoke'] = "No";
        initialValues['insured_have_umbrella_policy'] = "No";
        initialValues['package_policy'] = "None";
        // initialValues['fortified_for_safer_living'] = "";
        initialValues['water_leak_protection'] = "";
        initialValues['home_update'] = "No";
        initialValues['latest_permit_date'] = "";
        initialValues['losses_bankruptcy_or_foreclosures'] = "No";
        initialValues['flood_insured'] = "No";
        initialValues['undergoing_any_renovations'] = "No";
        initialValues['building_code_discount'] = "None";
        initialValues['secured_community_discount'] = "No";
        // initialValues['service_discount'] = "None";
        initialValues['has_current_auto_policy_with_agency'] = "No";
        initialValues['fire_claims'] = "0 Claim";
        initialValues['property_claims'] = "0 Claim";
        initialValues['liability_claims'] = "0 Claim";
        initialValues['weather_claims'] = "0 Claim";
        initialValues['coverage_a_dwelling'] = "";
        initialValues['all_other_peril_deductible'] = "$1,000";
        initialValues['hurricane_wind_hail_tornado_deductible'] = "3% of Cov A";
        initialValues['coverage_b_other_structures'] = "10% of Cov A";
        initialValues['coverage_c_personal_property'] = "50% of Cov A";
        initialValues['coverage_d_loss_of_use'] = "20% of Cov A";
        initialValues['coverage_e_personal_liability'] = "$500,000";
        initialValues['coverage_f_medical_payments'] = "$2,500";
        initialValues['loss_assessment_coverage'] = "";
        initialValues['special_computer_coverage'] = "";
        initialValues['jewelry_watches_furs_increased_limits'] = "";
        initialValues['silverware_goldware_pewterware_increased_limits'] = "";
        initialValues['limited_fungi_mold_and_bacteria_coverage'] = "$5,000";
        initialValues['water_back_up_sump_discharge_or_overflow'] = "$5,000";
        initialValues['ordinance_or_law'] = "10%";
        initialValues['identity_theft_protection'] = "No";
        initialValues['increased_replacement_cost_on_dwelling'] = "25% of Cov A";
        initialValues['personal_injury_coverage'] = "No";
        initialValues['coverage_c_personal_property_replacement_cost'] = "Yes";
        initialValues['equipment_breakdown'] = "$25,000/$50,000";
        initialValues['flood'] = "No";
        initialValues['earthquake'] = "No";
        initialValues['extended_rc_125'] = "Yes";
        initialValues['home_system_protection'] = "Yes";
        initialValues['service_line'] = "No";
        initialValues['water_damage_sublimit'] = "None";
        initialValues['fortified_roof_endorsement'] = "No";
        initialValues['mathing_of_undamaged_siding_and_or_roofing'] = "";
        initialValues['swimming_pool_hot_tub_liability_coverage'] = "No";
        initialValues['sinkhole_collapse_coverage'] = "";
        initialValues['enhanced_personal_property_coverage'] = "";
        initialValues['screened_enclosures'] = "";
        initialValues['direct_repair'] = "";
        initialValues['hooby_farming'] = "No";
        initialValues['under_construction'] = "No";
        initialValues['personal_cyber'] = "No";
        initialValues['home_sharing_participation'] = "No";
        initialValues['current_auto_limit_bipd'] = "$100K | $300K | $50K";
        initialValues['auto_insurance_in_past_31_days'] = "Yes";
        initialValues['continuous_auto_coverage_years'] = "5";

        return initialValues
    }
    const logout = ()=>{
        enqueueSnackbar('You are logged out. Redirecting to login page in 3 seconds.', {variant: "error"});
        setTimeout(() => {
            localStorage.clear();
            dispatch(removeToken());
            dispatch(removeCompany());
            dispatch(removeInvoicesDashboard());
            dispatch(removeCategories());
            dispatch(removeProcess());
            dispatch(setCurrentActive());
            dispatch(removeAllInvoices());
            dispatch(removeAccountNumbers());
            dispatch(removeDepartments());
            dispatch(removeSelectedDepartment());
            dispatch(removeDepartmentApprovementLevel());
            dispatch(removeSelectedPermissionAP());
            dispatch(removeUsers());
            dispatch(removeDepartmentsCM());
            dispatch(removeAPPermissions());
            dispatch(removePOPermissions());
            dispatch(removeSuperPOUsers());
            dispatch(removeProcessPermissions());
            dispatch(removeDocuments());
            dispatch(removeProcessHistory());
            dispatch(removeProcessDetails());
            dispatch(removeProcessInputs());
            dispatch(removeBondProcessInputs());
            dispatch(removeBondProcessDetails());
            dispatch(removeBondProcessHistory());
            dispatch(removeLawFirmData());
            dispatch(removeLawFirms());
            dispatch(removeLawFirmContacts());
            dispatch(removeLawFirmBranches());
            dispatch(removeLawSchools());
            dispatch(removeLawFirmProfessionalGuardians());
            dispatch(removeBondNumbers());
            navigate("/");

        }, 3000);
    }

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            // user credential control
            console.log("userdata",localStorage.getItem('userdata'));
            if (!localStorage.getItem('userdata')) {
                logout();
                return;
            }


            setLoadingText('Updating Data...');
            setLoading(true);
            setSubmitting(true);
            let data = {}
            let [orion_cred, asi_progressive_cred, swyfft_cred, ssiu_cred, sage_sure_cred, openly_cred, agency_zoom_cred, branch_cred] = [{}, {}, {}, {}, {}, {}, {}, {}];

            // Vendor Credential
            if (Object.keys(portalInsuranceVendorCreds).length > 0) {
                if (portalInsuranceVendorCreds.hasOwnProperty('orion')) {
                    orion_cred = {...portalInsuranceVendorCreds['orion']};
                }
                if (portalInsuranceVendorCreds.hasOwnProperty('asi_progressive')) {
                    asi_progressive_cred = {...portalInsuranceVendorCreds['asi_progressive']};
                }
                if (portalInsuranceVendorCreds.hasOwnProperty('swyfft')) {
                    swyfft_cred = {...portalInsuranceVendorCreds['swyfft']};
                }
                if (portalInsuranceVendorCreds.hasOwnProperty('ssiu')) {
                    ssiu_cred = {...portalInsuranceVendorCreds['ssiu']};
                }
                if (portalInsuranceVendorCreds.hasOwnProperty('sage_sure')) {
                    sage_sure_cred = {...portalInsuranceVendorCreds['sage_sure']}
                }
                if (portalInsuranceVendorCreds.hasOwnProperty('openly')) {
                    openly_cred = {...portalInsuranceVendorCreds['openly']};
                }
                if (portalInsuranceVendorCreds.hasOwnProperty('agency_zoom')) {
                    agency_zoom_cred = {...portalInsuranceVendorCreds['agency_zoom']};
                }
                if (portalInsuranceVendorCreds.hasOwnProperty('branch')) {
                    branch_cred = {...portalInsuranceVendorCreds['branch']};
                }
            }

            // API KEY
            if (Object.keys(portalInsuranceCompanyAPIKeys).length > 0) {
                if (portalInsuranceCompanyAPIKeys.hasOwnProperty('orion')) {
                    orion_cred = {...portalInsuranceCompanyAPIKeys['orion'], ...orion_cred};
                }
                if (portalInsuranceCompanyAPIKeys.hasOwnProperty('asi_progressive')) {
                    asi_progressive_cred = {...portalInsuranceCompanyAPIKeys['asi_progressive'], ...asi_progressive_cred};
                }
                if (portalInsuranceCompanyAPIKeys.hasOwnProperty('swyfft')) {
                    swyfft_cred = {...portalInsuranceCompanyAPIKeys['swyfft'], ...swyfft_cred};
                }
                if (portalInsuranceCompanyAPIKeys.hasOwnProperty('ssiu')) {
                    ssiu_cred = {...portalInsuranceCompanyAPIKeys['ssiu'], ...ssiu_cred};
                }
                if (portalInsuranceCompanyAPIKeys.hasOwnProperty('sage_sure')) {
                    sage_sure_cred = {...portalInsuranceCompanyAPIKeys['sage_sure'], ...sage_sure_cred};
                }
                if (portalInsuranceCompanyAPIKeys.hasOwnProperty('openly')) {
                    openly_cred = {...portalInsuranceCompanyAPIKeys['openly'], ...openly_cred};
                }
                if (portalInsuranceCompanyAPIKeys.hasOwnProperty('agency_zoom')) {
                    agency_zoom_cred = {...portalInsuranceCompanyAPIKeys['agency_zoom'], ...agency_zoom_cred};
                }
                if (portalInsuranceCompanyAPIKeys.hasOwnProperty('branch')) {
                    branch_cred = {...portalInsuranceCompanyAPIKeys['branch'], ...branch_cred};
                }
            }


            data['User'] = {
                'company': companyData.name,
                'email': email
            }
            data["Raters"] = {
                "ORION": {
                    "GetQuote": values['orion'],
                    "Credentials": {...orion_cred}
                },
                "ASI PROGRESSIVE": {
                    "GetQuote": values['asi_progressive'],
                    "Credentials": {...asi_progressive_cred}
                },
                "SWYFFT": {
                    "GetQuote": values['swyfft'],
                    "Credentials": {...swyfft_cred}
                },
                "SSIU": {
                    "GetQuote": values['ssiu'],
                    "Credentials": {...ssiu_cred}
                },
                "SAGESURE": {
                    "GetQuote": values['sage_sure'],
                    "Credentials": {...sage_sure_cred}
                },
                "OPENLY": {
                    "GetQuote": values['openly'],
                    "Credentials": {...openly_cred}
                },
                "BRANCH": {
                    "GetQuote": values['branch'],
                    "Credentials": {...branch_cred}
                }
            }
            data['CRM'] = {
                "Credentials": {...agency_zoom_cred},
                "ClientType": values['client_type'],
                "LeadSourceId": values['client_type'] === "New Business" ? values['leadSourceId'] : null,
                "AgencyZoomID": values['client_type'] === "Renewal" ? parseInt(values['agency_zoom_id']) : null,
                "ExistingCustomer": existingCustomer,
            }
            data['Applicant'] = {
                "FirstName": values['first_name'],
                "MiddleName": values['middle_name'],
                "LastName": values['last_name'],
                "BirthDate": values['date_of_birth'],
                "Gender": values['gender'],
                "MaritalStatus": values['marital_status'],
                "AddressLine": values['address'],
                "State": values['state'],
                "City": values['city'],
                "Zip": values['zip'],
                "MailingAddressLine": values['mailing_address'],
                "MailingState": values['mailing_state'],
                "MailingCity": values['mailing_city'],
                "MailingZip": values['mailing_zip'],
                "PriorAddressLine": values['prior_address'],
                "PriorState": values['prior_state'],
                "PriorCity": values['prior_city'],
                "PriorZip": values['prior_zip'],
                "HowLongLivedCurrentAddress": values['current_address_liv_duration'],
                "CellPhone": values['cell_phone'],
                "Last4DigitsOfSSN": values['last_digit_of_ssn'],
                "InsuranceScoreEstimate": values['insurance_score_estimate'],
                "InsuranceCancelledLast3Years": values['insurance_cancelled_last_3_years'],
                "PropertySameAsMailingAddress": values['same_mailing_address'],
                "Occupation": values['occupation'],
                "SpouseFirstName": values['SpouseFirstName'],
                "SpouseMiddleName": values['SpouseMiddleName'],
                "SpouseLastName": values['SpouseLastName'],
                "SpouseBirthDate": values['SpouseBirthDate'],
                "SpouseGender": values['SpouseGender'],
                "CurrentAutoLimitBIPD": values['current_auto_limit_bipd'],
                "AutoInsuranceInPast31Days": values['auto_insurance_in_past_31_days'],
                "ContinuousAutoCoverageYears": values['continuous_auto_coverage_years'],
            }
            data['Property'] = {
                "NewPurchase": values['is_new_purchase'],
                "PurchaseAmount": values['purchase_amount'],
                "PriorLiabilityLimit": values['prior_liability_limit'],
                "PurchaseDate": values['purchase_date'],
                "SquareFootage": parseInt(values['square_footage']),
                "YearBuilt": parseInt(values['year_built']),
                "FireHydrantWithin1000Feet": values['fire_hydrant'],
                "ProtectionClass": parseInt(values['protection_class']),
                "ResidenceType": values['residence_type'],
                "NumberOfHouseholdResidents": values['number_of_residence'],
                "HouseholdOccupantsUnderAgeOf21": values['household_occupant_under_21'],
                "CurrentlyInsuredOpenly": values['currently_insured_openly'],
                "CurrentlyInsuredOtherCarriers": values['currently_insured_other_carriers'],
                "ConstructionType": values['construction_type'],
                "ExteriorWalls": values['exterior_walls'],
                "FoundationType": values['foundation_type'],
                "Basement": values['basement'],
                "OccupancyType": values['occupancy_type'],
                "RoofMaterial": values['roof_material'],
                "RoofShape": values['roof_shape'],
                "RoofSheathing": values['roof_sheathing'],
                "RoofStrapsType": values['roof_straps_type'],
                "RoofDeckAttachment": values['roof_deck_attachment'],
                "RoofAnchorage": values['roof_anchorage'],
                "NumberOfStories": values['number_of_stories'],
                "Garage": values['garage'],
                "SwimmingPool": values['swimming_pool'],
                "WoodburningStove": values['woodburning_stove'],
                "SolarPanels": values['solar_panel'],
                "BathroomGrade": values['bathroom_grade'],
                "NumberOfFullBaths": values['number_of_full_bath'],
                "NumberOfHalfBath": values['number_of_half_bath'],
                "KitchenGrade": values['kitchen_grade'],
                "Cooling/Air": values['cooling_air'],
                "PermanentHeatSource": values['permanent_heat_source'],
                "Deck": values['deck'],
                "Deck (sq. ft.)": values['deck_size'] !== "" ? parseInt(values['deck_size']) : "",
                "YearRoofInstalled": values['year_roof_installed'] !== "" ? parseInt(values['year_roof_installed']) : "",
                "YearWiringInstalled": values['year_wiring_installed'] !== "" ? parseInt(values['year_wiring_installed']) : "",
                "YearPlumingInstalled": values['year_pluming_installed'] !== "" ? parseInt(values['year_pluming_installed']) : "",
                "YearHVACInstalled": values['year_hvac_installed'] !== "" ? parseInt(values['year_hvac_installed']) : "",
                "OwnDog": values['own_dog'],
                "IneligibleAnimalPresent": values['ineligible_animal_present'],
                "OnSiteBusiness": values['onsite_business'],
                "TreeOverStructure": values['tree_over_structure']
            }
            data['Discounts'] = {
                "WindowDoorOpeningProtection": values['window_and_door_opening_protection'],
                "BurglarAlarm": values['burglar_alarm'],
                "FireAlarm": values['fire_alarm'],
                "TanklessWaterHeater": values['tankless_water_heater'],
                "SprinklerSystem": values['sprinkler_system'],
                "TreeFreeYard": values['tree_free_yard'],
                "RoofDamageExclusion": values['roof_damage_exclusion'],
                "ExcludeWind": values['exclude_wind'],
                "HurricaneWindRatedRoofCovering": values['hurricane_wind_rated_roof_covering'],
                "SecondaryWaterResistance": values['secondary_water_resistance'],
                "ExistingDamage": values['existing_damage'],
                "WindHailLossesToRoof": values['loss_settlement_wind_hail_losses_to_roof'],
                "AnyResidentsSmoke": values['residents_smoke'],
                "InsuredHaveUmbrellaPolicy": values['insured_have_umbrella_policy'],
                "PackagePolicy": values['package_policy'],
                // "FortifiedForSaferLiving": values['fortified_for_safer_living'],
                "WaterLeakProtection": values['water_leak_protection'],
                "HomeUpdate": values['home_update'],
                "LatestPermitDate": values['latest_permit_date'],
                "LossesBankruptcyOrForeclosures": values['losses_bankruptcy_or_foreclosures'],
                "FloodInsured": values['flood_insured'],
                "UndergoingAnyRenovations": values['undergoing_any_renovations'],
                "BuildingCodeDiscount": values['building_code_discount'],
                "SecuredCommunityDiscount": values['secured_community_discount'],
                // "ServiceDiscount": values['service_discount'],
                "AutoPolicyWithAgency": values['has_current_auto_policy_with_agency']
            }
            data['Claims'] = {
                "FireClaims": values['fire_claims'],
                "PropertyClaims": values['property_claims'],
                "LiabilityClaims": values['liability_claims'],
                "WeatherClaims": values['weather_claims']
            }
            data['Coverages'] = {
                "CoverageADwelling": parseInt(values['coverage_a_dwelling']),
                "AllOtherPerilDeductible": values['all_other_peril_deductible'],
                "WindHailDeductible": values['hurricane_wind_hail_tornado_deductible'],
                "CoverageBOtherStructures": values['coverage_b_other_structures'],
                "CoverageCPersonalProperty": values['coverage_c_personal_property'],
                "CoverageDLossOfUse": values['coverage_d_loss_of_use'],
                "CoverageEPersonalLiability": values['coverage_e_personal_liability'],
                "CoverageFMedicalPayments": values['coverage_f_medical_payments'],
                "LossAssessmentCoverage": values['loss_assessment_coverage'],
                "SpecialComputerCoverage": values['special_computer_coverage'],
                "JewelryWatchesFursIncreasedLimits": values['jewelry_watches_furs_increased_limits'],
                "SilverwareGoldwarePewterwareIncreasedLimits": values['silverware_goldware_pewterware_increased_limits'],
                "LimitedFungiMoldAndBacteriaCoverage": values['limited_fungi_mold_and_bacteria_coverage'],
                "WaterBackUp": values['water_back_up_sump_discharge_or_overflow'],
                "OrdinanceOrLaw": values['ordinance_or_law'],
                "IdentityTheftProtection": values['identity_theft_protection'],
                "IncreasedReplacementCost": values['increased_replacement_cost_on_dwelling'],
                "PersonalInjuryCoverage": values['personal_injury_coverage'],
                "CoverageCPersonalPropertyReplacementCost": values['coverage_c_personal_property_replacement_cost'],
                "EquipmentBreakdown": values['equipment_breakdown'],
                "Flood": values['flood'],
                "Earthquake": values['earthquake'],
                "ExtendedRC125": values['extended_rc_125'],
                "HomeSystemProtection": values['home_system_protection'],
                "ServiceLine": values['service_line'],
                "WaterDamageSublimit": values['water_damage_sublimit'],
                "FortifiedRoofEndorsement": values['fortified_roof_endorsement'],
                "MathingOfUndamagedSidingAndOrRoofing": values['mathing_of_undamaged_siding_and_or_roofing'],
                "SwimmingPoolHotTubLiabilityCoverage": values['swimming_pool_hot_tub_liability_coverage'],
                "SinkholeCollapseCoverage": values['sinkhole_collapse_coverage'],
                "EnhancedPersonalPropertyCoverage": values['enhanced_personal_property_coverage'],
                "ScreenedEnclosures": values['screened_enclosures'],
                "DirectRepair": values['direct_repair'],
                "HoobyFarming": values['hooby_farming'],
                "UnderConstruction": values['under_construction'],
                "PersonalCyber": values['personal_cyber'],
                "HomesharingParticipation": values['home_sharing_participation']
            }
            // const element = document.createElement("a");
            // const textFile = new Blob([JSON.stringify(data)], {type: 'text/plain'});
            // element.href = URL.createObjectURL(textFile);
            // element.download = "userFile.txt";
            // document.body.appendChild(element);
            // element.click();
            const token = localStorage.getItem('userdata');
            const response = await fetch(`${API_URL}/portal_insurance/start_process/`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                resetForm({values: ''});
                setProgress(0);
                setShowSuccess(true);
            }
            setSubmitting(false);
            setLoading(false);
        },
        validationSchema: Yup.object()
    });

    return (
        <> <LoadingOverlay
            active={loading}
            spinner
            text={loadingText}
        >
            <div>
                <CusNavBar/>
                {
                    showSuccess && <CusAlert alertType='success'
                                             alertMessage="Process triggered successfully. You'll receive email with quotes shortly."
                                             alertClose={setShowSuccess}/>
                }


                <Container style={{marginTop: "6%"}}>
                    <Col>
                        <Row>
                            <ProgressBar percent={progress}
                                         filledBackground="linear-gradient(to right, #e3f2fd, #7879FF)">
                                <Step transition="scale">
                                    {({accomplished, index}) => (
                                        <div
                                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                        >
                                            {index + 1}<span
                                            className="accomplished-span"><strong>Carriers</strong></span>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({accomplished, index}) => (
                                        <div
                                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                        >
                                            {index + 1}<span
                                            className="accomplished-span"><strong>General</strong></span>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({accomplished, index}) => (
                                        <div
                                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                        >
                                            {index + 1}<span
                                            className="accomplished-span"><strong>Applicant</strong></span>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({accomplished, index}) => (
                                        <div
                                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                        >
                                            {index + 1}<span
                                            className="accomplished-span"><strong>Property</strong></span>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({accomplished, index}) => (
                                        <div
                                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                        >
                                            {index + 1}<span
                                            className="accomplished-span"><strong>Coverage</strong></span>
                                        </div>
                                    )}
                                </Step>
                                {/*<Step transition="scale">*/}
                                {/*    {({ accomplished, index }) => (*/}
                                {/*        <div*/}
                                {/*            className={`indexedStep ${accomplished ? "accomplished" : null}`}*/}
                                {/*        >*/}
                                {/*            {index + 1}<span className="accomplished-span"><strong>Policy</strong></span>*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {/*</Step>*/}
                            </ProgressBar>
                        </Row>
                        <Row style={{marginTop: "2%"}}>
                            {showVendorCreds &&
                                <InsuranceAlert show={true} isPopup={true} closePopup={setShowVendorCreds}/>}
                            <Form onSubmit={formIk.handleSubmit}>
                                {
                                    (() => {
                                        switch (progress) {
                                            case 0:
                                                return (
                                                    <CarrierSelection formIK={formIk} changeStep={setProgress}
                                                                      step={step}
                                                                      progress={progress} setLoading={setLoading}
                                                                      setLoadingText={setLoadingText}/>
                                                )
                                            case 25:
                                                return (
                                                    <General formIK={formIk} changeStep={setProgress} step={step}
                                                             progress={progress} setLoading={setLoading}
                                                             setLoadingText={setLoadingText}/>
                                                )
                                            case 50:
                                                return (
                                                    <Applicant formIK={formIk} changeStep={setProgress} step={step}
                                                               progress={progress}/>
                                                )
                                            case 75:
                                                return (
                                                    <Property formIK={formIk} changeStep={setProgress} step={step}
                                                              progress={progress}/>
                                                )
                                            case 100:
                                                return (
                                                    <Coverage formIK={formIk} changeStep={setProgress} step={step}
                                                              progress={progress} loading={loading}/>
                                                )
                                            // case 100:
                                            //     return (
                                            //         <Policy formIK={formIk} changeStep={setProgress} step={step}
                                            //                 progress={progress} />
                                            //     )
                                        }
                                    })()
                                }
                            </Form>
                        </Row>
                    </Col>
                </Container>
            </div>
        </LoadingOverlay>
        </>
    )
}
