import { observer } from "mobx-react-lite";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./app_header.module.scss";
import ApplyChanges from "../../shared/apply_changes";
import { invoiceStore } from "../../store/invoice";

const AppHeader = () => {
  return (
    <div className={styles.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "40px" }}>
        <Link to="invoices">invoices</Link>;
      </Breadcrumbs>
      {invoiceStore.fieldsСhanged && (
        <ApplyChanges
          isApplay={invoiceStore.fileldsSaved}
          applay={invoiceStore.patchInputsValues}
        />
      )}
    </div>
  );
};

export default observer(AppHeader);
