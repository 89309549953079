import React, {useEffect, useState} from 'react';
import AddEditUserComp from "../../../vermost_components/add_edit_user";
import Header from "../../../../../global_components/header";
import {CircularProgress, Card} from "@mui/material";
import {addLawFirm, addLawFirms} from "../../../../../store/company_management";
import {useDispatch} from "react-redux";
import AddLawFirmPopup from "../../../vermost_popups/add_law_firm_popup";
import {API_URL, email_url} from "../../../../../constants";
import {useSnackbar} from "notistack";
import {Row, Col} from "react-bootstrap";
import CusNavBar from "../../../../../global_components/navbar";
import {useNavigate} from "react-router";
import LoadingOverlay from 'react-loading-overlay';
import {log} from "../../../../../utils/logger";

function UserRegistration(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = React.useState(true);
    const [showSearchLawFirm, setShowSearchLawFirm] = useState(false);
    const [tempCreatedLawFirm, setTempCreatedLawFirm] = useState(0);
    const [overlayLoading, setOverlayLoading] = useState(false);
    const [overlayMessage, setOverlayMessage] = useState('');
    const ActionType = {
        insert: 'insert',
        create_law_firm: 'create_law_firm'
    }

    const loadAllLawFirmList = async () => {
        fetch(`${API_URL}/bond/vermost_law_firms/?&page=&size=&filter=&sort=&is_new_control=true`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (res) => {
            if (res.status === 200) {
                const result = await res.json();
                dispatch(addLawFirms(result.data));
            } else {
                const result = await res.json();
                enqueueSnackbar(result, {variant: "error"});
            }
            setOverlayLoading(false);
        }).catch((err) => {
            console.log(err);
            setOverlayLoading(false);
        });
    }

    const onActionCallbackLawFirms = async (actionType, obj, id) => {
        let response;
        switch (actionType) {
            case ActionType.create_law_firm:
                let formData = new FormData();
                Object.keys(obj).forEach((key) => {
                    if (key !== 'locations') {
                        formData.append(key, obj[key]);
                    } else {
                        let locationsString = JSON.stringify(obj["locations"]);
                        formData.append("locations", locationsString);
                    }
                });
                fetch(`${API_URL}/bond/vermost_law_firms/?type=register`, {
                    method: "POST",
                    body: formData,
                    headers: {},
                }).then(async (res) => {
                    if (res.status === 201) {
                        const result = await res.json();
                        console.log("result", result)
                        dispatch(addLawFirm(result.data));
                        setTempCreatedLawFirm(result.data.id);
                        setShowSearchLawFirm(false);
                    } else {
                        const result = await res.json();
                        enqueueSnackbar(result, {variant: "error"});
                        setShowSearchLawFirm(false);
                    }
                }).catch((err) => {
                    console.log(err);
                });
                break;
        }
    }

    const onCallbackUser = async (actionType, values) => {
        log("values", values)
        log("actionType", values)
        switch (actionType) {

            case ActionType.insert:
                if (values.selectedLawFirm.law_firm === undefined || values.selectedLawFirm.law_firm === null || values.selectedLawFirm.location === undefined || values.selectedLawFirm.location === null) {
                    break;
                }
                fetch(`${API_URL}/bond/register_user/`, {
                    method: "POST",
                    body: JSON.stringify({
                        ...values
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(async (res) => {
                    if (res.status === 200) {
                        const email_data = {
                            emailBody: "<p>Hi,</p><p>You have successfully registered with BlueOcean portal for Vermost.</p><p>After your registration is approved by Vermost Admin, you can click link to login with the registered email:</p><a href='https://portal.blueoceansp.com/'>https://portal.blueoceansp.com/</a><p>Regards,</p><p>Blue Ocean Team.</p>",
                            emailSubject: "Registration successfully completed.",
                            emailStyle: null,
                            recipientsTo: `${values.email};`,
                            recipientsCC: null,
                            recipientsBCC: null,
                            attachments: null
                        }
                        await fetch(`${email_url}`, {
                            method: "POST",
                            body: JSON.stringify(email_data),
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });
                        enqueueSnackbar("Success: A mail sent to Vermost Admin to approve.(Going to login page in 5 seconds)", {variant: "success"});
                        setTimeout(function () {
                            navigate('/');
                        }, 5000);
                    } else {
                        const result = await res.json();
                        enqueueSnackbar(result, {variant: "error"});
                    }
                }).catch((err) => {
                    enqueueSnackbar(err, {variant: "error"});
                });
                break;
        }

    }

    useEffect(async () => {
        setOverlayMessage('Fetching Law Firms.');
        setOverlayLoading(true);
        await loadAllLawFirmList();
        setLoading(false);
    }, []);

    return (
        <LoadingOverlay
            active={overlayLoading}
            spinner
            text={overlayMessage}
        >
            <CusNavBar/>
            <Header text={"Register"}></Header>

            <div style={{height: "1000px", marginTop: "2%"}}>
                <Col>
                    <Row style={{justifyContent: "center"}}>
                        <Card>
                            <AddEditUserComp data={{}}
                                             id={"0"}
                                             type={"register"}
                                             oncallback={onCallbackUser}
                                             tempCreatedLawFirm={tempCreatedLawFirm}
                                             showCreateLawFirmPopup={setShowSearchLawFirm}></AddEditUserComp>
                            {
                                showSearchLawFirm &&
                                <AddLawFirmPopup show={true} closePopup={setShowSearchLawFirm}
                                                 oncallback={onActionCallbackLawFirms}
                                                 userEditPage={true}
                                                 callback={{}}
                                                 id={0}/>
                            }
                        </Card>
                    </Row>
                </Col>
            </div>
        </LoadingOverlay>
    )
}


export default UserRegistration;
