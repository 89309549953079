import React, {useEffect, useState} from 'react';
import {API_URL} from '../../constants';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import {Card, CardContent, Container,} from "@mui/material";
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {ArrowLeft, ArrowRight} from "react-bootstrap-icons";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomTextInput from "../../global_components/inputs/text";
import CustomDatePicker from "../../global_components/custom_date_picker";
import {formatDateMDY} from "../../services/helper";
import CustomLongInput from "../../global_components/inputs/long-text";
import ZipCode from "../../global_components/inputs/zip_code";
import CustomNumberInput from "../../global_components/inputs/number";
import CityState from "city-and-state";
import Collapsible from 'react-collapsible';
import {BsChevronDown} from "react-icons/bs";
import "../../styles/main.css";
import GoogleAutoComplete from "../../global_components/inputs/google-auto-complete";
import CellPhone from "../../global_components/inputs/cell_phone";
import DateNormalInput from "../../global_components/inputs/date-normal-input";
import "../../index.css";
import {Logout} from "../../utils/user_logout"
import {useSnackbar} from "notistack";
import {
    removeCompany,
    removeDepartmentApprovementLevel,
    removeDepartments,
    removeSelectedDepartment, removeSelectedPermissionAP,
    removeToken
} from "../../store/auth";
import {removeCategories, removeInvoicesDashboard} from "../../store/dashboard";
import {removeProcess, setCurrentActive} from "../../store/processes";
import {removeAccountNumbers, removeAllInvoices} from "../../store/invoices";
import {
    removeAPPermissions, removeBondNumbers,
    removeDepartmentsCM,
    removeLawFirmBranches,
    removeLawFirmContacts,
    removeLawFirmProfessionalGuardians,
    removeLawFirms,
    removeLawSchools,
    removePOPermissions,
    removeProcessPermissions,
    removeSuperPOUsers,
    removeUsers
} from "../../store/company_management";
import {removeDocuments} from "../../store/document_approval";
import {removeProcessDetails, removeProcessHistory, removeProcessInputs} from "../../store/capital_project";
import {
    removeBondProcessDetails,
    removeBondProcessHistory,
    removeBondProcessInputs,
    removeLawFirmData
} from "../../store/bond";
import {useNavigate} from "react-router";


export default function General(props) {
    const [refreshAddress, setRefreshAddress] = useState(false);
    const [refreshPriorAddress, setRefreshPriorAddress] = useState(false);
    const cityState = new CityState();
    const companyData = useSelector((state) => state.auth.company);
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let prePopulated = [];
    let agencyZoomPrePopulated = JSON.parse(localStorage.getItem('agencyZoomPrePopulated'));
    useEffect(() => {

    });
    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };
    const addressAutoFillCallBack = async (address, city, state, zip_code) => {
        console.log(address, city, state, zip_code);
        await props.formIK.setFieldValue('state', state);
        await props.formIK.setFieldValue('address', address);
        await props.formIK.setFieldValue('zip', zip_code);
        await props.formIK.setFieldValue('city', city);
        console.log(refreshAddress);
        setRefreshAddress(!refreshAddress);
        await props.formIK.setFieldValue('prior_state', state);
        await props.formIK.setFieldValue('prior_address', address);
        await props.formIK.setFieldValue('prior_zip', zip_code);
        await props.formIK.setFieldValue('prior_city', city);
        setRefreshPriorAddress(!refreshPriorAddress);
        if (props.formIK.errors.hasOwnProperty('address')) {
            delete props.formIK.errors['address'];
        }
        if (props.formIK.errors.hasOwnProperty('state')) {
            delete props.formIK.errors['state'];
        }
        if (props.formIK.errors.hasOwnProperty('city')) {
            delete props.formIK.errors['city'];
        }
        if (props.formIK.errors.hasOwnProperty('zip')) {
            delete props.formIK.errors['zip'];
        }
        if (props.formIK.errors.hasOwnProperty('prior_address')) {
            delete props.formIK.errors['prior_address'];
        }
        if (props.formIK.errors.hasOwnProperty('prior_city')) {
            delete props.formIK.errors['prior_city'];
        }
        if (props.formIK.errors.hasOwnProperty('prior_state')) {
            delete props.formIK.errors['prior_state'];
        }
        if (props.formIK.errors.hasOwnProperty('prior_zip')) {
            delete props.formIK.errors['prior_zip'];
        }
    }

    const priorAddressAutoFillCallBack = async (address, city, state, zip_code) => {
        console.log(address, city, state, zip_code);
        await props.formIK.setFieldValue('prior_state', state);
        await props.formIK.setFieldValue('prior_address', address);
        await props.formIK.setFieldValue('prior_zip', zip_code);
        await props.formIK.setFieldValue('prior_city', city);
        setRefreshPriorAddress(!refreshPriorAddress);
        if (props.formIK.errors.hasOwnProperty('prior_address')) {
            delete props.formIK.errors['prior_address'];
        }
        if (props.formIK.errors.hasOwnProperty('prior_city')) {
            delete props.formIK.errors['prior_city'];
        }
        if (props.formIK.errors.hasOwnProperty('prior_state')) {
            delete props.formIK.errors['prior_state'];
        }
        if (props.formIK.errors.hasOwnProperty('prior_zip')) {
            delete props.formIK.errors['prior_zip'];
        }
    }

    const logout = ()=>{
        enqueueSnackbar('You are logged out. Redirecting to login page in 3 seconds.', {variant: "error"});
        setTimeout(() => {
            localStorage.clear();
            dispatch(removeToken());
            dispatch(removeCompany());
            dispatch(removeInvoicesDashboard());
            dispatch(removeCategories());
            dispatch(removeProcess());
            dispatch(setCurrentActive());
            dispatch(removeAllInvoices());
            dispatch(removeAccountNumbers());
            dispatch(removeDepartments());
            dispatch(removeSelectedDepartment());
            dispatch(removeDepartmentApprovementLevel());
            dispatch(removeSelectedPermissionAP());
            dispatch(removeUsers());
            dispatch(removeDepartmentsCM());
            dispatch(removeAPPermissions());
            dispatch(removePOPermissions());
            dispatch(removeSuperPOUsers());
            dispatch(removeProcessPermissions());
            dispatch(removeDocuments());
            dispatch(removeProcessHistory());
            dispatch(removeProcessDetails());
            dispatch(removeProcessInputs());
            dispatch(removeBondProcessInputs());
            dispatch(removeBondProcessDetails());
            dispatch(removeBondProcessHistory());
            dispatch(removeLawFirmData());
            dispatch(removeLawFirms());
            dispatch(removeLawFirmContacts());
            dispatch(removeLawFirmBranches());
            dispatch(removeLawSchools());
            dispatch(removeLawFirmProfessionalGuardians());
            dispatch(removeBondNumbers());
            navigate("/");

        }, 3000);
    }

    const validate_data = async () => {
        if (!localStorage.getItem('userdata')) {
            logout();
            return;

        }
        let error = false;
        if (props.formIK.values.first_name === "") {
            props.formIK.errors['first_name'] = 'First name is required.';
            error = true;
        }
        if (props.formIK.values.last_name === "") {
            props.formIK.errors['last_name'] = 'Last name is required.';
            error = true;
        }
        if (props.formIK.values.date_of_birth === "" ||  props.formIK.values.date_of_birth === "NaN-NaN-NaN" || new Date(props.formIK.values.date_of_birth).toString() === 'Invalid Date') {
            props.formIK.errors['date_of_birth'] = 'Date of birth is required.';
            error = true;
        } else if (new Date(props.formIK.values.date_of_birth) >= new Date()) {
            props.formIK.errors['date_of_birth'] = 'Date of birth cannot be today or in future.';
            error = true;
        }
        if (props.formIK.values.address === "") {
            props.formIK.errors['address'] = 'Address is required.';
            error = true;
        }
        if (props.formIK.values.state === "") {
            props.formIK.errors['state'] = 'State is required.';
            error = true;
        }
        if (props.formIK.values.city === "") {
            props.formIK.errors['city'] = 'City is required.';
            error = true;
        }
        if (props.formIK.values.zip === "") {
            props.formIK.errors['zip'] = 'Zip Code is required.';
            error = true;
        }
        if (props.formIK.values.prior_address === "") {
            props.formIK.errors['prior_address'] = 'Prior Address is required.';
            error = true;
        }
        if (props.formIK.values.prior_city === "") {
            props.formIK.errors['prior_city'] = 'Prior City is required.';
            error = true;
        }
        if (props.formIK.values.prior_state === "") {
            props.formIK.errors['prior_state'] = 'Prior State is required.';
            error = true;
        }
        if (props.formIK.values.prior_zip === "") {
            props.formIK.errors['prior_zip'] = 'Prior Zip is required.';
            error = true;
        }
        if (props.formIK.values.current_auto_limit_bipd === "") {
            props.formIK.errors['current_auto_limit_bipd'] = 'Current Auto Limit BIPD is required.';
            error = true;
        }
        if (props.formIK.values.auto_insurance_in_past_31_days === "") {
            props.formIK.errors['auto_insurance_in_past_31_days'] = 'Auto Insurance In Past 31 Days is required.';
            error = true;
        }
        if (props.formIK.values.continuous_auto_coverage_years === "") {
            props.formIK.errors['continuous_auto_coverage_years'] = 'Continuous Auto Coverage Years is required.';
            error = true;
        }
        if (error === true) {
            window.scroll(0, 0);
            console.log(props.formIK.errors)
        } else {
            const data = {
                first_name: props.formIK.values.first_name,
                last_name: props.formIK.values.last_name,
                date_of_birth: props.formIK.values.date_of_birth,
                address: props.formIK.values.address,
                city: props.formIK.values.city,
                state: props.formIK.values.state,
                zip: props.formIK.values.zip,
                prior_address: props.formIK.values.prior_address,
                prior_city: props.formIK.values.prior_city,
                prior_state: props.formIK.values.prior_state,
                prior_zip: props.formIK.values.prior_zip,
                current_auto_limit_bipd: props.formIK.values.current_auto_limit_bipd,
                auto_insurance_in_past_31_days: props.formIK.values.auto_insurance_in_past_31_days,
                continuous_auto_coverage_years: props.formIK.values.continuous_auto_coverage_years,
            }
            props.setLoadingText('Getting data...')
            props.setLoading(true);
            const token = localStorage.getItem('userdata');
            fetch(`${API_URL}/portal_insurance/get_branch_data/`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                },
            }).then(async (res) => {
                const res_data = await res.json();
                if (res_data.hasOwnProperty('quote')) {
                    if (res_data['quote'].hasOwnProperty('home')) {
                        if (res_data['quote']['home']['constructionType'] !== null && res_data['quote']['home']['constructionType'] !== undefined) {
                            if (res_data['quote']['home']['constructionType'] === 'F') {
                                props.formIK.values['construction_type'] = 'Frame Non-Masonry Veneer';
                                prePopulated.push('construction_type');
                            }
                        }
                        if (res_data['quote']['home']['exteriorWallType'] !== null && res_data['quote']['home']['exteriorWallType'] !== undefined) {
                            if (res_data['quote']['home']['exteriorWallType'] === 'VL') {
                                props.formIK.values['exterior_walls'] = 'Vinyl Siding';
                                prePopulated.push('exterior_walls');
                            } else if (res_data['quote']['home']['exteriorWallType'] === 'W') {
                                props.formIK.values['exterior_walls'] = 'Wood';
                                prePopulated.push('exterior_walls');
                            } else if (res_data['quote']['home']['exteriorWallType'] === 'T') {
                                props.formIK.values['exterior_walls'] = 'Stucco';
                                prePopulated.push('exterior_walls');
                            } else if (res_data['quote']['home']['exteriorWallType'] === 'AL') {
                                props.formIK.values['exterior_walls'] = 'Aluminum Siding';
                                prePopulated.push('exterior_walls');
                            } else if (res_data['quote']['home']['exteriorWallType'] === 'ASB') {
                                props.formIK.values['exterior_walls'] = 'Hardboard Siding';
                                prePopulated.push('exterior_walls');
                            } else if (res_data['quote']['home']['exteriorWallType'] === 'S') {
                                props.formIK.values['exterior_walls'] = 'EIFS or Dryvit Siding';
                                prePopulated.push('exterior_walls');
                            } else if (res_data['quote']['home']['exteriorWallType'] === 'B') {
                                props.formIK.values['exterior_walls'] = 'Brick Veneer';
                                prePopulated.push('exterior_walls');
                            }
                        }
                        if (res_data['quote']['home']['garageType'] !== null && res_data['quote']['home']['garageType'] !== undefined) {
                            if (res_data['quote']['home']['garageType'] === 'X') {
                                props.formIK.values['garage'] = 'None';
                                prePopulated.push('garage');
                            } else if (res_data['quote']['home']['garageType'] === '1') {
                                props.formIK.values['garage'] = '1-Car-Attached';
                                prePopulated.push('garage');
                            } else if (res_data['quote']['home']['garageType'] === '2') {
                                props.formIK.values['garage'] = '1-Car-Carport';
                                prePopulated.push('garage');
                            }
                        }
                        if (res_data['quote']['home']['ownershipStatus'] !== null && res_data['quote']['home']['ownershipStatus'] !== undefined) {
                            if (res_data['quote']['home']['ownershipStatus'] === 'P') {
                                props.formIK.values['occupancy_type'] = 'Primary';
                                prePopulated.push('occupancy_type');
                            } else if (res_data['quote']['home']['ownershipStatus'] === 'S') {
                                props.formIK.values['occupancy_type'] = 'Secondary';
                                prePopulated.push('occupancy_type');
                            }
                        }
                        if (res_data['quote']['home']['roofShape'] !== null && res_data['quote']['home']['roofShape'] !== undefined) {
                            if (res_data['quote']['home']['roofShape'] === 'G') {
                                props.formIK.values['roof_shape'] = 'Gable';
                                prePopulated.push('roof_shape');
                            } else if (res_data['quote']['home']['roofShape'] === 'H') {
                                props.formIK.values['roof_shape'] = 'Hip';
                                prePopulated.push('roof_shape');
                            } else if (res_data['quote']['home']['roofShape'] === 'F') {
                                props.formIK.values['roof_shape'] = 'Flat';
                                prePopulated.push('roof_shape');
                            }
                        }
                        if (res_data['quote']['home']['roofType'] !== null && res_data['quote']['home']['roofType'] !== undefined) {
                            if (res_data['quote']['home']['roofType'] === 'W') {
                                props.formIK.values['roof_material'] = 'Wood Shingle';
                                prePopulated.push('roof_material');
                            } else if (res_data['quote']['home']['roofType'] === 'T') {
                                props.formIK.values['roof_material'] = 'Clay Tile';
                                prePopulated.push('roof_material');
                            } else if (res_data['quote']['home']['roofType'] === 'M') {
                                props.formIK.values['roof_material'] = 'Metal';
                                prePopulated.push('roof_material');
                            }
                        }
                        if (res_data['quote']['home']['typeOfHome'] !== null && res_data['quote']['home']['typeOfHome'] !== undefined) {
                            if (res_data['quote']['home']['typeOfHome'] === '1') {
                                props.formIK.values['residence_type'] = 'Single Family Home';
                                prePopulated.push('residence_type');
                            } else if (res_data['quote']['home']['typeOfHome'] === '3') {
                                props.formIK.values['residence_type'] = 'Town House, Center'
                                prePopulated.push('residence_type');
                            }
                        }

                        if (res_data['quote']['home']['numFullBathrooms'] !== null && res_data['quote']['home']['numFullBathrooms'] !== undefined && res_data['quote']['home']['numFullBathrooms'] !== 0 && res_data['quote']['home']['numFullBathrooms'] !== '0') {
                            props.formIK.values['number_of_full_bath'] = res_data['quote']['home']['numFullBathrooms'] > 6 ? '6+' : res_data['quote']['home']['numFullBathrooms'].toString();
                            prePopulated.push('number_of_full_bath');
                        }
                        if (res_data['quote']['home']['numHalfBathrooms'] !== null && res_data['quote']['home']['numHalfBathrooms'] !== undefined) {
                            props.formIK.values['number_of_half_baths'] = res_data['quote']['home']['numHalfBathrooms'] > 6 ? '6+' : res_data['quote']['home']['numHalfBathrooms'].toString();
                            prePopulated.push('number_of_half_baths');
                        }
                        if (res_data['quote']['home']['numOccupants'] !== null && res_data['quote']['home']['numOccupants'] !== undefined) {
                            props.formIK.values['number_of_residence'] = res_data['quote']['home']['numOccupants'] > 8 ? '9 or more' : res_data['quote']['home']['numOccupants'].toString();
                            prePopulated.push('number_of_residence');
                        }
                        if (res_data['quote']['home']['numStories'] !== null && res_data['quote']['home']['numStories'] !== undefined) {
                            props.formIK.values['number_of_stories'] = res_data['quote']['home']['numStories'] > 5 ? '5+' : res_data['quote']['home']['numStories'].toString();
                            prePopulated.push('number_of_stories');
                        }
                        if (res_data['quote']['home']['purchaseDate'] !== null && res_data['quote']['home']['purchaseDate'] !== undefined) {
                            props.formIK.values['purchase_date'] = formatDateMDY(res_data['quote']['home']['purchaseDate'] + " 00:00:00");
                            prePopulated.push('purchase_date');
                        }
                        if (res_data['quote']['home']['roofYear'] !== null && res_data['quote']['home']['roofYear'] !== undefined) {
                            props.formIK.values['year_roof_installed'] = res_data['quote']['home']['roofYear'];
                            let currentYear = new Date().getFullYear();
                            if (currentYear - parseInt(res_data['quote']['home']['roofYear']) > 15) {
                                props.formIK.setFieldValue('loss_settlement_wind_hail_losses_to_roof', 'Roofing Materials Payment Schedule')
                            }
                            prePopulated.push('year_roof_installed');
                        }
                        if (res_data['quote']['home']['sqFt'] !== null && res_data['quote']['home']['sqFt'] !== undefined) {
                            props.formIK.values['square_footage'] = res_data['quote']['home']['sqFt'];
                            prePopulated.push('square_footage');
                        }
                        if (res_data['quote']['home']['yearBuilt'] !== null && res_data['quote']['home']['yearBuilt'] !== undefined) {
                            props.formIK.values['year_built'] = res_data['quote']['home']['yearBuilt'];
                            prePopulated.push('year_built');
                            if (props.formIK.values.sage_sure === true || props.formIK.values.ssiu === true) {
                                props.formIK.values['year_roof_installed'] = res_data['quote']['home']['yearBuilt'];
                                props.formIK.values['year_wiring_installed'] = res_data['quote']['home']['yearBuilt'];
                                props.formIK.values['year_pluming_installed'] = res_data['quote']['home']['yearBuilt'];
                                props.formIK.values['year_hvac_installed'] = res_data['quote']['home']['yearBuilt'];
                                prePopulated.push('year_roof_installed');
                                prePopulated.push('year_wiring_installed');
                                prePopulated.push('year_pluming_installed');
                                prePopulated.push('year_hvac_installed');
                                let currentYear = new Date().getFullYear();
                                if (currentYear - parseInt(props.formIK.values['year_roof_installed']) > 15) {
                                    props.formIK.values['loss_settlement_wind_hail_losses_to_roof'] = 'Roofing Materials Payment Schedule';
                                }
                            }
                        }
                    }
                    if (res_data['quote'].hasOwnProperty('homeCoverage')) {
                        if (res_data['quote']['homeCoverage']['coverageA'] !== null && res_data['quote']['homeCoverage']['coverageA'] !== undefined) {
                            props.formIK.values['coverage_a_dwelling'] = res_data['quote']['homeCoverage']['coverageA'].toString();
                            prePopulated.push('coverage_a_dwelling');
                        }
                        if (res_data['quote']['homeCoverage']['coverageBPctOfA'] !== null && res_data['quote']['homeCoverage']['coverageBPctOfA'] !== undefined) {
                            props.formIK.values['coverage_b_other_structures'] = res_data['quote']['homeCoverage']['coverageBPctOfA'] > 50 ? '10% of Cov A' : res_data['quote']['homeCoverage']['coverageBPctOfA'] + '% of Cov A';
                            prePopulated.push('coverage_b_other_structures');
                        }
                        if (res_data['quote']['homeCoverage']['coverageCPctOfA'] !== null && res_data['quote']['homeCoverage']['coverageCPctOfA'] !== undefined) {
                            props.formIK.values['coverage_c_personal_property'] = res_data['quote']['homeCoverage']['coverageCPctOfA'] > 70 ? '50% of Cov A' : res_data['quote']['homeCoverage']['coverageCPctOfA'] + '% of Cov A';
                            prePopulated.push('coverage_c_personal_property');
                        }
                        if (res_data['quote']['homeCoverage']['coverageDPctOfA'] !== null && res_data['quote']['homeCoverage']['coverageDPctOfA'] !== undefined) {
                            props.formIK.values['coverage_d_loss_of_use'] = res_data['quote']['homeCoverage']['coverageDPctOfA'] > 20 ? '20% of Cov A' : res_data['quote']['homeCoverage']['coverageDPctOfA'] + '% of Cov A';
                            prePopulated.push('coverage_d_loss_of_use');
                        }
                        if (res_data['quote']['homeCoverage']['deductibleAllOther'] !== null && res_data['quote']['homeCoverage']['deductibleAllOther'] !== undefined) {
                            const deductible = res_data['quote']['homeCoverage']['deductibleAllOther'];
                            if (deductible === 1000 || deductible === 1500 || deductible === 2500 || deductible === 5000) {
                                props.formIK.values['all_other_peril_deductible'] = '$' + res_data['quote']['homeCoverage']['deductibleAllOther'].toLocaleString("en-US");
                                prePopulated.push('all_other_peril_deductible');
                            }
                            if (deductible === 1 || deductible === 2 || deductible === 3 || deductible === 5) {
                                props.formIK.values['all_other_peril_deductible'] = res_data['quote']['homeCoverage']['deductibleAllOther'] + '% of Cov A';
                                prePopulated.push('all_other_peril_deductible');
                            }

                        }
                        if (res_data['quote']['homeCoverage']['deductibleWindHail'] !== null && res_data['quote']['homeCoverage']['deductibleWindHail'] !== undefined) {
                            const deductible = res_data['quote']['homeCoverage']['deductibleWindHail'];
                            if (deductible === 1500 || deductible === 2500 || deductible === 5000) {
                                props.formIK.values['hurricane_wind_hail_tornado_deductible'] = '$' + res_data['quote']['homeCoverage']['deductibleWindHail'].toLocaleString("en-US");
                                prePopulated.push('hurricane_wind_hail_tornado_deductible');
                            }
                            if (deductible === 1 || deductible === 2 || deductible === 3 || deductible === 5) {
                                props.formIK.values['hurricane_wind_hail_tornado_deductible'] = res_data['quote']['homeCoverage']['deductibleWindHail'] + '% of Cov A';
                                prePopulated.push('hurricane_wind_hail_tornado_deductible');
                            }
                        }
                        if (res_data['quote']['homeCoverage']['coverageWaterBackup'] !== null && res_data['quote']['homeCoverage']['coverageWaterBackup'] !== undefined) {
                            if (res_data['quote']['homeCoverage']['coverageWaterBackup'] === 0) {
                                props.formIK.values['water_back_up_sump_discharge_or_overflow'] = 'None';
                                prePopulated.push('water_back_up_sump_discharge_or_overflow');
                            } else if (res_data['quote']['homeCoverage']['coverageWaterBackup'] === 5000) {
                                props.formIK.values['water_back_up_sump_discharge_or_overflow'] = '$5,000';
                                prePopulated.push('water_back_up_sump_discharge_or_overflow');
                            } else if (res_data['quote']['homeCoverage']['coverageWaterBackup'] === 10000) {
                                props.formIK.values['water_back_up_sump_discharge_or_overflow'] = '$10,000';
                                prePopulated.push('water_back_up_sump_discharge_or_overflow');
                            } else if (res_data['quote']['homeCoverage']['coverageWaterBackup'] === 25000) {
                                props.formIK.values['water_back_up_sump_discharge_or_overflow'] = '$25,000';
                                prePopulated.push('water_back_up_sump_discharge_or_overflow');
                            }
                        }
                    }
                    if (res_data['quote'].hasOwnProperty('includeUmbrella')) {
                        const deductible = res_data['quote']['includeUmbrella'];
                        if (deductible === true) {
                            props.formIK.values['insured_have_umbrella_policy'] = 'Yes';
                            prePopulated.push('insured_have_umbrella_policy');
                        }
                        if (deductible === false) {
                            props.formIK.values['insured_have_umbrella_policy'] = 'No';
                            prePopulated.push('insured_have_umbrella_policy');
                        }
                    }
                    if (res_data['quote'].hasOwnProperty('umbrellaCoverage')) {
                        if (res_data['quote']['umbrellaCoverage']['presenceOfBusiness'] !== null && res_data['quote']['umbrellaCoverage']['presenceOfBusiness'] !== undefined) {
                            props.formIK.values['onsite_business'] = res_data['quote']['umbrellaCoverage']['presenceOfBusiness'] === true ? 'Yes' : 'No';
                            prePopulated.push('onsite_business');
                        }
                    }
                }
                localStorage.removeItem('prePopulatedFields');
                localStorage.setItem('prePopulatedFields', JSON.stringify(prePopulated));
                props.setLoading(false);
                props.changeStep(props.progress + props.step);
            }).catch((error) => {
                props.setLoading(false);
                localStorage.removeItem('prePopulatedFields');
                localStorage.setItem('prePopulatedFields', JSON.stringify(prePopulated));
                console.log(error);
                props.changeStep(props.progress + props.step);
            });


        }
    }

    return (
        <div>
            <Container onKeyDown={(e) => checkKeyDown(e)}>
                <Col>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button onClick={() => props.changeStep(props.progress - props.step)}
                                        className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate_data();
                                }} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{maxWidth: "75%", minHeight: "800px"}}
                              style={{marginBottom: "2%", overflow: "visible"}}>

                            <CardContent>
                                <Row style={{marginBottom: "1%"}}>
                                    <h4>General Information</h4>
                                </Row>
                                <Row>
                                    <Col key={props.formIK.errors["first_name"]}>
                                        <Form.Group key="first_name" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>First
                                                Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name="first_name"
                                                             className={agencyZoomPrePopulated.includes('first_name') ? 'form-control prePopulated' : 'form-control'}
                                                             id="first_name-input"
                                                             value={props.formIK.values.first_name}
                                                             onChange={(value) => {
                                                                 if (value === "") {
                                                                     props.formIK.errors['first_name'] = "First name is required.";
                                                                 } else {
                                                                     delete props.formIK.errors['first_name'];
                                                                 }
                                                                 props.formIK.setFieldValue("first_name", value);
                                                             }}/>
                                            <div className="text-error">{props.formIK.errors["first_name"]}</div>
                                        </Form.Group>
                                        <Form.Group key="last_name" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Last
                                                Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name="last_name"
                                                             className={agencyZoomPrePopulated.includes('last_name') ? 'form-control prePopulated' : 'form-control'}
                                                             id="last_name-input"
                                                             value={props.formIK.values.last_name}
                                                             onChange={(value) => {
                                                                 if (value === "") {
                                                                     props.formIK.errors['last_name'] = "Last name is required.";
                                                                 } else {
                                                                     delete props.formIK.errors['last_name'];
                                                                 }
                                                                 props.formIK.setFieldValue("last_name", value);
                                                             }}/>
                                            {props.formIK.errors["last_name"] && (
                                                <div className="text-error">{props.formIK.errors["last_name"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group key="middle_name" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label><strong>Middle Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true} name="middle_name"
                                                             className={agencyZoomPrePopulated.includes('middle_name') ? 'form-control prePopulated' : 'form-control'}
                                                             id="middle_name-input"
                                                             value={props.formIK.values.middle_name}
                                                             onChange={(value) => {
                                                                 props.formIK.setFieldValue("middle_name", value);
                                                             }}/>
                                            {props.formIK.errors["middle_name"] && (
                                                <div className="text-error">{props.formIK.errors["middle_name"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="date_of_birth" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Date Of
                                                Birth</strong></Form.Label>

                                            <DateNormalInput disabled={false} required={true} name="date_of_birth"
                                                             className={agencyZoomPrePopulated.includes('date_of_birth') ? 'form-control prePopulated' : 'form-control'}
                                                             id="date_of_birth-input"
                                                             value={props.formIK.values.date_of_birth}
                                                             onChange={(value) => {
                                                                 if (value === null || value === "" ||  value === "NaN-NaN-NaN" || new Date(value).toString() === 'Invalid Date') {
                                                                     props.formIK.errors['date_of_birth'] = "Date of birth is required.";
                                                                 } else if (value >= new Date()) {
                                                                     props.formIK.errors['date_of_birth'] = "Date of birth cannot be today or in future.";
                                                                 } else {
                                                                     delete props.formIK.errors['date_of_birth'];
                                                                 }
                                                                 props.formIK.setFieldValue("date_of_birth", formatDateMDY(value));
                                                             }}/>

                                            {props.formIK.errors["date_of_birth"] && (
                                                <div className="text-error">{props.formIK.errors["date_of_birth"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{padding: "0px 15px", marginTop: "1%"}}>
                                    <GoogleAutoComplete placeholder="Search Address..."
                                                        callback={addressAutoFillCallBack}/>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group key={props.formIK.values.address} controlId="formBasicEmail">
                                            <Form.Label className="required-label"><strong>Address</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={false} name="address"
                                                             className={agencyZoomPrePopulated.includes('address') ? 'form-control prePopulated' : 'form-control'}
                                                             id={"address-input"}
                                                             value={props.formIK.values.address}
                                                             onChange={(value) => {
                                                                 if (value === "") {
                                                                     props.formIK.errors['address'] = "Address is required.";
                                                                 } else {
                                                                     delete props.formIK.errors['address'];
                                                                 }
                                                                 props.formIK.setFieldValue("address", value);
                                                             }}
                                            />
                                            {props.formIK.errors["address"] && (
                                                <div className="text-error">{props.formIK.errors["address"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="state" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>State</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="state"
                                                               className={agencyZoomPrePopulated.includes('state') ? 'prePopulated' : ''}
                                                               value={props.formIK.values.state !== undefined ? props.formIK.values.state : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['state'] = "State is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['state'];
                                                                   }
                                                                   props.formIK.setFieldValue("state", value);
                                                               }}
                                                               options={cityState.getStates().sort().map((state) => {
                                                                   return {
                                                                       label: state, value: state
                                                                   }
                                                               })}/>
                                            {props.formIK.errors["state"] && (
                                                <div className="text-error">{props.formIK.errors["state"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group key={props.formIK.values.zip} controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Zip
                                                Code</strong></Form.Label>
                                            <ZipCode disabled={false} required={true} name="zip"
                                                     className={agencyZoomPrePopulated.includes('zip') ? 'form-control prePopulated' : 'form-control'}
                                                     id="zip-input"
                                                     value={props.formIK.values.zip}
                                                     onChange={(value) => {
                                                         if (value === "#####") {
                                                             props.formIK.errors['zip'] = "Zip code is required.";
                                                         } else {
                                                             delete props.formIK.errors['zip'];
                                                         }
                                                         props.formIK.setFieldValue("zip", value);
                                                     }}/>
                                            {props.formIK.errors["zip"] && (
                                                <div className="text-error">{props.formIK.errors["zip"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="city" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>City</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="city"
                                                               className={agencyZoomPrePopulated.includes('city') ? 'prePopulated' : ''}
                                                               value={props.formIK.values.city !== undefined ? props.formIK.values.city : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['city'] = "City is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['city'];
                                                                   }
                                                                   props.formIK.setFieldValue("city", value)
                                                               }}
                                                               options={props.formIK.values.state !== "" ? cityState.getCitiesOfState(props.formIK.values.state).map((city) => {
                                                                   return {label: city.city, value: city.city}
                                                               }).sort((a, b) => (a.value > b.value ? 1 : -1)) : []}/>
                                            {props.formIK.errors["city"] && (
                                                <div className="text-error">{props.formIK.errors["city"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{padding: "0px 15px", marginTop: "1%"}}>
                                    <GoogleAutoComplete placeholder="Search Prior Address..."
                                                        callback={priorAddressAutoFillCallBack}/>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group key={props.formIK.values.prior_address} controlId="formBasicEmail">
                                            <Form.Label className="required-label"><strong>Prior
                                                Address</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={false} name="prior_address"
                                                             className={agencyZoomPrePopulated.includes('prior_address') ? 'form-control prePopulated' : 'form-control'}
                                                             id={"prior_address-input"}
                                                             value={props.formIK.values.prior_address}
                                                             onChange={(value) => {
                                                                 if (value === "") {
                                                                     props.formIK.errors['prior_address'] = "Prior address is required.";
                                                                 } else {
                                                                     delete props.formIK.errors['prior_address'];
                                                                 }
                                                                 props.formIK.setFieldValue("prior_address", value);
                                                             }}
                                            />
                                            {props.formIK.errors["prior_address"] && (
                                                <div className="text-error">{props.formIK.errors["prior_address"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="prior_state" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Prior
                                                State</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="prior_state"
                                                               className={agencyZoomPrePopulated.includes('prior_state') ? 'prePopulated' : ''}
                                                               value={props.formIK.values.prior_state !== undefined ? props.formIK.values.prior_state : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['prior_state'] = "Prior State is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['prior_state'];
                                                                   }
                                                                   props.formIK.setFieldValue("prior_state", value);
                                                               }}
                                                               options={cityState.getStates().sort().map((state) => {
                                                                   return {label: state, value: state}
                                                               })}/>
                                            {props.formIK.errors["prior_state"] && (
                                                <div className="text-error">{props.formIK.errors["prior_state"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group key={props.formIK.values.prior_zip} controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Prior Zip
                                                Code</strong></Form.Label>
                                            <ZipCode disabled={false} required={true} name="prior_zip"
                                                     className={agencyZoomPrePopulated.includes('prior_zip') ? 'form-control prePopulated' : 'form-control'}
                                                     id="prior_zip-input"
                                                     value={props.formIK.values.prior_zip}
                                                     onChange={(value) => {
                                                         if (value === "#####") {
                                                             props.formIK.errors['prior_zip'] = "Prior Zip code is required.";
                                                         } else {
                                                             delete props.formIK.errors['prior_zip'];
                                                         }
                                                         props.formIK.setFieldValue("prior_zip", value);
                                                     }}/>
                                            {props.formIK.errors["prior_zip"] && (
                                                <div className="text-error">{props.formIK.errors["prior_zip"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="prior_city" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Prior
                                                City</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="prior_city"
                                                               className={agencyZoomPrePopulated.includes('prior_city') ? 'prePopulated' : ''}
                                                               value={props.formIK.values.prior_city !== undefined ? props.formIK.values.prior_city : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['prior_city'] = "Prior City is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['prior_city'];
                                                                   }
                                                                   props.formIK.setFieldValue("prior_city", value)
                                                               }}
                                                               options={props.formIK.values.prior_state !== '' ? cityState.getCitiesOfState(props.formIK.values.prior_state).map((city) => {
                                                                   return {label: city.city, value: city.city}
                                                               }).sort((a, b) => (a.value > b.value ? 1 : -1)) : []}/>
                                            {props.formIK.errors["prior_city"] && (
                                                <div className="text-error">{props.formIK.errors["prior_city"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row style={{marginTop: "1%"}}>
                                    <Collapsible trigger={["Default Fields", <BsChevronDown/>]}
                                                 triggerStyle={{background: '#e3f2fd'}}
                                                 >
                                        <Row >
                                            <Col>
                                                <Form.Group key="current_auto_limit_bipd" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label' style={{marginBottom: "1%"}}><strong>Current
                                                        Auto Limit BIPD</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="current_auto_limit_bipd"
                                                                       value={props.formIK.values.current_auto_limit_bipd !== undefined ? props.formIK.values.current_auto_limit_bipd : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['current_auto_limit_bipd'] = "Current Auto Limit BIPD is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['current_auto_limit_bipd'];
                                                                           }
                                                                           props.formIK.setFieldValue("current_auto_limit_bipd", value);
                                                                       }}
                                                                       options={[{
                                                                           label: '$50K | $100K | $25K',
                                                                           value: '$50K | $100K | $25K'
                                                                       },
                                                                           {
                                                                               label: '$50K | $100K | $100K',
                                                                               value: '$50K | $100K | $100K'
                                                                           },
                                                                           {
                                                                               label: '$100K | $300K | $50K',
                                                                               value: '$100K | $300K | $50K'
                                                                           },
                                                                           {
                                                                               label: '$250K | $500K | $50K',
                                                                               value: '$250K | $500K | $50K'
                                                                           }]}/>
                                                    {props.formIK.errors["current_auto_limit_bipd"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["current_auto_limit_bipd"]}</div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group key="auto_insurance_in_past_31_days"
                                                            controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label' style={{marginBottom: "1%"}}><strong>Auto
                                                        Insurance In Past 31 Days</strong></Form.Label>
                                                    <CustomSelectInput disabled={false}
                                                                       name="auto_insurance_in_past_31_days"
                                                                       value={props.formIK.values.auto_insurance_in_past_31_days !== undefined ? props.formIK.values.auto_insurance_in_past_31_days : ""}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['auto_insurance_in_past_31_days'] = "Auto Insurance In Past 31 Days is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['auto_insurance_in_past_31_days'];
                                                                           }
                                                                           props.formIK.setFieldValue("auto_insurance_in_past_31_days", value);
                                                                       }}
                                                                       options={[{
                                                                           label: 'Yes',
                                                                           value: 'Yes'
                                                                       }, {label: 'No', value: 'No'}]}/>
                                                    {props.formIK.errors["auto_insurance_in_past_31_days"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["auto_insurance_in_past_31_days"]}</div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group key="continuous_auto_coverage_years"
                                                            controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label' style={{marginBottom: "1%"}}><strong>Continuous
                                                        Auto Coverage Years</strong></Form.Label>
                                                    <CustomNumberInput disabled={false} required={true}
                                                                       className="form-control"
                                                                       name="continuous_auto_coverage_years"
                                                                       id="prior_zip-input"
                                                                       value={props.formIK.values.continuous_auto_coverage_years}
                                                                       onChange={(value) => {
                                                                           if (value === "") {
                                                                               props.formIK.errors['continuous_auto_coverage_years'] = "Continuous Auto Coverage Years is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['continuous_auto_coverage_years'];
                                                                           }
                                                                           props.formIK.setFieldValue("continuous_auto_coverage_years", value)
                                                                       }}/>
                                                    {props.formIK.errors["continuous_auto_coverage_years"] && (
                                                        <div
                                                            className="text-error">{props.formIK.errors["continuous_auto_coverage_years"]}</div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Collapsible>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    props.changeStep(props.progress - props.step)
                                }} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate_data();
                                }} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

General.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    setLoading: PropTypes.func.isRequired,
    setLoadingText: PropTypes.func.isRequired,
};
