import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {
    getBondNumberColumns, getBondNumbersPagination,

} from "../../../../../services/company_management";
import {
    addBondNumbers, removeBondNumbers

} from "../../../../../store/company_management";
import AddBondNumberPopup from '../../../../../components/popups/add_bond_number_popup';
import {useNavigate} from "react-router";
import {hasUserPermission} from "../../../../../utils/user_permission";
import CustomDataGrid from "../../../../../global_components/datatables/custom_datagrid";
import Footer from "../../../../../global_components/footer";
import CusNavBar from "../../../../../global_components/navbar";
import Header from "../../../../../global_components/header";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {exportToExcel} from "../../../../../utils/excel_export";
import {useSnackbar} from "notistack";
import GoBack from "../../../../../utils/go_back_button";

function BondNumberList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [addBondNumber, setAddBondNumber] = useState(false);
    const [bondNumberLoading, setBondNumberLoading] = useState(false);
    const bondNumberColumns = getBondNumberColumns();
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');
    const hasAddPermission =hasUserPermission(user_permission, 'show_table_add_button')
    const [bondJsonData, setBondJsonData] = useState([]);

    // Datagrid Pagination
    if (!localStorage.getItem("bond_list_pagesize")) {
        localStorage.setItem("bond_list_pagesize", 10);
    }
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0, pageSize: 10
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);
    // Datagrid Filter
    const [queryOptions, setQueryOptions] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptions({filterModel: {...filterModel.items}}), 1000);
    }, []);
    const [prevFilter, setPrevFilter] = useState("");
    //Datagrid Sort
    const [queryOptionsSort, setQueryOptionsSort] = useState({});
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSort({sortModel: [...sortModel]});
    }, []);
    const [prevSort, setPrevSort] = useState("");
    const {enqueueSnackbar} = useSnackbar();

    const refreshBondNumber = async (is_refresh) => {
        if (!bondNumberLoading) {
            setBondNumberLoading(true);

            let filter = ""
            if (queryOptions.filterModel) {
                filter = queryOptions.filterModel[0].field + "-" + queryOptions.filterModel[0].operator + "-" + queryOptions.filterModel[0].value;
            }

            let sort = ""
            if (queryOptionsSort.sortModel) {
                if (queryOptionsSort.sortModel.length > 0) {
                    if (prevSort) {
                        sort = prevSort
                    }
                    let sortModel = queryOptionsSort.sortModel[0]
                    sort = sortModel.field + "-" + sortModel.sort
                    if (prevSort !== sort) {
                        setPrevSort(sort)
                    } else {
                        if (queryOptions.filterModel) {
                            sort = ""
                        }
                    }
                }
            }
            let resp = await getBondNumbersPagination(controller.page, controller.pageSize, filter, sort);
            if (resp.status === 200) {
                const result = await resp.json();
                setRowCount(result.total);
                dispatch(removeBondNumbers());
                dispatch(addBondNumbers(result.data));
                setBondJsonData(result.data);
                setBondNumberLoading(false);
                if (is_refresh)
                    enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
                return result.data;
            } else {
                const result = await resp.json();
                enqueueSnackbar(result, {variant: "error"});
                return 0;
            }
        }
    }


    const onCallbackFun = async (type, obj) => {
        if (type === 'insert') {
            setAddBondNumber(true);
        } else if (type === 'refresh') {
            await refreshBondNumber(true);
        } else if (type === "download") {
            exportToExcel(await refreshBondNumber(false), "Bond Number");
        }
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        document.title = "Law Firms";

        await refreshBondNumber(false);
    }, [controller, queryOptions, queryOptionsSort]);


    return (
        <>
            <CusNavBar/>
            <GoBack pageUrl="/account_management"></GoBack>
            <Header text="Bond Number"/>
            {hasUserPermission(user_permission, 'show_bond_number_account_management') &&
                <CustomDataGrid rows={bondJsonData}
                                columns={bondNumberColumns}
                                rowCount={rowCount}
                                paginationModel={controller}
                                onPaginationModelChange={gatedSetController}
                                onFilterModelChange={onFilterChange}
                                onSortModelChange={handleSortModelChange}
                                loading={bondNumberLoading}
                                isActionsVisible={true}
                                isEdit={true}
                                onCallback={onCallbackFun}
                                addPermission={hasAddPermission}
                                deletePermission={hasDeletePermission}
                                updatePermission={hasUpdatePermission}
                                disableRowSelectionOnClick={true}/>

            }
            {addBondNumber &&
                <AddBondNumberPopup show={true} closePopup={setAddBondNumber}/>
            }

            <br/>
            <Footer/>
        </>
    )

}

export default BondNumberList;