import React, {useCallback, useEffect, useState} from 'react';
import '../../styles/login.css';
import '../../App.css';
import {useNavigate} from "react-router";
import Footer from "../../global_components/footer";
import CusNavBar from "../../global_components/navbar";
import APTableLoading from "../../global_components/loadings/ap_table_loading";
import {API_URL} from "../../constants";
import {DataGrid, GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import CustomDataGrid from "../../global_components/datatables/custom_datagrid";

function POHistory(props) {
    const navigate = useNavigate();
    const [poHistory, setPOHistory] = useState([]);
    const [hasPerm, setHasPerm] = useState(true);
    const [loading, setLoading] = useState(true);
    // Datagrid Pagination
    if (!localStorage.getItem("pohistory_datagrid_pagesize")) {
        localStorage.setItem("pohistory_datagrid_pagesize", 10);
    }
    const [rowCount, setRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("pohistory_datagrid_pagesize"))
    });
    const gatedSetController = useCallback((value) => {
        setController(value);
    }, []);
    // Datagrid Filter
    const [queryOptions, setQueryOptions] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptions({filterModel: {...filterModel.items}}), 1000);

    }, []);
    const [prevFilter, setPrevFilter] = useState("");
    //Datagrid Sort
    const [queryOptionsSort, setQueryOptionsSort] = useState({});
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSort({sortModel: [...sortModel]});
    }, []);
    const [prevSort, setPrevSort] = useState("");

    const columns = [
        {
            field: 'id', headerName: 'ID', sortable: false, flex: 1,
            headerClassName: 'header-bold right-column'
        },
        {
            field: 'po_type', headerName: 'PO Type', sortable: false, flex: 1,
            headerClassName: 'header-bold right-column'
        },
        {
            field: 'buyer_id', headerName: 'Buyer ID', sortable: false, flex: 1,
            headerClassName: 'header-bold right-column'
        },
        {
            field: 'vendor_id', headerName: 'Vendor ID', sortable: false, flex: 1,
            headerClassName: 'header-bold right-column'
        },
        {
            field: 'total_amount', headerName: 'Total Amount', sortable: false, flex: 1,
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                return <span>$ {params.row.total_amount}</span>;
            }
        },
        {
            field: 'comment', headerName: 'Comment', sortable: false, flex: 1,
            headerClassName: 'header-bold right-column'
        }
    ];


    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }

        let filter = ""
        if (queryOptions.filterModel) {
            filter = queryOptions.filterModel[0].field + "-" + queryOptions.filterModel[0].operator + "-" + queryOptions.filterModel[0].value
        }
        let sort = ""
        if (queryOptionsSort.sortModel) {
            if (queryOptionsSort.sortModel.length > 0) {
                if (prevSort) {
                    sort = prevSort
                }
                let sortModel = queryOptionsSort.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSort !== sort) {
                    setPrevSort(sort)
                } else {
                    if (queryOptions.filterModel) {
                        sort = ""
                    }
                }
            }
        }

        const token = localStorage.getItem('userdata');
        const response = await fetch(`${API_URL}/po_history/?&page=${controller.page}&size=${controller.pageSize}&filter=${filter}&sort=${sort}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });
        if (response.status === 401) {
            setHasPerm(false);
            setLoading(false);
        } else {
            let result = await response.json();
            setPOHistory(result.data)
            setRowCount(result.total)
            setLoading(false);
        }
        localStorage.setItem("pohistory_datagrid_pagesize", controller.pageSize);
        document.title = "PO History";

        // setProcesses(pros);
    }, [controller, queryOptions, queryOptionsSort])

    return (
        <>
            <CusNavBar/>
            <header
                className="d-flex align-items-center justify-content-between text-dark text-decoration-none pt-3 pb-3 mb-3 border-bottom">
                <div className="fs-1">
                    PO History
                </div>
            </header>

            <div className="my-3">
                <p className="fs-3 col-md-8">Following are the previously created POs.</p>
            </div>

            <hr className="col-3 col-md-2"/>
            {
                (hasPerm === true) ? (
                    <CustomDataGrid columns={columns}
                                    rows={poHistory}
                                    rowCount={rowCount}
                                    paginationModel={controller}
                                    onPaginationModelChange={gatedSetController}
                                    onFilterModelChange={onFilterChange}
                                    onSortModelChange={handleSortModelChange}
                                    loading={loading}
                    />
                    // <DataGrid
                    //     style={{height: "500px"}}
                    //     rows={poHistory}
                    //     columns={columns}
                    //     loading={loading}
                    //     initialState={{
                    //         pagination: {paginationModel: {pageSize: 10}}
                    //     }}
                    //     pageSizeOptions={[]}
                    // />

                ) : (
                    <p>You donot have permission to see the history.</p>
                )
            }
            <hr className="col-3 col-md-2"/>
            <Footer/>
        </>
    )
}

// Process.propTypes = {
//     category: PropTypes.string.isRequired,
// };


export default POHistory;
