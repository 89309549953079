import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner} from "react-bootstrap";
import '../../styles/login.css';
import {deleteInvoice} from "../../services/accounts";

export default function DeleteInvoicePopup(props) {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                Do you really want to delete the invoice.
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="danger" onClick={() => {
                            setLoading(true);
                            deleteInvoice(props.invoice_id).then((response)=>{
                                setLoading(false);
                                props.reject_inovice_callback();
                                props.closePopup(false);
                            }).catch((error)=>{
                                setLoading(false);
                                console.log(error);
                                props.closePopup(false);
                            });
                        }}>Confirm</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="danger" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(false);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

DeleteInvoicePopup.propTypes = {
    invoice_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    reject_inovice_callback: PropTypes.func.isRequired,
}
