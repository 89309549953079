import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import USPhoneNumberWithoutCountryCodeUniversal from "../../../global_components/inputs/us_phone_number_without_country_code";
import * as yup from 'yup';
import {useFormik} from "formik";
import {log} from "../../../utils/logger";
import {API_URL} from "../../../constants";
import {useSelector} from "react-redux";
import {hasUserPermission} from "../../../utils/user_permission";
import { getAPICallv2 } from "../../../services/helper";
import { FixedSizeList as List } from 'react-window';
import CreatableSelect from 'react-select/creatable';
import { memo } from 'react';

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const MenuList = memo((props) => {
    const height = 35; // Set height for each option item
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue(); // Get the selected value
    const initialOffset = options.indexOf(value) * height; // Scroll to the selected item

    return (
        <List
            height={Math.min(maxHeight, options.length * height)} // Set height for the list, based on the number of options or max height
            itemCount={children.length} // Number of items to render
            itemSize={height} // Height of each item
            initialScrollOffset={initialOffset} // Initial scroll position
        >
            {({ index, style }) => (
                <div style={style}>
                    {children[index]} {/* Render the visible option */}
                </div>
            )}
        </List>
    );
});


function LawFirmLocation(props) {
    const [loading, setLoading] = useState(true);
    const super_user = useSelector((state) => state.auth.user.user_role_name) === "SuperAdmin";
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const [locationOptions, setLocationOptions] = useState({ state: [], city: [], county: [] });
    const [locationResults, setLocationResults] = useState({});
    const [zipCode, setZipCode] = useState('');

    // Debounced value for the zip code
    const debouncedZipCode = useDebounce(zipCode, 1000); // 500ms delay


    const [lawFirm, setLawFirm] = useState({
        accounting_contact_full_name: "",
        accounting_contact_email: "",
        accounting_contact_phone: "",
    });

    const getLawFirm = async () => {
        const url = `/bond/get_law_firm/${props.lawId}`
        try {
            const token = localStorage.getItem('userdata');
            const response = await fetch(`${API_URL}${url}`, {
                method: "GET", headers: {
                    'Authorization': 'Bearer ' + token, "Content-Type": "application/json",
                },
            });
            setLoading(true);
            if (response.status === 200) {
                const result = await response.json();
                let law_firm = {}
                law_firm.accounting_contact_full_name = result.accounting_contact_full_name
                law_firm.accounting_contact_email = result.accounting_contact_email
                law_firm.accounting_contact_phone = result.accounting_contact_phone
                setLawFirm(law_firm);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            log(error);
        }

    }

    

    const schema = yup.object().shape({
        name: yup.string().required(),
        phone: yup.string().required('Phone is required.'),
        address: yup.string().required('Address is required.'),
        state: yup.string().required('State is required.'),
        zip_code: yup.string().required('Fax is required.'),
        city: yup.string().required('City is required.'),
        county: yup.string().required('County is required.')
        //accounting_contact_full_name: yup.string().required(),
        //accounting_contact_email: yup.string().matches(/\S+@\S+\.\S+/, 'Please enter a valid email.').required('Email is required.'),
        //accounting_contact_phone: yup.string().required(),
    });

    const getInitialValues = () => {
        if (props.id !== 0) {
            let data = props.data;
            return {
                name: data.name,
                customer_number: data.customer_number,
                phone: data.phone,
                fax: data.fax,
                address: data.address,
                city: data.city,
                county: data.county,
                state: data.state,
                zip_code: data.zip_code,
                use_seperate_firm_accounting_contact: data.use_seperate_firm_accounting_contact,
                accounting_contact_full_name: data.accounting_contact_full_name,
                accounting_contact_email: data.accounting_contact_email,
                accounting_contact_phone: data.accounting_contact_phone,
                physical_address: true,
                mailing_address: true,
                billing_address: true,
                is_active: data.is_active,
            }
        } else {
            return {
                name: '',
                customer_number: '',
                phone: '',
                fax: '',
                address: '',
                city: '',
                county: '',
                state: '',
                zip_code: '',
                use_seperate_firm_accounting_contact: false,
                accounting_contact_full_name: '',
                accounting_contact_email: '',
                accounting_contact_phone: "",
                physical_address: true,
                mailing_address: true,
                billing_address: true,
                is_active: true,
            }
        }
    }

        // Function to fetch location data by zip code
        const getLocationValues = async (zip) => {
            const token = localStorage.getItem('userdata');
            const response = await fetch(`${API_URL}/bond/place_holder?zip_code=${zip}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                }
            });
        
            const result = await response.json(); // Parse the JSON response
            console.log(result); // Log the response
    
            const locOpt = {
                city: [{ label: result.city, value: result.city }],  // Wrap the string in an array
                state: [{ label: result.state, value: result.state }],
                county: [{ label: result.county, value: result.county }]
            };

            // Update formik values based on the API result
            formik.setFieldValue('city', result.city);
            formik.setFieldValue('state', result.state);
            formik.setFieldValue('county', result.county);
    
            setLocationResults(locOpt);
        };

        useEffect(() => {
            if (debouncedZipCode) {
                getLocationValues(debouncedZipCode);
            }
        }, [debouncedZipCode]);

    useEffect(async () => {
        if (props && props.lawId && props.lawId !== '0') {
            await getLawFirm();
        }
    }, []);

    useEffect(async () => {
        log("user_permission,", user_permission);
        log("props", props);
        
        if(props.id === 0){
        const fetchLocationData = async () => {
            try {
                const response = await getAPICallv2('/bond/place_holder');
                
                // Ensure response is parsed as JSON
                const result = await response.json();  // Parse the body as JSON
                
                console.log(result);  // Log the parsed result
        
                // Access the first object in the result array
                if (result && result.length > 0 && result[0].city && result[0].state && result[0].county) {
                    const locData = result[0];  // Access the first object in the array
                    
                    const locOpt = {
                        city: locData.city.map(item => ({ label: item, value: item })),
                        state: locData.state.map(item => ({ label: item, value: item })),
                        county: locData.county.map(item => ({ label: item, value: item })),
                    };
                    setLocationOptions(locOpt);
                    console.log("Location options updated:", locOpt);
                }
            } catch (err) {
                console.error("Error fetching location data:", err);
            }
        };        
        fetchLocationData();
    }
    }, []);

    const formik = useFormik({
            initialValues: getInitialValues(),
            onSubmit: async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                if (props.id !== undefined && props.id !== 0) {
                    formik.resetForm({values: ''});
                    props.oncallback('edit', values, props.id);
                } else {
                    if (!formik.values.use_seperate_firm_accounting_contact) {
                        values.accounting_contact_full_name = lawFirm.accounting_contact_full_name
                        values.accounting_contact_email = lawFirm.accounting_contact_email
                        values.accounting_contact_phone = lawFirm.accounting_contact_phone
                    }
                    props.oncallback('insert', values);
                }
                setSubmitting(false);
            },
            validationSchema: schema
        }
    );
    return (
        <>
            <h1>{formik.values.name}</h1>
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col xl={8}>
                        <Form.Group key={'name'} controlId="validationFormik01" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Location Name</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          name='name'
                                          value={formik.values.name}
                                          isInvalid={!!formik.errors.name}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={8}>
                        <Form.Group key={'address'} controlId="validationFormik5" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Address</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          name='address'
                                          value={formik.values.address}
                                          isInvalid={!!formik.errors.address}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.address}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={4}>
                        <Form.Group key={'zip_code'} controlId="validationFormik9" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Zip Code</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={(e) => {
                                            formik.handleChange(e);  // Update formik values
                                            setZipCode(e.target.value); // Update local zip code state
                                          }}
                                          name='zip_code'
                                          value={formik.values.zip_code}
                                          isInvalid={!!formik.errors.zip_code}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.zip_code}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

{props.id !== 0 &&<Col xl={4}>
                        <Form.Group key={'county'} controlId="validationFormik17" className="mb-3">
                            <Form.Label className={'required-label'}><strong>County</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          name='county'
                                          value={formik.values.county}
                                          isInvalid={!!formik.errors.county}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.county}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>}
                    {props.id === 0 &&
                    <Col xl={4}>
                    <Form.Group key="county" controlId="validationFormik17" className="mb-3">
                        <Form.Label className={'required-label'}><strong>County</strong></Form.Label>
                        <CreatableSelect
                            options={locationOptions.county}  // Options from the API or predefined list
                            isSearchable
                            isClearable
                            onChange={(selectedOption) => {
                                // Set the selected county or new county to the formik values
                                formik.setFieldValue('county', selectedOption ? selectedOption.value : '');
                            }}
                            onCreateOption={(inputValue) => {
                                // Handle creation of a new county option
                                formik.setFieldValue('county', inputValue);
                            }}
                            name="county"
                            value={formik.values.county ? { label: formik.values.county, value: formik.values.county } : null}
                        />
                        {formik.errors.county && formik.touched.county && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.county}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                }
                </Row>
                <Row>
                {props.id !== 0 &&
                    <Col xl={4}>
                        <Form.Group key={'state'} controlId="validationFormik8" className="mb-3">
                            <Form.Label className={'required-label'}><strong>State</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          name='state'
                                          value={formik.values.state}
                                          isInvalid={!!formik.errors.state}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.state}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                }
                {props.id === 0 &&
                    <Col xl={4}>
    <Form.Group key="state" controlId="validationFormik8" className="mb-3">
        <Form.Label className={'required-label'}><strong>State</strong></Form.Label>
        <CreatableSelect
            options={locationOptions.state}  // The options should come from the API response or predefined list
            isSearchable
            isClearable
            onChange={(selectedOption) => {
                // Set the selected state or new state to the formik values
                formik.setFieldValue('state', selectedOption ? selectedOption.value : '');
            }}
            onCreateOption={(inputValue) => {
                // Handle creation of a new state option
                formik.setFieldValue('state', inputValue);
            }}
            name="state"
            value={formik.values.state ? { label: formik.values.state, value: formik.values.state } : null}
        />
        {formik.errors.state && formik.touched.state && (
            <Form.Control.Feedback type="invalid">
                {formik.errors.state}
            </Form.Control.Feedback>
        )}
    </Form.Group>
</Col>
                }
                                    {props.id !== 0 && <Col xl={4}>
                        <Form.Group key={'city'} controlId="validationFormik6" className="mb-3">
                            <Form.Label className={'required-label'}><strong>City</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          name='city'
                                          value={formik.values.city}
                                          isInvalid={!!formik.errors.city}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.city}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    }
{props.id === 0 && <Col xl={4}>
<Form.Group key="city" controlId="validationFormik6" className="mb-3">
            <Form.Label className={'required-label'}>
                <strong>City</strong>
            </Form.Label>
            <CreatableSelect
                components={{ MenuList }} // Use the custom MenuList component for virtualization
                options={locationOptions.city} // List of city options
                isSearchable
                isClearable
                onChange={(selectedOption) => {
                    // Set the selected value to formik values
                    formik.setFieldValue('city', selectedOption ? selectedOption.value : '');
                }}
                onCreateOption={(inputValue) => {
                    // Handle the creation of a new city and set it to formik values
                    formik.setFieldValue('city', inputValue);
                }}
                name="city"
                value={formik.values.city ? { label: formik.values.city, value: formik.values.city } : null}
            />
            {formik.errors.city && formik.touched.city && (
                <Form.Control.Feedback type="invalid">
                    {formik.errors.city}
                </Form.Control.Feedback>
            )}
        </Form.Group>
</Col>
}
                </Row>
                <Row>
                    <Col xl={4}>
                        <Form.Group key={'phone'} controlId="validationFormik3" className="mb-3">
                            <Form.Label className={'required-label'}><strong>Phone</strong></Form.Label><br></br>
                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                                      className="form-control"
                                                                      onChange={(val) => {
                                                                          formik.values.phone = val;
                                                                      }}
                                                                      name='phone'
                                                                      id='phone'
                                                                      value={formik.values.phone}
                                                                      isInvalid={!!formik.errors.phone}/>
                            {(formik.values.phone === null || formik.values.phone === '') && formik.errors.phone && (
                                <div className="text-error">{formik.errors.phone}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group key={'fax'} controlId="validationFormik4" className="mb-3">
                            <Form.Label><strong>Fax</strong></Form.Label><br></br>
                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                                      className="form-control"
                                                                      onChange={(val) => {
                                                                          formik.values.fax = val;
                                                                      }}
                                                                      name='fax'
                                                                      id='fax'
                                                                      value={formik.values.fax}
                                                                      isInvalid={!!formik.errors.fax}/>
                            {(formik.values.fax === null || formik.values.fax === '') && formik.errors.fax && (
                                <div className="text-error">{formik.errors.fax}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                {hasUserPermission(user_permission, 'show_customer_number_for_law_firm') && <Row>
                    <Col xl={4}>
                        <Form.Group key={'customer_number'} controlId="validationFormik02" className="mb-3">
                            <Form.Label><strong>Customer Number</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          name='customer_number'
                                          value={formik.values.customer_number}
                                          error={formik.errors.customer_number}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.customer_number}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                }

                <Form.Group key={'use_seperate_firm_accounting_contact'} controlId="validationFormik10"
                            className="mb-3">
                    <Form.Check
                        type="checkbox"
                        id="use_seperate_firm_accounting_contact"
                        label={<strong>Use seperate Accounting Contact for this location</strong>}
                        onChange={(e) => {
                            log("e.target.checked,", e.target.checked);
                            formik.setFieldValue("use_seperate_firm_accounting_contact", e.target.checked);
                        }}
                        checked={formik.values.use_seperate_firm_accounting_contact}
                    />
                </Form.Group>
                <Row hidden={formik.values.use_seperate_firm_accounting_contact == false}>
                    <Col xl={4}>
                        <Form.Group key={'accounting_contact_full_name'} controlId="validationFormik11"
                                    className="mb-3">
                            <Form.Label><strong>Accounting Contact Full Name</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          name='accounting_contact_full_name'
                                          value={formik.values.accounting_contact_full_name}
                                          isInvalid={!!formik.errors.accounting_contact_full_name}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.accounting_contact_full_name}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group key={'accounting_contact_email'} controlId="validationFormik12" className="mb-3">
                            <Form.Label><strong>Accounting Contact Email</strong></Form.Label>
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          name='accounting_contact_email'
                                          value={formik.values.accounting_contact_email}
                                          isInvalid={!!formik.errors.accounting_contact_email}/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.accounting_contact_email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group key={'accounting_contact_phone'} controlId="validationFormik013" className="mb-3">
                            <Form.Label><strong>Accounting Contact Phone</strong></Form.Label><br></br>
                            <USPhoneNumberWithoutCountryCodeUniversal
                                required={false}
                                className="form-control"
                                onChange={(val) => {
                                    formik.values.accounting_contact_phone = val;
                                }}
                                value={formik.values.accounting_contact_phone}
                                name='accounting_contact_phone'
                                isInvalid={!!formik.errors.accounting_contact_phone}
                                id='accounting_contact_phone1'/>
                            {(formik.values.accounting_contact_phone === null || formik.values.accounting_contact_phone === '') && formik.errors.accounting_contact_phone && (
                                <div className="text-error">{formik.errors.accounting_contact_phone}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <h5>Use this location as...</h5>
                    <Col xl={4}>
                        <Form.Group key={'physical_address'} controlId="validationFormik15"
                                    className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="physical_address"
                                label={<strong>Physical Address</strong>}
                                onChange={formik.handleChange}
                                checked={formik.values.physical_address}
                            />
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group key={'mailing_address'} controlId="validationFormik16"
                                    className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="mailing_address"
                                label={<strong>Mailing Address</strong>}
                                onChange={formik.handleChange}
                                checked={formik.values.mailing_address}
                            />
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Group key={'billing_address'} controlId="validationFormik17"
                                    className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="billing_address"
                                label={<strong>Billing Address</strong>}
                                onChange={formik.handleChange}
                                checked={formik.values.billing_address}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {(super_user && props.id !== undefined && props.id !== 0) &&
                    <Form.Group key={'is_active'} controlId="validationFormik14" className="mb-3">
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="is_active"
                            label="Is Active"
                            onChange={formik.handleChange}
                            checked={formik.values.is_active === true}
                        />
                    </Form.Group>
                }
                <Form.Group key={'submit_button'} controlId="validationFormik15" className="mb-3 text-right">
                    <Button type="submit" variant="primary">Submit </Button>
                </Form.Group>
            </Form>
        </>
    )
}


export default LawFirmLocation;
