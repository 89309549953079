import React, {useEffect} from 'react';
import '../../styles/login.css';
import {useNavigate} from "react-router";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";

function ReserveBondBlock(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
    }, [])

    return (
        <>
            <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 spaceCard">
                <div className="card">
                    <div className="card-body">
                        <button onClick={() => props.callback(true)} className="stretched-link">
                            <h4 className="card-title">Reserve Bond Number</h4>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}


export default ReserveBondBlock;

ReserveBondBlock.propTypes = {
    callback: PropTypes.func.isRequired,
};
