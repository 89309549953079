import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import {
    Card,
    CardContent,
    Checkbox,
    Container,
    Tooltip,
    FormControlLabel,
} from "@mui/material";
import {ArrowRight, ArrowLeft} from 'react-bootstrap-icons';
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import CustomNumberInput from "../../global_components/inputs/number";
import {API_URL, states, vendor_companies} from "../../constants";
import {formatDateMDY} from "../../services/helper";
import CustomSelectInput from "../../global_components/inputs/select-field";
import {get_insurance_quote_history, get_insurance_vendor_creds} from "../../services/portal_insurance";
import {Logout} from "../../utils/user_logout";
import {useSnackbar} from "notistack";
import {
    removeCompany,
    removeDepartmentApprovementLevel,
    removeDepartments,
    removeSelectedDepartment, removeSelectedPermissionAP,
    removeToken
} from "../../store/auth";
import {removeCategories, removeInvoicesDashboard} from "../../store/dashboard";
import {removeProcess, setCurrentActive} from "../../store/processes";
import {removeAccountNumbers, removeAllInvoices} from "../../store/invoices";
import {
    removeAPPermissions, removeBondNumbers,
    removeDepartmentsCM,
    removeLawFirmBranches,
    removeLawFirmContacts,
    removeLawFirmProfessionalGuardians,
    removeLawFirms,
    removeLawSchools,
    removePOPermissions,
    removeProcessPermissions,
    removeSuperPOUsers,
    removeUsers
} from "../../store/company_management";
import {removeDocuments} from "../../store/document_approval";
import {removeProcessDetails, removeProcessHistory, removeProcessInputs} from "../../store/capital_project";
import {
    removeBondProcessDetails,
    removeBondProcessHistory,
    removeBondProcessInputs,
    removeLawFirmData
} from "../../store/bond";

export default function CarrierSelection(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyData = useSelector((state) => state.auth.company);
    const [orionSelect, setOrionSelect] = useState(props.formIK.values.orion);
    const [swyfftSelect, setSwyfftSelect] = useState(props.formIK.values.swyfft);
    const [ASIProgressiveSelect, setASIProgressiveSelect] = useState(props.formIK.values.asi_progressive);
    const [SSIUSelect, setSSIUSelect] = useState(props.formIK.values.ssiu);
    const [sageSureSelect, setSageSureSelect] = useState(props.formIK.values.sage_sure);
    const [openlySelect, setOpenlySelect] = useState(props.formIK.values.openly);
    const [branchSelect, setBranchSelect] = useState(props.formIK.values.branch);
    const [orionDisabled, setOrionDisabled] = useState(false);
    const [orionMessage, setOrionMessage] = useState('');
    const [swyfftDisabled, setSwyfftDisabled] = useState(false);
    const [swyfftMessage, setSwyfftMessage] = useState('');
    const [ASIProgressiveDisabled, setASIProgressiveDisabled] = useState(false);
    const [ASIProgressiveMessage, setASIProgressiveMessage] = useState('');
    const [SSIUDisabled, setSSIUDisabled] = useState(false);
    const [SSIUMessage, setSSIUMessage] = useState('');
    const [sageSureDisabled, setSageSureDisabled] = useState(false);
    const [sageSureMessage, setSageSureMessage] = useState('');
    const [openlyDisabled, setOpenlyDisabled] = useState(false);
    const [openlyMessage, setOpenlyMessage] = useState('');
    const [branchDisabled, setBranchDisabled] = useState(false);
    const [branchMessage, setBranchMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const {enqueueSnackbar} = useSnackbar();
    let prePopulated = [];

    let nextEnable = false;
    let selectAll = false;
    if (companyData.name === 'Portal Insurance') {
        nextEnable = !orionSelect && !swyfftSelect && !sageSureSelect && !SSIUSelect && !openlySelect &&
            !ASIProgressiveSelect && !branchSelect;
        selectAll = !((orionSelect || orionDisabled) && (swyfftSelect || swyfftDisabled)  && (ASIProgressiveSelect || ASIProgressiveDisabled)  && (SSIUSelect || SSIUDisabled)  && (sageSureSelect || sageSureDisabled) &&
            ((openlySelect) || openlyDisabled) && (branchSelect || branchDisabled));
    } else {
        nextEnable = !orionSelect && !swyfftSelect && !sageSureSelect && !SSIUSelect && !openlySelect &&
            !ASIProgressiveSelect;
        selectAll = !((orionSelect || orionDisabled) && (swyfftSelect || swyfftDisabled) && (ASIProgressiveSelect || ASIProgressiveDisabled) && (SSIUSelect || SSIUDisabled) && (sageSureSelect || sageSureDisabled) &&
            (openlySelect) || openlyDisabled);
    }
    const logout = ()=>{
        enqueueSnackbar('You are logged out. Redirecting to login page in 3 seconds.', {variant: "error"});
        setTimeout(() => {
            localStorage.clear();
            dispatch(removeToken());
            dispatch(removeCompany());
            dispatch(removeInvoicesDashboard());
            dispatch(removeCategories());
            dispatch(removeProcess());
            dispatch(setCurrentActive());
            dispatch(removeAllInvoices());
            dispatch(removeAccountNumbers());
            dispatch(removeDepartments());
            dispatch(removeSelectedDepartment());
            dispatch(removeDepartmentApprovementLevel());
            dispatch(removeSelectedPermissionAP());
            dispatch(removeUsers());
            dispatch(removeDepartmentsCM());
            dispatch(removeAPPermissions());
            dispatch(removePOPermissions());
            dispatch(removeSuperPOUsers());
            dispatch(removeProcessPermissions());
            dispatch(removeDocuments());
            dispatch(removeProcessHistory());
            dispatch(removeProcessDetails());
            dispatch(removeProcessInputs());
            dispatch(removeBondProcessInputs());
            dispatch(removeBondProcessDetails());
            dispatch(removeBondProcessHistory());
            dispatch(removeLawFirmData());
            dispatch(removeLawFirms());
            dispatch(removeLawFirmContacts());
            dispatch(removeLawFirmBranches());
            dispatch(removeLawSchools());
            dispatch(removeLawFirmProfessionalGuardians());
            dispatch(removeBondNumbers());
            navigate("/");

        }, 3000);
    }

    const nextClicked = async () => {
        if (!localStorage.getItem('userdata')) {
            logout();
            return;
        }
        let error = false;
        if (props.formIK.values.agency_zoom_id !== "") {
            props.setLoading(true);
            props.setLoadingText('Fetching data from agency zoom.')
            await fetch(
                API_URL + '/portal_insurance/get_agency_zoom_data/',
                {
                    method: "POST",
                    body: JSON.stringify({leadID: props.formIK.values.agency_zoom_id}),
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('userdata'),
                        "Content-Type": "application/json",
                    },
                }
            ).then(async (res) => {
                if (res.status === 200) {
                    let data = await res.json();
                    if (data.firstname) {
                        props.formIK.values['first_name'] = data.firstname;
                        prePopulated.push('first_name');
                    }
                    if (data.middlename) {
                        props.formIK.values['middle_name'] = data.middlename;
                        prePopulated.push('middle_name');
                    }
                    if (data.lastname) {
                        props.formIK.values['last_name'] = data.lastname;
                        prePopulated.push('last_name');
                    }
                    if (data.phone) {
                        let phone = data.phone.replaceAll('-', ' ').split(' ');
                        props.formIK.values['cell_phone'] = `+1 (${phone[0]}) ${phone[1]} ${phone[2]}`;
                        prePopulated.push('cell_phone');
                    }
                    if (data.state) {
                        props.formIK.values['state'] = states.filter((state) => state[1] === data.state)[0][0];
                        props.formIK.values['prior_state'] = states.filter((state) => state[1] === data.state)[0][0];
                        prePopulated.push('state');
                        prePopulated.push('prior_state');
                    }
                    if (data.city) {
                        props.formIK.values['city'] = data.city;
                        props.formIK.values['prior_city'] = data.city;
                        prePopulated.push('city');
                        prePopulated.push('prior_city');
                    }
                    if (data.zip) {
                        props.formIK.values['zip'] = data.zip;
                        props.formIK.values['prior_zip'] = data.zip;
                        prePopulated.push('zip');
                        prePopulated.push('prior_zip');
                    }
                    if (data.birthday) {
                        props.formIK.values['date_of_birth'] = formatDateMDY(data.birthday);
                        prePopulated.push('date_of_birth');
                    }
                    if (data.maritalStatus) {
                        props.formIK.values['marital_status'] = data.maritalStatus;
                        prePopulated.push('marital_status');
                    }
                    if (data.streetAddress) {
                        props.formIK.values['address'] = data.streetAddress;
                        props.formIK.values['prior_address'] = data.streetAddress;
                        prePopulated.push('address');
                        prePopulated.push('prior_address');
                    }
                }
                localStorage.removeItem('agencyZoomPrePopulated');
                localStorage.setItem('agencyZoomPrePopulated', JSON.stringify(prePopulated));
            }).catch((error) => {
                console.log(error);
                localStorage.removeItem('agencyZoomPrePopulated');
                localStorage.setItem('agencyZoomPrePopulated', JSON.stringify(prePopulated));
            })
            props.setLoading(false);
        } else {
            localStorage.removeItem('agencyZoomPrePopulated');
            localStorage.setItem('agencyZoomPrePopulated', JSON.stringify(prePopulated));
        }

        if (props.formIK.values.client_type === "Renewal" && companyData.name !== "LTN Insurance") {
            if (props.formIK.values.agency_zoom_id === "" || props.formIK.values.agency_zoom_id === 0) {
                props.formIK.errors['agency_zoom_id'] = 'Agency Zoom ID is required.';
                error = true;
            }
        }
        if (error === true) {
            window.scroll(0, 0);
            console.log(props.formIK.errors)
        } else {
            props.changeStep(props.progress + props.step);
        }
    }

    const vendorCredentials = async () => {
        const tempListOfVendor = [];
        const vendorCredentials = await get_insurance_vendor_creds();
        if (vendorCredentials.length !== 0) {
            let tempVendors = vendor_companies;

            Object.entries(vendorCredentials.data).forEach(function ([key, value]) {
                if (value.is_active === null || value.is_active === undefined) {
                    value.is_active = true;
                }
                if ((!value.is_active) ||
                    (value.status !== null && typeof value.status !== 'undefined' && !value.status)) {
                    let prettyName = '';
                    if (key === 'orion') {
                        prettyName = 'Orion';
                    } else if (key === 'swyfft') {
                        prettyName = 'Swyfft';
                    } else if (key === 'asi_progressive') {
                        prettyName = 'ASI Progressive';
                    } else if (key === 'ssiu') {
                        prettyName = 'SSIU';
                    } else if (key === 'sage_sure') {
                        prettyName = 'Sage Sure';
                    } else if (key === 'openly') {
                        prettyName = 'Openly';
                    } else if (key === 'agency_zoom') {
                        prettyName = 'Agency Zoom';
                    } else if (key === 'branch') {
                        prettyName = 'Branch';
                    }
                    if (prettyName === '') {
                        prettyName = key;
                    }

                    if (prettyName.toLowerCase().includes('orion')) {
                        if (!value.is_active) {
                            setOrionMessage("Carrier is passive!");
                        } else {
                            setOrionMessage("Carrier has invalid credential!");
                        }
                        setOrionDisabled(true);
                    } else if (prettyName.toLowerCase().includes('swyfft')) {
                        if (!value.is_active) {
                            setSwyfftMessage("Carrier is passive!");
                        } else {
                            setSwyfftMessage("Carrier has invalid credential!");
                        }
                        setSwyfftDisabled(true);
                    } else if (prettyName.toLowerCase().includes('asi progressive')) {
                        if (!value.is_active) {
                            setASIProgressiveMessage("Carrier is passive!");
                        } else {
                            setASIProgressiveMessage("Carrier has invalid credential!");
                        }
                        setASIProgressiveDisabled(true);
                    } else if (prettyName.toLowerCase().includes('ssiu')) {
                        if (!value.is_active) {
                            setSSIUMessage("Carrier is passive!");
                        } else {
                            setSSIUMessage("Carrier has invalid credential!");
                        }
                        setSSIUDisabled(true);
                    } else if (prettyName.toLowerCase().includes('sage sure')) {
                        if (!value.is_active) {
                            setSageSureMessage("Carrier is passive!");
                        } else {
                            setSageSureMessage("Carrier has invalid credential!");
                        }
                        setSageSureDisabled(true);
                    } else if (prettyName.toLowerCase().includes('openly')) {
                        if (!value.is_active) {
                            setOpenlyMessage("Carrier is passive!");
                        } else {
                            setOpenlyMessage("Carrier has invalid credential!");
                        }
                        setOpenlyDisabled(true);
                    }
                    else if (prettyName.toLowerCase().includes('agency zoom')) {
                        if (!value.is_active) {
                            setOpenlyMessage("Carrier is passive!");
                        } else {
                            setOpenlyMessage("Carrier has invalid credential!");
                        }
                        setOpenlyDisabled(true);
                    }else if (prettyName.toLowerCase().includes('branch')) {
                        if (!value.is_active) {
                            setBranchMessage("Carrier is passive!");
                        } else {
                            setBranchMessage("Carrier has invalid credential!");
                        }
                        setBranchDisabled(true);
                    }
                }

                for (var nameKey in tempVendors) {
                    if (Object.keys(tempVendors[nameKey])[0] === key) {
                        delete tempVendors[nameKey][key];
                        break;
                    }
                }
            });
            let missingMessage = "Carrier credentials are missing!";
            for (var nameKey in tempVendors) {
                //if (Object.keys(tempVendors[nameKey])[0] === 'orion') {
                //    setOrionDisabled(true);
                //    setOrionMessage(missingMessage);
                //}
                if (Object.keys(tempVendors[nameKey])[0] === 'swyfft') {
                    setSwyfftDisabled(true);
                    setSwyfftMessage(missingMessage);
                } else if (Object.keys(tempVendors[nameKey])[0] === 'asi_progressive') {
                    setASIProgressiveDisabled(true);
                    setASIProgressiveMessage(missingMessage);
                } else if (Object.keys(tempVendors[nameKey])[0] === 'ssiu') {
                    setSSIUDisabled(true);
                    setSSIUMessage(missingMessage);
                }
                    //else if (Object.keys(tempVendors[nameKey])[0] === 'sage_sure') {
                    //    setSageSureDisabled(true);
                    //    setSageSureMessage(missingMessage);
                //}
                else if (Object.keys(tempVendors[nameKey])[0] === 'openly') {
                    setOpenlyDisabled(true);
                    setOpenlyMessage(missingMessage);
                }
                //else if (Object.keys(tempVendors[nameKey])[0] === 'branch') {
                //    setBranchDisabled(true);
                //    setBranchMessage(missingMessage);
                //}

            }
            setLoading(false);
        }
    }

    const selectAllControl = () => {
        setOrionSelect(selectAll && !orionDisabled);
        setSwyfftSelect(selectAll && !swyfftDisabled);
        setASIProgressiveSelect(selectAll && !ASIProgressiveDisabled);
        setSSIUSelect(selectAll && !SSIUDisabled);
        setSageSureSelect(selectAll && !sageSureDisabled);
        setOpenlySelect(selectAll && !openlyDisabled);
        if (companyData.name === 'Portal Insurance') {
            setBranchSelect(selectAll && !branchDisabled);
        }
        props.formIK.setFieldValue('orion', selectAll && !orionDisabled);
        props.formIK.setFieldValue('swyfft', selectAll && !swyfftDisabled);
        props.formIK.setFieldValue('asi_progressive', selectAll && !ASIProgressiveDisabled);
        props.formIK.setFieldValue('ssiu', selectAll && !SSIUDisabled);
        props.formIK.setFieldValue('sage_sure', selectAll && !sageSureDisabled);
        props.formIK.setFieldValue('openly', selectAll && !openlyDisabled);
        if (companyData.name === 'Portal Insurance') {
            props.formIK.setFieldValue('branch', selectAll && !branchDisabled);
        }
    }

    useEffect(async () => {
        if (loading) {
            vendorCredentials();
            setLoading(false);
        }
    });
    return (
        <div>
            <Container>
                <Col>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{maxWidth: "50%"}} style={{marginBottom: "2%", overflow: "visible"}}>

                            <CardContent>
                                <h4>Carriers</h4>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            control={<Checkbox checked={!selectAll}
                                                               sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                               onChange={() => {
                                                                   selectAllControl();
                                                               }}/>}
                                            label="Select All"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Tooltip title={orionDisabled && orionMessage}>
                                            <FormControlLabel
                                                control={<Checkbox checked={orionSelect} disabled={orionDisabled}
                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                   onChange={() => {
                                                                       setOrionSelect(!orionSelect);
                                                                       props.formIK.setFieldValue('orion', !orionSelect);
                                                                   }}/>}
                                                label="Orion"/>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        <Tooltip title={swyfftDisabled && swyfftMessage}>
                                            <FormControlLabel
                                                control={<Checkbox checked={swyfftSelect} disabled={swyfftDisabled}
                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                   onChange={() => {
                                                                       setSwyfftSelect(!swyfftSelect);
                                                                       props.formIK.setFieldValue('swyfft', !swyfftSelect);
                                                                   }}/>}
                                                label="Swyfft"/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Tooltip title={ASIProgressiveDisabled && ASIProgressiveMessage}>
                                            <FormControlLabel
                                                control={<Checkbox checked={ASIProgressiveSelect}
                                                                   disabled={ASIProgressiveDisabled}
                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                   onChange={() => {
                                                                       setASIProgressiveSelect(!ASIProgressiveSelect);
                                                                       props.formIK.setFieldValue('asi_progressive', !ASIProgressiveSelect);
                                                                   }}/>}
                                                label="ASI Progressive"/>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        <Tooltip title={SSIUDisabled && SSIUMessage}>
                                            <FormControlLabel
                                                control={<Checkbox checked={SSIUSelect} disabled={SSIUDisabled}
                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                   onChange={() => {
                                                                       setSSIUSelect(!SSIUSelect);
                                                                       props.formIK.setFieldValue('ssiu', !SSIUSelect);
                                                                   }}/>}
                                                label="SSIU"/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Tooltip title={sageSureDisabled && sageSureMessage}>
                                            <FormControlLabel
                                                control={<Checkbox checked={sageSureSelect} disabled={sageSureDisabled}
                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                   onChange={() => {
                                                                       setSageSureSelect(!sageSureSelect);
                                                                       props.formIK.setFieldValue('sage_sure', !sageSureSelect);
                                                                   }}/>}
                                                label="Sage Sure"/>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        <Tooltip title={openlyDisabled && openlyMessage}>
                                            <FormControlLabel
                                                control={<Checkbox checked={openlySelect} disabled={openlyDisabled}
                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                   onChange={() => {
                                                                       setOpenlySelect(!openlySelect);
                                                                       props.formIK.setFieldValue('openly', !openlySelect);
                                                                   }}/>}
                                                label="Openly"/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                {
                                    companyData.name === 'Portal Insurance' && (
                                        <Row>
                                            <Col>
                                                <Tooltip title={branchDisabled && branchMessage}>
                                                    <FormControlLabel
                                                        control={

                                                            <Checkbox checked={branchSelect}
                                                                      disabled={branchDisabled}
                                                                      sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                      onChange={() => {
                                                                          setBranchSelect(!branchSelect);
                                                                          props.formIK.setFieldValue('branch', !branchSelect);
                                                                      }}/>


                                                        }
                                                        label="Branch"/>

                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    )
                                }
                                <Row>
                                    <Col>
                                        <Form.Group key="client_type" controlId="formBasicEmail"
                                                    style={{padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Client
                                                Type</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="client_type"
                                                               value={props.formIK.values.client_type !== undefined ? props.formIK.values.client_type : ""}
                                                               onChange={(value) => {
                                                                   if (value === "") {
                                                                       props.formIK.errors['client_type'] = "Client Type is required.";
                                                                   } else {
                                                                       delete props.formIK.errors['client_type'];
                                                                   }
                                                                   if (value !== "Renewal") {
                                                                       props.formIK.setFieldValue("agency_zoom_id", "");
                                                                   }
                                                                   props.formIK.setFieldValue("client_type", value)
                                                               }}
                                                               options={[{label: 'Renewal', value: 'Renewal'},
                                                                   {
                                                                       label: 'Send email only',
                                                                       value: 'Send email only'
                                                                   }]}/>
                                            {props.formIK.errors["client_type"] && (
                                                <div className="text-error">{props.formIK.errors["client_type"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    {
                                        (props.formIK.values.client_type === "" || props.formIK.values.client_type === "Send email only") && (
                                            <Col></Col>
                                        )
                                    }
                                    {
                                        props.formIK.values.client_type === "Renewal" && (
                                            <Col>
                                                <Form.Group key="agency_zoom_id" controlId="formBasicEmail"
                                                            style={{padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label
                                                        className={companyData.name !== "LTN Insurance" ? 'required-label' : ""}><strong>Agency
                                                        Zoom ID</strong></Form.Label>
                                                    <CustomNumberInput disabled={false} required={true}
                                                                       name="agency_zoom_id"
                                                                       className={"form-control"}
                                                                       id="agency_zoom_id-input"
                                                                       value={props.formIK.values.agency_zoom_id}
                                                                       onChange={(value) => {
                                                                           if (companyData.name !== 'LTN Insurance' && value === "") {
                                                                               props.formIK.errors['agency_zoom_id'] = "Agency Zoom ID is required.";
                                                                           } else {
                                                                               delete props.formIK.errors['agency_zoom_id'];
                                                                           }
                                                                           props.formIK.setFieldValue("agency_zoom_id", value);
                                                                       }}/>
                                                    <div className="text-error"
                                                         key={props.formIK.errors["agency_zoom_id"]}>{props.formIK.errors["agency_zoom_id"]}</div>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={props.progress === 0} className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    nextClicked()
                                }}
                                        disabled={nextEnable}
                                        className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

CarrierSelection.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    setLoading: PropTypes.func.isRequired,
    setLoadingText: PropTypes.func.isRequired
};
