import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    process_details: {},
    capital_process_history: [],
    popup_inputs: []
};

const capitalProjectSlice = createSlice({
    name: 'capital_project',
    initialState,
    reducers: {
        addProcessDetails(state, action){
            state.process_details = action.payload;
        },
        removeProcessDetails(state, action){
            state.process_details = {};
        },
        addProcessHistory(state, action){
            state.capital_process_history = action.payload;
        },
        addNewProcessHistory(state, action){
            state.capital_process_history.push(action.payload);
        },
        removeProcessHistory(state, action){
            state.capital_process_history = [];
        },
        addProcessInputs(state, action){
            state.popup_inputs = action.payload;
        },
        removeProcessInputs(state, action){
            state.popup_inputs = [];
        },
    }
});

export const  {addProcessHistory, addNewProcessHistory, removeProcessHistory, addProcessInputs, removeProcessInputs,
    addProcessDetails, removeProcessDetails } = capitalProjectSlice.actions;

export default capitalProjectSlice.reducer;
