import React from "react"
import ContentLoader from "react-content-loader"

const APEditFormLoading = (props) => (
    <ContentLoader
        speed={2}
        width={1000}
        height={724}
        viewBox="0 0 1000 724"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
        <rect x="0" y="30" rx="3" ry="3" width="200" height="25" />
        <rect x="0" y="70" rx="3" ry="3" width="100" height="20" />
        <rect x="0" y="100" rx="3" ry="3" width="200" height="25" />
        <rect x="0" y="140" rx="3" ry="3" width="100" height="20" />
        <rect x="0" y="170" rx="3" ry="3" width="200" height="25" />
        <rect x="0" y="210" rx="3" ry="3" width="100" height="20" />
        <rect x="0" y="240" rx="3" ry="3" width="200" height="25" />
        <rect x="0" y="280" rx="3" ry="3" width="100" height="20" />
        <rect x="0" y="310" rx="3" ry="3" width="200" height="25" />
        <rect x="0" y="360" rx="3" ry="3" width="100" height="20" />
        <rect x="30" y="390" rx="3" ry="3" width="100" height="20" />
        <rect x="30" y="420" rx="3" ry="3" width="200" height="25" />
        <rect x="30" y="460" rx="3" ry="3" width="100" height="20" />
        <rect x="30" y="490" rx="3" ry="3" width="200" height="25" />
        <rect x="400" y="0" rx="3" ry="3" width="800" height="700" />
    </ContentLoader>
)

export default APEditFormLoading;

