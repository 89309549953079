import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CusNavBar from "../../../../../global_components/navbar";
import Footer from "../../../../../global_components/footer";
import ArticleIcon from '@mui/icons-material/Article';
import {useNavigate} from "react-router";

function AccountManagementHome(props) {
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const navigate = useNavigate();

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);

    };

    return (
        <> <CusNavBar/>
            <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                <List title="Account Management" component="nav" aria-label="main mailbox folders">
                    <ListItemButton style={{background: '#e3f2fd'}}>
                        <ListItemText primary="Account Management"/>
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) => {
                            handleListItemClick(event, 0);
                            localStorage.setItem("isInternalFlow", "true")
                            navigate("/user_list");
                        }}>
                        <ListItemIcon>
                            <ArticleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Users"/>
                    </ListItemButton>

                    <ListItemButton
                        selected={selectedIndex === 1}
                        onClick={(event) => {
                            handleListItemClick(event, 1);
                            localStorage.setItem("isInternalFlow", "false")
                            navigate("/law_firm_list");
                        }}>
                        <ListItemIcon>
                            <ArticleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Law Firms"/>
                    </ListItemButton>

                    {/*<ListItemButton
                        selected={selectedIndex === 3}
                        onClick={(event) => {
                            handleListItemClick(event, 3);
                            navigate("/location_list");
                        }}>
                        <ListItemIcon>
                            <ArticleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Law Firm Locations"/>
                    </ListItemButton> */}

                    <ListItemButton
                        selected={selectedIndex === 2}
                        onClick={(event) => {
                            handleListItemClick(event, 2);
                            navigate("/bond_number_list");
                        }
                        }>
                        <ListItemIcon>
                            <ArticleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Bond Numbers"/>
                    </ListItemButton>

                    <ListItemButton
                        hidden={true}
                        selected={selectedIndex === 4}
                        onClick={(event) => {
                            handleListItemClick(event, 4);
                            navigate("/professional_guardian_list");
                        }
                        }>
                        <ListItemIcon>
                            <ArticleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Professional Guardians"/>
                    </ListItemButton>
                </List>

            </Box>
            <br/>
            <Footer/>
        </>
    )
}

export default AccountManagementHome;