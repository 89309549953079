import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {Card, CardContent, Typography} from "@mui/material";

function LawFirmHistory(props) {
    return (
        <>
            <Card sx={{width: '100%', height: '100%'}}>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        Firm History
                    </Typography>
                    <hr className="fs-1"/>
                    <Typography style={{padding: "51px 0 51px 5%"}}>
                        <Typography gutterBottom variant="h6" component="div">
                            Last Update
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {props.lastUpdate}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="div">
                            Last Bond
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {props.lastBond}
                        </Typography>
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default LawFirmHistory;
