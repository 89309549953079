import React, {useState} from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form} from "react-bootstrap";
import '../../styles/login.css';

export default function APCheckAmountPopup(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <strong>Invoice Amount : {props.amount.toString().includes('$') ? props.amount : '$ ' + props.amount.toLocaleString(navigator.languages[0])}</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>This exceeds the invoice threshold. Are you sure you want to continue?</Form.Label>
            </Modal.Body>
            <Modal.Footer>
                {
                    <Button variant="success" type="submit" onClick={(e) => {
                        props.onCallback(true);
                    }}>Yes</Button>

                }
                {
                    <Button onClick={(e) => {
                        e.preventDefault();
                        props.closePopup(false);
                    }}>No</Button>

                }
            </Modal.Footer>
        </Modal>
    )
}

APCheckAmountPopup.propTypes = {
    amount: PropTypes.number.isRequired,
    onCallback: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
}
