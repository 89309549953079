import React, {useEffect, useState} from 'react';
import '../styles/login.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {useNavigate} from "react-router";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import CusNavBar from "./navbar";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import {useSearchParams} from "react-router-dom";
import {addUserBackend} from "../services/company_management";
import CusAlert from "./alert";
import Select from "react-select";
import {API_URL} from "../constants";
import LoadingOverlay from 'react-loading-overlay';

function AddUser(props){
    const navigate = useNavigate();
    const [loginBtnDisabled, setLoginBtnDisabled] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [ searchParams , setSearchParams ] = useSearchParams();
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [departments, setDepartments] = useState([]);
    const company_id = searchParams.get('cID') || '';
    const email_id = searchParams.get('eID') || '';

    const getDepartmentOptions = () => {
        let depOpts = [];
        departments.map((dep)=> depOpts.push({label: dep.name, value: dep.id}))
        return depOpts;
    }


    useEffect(() => {
        if (company_id === '' || email_id === ''){
            navigate("/dashboard");
        }
        if(company_id === "Vermost"){
            navigate(`/register_vermost?eID=${email_id}`);
        }
        if(company_id !== "Vermost") {
            document.title = "Register";
            fetch(`${API_URL}/department_without_auth/${company_id}/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async (res) => {
                if (res.ok && res.status === 200) {
                    const deps = await res.json();
                    setDepartments(deps['departments']);
                    setLoading(false);
                } else {
                    navigate("/dashboard");
                }
            }).catch((error) => {
                console.log(error);
                navigate("/dashboard");
            })
        }
    },[])

    const formIk = useFormik({
        initialValues: {email: email_id, password: '', confirm_password: '', first_name: '', last_name: '',
            department_id: '', buyer_id: ''},
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLoginBtnDisabled(true);
            const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (values.password !== values.confirm_password){
                setLoginBtnDisabled(false);
                formIk.errors['confirm_password'] = 'Confirm password does not match password.';
            }
            if (re.test(values.email)){
                const data = {...values, company_id: company_id}
                addUserBackend(data).then((res)=>{
                    if(res === 201){
                        setLoginBtnDisabled(false);
                        navigate("/?uc=1");
                    }
                }).catch((error)=>{
                    console.log(error);
                    setLoginBtnDisabled(false);
                    setShowFailureMessage(true);
                });
            }else{
                setLoginBtnDisabled(false);
                formIk.errors['email'] = 'Invalid Email Format.';
            }
            setSubmitting(false);
        },
        validationSchema: Yup.object({
            email: Yup.string(),
            password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ , 'Invalid format. Please provide 1 upper ' +
                'case, 1 number, 1 special character and total 8 characters.').required('Password is required'),
            confirm_password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ , 'Invalid format. Please provide 1 upper ' +
                'case, 1 number, 1 special character and total 8 characters.').required('Confirm password is required'),
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            department_id: Yup.string().required('Department is required.'),
            buyer_id: Yup.string().required('Buyer ID is required.'),
        })
    })

    return(
        <LoadingOverlay
            active={loading}
            spinner
            text='Fetching data...'
        >
            <CusNavBar />
            {
                showFailureMessage && <CusAlert alertType='danger' alertMessage={"Unable to create user."}
                                                alertClose={setShowFailureMessage} />
            }
            <div
                className="inset-x-0 shadow-xl bg-white w-3/4 md:w-3/5 mx-auto -mt-1 rounded-lg rounded-t-none p-4 top-1/2 bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 lg:p-7 dark:bg-gray-800 dark:border-gray-700">
                <form className="space-y-6" id="login-form" onSubmit={formIk.handleSubmit}>
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">Register</h5>
                    <Row>
                        <Col>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
                                <input defaultValue={email_id} disabled={true} type="email" name="email" id="email" style={{color: "black"}}
                                       className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                       placeholder="name@company.com" onBlur={formIk.handleBlur} onChange={formIk.handleChange} required />
                                {formIk.errors['email'] && formIk.touched['email'] && (
                                    <div className="text-error">{formIk.errors['email']}</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <label htmlFor="buyer_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Buyer ID</label>
                                <input type="text" name="buyer_id" id="buyer_id" style={{color: "black"}}
                                       className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                       onBlur={formIk.handleBlur} onChange={formIk.handleChange} required />
                                {formIk.errors['buyer_id'] && formIk.touched['buyer_id'] && (
                                    <div className="text-error">{formIk.errors['buyer_id']}</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group key={'department_id'} controlId="formFileSm" className="mb-3">
                                <Form.Label className={'required-label'}>Department</Form.Label>
                                <Select
                                    placeholder={"Select Department"}
                                    name="department_id"
                                    onChange={(opt, e) => {
                                        formIk.setFieldValue('department_id', opt.value);
                                    }}
                                    options={getDepartmentOptions()}
                                />
                                {formIk.errors['department_id'] && (
                                    <div className="text-error">{formIk.errors['department_id']}</div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">First Name</label>
                                <input type="text" name="first_name" id="first_name" style={{color: "black"}}
                                       className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                       onBlur={formIk.handleBlur} onChange={formIk.handleChange} required />
                                {formIk.errors['first_name'] && formIk.touched['first_name'] && (
                                    <div className="text-error">{formIk.errors['first_name']}</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Last Name</label>
                                <input type="text" name="last_name" id="last_name" style={{color: "black"}}
                                       className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                       onBlur={formIk.handleBlur} onChange={formIk.handleChange} required />
                                {formIk.errors['last_name'] && formIk.touched['last_name'] && (
                                    <div className="text-error">{formIk.errors['last_name']}</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password</label>
                                <Input style={{padding: "1%", color: "black"}}
                                       name="password" id="password" placeholder="••••••••"
                                       className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                       type={showNewPassword ? "text" : "password"}
                                       onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                       endAdornment={
                                           <InputAdornment position="end">
                                               <IconButton
                                                   onClick={()=>setShowNewPassword(!showNewPassword)}
                                                   onMouseDown={(e)=>e.preventDefault()}
                                               >
                                                   {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                               </IconButton>
                                           </InputAdornment>
                                       }
                                />
                                {formIk.errors['password'] && formIk.touched['password'] && (
                                    <div className="text-error">{formIk.errors['password']}</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Confirm Password</label>
                                <Input style={{padding: "1%", color: "black"}}
                                       name="confirm_password" id="confirm_password" placeholder="••••••••"
                                       className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                       type={showConfirmPassword ? "text" : "password"}
                                       onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                       endAdornment={
                                           <InputAdornment position="end">
                                               <IconButton
                                                   onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                                                   onMouseDown={(e)=>e.preventDefault()}
                                               >
                                                   {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                               </IconButton>
                                           </InputAdornment>
                                       }
                                />
                                {formIk.errors['confirm_password'] && formIk.touched['confirm_password'] && (
                                    <div className="text-error">{formIk.errors['confirm_password']}</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {
                        !loginBtnDisabled ?
                            <>
                                <button  onClick={() => formIk.submitForm()}
                                         className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Register your account
                                </button>
                            </>:
                            <Button variant="primary" className="loadingBtn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </Button>
                    }
                </form>
            </div>
        </LoadingOverlay>
    )
}


export default AddUser;
