import {Button, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import USPhoneNumberWithoutCountryCodeUniversal from "../../../global_components/inputs/us_phone_number_without_country_code";
import * as yup from 'yup';
import {useFormik} from "formik";
import {log} from "../../../utils/logger";
import {API_URL} from "../../../constants";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";

function LawFirmLocationMain(props) {
    const navigate = useNavigate();
    const routeParams = useParams();
    const [lawFirmLocation, setLawFirmLocation] = useState({});
    const [loading, setLoading] = useState(true);
    const [lawFirm, setLawFirm] = useState({
        accounting_contact_full_name: "",
        accounting_contact_email: "",
        accounting_contact_phone: "",
    });
    const getLawFirm = async () => {
        const url = `/bond/get_law_firm/${props.lawId}`
        try {
            const token = localStorage.getItem('userdata');
            const response = await fetch(`${API_URL}${url}`, {
                method: "GET", headers: {
                    'Authorization': 'Bearer ' + token, "Content-Type": "application/json",
                },
            });
            setLoading(true);
            if (response.status === 200) {
                const result = await response.json();
                let law_firm = {}
                law_firm.accounting_contact_full_name = result.accounting_contact_full_name
                law_firm.accounting_contact_email = result.accounting_contact_email
                law_firm.accounting_contact_phone = result.accounting_contact_phone
                setLawFirm(law_firm);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            log(error);
        }

    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        // customer_number: yup.number().required().min(0),
        //accounting_contact_full_name: yup.string().required(),
        //accounting_contact_email: yup.string().matches(/\S+@\S+\.\S+/, 'Please enter a valid email.').required('Email is required.'),
        //accounting_contact_phone: yup.string().required(),
    });

    const getInitialValues = () => {

        if (props.id !== '0') {
            let data = props.data;
            return {
                name: data.name,
                customer_number: data.customer_number,
                phone: data.phone,
                fax: data.fax,
                address: data.address,
                city: data.city,
                county: data.county,
                state: data.state,
                zip_code: data.zip_code,
                use_firm_accounting_contact: data.use_firms_accounting_contact,
                accounting_contact_full_name: data.accounting_contact_full_name,
                accounting_contact_email: data.accounting_contact_email,
                accounting_contact_phone: data.accounting_contact_phone,
                is_active: data.is_active,
            }
        } else {
            return {
                name: '',
                customer_number: '',
                phone: '',
                fax: '',
                address: '',
                city: '',
                county: '',
                state: '',
                zip_code: '',
                use_firm_accounting_contact: true,
                accounting_contact_full_name: '',
                accounting_contact_email: '',
                accounting_contact_phone: lawFirm.accounting_contact_phone,
                is_active: true,
            }
        }
    }

    useEffect(async () => {
        if (props && props.lawId && props.lawId !== '0') {
            await getLawFirm();
        }
    }, []);

    const formik = useFormik({
            initialValues: getInitialValues(),
            onSubmit: async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                if (props.id !== undefined && props.id !== '0') {
                    formik.resetForm({values: ''});
                    props.oncallback('edit', values);
                } else {
                    if (formik.values.use_firm_accounting_contact) {
                        values.accounting_contact_full_name = lawFirm.accounting_contact_full_name
                        values.accounting_contact_email = lawFirm.accounting_contact_email
                        values.accounting_contact_phone = lawFirm.accounting_contact_phone
                    }
                    props.oncallback('insert', values);
                }
                setSubmitting(false);
            },
            validationSchema: schema
        }
    );
    return (
        <>
            <h1>{formik.values.name}</h1>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group key={'name'} controlId="validationFormik01" className="mb-3">
                    <Form.Label className={'required-label'}><strong>Location Name</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                                  name='name'
                                  value={formik.values.name}
                                  isInvalid={!!formik.errors.name}/>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.name}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group key={'customer_number'} controlId="validationFormik02" className="mb-3">
                    <Form.Label><strong>Customer Number</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                                  name='customer_number'
                                  value={formik.values.customer_number}
                                  error={formik.errors.customer_number}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.customer_number}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group key={'phone'} controlId="validationFormik3" className="mb-3">
                    <Form.Label><strong>Phone</strong></Form.Label><br></br>
                    <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                              className="form-control"
                                                              onChange={(val) => {
                                                                  formik.values.phone = val;
                                                              }}
                                                              name='phone'
                                                              id='phone'
                                                              value={formik.values.phone}
                                                              isInvalid={!!formik.errors.phone}/>
                    {(formik.values.phone === null || formik.values.phone === '') && formik.errors.phone && (
                        <div className="text-error">{formik.errors.phone}</div>
                    )}
                </Form.Group>
                <Form.Group key={'fax'} controlId="validationFormik4" className="mb-3">
                    <Form.Label><strong>Fax</strong></Form.Label><br></br>
                    <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                              className="form-control"
                                                              onChange={(val) => {
                                                                  formik.values.fax = val;
                                                              }}
                                                              name='fax'
                                                              id='fax'
                                                              value={formik.values.fax}
                                                              isInvalid={!!formik.errors.fax}/>
                    {(formik.values.fax === null || formik.values.fax === '') && formik.errors.fax && (
                        <div className="text-error">{formik.errors.fax}</div>
                    )}
                </Form.Group>
                <Form.Group key={'address'} controlId="validationFormik5" className="mb-3">
                    <Form.Label><strong>Address</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                                  name='address'
                                  value={formik.values.address}
                                  isInvalid={!!formik.errors.address}/>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.address}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group key={'city'} controlId="validationFormik6" className="mb-3">
                    <Form.Label><strong>City</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                                  name='city'
                                  value={formik.values.city}
                                  isInvalid={!!formik.errors.city}/>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.city}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group key={'county'} controlId="validationFormik17" className="mb-3">
                    <Form.Label><strong>County</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                                  name='county'
                                  value={formik.values.county}
                                  isInvalid={!!formik.errors.county}/>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.county}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group key={'state'} controlId="validationFormik8" className="mb-3">
                    <Form.Label><strong>State</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                                  name='state'
                                  value={formik.values.state}
                                  isInvalid={!!formik.errors.state}/>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.state}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group key={'zip_code'} controlId="validationFormik9" className="mb-3">
                    <Form.Label><strong>Zip Code</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                                  name='zip_code'
                                  value={formik.values.zip_code}
                                  isInvalid={!!formik.errors.zip_code}/>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.zip_code}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group key={'use_firm_accounting_contact'} controlId="validationFormik10" className="mb-3">
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="use_firm_accounting_contact"
                        label="Use Firm Accounting Contact"
                        onChange={formik.handleChange}
                        checked={formik.values.use_firm_accounting_contact === true}
                    />
                </Form.Group>
                <Form.Group key={'accounting_contact_full_name'} controlId="validationFormik11" className="mb-3">
                    <Form.Label><strong>Accounting Contact Name</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                        // onChange={(value) => {
                        //     console.log("change", value)
                        //     formik.setFieldValue('accounting_contact_full_name', value);
                        // }}
                                  disabled={formik.values.use_firm_accounting_contact}
                                  name='accounting_contact_full_name'
                                  value={formik.values.use_firm_accounting_contact ? lawFirm.accounting_contact_full_name : formik.values.accounting_contact_full_name}
                                  isInvalid={!!formik.errors.accounting_contact_full_name}/>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.accounting_contact_full_name}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group key={'accounting_contact_email'} controlId="validationFormik12" className="mb-3">
                    <Form.Label><strong>Accounting Contact Email</strong></Form.Label>
                    <Form.Control type="text" size="sm"
                                  onChange={formik.handleChange}
                                  disabled={formik.values.use_firm_accounting_contact}
                                  name='accounting_contact_email'
                                  value={formik.values.use_firm_accounting_contact ? lawFirm.accounting_contact_email : formik.values.accounting_contact_email}
                                  isInvalid={!!formik.errors.accounting_contact_email}/>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.accounting_contact_email}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group key={'accounting_contact_phone'} controlId="validationFormik013" className="mb-3">
                    <Form.Label><strong>Accounting Contact Phone</strong></Form.Label><br></br>
                    {
                        formik.values.use_firm_accounting_contact ?
                            <Form.Control type="text" size="sm"
                                          onChange={formik.handleChange}
                                          disabled={formik.values.use_firm_accounting_contact}
                                          name='accounting_contact_phone'
                                          value={lawFirm.accounting_contact_phone}
                                          isInvalid={!!formik.errors.accounting_contact_phone}/>
                            :

                            <USPhoneNumberWithoutCountryCodeUniversal
                                disabled={formik.values.use_firm_accounting_contact}
                                required={false}
                                className="form-control"
                                onChange={(val) => {
                                    formik.values.accounting_contact_phone = val;
                                }}
                                name='accounting_contact_phone'
                                value={formik.values.accounting_contact_phone}
                                isInvalid={!!formik.errors.accounting_contact_phone} id='accounting_contact_phone1'/>


                    }

                    {(formik.values.accounting_contact_phone === null || formik.values.accounting_contact_phone === '') && formik.errors.accounting_contact_phone && (
                        <div className="text-error">{formik.errors.accounting_contact_phone}</div>
                    )}
                </Form.Group>
                <Form.Group key={'is_active'} controlId="validationFormik14" className="mb-3">
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="is_active"
                        label="Is Active"
                        onChange={formik.handleChange}
                        checked={formik.values.is_active === true}
                    />
                </Form.Group>
                <Form.Group key={'submit_button'} controlId="validationFormik15" className="mb-3 text-right">
                    <Button type="submit" variant="primary">Submit </Button>
                </Form.Group>
            </Form>
        </>
    )
}


export default LawFirmLocationMain;
