import React, {useState} from "react";
import PropTypes from "prop-types";
import {Modal, Button} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import SearchLawFirm from "../vermost_components/search_law_firm";
import {deleteVermostLawFirmBackend} from "../../../services/company_management";
import {removeLawFirm} from "../../../store/company_management";
import {ConfirmationPopup} from "../../../utils/confirmation_popup";
import {useLocation, useNavigate} from "react-router";
import {useSnackbar} from "notistack";

export default function UserLawFirmInsertPopup(props) {
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    const destroyLawFirm = async () => {
        await setOpenConfirmationPopup(false);
        if (props.tempCreatedLawFirm !== 0) {
            let response = await deleteVermostLawFirmBackend(props.tempCreatedLawFirm);
            if (response.status === 200) {
                removeLawFirm(props.tempCreatedLawFirm);
            }
        }
        props.closePopup(false);
    }

    const handleConfirmationPopup = async () => {
        await setOpenConfirmationPopup(false);
        await destroyLawFirm();
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Law Firm
                    </Modal.Title>

                    <Button variant={"warning"} onClick={(e) => {
                        e.preventDefault();
                        setOpenConfirmationPopup(true);

                    }}> <CloseIcon/></Button>

                </Modal.Header>
                <Modal.Body>
                    <SearchLawFirm show={true}
                                   lawFirmEditID={props.lawFirmEditID}
                                   editID={props.editID}
                                   lawFirmData={props.lawFirmData}
                                   callback={props.callback}
                                   onCallBack={props.onCallBack}
                                   userEditPage={props.userEditPage}
                                   closePopup={props.closePopup}
                                   tempCreatedLawFirm={props.tempCreatedLawFirm}
                                   showCreateLawFirmPopup={props.showCreateLawFirmPopup}></SearchLawFirm>
                </Modal.Body>
            </Modal>

            {
                openConfirmationPopup &&
                <ConfirmationPopup show={openConfirmationPopup}
                                   handleClose={handleConfirmationPopup}
                                   setOpenConfirmationPopup={setOpenConfirmationPopup}
                                   title="Do you want to cancel all inputs?"
                />

            }
        </>
    )
}

UserLawFirmInsertPopup.propTypes = {
    lawFirmEditID: PropTypes.number,
    closePopup: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,

}
