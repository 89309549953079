import {Button, Modal} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import LawFirmMain from "../vermost_components/law_firm_main";
import React from "react";

export default function AddLawFirmPopup(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>

                <Button variant={"warning"} onClick={(e) => {
                    e.preventDefault();
                    props.closePopup(false);
                }}> <CloseIcon/></Button>

            </Modal.Header>
            <Modal.Body>
                <LawFirmMain data={props.data} id={props.id}
                             userEditPage={props.userEditPage}
                             oncallback={props.oncallback}></LawFirmMain>
            </Modal.Body>
        </Modal>
    )
}