import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {Modal, Button, Spinner, Form} from "react-bootstrap";
import {addNewProcessHistory} from "../../store/capital_project";
import CustomAmountInput from "../../global_components/inputs/amount";
import CustomLongInput from "../../global_components/inputs/long-text";
import CustomTextInput from "../../global_components/inputs/text";
import CustomSelectInput from "../../global_components/inputs/select-field";
import PropTypes from "prop-types";
import {useFormik} from "formik";
import CustomDatePicker from "../../global_components/custom_date_picker";
import {formatDate, getInitialValues} from "../../services/helper";
import * as Yup from "yup";
import {triggerProcessesWithData} from "../../services/process";
import NumberFormat from "react-number-format";

export default function CapitalProjectInputPopup(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [lineItems, setLineItems] = useState([{funding_source_budget_allocation: "", funding_source: "", notes: "", fund_name_unit_account: "", fund_budget: ""}]);
    const currentUserId = useSelector((state)=> state.auth.user.user_id);
    const capitalProjectDetailsStore = useSelector((state)=> state.capitalProject.process_details);
    const capitalProjectInputsStore = useSelector((state)=> state.capitalProject.popup_inputs);
    const normalInputs = capitalProjectInputsStore.filter((input)=>input.is_line_item===false);
    const lineItemInputs = capitalProjectInputsStore.filter((input)=>input.is_line_item===true);
    const userInStore = useSelector((state) => state.auth.user);
    const email = userInStore.email;
    const [totalBudget, setTotalBudget] = useState("");
    const [budget, setBudget] = useState("");
    const [contingencyAmount, setContingencyAmount] = useState("");
    const [totalFundingSource, setTotalFundingSource] = useState(0);
    const [fundBudget, setFundBudget] = useState(0);
    const [totalBudgetAllocation, setTotalBudgetAllocation] = useState(0);
    const wpcValue = lineItemInputs.find(input => input.name === "funding_source")?.options[0].value;
    const [validEmail, setValidEmail] = useState("");
    const validateEmail = (value) => {
        const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
        return isValidEmail ? undefined : "Invalid email format";
      };

    useEffect(()=>{
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }

    });

    useEffect(() => {
        const newTotalBudget = parseFloat(budget || 0) + parseFloat(contingencyAmount || 0);
        setTotalBudget(newTotalBudget.toFixed(2));
        
        const newTotalBudgetAllocation = lineItems.reduce((total, item) => total + parseFloat(item.funding_source_budget_allocation || 0), 0);
        setTotalBudgetAllocation(newTotalBudgetAllocation.toFixed(2));

        const newTotalFundingSource = lineItems.reduce((total, item) => total + parseFloat(item.fund_budget || 0), 0);
        setTotalFundingSource(newTotalFundingSource.toFixed(2));
      }, [budget, contingencyAmount, lineItems]);

    const getValidation = () => {
        const validation = {};
        const previous = new Date((new Date()).getTime());
        previous.setDate(previous.getDate() - 1);
        normalInputs.forEach((input)=>{
            if(input.input_type === "date"){
                validation[input.name] = input.is_required === true ? Yup.date().min(previous,
                        ({min})=> `Date need to be at least ${formatDate(new Date())}`).required(`${input.label} is required`) :
                    Yup.date().min(previous,
                        ({min})=> `Date need to be at least ${formatDate(new Date())}`).nullable();
            }else if(input.input_type === "email"){
                if(input.name!=="project_owner"){
                    validation[input.name] = input.is_required === true ? Yup.string().email("Field should contain a valid e-mail").required(`${input.label} is required`) : Yup.string().email("Field should contain a valid e-mail");
                }
            }
            else{
                validation[input.name] = input.is_required === true ? Yup.string().required(`${input.label} is required`) : Yup.string().nullable();
            }
        });
        return validation;
    }

    const formIk = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: getInitialValues(normalInputs),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            
            if(values['total_budget'] !== (values['budget'] + values['contingency_amount'])){
                formIk.errors['total_budget'] = "Total Budget must be equal to sum of Budget and Contingency Amount.";
                setLoading(false);
                return;
            }
            if(lineItems.length === 0){
                formIk.errors['line_items'] = "At least one funding source is required.";
                setLoading(false);
                return;
            }
            const lineItemBudgetTotal = lineItems.reduce((total, obj) => obj.funding_source_budget_allocation + total,0)
            if(values['total_budget'] !== lineItemBudgetTotal){
                formIk.errors['line_items'] = "Total Budget Allocation must be equal to Total Budget.";
                setLoading(false);
                return;
            }
            const api_data = {};
            normalInputs.forEach((field)=>{
                api_data[field.name] = {...field, data: values[field.name]};
            });
            api_data["line_items"] = [];
            lineItems.map((lineI, idx)=>{
                const fundingSourceBudgetAllocation = parseFloat(lineI['funding_source_budget_allocation']);
                const fundBudget = parseFloat(lineI['fund_budget']);
                if (lineI['funding_source']!==wpcValue && fundingSourceBudgetAllocation > fundBudget) {
                    formIk.errors['line_items'] = "Funding Source Budget Allocation cannot be greater than Fund Budget.";
                    setLoading(false);
                    return;
                }
                if(lineI['funding_source_budget_allocation'] === "" || lineI['funding_source_budget_allocation'] === undefined){
                    formIk.errors['line_items'] = "All funding sources must contain Funding Source Budget Allocation.";
                    setLoading(false);
                    return;
                }
                if(lineI['funding_source'] === "" || lineI['funding_source'] === undefined){
                    formIk.errors['line_items'] = "All funding sources must contain Funding Source.";
                    setLoading(false);
                    return;
                }
                api_data["line_items"][idx] = [];
                JSON.parse(JSON.stringify(lineItemInputs)).map((pli)=>{
                    api_data["line_items"][idx].push({name: pli.name, data: lineI[pli.name], ...pli});
                })
                
            });
            if (formIk.errors['line_items']) {
                setLoading(false);
                return;
              }
            const processTriggerStatus = await triggerProcessesWithData(capitalProjectDetailsStore.id, api_data, false);
            if (processTriggerStatus) {
                props.showSuccess(true);
                dispatch(addNewProcessHistory({...values, status: false, created_at: new Date(),
                    updated_at: new Date(), user: currentUserId, id: Math.floor(Math.random())}))
            } else {
                props.showFail(true);
            }
            setLoading(false);
            props.closePopup(false);
            props.refreshTable(!props.refreshGrid)
        },
        validationSchema: Yup.object(getValidation()) 
        
    });

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Provide Proposal Details.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formIk.handleSubmit}>
                    {
                        normalInputs.map((input, idx)=>{
                            if(input.input_type==='amount'){
                                if(input.label !== "Total Budget"){
                                return (
                                    <Form.Group key={idx} className="col-6" controlId="formBasicEmail"
                                                style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label className={input.is_required === true ? 'required-label' : ''}>
                                            {input.label}</Form.Label>
                                        <CustomAmountInput disabled={false} required={input.is_required} name={input.name}
                                                           className={"form-control"} id={input.name+"-input"}
                                                           value={""} onChange={(value)=>{
                                                                formIk.setFieldValue(input.name, value)
                                                                if (input.name === 'budget') {
                                                                    setBudget(value)
                                                                } else if (input.name === 'contingency_amount') {
                                                                    setContingencyAmount(value)
                                                                }
                                                                if(formIk.errors.hasOwnProperty("line_items")){
                                                                    delete formIk.errors['line_items'];
                                                                }
                                                           }}/>
                                        {formIk.errors[input.name] && formIk.touched[input.name] && (
                                            <div className="text-error">{formIk.errors[input.name]}</div>
                                        )}
                                        </Form.Group>
                                ) 
                                }
                                else if(input.label === "Total Budget"){
                                    return (
                                    <div>
                                    <Form.Group key={idx} className="col-6" controlId="formBasicEmail"
                                                style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label className={input.is_required === true ? 'required-label' : ''}>
                                            {"Total Budget"}</Form.Label>
                                        <NumberFormat disabled={true} required={input.is_required} name={input.name}
                                                           className={"form-control"} id={input.name+"-input"}
                                                           value={parseFloat(totalBudget)}
                                                           thousandSeparator={true}
                                                           prefix={'$ '}
                                                           decimalScale={2}
                                                           fixedDecimalScale={true}
                                                           onValueChange={(valueObj) => {
                                                             const { value } = valueObj;
                                                             formIk.setFieldValue(input.name, parseFloat(value));
                                                             setTotalBudget(value);
                                                                if(formIk.errors.hasOwnProperty("line_items")){
                                                                    delete formIk.errors['line_items'];
                                                                }
                                                           }}/>
                                        {formIk.errors[input.name] && formIk.touched[input.name] && (
                                            <div className="text-error">{formIk.errors[input.name]}</div>
                                        )}

                                    </Form.Group>
                                    <Form.Group className="col-6" controlId="formBasicEmail" style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label>
                                            {"Total Funding Source"}
                                        </Form.Label>
                                        <NumberFormat disabled={true} 
                                            className={"form-control"}
                                            value={totalFundingSource}
                                            thousandSeparator={true}
                                            prefix={'$ '}
                                            decimalScale={2}
                                            fixedDecimalScale={true}/>
                                        </Form.Group>
                                    </div>
                                )
                                }
                            }else if(input.input_type === 'date'){
                                return (
                                    <Form.Group key={idx} className="col-6" controlId="formBasicEmail"
                                                style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label className={input.is_required === true ? 'required-label' : ''}>
                                            {input.label}</Form.Label>
                                        <CustomDatePicker className="form-control" name={input.name} id={input.name+"-input"}
                                                          value={formatDate(new Date)} format="y-MM-dd"
                                                          onChange={(value)=>{
                                                              if (formatDate(value) === '1970-01-01'){
                                                                  formIk.setFieldValue(input.name, '');
                                                              }else{
                                                                  formIk.setFieldValue(input.name, formatDate(value))
                                                              }
                                                              if(formIk.errors.hasOwnProperty("line_items")){
                                                                  delete formIk.errors['line_items'];
                                                              }
                                                          }}
                                                          required={input.is_required} />
                                        {formIk.errors[input.name] && formIk.touched[input.name] && (
                                            <div className="text-error">{formIk.errors[input.name]}</div>
                                        )}
                                    </Form.Group>
                                )
                            }else if(input.input_type==='long_text'){
                                return (
                                    <Form.Group key={idx} className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className={input.is_required === true ? 'required-label' : ''}>
                                            {input.label}</Form.Label>
                                        <CustomLongInput disabled={false} required={input.is_required} name={input.name}
                                                         className={"form-control"} id={input.name+"-input"} value={""}
                                                         onChange={(value)=>{
                                                             formIk.setFieldValue(input.name, value)
                                                             if(formIk.errors.hasOwnProperty("line_items")){
                                                                 delete formIk.errors['line_items'];
                                                             }
                                                         }}
                                                         placeholder={input.name==="justification" ?
                                                             "Provide a rationale for undertaking the project.":""}/>
                                        {formIk.errors[input.name] && formIk.touched[input.name] && (
                                            <div className="text-error">{formIk.errors[input.name]}</div>
                                        )}
                                    </Form.Group>
                                )
                            }else if(input.input_type==='text'){
                                return (
                                    <Form.Group key={idx} className="col-6" controlId="formBasicEmail"
                                                style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label className={input.is_required === true ? 'required-label' : ''} style={{marginRight:"130px !important"}}>
                                            {input.label}</Form.Label>
                                        <CustomTextInput disabled={false} required={input.is_required} name={input.name} 
                                                         className={"form-control"} id={input.name+"-input"}
                                                         value={""} onChange={(value)=>{
                                            formIk.setFieldValue(input.name, value)
                                            if(formIk.errors.hasOwnProperty("line_items")){
                                                delete formIk.errors['line_items'];
                                            }
                                        }}/>
                                        {formIk.errors[input.name] && formIk.touched[input.name] && (
                                            <div className="text-error">{formIk.errors[input.name]}</div>
                                        )}
                                    </Form.Group>
                                )
                            }else if(input.input_type==='email'){
                                let value = '';
                                if (input.label === 'Project Owner') {
                                    value = email
                                }
                                return (
                                    <Form.Group key={idx} className="col-6" controlId="formBasicEmail"
                                                style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                                        <Form.Label className={input.is_required === true ? 'required-label' : ''}>
                                            {input.label+"'s Email"}</Form.Label>
                                        <CustomTextInput disabled={input.label === 'Project Owner'} required={input.is_required} name={input.name}
                                                         className={"form-control"} id={input.name+"-input"} onBlur={formIk.handleBlur}
                                                         value={value} placeholder={"example@email.com"} onChange={(value)=>{
                                                            if(input.name==="division_head"){
                                                                formIk.setFieldValue("project_owner", email)
                                                            }
                                                             formIk.setFieldValue(input.name, value)
                                                             if(formIk.errors.hasOwnProperty("line_items")){
                                                                 delete formIk.errors['line_items'];
                                                             }
                                                             const emailError = validateEmail(value);
                                                             if (emailError) {
                                                                formIk.setFieldError(input.name, emailError);
                                                              } else {
                                                                formIk.setFieldError(input.name, "");
                                                              }
                                                         }}/>
                                        {formIk.errors[input.name] && formIk.touched[input.name] && (
                                            <div className="text-error">{formIk.errors[input.name]}</div>
                                        )}
                                    </Form.Group>
                                )
                            }else if(input.input_type==='select'){
                                return (
                                    <Form.Group key={idx} className="col-6" controlId="formBasicEmail"
                                                style={{display: "inline-block", padding: "0px 15px 0px 0px" ,position: "relative", top: "-12px"}}>
                                        <Form.Label className={input.is_required === true ? 'required-label' : ''}>
                                            {input.label}</Form.Label>
                                        <CustomSelectInput disabled={false} name={input.name} value=""
                                                           onChange={(value)=>{
                                                               formIk.setFieldValue(input.name, value)
                                                               if(formIk.errors.hasOwnProperty("line_items")){
                                                                   delete formIk.errors['line_items'];
                                                               }
                                                           }}
                                                           options={input.options}/>
                                        {formIk.errors[input.name] && (
                                            <div className="text-error">{formIk.errors[input.name]}</div>
                                        )}
                                    </Form.Group>
                                )
                            }
                        })
                    }
                    <h4 style={{margin: "2% 0px"}} id="funding-source-label">Funding Sources Details</h4>
                    {formIk.errors.hasOwnProperty("line_items") && formIk.errors["line_items"] && (
                        <div id="line_items_error" className="text-error"><b>{formIk.errors["line_items"]}</b></div>)}
                    {
                        lineItems.map((lineItem, idx)=>{
                            return(<>
                                <h5 style={{marginTop: "1%"}}>Funding Source {idx+1}
                                    <span style={{marginLeft: "1%"}}><button className="btn btn-danger" onClick={(e)=>{
                                        e.preventDefault();
                                        let state_line_items = JSON.parse(JSON.stringify(lineItems))
                                        state_line_items.splice(idx, 1);
                                        setLineItems(state_line_items);
                                        if(formIk.errors.hasOwnProperty("line_items")){
                                            delete formIk.errors['line_items'];
                                        }
                                    }}>Remove</button>
                                </span></h5>
                                {
                                    Object.keys(lineItem).map((field) => {
                                        const lineItemFullObj = lineItemInputs.filter((liInput) => liInput.name === field)[0]
                                        if(lineItemFullObj.input_type==='amount'){
                                            if(lineItemFullObj.label==='Funding Source Budget Allocation'){
                                                return (
                                                    <Form.Group key={"line_items[" + idx + "]." + lineItemFullObj.name} className="col-6" controlId="formBasicEmail"
                                                                style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className={lineItemFullObj.is_required === true ? 'required-label' : ''}>
                                                            {lineItemFullObj.label}</Form.Label>
                                                        <CustomAmountInput disabled={false} required={lineItemFullObj.is_required}
                                                                           name={"line_items[" + idx + "]." + lineItemFullObj.name}
                                                                           className={"form-control"} id={idx+lineItemFullObj.name+"-input"}
                                                                           value={""} onChange={(value)=>{
                                                                                if(formIk.errors.hasOwnProperty("line_items")){
                                                                                    delete formIk.errors['line_items'];
                                                                                }
                                                                                let state_line_items = JSON.parse(JSON.stringify(lineItems));
                                                                                state_line_items[idx][lineItemFullObj.name] = value;
                                                                                setLineItems(state_line_items);
                                                                            }
                                                                            }/>
                                                    </Form.Group>
                                                )
                                            }
                                            else if(lineItemFullObj.label==='Fund Budget'){
                                                return (
                                                    <Form.Group key={"line_items[" + idx + "]." + lineItemFullObj.name} className="col-6" controlId="formBasicEmail"
                                                                style={{display: "inline", padding: "0px 15px 0px 0px"}}>
                                                        <Form.Label className={lineItemFullObj.is_required === true ? 'required-label' : ''}>
                                                            {lineItemFullObj.label}</Form.Label>
                                                        <NumberFormat disabled={true} required={lineItemFullObj.is_required}
                                                                           name={"line_items[" + idx + "]." + lineItemFullObj.name}
                                                                           className={"form-control"} id={idx+lineItemFullObj.name+"-input"}
                                                                           thousandSeparator={true} prefix={'$ '}
                                                                           decimalScale={2} fixedDecimalScale={true}
                                                                           value={lineItems[idx][lineItemFullObj.name]}  onValueChange={(valueObj) => {
                                                                            const { value } = valueObj;
                                                                            setFundBudget(value);
                                                                                if(formIk.errors.hasOwnProperty("line_items")){
                                                                                    delete formIk.errors['line_items'];
                                                                                }
                                                                                let state_line_items = JSON.parse(JSON.stringify(lineItems));
                                                                                state_line_items[idx][lineItemFullObj.name] = value;
                                                                                setLineItems(state_line_items);
                                                                            }
                                                                            }/>
                                                    </Form.Group>
                                                )
                                            }
                                        }else if(lineItemFullObj.input_type==='select'){
                                            const isDisabled = lineItemFullObj.name === 'fund_name_unit_account' && lineItems[idx].funding_source === wpcValue;
                                            const selectValue = isDisabled ? "" : lineItems[idx][lineItemFullObj.name];
                                            return (
                                                <Form.Group key={"line_items[" + idx + "]." + lineItemFullObj.name}
                                                            className="col-6" controlId="formBasicEmail"
                                                            style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className={lineItemFullObj.is_required === true ? 'required-label' : ''}>
                                                        {lineItemFullObj.label}</Form.Label>
                                                    <CustomSelectInput disabled={isDisabled}
                                                                       value={selectValue}
                                                                       options={lineItemFullObj.name === 'fund_name_unit_account' ? lineItemFullObj.options
                                                                       .filter(opt => opt.filter_values.includes(lineItems[idx].funding_source))
                                                                       .map(opt => ({ label: opt.value, value: opt.value })) : lineItemFullObj.options}
                                                                       onChange={(value)=>{
                                                                           let state_line_items = JSON.parse(JSON.stringify(lineItems));
                                                                           state_line_items[idx][lineItemFullObj.name] = value;
                                                                           if (lineItemFullObj.name === 'fund_name_unit_account') {
                                                                            const selectedOption = lineItemFullObj.options.find(
                                                                              (opt) => opt.value === value);
                                                                                if (selectedOption) {
                                                                                    const newBudget = selectedOption.label.split(' - Budget: ')[1];
                                                                                    const budgetAmount = parseFloat(newBudget);
                                                                                    state_line_items[idx]['fund_budget'] = budgetAmount.toFixed(2);
                                                                                }
                                                                            }
                                                                            if (value===wpcValue){
                                                                                state_line_items[idx]['fund_budget'] = 0;
                                                                            }
                                                                            if(lineItemFullObj.name === 'funding_source' && value!==""){
                                                                                state_line_items[idx]['fund_budget'] = 0;
                                                                                state_line_items[idx]['fund_name_unit_account'] = "";
                                                                            }
                                                                            setLineItems(state_line_items);

                                                                           if(formIk.errors.hasOwnProperty("line_items")){
                                                                               delete formIk.errors['line_items'];
                                                                           }
                                                                       }}/>
                                                </Form.Group>
                                            )
                                        }else if(lineItemFullObj.input_type==='text'){
                                            return (
                                                <Form.Group key={"line_items[" + idx + "]." + lineItemFullObj.name}
                                                            className="col-6" controlId="formBasicEmail"
                                                            style={{display: "inline-block", padding: "0px 15px 0px 0px",position: "relative", top: "12px"}}>
                                                    <Form.Label className={lineItemFullObj.is_required === true ? 'required-label' : ''}>
                                                        {lineItemFullObj.label}</Form.Label>
                                                    <CustomTextInput disabled={false} required={lineItemFullObj.is_required}
                                                                     name={"line_items[" + idx + "]." + lineItemFullObj.name} value={""}
                                                                     className={"form-control"} id={lineItemFullObj.name+"-input"}
                                                                     onChange={(value)=>{
                                                                     let state_line_items = JSON.parse(JSON.stringify(lineItems));
                                                                     state_line_items[idx][lineItemFullObj.name] = value;
                                                                     setLineItems(state_line_items);
                                                                     if(formIk.errors.hasOwnProperty("line_items")){
                                                                         delete formIk.errors['line_items'];
                                                                     }
                                                    }}/>
                                                    
                                                </Form.Group>
                                            )
                                        }
                                    })
                                }
                                
                            </>)
                        })
                    }
                    <div>
                    <Form.Group className="col-6" controlId="formBasicEmail" style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                        <Form.Label>
                            {"Total Budget Allocation"}
                        </Form.Label>
                        <NumberFormat disabled={true} 
                            className={"form-control"}
                            value={totalBudgetAllocation}
                            thousandSeparator={true}
                            prefix={'$ '}
                            decimalScale={2}
                            fixedDecimalScale={true}/>
                    </Form.Group>
                    <Form.Group className="col-6" controlId="formBasicEmail" style={{display: "inline-block", padding: "0px 15px 0px 0px"}}>
                        <Form.Label>
                            {"Total Funding Source"}
                        </Form.Label>
                        <NumberFormat disabled={true} 
                            className={"form-control"}
                            value={totalFundingSource}
                            thousandSeparator={true}
                            prefix={'$ '}
                            decimalScale={2}
                            fixedDecimalScale={true}/>
                    </Form.Group> 
                    </div>                    
                </Form>
            </Modal.Body>
            {
                !loading && (
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={(e)=> {
                            e.preventDefault()
                            props.closePopup(false);
                            props.refreshTable(!props.refreshGrid)
                        }}>Close</Button>
                        <Button className="btn btn-primary" onClick={(e)=> {
                            e.preventDefault();
                            if(formIk.errors.hasOwnProperty("line_items")){
                                delete formIk.errors['line_items'];
                            }
                            setLineItems([...lineItems, {funding_source_budget_allocation: "", funding_source: "", notes: "", fund_name_unit_account: "", fund_budget: ""}])
                        }}>Add Funding Source</Button>
                        <Button className="btn btn-success" type="submit" onClick={()=> formIk.submitForm()}>Submit</Button>
                    </Modal.Footer>
                )
            }
            {
                loading && (
                    <Modal.Footer>
                        <Button variant="danger" disabled>
                            <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                            Close
                        </Button>
                        <Button variant="primary" disabled>
                            <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                            Add Funding Source
                        </Button>
                        <Button variant="success" disabled>
                            <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                            Submitting...
                        </Button>
                    </Modal.Footer>
                )
            }
        </Modal>
    )
}

CapitalProjectInputPopup.propTypes = {
    closePopup: PropTypes.func.isRequired,
    showSuccess: PropTypes.func.isRequired,
    showFail: PropTypes.func.isRequired,
    refreshTable: PropTypes.func.isRequired,
    refreshGrid: PropTypes.func.isRequired,
}