import React from 'react';
import PropTypes from "prop-types";

export default function Header(props) {
    return (
        <div>
            <header style={{marginBottom: "1%"}} className="d-flex align-items-center justify-content-between text-dark
            text-decoration-none border-bottom">
                <div className="fs-1">
                    {props.text}
                </div>
                <div align={"right"}><label
                    style={{backgroundColor: "lightgoldenrodyellow"}}><b>{props.warning}</b></label>
                </div>
            </header>
            {/*<hr className="col-3 col-md-2"/>*/}
        </div>
    )
}

Header.propTypes = {
    text: PropTypes.string.isRequired,
    warning: PropTypes.string,
};
