import React, { useState } from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import { addLawFirmContactBackend, updateLawFirmContactBackend } from "../../services/company_management";
import { addLawFirmContact, updateLawFirmContact } from "../../store/company_management";
import {addNewLawFirmContact, removeLawFirmData} from "../../store/bond";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomTextInput from "../../global_components/inputs/text";
import CustomMultiSelectInput from "../../global_components/inputs/multi-select-field";
import USPhoneNumberWithoutCountryCodeUniversal from "../../global_components/inputs/us_phone_number_without_country_code";
import {hasUserPermission} from "../../utils/user_permission";

export default function LawFirmAttorneyPopup(props) {
    const dispatch = useDispatch();
    const lawFirmsInStore = useSelector((state) => state.bond.law_firms);
    const lawSchoolsInStore = useSelector((state) => state.bond.law_schools);
    const [loading, setLoading] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const isValidate = hasUserPermission(user_permission,'ignore_validation') ;

    const getInitialValues = () => {
        return {full_name: '', preferred_name: '', email: '', phone_number: '', law_firm: [props.law_firm_id],
                law_school: '', role: 'ATTORNEY', bar: ''}
    }

    const getValidation = () => {
        if (!isValidate){
            return Yup.object({
                full_name: Yup.string().required('FullName is required.'),
                preferred_name: Yup.string().nullable(),
                email: Yup.string().matches(/\S+@\S+\.\S+/ , 'Please enter a valid email.').required('Email is required.'),
                phone_number: Yup.string().required('Phone number is required.').matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378"),
                law_firm: Yup.array().of(Yup.number()).min(1, "Law Firm is required."),
                law_school: Yup.number().when("role", {
                    is: val => (val && val === 'ATTORNEY'),
                    then: Yup.number().required("Law school is required.")
                }),
                role: Yup.string().oneOf(['ATTORNEY'], 'Please select a valid role.').required('Role of contact is required.'),
                bar: Yup.string().when("role", {
                    is: val => (val && val === 'ATTORNEY'),
                    then: Yup.string().required("BAR number is required.")
                })
            })
        }else{
            return Yup.object({
                full_name: Yup.string().required('Full name is required.'),
                preferred_name: Yup.string().nullable(),
                email: Yup.string().matches(/\S+@\S+\.\S+/ , 'Please enter a valid email.').nullable(),
                phone_number: Yup.string().matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378").nullable(),
                law_firm: Yup.array().of(Yup.number()).min(1, "Law Firm is required."),
                law_school: Yup.number().nullable(),
                role: Yup.string().oneOf(['ATTORNEY'], 'Please select a valid role.').required('Role of contact is required.'),
                bar: Yup.string().nullable(),
            })
        }
    }

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            await addLawFirmContactBackend(values).then((res)=>{
                if (res.status === 201){
                    const data = res.data;
                    dispatch(addLawFirmContact(data));
                    dispatch(addNewLawFirmContact({id: data.id, name: data.full_name, law_firms: values.law_firm}));
                    setLoading(false);
                    props.addAttorneyCallBack(data.id);
                    props.closePopup(false);
                }else{
                    setShowFailureMessage(true);
                    setTimeout(() => setShowFailureMessage(false), 5000);
                }
            }).catch((error)=> {
                console.log(error);
                setLoading(false);
                setShowFailureMessage(true);
                setTimeout(() => setShowFailureMessage(false), 5000);
            })
        },
        validationSchema:getValidation()
    });

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Provide Details of Attorney
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key="full_name" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={'required-label'}><strong>Full Name</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='full_name'
                                          defaultValue={''}/>
                            {formIk.errors['full_name'] && formIk.touched['full_name'] && (
                                <div className="text-error">{formIk.errors['full_name']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="preferred_name" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label><strong>Preferred Name</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='preferred_name'
                                          defaultValue={''}/>
                            {formIk.errors['preferred_name'] && formIk.touched['preferred_name'] && (
                                <div className="text-error">{formIk.errors['preferred_name']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key="email" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={!isValidate ? 'required-label' : ''}><strong>Email</strong></Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                                          name='email' defaultValue={''}/>
                            {formIk.errors['email'] && formIk.touched['email'] && (
                                <div className="text-error">{formIk.errors['email']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="phone_number" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={!isValidate ? 'required-label' : ''}><strong>Phone Number</strong></Form.Label>
                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                                      className="form-control"
                                                                      name="phone_number" id="phone_number-input"
                                                                      value={''}
                                                                      onChange={(val) => {
                                                                          if(val === "(___) ___ ____"){
                                                                              val = "";
                                                                          }
                                                                          formIk.setFieldValue('phone_number', val);
                                                                      }}
                            />
                            {formIk.errors['phone_number'] && (
                                <div className="text-error">{formIk.errors["phone_number"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key="law_firm" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={'required-label'}><strong>Law Firms</strong></Form.Label>

                            <CustomMultiSelectInput disabled={true} name="law_firm" options={lawFirmsInStore}
                                                    value={[props.law_firm_id]}
                                                    onChange={
                                                        (value)=>formIk.setFieldValue('law_firm', value)
                                                    }
                            />
                            {formIk.errors['law_firm'] && (
                                <div className="text-error">{formIk.errors['law_firm']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key={"role"} className="col-12"
                                    controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className="required-label"><strong>Role</strong></Form.Label>
                            <CustomSelectInput disabled={true} name={"role"}
                                               value={"ATTORNEY"}
                                               onChange={(value)=>{formIk.setFieldValue("role", value);}}
                                               options={[{value: 'ATTORNEY', label: 'Attorney'}]}/>
                            {formIk.errors["role"] && (
                                <div className="text-error">{formIk.errors["role"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                    {
                        formIk.values.role === "ATTORNEY" ? (
                            <Col>
                                <Form.Group key={"bar"} className="col-12"
                                            controlId="formBasicEmail"
                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                    <Form.Label className={!isValidate ? 'required-label' : ''}><strong>BAR Association Number</strong></Form.Label>
                                    <CustomTextInput disabled={false} required={true} className="form-control"
                                                     name={"bar"}
                                                     id={"bar-input"} value={formIk.values.bar}
                                                     onChange={(value)=>formIk.setFieldValue("bar", value)}
                                                     onBlur={formIk.handleBlur}/>
                                    {formIk.errors["bar"] && formIk.touched["bar"] && (
                                        <div className="text-error">{formIk.errors["bar"]}</div>
                                    )}
                                </Form.Group>
                            </Col>
                        ) : (
                            <Col></Col>
                        )
                    }
                </Row>
                {
                    formIk.values.role === "ATTORNEY" && (
                        <Row>
                            <Col>
                                <Form.Group key={"law_school"} className="col-12"
                                            controlId="formBasicEmail"
                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                    <Form.Label className={!isValidate ? 'required-label' : ''}>
                                        <strong>Law School</strong>
                                    </Form.Label>
                                    <CustomSelectInput disabled={false} name={"law_school"}
                                                       value={formIk.values.law_school}
                                                       onChange={(value)=>{formIk.setFieldValue("law_school", value);}}
                                                       options={lawSchoolsInStore}/>
                                    {formIk.errors["law_school"] && (
                                        <div className="text-error">{formIk.errors["law_school"]}</div>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                }
                {
                    showFailureMessage && <p className='text-danger px-3'>Something went wrong.</p>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => {
                            console.log(formIk.values)
                            formIk.submitForm();
                        }}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

LawFirmAttorneyPopup.propTypes = {
    law_firm_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    addAttorneyCallBack: PropTypes.func.isRequired,
}
