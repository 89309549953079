import React, {useState, useEffect, useRef} from 'react';
import {Grid, TextField, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {makeStyles, createMuiTheme} from '@material-ui/core';

import {useDispatch, useSelector} from "react-redux";
import NumberFormat from 'react-number-format';
import './CapitalProject.css'
import {isEmpty} from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import {useParams} from "react-router-dom";
import {API_URL, states} from "../../constants";
import CusNavBar from "../../global_components/navbar";
import Header from "../../global_components/header";
import Footer from "../../global_components/footer";
import {useLocation, useNavigate} from "react-router";
import {
    addCapitalProject,
    approveCapitalProject,
    deleteUploadFile, getProcesses, getProcessInputs,
    saveDraftCapital,
    uploadFiles
} from "../../services/process";
import {addProcessDetails, addProcessInputs} from "../../store/capital_project";
import CusAlert from "../../global_components/alert";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Modal, Spinner} from "react-bootstrap";
import RejectDocumentPopup from "../popups/document_rejection_popup";
import JoditEditor from "jodit-react";
import {formatDate} from "../../services/helper";

const ErrorMessages = {
    additional_contacts: '- Additional contacts email cannot be empty',
    budget: '- Budget cannot be empty',
    contingency_amount: '- Contingency amount cannot be empty',
    division: '- Division cannot be empty',
    division_head: '- Division heads email cannot be empty',
    justification: '- Justification cannot be empty',
    project_coordinator: '- Project coordinators email cannot be empty',
    project_name: '- Project name cannot be empty',
    scope_of_work: '- Scope of work cannot be empty',
    start_date: '- Start date cannot be empty',
    completion_date: '- End date cannot be empty',
    valid_start_date: '- Start date cannot be before than today',
    valid_completion_date: '- End date cannot be before than today',
    funding_source: '- Please fill at least 1 funding source for submit.',
    empty_funding_source: '- Please add at least 1 funding source for submit.',
    valid_funding_source: '- Each funding source must have budget allocation and fund name unit account',
    budgetValidation: '- Total Budget Allocation must be equal to Total Budget',
    dateValidation: '- End date cannot be earlier than the start date',
    divisionHeadValidation: '- Please enter valid email for Division Head',
    coordinatorValidation: '- Please enter valid email for Project Coordinator',
    contactsValidation: '- Please enter valid email for Additional Contacts'
};

const useStyles = makeStyles((theme) => ({
    disabledTextField: {
        '& .MuiInputBase-input.Mui-disabled': {
            color: 'inherit', // Use 'inherit' to maintain the same text color as enabled parts
            background: theme.palette.grey[100],
            cursor: 'not-allowed',
        },
    },
    requiredAsterisk: {
        color: theme.palette.error.main,
    },
}));
const CapitalProjectCreate = (props) => {
    const [totalFundingSource, setTotalFundingSource] = useState();
    const [totalBudgetAllocation, setTotalBudgetAllocation] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [fundingSources, setFundingSources] = useState([]);
    const [fundingSourceCount, setFundingSourceCount] = useState(1);
    const capitalProjectDetailsStore = useSelector((state) => state.capitalProject.process_details);
    const capitalProjectInputsStore = useSelector((state) => state.capitalProject.popup_inputs);
    const funding_source_option = capitalProjectInputsStore.find(input => input.name === 'funding_source')?.options || [];
    const fund_name_unit_account_option = capitalProjectInputsStore.find(input => input.name === 'fund_name_unit_account')?.options || [];
    const division = capitalProjectInputsStore.find(input => input.name === 'division')?.options || [];
    const userInStore = useSelector((state) => state.auth.user);
    const currentUserId = useSelector((state) => state.auth.user.user_id);
    const email = userInStore.email;
    const location = useLocation();
    const classes = useStyles();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const multiEmailRegex = /^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([,.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;

    const normalInputs = capitalProjectInputsStore.filter((input) => input.is_line_item === false);
    const lineItemInputs = capitalProjectInputsStore.filter((input) => input.is_line_item === true);
    const wpcValue = lineItemInputs.find(input => input.name === "funding_source")?.options[0].value;
    const lineOfCreditValue = lineItemInputs.find(input => input.name === "funding_source")?.options[4].value;
    const otherFundingValue = lineItemInputs.find(input => input.name === "funding_source")?.options[3].value;
    const [viewStatus, setViewStatus] = useState();
    const [readOnlyView, setReadOnlyView] = useState(false);
    const [totalBudget, setTotalBudget] = useState(0);
    const routeParams = useParams();
    const [processApprovalID, setProcessApprovalID] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);

    const [capitalProject, setCapitalProject] = useState({
        additional_contacts: "",
        budget: "",
        comment: "",
        completion_date: "",
        contingency_amount: '',
        division: "",
        division_head: "",
        justification: "",
        line_items: [
            {
                funding_source_budget_allocation: "",
                funding_source: "",
                fund_name_unit_account: "",
                notes: "",
                fund_budget: ""
            }
        ],
        project_coordinator: "",
        project_name: "",
        project_owner: email,
        scope_of_work: "",
        start_date: "",
        total_budget: "",
        upload_files: [],
        comments: [],
        process_status: ""
    });

    const [attachments, setAttachments] = useState([]);
    const divisionHeadValidation = (!isEmpty(capitalProject.division_head) && !emailRegex.test(capitalProject.division_head))
    const coordinatorValidation = (!isEmpty(capitalProject.project_coordinator) && !emailRegex.test(capitalProject.project_coordinator))
    const contactsValidation = (!isEmpty(capitalProject.additional_contacts) && !multiEmailRegex.test(capitalProject.additional_contacts))
    const dateValidation = dayjs(capitalProject.completion_date).isBefore(dayjs(capitalProject.start_date), 'day')
    const budgetValidation = ((!isNaN(parseFloat(capitalProject.budget)) && capitalProject.budget !== "") &&
        (!isNaN(parseFloat(capitalProject.contingency_amount)) && capitalProject.contingency_amount !== "") &&
        totalBudgetAllocation !== "" && parseFloat(capitalProject.total_budget) !== totalBudgetAllocation)
    const fundingSourceValidation = capitalProject.line_items.some(
        (item) =>
            isNaN(parseFloat(item.funding_source_budget_allocation)) ||
            (item.funding_source !== wpcValue && item.funding_source !== lineOfCreditValue && item.funding_source !== otherFundingValue &&
                (isEmpty(item.funding_source) || isEmpty(item.fund_name_unit_account)))
    )

    const validationErrors = [];
    if (isEmpty(capitalProject.additional_contacts)) {
        validationErrors.push(ErrorMessages.additional_contacts);
    }
    if (isNaN(parseFloat(capitalProject.budget))) {
        validationErrors.push(ErrorMessages.budget);
    }
    if (isNaN(parseFloat(capitalProject.contingency_amount))) {
        validationErrors.push(ErrorMessages.contingency_amount);
    }
    if (isEmpty(capitalProject.division)) {
        validationErrors.push(ErrorMessages.division);
    }
    if (isEmpty(capitalProject.division_head)) {
        validationErrors.push(ErrorMessages.division_head);
    }
    if (isEmpty(capitalProject.justification)) {
        validationErrors.push(ErrorMessages.justification);
    }
    if (isEmpty(capitalProject.project_coordinator)) {
        validationErrors.push(ErrorMessages.project_coordinator);
    }
    if (isEmpty(capitalProject.project_name)) {
        validationErrors.push(ErrorMessages.project_name);
    }
    if (isEmpty(capitalProject.scope_of_work)) {
        validationErrors.push(ErrorMessages.scope_of_work);
    }
    if (isEmpty(capitalProject.start_date)) {
        validationErrors.push(ErrorMessages.start_date);
    }
    if (isEmpty(capitalProject.completion_date)) {
        validationErrors.push(ErrorMessages.completion_date);
    }
    if (dayjs(capitalProject.start_date, 'MM-DD-YYYY').isBefore(dayjs(), 'day')) {
        validationErrors.push(ErrorMessages.valid_start_date);
    }
    if (dayjs(capitalProject.completion_date, 'MM-DD-YYYY').isBefore(dayjs(), 'day')) {
        validationErrors.push(ErrorMessages.valid_completion_date);
    }
    if (capitalProject.line_items.length === 0) {
        validationErrors.push(ErrorMessages.empty_funding_source);
    }
    if (budgetValidation) {
        validationErrors.push(ErrorMessages.budgetValidation);
    }
    if (fundingSourceValidation) {
        validationErrors.push(ErrorMessages.funding_source);
    }
    if (dateValidation) {
        validationErrors.push(ErrorMessages.dateValidation);
    }
    if (divisionHeadValidation) {
        validationErrors.push(ErrorMessages.divisionHeadValidation);
    }
    if (coordinatorValidation) {
        validationErrors.push(ErrorMessages.coordinatorValidation);
    }
    if (contactsValidation) {
        validationErrors.push(ErrorMessages.contactsValidation);
    }
    if (capitalProject.line_items.some(
        (item) =>
            isNaN(parseFloat(item.funding_source_budget_allocation)) ||
            (item.funding_source !== wpcValue && item.funding_source !== lineOfCreditValue && item.funding_source !== otherFundingValue &&
                (isEmpty(item.funding_source) || isEmpty(item.fund_name_unit_account)))
    )) {
        validationErrors.push(ErrorMessages.valid_funding_source);
    }
    const hasErrors = validationErrors.length > 0;
    const errorMessage = validationErrors.join('<br />');

    const currentDate = new Date();
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [loadingDraft, setLoadingDraft] = useState(false)
    const [loadingComment, setLoadingComment] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [loadingReject, setLoadingReject] = useState(false)
    const [showRejectionPopup, setShowRejectionPopup] = useState(false)
    const editor = useRef(null);
    const config = {
        readonly: false,
        height: 200,
        placeholder: "Write a comment..."
    };
    let newComment = '';
    const [content, setContent] = useState("");

    const loadEditForm = async () => {
        console.log("lineItemInputs", lineItemInputs)
        let error = false;

        if (routeParams && routeParams.id !== '0') {
            setProcessApprovalID(routeParams.id);

            const url = `/get_capital_project_history/${routeParams.id}`
            try {
                const token = localStorage.getItem('userdata');
                const response = await fetch(`${API_URL}${url}`, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 200) {
                    const result = await response.json();
                    setViewStatus(result.process_status);

                    if (result.process_status !== 'Draft') {
                        setReadOnlyView(true)
                    }
                    if (result?.can_edit && result.process_status === 'In Process') {
                        setReadOnlyView(false)
                    }

                    if (result.process_status) {
                        capitalProject.process_status = result.process_status;
                    }
                    if (result.additional_contacts) {
                        capitalProject.additional_contacts = result.additional_contacts;
                    }
                    if (result.budget !== undefined || result.budget !== "") {
                        capitalProject.budget = result.budget;
                    }
                    if (result.comment) {
                        capitalProject.comment = result.comment;
                    }
                    if (result.completion_date) {
                        capitalProject.completion_date = result.completion_date;
                    }
                    if (result.contingency_amount !== undefined || result.contingency_amount !== "") {
                        capitalProject.contingency_amount = result.contingency_amount;
                    }
                    if (result.division) {
                        capitalProject.division = result.division;
                    }
                    if (result.division_head) {
                        capitalProject.division_head = result.division_head;
                    }
                    if (result.justification) {
                        capitalProject.justification = result.justification;
                    }
                    if (result.line_items) {
                        let line_items = [];

                        result.line_items.forEach((line_item) => {
                            let temp_item = {}
                            if (line_item?.length > 0) {
                                line_item?.forEach((item) => {
                                    temp_item[item['name']] = item['data'] !== null ? item['data'] : '';
                                });
                            } else {
                                Object.entries(line_item).forEach((item) => {
                                    temp_item[item[0]] = item[1] !== null ? item[1] : '';

                                })

                            }

                            line_items.push(temp_item);
                        });
                        capitalProject.line_items = line_items;
                    }
                    if (result.project_owner) {
                        capitalProject.project_owner = result.project_owner;
                    }
                    if (result.project_coordinator) {
                        capitalProject.project_coordinator = result.project_coordinator;
                    }
                    if (result.project_name) {
                        capitalProject.project_name = result.project_name;
                    }
                    if (result.scope_of_work) {
                        capitalProject.scope_of_work = result.scope_of_work;
                    }
                    if (result.start_date) {
                        capitalProject.start_date = result.start_date;
                    }
                    if (result.total_budget) {
                        capitalProject.total_budget = result.total_budget;
                    }
                    if (result.upload_files) {
                        capitalProject.upload_files = result.upload_files;
                        setAttachments(attachments => result.upload_files);
                    }
                    if (result.comments) {
                        capitalProject.comments = result.comments;
                    }
                    if (result.can_edit) {
                        capitalProject.can_edit = result.can_edit;
                    }
                    if (result.send_back_to) {
                        capitalProject.send_back_to = result.send_back_to;
                    }

                    setCapitalProject({...capitalProject, capitalProject})
                }
            } catch (error) {
                console.log(error);
            }

        }
        if (error === true) {
            window.scroll(0, 0);
            //console.log(props.formIK.errors)
        } else {
            //props.changeStep(props.progress + props.step);
        }
    }

    const isEndDateBeforeToday = () => {
        const selectedEndDate = dayjs(capitalProject.completion_date);
        const today = dayjs().startOf('day');

        return selectedEndDate.isBefore(today);
    };

    const setDefaultValue = (index) => {
        let returnObj = '';
        if (capitalProject.line_items[index].fund_name_unit_account !== "") {
            returnObj = fund_name_unit_account_option.filter((fun) => fun?.label?.includes(capitalProject.line_items[index].fund_name_unit_account.split(' -')[0]))[0]?.label.toString();
        }
        return returnObj;
    }

    const isStartDateBeforeToday = () => {
        const selectedStartDate = dayjs(capitalProject.start_date);
        const today = dayjs().startOf('day');

        return selectedStartDate.isBefore(today);
    };

    const handleAddFundingSource = () => {
        const newFundingSource = {
            funding_source_budget_allocation: "",
            funding_source: "",
            fund_name_unit_account: "",
            notes: "",
            fund_budget: ""
        };
        // setLineItems(prevSources => [...prevSources, newFundingSource]);
        setCapitalProject(prevProject => ({
            ...prevProject,
            line_items: [...prevProject.line_items, newFundingSource]
        }));
    };

    const handleRemoveFundingSource = (index) => {
        const updatedSources = [...capitalProject.line_items];
        updatedSources.splice(index, 1);

        // setLineItems(updatedSources);
        setCapitalProject(prevProject => ({
            ...prevProject,
            line_items: updatedSources
        }));
    };

    const handleFormSubmit = async () => {
        setLoadingSubmit(true)
        const api_data = {};
        normalInputs.forEach((field) => {
            api_data[field.name] = {...field, data: capitalProject[field.name]};
        });
        api_data["line_items"] = [];
        capitalProject.line_items.map((lineI, idx) => {
            api_data["line_items"][idx] = [];
            JSON.parse(JSON.stringify(lineItemInputs)).map((pli) => {
                api_data["line_items"][idx].push({name: pli.name, data: lineI[pli.name], ...pli});
            })

        });
        api_data["start_date"]["data"] = formatDate(new Date(api_data["start_date"]["data"]))
        api_data["completion_date"]["data"] = formatDate(new Date(api_data["completion_date"]["data"]))

        if (isNaN(parseFloat(api_data["budget"].data))) {
            api_data["budget"].data = 0
        } else {
            api_data["budget"].data = parseFloat(api_data["budget"].data)
        }

        if (isNaN(parseFloat(api_data["contingency_amount"].data))) {
            api_data["contingency_amount"].data = 0
        } else {
            api_data["contingency_amount"].data = parseFloat(api_data["contingency_amount"].data)
        }

        if (isNaN(parseFloat(api_data["total_budget"].data))) {
            api_data["total_budget"].data = 0
        } else {
            api_data["total_budget"].data = parseFloat(api_data["total_budget"].data)
        }

        const response = await addCapitalProject(capitalProjectDetailsStore.id, api_data, processApprovalID);
        if (response) {
            if (capitalProject.upload_files.length === 0) {
                navigate(`/capital_project`);
            } else {
                await handleUploadFiles(response["approval_id"])

            }
        } else {
            setShowFailureMessage(true)
        }
        setLoadingSubmit(false)
    };

    const handleFormSaveDraft = async () => {
        setLoadingDraft(true);
        const api_data = {};
        normalInputs.forEach((field) => {
            api_data[field.name] = {...field, data: capitalProject[field.name]};
        });
        api_data["line_items"] = [];
        capitalProject.line_items.map((lineI, idx) => {
            api_data["line_items"][idx] = [];
            JSON.parse(JSON.stringify(lineItemInputs)).map((pli) => {
                api_data["line_items"][idx].push({name: pli.name, data: lineI[pli.name], ...pli});
            })

        });

        if (isNaN(parseFloat(api_data["budget"].data))) {
            api_data["budget"].data = 0
        } else {
            api_data["budget"].data = parseFloat(api_data["budget"].data)
        }
        if (isNaN(parseFloat(api_data["contingency_amount"].data))) {
            api_data["contingency_amount"].data = 0
        } else {
            api_data["contingency_amount"].data = parseFloat(api_data["contingency_amount"].data)
        }
        if (isNaN(parseFloat(api_data["total_budget"].data))) {
            api_data["total_budget"].data = 0
        } else {
            api_data["total_budget"].data = parseFloat(api_data["total_budget"].data)
        }

        const response = await saveDraftCapital(capitalProjectDetailsStore.id, api_data, processApprovalID);
        if (response?.status === 200) {
            if (capitalProject.upload_files.length > 0) {
                await handleUploadFiles(response.approval_id)
            } else {
                setShowSuccessMessage(true)
                navigate(`/capital_project`);
            }
        } else {
            setShowFailureMessage(true)
            window.scroll(0, 0)
        }
        setLoadingDraft(false);
    };
    const handleUploadFiles = async (approval_id, navigatePage = true) => {
        let formData = new FormData();
        capitalProject.upload_files.forEach((file, i) => {
            formData.append(`upload_files`, file);
        });
        const response = await uploadFiles(formData, approval_id);
        if (response && navigatePage) {
            navigate(`/capital_project`);
        } else if (response === null) {
            window.scroll(0, 0)
            setShowFailureMessage(true)
        }
    };

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        if (Object.keys(capitalProjectDetailsStore).length === 0 || capitalProjectInputsStore.length === 0) {
            getProcesses("Capital Proposal").then(async (response) => {
                dispatch(addProcessDetails(response[0]))
                if (capitalProjectInputsStore.length === 0) {
                    getProcessInputs(response[0].id).then((resp) => {
                        dispatch(addProcessInputs(resp));
                    }).catch((error) => {
                        console.log(error);
                        setShowFailureMessage(true);
                    });
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        await loadEditForm();
    }, []);

    useEffect(() => {
        const sum = parseFloat(capitalProject.budget || 0) + parseFloat(capitalProject.contingency_amount || 0)
        setCapitalProject({
            ...capitalProject,
            total_budget: sum.toFixed(2)
        });
    }, [capitalProject.budget, capitalProject.contingency_amount]);

    useEffect(() => {
        const sumFund = capitalProject.line_items.reduce((total, item) => total + parseFloat(item.fund_budget || 0), 0);
        setTotalFundingSource(sumFund);
        const sumBudget = capitalProject.line_items.reduce((total, item) => total + parseFloat(item.funding_source_budget_allocation || 0), 0);
        setTotalBudgetAllocation(sumBudget);
    }, [capitalProject.line_items]);

    const handleAddFile = (e) => {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let secondSameFile = [];
            secondSameFile = attachments.filter((element) => {
                return element?.file_name === files[i].name || element?.name === files[i].name;
            })
            if (secondSameFile.length === 0)
                attachments.push(files[i])
        }
        capitalProject.upload_files = attachments
        setCapitalProject({
            ...capitalProject,
            capitalProject
        })
    };

    const handleRemoveFile = async (i) => {
        if (i.includes('https://adlsoglebayprod')) {
            await deleteUploadFile(i, processApprovalID);
        }
        const tempAttachment = attachments.filter(file => {
            return (i !== (file.file_url ?? file.name))
        })
        setAttachments(attachments => tempAttachment);
        capitalProject.upload_files = tempAttachment;
        setCapitalProject({
            ...capitalProject,
            capitalProject
        })
    };


    const handleAddComment = async () => {
        setLoadingComment(true);
        if (content === '' || content === undefined) {
            alert('Comment text is required.');
            return;
        }
        const token = localStorage.getItem('userdata');
        const response = await fetch(`${API_URL}/document_approval_add_comment/`, {
            method: "POST",
            body: JSON.stringify({comment: content, approval_record_id: processApprovalID}),
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
        }).catch((error) => {
            console.log("error,", error);
            setShowFailureMessage(true)
            window.scroll(0, 0)

        });
        if (response.status === 200) {
            const new_comments = await response.json();
            setCapitalProject({
                ...capitalProject,
                comments: new_comments
            })
            setContent(' ');
            newComment = '';
            // setShowSuccessMessage(true)
            setLoadingComment(false);

        } else {
            setShowFailureMessage(true)
            window.scroll(0, 0)
        }
        setLoadingComment(false);

        return response.status;
    }
    const handleFormApproved = async () => {
        setLoadingApprove(true);
        const api_data = {};
        normalInputs.forEach((field) => {
            api_data[field.name] = {...field, data: capitalProject[field.name]};
        });
        api_data["line_items"] = [];
        capitalProject.line_items.map((lineI, idx) => {
            api_data["line_items"][idx] = [];
            JSON.parse(JSON.stringify(lineItemInputs)).map((pli) => {
                api_data["line_items"][idx].push({name: pli.name, data: lineI[pli.name], ...pli});
            })

        });
        api_data["start_date"]["data"] = formatDate(new Date(api_data["start_date"]["data"]))
        api_data["completion_date"]["data"] = formatDate(new Date(api_data["completion_date"]["data"]));

        if (isNaN(parseFloat(api_data["budget"].data))) {
            api_data["budget"].data = 0
        } else {
            api_data["budget"].data = parseFloat(api_data["budget"].data)
        }
        if (isNaN(parseFloat(api_data["contingency_amount"].data))) {
            api_data["contingency_amount"].data = 0
        } else {
            api_data["contingency_amount"].data = parseFloat(api_data["contingency_amount"].data)
        }
        if (isNaN(parseFloat(api_data["total_budget"].data))) {
            api_data["total_budget"].data = 0
        } else {
            api_data["total_budget"].data = parseFloat(api_data["total_budget"].data)
        }

        if (content !== null && content !== '' && content !== undefined) {
            let responseComment = await handleAddComment();
            if (responseComment === 200) {
                let successFlag = true;
            } else {
                window.scroll(0, 0)
                setShowFailureMessage(true)
            }
        }
        const response = await approveCapitalProject(api_data, processApprovalID);
        if (response) {

            if (attachments.length !== 0) {
                await handleUploadFiles(processApprovalID, false);
            }

            if (!loadingComment && !loadingApprove && !showFailureMessage) {
                navigate(`/capital_project`);
            } else {
                window.scroll(0, 0)
                setShowFailureMessage(true);
                setLoadingApprove(false);
            }


        } else {
            window.scroll(0, 0)
            setShowFailureMessage(true);
            setLoadingApprove(false);
        }
        setLoadingApprove(false);
    }
    const rejectDocument = async (send_back_to = '', comment = '') => {
        let post_data = {approval_record_id: processApprovalID}
        if (send_back_to !== '' && comment !== '') {
            post_data = {...post_data, comment: comment, send_back_to: send_back_to}
        }
        setLoadingReject(true);
        const token = localStorage.getItem('userdata');
        const response = await fetch(`${API_URL}/document_approval_reject/`, {
            method: "POST",
            body: JSON.stringify(post_data),
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
        }).catch((error) => {
            setLoadingReject(false);
            console.log(error);
            window.scroll(0, 0)
            setShowFailureMessage(true)
        });

        if (response.status === 200) {
            if (content !== '' && content !== undefined) {
                let responseComment = await handleAddComment();
                if (responseComment === 200) {
                    navigate(`/capital_project`);
                } else {
                    window.scroll(0, 0)
                    setShowFailureMessage(true)
                }
            } else {
                navigate(`/capital_project`);
            }

        } else {
            window.scroll(0, 0)
            setShowFailureMessage(true)
        }
        setLoadingReject(false)
    }

    const handleFormRejected = () => {
        if (capitalProject?.send_back_to?.length === 0) {
            rejectDocument()
        } else {
            setShowRejectionPopup(true);
        }
    }
    const get_send_back_to_options = () => {
        let options = [];
        capitalProject?.send_back_to?.forEach((contact) => {
            options.push({label: contact, value: contact});
        })
        return options;
    }

    return (
        <>
            <CusNavBar/>
            <Header text="Capital Project"/>

            {
                showSuccessMessage && <CusAlert alertType='success' alertMessage="Proposal created successfully."
                                                alertClose={setShowSuccessMessage}/>
            }
            {
                showFailureMessage &&
                <CusAlert alertType='danger' alertMessage="Something went wrong while creating Proposal."
                          alertClose={setShowFailureMessage}/>
            }


            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Button variant="outlined" onClick={() => {
                        navigate(`/capital_project`);
                    }}>
                        Go Back
                    </Button>
                </Grid>
                <Grid item xs={10}>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            disabled={readOnlyView}
                            label={
                                <span>
                                  Project Name
                                  <span className={classes.requiredAsterisk}> *</span>
                                </span>
                            }
                            value={capitalProject.project_name}
                            onChange={(e) => {
                                setCapitalProject({
                                    ...capitalProject,
                                    project_name: e.target.value,
                                })
                            }}/>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="division-label">
                            Division <span className={classes.requiredAsterisk}> *</span>
                        </InputLabel>
                        <Select
                            disabled={readOnlyView}
                            labelId="division-label"
                            label={"Division"}
                            id="division-select"
                            value={capitalProject.division}
                            onChange={(e) =>
                                setCapitalProject({
                                    ...capitalProject,
                                    division: e.target.value,
                                })
                            }
                        >
                            {division.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            disabled={readOnlyView}
                            label={
                                <span>
                                    Justification
                                    <span className={classes.requiredAsterisk}> *</span>
                                </span>
                            }
                            multiline
                            rows={4}
                            variant="outlined"
                            value={capitalProject.justification}
                            onChange={(e) => {
                                setCapitalProject({
                                    ...capitalProject,
                                    justification: e.target.value
                                })
                            }}/>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            disabled={readOnlyView}
                            label={
                                <span>
                                    Scope of Work
                                    <span className={classes.requiredAsterisk}> *</span>
                                </span>
                            }
                            multiline
                            rows={4}
                            variant="outlined"
                            value={capitalProject.scope_of_work}
                            onChange={(e) => {
                                setCapitalProject({
                                    ...capitalProject,
                                    scope_of_work: e.target.value
                                })
                            }}/>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            label="Project Owner's Email"
                            type="email"
                            disabled
                            value={capitalProject.project_owner}
                            onChange={(e) => {
                                setCapitalProject({
                                    ...capitalProject,
                                })
                            }}/>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            disabled={readOnlyView}
                            label={
                                <span>
                                    Division Head's Email
                                    <span className={classes.requiredAsterisk}> *</span>
                                </span>
                            }
                            type="email"
                            error={divisionHeadValidation}
                            helperText={divisionHeadValidation ? "Invalid email format" : ""}
                            value={capitalProject.division_head}
                            onChange={(e) => {
                                setCapitalProject({
                                    ...capitalProject,
                                    division_head: e.target.value
                                })
                            }}/>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            disabled={readOnlyView}
                            label={
                                <span>
                                    Project Coordinator's Email
                                    <span className={classes.requiredAsterisk}> *</span>
                                </span>
                            }
                            type="email"
                            error={coordinatorValidation}
                            helperText={coordinatorValidation ? "Invalid email format" : ""}
                            value={capitalProject.project_coordinator}
                            onChange={(e) => {
                                setCapitalProject({
                                    ...capitalProject,
                                    project_coordinator: e.target.value
                                })
                            }}/>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField
                            disabled={readOnlyView}
                            label={
                                <span>
                                    Additional Contacts' Email
                                    <span className={classes.requiredAsterisk}> *</span>
                                </span>
                            }
                            type="email"
                            error={contactsValidation}
                            helperText={contactsValidation ? "Invalid email format" : ""}
                            value={capitalProject.additional_contacts}
                            onChange={(e) => {
                                setCapitalProject({
                                    ...capitalProject,
                                    additional_contacts: e.target.value
                                })
                            }}/>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Comments"
                            disabled={readOnlyView}
                            multiline
                            rows={4}
                            variant="outlined"
                            value={capitalProject.comment}
                            onChange={(e) => {
                                setCapitalProject({
                                    ...capitalProject,
                                    comment: e.target.value
                                })
                            }}/>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <NumberFormat
                            label={
                                <span>
                                    Budget
                                    <span className={classes.requiredAsterisk}> *</span>
                                </span>
                            }
                            disabled={readOnlyView}
                            customInput={TextField}
                            thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                            decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                            decimalScale={2}
                            isNumericString
                            fixedDecimalScale
                            prefix="$"
                            value={capitalProject.budget}
                            InputProps={{
                                inputProps: {style: {textAlign: 'end'}},
                            }}
                            onValueChange={(values) => {
                                const {value} = values;
                                const floatValue = parseFloat(value);
                                const newBudget = isNaN(floatValue) ? 0 : floatValue;
                                setCapitalProject({
                                    ...capitalProject,
                                    budget: newBudget
                                });
                            }}
                            allowNegative={false}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <NumberFormat
                            label={
                                <span>
                                    Continency Amount
                                    <span className={classes.requiredAsterisk}> *</span>
                                </span>
                            }
                            disabled={readOnlyView}
                            customInput={TextField}
                            thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                            decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                            decimalScale={2}
                            isNumericString
                            fixedDecimalScale
                            prefix="$"
                            value={capitalProject.contingency_amount}
                            InputProps={{
                                inputProps: {style: {textAlign: 'end'}},
                            }}
                            onValueChange={(values) => {
                                const {value} = values;
                                const floatValue = parseFloat(value);
                                const newContinency = isNaN(floatValue) ? 0 : floatValue;
                                setCapitalProject({
                                    ...capitalProject,
                                    contingency_amount: newContinency
                                });
                            }}
                            allowNegative={false}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <NumberFormat
                            label="Total Budget"
                            customInput={TextField}
                            className={classes.disabledTextField}
                            thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                            decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                            isNumericString
                            fixedDecimalScale
                            decimalScale={2}
                            prefix="$"
                            error={budgetValidation}
                            helperText={budgetValidation ? "Total Budget Allocation must be equal to Total Budget" : ""}
                            disabled
                            value={capitalProject.total_budget}
                            InputProps={{
                                inputProps: {style: {textAlign: 'end'}},
                            }}
                            onValueChange={(values) => {
                                const {value} = values;
                                const floatValue = parseFloat(value);
                                setCapitalProject({
                                    ...capitalProject,
                                    total_budget: floatValue
                                });
                            }}
                            allowNegative={false}
                        />
                    </FormControl>
                </Grid>


                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <NumberFormat
                            label="Total Funding Source"
                            customInput={TextField}
                            className={classes.disabledTextField}
                            thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                            decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                            isNumericString
                            fixedDecimalScale
                            decimalScale={2}
                            prefix="$"
                            disabled
                            value={totalFundingSource}
                            InputProps={{
                                inputProps: {style: {textAlign: 'end'}},
                            }}
                            onValueChange={(values) => {
                                const {value} = values;
                                setCapitalProject({
                                    ...capitalProject,
                                });
                            }}
                            allowNegative={false}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled={readOnlyView}
                                label={
                                    <span>
                                        Start Date
                                        <span className={classes.requiredAsterisk}> *</span>
                                    </span>
                                }
                                startDate={dayjs(capitalProject.completion_date)}
                                endDate={dayjs(capitalProject.start_date)}
                                format="MM-DD-YYYY"
                                value={dayjs(capitalProject.start_date)}
                                onChange={(date) => {
                                    const formattedDate = date.format('MM-DD-YYYY');
                                    setCapitalProject({
                                        ...capitalProject,
                                        start_date: formattedDate,
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        error: (isEmpty(capitalProject.start_date) || isStartDateBeforeToday()) && !readOnlyView,
                                        helperText: isEmpty(capitalProject.start_date)
                                            ? ""
                                            : ((isStartDateBeforeToday() && !readOnlyView)
                                                ? "Start date cannot be before today"
                                                : ""),
                                    },
                                }}
                                disablePast={!readOnlyView}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled={readOnlyView}
                                label={
                                    <span>
                                        End Date
                                        <span className={classes.requiredAsterisk}> *</span>
                                    </span>
                                }
                                startDate={dayjs(capitalProject.completion_date)}
                                endDate={dayjs(capitalProject.start_date)}
                                format="MM-DD-YYYY"
                                value={dayjs(capitalProject.completion_date)}
                                onChange={(date) => {
                                    const formattedDate = date.format('MM-DD-YYYY');
                                    setCapitalProject({
                                        ...capitalProject,
                                        completion_date: formattedDate,
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        error: (dateValidation || isEmpty(capitalProject.completion_date) || isEndDateBeforeToday()) && !readOnlyView,

                                        helperText: dateValidation
                                            ? "End date cannot be earlier than the start date"
                                            : isEmpty(capitalProject.completion_date)
                                                ? ""
                                                : ((isEndDateBeforeToday() && !readOnlyView)
                                                    ? "End date cannot be before today"
                                                    : ""),
                                    },
                                }}
                                disablePast={!readOnlyView}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '10px',}}>
                            <h3 style={{marginLeft: '15px'}}>Funding Sources Details</h3>
                            <IconButton size="small" disabled={readOnlyView} color="primary"
                                        onClick={handleAddFundingSource}
                                        aria-label="Add">
                                <AddIcon/>
                            </IconButton>
                        </div>
                        {capitalProject.line_items.length === 0 ? (
                            <Grid item xs={4} style={{marginLeft: '20px', marginTop: '14px'}}>
                                <p style={{color: 'red'}}>Please add at least 1 funding source for submit.</p>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>

                {capitalProject.line_items.map((source, index) => (
                    <React.Fragment key={index}>

                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={8}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <h4 style={{
                                        marginRight: '8px',
                                        marginLeft: '20px'
                                    }}>{`Funding Source ${index + 1}`}</h4>
                                    <IconButton
                                        size="small"
                                        disabled={readOnlyView}
                                        onClick={() => handleRemoveFundingSource(index)}
                                        aria-label="Remove"
                                        style={{marginTop: '-5px', color: readOnlyView ? '' : 'red'}}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <NumberFormat
                                    disabled={readOnlyView}
                                    label={
                                        <span>
                                            Fund Budget Source Allocation
                                            <span className={classes.requiredAsterisk}> *</span>
                                        </span>
                                    }
                                    customInput={TextField}
                                    thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                    decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                    decimalScale={2}
                                    error={capitalProject.line_items[index].funding_source_budget_allocation === "" || (capitalProject.line_items[index].fund_budget !== "" && capitalProject.line_items[index].funding_source_budget_allocation !== "" && !isNaN(parseFloat(capitalProject.line_items[index].fund_budget)) && !isNaN(parseFloat(capitalProject.line_items[index].funding_source_budget_allocation)) && capitalProject.line_items[index].funding_source !== wpcValue && capitalProject.line_items[index].funding_source !== lineOfCreditValue && capitalProject.line_items[index].funding_source !== otherFundingValue && parseFloat(capitalProject.line_items[index].funding_source_budget_allocation) > parseFloat(capitalProject.line_items[index].fund_budget))}
                                    helperText={capitalProject.line_items[index].fund_budget !== "" && capitalProject.line_items[index].funding_source_budget_allocation !== "" && !isNaN(parseFloat(capitalProject.line_items[index].fund_budget)) && !isNaN(parseFloat(capitalProject.line_items[index].funding_source_budget_allocation)) && capitalProject.line_items[index].funding_source !== wpcValue && capitalProject.line_items[index].funding_source !== lineOfCreditValue && capitalProject.line_items[index].funding_source !== otherFundingValue && parseFloat(capitalProject.line_items[index].funding_source_budget_allocation) > parseFloat(capitalProject.line_items[index].fund_budget) ? "Budget Allocation can't be greater than Fund Budget" : ""}
                                    fixedDecimalScale
                                    prefix="$"
                                    value={capitalProject.line_items[index].funding_source_budget_allocation}
                                    InputProps={{
                                        inputProps: {style: {textAlign: 'end'}},
                                    }}
                                    onValueChange={(values) => {
                                        const {value} = values;
                                        const floatValue = parseFloat(value);
                                        const updatedFundingSources = [...capitalProject.line_items];
                                        updatedFundingSources[index].funding_source_budget_allocation = floatValue;
                                        setCapitalProject({
                                            ...capitalProject,
                                            line_items: updatedFundingSources
                                        });
                                    }}
                                    allowNegative={false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id={`fund-source-label-${index}`}
                                            error={capitalProject.line_items[index].funding_source === ""}>Fund Source
                                    Selection<span
                                        className={classes.requiredAsterisk}> *</span> </InputLabel>
                                <Select
                                    disabled={readOnlyView}
                                    labelId={`fund-source-label-${index}`}
                                    label={"Fund Source Selection"}
                                    id={`fund-source-select-${index}`}
                                    error={capitalProject.line_items[index].funding_source === ""}
                                    value={capitalProject.line_items[index].funding_source}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const updatedFundingSources = [...capitalProject.line_items];
                                        updatedFundingSources[index].funding_source = value;
                                        if (capitalProject.line_items[index].funding_source === wpcValue || capitalProject.line_items[index].funding_source === lineOfCreditValue || capitalProject.line_items[index].funding_source === otherFundingValue) {
                                            updatedFundingSources[index].fund_budget = ""
                                            updatedFundingSources[index].fund_name_unit_account = ""
                                        }

                                        setCapitalProject({
                                            ...capitalProject,
                                            line_items: updatedFundingSources
                                        });
                                    }}
                                >
                                    {funding_source_option.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={readOnlyView}
                                    label={`Notes`}
                                    value={capitalProject.line_items[index].notes}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const updatedFundingSources = [...capitalProject.line_items];
                                        updatedFundingSources[index].notes = value;
                                        setCapitalProject({
                                            ...capitalProject,
                                            line_items: updatedFundingSources
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id={`fund-source-label-${index}`}
                                            error={capitalProject.line_items[index].fund_name_unit_account === "" && capitalProject.line_items[index].funding_source !== wpcValue && capitalProject.line_items[index].funding_source !== lineOfCreditValue && capitalProject.line_items[index].funding_source !== otherFundingValue}>Fund
                                    Name Unit Account
                                    Selection{(capitalProject.line_items[index].funding_source === wpcValue || capitalProject.line_items[index].funding_source === lineOfCreditValue || capitalProject.line_items[index].funding_source === otherFundingValue) ? "" : (
                                        <span className={classes.requiredAsterisk}> *</span>
                                    )}</InputLabel>
                                <Select
                                    labelId={`fund-source-label-${index}`}
                                    label={"Fund Name Unit Account Selection"}
                                    id={`fund-source-select-${index}`}
                                    error={capitalProject.line_items[index].fund_name_unit_account === ""}
                                    value={setDefaultValue(index)}

                                    className={classes.disabledTextField}
                                    disabled={capitalProject.line_items[index].funding_source === wpcValue || capitalProject.line_items[index].funding_source === lineOfCreditValue || capitalProject.line_items[index].funding_source === otherFundingValue || readOnlyView}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const updatedLineItems = [...capitalProject.line_items];
                                        updatedLineItems[index].fund_name_unit_account = value;
                                        setCapitalProject({
                                            ...capitalProject,
                                            line_items: updatedLineItems
                                        });
                                        const selectedOption = value
                                        if (selectedOption) {
                                            const newBudget = selectedOption.split(' - Budget: ')[1];
                                            const budgetAmount = parseFloat(newBudget);
                                            updatedLineItems[index].fund_budget = parseFloat(budgetAmount.toFixed(2))
                                        }
                                    }}
                                >
                                    {fund_name_unit_account_option
                                        .filter((option) => {
                                            if (!capitalProject.line_items[index].funding_source) {
                                                return option.filter_values.length === 0;
                                            }
                                            return option.filter_values.includes(capitalProject.line_items[index].funding_source);
                                        })
                                        .map((option) => (
                                            <MenuItem key={option.value} value={option.label}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <NumberFormat
                                    label="Fund Budget"
                                    customInput={TextField}
                                    className={classes.disabledTextField}
                                    thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                    decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    disabled
                                    prefix="$"
                                    value={capitalProject.line_items[index].fund_budget}
                                    InputProps={{
                                        inputProps: {style: {textAlign: 'end'}},
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const floatValue = parseFloat(value)
                                        const updatedFundingSources = [...capitalProject.line_items];
                                        updatedFundingSources[index].fund_budget = floatValue;
                                        capitalProject.line_items = updatedFundingSources;
                                    }}
                                    allowNegative={false}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                        </Grid>
                    </React.Fragment>
                ))}
                {(routeParams.id === '0' || !readOnlyView) &&
                    <Grid item xs={12}>
                        <Grid item xs={2}>
                            <Button variant="contained" onClick={handleAddFundingSource} fullWidth>
                                Add Funding Source
                            </Button>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={6} style={{marginTop: '30px'}}>
                    <FormControl fullWidth>
                        <NumberFormat
                            label="Total Budget Allocation"
                            customInput={TextField}
                            className={classes.disabledTextField}
                            thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                            decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                            decimalScale={2}
                            fixedDecimalScale
                            disabled
                            prefix="$"
                            value={totalBudgetAllocation}
                            InputProps={{
                                inputProps: {style: {textAlign: 'end'}},
                            }}
                            onValueChange={(values) => {
                                const {value} = values;
                                const floatValue = parseFloat(value);
                                setTotalBudgetAllocation(floatValue)
                            }}
                            allowNegative={false}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6} style={{marginTop: '30px'}}>
                    <FormControl fullWidth>
                        <NumberFormat
                            label="Total Funding Source"
                            customInput={TextField}
                            className={classes.disabledTextField}
                            thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                            decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                            decimalScale={2}
                            fixedDecimalScale
                            disabled
                            prefix="$"
                            value={totalFundingSource}
                            InputProps={{
                                inputProps: {style: {textAlign: 'end'}},
                            }}
                            onValueChange={(values) => {
                                const {value} = values;
                                const floatValue = parseFloat(value);
                                setTotalFundingSource(floatValue)
                            }}
                            allowNegative={false}
                        />
                    </FormControl>
                </Grid>


                <Grid item xs={12} container justifyContent="flex-start">
                    {(routeParams.id === '0' || !readOnlyView) &&
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <input type="file"
                                       hidden={true}
                                       disabled={readOnlyView}
                                       accept="application/pdf"
                                       id="upload_files"
                                       name="upload_files"
                                       onClick={(e) => {
                                           e.target.value = null
                                       }}
                                       onChange={(e) => handleAddFile(e)} multiple/>
                                <Button variant="contained"
                                        onClick={() => {
                                            document.getElementById("upload_files").click()
                                        }}>Upload Files
                                </Button>
                            </FormControl>
                        </Grid>
                    }


                    {attachments.length > 0 && (
                        <ul><b>Uploaded Files:</b>
                            {attachments.map((val, index) => {
                                if (val.name || val.file_name) {
                                    return (
                                        <li key={index}>
                                                <span style={{paddingRight: "15px", paddingTop: "15px"}}><a
                                                    href={val.file_url}>{val.name ?? val.file_name}</a></span>
                                            {
                                                !readOnlyView && <Button style={{paddingLeft: "0px"}}>
                                                    <DeleteForeverIcon
                                                        color={"error"}
                                                        onClick={() => {
                                                            handleRemoveFile(val.name ?? val.file_url);
                                                        }}
                                                    />
                                                </Button>
                                            }

                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    )}
                </Grid>

                <Grid item xs={12}>

                    {
                        (capitalProject.comments.length > 0) ?
                            <>
                                <h4 style={{marginTop: "50px"}}>Comments:</h4>
                                <hr className="col-9"/>
                            </> : <></>
                    }
                    {
                        capitalProject.comments.length > 0 && (
                            capitalProject.comments.map((comment) => {
                                return (
                                    <div>
                                        <p style={{
                                            fontSize: "15px",
                                            fontStyle: "italic",
                                            margin: "0px !important"
                                        }}>{comment.user} - <span style={{
                                            fontSize: "15px",
                                            fontStyle: "italic",
                                            margin: "0px !important"
                                        }}>{comment.created_date}</span></p>
                                        <h5 dangerouslySetInnerHTML={{__html: comment.comment}}/>
                                        <hr className="col-9"/>
                                    </div>
                                )
                            })
                        )
                    }

                    {(viewStatus === 'In Process' && !readOnlyView) && (

                        <JoditEditor
                            ref={editor}
                            config={config}
                            value={content}
                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={newContent => {
                            }}

                        />

                    )
                    }
                </Grid>
                {(viewStatus === 'In Process' && !readOnlyView) &&
                    <Grid item xs={12} container justifyContent="flex-start">
                        <Grid item xs={2}>
                            {
                                !loadingComment && (
                                    <Button variant="contained" onClick={() => {
                                        handleAddComment();
                                    }}>
                                        Add Comment
                                    </Button>
                                )
                            }
                            {
                                loadingComment && (
                                    <Button variant="contained" disabled>
                                        <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                                        Adding...
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12} container justifyContent="flex-start">
                    <Grid item xs={2}>
                        <Button variant="outlined" onClick={() => {
                            navigate(`/capital_project`);
                        }}>
                            &nbsp; &nbsp; &nbsp; Go Back &nbsp;&nbsp;&nbsp;
                        </Button>
                    </Grid>
                    {
                        (viewStatus === 'In Process' && !readOnlyView) && (

                            <Grid item xs={10} container justifyContent="flex-end">
                                {
                                    !loadingReject && (
                                        <Button variant="contained" sx={{marginLeft: '0.5rem'}} color="error"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    handleFormRejected()
                                                }}>
                                            Reject</Button>
                                    )
                                }
                                {
                                    loadingReject && (
                                        <Button variant="contained" disabled>
                                            <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                                            Rejected...
                                        </Button>
                                    )
                                }
                                {
                                    !loadingApprove && (
                                        <Button variant="contained" sx={{marginLeft: '0.5rem'}}
                                                onClick={() => handleFormApproved()}
                                                disabled={
                                                    isNaN(parseFloat(capitalProject.budget)) ||
                                                    isEmpty(capitalProject.division) ||
                                                    isEmpty(capitalProject.division_head) ||
                                                    isEmpty(capitalProject.justification) ||
                                                    isEmpty(capitalProject.project_coordinator) ||
                                                    isEmpty(capitalProject.project_name) ||
                                                    isEmpty(capitalProject.scope_of_work) ||
                                                    divisionHeadValidation ||
                                                    coordinatorValidation ||
                                                    contactsValidation ||
                                                    totalBudgetAllocation !== "" && parseFloat(capitalProject.total_budget) !== totalBudgetAllocation ||
                                                    capitalProject.line_items.length === 0 ||
                                                    capitalProject.line_items.some(
                                                        (item) =>
                                                            isNaN(parseFloat(item.funding_source_budget_allocation)) ||
                                                            (item.funding_source !== wpcValue && item.funding_source !== lineOfCreditValue && item.funding_source !== otherFundingValue &&
                                                                (isEmpty(item.funding_source) || isEmpty(item.fund_name_unit_account)))
                                                    )
                                                }>
                                            Approve</Button>
                                    )
                                }
                                {
                                    loadingApprove && (
                                        <Button variant="contained" disabled>
                                            <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                                            Approved...
                                        </Button>
                                    )
                                }
                            </Grid>
                        )
                    }
                    {(routeParams.id === '0' || viewStatus === 'Draft') &&
                        <Grid item xs={10} container justifyContent="flex-end">
                            {
                                !loadingDraft && (
                                    <Button variant="contained" onClick={handleFormSaveDraft} sx={{marginLeft: '0.5rem'}}>
                                        Save as Draft
                                    </Button>
                                )}
                            {
                                loadingDraft && (
                                    <Button variant="contained" disabled>
                                        <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                                        Saving as Draft...
                                    </Button>
                                )
                            }
                            {
                                !loadingSubmit && (
                                    <Tooltip
                                        open={showTooltip && hasErrors}
                                        title={<span dangerouslySetInnerHTML={{__html: errorMessage}}/>}
                                        placement="bottom"
                                        arrow
                                    >
                                        <span
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                        >
                                    <Button variant="contained"
                                            onClick={handleFormSubmit}
                                            sx={{marginLeft: '0.5rem'}}
                                            disabled={
                                                isNaN(parseFloat(capitalProject.budget)) ||
                                                isNaN(parseFloat(capitalProject.contingency_amount)) ||
                                                isEmpty(capitalProject.division) ||
                                                isEmpty(capitalProject.division_head) ||
                                                isEmpty(capitalProject.justification) ||
                                                isEmpty(capitalProject.project_coordinator) ||
                                                isEmpty(capitalProject.project_name) ||
                                                isEmpty(capitalProject.scope_of_work) ||
                                                isEmpty(capitalProject.start_date) ||
                                                isEmpty(capitalProject.completion_date) ||
                                                dayjs(capitalProject.start_date, 'MM-DD-YYYY').isBefore(dayjs(), 'day') ||
                                                dayjs(capitalProject.completion_date, 'MM-DD-YYYY').isBefore(dayjs(), 'day') ||
                                                dateValidation ||
                                                divisionHeadValidation ||
                                                coordinatorValidation ||
                                                contactsValidation ||
                                                totalBudgetAllocation !== "" && parseFloat(capitalProject.total_budget) !== totalBudgetAllocation ||
                                                capitalProject.line_items.length === 0 ||
                                                capitalProject.line_items.some(
                                                    (item) =>
                                                        isNaN(parseFloat(item.funding_source_budget_allocation)) ||
                                                        (item.funding_source !== wpcValue && item.funding_source !== lineOfCreditValue && item.funding_source !== otherFundingValue &&
                                                            (isEmpty(item.funding_source) || isEmpty(item.fund_name_unit_account)))
                                                )
                                            }
                                    >
                                        Submit
                                    </Button>
                                    </span>
                                    </Tooltip>
                                )}
                            {
                                loadingSubmit && (
                                    <Button variant="contained" disabled>
                                        <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"/>
                                        Submitting...
                                    </Button>
                                )}


                        </Grid>
                    }
                </Grid>


                {
                    showRejectionPopup && <RejectDocumentPopup show={true} closePopup={setShowRejectionPopup}
                                                               reject_document_callback={rejectDocument}
                                                               send_back_to_options={get_send_back_to_options}/>
                }
            </Grid>
            <br/>

            <Footer/>
        </>
    )

};


export default CapitalProjectCreate;