import React, {useState} from "react";
import {Modal, Button, Spinner, Form, Tab, Tabs} from "react-bootstrap";
import '../../styles/login.css';
import * as Yup from "yup";
import {useFormik} from "formik";
import PropTypes from "prop-types";
import {API_URL} from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import InsurancePortalVendorCredsForm from "../insurance_portal/insurance_portal_vendor_creds_form";
import {useDispatch, useSelector} from "react-redux";


export default function InsuranceCompanyCredsPopup(props) {
    const [loading, setLoading] = useState(false);
    const companyData = useSelector((state) => state.auth.company);
    const portalInsuranceVendorCreds = useSelector((state) => state.auth.user.extra_attrs.hasOwnProperty('portal_insurance_vendor_creds') ? state.auth.user.extra_attrs.portal_insurance_vendor_creds : {'portal_insurance_vendor_creds': {}});

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Portal Insurance Vendor Credentials.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* { Object.keys(portalInsuranceVendorCreds).length > 0 && ( */}
                <Tabs defaultActiveKey="orion" id="uncontrolled-tab-example" className="mb-3">

                    <Tab eventKey="orion" title="Orion">
                        <InsurancePortalVendorCredsForm
                            vendor="orion" vendor_name="Orion"
                            username={portalInsuranceVendorCreds['orion'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['orion']['username'] === '' || portalInsuranceVendorCreds['orion']['username'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['orion']['username']
                            }
                            password={portalInsuranceVendorCreds['orion'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['orion']['password'] === '' || portalInsuranceVendorCreds['orion']['password'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['orion']['password']
                            }
                            is_active={portalInsuranceVendorCreds['orion'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['orion']['is_active'] === '' || portalInsuranceVendorCreds['orion']['is_active'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['orion']['is_active']
                            }
                        />
                    </Tab>
                    <Tab eventKey="swyfft" title="Swyfft">
                        <InsurancePortalVendorCredsForm
                            vendor="swyfft" vendor_name="Swyfft"
                            username={portalInsuranceVendorCreds['swyfft'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['swyfft']['username'] === '' || portalInsuranceVendorCreds['swyfft']['username'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['swyfft']['username']
                            }
                            password={portalInsuranceVendorCreds['swyfft'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['swyfft']['password'] === '' || portalInsuranceVendorCreds['swyfft']['password'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['swyfft']['password']
                            }
                            is_active={portalInsuranceVendorCreds['swyfft'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['swyfft']['is_active'] === '' || portalInsuranceVendorCreds['swyfft']['is_active'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['swyfft']['is_active']
                            }

                        />
                    </Tab>
                    <Tab eventKey="asi_progressive" title="ASI Progressive">
                        <InsurancePortalVendorCredsForm
                            vendor="asi_progressive" vendor_name="ASI Progressive"
                            username={portalInsuranceVendorCreds['asi_progressive'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['asi_progressive']['username'] === '' || portalInsuranceVendorCreds['asi_progressive']['username'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['asi_progressive']['username']
                            }
                            password={portalInsuranceVendorCreds['asi_progressive'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['asi_progressive']['password'] === '' || portalInsuranceVendorCreds['asi_progressive']['password'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['asi_progressive']['password']
                            }
                            is_active={portalInsuranceVendorCreds['asi_progressive'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['asi_progressive']['is_active'] === '' || portalInsuranceVendorCreds['asi_progressive']['is_active'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['asi_progressive']['is_active']
                            }
                        />
                    </Tab>
                    <Tab eventKey="ssiu" title="SSIU">
                        <InsurancePortalVendorCredsForm
                            vendor="ssiu" vendor_name="SSIU"
                            username={portalInsuranceVendorCreds['ssiu'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['ssiu']['username'] === '' || portalInsuranceVendorCreds['ssiu']['username'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['ssiu']['username']
                            }
                            password={portalInsuranceVendorCreds['ssiu'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['ssiu']['password'] === '' || portalInsuranceVendorCreds['ssiu']['password'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['ssiu']['password']
                            }
                            is_active={portalInsuranceVendorCreds['ssiu'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['ssiu']['is_active'] === '' || portalInsuranceVendorCreds['ssiu']['is_active'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['ssiu']['is_active']
                            }
                        />
                    </Tab>
                    <Tab eventKey="sage_sure" title="Sage Sure">
                        <InsurancePortalVendorCredsForm
                            vendor="sage_sure" vendor_name="Sage Sure"
                            username={portalInsuranceVendorCreds['sage_sure'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['sage_sure']['username'] === '' || portalInsuranceVendorCreds['sage_sure']['username'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['sage_sure']['username']
                            }
                            password={portalInsuranceVendorCreds['sage_sure'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['sage_sure']['password'] === '' || portalInsuranceVendorCreds['sage_sure']['password'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['sage_sure']['password']
                            }
                            is_active={portalInsuranceVendorCreds['sage_sure'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['sage_sure']['is_active'] === '' || portalInsuranceVendorCreds['sage_sure']['is_active'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['sage_sure']['is_active']
                            }
                        />
                    </Tab>
                    <Tab eventKey="openly" title="Openly">
                        <InsurancePortalVendorCredsForm
                            vendor="openly" vendor_name="Openly"
                            username={portalInsuranceVendorCreds['openly'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['openly']['username'] === '' || portalInsuranceVendorCreds['openly']['username'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['openly']['username']
                            }
                            password={portalInsuranceVendorCreds['openly'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['openly']['password'] === '' || portalInsuranceVendorCreds['openly']['password'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['openly']['password']
                            }
                            is_active={portalInsuranceVendorCreds['openly'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['openly']['is_active'] === '' || portalInsuranceVendorCreds['openly']['is_active'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['openly']['is_active']
                            }
                        />
                    </Tab>
                    {companyData.name === 'Portal Insurance' &&
                        <Tab eventKey="branch" title="Branch">
                            <InsurancePortalVendorCredsForm vendor="branch" vendor_name="Branch"
                                                            username={portalInsuranceVendorCreds['branch'] === undefined ? "" :
                                                                portalInsuranceVendorCreds['branch']['username'] === '' || portalInsuranceVendorCreds['branch']['username'] === undefined
                                                                    ? "" : portalInsuranceVendorCreds['branch']['username']}
                                                            password={portalInsuranceVendorCreds['branch'] === undefined ? "" : portalInsuranceVendorCreds['branch']['password'] === '' ||
                                                            portalInsuranceVendorCreds['branch']['password'] === undefined ? "" : portalInsuranceVendorCreds['branch']['password']}
                                                            is_active={portalInsuranceVendorCreds['branch'] === undefined
                                                                ? ""
                                                                : portalInsuranceVendorCreds['branch']['is_active'] === '' || portalInsuranceVendorCreds['branch']['is_active'] === undefined
                                                                    ? ""
                                                                    : portalInsuranceVendorCreds['branch']['is_active']
                                                            }
                            />
                        </Tab>
                    }
                    <Tab eventKey="agency_zoom" title="Agency Zoom">
                        <InsurancePortalVendorCredsForm
                            vendor="agency_zoom" vendor_name="Agency Zoom"
                            username={portalInsuranceVendorCreds['agency_zoom'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['agency_zoom']['username'] === '' || portalInsuranceVendorCreds['agency_zoom']['username'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['agency_zoom']['username']
                            }
                            password={portalInsuranceVendorCreds['agency_zoom'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['agency_zoom']['password'] === '' || portalInsuranceVendorCreds['agency_zoom']['password'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['agency_zoom']['password']
                            }
                            is_active={portalInsuranceVendorCreds['agency_zoom'] === undefined
                                ? ""
                                : portalInsuranceVendorCreds['agency_zoom']['is_active'] === '' || portalInsuranceVendorCreds['agency_zoom']['is_active'] === undefined
                                    ? ""
                                    : portalInsuranceVendorCreds['agency_zoom']['is_active']
                            }
                        />
                    </Tab>

                </Tabs>

                {/* ) } */}
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(false);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
}

InsuranceCompanyCredsPopup.propTypes = {
    closePopup: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
}
