import React from 'react';
import "../App.css";

function Footer(props){

    return(
        <>
            <br/><br/><br/><br/><br/><br/><br/>
            <footer className="footer pt-4 mt-5 text-muted border-top">
                Created by Blue Ocean Strategic Partners &middot; &copy; {new Date().getFullYear()} | For support please send an email to our <a href="mailto:support@blueoceansp.atlassian.net">Help Desk</a>.
            </footer>
        </>
    )
}


export default Footer;

// Block.propTypes = {
//     text: PropTypes.string.isRequired,
//     class: PropTypes.string.isRequired,
//     anchor: PropTypes.string.isRequired,
// };
