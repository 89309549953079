import {createSlice} from "@reduxjs/toolkit";
import {log} from "../utils/logger";


const initialState = {
    bond_process_details: {},
    bond_process_input: [],
    bond_history: [],
    law_firms: [],
    law_firm_addresses: {},
    law_firm_contacts: {},
    law_firm_users: [],
    law_schools: [],
    law_firm_locations: [],
};

const bondSlice = createSlice({
    name: 'bond', initialState, reducers: {
        addBond(state, action) {
            if (!state.bond.hasOwnProperty(action.payload.bondType)) {
                state.bond[action.payload.bondType] = {};
            }
            if (!state.bond[action.payload.bondType].hasOwnProperty(action.payload.bondType)) {
                state.bond[action.payload.bondType][action.payload.bondType] = [];
            }
            state.bond[action.payload.bondType][action.payload.bondType] = action.payload.bond;
        },
        addBondProcessDetails(state, action) {
            state.bond_process_details = action.payload;
        }, removeBondProcessDetails(state, action) {
            state.bond_process_details = {};
        }, addBondProcessHistory(state, action) {
            state.bond_history = action.payload;
        }, mergeBondProcessHistory(state, action) {
            log("mergeBondProcessHistory start");
            state.bond_history = state.bond_history.concat(action.payload);
            log("mergeBondProcessHistory end");
        }, addNewBondProcessHistory(state, action) {
            state.bond_history.push(action.payload);
        }, updateBondProcessHistory(state, action) {
            state.bond_history = state.bond_history.map((bond) => {
                if (bond.id === action.payload.id) {
                    bond = action.payload;
                }
                return bond;
            });
        }, updateBondStatus(state, action) {
            state.bond_history = state.bond_history.map((bond) => {
                if (bond.id === action.payload.bond_id) {
                    bond.is_approved = action.payload.status
                }
                return bond;
            });
        }, removeBondProcessHistory(state, action) {
            state.bond_history = [];
        }, addBondProcessInputs(state, action) {
            state.bond_process_input = action.payload;
        }, removeBondProcessInputs(state, action) {
            state.bond_process_input = [];
        }, addLawFirm(state, action) {
            state.law_firms = action.payload.law_firms;
        }, addNewLawFirm(state, action) {
            state.law_firms.push(action.payload);
        }, updateLawFirm(state, action) {
            state.law_firms = state.law_firms.map((law_firm) => {
                if (law_firm.value === action.payload.id) {
                    law_firm.label = action.payload.name;
                }
                return law_firm;
            });
        },
        addVermostLawFirmData(state, action) {
            log("state", state)
            log("action", action)
            log("state.law_firms", state.law_firms)
            action.payload.forEach((law_firm) => {
                state.law_firms.push({label: law_firm.name, value: law_firm.id});
            })
        },
        addVermostUserData(state, action) {
            state.law_firm_users = [];
            action.payload.forEach((user) => {
                state.law_firm_users.push({label: user.user_full_name, value: user.id});
            })
        },
        addVermostLocations(state, action) {
            console.log("action", action)
            state.law_firm_locations = [];
            action.payload.forEach((location) => {
                state.law_firm_locations.push({label: location.name, value: location.id});
            })
        },

        addLawFirmData(state, action) {
            // state.law_firms = action.payload.law_firms;
            state.law_firm_addresses = action.payload.addresses;
            state.law_firm_contacts = action.payload.contacts;
        }, removeLawFirmData(state, action) {
            state.law_firms = [];
            state.law_firm_addresses = {};
            state.law_firm_contacts = {};
        }, addLawSchool(state, action) {
            state.law_schools = action.payload.law_schools;
        }, removeLawSchool(state, action) {
            state.law_schools = [];
        }, addNewLawFirmContact(state, action) {
            action.payload.law_firms.map((law_firm) => {

                if (state.law_firm_contacts[law_firm] === undefined) {
                    state.law_firm_contacts[law_firm] = [{label: action.payload.name, value: action.payload.id}];
                } else {
                    state.law_firm_contacts[law_firm].push({label: action.payload.name, value: action.payload.id});
                }
            })
        }, addNewLawFirmAddress(state, action) {
            if (state.law_firm_addresses[action.payload.law_firm] === undefined) {
                state.law_firm_addresses[action.payload.law_firm] = [{
                    label: action.payload.address, value: action.payload.id
                }];
            } else {
                state.law_firm_addresses[action.payload.law_firm].push({
                    label: action.payload.address, value: action.payload.id
                });
            }
        }
    }
});

export const {
    addBond,
    addBondProcessDetails,
    removeBondProcessDetails,
    addBondProcessHistory,
    addBondProcessInputs,
    removeBondProcessInputs,
    updateBondStatus,
    addNewBondProcessHistory,
    updateBondProcessHistory,
    removeBondProcessHistory,
    addVermostLawFirmData,
    addVermostUserData,
    addVermostLocations,
    addLawFirmData,
    addLawFirm,
    updateLawFirm,
    removeLawFirmData,
    addLawSchool,
    removeLawSchool,
    mergeBondProcessHistory,
    addNewLawFirm,
    addNewLawFirmContact,
    addNewLawFirmAddress
} = bondSlice.actions;

export default bondSlice.reducer;
