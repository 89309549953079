import React, {useEffect, useState} from "react";
import {insurance_companies, vendor_companies} from "../../constants";
import {get_insurance_vendor_creds} from "../../services/portal_insurance"
import CusAlert from "../../global_components/alert";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";

export default function InsuranceAlert(props) {
    const [listOfVendor, setListOfVendor] = useState('');
    const [showVendorCredsError, setVendorCredsError] = useState(false);
    const companyData = useSelector((state) => state.auth.company);
    const [loading, setLoading] = useState(false);

    const vendorCredentials = async () => {
        const tempListOfVendor = [];
        const vendorCredentials = await get_insurance_vendor_creds();
        if (vendorCredentials.length !== 0) {
            Object.entries(vendorCredentials.data).forEach(function ([key, value]) {
                if (value.status !== null && typeof value.status !== 'undefined' && !value.status && value.is_active) {
                    let prettyName = '';
                    if (key === 'orion') {
                        prettyName = 'Orion';
                    } else if (key === 'swyfft') {
                        prettyName = 'Swyfft';
                    } else if (key === 'asi_progressive') {
                        prettyName = 'ASI Progressive';
                    } else if (key === 'ssiu') {
                        prettyName = 'SSIU';
                    } else if (key === 'sage_sure') {
                        prettyName = 'Sage Sure';
                    } else if (key === 'openly') {
                        prettyName = 'Openly';
                    } else if (key === 'agency_zoom') {
                        prettyName = 'Agency Zoom';
                    } else if (key === 'branch') {
                        prettyName = 'Branch';
                    }
                    if (prettyName !== '') {
                        tempListOfVendor.push(prettyName);
                    } else {
                        tempListOfVendor.push(key);
                    }
                }
            });
            if (tempListOfVendor.length > 0) {
                setVendorCredsError(true);
                if (props.isPopup)
                    props.closePopup(true);
                setLoading(false);
                setListOfVendor(''.concat('You have to change these vendor credentials information', '(', tempListOfVendor.join(', '), ')'));
            } else {
                if (props.isPopup)
                    props.closePopup(false);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        //setInterval(() => {
        setLoading(true);
        if (insurance_companies.includes(companyData.name)) {
            setListOfVendor('');
            vendorCredentials();
        }
        //}, 300000);
    }, []);

    return (
        <div>
            <br/>
            {
                !loading && !props.isPopup && showVendorCredsError && <CusAlert alertType='danger'
                                                                                alertMessage={listOfVendor}
                                                                                alertClose={setVendorCredsError}
                />
            }
            {
                !loading && props.isPopup &&
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Vendor's Credential Alert
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        {listOfVendor}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            !loading && (
                                <Button className="btn btn-warning" onClick={(e) => {
                                    window.location.href='/profile'
                                }}>Go to Profile</Button>
                            )
                        }
                        {
                            !loading && (
                                <Button onClick={(e) => {
                                    e.preventDefault();
                                    props.closePopup(false);
                                }}>Cancel</Button>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            }
        </div>
    )
}


InsuranceAlert.prototype = {
    closePopup: PropTypes.func.isRequired,
    failureMessage: PropTypes.string.isRequired,
    isPopup: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired,
}