import React, {useEffect, useState} from 'react';
import '../styles/login.css';
import '../App.css';
import {useNavigate} from "react-router";
import {getProcesses, triggerProcesses, getProcessInputs} from "../services/process";
import Footer from "./footer";
import CusAlert from "./alert";
import ProcessInputPopup from "../components/popups/process_input_popup";
import PropTypes from "prop-types";
import ButtonLoading from "./loadings/button_loading";
import CusNavBar from "./navbar";
import {useDispatch, useSelector} from "react-redux";
import {addProcess, addProcessInputs} from '../store/processes';
import LoadingOverlay from 'react-loading-overlay';
import APTableLoading from "./loadings/ap_table_loading";
import {DataGrid} from "@mui/x-data-grid";
import {API_URL} from "../constants";

function Process(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const po_approval_threshold = useSelector((state) => state.auth.user.po_approval_threshold)
    const categoryName = useSelector((state) => state.processCategories.current_active)
    const processes = useSelector((state) => state.processCategories.processes.hasOwnProperty(categoryName) ? state.processCategories.processes[categoryName] : []);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [showFailPopUp, setShowFailPopUp] = useState(false);
    const [showInputPopUp, setShowInputPopUp] = useState(false);
    const [processToTriggerState, setProcessToTriggerState] = useState({});
    const triggerProcess = async (processID) => {
        let processToTrigger = processes.filter(process => process.id === processID)[0];
        let processTriggerStatus;
        setLoading(true);
        let process_inputs = [];
        if (!processToTrigger.hasOwnProperty('inputs')) {
            process_inputs = await getProcessInputs(processToTrigger.id).catch((error) => {
                setLoading(false);
            });
        } else {
            process_inputs = processToTrigger.inputs;
        }
        setLoading(false);
        if (process_inputs.length > 0) {
            if (!processToTrigger.hasOwnProperty('inputs')) {
                dispatch(addProcessInputs({
                    process_name: categoryName,
                    processID: processToTrigger.id,
                    processInputs: process_inputs
                }));
                processToTrigger = {...processToTrigger, inputs: process_inputs};
            }

            setProcessToTriggerState(processToTrigger);
            setShowInputPopUp(true);
        } else {
            processTriggerStatus = await triggerProcesses(processID);
            if (processTriggerStatus) {
                setShowSuccessPopUp(true);
            } else {
                setShowFailPopUp(true);
            }
        }
    }
    const hasPOProcess = processes.map(p => p.name).includes('Create Purchase Order');

    const [date, setDate] = useState('');
    const [file, setFile] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('date', date);
        formData.append('file', file);

        const token = localStorage.getItem('userdata');
        const response = await fetch(`${API_URL}/ap/upload_salesman_report/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body: formData,
        });

        if (response.ok) {
            console.log('Form submitted successfully');
            // Handle response here
        } else {
            console.error('Form submission failed');
            // Handle errors here
        }
    };

    const columns = [{
        field: 'id', headerName: 'Tigger Process', sortable: false, minWidth: 180, maxWidth: 1000, flex: 1,
        headerClassName: 'header-bold right-column',
        renderCell: (params) => {
            return <button onClick={() => {
                triggerProcess(params.row.id);
            }}
                           className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                <span>{'Initiate Process'}</span>
            </button>;
        }
    }, {
        field: 'name', headerName: 'Process Name', sortable: false, minWidth: 200, maxWidth: 1000, flex: 1,
        headerClassName: 'header-bold right-column'
    }, {
        field: 'last_trigger_by_name',
        headerName: 'Last Triggered By',
        sortable: false,
        minWidth: 150,
        maxWidth: 1000,
        flex: 1,
        headerClassName: 'header-bold right-column'
    }, {
        field: 'last_trigger_at_formatted',
        headerName: 'Last Triggered At',
        sortable: false,
        minWidth: 120,
        maxWidth: 1000,
        flex: 1,
        headerClassName: 'header-bold right-column',
        valueFormatter: params => !params ? "" : new Date(params?.value).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        })
    }];


    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        const pros = await getProcesses(categoryName);
        dispatch(addProcess({process_name: categoryName, processes: pros}))
        document.title = "Processes";

        // setProcesses(pros);
    }, [showSuccessPopUp])

    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner
                text='Triggering Process ...'
            >
                <CusNavBar />
                {
                    showSuccessPopUp && <CusAlert alertType='success' alertMessage={categoryName !== 'Purchasing' ? 'Process started successfully.' : `Process started successfully. If you triggered Create Purchase Order with price over $${po_approval_threshold} then it will be sent for approval to your manager before triggering.`}
                                                alertClose={setShowSuccessPopUp} />
                }
                {
                    showFailPopUp && <CusAlert alertType='danger' alertMessage='Some internal issue caused process execution failure.'
                                                alertClose={setShowFailPopUp} />
                }
                <header
                    className="d-flex align-items-center justify-content-between text-dark text-decoration-none pt-3 pb-3 mb-3 border-bottom">
                    <div className="fs-1">
                        {categoryName} Processes
                    </div>
                </header>

                <div className="my-3">
                    <p className="fs-3 col-md-8">Please click on the button for the process you would like to trigger.</p>
                </div>

                <hr className="col-3 col-md-2" />
                {
                    hasPOProcess === true && (
                        <div style={{marginBottom: "1%", textAlign: "right"}}>
                            <button onClick={() => navigate('/po_history')} className="btn btn-primary">
                                Go to PO History
                            </button>
                        </div>
                    )
                }
                {
                    (processes && processes.length) ? (
                        <DataGrid
                            style={{height: "500px"}}
                            rows={processes}
                            columns={ columns }
                            pageSize={10}
                        />
                    ) : (
                        <APTableLoading />
                    )
                }
                {
                    showInputPopUp && <ProcessInputPopup show={true} close_popup={setShowInputPopUp}
                                                        process={processToTriggerState} showFail={setShowFailPopUp}
                                                        showSuccess={setShowSuccessPopUp} />
                }
                <hr className="col-3 col-md-2" />

            </LoadingOverlay>
            <Footer />
        </>
    )
}

Process.propTypes = {
    category: PropTypes.string.isRequired,
};


export default Process;
