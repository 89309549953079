import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import AppHeader from "../../components/app_header";
import styles from "./main.module.scss";

function Main(){
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/invoices");
    }
  }, []);

  return (
    <div className={styles.root}>
      <AppHeader />
      <div className={styles.outlet}>
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
