import { combineReducers } from "redux";
import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./auth";
import categoriesReducer from "./dashboard";
import processCategories from "./processes";
import invoicesReducer from "./invoices";
import companyManagementReducer from "./company_management";
import documentApprovalReducer from "./document_approval";
import capitalProjectSlice from "./capital_project";
import bondProcessReducer from "./bond";
import storage from "redux-persist/lib/storage";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'

const reducer = combineReducers({
    auth: authReducer,
    dashboard: categoriesReducer,
    processCategories: processCategories,
    invoices: invoicesReducer,
    companyManagement: companyManagementReducer,
    documentApproval: documentApprovalReducer,
    capitalProject: capitalProjectSlice,
    bond: bondProcessReducer
});

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export default store;
