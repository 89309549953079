import {log} from './logger'

export function calculatePremium(amount) {
    let originalAmount = amount;
    let premium = 0.0;

    if (isNaN(amount)) {
        log(`Amount: ${originalAmount}, Premium: ${premium}`)
        return premium;
    }

    if (amount < 2000) {
        premium = premium + ((amount * 10) / 1000);
    } else {
        premium = premium + ((2000 * 10) / 1000);
        amount = amount - 2000;
        if (amount < 48000) {
            premium = premium + ((amount * 5) / 1000);
        } else {
            premium = premium + ((48000 * 5) / 1000);
            amount = amount - 48000;
            if (amount < 150000) {
                premium = premium + ((amount * 4) / 1000);
            } else {
                premium = premium + ((150000 * 4) / 1000);
                amount = amount - 150000;
                if (amount < 300000) {
                    premium = premium + ((amount * 2.5) / 1000);
                } else {
                    premium = premium + ((300000 * 2.5) / 1000);
                    amount = amount - 300000;
                    if (amount < 500000) {
                        premium = premium + ((amount) / 1000);
                    } else {
                        premium = premium + ((500000) / 1000);
                        amount = amount - 500000;
                        if (amount < 50000000) {
                            premium = premium + ((amount * 0.5) / 1000);
                        } else {
                            premium = premium + ((50000000 * 0.5) / 1000);
                            amount = amount - 50000000;
                        }
                    }
                }
            }
        }
    }

    if (premium < 100) {
        premium = 100.0;
    }

    premium = Math.ceil(premium);
    log(`Amount: ${originalAmount}, Premium: ${premium}`)

    return premium;
}