import PropTypes from "prop-types";
import {Modal, Button, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useFormik, FieldArray, Formik, Form as FKForm} from "formik";
import '../../styles/login.css';
import * as Yup from "yup";
import {triggerProcessesWithData, filterInputOption} from "../../services/process";
import Select from 'react-select';
import jwt_decode from "jwt-decode";
import LoadingOverlay from 'react-loading-overlay';
import {useSelector} from "react-redux";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import CustomDatePicker from "../../global_components/custom_date_picker";
import {formatUTCDate} from "../../services/helper";

export default function ProcessInputPopup(props) {
    const [validationSchema, setValidationSchema] = useState({});
    const [loading, setLoading] = useState(false);
    const [line_items, setLineItems] = useState([]);
    const [line_items_total, setLineItemsTotal] = useState(0);
    const [accountNumberOptions, setAccountNumberOptions] = useState([]);
    const company_name = useSelector((state) => state.auth.company.name);
    const userInStore = useSelector((state) => state.auth.user);
    const decodedToken = jwt_decode(localStorage.getItem('userdata'));
    useEffect(() => {
        createLineItems();
        getValidation();
        // checkBuyerID();
        getInitialValues();
    }, []);

    // const checkBuyerID = async () => {
    //     const buyer_id_input = props.process.inputs.filter((input)=> input.name === 'Buyer ID');
    //     const account_number_input = props.process.inputs.filter((input)=> input.name === 'account_number');

    //     if (buyer_id_input && buyer_id_input.length && account_number_input && account_number_input.length){
    //         const account_numbers = await filterInputOption(account_number_input[0].id, decodedToken['buyer_id']);
    //         setAccountNumberOptions(account_numbers);
    //     }
    // }

    const getInputOptions = async (inputID, filterValue) => {
        const inputOptions = await filterInputOption(inputID, filterValue);
        return inputOptions;
    }

    const getInitialValues = () => {
        const initialValues = {};
        props.process.inputs.map((input, idx) => {
            if (input.is_line_item === false) {
                if (userInStore.hasOwnProperty('buyer_id') && input.name === "Buyer ID") {
                    initialValues[input.name] = userInStore['buyer_id']
                } else if (userInStore.hasOwnProperty('email') && input.name === "user_email") {
                    initialValues["user_email"] = userInStore['email']
                } else if (input.name === "company_name") {
                    initialValues[input.name] = company_name;
                }
                // else{
                //     initialValues[input.name] = input.default_value ? input.default_value : '';
                // }
            }
        });

        if (props.process.name === "Create Purchase Order") {
            initialValues["line_items"] = [];
        }
        return initialValues;
    }

    const getValidation = () => {
        const validation = {};
        props.process.inputs.map((input, idx) => {
            if ((input.input_type === 'text' || input.input_type === 'select') && input.is_line_item === false) {
                validation[input.name] = input.is_required ? Yup.string().required(input.label + ' is required.') : Yup.string()
            } else if ((input.input_type === 'number') && input.is_line_item === false) {
                validation[input.name] = input.is_required ? Yup.number().required(input.label + ' is required.') : Yup.number()
            } else if ((input.input_type === 'file') && input.is_line_item === false) {
                validation[input.name] = input.is_required ? Yup.mixed().required(input.label + ' is required.') : Yup.mixed()
            }
        });
        if (props.process.name === "Create Purchase Order") {
            let temp_line_item = {}
            props.process.inputs.map((input, idx) => {
                if ((input.input_type === 'text' || input.input_type === 'select') && input.is_line_item === true) {
                    temp_line_item[input.name] = input.is_required ? Yup.string().required(input.label + ' is required.') : Yup.string()
                } else if ((input.input_type === 'number') && input.is_line_item === true) {
                    temp_line_item[input.name] = input.is_required ? Yup.number().required(input.label + ' is required.') : Yup.number()
                } else if ((input.input_type === 'file') && input.is_line_item === true) {
                    temp_line_item[input.name] = input.is_required ? Yup.mixed().required(input.label + ' is required.') : Yup.mixed()
                }
            });
            validation["line_items"] = Yup.array().of(
                Yup.object().shape(temp_line_item)
            ).required("Must have a Line Item.").min(1, "Minimum of 1 Line Item is required.");
        }
        setValidationSchema(validation);
    }

    const get_line_item_total = (values) => {
        let lit = 0;
        values.line_items.map((lineI) => {
            if (lineI.quantity_ordered && lineI.quantity_ordered > 0 && lineI.price_per_unit && lineI.price_per_unit > 0) {
                lit = lit + (lineI.quantity_ordered * lineI.price_per_unit);
            }
        })
        setLineItemsTotal(lit);
        return lit;
    }

    const createLineItems = () => {
        let line = [];
        props.process.inputs.map((input, idx) => {
            if (input.is_line_item === true) {
                line.push({...input});
            }
        });
        setLineItems(line);
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Triggering Process ...'
        >
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Please add required data to trigger process.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={getInitialValues()}
                        onSubmit={async (values) => {
                            setLoading(true);
                            let payload = props.process.inputs.filter((i) => i.is_line_item !== true);
                            let payload_line_items = props.process.inputs.filter((i) => i.is_line_item === true);
                            let isFile = false;
                            let api_data = {};
                            payload.map((data) => {
                                api_data[data.name] = {data: values[data.name], ...data};
                                if (data.input_type === 'file') {
                                    isFile = true;
                                }
                            });
                            if (payload_line_items.length > 0) {
                                api_data["line_items"] = []
                                values.line_items.map((lineI, idx) => {
                                    api_data["line_items"][idx] = [];
                                    JSON.parse(JSON.stringify(payload_line_items)).map((pli) => {
                                        // pli["data"] = lineI[pli.name];
                                        api_data["line_items"][idx].push({
                                            name: pli.name,
                                            data: lineI[pli.name], ...pli
                                        });
                                    })
                                })
                            }
                            const processTriggerStatus = await triggerProcessesWithData(props.process.id, api_data, isFile);
                            if (processTriggerStatus) {
                                props.showSuccess(true);
                            } else {
                                props.showFail(true);
                            }
                            setLoading(false);
                            props.close_popup(false);
                        }
                        }
                        validationSchema={Yup.object(validationSchema)}
                        render={({
                                     initialValues,
                                     handleSubmit,
                                     submitForm,
                                     values,
                                     handleBlur,
                                     handleChange,
                                     touched,
                                     errors,
                                     setFieldValue,
                                     setFieldTouched
                                 }) => (
                            <FKForm onSubmit={handleSubmit}>
                                {
                                    <div class="col-10 col-md-8 col-lg-6">
                                        {
                                            props.process.inputs.map((input, idx) => {
                                                if (input.input_type === "text" && input.is_line_item === false) {
                                                    return (
                                                        <Form.Group key={idx} className="mb-3"
                                                                    controlId="formBasicEmail">
                                                            <Form.Label
                                                                className={input.is_required === true ? 'required-label' : ''}>{input.label}</Form.Label>
                                                            <Form.Control name={input.name} type="text"
                                                                          placeholder={"Enter " + input.label}
                                                                          onBlur={handleBlur} onChange={handleChange}
                                                                          disabled={input.name === 'Buyer ID'}
                                                                          defaultValue={input.name === 'Buyer ID' ? userInStore['buyer_id'] : ''}/>
                                                            {errors[input.name] && touched[input.name] && (
                                                                <div className="text-error">{errors[input.name]}</div>
                                                            )}
                                                        </Form.Group>)
                                                } else if (input.input_type === 'number' && input.is_line_item === false) {
                                                    return (
                                                        <Form.Group key={idx} className="mb-3"
                                                                    controlId="formBasicEmail">
                                                            <Form.Label
                                                                className={input.is_required === true ? 'required-label' : ''}>{input.label}</Form.Label>
                                                            <Form.Control name={input.name} type="number"
                                                                          placeholder={"Enter " + input.label}
                                                                          onBlur={handleBlur} onChange={handleChange}/>
                                                            {errors[input.name] && touched[input.name] && (
                                                                <div className="text-error">{errors[input.name]}</div>
                                                            )}
                                                        </Form.Group>)
                                                } else if (input.input_type === 'file' && input.is_line_item === false) {
                                                    return (
                                                        <Form.Group key={idx} controlId="formFileSm" className="mb-3">
                                                            <Form.Label
                                                                className={input.is_required === true ? 'required-label' : ''}>{input.label}</Form.Label>
                                                            <Form.Control type="file" size="sm" onBlur={handleBlur}
                                                                          onChange={(event) => {
                                                                              setFieldValue(input.name, event.target.files[0])
                                                                          }}
                                                                          name={input.name}/>
                                                            {errors[input.name] && touched[input.name] && (
                                                                <div className="text-error">{errors[input.name]}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                } else if (input.input_type === 'select' && input.is_line_item === false) {
                                                    return (
                                                        <Form.Group key={idx} controlId="formFileSm" className="mb-3">
                                                            <Form.Label
                                                                className={input.is_required === true ? 'required-label' : ''}>{input.label}</Form.Label>
                                                            <Select
                                                                name={input.name}
                                                                onChange={async (opt, e) => {
                                                                    if (input.name === 'Vendor ID' && props.process.name === "Create Purchase Order") {
                                                                        const vendor_item = line_items.filter((lits) => lits.name === 'vendor_item');
                                                                        setLoading(true)
                                                                        const vendor_item_options = await getInputOptions(vendor_item[0].id, opt.value)
                                                                        line_items.map((lits) => {
                                                                            if (lits.name === 'vendor_item') {
                                                                                lits.options = vendor_item_options;
                                                                            }
                                                                        });
                                                                        values["line_items"] = [];
                                                                        let tempLineItem = {};
                                                                        line_items.map((input, idx) => {
                                                                            tempLineItem[input.name] = "";
                                                                        });
                                                                        values["line_items"].push(tempLineItem);
                                                                        setLoading(false)
                                                                    }
                                                                    setFieldValue(input.name, opt.value);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                options={input.options}
                                                            />
                                                            {errors[input.name] && (
                                                                <div className="text-error">{errors[input.name]}</div>
                                                            )}
                                                        </Form.Group>
                                                    )
                                                } else if (input.input_type === 'date' && input.is_line_item === false) {
                                                    return (
                                                        <Form.Group key={idx} controlId="formBasicEmail" className="mb-3">
                                                            <Form.Label
                                                                className={input.is_required === true ? 'required-label' : ''}>{input.label}</Form.Label>
                                                            <CustomDatePicker className="form-control"
                                                                              name={input.name}
                                                                              id={input.name}
                                                                              format="MM-dd-y"
                                                                              value={formatUTCDate(new Date())}
                                                                              onChange={(value) => {
                                                                                  setFieldValue(input.name, formatUTCDate(value));
                                                                              }}
                                                                              disabled={false}
                                                            />
                                                            {/*<LocalizationProvider*/}
                                                            {/*    dateAdapter={AdapterDayjs}>*/}
                                                            {/*    <DatePicker*/}
                                                            {/*        label={*/}
                                                            {/*            <span*/}
                                                            {/*                className={input.is_required === true ? 'required-label' : ''}>{input.label}</span>*/}
                                                            {/*        }*/}
                                                            {/*        format="MM-DD-YYYY"*/}
                                                            {/*        onChange={(event) => {*/}
                                                            {/*            setFieldValue(input.name, event.target.files[0])*/}
                                                            {/*        }}*/}
                                                            {/*        slotProps={{textField: {fullWidth: true}}}*/}
                                                            {/*    />*/}
                                                            {/*</LocalizationProvider>*/}
                                                            {errors[input.name] && (
                                                                <div className="text-error">{errors[input.name]}</div>
                                                            )}
                                                        </Form.Group>

                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                }
                                {
                                    values.line_items && values.line_items.length > 0 && (<FieldArray
                                        name="line_items"
                                        render={arrayHelpers => (
                                            values.line_items.map((line_item, idx) => (
                                                <div class="row" key={'values["line_items"][' + idx + ']'}>
                                                    <p> {idx === 0 ? (
                                                            <div><b>Line Item {idx + 1}</b></div>
                                                        ) :
                                                        <div><b>Line Item {idx + 1}</b> <Button variant="danger"
                                                                                                onClick={() => {
                                                                                                    arrayHelpers.remove(idx);
                                                                                                    let sum = 0;
                                                                                                    values.line_items.map((litsum, index) => {
                                                                                                        if (index !== idx && litsum["price_per_unit"] !== "" && litsum["quantity_ordered"] !== "") {
                                                                                                            sum += litsum["quantity_ordered"] * litsum["price_per_unit"]
                                                                                                        }
                                                                                                    });
                                                                                                    setLineItemsTotal(sum);
                                                                                                }}> Remove </Button>
                                                        </div>}
                                                    </p>
                                                    {
                                                        <div>
                                                            <table class="table table-condensed " style={{width: '100%'}}>
                                                                <tbody>
                                                                <tr>

                                                                    {
                                                                        Object.keys(line_item).map((key, id) => {
                                                                            const line_item_full_obj = line_items.filter((li) =>
                                                                                li.name === key)

                                                                            if (line_item_full_obj[0].input_type === 'text') {
                                                                                return (
                                                                                    <td style={{
                                                                                        width: '30%',
                                                                                        padding: '1%'
                                                                                    }}>
                                                                                        <Form.Group
                                                                                            key={'line_items[' + idx + '].' + line_item_full_obj[0].name}

                                                                                            controlId="formFileSm"
                                                                                            className="mb-3">
                                                                                            <Form.Label
                                                                                                className={line_item_full_obj[0].is_required === true ? 'required-label' : ''}>{line_item_full_obj[0].label}</Form.Label>
                                                                                            <Form.Control type="text"
                                                                                                          size="sm" onBlur=
                                                                                                              {handleBlur}
                                                                                                          onChange={handleChange}
                                                                                                          name={'line_items[' + idx + '].' + line_item_full_obj[0].name}/>
                                                                                            {errors.hasOwnProperty("line_items") && errors["line_items"][idx] !== undefined && errors["line_items"].length > 0 && errors["line_items"][idx] !== undefined && errors["line_items"][idx][line_item_full_obj[0].name] && (
                                                                                                <div
                                                                                                    className="text-error">{errors["line_items"][idx][line_item_full_obj[0].name]}</div>
                                                                                            )}
                                                                                        </Form.Group>
                                                                                    </td>
                                                                                )
                                                                            } else if (line_item_full_obj[0].input_type === 'select') {
                                                                                return (
                                                                                    <td style={{
                                                                                        width: '30%',
                                                                                        padding: '1%'
                                                                                    }}>
                                                                                        <Form.Group size="lg"
                                                                                                    key={'line_items[' + idx + '].' + line_item_full_obj[0].name}
                                                                                                    controlId="formFileSm"
                                                                                                    className="mb-3"
                                                                                        >
                                                                                            <Form.Label
                                                                                                className={line_item_full_obj[0].is_required === true ? 'required-label' : ''}>{line_item_full_obj[0].label}</Form.Label>
                                                                                            <Select
                                                                                                name={'line_items[' + idx + '].' + line_item_full_obj[0].name}
                                                                                                bsSize="sm"
                                                                                                onChange={(opt, e) => {
                                                                                                    setFieldValue('line_items[' + idx + '].' + line_item_full_obj[0].name, opt.value);
                                                                                                }}
                                                                                                onBlur={setFieldTouched}
                                                                                                options={line_item_full_obj[0].options}
                                                                                            />
                                                                                            {errors.hasOwnProperty("line_items") && errors["line_items"][idx] !== undefined && errors["line_items"].length > 0 && errors["line_items"][idx] !== null && errors["line_items"][idx][line_item_full_obj[0].name] && (
                                                                                                <div
                                                                                                    className="text-error">{errors["line_items"][idx][line_item_full_obj[0].name]}</div>
                                                                                            )}
                                                                                        </Form.Group>
                                                                                    </td>
                                                                                )
                                                                            } else if (line_item_full_obj[0].input_type === 'number') {
                                                                                return (
                                                                                    <td style={{
                                                                                        position: 'relative',
                                                                                        padding: '1%',
                                                                                        width: '20%'
                                                                                    }}>

                                                                                        <Form.Group
                                                                                            key={'line_items[' + idx + '].' + line_item_full_obj[0].name}

                                                                                            controlId="formFileSm"
                                                                                            className="mb-3">
                                                                                            <Form.Label
                                                                                                className={line_item_full_obj[0].is_required === true ? 'required-label' : ''}>{line_item_full_obj[0].label}</Form.Label>
                                                                                            <Form.Control type="number"
                                                                                                          size="sm" onBlur=
                                                                                                              {handleBlur}
                                                                                                          onChange={(e) => {
                                                                                                              handleChange(e);
                                                                                                              if (e.target.value !== '') {
                                                                                                                  if (e.target.name.split('.')[1] === 'quantity_ordered') {
                                                                                                                      if (values.line_items[idx]["price_per_unit"] !== "") {
                                                                                                                          let sum = values.line_items[idx]["price_per_unit"] * e.target.value;
                                                                                                                          values.line_items.map((litsum, index) => {
                                                                                                                              if (index !== idx && litsum["price_per_unit"] !== "" && litsum["quantity_ordered"] !== "") {
                                                                                                                                  sum += litsum["quantity_ordered"] * litsum["price_per_unit"]
                                                                                                                              }
                                                                                                                          });
                                                                                                                          setLineItemsTotal(sum);
                                                                                                                      }
                                                                                                                  } else if (e.target.name.split('.')[1] === 'price_per_unit') {
                                                                                                                      if (values.line_items[idx]["quantity_ordered"] !== "") {
                                                                                                                          let sum = e.target.value * values.line_items[idx]["quantity_ordered"];
                                                                                                                          values.line_items.map((litsum, index) => {
                                                                                                                              if (index !== idx && litsum["price_per_unit"] !== "" && litsum["quantity_ordered"] !== "") {
                                                                                                                                  sum += litsum["quantity_ordered"] * litsum["price_per_unit"]
                                                                                                                              }
                                                                                                                          });
                                                                                                                          setLineItemsTotal(sum);
                                                                                                                      }
                                                                                                                  }
                                                                                                              }
                                                                                                          }
                                                                                                          }
                                                                                                          name={'line_items[' + idx + '].' + line_item_full_obj[0].name}/>
                                                                                            {errors.hasOwnProperty("line_items") && errors["line_items"][idx] !== undefined && errors["line_items"].length >= 0 && errors["line_items"][idx] !== null && errors["line_items"][idx][line_item_full_obj[0].name] && (
                                                                                                <div
                                                                                                    className="text-error">{errors["line_items"][idx][line_item_full_obj[0].name]}</div>
                                                                                            )}
                                                                                        </Form.Group>
                                                                                    </td>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </tr>
                                                                </tbody>
                                                            </table>


                                                            <Button
                                                                hidden={(idx === values.line_items.length - 1) ? false : true}
                                                                onClick={() => {
                                                                    let tempLineItem = {};
                                                                    let tempError = {}
                                                                    line_items.map((input, idx) => {
                                                                        tempLineItem[input.name] = "";
                                                                        if (input.is_required === true) {
                                                                            tempError[input.name] = input.label + " is required.";
                                                                        }
                                                                    });
                                                                    arrayHelpers.insert(values.line_items.length + 1, tempLineItem);
                                                                    if (errors.line_items !== undefined) {
                                                                        errors.line_items = [...errors.line_items, tempError];
                                                                    } else {
                                                                        errors.line_items = [];
                                                                        values.line_items.forEach((l) => {
                                                                            errors.line_items.push(undefined);
                                                                        })
                                                                        errors.line_items.push(tempError);
                                                                    }
                                                                }} variant="success"> Add Line Item </Button> <br/><br/>


                                                        </div>

                                                    }
                                                    {
                                                        values.line_items[idx].quantity_ordered !== '' && values.line_items[idx].quantity_ordered > 0 && values.line_items[idx].price_per_unit !== '' && values.line_items[idx].price_per_unit > 0 && (
                                                            <div style={{textAlign: "right", paddingRight: "3.5%"}}>Sub Total:
                                                                ${values.line_items[idx].quantity_ordered * values.line_items[idx].price_per_unit}</div>
                                                        )
                                                    }

                                                    {typeof errors["line_items"] === 'string' && (
                                                        <div className="text-error">{errors["line_items"]}</div>
                                                    )}


                                                </div>


                                            ))
                                        )}
                                    />)
                                }
                                {
                                    line_items_total > 0 &&
                                    <div style={{textAlign: "right"}}>Grand Total: ${line_items_total} </div>
                                }
                                <Button onClick={() => submitForm()} disabled={loading}>Execute</Button>
                            </FKForm>
                        )}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => props.close_popup(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </LoadingOverlay>
    )
}

ProcessInputPopup.propTypes = {
    close_popup: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    process: PropTypes.object.isRequired,
    showFail: PropTypes.func.isRequired,
    showSuccess: PropTypes.func.isRequired,
};
