import React, {useState} from "react";
import {Modal, Button, Spinner, Form} from "react-bootstrap";
import '../../styles/login.css';
import * as Yup from "yup";
import {useFormik} from "formik";
import PropTypes from "prop-types";
import {API_URL} from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";


export default function ResetPasswordPopup(props) {
    const [loading, setLoading] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const token = localStorage.getItem('userdata');
    const navigate = useNavigate();
    const userAttrs = useSelector((state) => state.auth.user.extra_attrs);
    const formIk = useFormik({
        initialValues: {current_password: '', password: ''},
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            await fetch(`${API_URL}/user/users/update_user_password/`, {
                method: "PATCH",
                body: JSON.stringify(values),
                headers: {
                    'Authorization': 'Bearer '+token,
                    "Content-Type": "application/json",
                },
            }).then((response)=>{
                if(response.status === 200){
                    props.callback(false);
                    props.closePopup(false);
                    if(userAttrs.portal_insurance_vendor_creds !== undefined){
                        navigate("/insurance_portal");
                    }
                }
                else{
                    enqueueSnackbar('Current password does not match.', { variant:"error" });
                    props.callback(true);
                }
            }).catch((error)=>{
                console.log(error);
                props.callback(true);
            });
            setLoading(false);
        },
        validationSchema:Yup.object({
            current_password: Yup.string().required('Current password is required.'),
            password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ , 'Invalid format. Please provide 1 upper ' +
                'case, 1 number, 1 special character and total 8 characters.').required('New Password is required.')
        })
    });

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please provide new password.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group mb-2" key="current_password">
                    <label htmlFor="current_password" className="required-label"><b>Current Password</b></label>
                    <Input
                        name="current_password" id="current_password_input" className="form-control"
                        type={showCurrentPassword ? "text" : "password"}
                        onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={()=>setShowCurrentPassword(!showCurrentPassword)}
                                    onMouseDown={(e)=>e.preventDefault()}
                                >
                                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {/*<input type="text" className="form-control" name="current_password"*/}
                    {/*       id="current_password_input" onBlur={formIk.handleBlur} onChange={formIk.handleChange}/>*/}
                    {formIk.errors['current_password'] && formIk.touched['current_password'] && (
                        <div className="text-error">{formIk.errors['current_password']}</div>
                    )}
                </div>
                <div className="form-group mb-2" key="password">
                    <label htmlFor="password" className="required-label"><b>New Password</b></label>
                    <Input
                        name="password" id="password_input" className="form-control"
                        type={showNewPassword ? "text" : "password"}
                        onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={()=>setShowNewPassword(!showNewPassword)}
                                    onMouseDown={(e)=>e.preventDefault()}
                                >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {/*<input type="text" className="form-control" name="password"*/}
                    {/*       id="password_input" onBlur={formIk.handleBlur} onChange={formIk.handleChange}/>*/}
                    {formIk.errors['password'] && formIk.touched['password'] && (
                        <div className="text-error">{formIk.errors['password']}</div>
                    )}
                </div>
                {
                    !loading && (
                        <button className="btn btn-success" onClick={() => formIk.submitForm()}>Update</button>
                    )
                }
                {
                    loading && (
                        <Button disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && props.showCloseButton !== false && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(false);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
}

ResetPasswordPopup.propTypes = {
    closePopup: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    showCloseButton: PropTypes.bool,
}
