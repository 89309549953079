import React, {useEffect} from 'react';
import '../../styles/login.css';
import {useNavigate} from "react-router";
import PropTypes from "prop-types";
import {DataGrid} from '@mui/x-data-grid';
import '../../App.css';

function CusDataGrid(props) {
    const navigate = useNavigate();
    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
    }, [])



    return (
        <div style={{"height": "650px"}}>
            {
                <DataGrid
                    columns={props.columns}
                    rows={props.data}
                    rowCount={props.rowCount}
                    getRowHeight={props.rowAutoHeight}
                    getEstimatedRowHeight={props.estimatedRowHeight}
                    paginationMode="server"
                    paginationModel={props.paginationModel}
                    pageSizeOptions={[10, 25, 50]}
                    onPaginationModelChange={props.onPaginationModelChange}
                    filterMode="server"
                    onFilterModelChange={props.onFilterModelChange}
                    sortingMode="server"
                    onSortModelChange={props.onSortModelChange}
                    loading={props.loading}
                    getRowClassName={(params) => {

                        if(params.row.process_status === "In Process" && params.row.owner === 1 ) {
                            return 'approveRows';
                        }

                    }}
                    sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: 1,
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '10px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '17px',
                        },
                        '.MuiTablePagination-displayedRows': {
                            'margin-top': '1em',
                            'margin-bottom': '1em'
                        },
                        '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                            'margin-top': '1em',
                            'margin-bottom': '1em'
                        }

                    }
                    }
                />
            }
        </div>
    )
}

CusDataGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    rowAutoHeight: PropTypes.string,
    estimatedRowHeight: PropTypes.number
};


export default CusDataGrid;
