import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    invoices: {},
    account_numbers: [],
    payment_terms:[]
};

const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        addInvoices(state, action) {
            if (!state.invoices.hasOwnProperty(action.payload.permissionType)) {
                state.invoices[action.payload.permissionType] = {};
            }
            if (!state.invoices[action.payload.permissionType].hasOwnProperty(action.payload.invoiceType)) {
                state.invoices[action.payload.permissionType][action.payload.invoiceType] = [];
            }
            // state.invoices[action.payload.permissionType][action.payload.invoiceType] = state.invoices[action.payload.permissionType][action.payload.invoiceType].concat(action.payload.invoices);
            state.invoices[action.payload.permissionType][action.payload.invoiceType] = action.payload.invoices;
        },
        removeTypeInvoices(state, action) {
            if (!state.invoices.hasOwnProperty(action.payload.permissionType)) {
                state.invoices[action.payload.permissionType] = {};
            }
            if (!state.invoices[action.payload.permissionType].hasOwnProperty(action.payload.invoiceType)) {
                state.invoices[action.payload.permissionType][action.payload.invoiceType] = [];
            }
            state.invoices[action.payload.permissionType][action.payload.invoiceType] = [];
        },
        removeInvoices(state, action) {
            state.invoices[action.payload.permissionType][action.payload.invoiceType] = state.invoices[action.payload.permissionType][action.payload.invoiceType].filter((inv) => {
                if (!action.payload.invoicesToRemove.includes(inv.id)) {
                    return inv;
                }
            })
        },
        updateInvoice(state, action) {
            state.invoices[action.payload.permissionType][action.payload.invoiceType] = state.invoices[action.payload.permissionType][action.payload.invoiceType].filter((inv) => {
                if (inv.id === action.payload.data.id) {
                    Object.keys(inv['invoice_details']).forEach((key) => {
                        if (key !== 'id') {
                            inv['invoice_details'][key]['value'] = action.payload.data['invoice_details'][key];
                        }
                    });
                    // inv['invoice_details'] = action.payload.data['invoice_details'];
                    inv['line_items'] = action.payload.data['line_items'];
                    inv['is_multi_client_number'] = action.payload.data['is_multi_client_number'];
                    inv['po_closed'] = action.payload.data['po_closed'];
                    return inv;
                } else {
                    return inv
                }
            })
            // localStorage.setItem('invoices', JSON.stringify(action.payload.data));
        },
        moveFailedToPosted(state, action) {
            state.invoices[action.payload.permissionType]['failed'] = state.invoices[action.payload.permissionType]['failed'].filter((inv) => {

                if (inv.id === action.payload.invoice_id) {

                    inv.status = 'posted';
                    state.invoices[action.payload.permissionType]['posted'] = [...state.invoices[action.payload.permissionType]['posted'], inv];
                } else {
                    return inv
                }
            });
            state.invoices[action.payload.permissionType]['failed'] = state.invoices[action.payload.permissionType]['failed'].filter(inv => inv.id !== action.payload.invoice_id)
        },
        removeAllInvoices(state, action) {
            state.invoices = {};
        },
        updateInvoiceComments(state, action) {
            state.invoices[action.payload.permissionType][action.payload.invoiceType] = state.invoices[action.payload.permissionType][action.payload.invoiceType].filter((inv) => {
                if (inv.id === action.payload.id) {
                    inv['comments'] = action.payload.comments;
                    return inv;
                } else {
                    return inv
                }
            })
        },
        setAccountNumbers(state, action) {
            state.account_numbers = action.payload;
        },
        removeAccountNumbers(state, action) {
            state.account_numbers = [];
        },
        setPaymentTerms(state, action) {
            state.payment_terms = action.payload;
        },
        removePaymentTerms(state, action) {
            state.payment_terms = [];
        }

    }
});

export const {
    addInvoices, removeInvoices, removeAllInvoices, updateInvoice, updateInvoiceComments,
    removeTypeInvoices, setAccountNumbers, removeAccountNumbers,setPaymentTerms,removePaymentTerms, moveFailedToPosted
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
