import React from "react"
import ContentLoader from "react-content-loader"

const CompanyLoading = (props) => (
    <ContentLoader
        speed={2}
        width={1500}
        height={224}
        viewBox="0 0 1500 224"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="8" rx="3" ry="3" width="600" height="30" />
        <rect x="0" y="40" rx="3" ry="3" width="950" height="1" />
        <rect x="0" y="45" rx="3" ry="3" width="400" height="30" />
        <rect x="0" y="85" rx="3" ry="3" width="600" height="20" />
    </ContentLoader>
)

export default CompanyLoading;

