import React, {useEffect, useState} from 'react';
import '../styles/login.css';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {login, login_without_password, getUserInformation} from '../services/login';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {Button, Spinner} from "react-bootstrap";
import CusNavBar from "./navbar";
import {
    addToken,
    addUserInformation,
    removeCompany,
    removeToken,
    removeUserInformation,
    removeSelectedDepartment,
    removeDepartments,
    removeDepartmentApprovementLevel
} from "../store/auth";
import {removeCategories, removeInvoicesDashboard} from "../store/dashboard";
import {removeProcess, setCurrentActive} from "../store/processes";
import {removeAllInvoices} from "../store/invoices";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import {useSearchParams} from "react-router-dom";
import CusAlert from "./alert";
import {removeDocuments} from "../store/document_approval";
import ForgotPasswordPopup from "../components/popups/forgot_password_popup";

function VermostLogin(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginBtnDisabled, setLoginBtnDisabled] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const user_created = searchParams.get('uc') || '';
    const is_vermost = searchParams.get('is_vermost') === 'true';
    const lead_id = searchParams.get('lead_id') ?? searchParams.get('customer_id');
    let existingCustomerParams = false;

    useEffect(() => {



        if (localStorage.getItem('userdata')) {
            if (lead_id) {
                if (searchParams.get('customer_id')) {
                    existingCustomerParams = true
                }
                navigate({
                    pathname: '/dashboard/',
                    search: `?lead_id=${lead_id}&existingCustomer=${existingCustomerParams}`,
                })

            } else {
                navigate("/dashboard");
            }
        }
        document.title = "Login";
        if (user_created === '1') {
            setShowSuccessMessage(true)
        }
    }, [])

    const formIk = useFormik({
        initialValues: {email: '', password: ''},
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLoginBtnDisabled(true);
            const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            // if (re.test(values.email)){
            login(values).then((tokenData) => {
                dispatch(removeToken());
                dispatch(addToken(tokenData.access));
                localStorage.setItem('userdata', tokenData.access);
                getUserInformation().then((userData) => {
                    dispatch(removeUserInformation());
                    dispatch(removeCompany());
                    dispatch(removeInvoicesDashboard());
                    dispatch(removeCategories());
                    dispatch(removeProcess());
                    dispatch(setCurrentActive());
                    dispatch(removeAllInvoices());
                    dispatch(removeSelectedDepartment());
                    dispatch(removeDepartments());
                    dispatch(removeDepartmentApprovementLevel());
                    dispatch(removeDocuments());
                    dispatch(addUserInformation(userData));

                }).then(() => {
                    if (lead_id) {
                        if (searchParams.get('customer_id')) {
                            existingCustomerParams = true
                        }
                        navigate({
                            pathname: '/dashboard/',
                            search: `?lead_id=${lead_id}&existingCustomer=${existingCustomerParams}`,
                        })

                    } else {
                        navigate("/dashboard");
                    }
                }).catch(error => {
                    console.log(error)
                    setLoginBtnDisabled(false);
                    formIk.errors['password'] = 'Invalid Credentials.';
                    setSubmitting(false);
                });
            }).catch(error => {
                console.log(error)
                setLoginBtnDisabled(false);
                formIk.errors['password'] = 'Invalid Credentials.';
                setSubmitting(false);
            });

            setSubmitting(false);
        },
        validationSchema: Yup.object({
            email: Yup.string().required('Email is required'),
            password: Yup.string().required('Password is required'),
        })
    })

    return (
        <>
            <CusNavBar/>
            {
                showSuccessMessage && <CusAlert alertType='success' alertMessage={"User created successfully."}
                                                alertClose={setShowSuccessMessage}/>
            }
            <div
                className="absolute inset-x-0 shadow-xl bg-white w-3/4 md:w-2/5 mx-auto -mt-1 rounded-lg rounded-t-none p-4 top-1/2 -translate-y-1/2 bg-white rounded-lg border border-gray-200 shadow-md sm:p-8 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
                <form className="space-y-6" id="login-form" onSubmit={formIk.handleSubmit}>
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">Sign in to our platform</h5>
                    <div>
                        <label htmlFor="email"
                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your
                            email</label>
                        <input type="email" name="email" id="email" style={{color: "black"}}
                               className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                               placeholder="name@company.com" onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                               required/>
                        {formIk.errors['email'] && formIk.touched['email'] && (
                            <div className="text-error">{formIk.errors['email']}</div>
                        )}
                    </div>
                    <div>
                        <label htmlFor="password"
                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your
                            password</label>
                        <Input style={{padding: "1%", color: "black"}}
                               name="password" id="password" placeholder="••••••••"
                               className="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                               type={showNewPassword ? "text" : "password"}
                               onBlur={formIk.handleBlur} onChange={formIk.handleChange}
                               endAdornment={
                                   <InputAdornment position="end">
                                       <IconButton
                                           onClick={() => setShowNewPassword(!showNewPassword)}
                                           onMouseDown={(e) => e.preventDefault()}
                                       >
                                           {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                       </IconButton>
                                   </InputAdornment>
                               }
                        />
                        {formIk.errors['password'] && formIk.touched['password'] && (
                            <div className="text-error">{formIk.errors['password']}</div>
                        )}
                    </div>
                    {
                        !loginBtnDisabled ?
                            <>
                                <button onClick={() => formIk.submitForm()}
                                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login
                                    to your account
                                </button>
                                
                                <button onClick={() => {navigate("/vermost_guest_dashboard");}}
                                        className="w-full text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-white">Login
                                    as a Guest user
                                </button>
                               
                                <button style={{display: "none"}} onClick={(event) => {
                                    event.preventDefault();
                                    setLoginBtnDisabled(true);
                                    login_without_password({
                                        'email': formIk.values['email'],
                                        'password': '1234'
                                    }).then((tokenData) => {
                                        dispatch(removeToken());
                                        dispatch(addToken(tokenData.access));
                                        localStorage.setItem('userdata', tokenData.access);

                                        getUserInformation().then((userData) => {

                                            dispatch(removeUserInformation());
                                            dispatch(removeCompany());
                                            dispatch(removeInvoicesDashboard());
                                            dispatch(removeCategories());
                                            dispatch(removeProcess());
                                            dispatch(setCurrentActive());
                                            dispatch(removeAllInvoices());
                                            dispatch(removeSelectedDepartment());
                                            dispatch(removeDepartments());
                                            dispatch(removeDepartmentApprovementLevel());
                                            dispatch(removeDocuments());
                                            dispatch(addUserInformation(userData));

                                        }).then(() => {
                                            setLoginBtnDisabled(false);
                                            navigate("/dashboard");
                                        }).catch(error => {
                                            console.log(error)
                                            setLoginBtnDisabled(false);
                                            formIk.errors['password'] = 'Invalid Credentials.';
                                        });

                                    }).catch(error => {
                                        console.log(error);
                                        setLoginBtnDisabled(false);
                                        formIk.errors['password'] = 'Invalid Credentials.';
                                    });
                                }} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4
                                    focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                                    dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your
                                    account WA
                                </button>
                                <div style={{textAlign: "right"}}>
                                    {
                                        is_vermost && (
                                            <p>Do not have account? Click to&nbsp;
                                                <span><a href="/register_vermost">Register</a></span>.
                                            </p>
                                        )
                                    }
                                    <p>&nbsp;
                                        <span onClick={() => setShowForgotPassword(true)}>
                                            <a href="#">
                                                Forgot Password?
                                            </a>
                                        </span>
                                    </p>
                                </div>
                                
                                
                            </> :
                            <Button variant="primary" className="loadingBtn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </Button>
                    }
                </form>
            </div>
            {
                showForgotPassword && <ForgotPasswordPopup closePopup={setShowForgotPassword} show={true}/>
            }
        </>
    )
}


export default VermostLogin;
