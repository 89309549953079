import React, {useState} from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Form, Col, Row} from "react-bootstrap";
import '../../styles/login.css';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addLawFirmBackend,
    getLawFirmBranches,
    updateLawFirmBackend
} from "../../services/company_management";
import {
    addLawFirm,
    updateLawFirm, addLawFirmBranches
} from "../../store/company_management";
import {addLawFirmData, addNewLawFirm, removeLawFirmData, updateLawFirm as updateLawFirmBond} from "../../store/bond";
import USPhoneNumberWithoutCountryCodeUniversal from "../../global_components/inputs/us_phone_number_without_country_code";
import CustomMultiSelectInput from "../../global_components/inputs/multi-select-field";
import {getLawFirmData} from "../../services/bond";
import {hasUserPermission} from "../../utils/user_permission";
import { log } from "../../utils/logger";


export default function LawFirmPopup(props) {
    const dispatch = useDispatch();
    log("law_firm_id", props.law_firm_id);
    const lawFirmsInStore = useSelector((state) => state.companyManagement.law_firms);
    const userInStore = useSelector((state) => state.companyManagement.users);
    const [loading, setLoading] = useState(false);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const isValidate = hasUserPermission(user_permission,'ignore_validation') ;
    let law_firm_details = {};
    let selectedUsers = {};
    if (props.law_firm_id !== 0) {
        law_firm_details = lawFirmsInStore.filter((law_firm) => law_firm.id === props.law_firm_id)[0];
        log("law_firm_details", law_firm_details)
    }
    // Create a shallow copy of law_firm_details to make it extensible
    law_firm_details = { ...law_firm_details, users: law_firm_details.users ? law_firm_details.users : [''] };

    log("Updated law_firm_details", law_firm_details);
    
    selectedUsers = userInStore.filter((user) => user.id === law_firm_details.user)[0];


    const getUserOptions = () => {
        let userOpts = [];
        userInStore.map((user) => userOpts.push({label: `${user.first_name} ${user.last_name}`, value: user.id}))
        return userOpts;
    }


    const getInitialValues = () => {
        if (props.law_firm_id !== 0) {
            return {
                name: law_firm_details.name, website: law_firm_details.website, phone: law_firm_details.phone,
                fax: law_firm_details.fax, users: law_firm_details.users,
                mail_addr_same_as_phy_addr: law_firm_details.mail_addr_same_as_phy_addr,
                customer_number: law_firm_details.customer_number, is_active: law_firm_details.is_active
            }
        } else {
            return {
                name: '', website: '', phone: '', fax: '', mail_addr_same_as_phy_addr: true,
                users: [], customer_number: 0, is_active: true
            }
        }
    }

    const getValidation = () => {
        if (!isValidate) {
            return Yup.object({
                name: Yup.string().required('Name is required.'),
                website: Yup.string()
                    .matches(
                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        'Enter correct url!'
                    )
                    .required('Please enter website'),
                phone: Yup.string().required('Phone number is required.').matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378"),
                users: Yup.array().of(Yup.number()).min(1, "Atleast one user required."),
                fax: Yup.string().matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid fax i.e. (504) 595 1378"),
                mail_addr_same_as_phy_addr: Yup.boolean().oneOf([true, false], 'Similar Address status is required.'),
                customer_number: Yup.number().required('Customer Number is required.'),
                is_active: Yup.boolean().oneOf([true, false], 'Active status is required.'),
            })
        } else {
            return Yup.object({
                name: Yup.string().required('Name is required.'),
                website: Yup.string()
                    .matches(
                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        'Enter correct url!'
                    ).nullable(),
                phone: Yup.string().matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid phone number i.e. (504) 595 1378").nullable(),
                users: Yup.array().of(Yup.number()).nullable(),
                fax: Yup.string().matches(/^([(]?)([0-9]{1})([0-9]{2})([)]?)([\s.-]?)([0-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$/gm, "Enter a valid fax i.e. (504) 595 1378").nullable(),
                mail_addr_same_as_phy_addr: Yup.boolean().oneOf([true, false], 'Similar Address status is required.'),
                customer_number: Yup.number().nullable(),
            })
        }
    }

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            if (props.law_firm_id !== 0) {
                await updateLawFirmBackend(props.law_firm_id, values).then((res) => {
                    if (res === 200) {
                        props.showSuccessAlert(true);
                        props.firstLawFirmLoading(true);
                        props.setSuccessMessage('Law Firm updated successfully');
                        dispatch(updateLawFirm({
                            id: props.law_firm_id,
                            name: values.name,
                            website: values.website,
                            phone: values.phone,
                            fax: values.fax,
                            users: values.users,
                            customer_number: values.customer_number,
                            mail_addr_same_as_phy_addr: values.mail_addr_same_as_phy_addr,
                            is_active: values.is_active
                        }))
                        dispatch(updateLawFirmBond({id: props.law_firm_id, name: values.name}))
                        dispatch(removeLawFirmData());
                        console.log("updateLawFirmBackend");
                        getLawFirmBranches().then((response) => {
                            dispatch(addLawFirmBranches(response['data']));
                        }).catch((error) => {
                            console.log(error);
                        });
                        //dispatch(removeLawFirmBranches());
                    } else {
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(0);
                }).catch((error) => {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(0);
                })
            } else {
                await addLawFirmBackend(values).then((res) => {
                    if (res.status === 201) {
                        const data = res.data;
                        props.showSuccessAlert(true);
                        props.firstLawFirmLoading(true);
                        props.setSuccessMessage('Law Firm created successfully.');
                        dispatch(addLawFirm(data))
                        dispatch(addNewLawFirm({label: data.name, value: data.id}))
                    } else {
                        props.showFailureAlert(true);
                        props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    }
                    setLoading(false);
                    props.closePopup(false);
                }).catch((error) => {
                    console.log(error);
                    props.showFailureAlert(true);
                    props.setFailureMessage('Something went wrong sorry for inconvenience.');
                    setLoading(false);
                    props.closePopup(false);
                })


            }

        },
        validationSchema: getValidation()
    });
    const [isSameMailAddressCheckbox, setIsSameMailAddressCheckbox] = useState(props.law_firm_id !== 0 ? law_firm_details.mail_addr_same_as_phy_addr : true);
    const [isActiveCheckbox, setIsActiveCheckbox] = useState(props.law_firm_id !== 0 ? law_firm_details.is_active : true);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Law Firm
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group key="name" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className='required-label'><strong>Law Firm Name</strong>
                            </Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='name'
                                          defaultValue={props.law_firm_id !== 0 ? law_firm_details.name : ''}/>
                            {formIk.errors['name'] && formIk.touched['name'] && (
                                <div className="text-error">{formIk.errors['name']}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="website" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={!isValidate ? 'required-label' : ''}>
                                <strong>Website</strong>
                            </Form.Label>
                            <Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='website'
                                          defaultValue={props.law_firm_id !== 0 ? law_firm_details.website : ''}/>
                            {formIk.errors['website'] && formIk.touched['website'] && (
                                <div className="text-error">{formIk.errors['website']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key="phone" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={!isValidate ? 'required-label' : ''}>
                                <strong>Phone Number</strong>
                            </Form.Label>
                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                                      className="form-control"
                                                                      name="phone" id="phone-input"
                                                                      value={props.law_firm_id !== 0 ? law_firm_details.phone : ''}
                                                                      onChange={(val) => {
                                                                          if (val === "(___) ___ ____") {
                                                                              val = "";
                                                                          }
                                                                          formIk.setFieldValue('phone', val);
                                                                      }}
                            />
                            {formIk.errors['phone'] && (
                                <div className="text-error">{formIk.errors["phone"]}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group key="fax" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label><strong>Fax</strong></Form.Label>
                            <USPhoneNumberWithoutCountryCodeUniversal disabled={false} required={true}
                                                                      onBlur={formIk.handleBlur}
                                                                      className="form-control"
                                                                      name="phone" id="fax-input"
                                                                      value={props.law_firm_id !== 0 ? law_firm_details.fax : ''}
                                                                      onChange={(val) => {
                                                                          if (val === "(___) ___ ____") {
                                                                              val = "";
                                                                          }
                                                                          formIk.setFieldValue('fax', val);
                                                                      }}
                            />
                            {/*<Form.Control type="text" size="sm" onBlur={formIk.handleBlur}
                                          onChange={formIk.handleChange}
                                          name='fax'
                                          defaultValue={props.law_firm_id !== 0 ? law_firm_details.fax : ''}/>*/}
                            {formIk.errors['fax'] && formIk.touched['fax'] && (
                                <div className="text-error">{formIk.errors['fax']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group key="users" className="col-12" controlId="formBasicEmail"
                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                            <Form.Label className={!isValidate ? 'required-label' : ''}>
                                <strong>Users</strong>
                            </Form.Label>
                            <CustomMultiSelectInput
                                disabled={false}
                                name="users"
                                onChange={
                                    (value) => formIk.setFieldValue('users', value)
                                }
                                options={getUserOptions()}
                                value={props.law_firm_id !== 0 ? law_firm_details.users : []}
                            />
                            {formIk.errors['users'] && (
                                <div className="text-error">{formIk.errors['users']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Control type="hidden" name='is_admin' defaultValue={hasUserPermission(user_permission,'show_customer_number_for_law_firm')}/>
                {
                    hasUserPermission(user_permission,'show_customer_number_for_law_firm')  &&
                    <Row>
                        <Col>
                            <Form.Group key="customer_number" className="col-12" controlId="formBasicEmail"
                                        style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                <Form.Label className={!isValidate ? 'required-label' : ''}>
                                    <strong>Customer Number</strong>
                                </Form.Label>
                                <Form.Control type="number" size="sm" onBlur={formIk.handleBlur}
                                              onChange={formIk.handleChange}
                                              name='customer_number'
                                              defaultValue={props.law_firm_customer_number_id !== 0 ? law_firm_details.customer_number : 0}/>
                                {formIk.errors['customer_number'] && formIk.touched['customer_number'] && (
                                    <div className="text-error">{formIk.errors['customer_number']}</div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                }
                <Row>
                    <Col>
                        <Form.Group key={'mail_addr_same_as_phy_addr'} controlId="formFileSm" className="mb-3">
                            <Form.Label className={!isValidate ? 'required-label' : ''}>
                                <strong>Mailing Address Same as Physical Address</strong>
                            </Form.Label>
                            <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                   onChange={() => {
                                       {
                                           formIk.setFieldValue('mail_addr_same_as_phy_addr', !isSameMailAddressCheckbox);
                                           setIsSameMailAddressCheckbox(!isSameMailAddressCheckbox);
                                       }
                                   }}
                                   name='mail_addr_same_as_phy_addr' checked={isSameMailAddressCheckbox}/>
                            {formIk.errors['mail_addr_same_as_phy_addr'] && formIk.touched['mail_addr_same_as_phy_addr'] && (
                                <div className="text-error">{formIk.errors['mail_addr_same_as_phy_addr']}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                {props.law_firm_id !== 0 &&
                    <Row>
                        <Col>
                            <Form.Group key={'is_active'} controlId="formFileSm" className="mb-3">
                                <Form.Label className={!isValidate ? 'required-label' : ''}>
                                    <strong>Active</strong>
                                </Form.Label>
                                <input style={{marginLeft: "3%"}} type="checkbox" size="sm" onBlur={formIk.handleBlur}
                                       onChange={() => {
                                           {
                                               formIk.setFieldValue('is_active', !isActiveCheckbox);
                                               setIsActiveCheckbox(!isActiveCheckbox);
                                           }
                                       }}
                                       name='is_active' checked={isActiveCheckbox}/>
                                {formIk.errors['is_active'] && formIk.touched['is_active'] && (
                                    <div className="text-error">{formIk.errors['is_active']}</div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && (
                        <Button variant="success" onClick={() => {
                            formIk.submitForm()
                        }}>Save</Button>
                    )
                }
                {
                    loading && (
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    )
                }
                {
                    !loading && (
                        <Button onClick={(e) => {
                            e.preventDefault();
                            props.closePopup(0);
                        }}>Cancel</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

LawFirmPopup.propTypes = {
    law_firm_id: PropTypes.number.isRequired,
    closePopup: PropTypes.func.isRequired,
    showSuccessAlert: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    showFailureAlert: PropTypes.func.isRequired,
    setFailureMessage: PropTypes.func.isRequired,
    firstLawFirmLoading: PropTypes.func
}
