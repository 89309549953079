import React, {useEffect, useState} from "react";
import '../../../styles/login.css';
import {
    deleteLawFirmBackend, deleteUserLawFirmBackend,
    getUserLawFirms, getVermostLawFirms,
} from "../../../services/company_management";
import CustomDataGrid from "../../../global_components/datatables/custom_datagrid";
import {hasUserPermission} from "../../../utils/user_permission";
import {useDispatch, useSelector} from "react-redux";
import {addLawFirms, removeLawFirm} from "../../../store/company_management";
import PropTypes from "prop-types";
import {log} from "../../../utils/logger";
import UserLawFirmInsertPopup from "../vermost_popups/add_edit_user_lawfirm_popup";
import {useSnackbar} from "notistack";
import AddLawFirmPopup from "../vermost_popups/add_law_firm_popup";

const UserLawFirms = (props) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    // const [lawFirmList, setLawFirmList] = useState([]);
    const [loadingLawFirm, setLoadingLawFirm] = useState(true);
    const {enqueueSnackbar} = useSnackbar();
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const hasUpdatePermission = hasUserPermission(user_permission, 'show_table_update_button');
    const hasDeletePermission = hasUserPermission(user_permission, 'show_table_delete_button');
    const hasAddPermission = hasUserPermission(user_permission, 'show_table_add_button')
    useEffect(() => {
        if (props.user_id !== 0) {
            setDisabled(false)
        }
    }, []);


    const getColumns = () => {
        let cols = [
            {
                field: 'law_firm_name',
                headerName: 'Law Firm',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'location_name',
                headerName: 'Location',
                minWidth: 100,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'is_default',
                headerName: 'Default',
                minWidth: 50,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                type: 'boolean',
                sortable: false,
            },
            {
                field: 'is_active',
                headerName: 'Active',
                minWidth: 50,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                type: 'boolean',
                sortable: false,
            },
            {
                field: 'id',
                headerName: '',
                minWidth: 200,
                maxWidth: 1000,
                align: 'right',
                disableColumnMenu: true,
                flex: 1,
                sortable: false,
                editable: false,
                headerAlign: 'right',
                filterable: false,
                headerClassName: 'header-bold'
            }
        ]
        return cols
    }
    const columns = getColumns();

    // const loadLawFirmList = async () => {
    //     let response = await getVermostLawFirms('1','10','','');
    //     if (response.status === 200) {
    //         const result = await response.json();
    //         setLawFirmList(result.data);
    //         dispatch(addLawFirms(result.data));
    //     } else {
    //         const result = await response.json();
    //         enqueueSnackbar(result, {variant: "error"});
    //     }
    //
    // }

    // useEffect(async () => {
    //     await loadLawFirmList();
    // }, []);

    return (
        <>
            <h1>User's Law Firms</h1>
            <CustomDataGrid rows={props.data}
                            columns={columns}
                            rowCount={props.rowCount}
                            paginationModel={props.controller}
                            onPaginationModelChange={props.gatedSetController}
                            onFilterModelChange={props.onFilterChange}
                            onSortModelChange={props.handleSortModelChange}
                            loading={props.loading}
                            isActionsVisible={true}
                            visibleActionButtons={true}
                            isEdit={props.isEdit}
                            onActionCallback={props.onActionCallback}
                            onCallback={props.onCallback}
                            filterModel={props.filterModel}
                            addPermission={hasAddPermission}
                            deletePermission={hasDeletePermission}
                            updatePermission={hasUpdatePermission}/>



        </>
    )


};

UserLawFirms.propTypes = {
    onActionCallback: PropTypes.func,
    onCallback: PropTypes.func,
    showInsertPopup: PropTypes.bool,
}
export default UserLawFirms;
