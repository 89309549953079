import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import "../../index.css";
import {
    Card,
    CardContent,
    Checkbox,
    Container,
    FormControlLabel,
} from "@mui/material";
import {ArrowRight, ArrowLeft} from 'react-bootstrap-icons';
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import CustomSelectInput from "../../global_components/inputs/select-field";
import CustomAmountInput from "../../global_components/inputs/amount";
import CustomTextInput from "../../global_components/inputs/text";
import CustomDatePicker from "../../global_components/custom_date_picker";
import {formatDate, formatUTCDate} from "../../services/helper";
import CustomNumberInput from "../../global_components/inputs/number";
import {calculatePremium} from "../../utils/vermost_calculator"
import {forEach} from "react-bootstrap/ElementChildren";


export default function BasicInformation({formIK, step, progress, changeStep}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [bondSubCategoryOptions, setBondSubCategoryOptions] = useState([]);
    const [premiumVal, setPremiumVal] = useState(formIK.values.premium);
    const [courtCaseError, setCourtCaseError] = useState(false);
    const processInput = useSelector((state) => state.bond.bond_process_input);
    const normalInputs = processInput.filter((input) => input.is_line_item === false);
    const lineItemInputs = processInput.filter((input) => input.is_line_item === true);
    let subCats = [];
    const validate = () => {
        let error = false;
        let errors = {}
        if (formIK.values.bond_type === "" || formIK.values.bond_type === undefined) {
            errors = {...errors, bond_type: 'Bond Type is required.'};
            error = true;
        }
        if (formIK.values.bond_sub_category === "" || formIK.values.bond_sub_category === undefined) {
            errors = {...errors, bond_sub_category: 'Bond Sub Category is required.'};
            error = true;
        }
        if (formIK.values.bond_amount < 0) {
            errors = {...errors, bond_amount: 'Bond Amount must be greater than 0.'}
            error = true;
        }
        if (formIK.values.bond_amount === "" || formIK.values.bond_amount === undefined) {
            errors = {...errors, bond_amount: 'Bond Amount is required.'}
            error = true;
        }
        if (formIK.values.deceased_legal_first_name === "" || formIK.values.deceased_legal_first_name === undefined) {
            errors = {...errors, deceased_legal_first_name: 'Deceased Legal First Name is required.'}
            error = true;
        }
        if (formIK.values.deceased_legal_last_name === "" || formIK.values.deceased_legal_last_name === undefined) {
            errors = {...errors, deceased_legal_last_name: 'Deceased Legal Last Name is required.'}
            error = true;
        }
        //if (formIK.values.initialValues['deceased_legal_name'] = bondToEdit["deceased_legal_name"]; === "" || formIK.values.deceased_legal_name === undefined) {
        //    errors = {...errors, deceased_legal_name: 'Deceased Legal Name is required.'}
        //    error = true;
        //}
        if (formIK.values.date_of_death === "" || formIK.values.date_of_death === undefined) {
            errors = {...errors, date_of_death: 'Date of Death is required.'};
            error = true;
        }
        if (new Date(formIK.values.date_of_death) > new Date()) {
            errors = {...errors, date_of_death: 'Date of Death cannot be of future.'};
            error = true;
        }
        if (formIK.values.state === "" || formIK.values.state === undefined) {
            errors = {...errors, state: 'State is required.'};
            error = true;
        }
        if (formIK.values.county === "" || formIK.values.county === undefined) {
            errors = {...errors, county: 'County is required.'};
            error = true;
        }
        if (formIK.values.county === "Pasco") {
            if (formIK.values.has_judge_order === "" || formIK.values.has_judge_order === undefined) {
                errors = {
                    ...errors,
                    has_judge_order: 'Has Judge Stearn’s issued his order requiring bond yet? for Pasco is required.'
                };
                error = true;
            }
        }
        if (formIK.values.county === 'Pinellas') {
            if (formIK.values.court_case_number !== "") {
                const court_case_number_regex = new RegExp("^\\d{2}-\\d{6}-(ES|GD)$");
                if (!court_case_number_regex.test(formIK.values.court_case_number)) {
                    errors = {
                        ...errors,
                        court_case_number: 'Court Case Number for pinellas must match Year(YY) – 6_Digit_Sequence# – Court_Type (ES, GD).'
                    };
                    error = true;
                    setCourtCaseError(true);
                }
            }
            if (formIK.values.bond_type === 'Probate' && formIK.values.bond_amount < 18000) {
                errors = {...errors, bond_amount: 'The minimum expected value to be entered is $18000.'};
                error = true;
                setCourtCaseError(true);
            }
        }
        if (formIK.values.number_of_personal_representative === "" || formIK.values.number_of_personal_representative === undefined) {
            errors = {...errors, number_of_personal_representative: 'Number of Personal Representative should be between 1-4.'};
            error = true;
        } else {
            if (formIK.values.number_of_personal_representative > 4) {
                errors = {...errors, number_of_personal_representative: 'Number of Personal Representative should be between 1-4.'};
                error = true;
            }
            if (formIK.values.number_of_personal_representative < 1) {
                errors = {...errors, number_of_personal_representative: 'Number of Personal Representative should be between 1-4.'};
                error = true;
            }
        }
        if (error === true) {
            formIK.setErrors(errors);
            window.scroll(0, 0);
        } else {
            changeStep(progress + step);
        }
    }

    if (formIK.values.bond_type !== "" && formIK.values.bond_type !== undefined) {
        let sub_category_opt = normalInputs.filter((input) => input.name === "bond_sub_category")[0].options;
        sub_category_opt = sub_category_opt.filter((opt) => opt.filter_values.includes(formIK.values.bond_type));
        subCats = sub_category_opt;
    }

    useEffect(() => {
        if (formIK.values.date_of_death === "") {
            formIK.values.date_of_death = formatUTCDate(new Date())
        }
    });
    return (
        <div>
            <Container>
                <Col>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0}
                                        className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Card sx={{maxWidth: "50%"}} style={{marginBottom: "2%"}}>

                            <CardContent>
                                <h4>Basic Information</h4>
                                <Row>
                                    <Col>
                                        <Form.Group key="bond_type" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Bond
                                                Type</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="bond_type"
                                                               value={formIK.values.bond_type}
                                                               onChange={(value) => {
                                                                   formIK.setFieldValue("bond_type", value);
                                                                   let sub_category_opt = normalInputs.filter((input) => input.name === "bond_sub_category")[0].options;
                                                                   sub_category_opt = sub_category_opt.filter((opt) => opt.filter_values.includes(value));
                                                                   setBondSubCategoryOptions(sub_category_opt);
                                                                   if (value === "Probate") {
                                                                       formIK.setFieldValue("bond_sub_category", "Personal Representative");
                                                                   } else {
                                                                       formIK.setFieldValue("bond_sub_category", "");
                                                                   }

                                                               }}
                                                               options={normalInputs.filter((input) => input.name === "bond_type")[0].options}/>
                                            {formIK.errors["bond_type"] && (
                                                <div className="text-error">{formIK.errors["bond_type"]}</div>
                                            )}
                                        </Form.Group>
                                        {/*<div key={formIK.errors["bond_type"]} className="text-error">*/}
                                        {/*    {formIK.errors["bond_type"]}*/}
                                        {/*</div>*/}
                                        <Form.Group key="bond_sub_category" className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Bond Sub
                                                Category</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="bond_sub_category"
                                                               value={formIK.values.bond_sub_category}
                                                               onChange={(value) => formIK.setFieldValue("bond_sub_category", value)}
                                                               options={bondSubCategoryOptions.length === 0 ? subCats : bondSubCategoryOptions}/>
                                            {formIK.errors["bond_sub_category"] && (
                                                <div className="text-error">{formIK.errors["bond_sub_category"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="state" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>State</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="state" value={formIK.values.state}
                                                               onChange={(value) => {
                                                                   formIK.setFieldValue("state", value);
                                                               }}
                                                               options={normalInputs.filter((input) => input.name === "state")[0].options}/>
                                            {formIK.errors["state"] && (
                                                <div className="text-error">{formIK.errors["state"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="county" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>County</strong></Form.Label>
                                            <CustomSelectInput disabled={false} name="county"
                                                               value={formIK.values.county}
                                                               onChange={(value) => {
                                                                   formIK.setFieldValue("county", value);
                                                               }}
                                                               options={normalInputs.filter((input) => input.name === "county")[0].options.filter(opt => opt.filter_values.includes(formIK.values.state))}/>
                                            {formIK.errors["county"] && (
                                                <div className="text-error">{formIK.errors["county"]}</div>
                                            )}
                                        </Form.Group>
                                        {
                                            formIK.values.county === 'Pasco' && (
                                                <Form.Group key={formIK.errors["has_judge_order"]} className="col-12"
                                                            controlId="formBasicEmail"
                                                            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                                    <Form.Label className='required-label'><strong>Has Judge Stearn’s issued
                                                        his order requiring bond yet?</strong></Form.Label>
                                                    <CustomSelectInput disabled={false} name="has_judge_order"
                                                                       value={formIK.values.has_judge_order}
                                                                       onChange={(value) => formIK.setFieldValue("has_judge_order", value)}
                                                                       options={[{label: 'Yes', value: 'yes'}, {
                                                                           label: 'No',
                                                                           value: 'no'
                                                                       }]}/>

                                                    <div key={formIK.errors["has_judge_order"]}
                                                         className="text-error">{formIK.errors["has_judge_order"]}</div>

                                                </Form.Group>
                                            )
                                        }
                                        <Form.Group key="bond_amount" className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Bond
                                                Amount</strong></Form.Label>
                                            <CustomAmountInput disabled={false} required={true} name="bond_amount"
                                                               className={"form-control"} id="bond_amount-input"
                                                               decimalScale={0}
                                                               value={formIK.values.bond_amount} onChange={(value) => {
                                                let premium = calculatePremium(value)

                                                setPremiumVal(premium);
                                                formIK.setFieldValue("premium", premium);
                                                formIK.setFieldValue("bond_amount", value);
                                            }
                                            }/>
                                            {formIK.errors["bond_amount"] && (
                                                <div className="text-error">{formIK.errors["bond_amount"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key={premiumVal} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Premium
                                                Amount</strong></Form.Label>
                                            <CustomAmountInput disabled={true} required={true} name="premium"
                                                               className={"form-control"} id="premium-input"
                                                               decimalScale={0}
                                                               value={premiumVal.toString()} onChange={(value) => {
                                                formIK.setFieldValue("premium", value);
                                            }
                                            }/>
                                            {formIK.errors["premium"] && formIK.touched["premium"] && (
                                                <div className="text-error">{formIK.errors["premium"]}</div>
                                            )}
                                        </Form.Group>

                                        <Form.Group key="deceased_legal_first_name" className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Deceased Legal First
                                                Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true}
                                                             name="deceased_legal_first_name"
                                                             className={"form-control"}
                                                             id="deceased_legal_first_name-input"
                                                             value={formIK.values.deceased_legal_first_name}
                                                             onChange={(value) => {
                                                                 formIK.setFieldValue("deceased_legal_first_name", value);
                                                             }}/>
                                            {formIK.errors["deceased_legal_first_name"] && (
                                                <div
                                                    className="text-error">{formIK.errors["deceased_legal_first_name"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="deceased_legal_middle_name" className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label><strong>Deceased Legal Middle Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true}
                                                             name="deceased_legal_middle_name"
                                                             className={"form-control"}
                                                             id="deceased_legal_middle_name-input"
                                                             value={formIK.values.deceased_legal_middle_name}
                                                             onChange={(value) => {
                                                                 formIK.setFieldValue("deceased_legal_middle_name", value);
                                                             }}
                                            />
                                            {formIK.errors["deceased_legal_middle_name"] && (
                                                <div
                                                    className="text-error">{formIK.errors["deceased_legal_middle_name"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="deceased_legal_last_name" className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Deceased Legal Last
                                                Name</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={true}
                                                             name="deceased_legal_last_name"
                                                             className={"form-control"}
                                                             id="deceased_legal_last_name-input"
                                                             value={formIK.values.deceased_legal_last_name}
                                                             onChange={(value) => {
                                                                 formIK.setFieldValue("deceased_legal_last_name", value);
                                                             }}/>
                                            {formIK.errors["deceased_legal_last_name"] && (
                                                <div
                                                    className="text-error">{formIK.errors["deceased_legal_last_name"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key="deceased_legal_suffix" className="col-12"
            controlId="formBasicEmail"
            style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
    <Form.Label><strong>Deceased Legal Suffix</strong></Form.Label>
    <Form.Select
        disabled={false} 
        required={false}
        name="deceased_legal_suffix"
        className="form-control" 
        id="deceased_legal_suffix-input"
        value={formIK.values.deceased_legal_suffix}
        onChange={(e) => formIK.setFieldValue("deceased_legal_suffix", e.target.value)}>
        <option value="">Select suffix</option>
        <option value="Sr.">Sr.</option>
        <option value="Jr.">Jr.</option>
        <option value="I">I</option>
        <option value="II">II</option>
        <option value="III">III</option>
        <option value="IV">IV</option>
    </Form.Select>
    {formIK.errors["deceased_legal_suffix"] && (
        <div className="text-error">{formIK.errors["deceased_legal_suffix"]}</div>
    )}
</Form.Group>

                                        {/* <Form.Group key="deceased_legal_name" className="col-12"*/}
                                        {/*            controlId="formBasicEmail"*/}
                                        {/*           style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>*/}
                                        {/*    <Form.Label className='required-label'><strong>Deceased Legal Name</strong></Form.Label>*/}
                                        {/*    <CustomTextInput disabled={false} required={true} name="deceased_legal_name"*/}
                                        {/*                    className={"form-control"} id="deceased_legal_name-input"*/}
                                        {/*                    value={deceasedLegalName}*/}
                                        {/*                     onChange={(value) => {*/}
                                        {/*                         formIK.setFieldValue("deceased_legal_name", value);*/}
                                        {/*                    }}/>*/}
                                        {/*    {formIK.errors["deceased_legal_name"] && (*/}
                                        {/*        <div className="text-error">{formIK.errors["deceased_legal_name"]}</div>*/}
                                        {/*    )}*/}
                                        {/*</Form.Group>*/}
                                        <Form.Group key="alias_deceased_legal_name" className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label><strong>Deceased Aliases</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={false}
                                                             name="alias_deceased_legal_name"
                                                             className={"form-control"}
                                                             aka={true}
                                                             id="alias_deceased_legal_name-input"
                                                             value={formIK.values.alias_deceased_legal_name}
                                                             onChange={(value) => {
                                                                 formIK.setFieldValue("alias_deceased_legal_name", value);
                                                             }}/>
                                            {formIK.errors["alias_deceased_legal_name"] && (
                                                <div
                                                    className="text-error">{formIK.errors["alias_deceased_legal_name"]}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group key={formIK.errors["date_of_death"]} className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Date Of
                                                Death</strong></Form.Label>
                                            <CustomDatePicker className="form-control" name="date_of_death"
                                                              id="date_of_death-input"
                                                              value={formIK.values.date_of_death === "" ? formatUTCDate(new Date()) : formatUTCDate(formIK.values.date_of_death)}
                                                              format="MM-dd-y"
                                                              onChange={(value) => {
                                                                  if (formatUTCDate(value) === '1970-01-01') {
                                                                      formIK.setFieldValue("date_of_death", '');
                                                                  } else {
                                                                      formIK.setFieldValue("date_of_death", formatUTCDate(value))
                                                                  }
                                                              }} disabled={false}
                                                              required={true}/>
                                            {/*{formIK.errors["date_of_death"] && (*/}
                                            <div className="text-error">{formIK.errors["date_of_death"]}</div>
                                            {/*)}*/}
                                        </Form.Group>
                                        {/*(Examples: Pinellas County FL format = (22-012345-ES) which is compose from: Year(YY)-6_Digit_Sequence#-Court_Type(ES, GD). Pasco County FL uses this format: Year-CourtType-Seq#-PartyIdentifier-BranchLocation)*/}
                                        <Form.Group key={courtCaseError} className="col-12" controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label><strong>Court Case Number</strong></Form.Label>
                                            <CustomTextInput disabled={false} required={false} name="court_case_number"
                                                             className={"form-control"} id="court_case_number-input"
                                                             value={formIK.values.court_case_number}
                                                             onChange={(value) => {
                                                                 formIK.setFieldValue("court_case_number", value);
                                                             }}/>
                                            <div className="text-error">{formIK.errors["court_case_number"]}</div>
                                        </Form.Group>
                                        <Form.Group key="number_of_personal_representative" className="col-12"
                                                    controlId="formBasicEmail"
                                                    style={{display: "inline-grid", padding: "0px 15px 0px 0px"}}>
                                            <Form.Label className='required-label'><strong>Number Of Personal
                                                Representative</strong></Form.Label>
                                            <CustomNumberInput disabled={false} required={true}
                                                               name="number_of_personal_representative"
                                                               className={"form-control"}
                                                               min="1"
                                                               max="4"
                                                               id={"number_of_personal_representative-input"}
                                                               value={formIK.values.number_of_personal_representative}
                                                               onChange={(value) => { //changePersonalRepresentatives(parseInt(value));
                                                                   const dup_value = value;
                                                                   if (parseInt(value) === 0) {
                                                                       formIK.values.personal_representative = [];
                                                                   } else if (formIK.values.personal_representative.length > parseInt(value)) {
                                                                       formIK.values.personal_representative = [...formIK.values.personal_representative.slice(0, value)];
                                                                   } else {
                                                                       let inputs_obj = {};
                                                                       const tempLineItemInputs = lineItemInputs.map((lineItem) => {
                                                                           if (lineItem.name === 'is_us_citizen') {
                                                                               inputs_obj[lineItem.name] = "yes";
                                                                           } else {
                                                                               inputs_obj[lineItem.name] = "";
                                                                           }
                                                                       });
                                                                       if (formIK.values.personal_representative.length > 0) {
                                                                           value = value - formIK.values.personal_representative.length;
                                                                       }
                                                                       let tempLineItemInputList = [];
                                                                       for (value; value > 0; value--) {
                                                                           tempLineItemInputList.push(inputs_obj);
                                                                       }
                                                                       formIK.values.personal_representative = [...formIK.values.personal_representative, ...tempLineItemInputList];
                                                                   }
                                                                   formIK.setFieldValue("number_of_personal_representative", parseInt(dup_value));
                                                               }}/>
                                            {formIK.errors["number_of_personal_representative"] && (
                                                <div
                                                    className="text-error">{formIK.errors["number_of_personal_representative"]}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Row>
                    <Row>
                        <div>
                            <div style={{textAlign: "left", width: "50%", display: "inline-block"}}>
                                <button disabled={progress === 0}
                                        className="btn btn-primary">
                                    <ArrowLeft style={{display: "inline"}}/> Back
                                </button>
                            </div>
                            <div style={{textAlign: "right", width: "50%", display: "inline-block"}}>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    validate();
                                    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top
                                }}
                                        disabled={false} className="btn btn-primary">
                                    Next <ArrowRight style={{display: "inline"}}/>
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

BasicInformation.propTypes = {
    formIK: PropTypes.object.isRequired,
    changeStep: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};
