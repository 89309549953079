import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal, Button, Spinner, Row, Form, Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import CustomSelectInput from "../../../global_components/inputs/select-field";
import * as yup from "yup";
import {log} from "../../../utils/logger";
import {useSnackbar} from "notistack";
import Link from "@mui/material/Link";
import {useLocation, useNavigate} from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import {ConfirmationPopup} from "../../../utils/confirmation_popup";
import AddEditTeamMember from "./add_edit_team_member_popup";

export default function UserInsertPopup(props) {
    const navigator = useNavigate();
    const [loading, setLoading] = useState(false);
    
    const [isOpen, setIsOpen] = useState(false);
    const AttorneyMap = props.userData.map(user => ({
        id: user.id,
        isAttorney: user.position === "ATTORNEY" || user.position_value === "Attorney"
    }));
    console.log(AttorneyMap);
   
    const handleClick = (id) => {
        const user = AttorneyMap.find(user => user.id === id);
        if (user && user.isAttorney) {
          setIsOpen(true);
        }
    }


    const [locationList, setLocationList] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const super_user = useSelector((state) => state.auth.user.user_role_name) === "SuperAdmin";
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const [showAddEditTeamMemberPopup, setShowAddEditTeamMemberPopup] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = yup.object().shape(
        props.isWebAdministrator === true ? {
            full_name: yup.number().required("User Name is required.")
        } : (props.showLocation ? {
            full_name: yup.number().required("User Name is required."),
            location: yup.number().required("Location Name is required."),
            is_active: yup.bool(),
            is_default: yup.bool(),
        } : {
            full_name: yup.number().required("User Name is required."),
            is_active: yup.bool(),
            is_default: yup.bool(),
        }));
    const getInitialValues = () => {
        return props.isWebAdministrator === true ? {full_name: ''} : (props.showLocation ? {
            full_name: '',
            location: '',
            is_active: true,
            is_default: false
        } : {
            full_name: '',
            is_active: true,
            is_default: false
        })
    }
    const getUserOptions = () => {
        let userOptions = [];
        log("userData,", props.userData);
        props.userData?.map((user) => userOptions.push({label: `${user.user_full_name}`, value: user.id}))
        return userOptions;
    }
    const getLocationOptions = () => {
        let locationOptions = [];
        props.locationList?.filter((loc) => loc.law_firm == props.editID).map((location) => locationOptions.push({
            label: `${location.name}`,
            value: location.id
        }))
        return locationOptions;
    }

    const formik = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            log("formik.values,", formik.values)
            props.callback('insert', values);
            props.closePopup(false);
            setLoading(false);
        },
        validationSchema: schema
    });
    const destroyLawFirm = async () => {
        await setOpenConfirmationPopup(false);
        props.closePopup(false);
    }
    const handleConfirmationPopup = async () => {
        await setOpenConfirmationPopup(false);
        await destroyLawFirm();
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Existing User to {props.title}
                    </Modal.Title>
                    <Button variant={"warning"} onClick={(e) => {
                        e.preventDefault();
                        setOpenConfirmationPopup(true);
                    }}> <CloseIcon/></Button>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col sm={6} className={"pe-2"}>
                                <Form.Group key={'logo1'} controlId="validationFormik011" className="mb-3">
                                    <Form.Label className={'required-label'}><strong>User</strong></Form.Label>
                                    <CustomSelectInput
                                        disabled={false}
                                        name="full_name"
                                        onChange={
                                            (value) => {
                                                log("props.userID,",props.userID);
                                                handleClick(value);

                                                formik.values.full_name =  value;
                                                if(getLocationOptions().length===1) {
                                                    formik.values.location = getLocationOptions()[0].value;
                                                }
                                            }

                                        }
                                        options={getUserOptions()}
                                        value={
                                            props.userID !== undefined && props.userID !== 0 && props.userData !== undefined && props.userData.length > 0 ? props.userData?.filter((user) => user?.id === props.userID)[0]?.id : []

                                        }
                                    />
                                    {formik.errors['full_name'] && (
                                        <div className="text-error">{formik.errors['full_name']}</div>
                                    )}
                                </Form.Group>
                                {
                                    (!props.isWebAdministrator) && (<>
                                            {props.showLocation && (
                                                <Form.Group key={'location'} controlId="validationFormik022"
                                                            className="mb-3">
                                                    <Form.Label
                                                        className={'required-label'}><strong>Location</strong></Form.Label>
                                                    <CustomSelectInput
                                                        disabled={false}
                                                        name="location"
                                                        onChange={
                                                            (value) => {
                                                                formik.setFieldValue('location', value);

                                                            }
                                                        }
                                                        options={getLocationOptions()}
                                                        value={
                                                            props.userID !== undefined && props.userID !== 0 ? locationList?.filter((user) => user.id === props.userID)[0].id :getLocationOptions().length===1 ? getLocationOptions()[0].value: []

                                                        }
                                                    />
                                                    {formik.errors['location'] && (
                                                        <div className="text-error">{formik.errors['location']}</div>
                                                    )}
                                                </Form.Group>)}
                                            {props.showBoolean &&
                                                <>
                                                    <Form.Group key={'is_active'} controlId="validationFormik033"
                                                                className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            type="switch"
                                                            id="is_active"
                                                            label="Is Active?"
                                                            onChange={formik.handleChange}
                                                            checked={formik.values.is_active === true}
                                                        />
                                                    </Form.Group>
                                                    {isOpen && <Form.Group key={'is_default'} controlId="validationFormik044"
                                                                className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            type="switch"
                                                            id="is_default"
                                                            label="Is Default?"
                                                            onChange={formik.handleChange}
                                                            checked={formik.values.is_default === true}
                                                        />
                                                    </Form.Group>
                                                    }
                                                </>}
                                        </>
                                    )
                                }
                                <div style={{textAlign: "right"}}>{
                                    !loading && (
                                        <Button variant="primary" onClick={() => {
                                            formik.submitForm();
                                        }}>Complete Assignment</Button>
                                    )
                                }
                                    {
                                        loading && (
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                        )
                                    }
                                </div>
                            </Col>
                            {(super_user) && (<><Col sm={6} className={"ps-2"}
                                                     style={{
                                                         verticalAlign: "middle",
                                                         textAlign: "center",
                                                         borderLeft: "2px solid black"
                                                     }}>
                                <Row>
                                    <Col sm={1}></Col>
                                    <Col sm={11}><label style={{textAlign: "center"}}><b>Create New
                                        User</b></label></Col>
                                </Row>
                                <Row style={{verticalAlign: "middle", paddingTop: "5%", textAlign: "center"}}>
                                    <Col sm={1}>
                                        <label><b>OR</b></label>
                                    </Col>
                                    <Col sm={11}>
                                        <Form.Group key={'submit_button'} controlId="validationFormik16">
                                            <Button variant="primary" onClick={() => {
                                                setShowAddEditTeamMemberPopup(true);
                                            }}>Create New User</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Col>
                            </>)
                            }
                            {/*<Button variant="primary" onClick={() => {
                            navigator("/user_management/0", {state: {history: `/law_firm/${props.editID}`}});
                        }}>
                            Create a new User instead
                        </Button>*/}
                        </Row>
                    </Form>


                </Modal.Body>

            </Modal>

            {
                showAddEditTeamMemberPopup && <AddEditTeamMember show={true} lawFirmEditID={props.editID}  closePopup={setShowAddEditTeamMemberPopup} ></AddEditTeamMember>
            }
            {
                openConfirmationPopup &&
                <ConfirmationPopup show={openConfirmationPopup}
                                   setOpenConfirmationPopup={setOpenConfirmationPopup}
                                   title="Do you want to cancel all inputs?"
                                   handleClose={handleConfirmationPopup}
                />
            }


        </>
    )
}

UserInsertPopup.propTypes = {
    law_firm_contact_id: PropTypes.number,
    closePopup:
    PropTypes.func.isRequired,
    callback:
    PropTypes.func.isRequired,

}
