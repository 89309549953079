import React, {useEffect, useState} from 'react';
import "react-step-progress-bar/styles.css";
import "../index.css"
import {ProgressBar, Step} from "react-step-progress-bar";
import CusNavBar from "./navbar";
import {Container} from "@mui/material";
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useFormik} from "formik";
import {API_URL} from "../constants";
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import CusAlert from "./alert";
import BasicInformation from "../components/vermost/basic_information";
import {getProcesses, getProcessInputs} from "../services/process";
import {
    addBondProcessDetails,
    addBondProcessInputs,
    addLawFirmData,
    addLawSchool,
    addVermostLawFirmData, addVermostUserData
} from "../store/bond";
import GeneralInformation from "../components/vermost/general_information";
import PersonalRepresentatives from "../components/vermost/personal_representatives";
import AttorneyInformation from "../components/vermost/attorney_information";
import DocumentUpload from "../components/vermost/document_upload";
import PersonalInformation from "../components/vermost/personal_information";
import {getLawFirmData} from "../services/bond";
import {getVermostLawFirms, getVermostUsersPagination} from "../services/company_management";
import {addUsers} from "../store/company_management";

export default function BondCreateSteps(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFail, setShowFail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formReset, setFormReset] = useState(false);
    const step = 20;
    const [progress, setProgress] = useState(0);
    const processInput = useSelector((state) => state.bond.bond_process_input);
    const lawFirms = useSelector((state) => state.bond.law_firms);
    // const lawFirmAddresses = useSelector((state) => state.bond.law_firm_addresses);
    // const lawFirmContacts = useSelector((state) => state.bond.law_firm_contacts);
    // const lawFirmUsers = useSelector((state) => state.bond.law_firm_users);
    const user = useSelector((state) => state.auth.user);
    const lawSchoolsInStore = useSelector((state) => state.bond.law_schools);
    const [showFailMessage, setShowFailMessage] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        document.title = "Request Bond"
    });
    useEffect(async () => {
        if (lawSchoolsInStore.length === 0) {
            fetch(`${API_URL}/bond/list_all_law_schools/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async (res) => {
                const law_school_data = await res.json();
                dispatch(addLawSchool(law_school_data));
            }).catch((err) => {
                console.log(err);
            });
        }
        if (lawFirms.length === 0) {
            // getLawFirmData().then(async (res) => {
            //     dispatch(addLawFirmData(res));
            // }).catch((err) => {
            //     console.log(err);
            // });

            let resp = await getVermostLawFirms('', '', '', '', true);
            if (resp.status === 200) {
                const result = await resp.json();
                dispatch(addVermostLawFirmData(result.data));
                console.log("lawFirms", lawFirms)
            } else {
                console.log("err");
            }
        }
        // if (lawFirmUsers.length === 0) {
        //     let user_resp = await getVermostUsersPagination('', '', '', '');
        //     if (user_resp.status === 200) {
        //         const result = await user_resp.json();
        //         dispatch(addVermostUserData(result.data));
        //         console.log("lawFirmUsers", lawFirmUsers)
        //     } else {
        //         console.log("err");
        //     }
        // }

        await getLawFirmData().then(async (res) => {
            dispatch(addLawFirmData(res));
        }).catch((err) => {
            console.log(err);
        });

        if (processInput.length === 0) {
            getProcesses('Request Bond').then((response) => {
                dispatch(addBondProcessDetails(response));
                getProcessInputs(response[0].id).then(async (res) => {
                    dispatch(addBondProcessInputs(res));
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(()=>{}, []);

    

    const getInitialValues = () => {
        let initialValues = {}
        initialValues['bond_type'] = "";
        initialValues['bond_sub_category'] = "";
        initialValues['deceased_legal_first_name'] = "";
        initialValues['deceased_legal_middle_name'] = "";
        initialValues['deceased_legal_last_name'] = "";
        initialValues['deceased_legal_suffix'] = "";
        //initialValues['deceased_legal_name'] = "";
        initialValues['alias_deceased_legal_name'] = "";
        initialValues['date_of_death'] = "";
        initialValues['court_case_number'] = "";
        initialValues['has_judge_order'] = "";
        initialValues['state'] = "Florida";
        initialValues['county'] = "";
        initialValues['bond_amount'] = "";
        initialValues['number_of_personal_representative'] = "";
        initialValues['premium'] = "";
        initialValues['estimated_estate_assets'] = "";
        initialValues['estate_has_ongoing_business'] = "no";
        initialValues['type_of_business_in_operation'] = "";
        initialValues['personal_representative_indebited_to_estate'] = "no";
        initialValues['decedent_execute_last_will_and_testament'] = "";
        initialValues['applicant_replace_any_previous_fiduciary_in_this_estate'] = "no";
        initialValues['previous_fiduciaries'] = "";
        initialValues['declined_by_another'] = "no";
        initialValues['ever_bankrupt'] = "no";
        initialValues['replace_previous_bond'] = "no";
        initialValues['lawsuits_judgment_liens'] = "no";
        initialValues['any_disputes'] = "no";
        initialValues['is_required_on_demand'] = "no";
        initialValues['e_signed_indemnity'] = "no";
        initialValues['interested_parties'] = "";
        initialValues['law_firm'] = lawFirms.length === 1 ? lawFirms[0].value : "";
        // initialValues['law_firm_address'] = lawFirms.length === 1 && lawFirmAddresses[lawFirms[0].value].length === 1 ? lawFirmAddresses[lawFirms[0].value][0].value : "";
        initialValues['law_firm_location'] = "";
        // initialValues['contact'] = lawFirms.length === 1 && lawFirmContacts[lawFirms[0].value].length === 1 ? lawFirmContacts[lawFirms[0].value][0].value : "";
        initialValues['contact'] = "";
        initialValues['copy_of_last_will_and_testament'] = "";
        initialValues['copy_of_court_order'] = "";
        initialValues['other_document'] = "";
        initialValues['user_comment'] = "";
        initialValues['user_first_name'] = user.first_name;
        initialValues['user_last_name'] = user.last_name;
        initialValues['user_email'] = user.email;
        initialValues['phone_number'] = user.phone_number;
        initialValues['personal_representative'] = [];
        return initialValues
    }

    const getValidation = () => {
        let validation = {}
        validation['bond_type'] = Yup.string();
        validation['bond_sub_category'] = Yup.string();
        validation['deceased_legal_first_name'] = Yup.string();
        validation['deceased_legal_middle_name'] = Yup.string();
        validation['deceased_legal_last_name'] = Yup.string();
        validation['deceased_legal_suffix'] = Yup.string();
        //validation['deceased_legal_name'] = Yup.string();
        validation['alias_deceased_legal_name'] = Yup.string();
        validation['date_of_death'] = Yup.date();
        validation['court_case_number'] = Yup.string();
        validation['has_judge_order'] = Yup.string();
        validation['county'] = Yup.string();
        validation['state'] = Yup.string();
        validation['bond_amount'] = Yup.number().typeError('Bond amount must be greater than 0.');
        validation['premium'] = Yup.number();
        validation['number_of_personal_representative'] = Yup.number().min(1, "Number of Personal Representative should be between 1-4.").typeError('Number of Personal Representative should be between 1-4.');
        validation['estimated_estate_assets'] = Yup.number();
        validation['estate_has_ongoing_business'] = Yup.string().required(`Estate Has Ongoing Business is required`);
        validation['type_of_business_in_operation'] = Yup.string();
        validation['personal_representative_indebited_to_estate'] = Yup.string().required('Personal Representative Indebted To Estate is required.');
        validation['decedent_execute_last_will_and_testament'] = Yup.string().required(`Decedent Execute Last Will And Testament is required.`);
        validation['applicant_replace_any_previous_fiduciary_in_this_estate'] = Yup.string().required(`Applicant Replace Any Previous Fiduciary In This Estate is required.`);
        validation['previous_fiduciaries'] = Yup.string();
        validation['declined_by_another'] = Yup.string().required(`Declined by another court is required`);
        validation['ever_bankrupt'] = Yup.string().required(`Bankruptcy status is required`);
        validation['replace_previous_bond'] = Yup.string().required(`Bond replacing status is required`);
        validation['lawsuits_judgment_liens'] = Yup.string().required(`Lawsuits judgment liens is required`);
        validation['any_disputes'] = Yup.string().required(`Dispute with other court status is required`);
        validation['is_required_on_demand'] = Yup.string().required('Is Required On Demand is mandatory field.');
        validation['interested_parties'] = Yup.string();
        validation['law_firm'] = Yup.string();
        validation['law_firm_location'] = Yup.string();
        validation['contact'] = Yup.string();
        validation['copy_of_court_order'] = Yup.mixed();
        validation['copy_of_last_will_and_testament'] = Yup.mixed();
        validation['other_document'] = Yup.mixed();
        validation['user_comment'] = Yup.string();
        validation['user_first_name'] = Yup.string();
        validation['user_last_name'] = Yup.string();
        validation['user_email'] = Yup.string();
        validation['phone_number'] = Yup.string().typeError('Phone number is required.');//.matches(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/, "Enter a valid phone number i.e. +15045951378").required('Phone number is required.');
        validation['personal_representative'] = Yup.array().of(
            Yup.object().shape({
                name: Yup.string(),
                address: Yup.string(),
                city: Yup.string(),//.required(`City/Town for Personal Rep is required`),
                state: Yup.string(),//.required(`State/Province/Region for Personal Rep is required`),
                zip_code: Yup.string(),
                is_us_citizen: Yup.string(),
                citizenship: Yup.string(),
                other_country: Yup.string(),
                ssn: Yup.string(),
                occupation: Yup.string(),
                email: Yup.string()
            })
        ).required("Number of Personal Representative should be between 1-4.").min(1, "Number of Personal Representative should be between 1-4.");
        return validation;
    }

    const formIk = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            setLoading(true);
            setSubmitting(true);
            let formData = new FormData();
            let data = JSON.parse(JSON.stringify(values));
            let hasError = false;
            Object.keys(data).forEach((key) => {
                if (key === 'personal_representative') {
                    data['personal_representative'].forEach((pr, index) => {
                        Object.keys(pr).forEach((prKey) => {
                            if (data['personal_representative'][index][prKey] === 'yes') {
                                data['personal_representative'][index][prKey] = true;
                            } else if (data['personal_representative'][index][prKey] === 'no') {
                                data['personal_representative'][index][prKey] = false;
                            }
                        });
                    });
                } else {
                    if (data[key] === 'yes') {
                        data[key] = true;
                    } else if (data[key] === 'no') {
                        data[key] = false;
                    }
                }
            });
            if (data['estate_has_ongoing_business'] === true) {
                let error = false;
                if (data['type_of_business_in_operation'] === "") {
                    formIk.errors['type_of_business_in_operation'] = 'This field is required.';
                    error = true;
                }
                // if(data['copy_of_court_order'] === ""){
                //     formIk.errors['copy_of_court_order'] = 'Please provide court order.';
                //     error = true;
                // }
                if (error === true) {
                    setLoading(false);
                    return
                }
            }
            if (data['bond_amount'] < 0) {
                formIk.errors['bond_amount'] = 'Bond Amount must be greater than 0.';
                setLoading(false);
                return
            }
            if (data['decedent_execute_last_will_and_testament'] === true && data['bond_amount'] >= 1000000) {
                if (data['copy_of_last_will_and_testament'] === "") {
                    formIk.errors['copy_of_last_will_and_testament'] = 'Please provide copy of last will and testament.';
                    setLoading(false);
                    return
                }
            }
            // if(data['replace_previous_bond'] === true){
            //     if(data['replacing_explanation'] === ""){
            //         formIk.errors['replacing_explanation'] = 'Please explain replacing reason.';
            //         setLoading(false);
            //         return
            //     }
            // }
            if (data['is_required_on_demand'] === true) {
                if (data['interested_parties'] === "") {
                    formIk.errors['interested_parties'] = 'Please list and briefly describe each party.';
                    setLoading(false);
                    return
                }
            }
            if (data['county'] === 'Pinellas') {
                if (data['court_case_number'] !== "") {
                    const court_case_number_regex = new RegExp("^\\d{2}-\\d{6}-(ES|GD)$");
                    if (!court_case_number_regex.test(data['court_case_number'])) {
                        formIk.errors['court_case_number'] = 'Court Case Number for pinellas must match Year(YY) – 6_Digit_Sequence# – Court_Type (ES, GD).';
                        setLoading(false);
                        return
                    }
                }
                if (data['bond_type'] === 'Probate' && data['bond_amount'] < 18000) {
                    formIk.errors['bond_amount'] = 'The minimum expected value to be entered is $18000.';
                    setLoading(false);
                    return false;
                }
            } else if (data['county'] === 'Pasco') {
                if (data['has_judge_order'] === "") {
                    formIk.errors['has_judge_order'] = 'Has Judge Stearn’s issued his order requiring bond yet? for Pasco is required.';
                    setLoading(false);
                    return
                }
            }
            data['personal_representative'].forEach((pr, index) => {
                if (pr['is_us_citizen'] === false) {
                    if (pr['citizenship'] === "" || !pr.hasOwnProperty('citizenship')) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['citizenship'] = 'This field is required.';
                        setLoading(false);
                        hasError = true;
                        return;
                    }
                    if (pr['citizenship'] === "Others" && (pr['other_country'] === "" || !pr.hasOwnProperty('other_country'))) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['other_country'] = 'This field is required.';
                        setLoading(false);
                        hasError = true;
                        return;
                    }
                } else if (pr['is_us_citizen'] === true && data['bond_amount'] >= 250000) {
                    var re = new RegExp("^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$");
                    if (pr['ssn'] === "" || !pr.hasOwnProperty('ssn')) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['ssn'] = 'This field is required.';
                        setLoading(false);
                        hasError = true;
                        return;
                    }
                    // else if (!re.test(pr['ssn'])) {
                    //     formIk.errors['personal_representative'] = [];
                    //     formIk.errors['personal_representative'][index] = {};
                    //     formIk.errors['personal_representative'][index]['ssn'] = 'SSN must match this format 123-12-1234.';
                    //     setLoading(false);
                    //     hasError = true;
                    //     return;
                    // }
                }
                if (pr['is_us_citizen'] === true) {
                    if (pr['state'] === "") {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['state'] = 'State/Province/Region for Personal Rep is required.';
                        setLoading(false);
                        hasError = true;
                        return;
                    }
                    if (pr['city'] === "") {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['city'] = 'City/Town for Personal Rep is required.';
                        setLoading(false);
                        hasError = true;
                        return;
                    }
                    if (pr['zip_code'] === "" || pr['zip_code'] === undefined) {
                        formIk.errors['personal_representative'] = [];
                        formIk.errors['personal_representative'][index] = {};
                        formIk.errors['personal_representative'][index]['zip_code'] = 'Zip/Postal Code of Personal Rep is required.';
                        hasError = true;
                        return;
                    }
                }
                // if(data['bond_amount']>50000){
                //     if(pr['occupation'] === ""){
                //         formIk.errors['personal_representative'] = [];
                //         formIk.errors['personal_representative'][index] = {};
                //         formIk.errors['personal_representative'][index]['occupation'] = 'Occupation is required.';
                //         setLoading(false);
                //         hasError = true;
                //         return;
                //     }
                // }
            });
            if (hasError === true) {
                return;
            }
            if (values['copy_of_last_will_and_testament'] === "" || values['copy_of_last_will_and_testament'] === null) {
                delete values.copy_of_last_will_and_testament;
            }
            if (values['other_document'] === "" || values['other_document'] === null) {
                delete values.other_document;
            }
            if (values['copy_of_court_order'] === "" || values['copy_of_court_order'] === null) {
                delete values.copy_of_court_order;
            }
            if (values['estimated_estate_assets'] === "" || isNaN(values['estimated_estate_assets']) || values['estimated_estate_assets'] === null) {
                delete values.estimated_estate_assets;
            }
            Object.keys(values).forEach((key) => {
                if (key === 'personal_representative') {
                    formData.append('personal_representatives', JSON.stringify(data[key]));
                } else if (key === 'copy_of_last_will_and_testament' || key === 'copy_of_court_order' || key === 'other_document') {
                    formData.append(key, values[key])
                } else {
                    formData.append(key, data[key]);
                }
            });
            formData.append('user_comment', values.user_comment);
            formData.append('user', user.user_id);
            formData.append('deceased_legal_name', ''.concat(values.deceased_legal_first_name, ' ', values.deceased_legal_middle_name, ' ', values.deceased_legal_last_name, ' ', values.deceased_legal_suffix));


            await fetch(
                API_URL + '/bond/create_bond/',
                {
                    // mode: 'no-cors',
                    method: "POST",
                    body: formData,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('userdata'),
                        // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
                    },
                }
            ).then(async (res) => {
                if (res.status === 201) {
                    setShowSuccess(true);
                    resetForm({values: ''});
                    setFormReset(!formReset);
                    setProgress(0);
                    formIk.errors = {};
                    setShowSuccess(true);
                    // After 5 seconds, redirect to dashboard.
                    setTimeout(() => {
                        navigate('/')
                    }, 5000)
                } else if (res.status === 226) {
                    setShowFail(true);
                    setShowFailMessage('No bond number is available.');

                } else {
                    console.log("async error: ", res.status);
                    setShowFail(true);
                    setShowFailMessage('Some internal issue caused bond creation failure.');
                }
            }).catch((error) => {
                console.log("async error: ", error);
                setShowFail(true);
                setShowFailMessage('Some internal issue caused bond creation failure.');
            })
            setLoading(false);
            setSubmitting(false);
        },
        validationSchema: Yup.object(getValidation())
    });


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Creating Bond...'
        >
            {
                processInput.length > 0 && (
                    <div>
                        <CusNavBar/>
                        {
                            showSuccess && <CusAlert alertType='success'
                                                     alertMessage="Bond created and sent for approval. After 5 seconds, the home page will open."
                                                     alertClose={setShowSuccess}/>
                        }
                        {
                            showFail &&
                            <CusAlert alertType='danger' alertMessage={showFailMessage} alertClose={setShowFail}/>
                        }
                        <Container style={{marginTop: "6%"}}>
                            <Col>
                                <Row>
                                    <ProgressBar percent={progress}
                                                 filledBackground="linear-gradient(to right, #e3f2fd, #7879FF)">

                                        <Step transition="scale">
                                            {({accomplished, index}) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}<span
                                                    className="accomplished-span"><strong>Basic Information</strong></span>
                                                </div>
                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({accomplished, index}) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}<span className="accomplished-span"><strong>General Information</strong></span>
                                                </div>
                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({accomplished, index}) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}<span className="accomplished-span"><strong>Personal Representatives</strong></span>
                                                </div>
                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({accomplished, index}) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}<span className="accomplished-span"><strong>Attorney Information</strong></span>
                                                </div>
                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({accomplished, index}) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}<span className="accomplished-span"><strong>Requestor Information</strong></span>
                                                </div>
                                            )}
                                        </Step>
                                        <Step transition="scale">
                                            {({accomplished, index}) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}<span
                                                    className="accomplished-span"><strong>Document Upload</strong></span>
                                                </div>
                                            )}
                                        </Step>
                                    </ProgressBar>
                                </Row>
                                <Row style={{marginTop: "2%"}}>
                                    <Form onSubmit={formIk.handleSubmit}>
                                        {
                                            (() => {
                                                switch (progress) {
                                                    case 0:
                                                        return (
                                                            <BasicInformation formIK={formIk} changeStep={setProgress}
                                                                              step={step} progress={progress}/>
                                                        )
                                                    case 20:
                                                        return (
                                                            <GeneralInformation formIK={formIk} changeStep={setProgress}
                                                                                step={step} progress={progress}/>
                                                        )
                                                    case 40:
                                                        return (
                                                            <PersonalRepresentatives formIK={formIk}
                                                                                     changeStep={setProgress}
                                                                                     step={step} progress={progress}/>
                                                        )
                                                    case 60:
                                                        return (
                                                            <AttorneyInformation formIK={formIk}
                                                                                 changeStep={setProgress} step={step}
                                                                                 progress={progress} loading={loading}/>
                                                        )
                                                    case 80:
                                                        return (
                                                            <PersonalInformation formIK={formIk} changeStep={setProgress}
                                                                                 step={step} progress={progress}/>
                                                        )
                                                    case 100:
                                                        return (
                                                            <DocumentUpload formIK={formIk} changeStep={setProgress}
                                                                            step={step}
                                                                            progress={progress}/>
                                                        )
                                                }
                                            })()
                                        }
                                    </Form>
                                </Row>
                            </Col>
                        </Container>
                    </div>
                )
            }
        </LoadingOverlay>
    )
}
