import React, { useState } from 'react';
import PropTypes from "prop-types";
import Select from "react-select";

export default function CustomMultiSelectInput(props) {
    const [value, onChange] = useState(props.value);
    const styles = {
        menuList: styles => {
            return {
                ...styles,
                maxHeight: 175
            };
        }
    }

    return (
        <Select
            name={props.name} isDisabled={props.disabled}
            onChange={async (opt, e) => {
                console.log(opt.map((val)=>val.value))
                onChange(opt.map((val)=>val.value));
                props.onChange(opt.map((val)=>val.value));
            }}
            isMulti
            options={props.options}
            defaultValue={props.options.length > 0 !== undefined ? props.options.filter((option)=>props.value.includes(option.value)) : []}
            styles={styles}
            className={"basic-multi-select "+props.className}
            key={props.value.length}
        />
    );
}

CustomMultiSelectInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
};
