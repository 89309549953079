import CusNavBar from "../../../../../global_components/navbar";
import {Card, CardContent, Grid} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import LawFirmMain from "../../../vermost_components/law_firm_main";
import UserList from "../../../vermost_components/user_list";
import LocationList from "../../../vermost_components/locations";
import LawFirmHistory from "../../../vermost_components/law_firm_history";
import {formatUTCDateTime} from "../../../../../services/helper";
import LawFirmBondHistory from "../../../vermost_components/law_firm_bond_history";
import Footer from "../../../../../global_components/footer";
import React, {useCallback, useEffect, useState} from "react";
import LawFirmLocationMain from "../../../vermost_components/law_firm_location_main";
import {useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import {log} from "../../../../../utils/logger";
import {
    addLocationUser,
    addVermostLawFirmLocation,
    editVermostLawFirmLocation,
    getVermostLocationUsersPagination
} from "../../../../../services/company_management";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {API_URL} from "../../../../../constants";
import {useSnackbar} from "notistack";
import {addLawFirmProfessionalGuardians, removeLawFirmProfessionalGuardians} from "../../../../../store/company_management";
import GoBack from "../../../../../utils/go_back_button";

const ActionType = {
    edit: 'edit',
    insert: 'insert',
    delete: 'delete',
    download: 'download',
    refresh: 'refresh'
}
const LawFirmLocation = () => {
    const location = useLocation();
    const routeParams = useParams();
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);

    const [attorneyList, setAttorneyList] = useState([]);
    const [teamMemberList, setTeamMemberList] = useState([]);
    const [loadingLawFirmLocation, setLoadingLawFirmLocation] = useState(true);
    const [showAttorneyInsertPopup, setShowAttorneyInsertPopup] = useState(false);
    const [showTeamMemberInsertPopup, setShowTeamMemberInsertPopup] = useState(false);
    const [loadingAttorney, setLoadingAttorney] = useState(true);
    const [loadingAttorneyTable, setLoadingAttorneyTable] = useState(true);
    const [rowCountAttorney, setRowCountAttorney] = useState(0);
    const [lawFirmLocation, setLawFirmLocation] = useState({});
    const {enqueueSnackbar} = useSnackbar();

    // Datagrid Pagination
    if (!localStorage.getItem("law_firm_location_attorney_pagesize")) {
        localStorage.setItem("law_firm_location_attorney_pagesize", 10);
    }
    if (!localStorage.getItem("law_firm_location_team_member_pagesize")) {
        localStorage.setItem("law_firm_location_team_member_pagesize", 10);
    }

    const [controllerAttorney, setControllerAttorney] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("law_firm_location_attorney_pagesize"))
    });
    const gatedSetControllerAttorney = useCallback((value) => {
        setControllerAttorney(value);
    }, []);
    const [loadingTeamMember, setLoadingTeamMember] = useState(true);
    const [loadingTeamMemberTable, setLoadingTeamMemberTable] = useState(true);
    const [rowCountTeamMember, setRowCountTeamMember] = useState(0);
    const [controllerTeamMember, setControllerTeamMember] = useState({
        page: 0, pageSize: parseInt(localStorage.getItem("law_firm_location_team_member_pagesize"))
    });
    const gatedSetControllerTeamMember = useCallback((value) => {
        setControllerTeamMember(value);
    }, []);
    const [queryOptionsSortAttorney, setQueryOptionsSortAttorney] = useState({});
    const handleSortModelChangeAttorney = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortAttorney({sortModel: [...sortModel]});
    }, []);
    const [prevSortAttorney, setPrevSortAttorney] = useState("");
    const [queryOptionsSortTeamMember, setQueryOptionsSortTeamMember] = useState({});
    const handleSortModelChangeTeamMember = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortTeamMember({sortModel: [...sortModel]});
    }, []);
    const [queryOptionsAttorney, setQueryOptionsAttorney] = useState({});
    const onFilterChangeAttorney = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptionsAttorney({filterModel: {...filterModel.items}}), 1000);
    }, []);
    const [prevFilterAttorney, setPrevFilterAttorney] = useState("");
    const [queryOptionsTeamMember, setQueryOptionsTeamMember] = useState({});
    const onFilterChangeTeamMember = useCallback((filterModel: GridFilterModel) => {
        setTimeout(() => setQueryOptionsTeamMember({filterModel: {...filterModel.items}}), 1000);
    }, []);
    const [prevSortTeamMember, setPrevSortTeamMember] = useState("");


    const getLawFirmLocation = async () => {
        const url = `/bond/get_law_firm_location/${routeParams.id}`
        try {
            const token = localStorage.getItem('userdata');
            const response = await fetch(`${API_URL}${url}`, {
                method: "GET", headers: {
                    'Authorization': 'Bearer ' + token, "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                const result = await response.json();
                lawFirmLocation.name = result.name
                lawFirmLocation.customer_number = result.customer_number
                lawFirmLocation.phone = result.phone
                lawFirmLocation.fax = result.fax
                lawFirmLocation.address = result.address
                lawFirmLocation.city = result.city
                lawFirmLocation.county = result.county
                lawFirmLocation.country = result.country
                lawFirmLocation.state = result.state
                lawFirmLocation.zip_code = result.zip_code
                lawFirmLocation.use_firms_accounting_contact = result.use_firms_accounting_contact
                lawFirmLocation.accounting_contact_full_name = result.accounting_contact_full_name
                lawFirmLocation.accounting_contact_email = result.accounting_contact_email
                lawFirmLocation.accounting_contact_phone = result.accounting_contact_phone
                lawFirmLocation.is_active = result.is_active
                lawFirmLocation.updated_at = result.updated_at
                setLawFirmLocation({...lawFirmLocation, lawFirmLocation});
                log("lawFirmLocation,", lawFirmLocation);
            } else {
                const result = await response.json();
                enqueueSnackbar(result, {variant: "error"});
            }
        } catch (error) {
            log(error);
            enqueueSnackbar('An error occured while getting location list.', {variant: "error"});
        }
    }

    const getAttorneyUsers = async (id, type, is_refresh) => {
        let filter = ""
        if (queryOptionsAttorney.filterModel) {
            filter = queryOptionsAttorney.filterModel[0].field + "-" + queryOptionsAttorney.filterModel[0].operator + "-" + queryOptionsAttorney.filterModel[0].value
        }
        let sort = ""
        if (queryOptionsSortAttorney.sortModel) {
            if (queryOptionsSortAttorney.sortModel.length > 0) {
                if (prevSortAttorney) {
                    sort = prevSortAttorney
                }
                let sortModel = queryOptionsSortAttorney.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortAttorney !== sort) {
                    setPrevSortAttorney(sort)
                } else {
                    if (queryOptionsAttorney.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        let response = await getVermostLocationUsersPagination(id, type, controllerAttorney.page, controllerAttorney.pageSize, filter, sort);
        if (response.status === 200) {
            const result = await response.json();
            setAttorneyList(result.data);
            setRowCountAttorney(result.total);
            if (is_refresh)
                enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }

    const getTeamMembers = async (id, type, is_refresh) => {
        let filter = ""
        if (queryOptionsTeamMember.filterModel) {
            filter = queryOptionsTeamMember.filterModel[0].field + "-" + queryOptionsTeamMember.filterModel[0].operator + "-" + queryOptionsTeamMember.filterModel[0].value
        }
        let sort = ""
        if (queryOptionsSortTeamMember.sortModel) {
            if (queryOptionsSortTeamMember.sortModel.length > 0) {
                if (prevSortTeamMember) {
                    sort = prevSortTeamMember
                }
                let sortModel = queryOptionsSortTeamMember.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortTeamMember !== sort) {
                    setPrevSortTeamMember(sort)
                } else {
                    if (queryOptionsTeamMember.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        let response = await getVermostLocationUsersPagination(id, type, controllerTeamMember.page, controllerTeamMember.pageSize, filter, sort);
        if (response.status === 200) {
            const result = await response.json();
            setRowCountTeamMember(result.total)
            setTeamMemberList(result.data)
            if (is_refresh)
                enqueueSnackbar("The table refreshed successfully.", {variant: "success"});
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
        }
    }

    const onCreateCallback = async (type, values) => {
        try {
            if (ActionType.edit === type) {
                log("val data,", values);
                let response = await editVermostLawFirmLocation(routeParams.id, values);
                if (response.status === 200) {
                    setLoadingLawFirmLocation(true);
                    setLawFirmLocation({});
                    await getLawFirmLocation();
                    enqueueSnackbar('Law firm location edited successfully.', {variant: "success"});
                    setLoadingLawFirmLocation(false);
                } else {
                    const result = await response.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
            } else if (ActionType.insert === type) {
                log("val data,", values);
                let res = await addVermostLawFirmLocation(values, routeParams.lawId);
                log("res,", res);
                if (res.status === 200) {
                    let new_location = await res.json()
                    log("new_location,", new_location);
                    enqueueSnackbar('Law firm location added successfully.', {variant: "success"});
                    navigate(`/law_firm_location/${new_location.id}/${new_location.law_firm}`);
                    window.location.reload(true);
                } else {
                    const result = await res.json();
                    enqueueSnackbar(result, {variant: "error"});
                }
            }
        } catch (error) {
            log(error);
        }

    }

    const onCallbackAttorney = async (actionType, obj) => {
        switch (actionType) {
            case ActionType.insert:
                setShowAttorneyInsertPopup(true);
                break;
            case ActionType.refresh:
                await refreshAttorneyTable();
                break;
            case ActionType.download:
                log("download");
                break;
        }
    }
    const onActionCallbackAttorney = async (actionType, newValues, id) => {
        log("Attorney type,", actionType);
        let response;
        switch (actionType) {
            case ActionType.insert:
                log("insert, newValues", newValues);
                response = await addLocationUser(routeParams.id, newValues);
                if (response.status === 200) {
                    await refreshAttorneyTable();
                }
                setShowAttorneyInsertPopup(false);
                break;
            case ActionType.download:
                log("download");
                break;
        }
    }

    const onCallbackTeamMember = async (actionType, obj) => {
        switch (actionType) {
            case ActionType.insert:
                setShowTeamMemberInsertPopup(true);
                break;
            case ActionType.refresh:
                await refreshTeamMemberTable();
                break;
            case ActionType.download:
                log("download");
                break;
        }
    }
    const onActionCallbackTeamMember = async (actionType, newValues, id) => {
        let response;
        switch (actionType) {
            case ActionType.insert:
                log("insert, newValues", newValues);
                response = await addLocationUser(routeParams.id, newValues);
                if (response.status === 200) {
                    await refreshTeamMemberTable();
                }
                setShowTeamMemberInsertPopup(false);
                break;
            case ActionType.download:
                log("download");
                break;
        }
    }

    const refreshAttorneyTable = async () => {
        setLoadingAttorneyTable(true);
        await getAttorneyUsers(routeParams.id, "ATTORNEY", true)
        setLoadingAttorneyTable(false);
    }
    const refreshTeamMemberTable = async () => {
        setLoadingTeamMemberTable(true);
        await getTeamMembers(routeParams.id, "TEAM_MEMBERS", true)
        setLoadingTeamMemberTable(false);
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        log("routeParams.lawId,", routeParams.lawId);
        if (routeParams && routeParams.id && routeParams.id !== '0') {
            setIsEdit(true);
            if (loadingLawFirmLocation) {
                await getLawFirmLocation();
            }
        }

        setLoadingLawFirmLocation(false);
    }, []);

    useEffect(async () => {
        try {
            if (routeParams && routeParams.id && routeParams.id !== '0') {
                setLoadingAttorney(true);
                await getAttorneyUsers(routeParams.id, "ATTORNEY", false);
            }
            setLoadingAttorney(false);
            setLoadingAttorneyTable(false);
        } catch {
            setLoadingAttorney(false);
            setLoadingAttorneyTable(false);
        }

    }, [controllerAttorney, queryOptionsAttorney, queryOptionsSortAttorney]);

    useEffect(async () => {
        try {
            if (routeParams && routeParams.id && routeParams.id !== '0') {
                setLoadingTeamMember(true);
                await getTeamMembers(routeParams.id, "TEAM_MEMBERS", false);
            }
            setLoadingTeamMember(false);
            setLoadingTeamMemberTable(false);
        } catch {
            setLoadingTeamMember(false);
            setLoadingTeamMemberTable(false);
        }
    }, [controllerTeamMember, queryOptionsTeamMember, queryOptionsSortTeamMember]);

    return (
        <>
            <CusNavBar/>
            <GoBack
                pageUrl={location?.state !== undefined && location.state !== null? `${location?.state?.history}` : "/location_list"}></GoBack>
            <br></br>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{width: '100%', height: '100%', padding: "0", margin: "0"}}>
                        <CardContent sx={{width: '100%', margin: "0"}}>
                            <Row>
                                <>
                                    <Col className={"col-4"} xl={4} xs={12}>
                                        {
                                            !loadingLawFirmLocation &&
                                            <LawFirmLocationMain data={lawFirmLocation} id={routeParams.id}
                                                                 lawId={routeParams.lawId}
                                                                 oncallback={onCreateCallback}></LawFirmLocationMain>
                                        }
                                    </Col>
                                    <Col className={"col-8"} xl={8} xs={12}>

                                        <h1>Users</h1>

                                        <Row className={"mb-3"}>
                                            {
                                                !loadingAttorney && <UserList title="ATTORNEY"
                                                                              data={attorneyList}
                                                                              isEdit={isEdit}
                                                                              rowCount={rowCountAttorney}
                                                                              paginationModel={controllerAttorney}
                                                                              onPaginationModelChange={gatedSetControllerAttorney}
                                                                              onFilterModelChange={onFilterChangeAttorney}
                                                                              onSortModelChange={handleSortModelChangeAttorney}
                                                                              loading={loadingAttorneyTable}
                                                                              editID={routeParams.id}
                                                                              showUserInsertPopup={showAttorneyInsertPopup}
                                                                              setShowUserInsertPopup={setShowAttorneyInsertPopup}
                                                                              onCallback={onCallbackAttorney}
                                                                              onActionCallback={onActionCallbackAttorney}
                                                                              isWebAdministrator={false}
                                                                              showBoolean={true}
                                                                              showLocation={false}>
                                                </UserList>
                                            }


                                        </Row>
                                        <Row className={"mb-3"}>
                                            {
                                                !loadingTeamMember &&
                                                <UserList title="TEAM_MEMBER"
                                                          data={teamMemberList}
                                                          isEdit={isEdit}
                                                          rowCount={rowCountTeamMember}
                                                          paginationModel={controllerTeamMember}
                                                          onPaginationModelChange={gatedSetControllerTeamMember}
                                                          onFilterModelChange={onFilterChangeTeamMember}
                                                          onSortModelChange={handleSortModelChangeTeamMember}
                                                          loading={loadingTeamMemberTable}
                                                          showUserInsertPopup={showTeamMemberInsertPopup}
                                                          setShowUserInsertPopup={setShowTeamMemberInsertPopup}
                                                          onCallback={onCallbackTeamMember}
                                                          onActionCallback={onActionCallbackTeamMember}
                                                          editID={routeParams.id}
                                                          isWebAdministrator={false}
                                                          showBoolean={true}
                                                          showLocation={false}>
                                                </UserList>
                                            }
                                        </Row>
                                    </Col>
                                </>
                            </Row>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <br/>
            <Footer/>
        </>
    )
};

export default LawFirmLocation;