import React, {useEffect, useState} from 'react';
import '../styles/login.css';
import '../App.css';
import {useNavigate} from "react-router";
import Footer from "./footer";
import CusAlert from "./alert";
import CusNavBar from "./navbar";
import {useDispatch, useSelector} from "react-redux";
import {
    addBondProcessDetails,
    addBondProcessInputs,
    addLawFirmData,
    mergeBondProcessHistory,
    removeBondProcessHistory
} from '../store/bond';
import LoadingOverlay from 'react-loading-overlay';
import APTableLoading from "./loadings/ap_table_loading";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {getBondList, getLawFirmData} from "../services/bond";
import {Tab, Tabs} from "react-bootstrap";
import {getProcesses, getProcessInputs} from "../services/process";
import BondEditForm from "./bond_edit_form";
import {log} from "../utils/logger";
import {hasUserPermission} from "../utils/user_permission";

function BondApproval(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [emptyBonds, setEmptyBonds] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [loadingApproved, setLoadingApproved] = useState(false);
    const [loadingRejected, setLoadingRejected] = useState(false);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const [bondToEdit, setBondToEdit] = useState(0);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [showFailPopUp, setShowFailPopUp] = useState(false);

    const approved_bonds = useSelector((state) => {
        let approvedList = state.bond.bond_history.filter((bond) => bond.is_approved === "APPROVED");
        return approvedList;
    });
    const rejected_bonds = useSelector((state) => {
        return state.bond.bond_history.filter((bond) => bond.is_approved === "REJECTED");
    });
    const under_processing_bonds = useSelector((state) => {
        return state.bond.bond_history.filter((bond) => bond.is_approved === "IN_PROCESS");
    });
    const processInput = useSelector((state) => state.bond.bond_process_input);
    const lawFirms = useSelector((state) => state.bond.law_firms);


    const getColumns = () => {
        const columns = [];
        columns.push({
            field: 'id',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'View Bond',
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                return (<button onClick={() => {
                    setBondToEdit(params.row.id);
                    window.scroll(0, 50)
                }}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                            inline-flex items-center">
                    <span>View</span>
                </button>);
            }
        })
        columns.push({
            field: 'bond_type',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            headerName: 'Bond Type',
            headerClassName: 'header-bold right-column'
        })
        columns.push({
            field: 'bond_sub_category',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'Bond Sub Category',
            headerClassName: 'header-bold right-column'
        })
        columns.push({
            field: 'bond_amount',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'Bond Amount',
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                return (<span>$ {parseInt(params.row.bond_amount).toLocaleString()}</span>);
            }
        })
        columns.push({
            field: 'bond_number',
            minWidth: 100, maxWidth: 1000, flex: 1,
            headerName: 'Bond Number',
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                if (params.row.bond_number?.bond_number === undefined) {
                    return (<span>{params.row.bond_number}</span>)
                } else {
                    return (<span>{params.row.bond_number.bond_number}</span>)
                }
            }
        })
        columns.push({
            field: 'deceased_legal_name',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'Deceased Legal Name',
            headerClassName: 'header-bold right-column'
        })
        columns.push({
            field: 'created_at',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'Created At',
            headerClassName: 'header-bold right-column',
            valueFormatter: params => !params ? "" : new Date(params?.value).toLocaleString('en-US', {
                year: 'numeric', month: '2-digit', day: '2-digit'
            })
        })

        return columns;
    }

    const getApproveAndRejectBondColumns = () => {
        const columns = [];
        columns.push({
            field: 'id',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'View Bond',
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                const element = params.row.bond_type === 'Reserve' ? "" : <button onClick={() => {
                    setBondToEdit(params.row.id);
                    window.scroll(0, 50)
                }}
                                                                                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded
                        inline-flex items-center">
                    <span>View</span>
                </button>;
                return element
            }
        })
        columns.push({
            field: 'bond_type',
            minWidth: 120,
            maxWidth: 1000,
            flex: 1,
            headerName: 'Bond Type',
            headerClassName: 'header-bold right-column'
        })
        columns.push({
            field: 'bond_sub_category',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'Bond Sub Category',
            headerClassName: 'header-bold right-column'
        })
        columns.push({
            field: 'bond_amount',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'Bond Amount',
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                return (<span>$ {parseInt(params.row.bond_amount).toLocaleString()}</span>);
            }
        })
        columns.push({
            field: 'bond_number',
            minWidth: 100, maxWidth: 1000, flex: 1,
            headerName: 'Bond Number',
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                if (params.row.bond_number?.bond_number === undefined) {
                    return (<span>{params.row.bond_number}</span>)
                } else {
                    return (<span>{params.row.bond_number.bond_number}</span>)
                }
            }
        })
        columns.push({
            field: 'deceased_legal_name',
            minWidth: 180, maxWidth: 1000, flex: 1,
            headerName: 'Deceased Legal Name',
            headerClassName: 'header-bold right-column'
        })
        columns.push({
            field: 'created_at',
            minWidth: 120, maxWidth: 1000, flex: 1,
            headerName: 'Created At',
            headerClassName: 'header-bold right-column',
            valueFormatter: params => !params ? "" : new Date(params?.value).toLocaleString('en-US', {
                year: 'numeric', month: '2-digit', day: '2-digit'
            })
        })

        return columns;
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        if (lawFirms.length === 0) {
            getLawFirmData().then((res) => {
                dispatch(addLawFirmData(res));
            }).catch((err) => {
                log(err);
            })
        }
        if (emptyBonds === true) {
            setEmptyBonds(false);
            dispatch(removeBondProcessHistory());
        }

        setLoadingRejected(true);
        setLoadingApproved(true);
        setLoadingProcess(true);
        getBondList('IN_PROCESS').then((res) => {
            dispatch(mergeBondProcessHistory(res));
            setLoadingProcess(false);
        }).catch((error) => {
            log(error);
            setLoadingProcess(false);
            navigate('/');
        });

        // getBondList('APPROVED').then((res) => {
        //     log("mergeBondProcessHistory start");
        //     dispatch(mergeBondProcessHistory(res));
        //     log("mergeBondProcessHistory end");
        //     setLoadingApproved(false);
        // }).catch((error) => {
        //     log(error);
        //     setLoadingApproved(false);
        // });

        // getBondList('REJECTED').then((res) => {
        //     dispatch(mergeBondProcessHistory(res));
        //     setLoadingRejected(false);
        // }).catch((error) => {
        //     log(error);
        //     setLoadingRejected(false);
        // });

        if (processInput.length === 0) {
            getProcesses('Request Bond').then((response) => {
                dispatch(addBondProcessDetails(response));
                getProcessInputs(response[0].id).then(async (res) => {
                    dispatch(addBondProcessInputs(res));
                }).catch((err) => {
                    log(err);
                });
            }).catch((err) => {
                log(err);
            });
        }
        document.title = "Bond List";
    }, [])


    const closeEditForm = (documentID = 0) => {
        setBondToEdit(0);
        if (showSuccessPopUp) {
            log("closeEditForm, showSuccessPopUp: ", showSuccessPopUp);
        }
        // if (documentID !== 0){
        //     dispatch(removeDocument({id: documentID}));
        // }
    }

    function OnBondEditFormSuccess() {
        setLoading(true);
        setShowSuccessPopUp(true);
        setLoading(false);
    }

    return (<>
        <CusNavBar/>
        {showSuccessPopUp && <CusAlert alertType='success' alertMessage="Process completed successfully."
                                       alertClose={setShowSuccessPopUp}/>}
        {showFailPopUp && <CusAlert alertType='danger' alertMessage="Something went wrong while updating bond."
                                    alertClose={setShowFailPopUp}/>}
        <header className="d-flex align-items-center justify-content-between text-dark text-decoration-none
                pt-3 pb-3 mb-3 border-bottom">
            <div className="fs-1">Bond List</div>
        </header>

        <div className="my-3">
            <p className="fs-3 col-md-8">{hasUserPermission(user_permission, 'show_view_button_in_bond') ? "Please click on the view button for the bond you would like to " + "process." : "Following is the list of bonds."}</p>
        </div>

        <hr className="col-3 col-md-2"/>

        {bondToEdit !== 0 && <BondEditForm bond_id={bondToEdit} closeCallBack={closeEditForm}
                                           successCallBack={OnBondEditFormSuccess}
                                           failCallBack={setShowFailPopUp}/>}

        <hr className="col-12"/>
        {(<Tabs defaultActiveKey="under_processing_bonds" id="uncontrolled-tab-example" className="mb-3"
                onSelect={(key) => {
                    if (key === 'under_processing_bonds') {
                        setLoadingProcess(true);
                        setBondToEdit(0);
                        getBondList('IN_PROCESS').then((res) => {
                            dispatch(removeBondProcessHistory());
                            dispatch(mergeBondProcessHistory(res));
                            setLoadingProcess(false);
                        }).catch((error) => {
                            log(error);
                            setLoadingProcess(false);
                            navigate('/');
                        });
                    } else if (key === 'approved_bonds') {
                        setLoadingApproved(true);
                        setBondToEdit(0);
                        getBondList('APPROVED').then((res) => {
                            dispatch(removeBondProcessHistory());
                            dispatch(mergeBondProcessHistory(res));
                            setLoadingApproved(false);
                        }).catch((error) => {
                            log(error);
                            setLoadingApproved(false);
                        });
                    } else if (key === 'rejected_bonds') {
                        setLoadingRejected(true);
                        setBondToEdit(0);
                        getBondList('REJECTED').then((res) => {
                            dispatch(removeBondProcessHistory());
                            dispatch(mergeBondProcessHistory(res));
                            setLoadingRejected(false);
                        }).catch((error) => {
                            log(error);
                            setLoadingRejected(false);
                        });
                    }
                }}>
            <Tab eventKey="under_processing_bonds" title="To Be Processed">

                <DataGrid
                    disableSelectionOnClick={true}
                    style={{height: "500px"}}
                    rows={under_processing_bonds.filter((bond) => bond.is_approved === "IN_PROCESS")}
                    columns={getColumns()}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}}
                    }}
                    pageSizeOptions={[]}
                    loading={loadingProcess || loading}
                />

            </Tab>
            <Tab eventKey="approved_bonds" title="Approved Bonds">

                <DataGrid
                    disableSelectionOnClick={true}
                    style={{height: "500px"}}
                    rows={approved_bonds.filter((bond) => bond.is_approved === "APPROVED")}
                    columns={getApproveAndRejectBondColumns()}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}}
                    }}
                    pageSizeOptions={[]}
                    loading={loadingApproved || loading}
                />

            </Tab>
            <Tab eventKey="rejected_bonds" title="Rejected Bonds">

                <DataGrid
                    disableSelectionOnClick={true}
                    style={{height: "500px"}}
                    rows={rejected_bonds.filter((bond) => bond.is_approved === "REJECTED")}
                    columns={getApproveAndRejectBondColumns()}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}}
                    }}
                    pageSizeOptions={[]}
                    loading={loadingRejected || loading}
                />

            </Tab>
        </Tabs>)}
        <Footer/>
    </>)
}


export default BondApproval;
