import React, {useEffect, useState, useCallback, useLayoutEffect} from 'react';
import CusNavBar from "./navbar";
import CusAlert from "./alert";
import Header from "./header";
import LoadingOverlay from 'react-loading-overlay';
import {useDispatch, useSelector} from "react-redux";
import {getCapitalProjectHistory, getCapitalProjectHistoryWithStatus} from "../services/capital_project";
import {
    addProcessHistory,
    addProcessDetails,
    addProcessInputs
} from "../store/capital_project";
import {useLocation, useNavigate} from "react-router";
import APTableLoading from "./loadings/ap_table_loading";
import {getProcesses, getProcessInputs} from "../services/process";
import CapitalProjectInputPopup from "../components/popups/capital_project_input_popup";
import {API_URL} from "../constants";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {Tab, Tabs} from "react-bootstrap";
import {Badge} from "react-bootstrap";
import CusDataGrid from "../global_components/datatables/datagrid";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from '@mui/icons-material/Visibility';
import "../styles/insurance.css";
import button from "bootstrap/js/src/button";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";

var ulID = 0;

function writeUploadFiles(jsonVal) {

    const listArr = [];
    if (jsonVal !== null && jsonVal !== undefined && jsonVal !== '' && jsonVal.length > 0) {
        jsonVal?.forEach(element => {
            listArr.push(
                <li>{element.file_name}
                    <Button size='small' target="_blank" href={element.file_url}>
                        {
                            element.file_url !== null && element.file_url !== undefined && element.file_url !== "" ?
                                <DownloadIcon color="primary" fontSize="small"></DownloadIcon> : null
                        }
                    </Button>
                </li>);
        });

    }

    return listArr;
}

function RenderFiles(props) {


    const {value, ...authProviderConfig} = props;

    return (
        <strong>
            <ul id={"UL_" + ulID++}>
                <li>
                    <ul className="tree">
                        <li><a href="#">All Attachment</a>
                            <ul>
                                {

                                    writeUploadFiles(value)
                                }

                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

        </strong>
    );
}

function ExpandableCell({value}) {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Box>
            {value !== undefined && !expanded ? value?.slice(0, 20) : value} &nbsp;
            {value !== undefined && value.length > 20 && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                    type="button"
                    component="button"
                    sx={{fontSize: 'inherit'}}
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? 'view less' : 'view more'}
                </Link>
            )}
        </Box>
    );
}

ExpandableCell.propTypes = {
    value: PropTypes.any,
};

export default function CapitalProject(props) {
    const draft = "draft";
    const inProcess = "in_process";
    const competed = "executed"
    const rejected = "rejected";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [overlayLoading, setOverlayLoading] = useState(false);
    const [overlayMessage, setOverlayMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadData, setLoadData] = useState(true);
    const [showProcessPopup, setShowProcessPopup] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(true);
    const [failureMessage, setFailureMessage] = useState('');
    const capitalProjectHistoryStore = useSelector((state) => state.capitalProject.capital_process_history);
    const capitalProjectInputsStore = useSelector((state) => state.capitalProject.popup_inputs);
    const capitalProjectDetailsStore = useSelector((state) => state.capitalProject.process_details);
    const [pendingLoading, setPendingLoading] = useState(true);
    const [completedLoading, setCompletedLoading] = useState(true);
    const [columnsDraft, setColumnsDraft] = useState([]);
    const [columnsPending, setColumnsPending] = useState([]);
    const [columnsCompleted, setColumnsCompleted] = useState([]);
    const [columnsRejected, setColumnsRejected] = useState([]);
    const {state} = useLocation();
    const [capitalDraftList, setCapitalDraftList] = useState([]);
    const [capitalInProcessList, setCapitalInProcessList] = useState([]);
    const [capitalCompletedList, setCapitalCompletedList] = useState([]);
    const [capitalRejectedList, setCapitalRejectedList] = useState([]);

    // Datagrid Loading
    const [loadingDataGrid, setLoadingDataGrid] = useState(true);
    const [loadingInProcessDataGrid, setLoadingInProcessDataGrid] = useState(true);
    const [loadingCompletedDataGrid, setLoadingCompletedDataGrid] = useState(true);
    const [loadingRejectedDataGrid, setLoadingRejectedDataGrid] = useState(true);
    const [currentTab, setCurrentTab] = useState();

    const handleSelect = (eventKey) => {
        localStorage.setItem("capitalProjectTab", eventKey);
        setCurrentTab(eventKey);
    };
    // Datagrid Pagination
    if (!localStorage.getItem("capital_project_datagrid_pagesize")) {
        localStorage.setItem("capital_project_datagrid_pagesize", JSON.stringify({
            'draft': 10,
            'pending': 10,
            'completed': 10,
            'rejected': 10
        }));
    }
    const [rowCountDraft, setRowCountDraft] = useState(0);
    const [controllerDraft, setControllerDraft] = useState({
        page: 0,
        pageSize: JSON.parse(localStorage.getItem("capital_project_datagrid_pagesize"))['draft']
    });
    const gatedSetControllerDraft = useCallback((value) => {
        setControllerDraft(value);
    }, []);
    const [rowCountPending, setRowCountPending] = useState(0);
    const [controllerPending, setControllerPending] = useState({
        page: 0,
        pageSize: JSON.parse(localStorage.getItem("capital_project_datagrid_pagesize"))['pending']
    });
    const gatedSetControllerPending = useCallback((value) => {
        setControllerPending(value);
    }, []);
    const [rowCountCompleted, setRowCountCompleted] = useState(0);
    const [controllerCompleted, setControllerCompleted] = useState({
        page: 0,
        pageSize: JSON.parse(localStorage.getItem("capital_project_datagrid_pagesize"))['completed']
    });
    const gatedSetControllerCompleted = useCallback((value) => {
        setControllerCompleted(value);
    }, []);
    const [rowCountRejected, setRowCountRejected] = useState(0);
    const [controllerRejected, setControllerRejected] = useState({
        page: 0,
        pageSize: JSON.parse(localStorage.getItem("capital_project_datagrid_pagesize"))['rejected']
    });
    const gatedSetControllerRejected = useCallback((value) => {
        setControllerRejected(value);
    }, []);

    // Datagrid Filter
    const [prevFilterDraft, setPrevFilterDraft] = useState("");
    const [queryOptionsDraft, setQueryOptionsDraft] = useState({});
    const onFilterChangeDraft = useCallback((filterModel: GridFilterModel) => {
        setQueryOptionsDraft({filterModel: {...filterModel.items}});
    }, []);
    const [prevFilterPending, setPrevFilterPending] = useState("");
    const [queryOptionsPending, setQueryOptionsPending] = useState({});
    const onFilterChangePending = useCallback((filterModel: GridFilterModel) => {
        setQueryOptionsPending({filterModel: {...filterModel.items}});
    }, []);
    const [prevFilterCompleted, setPrevFilterCompleted] = useState("");
    const [queryOptionsCompleted, setQueryOptionsCompleted] = useState({});
    const onFilterChangeCompleted = useCallback((filterModel: GridFilterModel) => {
        setQueryOptionsCompleted({filterModel: {...filterModel.items}});
    }, []);
    const [prevFilterRejected, setPrevFilterRejected] = useState("");
    const [queryOptionsRejected, setQueryOptionsRejected] = useState({});
    const onFilterChangeRejected = useCallback((filterModel: GridFilterModel) => {
        setQueryOptionsRejected({filterModel: {...filterModel.items}});
    }, []);

    //Datagrid Sort
    const [prevSortDraft, setPrevSortDraft] = useState("");
    const [queryOptionsSortDraft, setQueryOptionsSortDraft] = useState({});
    const handleSortModelChangeDraft = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortDraft({sortModel: [...sortModel]});
    }, []);
    const [prevSortPending, setPrevSortPending] = useState("");
    const [queryOptionsSortPending, setQueryOptionsSortPending] = useState({});
    const handleSortModelChangePending = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortPending({sortModel: [...sortModel]});
    }, []);
    const [prevSortCompleted, setPrevSortCompleted] = useState("");
    const [queryOptionsSortCompleted, setQueryOptionsSortCompleted] = useState({});
    const handleSortModelChangeCompleted = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortCompleted({sortModel: [...sortModel]});
    }, []);
    const [prevSortRejected, setPrevSortRejected] = useState("");
    const [queryOptionsSortRejected, setQueryOptionsSortRejected] = useState({});
    const handleSortModelChangeRejected = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSortRejected({sortModel: [...sortModel]});
    }, []);
    useLayoutEffect(() => {

        const tree = document.querySelectorAll('ul.tree a:not(:last-child)');
        for (var i = 0; i < tree.length; i++) {

            tree[i].addEventListener('click', function (e) {
                var parent = e.target.parentElement;
                var classList = parent.classList;

                var closeAllOpenSiblings = function () {
                    const elements = document.querySelectorAll([":scope .open"]).forEach((el) => {
                        el.classList.remove('open')
                    });

                }
                if (!classList.contains("open")) {
                    closeAllOpenSiblings();
                    classList.add('open');
                }
            });
        }

    });

    useEffect(() => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }

        if (Object.keys(capitalProjectDetailsStore).length === 0 || capitalProjectInputsStore.length === 0) {
            getProcesses("Capital Proposal").then(async (response) => {
                dispatch(addProcessDetails(response[0]))
                if (capitalProjectInputsStore.length === 0) {
                    getProcessInputs(response[0].id).then((resp) => {
                        dispatch(addProcessInputs(resp));
                    }).catch((error) => {
                        console.log(error);
                        setFailureMessage("Something went wrong fetching the inputs.");
                        setShowFailureMessage(true);
                    });
                }
            }).catch((error) => {
                console.log(error);
            });
        }


    }, [refreshGrid]);

    useEffect(() => {
        setColumnsDraft(getColumns());
        getData();
    }, [controllerDraft, queryOptionsDraft, queryOptionsSortDraft]);

    const getColumns = (status) => {

        const columns = [];
        columns.push({
            field: 'id',
            minWidth: 50, maxWidth: 1000, flex: 1,
            headerName: '',
            hideFilterIcons: true,
            headerClassName: 'header-bold right-column',
            renderCell: (params) => {
                const element = <a href={`/capital_project_create/${params.row.approval_id}`}> <VisibilityIcon
                    type="button" color={"primary"}/></a>;
                return element
            }
        })
        if (status === inProcess) {
            columns.push(
                {
                    field: 'approval_order_label',
                    headerName: 'Approval Stage',
                    minWidth: 120,
                    maxWidth: 1200,
                    flex: 1,
                    editable: false,
                    headerClassName: 'header-bold',
                    sortID: -2,
                    headerAlign: 'right',
                    align: 'center',
                    renderCell: (params) => {
                        return (
                            <h6>
                                <Badge pill bg="warning">
                                    {params.row.approval_order_label}
                                </Badge>
                            </h6>
                        );

                    }
                }
            )
        }
        const currencyFormatter = new Intl.NumberFormat(undefined,
            {
                style: 'currency',
                currency: 'USD',
            }
        );
        columns.push(
            {
                field: 'approval_id',
                headerName: 'Project ID',
                minWidth: 100,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'project_name',
                headerName: 'Project Name',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                renderCell: (params) => <ExpandableCell {...params} />
            },
            {
                field: 'division',
                headerName: 'Division',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                renderCell: (params) => <ExpandableCell {...params} />
            },
            {
                field: 'justification',
                headerName: 'Justification',
                minWidth: 120, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                renderCell: (params) => <ExpandableCell {...params} />
            },
            {
                field: 'scope_of_work',
                headerName: 'Scope Of Work',
                minWidth: 120, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                renderCell: (params) => <ExpandableCell {...params} />
            },
            {
                field: 'project_owner',
                headerName: 'Project Owner',
                minWidth: 180, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'division_head',
                headerName: 'Division Head',
                minWidth: 180, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'project_coordinator',
                headerName: 'Project Coordinator',
                minWidth: 180, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'additional_contacts',
                headerName: 'Additional Contact',
                minWidth: 120, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold'
            },
            {
                field: 'budget',
                headerName: 'Budget',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                align: 'right',
                renderCell: (params) => {
                    if (params.row.budget === "" || params.row.budget === 0) {
                        return "-";
                    } else {
                        return currencyFormatter.format(params.row.budget)
                    }
                },
            },
            {
                field: 'contingency_amount',
                headerName: 'Contingency Amount',
                minWidth: 120, maxWidth: 1000, flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                align: 'right',
                renderCell: (params) => {
                    if (params.row.contingency_amount === "" || params.row.contingency_amount === 0) {
                        return "-";
                    } else {
                        return currencyFormatter.format(params.row.contingency_amount)
                    }
                },
            },
            {
                field: 'total_budget',
                headerName: 'Total Budget',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                align: 'right',
                renderCell: (params) => {
                    if (params.row.total_budget === "" || params.row.total_budget === "0.00" || params.row.total_budget === 0) {
                        return "-";
                    } else {
                        return currencyFormatter.format(params.row.total_budget)
                    }
                },
            },
            {
                field: 'start_date',
                headerName: 'Start Date',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                renderCell: (params) => {
                    const startDate = new Date(params.row.start_date);
                    const localeFormattedDate = startDate.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    });
                    return localeFormattedDate.replace(/\./g, '/');
                },
            },
            {
                field: 'completion_date',
                headerName: 'Completion Date',
                minWidth: 180,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                renderCell: (params) => {
                    const completionDate = new Date(params.row.completion_date);
                    const localeFormattedDate = completionDate.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    });
                    return localeFormattedDate.replace(/\./g, '/');
                },
            },
            {
                field: 'process_status',
                headerName: 'Process Execution Status',
                minWidth: 150,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
            },
            {
                field: 'owner',
                headerName: 'Owner',
                minWidth: 150,
                maxWidth: 1000,
                flex: 1,
                hidden: true,
                editable: false,
                headerClassName: 'header-bold',
            },
            {
                field: 'current_approvers',
                headerName: 'Current Approver',
                minWidth: 120,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                renderCell: (params) => {
                    return (
                        <ul>
                            {
                                params.row.current_approvers.map(ap => <li>{ap}</li>)
                            }
                        </ul>
                    )
                }
            },
            {
                field: 'status',
                headerName: 'Status',
                minWidth: 100,
                maxWidth: 1000,
                flex: 1,
                editable: false,
                headerClassName: 'header-bold',
                type: 'boolean'
            }
        )
        columns.push(
            {
                field: 'upload_files',
                headerName: 'Attachments',
                width: 500,
                headerClassName: 'header-bold',
                renderCell: RenderFiles
            }
        )


        return columns;
    }
    const getData = async () => {
        setLoadingDataGrid(true);
        let filter = ""
        if (queryOptionsDraft.filterModel) {
            if (queryOptionsDraft.filterModel[0] !== undefined) {
                filter = queryOptionsDraft.filterModel[0].field + "-" +
                    queryOptionsDraft.filterModel[0].operator + "-" +
                    queryOptionsDraft.filterModel[0].value
                setPrevFilterDraft(filter)
            } else {
                filter = prevFilterDraft
            }
        }
        let sort = ""
        if (queryOptionsSortDraft.sortModel) {
            if (queryOptionsSortDraft.sortModel.length > 0) {
                if (prevSortDraft) {
                    sort = prevSortDraft
                }
                let sortModel = queryOptionsSortDraft.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortDraft !== sort) {
                    setPrevSortDraft(sort)
                } else {
                    if (queryOptionsDraft.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        try {
            const response = await getCapitalProjectHistoryWithStatus(draft, controllerDraft.page, controllerDraft.pageSize, filter, sort);
            setCapitalDraftList(response.data);
            setRowCountDraft(response.total);
            setLoadingDataGrid(false);

        } catch (error) {
            showFailureMessage(true);
            setLoadingDataGrid(false);
            console.log(error);
        }
    };
    useEffect(() => {
        setColumnsPending(getColumns(inProcess));
        getDataInProcess();
    }, [controllerPending, queryOptionsPending, queryOptionsSortPending]);

    useEffect(() => {
        setColumnsCompleted(getColumns());
        getDataCompleted();
    }, [controllerCompleted, queryOptionsCompleted, queryOptionsSortCompleted]);

    useEffect(() => {
        setColumnsRejected(getColumns());
        getDataRejected();
    }, [controllerRejected, queryOptionsRejected, queryOptionsSortRejected]);


    const getDataInProcess = async () => {
        setLoadingInProcessDataGrid(true);
        let filter = ""
        if (queryOptionsPending.filterModel) {
            if (queryOptionsPending.filterModel[0] !== undefined) {
                filter = queryOptionsPending.filterModel[0].field + "-" +
                    queryOptionsPending.filterModel[0].operator + "-" +
                    queryOptionsPending.filterModel[0].value
            } else {
                filter = prevFilterPending
            }
        }
        let sort = ""
        if (queryOptionsSortPending.sortModel) {
            if (queryOptionsSortPending.sortModel.length > 0) {
                if (prevSortPending) {
                    sort = prevSortPending
                }
                let sortModel = queryOptionsSortPending.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortPending !== sort) {
                    setPrevSortPending(sort)
                } else {
                    if (queryOptionsPending.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        try {
            const response = await getCapitalProjectHistoryWithStatus(inProcess, controllerPending.page, controllerPending.pageSize, filter, sort);
            setCapitalInProcessList(response.data);
            setRowCountPending(response.total);
            setLoadingInProcessDataGrid(false);

        } catch (error) {
            console.log(error);
            showFailureMessage(true);
            setLoadingInProcessDataGrid(false);
        }
    };
    const getDataCompleted = async () => {
        setLoadingCompletedDataGrid(true);
        let filter = ""
        if (queryOptionsCompleted.filterModel) {
            if (queryOptionsCompleted.filterModel[0] !== undefined) {
                filter = queryOptionsCompleted.filterModel[0].field + "-" +
                    queryOptionsCompleted.filterModel[0].operator + "-" +
                    queryOptionsCompleted.filterModel[0].value
                setPrevFilterCompleted(filter)
            } else {
                filter = prevFilterCompleted
            }
        }
        let sort = ""
        if (queryOptionsSortCompleted.sortModel) {
            if (queryOptionsSortCompleted.sortModel.length > 0) {
                if (prevSortCompleted) {
                    sort = prevSortCompleted
                }
                let sortModel = queryOptionsSortCompleted.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortCompleted !== sort) {
                    setPrevSortCompleted(sort)
                } else {
                    if (queryOptionsCompleted.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        try {
            const response = await getCapitalProjectHistoryWithStatus(competed, controllerCompleted.page, controllerCompleted.pageSize, filter, sort);
            setCapitalCompletedList(response.data);
            setRowCountCompleted(response.total);
            setLoadingCompletedDataGrid(false);

        } catch (error) {
            console.log(error);
            showFailureMessage(true);
            setLoadingCompletedDataGrid(false);
        }
    };
    const getDataRejected = async () => {
        setLoadingRejectedDataGrid(true);
        let filter = ""
        if (queryOptionsRejected.filterModel) {
            if (queryOptionsRejected.filterModel[0] !== undefined) {
                filter = queryOptionsRejected.filterModel[0].field + "-" +
                    queryOptionsRejected.filterModel[0].operator + "-" +
                    queryOptionsRejected.filterModel[0].value
                setPrevFilterRejected(filter)
            } else {
                filter = prevFilterRejected
            }
        }
        let sort = ""
        if (queryOptionsSortRejected.sortModel) {
            if (queryOptionsSortRejected.sortModel.length > 0) {
                if (prevSortRejected) {
                    sort = prevSortRejected
                }
                let sortModel = queryOptionsSortRejected.sortModel[0]
                sort = sortModel.field + "-" + sortModel.sort
                if (prevSortRejected !== sort) {
                    setPrevSortRejected(sort)
                } else {
                    if (queryOptionsRejected.filterModel) {
                        sort = ""
                    }
                }
            }
        }
        try {
            const response = await getCapitalProjectHistoryWithStatus(rejected, controllerRejected.page, controllerRejected.pageSize, filter, sort);
            setCapitalRejectedList(response.data);
            setRowCountRejected(response.total);
            setLoadingRejectedDataGrid(false);

        } catch (error) {
            console.log(error);
            showFailureMessage(true);
            setLoadingRejectedDataGrid(false);
        }
    };

    const createBtnClick = async () => {
        // if (capitalProjectInputsStore.length === 0) {
        //     setOverlayMessage('Fetching Process Details...');
        //     setOverlayLoading(true);
        //     await getProcessInputs(capitalProjectDetailsStore.id).then((response) => {
        //         dispatch(addProcessInputs(response));
        //     }).catch((error) => {
        //         console.log(error);
        //         setFailureMessage("Something went wrong fetching the inputs.");
        //         setShowFailureMessage(true);
        //     });
        //     setOverlayLoading(false);
        // }
        //setShowProcessPopup(true);
        navigate("/capital_project_create/0")
    }


    return (
        <LoadingOverlay
            active={overlayLoading}
            spinner
            text={overlayMessage}
        >
            <CusNavBar/>
            <Header text="Capital Project Proposal"/>
            {
                showSuccessMessage && <CusAlert alertType='success' alertMessage="Proposal created successfully."
                                                alertClose={setShowSuccessMessage}/>
            }
            {
                showFailureMessage &&
                <CusAlert alertType='danger' alertMessage="Something went wrong while creating Proposal."
                          alertClose={setShowFailureMessage}/>
            }
            <div style={{marginBottom: "1%", textAlign: "right"}}>
                <button onClick={() => createBtnClick()} className="btn btn-primary">
                    Create
                </button>
            </div>

            {/*<Tabs defaultActiveKey="draft_capital" id="uncontrolled-tab-example" className="mb-3"*/}
            {/*      onSelect={(key) => {*/}
            {/*          if (key === 'draft_capital') {*/}
            {/*              getData();*/}
            {/*          } else if (key === 'pending_capital') {*/}
            {/*              getDataInProcess();*/}
            {/*          } else if (key === 'completed_capital') {*/}
            {/*              getDataCompleted();*/}
            {/*          } else if (key === 'rejected_capital') {*/}
            {/*              getDataRejected();*/}
            {/*          }*/}
            {/*      }}>*/}
            <Tabs defaultActiveKey="draft_capital" id="uncontrolled-tab-example" className="mb-3"
                  activeKey={localStorage.getItem("capitalProjectTab") || currentTab ? localStorage.getItem("capitalProjectTab") : "draft_capital"}
                  onSelect={(key) => handleSelect(key)}

            >

                <Tab eventKey="draft_capital" title="Draft">
                    <CusDataGrid columns={columnsDraft}
                                 data={capitalDraftList}
                                 rowCount={rowCountDraft}
                                 rowAutoHeight={() => "auto"}
                                 estimatedRowHeight={() => 100}
                                 paginationModel={controllerDraft}
                                 onPaginationModelChange={gatedSetControllerDraft}
                                 onFilterModelChange={onFilterChangeDraft}
                                 onSortModelChange={handleSortModelChangeDraft}
                                 loading={loadingDataGrid}/>

                </Tab>
                <Tab eventKey="pending_capital" title="Pending">
                    <CusDataGrid columns={columnsPending}
                                 data={capitalInProcessList}
                                 rowCount={rowCountPending}
                                 rowAutoHeight={() => "auto"}
                                 estimatedRowHeight={() => 100}
                                 paginationModel={controllerPending}
                                 onPaginationModelChange={gatedSetControllerPending}
                                 onFilterModelChange={onFilterChangePending}
                                 onSortModelChange={handleSortModelChangePending}
                                 loading={loadingInProcessDataGrid}/>

                </Tab>

                <Tab eventKey="completed_capital" title="Completed">
                    <CusDataGrid columns={columnsCompleted}
                                 data={capitalCompletedList}
                                 rowCount={rowCountCompleted}
                                 rowAutoHeight={() => "auto"}
                                 estimatedRowHeight={() => 100}
                                 paginationModel={controllerCompleted}
                                 onPaginationModelChange={gatedSetControllerCompleted}
                                 onFilterModelChange={onFilterChangeCompleted}
                                 onSortModelChange={handleSortModelChangeCompleted}
                                 loading={loadingCompletedDataGrid}/>

                </Tab>
                <Tab eventKey="rejected_capital" title="Rejected">
                    <CusDataGrid columns={columnsRejected}
                                 data={capitalRejectedList}
                                 rowCount={rowCountRejected}
                                 rowAutoHeight={() => "auto"}
                                 estimatedRowHeight={() => 100}
                                 paginationModel={controllerRejected}
                                 onPaginationModelChange={gatedSetControllerRejected}
                                 onFilterModelChange={onFilterChangeRejected}
                                 onSortModelChange={handleSortModelChangeRejected}
                                 loading={loadingRejectedDataGrid}/>

                </Tab>

            </Tabs>
            {
                showProcessPopup && <CapitalProjectInputPopup show={true} closePopup={setShowProcessPopup}
                                                              showSuccess={setShowSuccessMessage}
                                                              showFail={setShowFailureMessage}
                                                              refreshTable={setRefreshGrid}
                                                              refreshGrid={refreshGrid}/>
            }
        </LoadingOverlay>
    )

}
