import React from "react"
import ContentLoader from "react-content-loader"

const APTableLoading = (props) => (
    <ContentLoader
        speed={2}
        width={1000}
        height={424}
        viewBox="0 0 1000 424"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="3" ry="3" width="900" height="30" />
        <rect x="0" y="55" rx="3" ry="3" width="900" height="30" />
        <rect x="0" y="105" rx="3" ry="3" width="900" height="30" />
        <rect x="0" y="160" rx="3" ry="3" width="900" height="30" />
        <rect x="0" y="215" rx="3" ry="3" width="900" height="30" />
        <rect x="0" y="270" rx="3" ry="3" width="900" height="30" />
    </ContentLoader>
)

export default APTableLoading;

