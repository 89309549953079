import { getAPICall } from './helper';

export async function getCapitalProjectHistory () {
    return await getAPICall('/capital_project_history/');
}

export async function getCapitalProjectHistoryWithStatus(capitalStatus, page, pageSize,filter,sort){
    return await getAPICall(`/capital_project_history/?&status=${capitalStatus}&page=${page}&size=${pageSize}&filter=${filter}&sort=${sort}`);
}

export function validateData(values){
    let errors = {}
    if(values['total_budget'] !== (values['budget'] + values['contingency_amount'])){
        errors['total_budget'] = "Total Budget must be equal to sum of Budget and Contingency Amount.";
        return errors;
    }
    if(values['line_items'].length === 0){
        errors['line_items'] = "At least one funding source is required.";
        return errors;
    }
    const lineItemBudgetTotal = values['line_items'].reduce((total, obj) => obj.funding_source_budget_allocation + total,0)
    if(values['total_budget'] !== lineItemBudgetTotal){
        errors['line_items'] = "Sum of all Funding Source Budget Allocation must be equal toTotal Budget.";
        return errors;
    }
    return errors;
}