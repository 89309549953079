import React, { useState } from 'react';
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";


export default function CustomPasswordInput(props) {
    const [value, onChange] = useState(props.value);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    return (
        <Input type={showCurrentPassword ? "text" : "password"} className={props.className} name={props.name} 
            id={props.id} defaultValue={value} disabled={props.disabled} onChange={(value)=> { onChange(value.target.value) 
                props.onChange(value.target.value);}}
                endAdornment={<InputAdornment position="end">
                    <IconButton
                        onClick={()=>setShowCurrentPassword(!showCurrentPassword)}
                        onMouseDown={(e)=>e.preventDefault()}
                    >
                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>}/>
    );
}

CustomPasswordInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    endAdornment: PropTypes.func.isRequired
};
