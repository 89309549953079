import React, {useEffect, useState} from 'react';
import '../styles/login.css';
import {useNavigate} from "react-router";
import PropTypes from "prop-types";
import Navbar from './navbar';
import {setCurrentActive} from '../store/processes';
import {useDispatch, useSelector} from "react-redux";

function Block(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companyData = useSelector((state) => state.auth.company);

    useEffect(() => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
    }, [])

    const navigateMethod = () => {
        if (props.category_name === 'Document Approval') {
            navigate('/document_approval');
            return;
        } else if (props.category_name === 'Capital Proposal') {
            navigate('/capital_project');
            return;
        } else if (props.category_name === 'Request Bond') {
            navigate('/create_bond');
            return;
        } else if (props.category_name.includes('Bonds')) {
            navigate('/bond_history');
            return;
        } else if (props.category_name === 'Account Management') {
            console.log("block companyData.name ,", companyData.name);
            navigate(companyData.name === "Vermost" ? "/account_management" : "/company_management");
            return;
        } else if (props.category_name === 'Bond Calculator') {
            return;
        }
        localStorage.setItem('category_name', props.category_name);
        navigate('/process');
        dispatch(setCurrentActive(props.category_name));
    }

    return (
        <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 spaceCard">
            <div className="card">
                <div className="card-body">
                    <button disabled={props.processes.length === 0} onClick={() => navigateMethod()}
                            className="stretched-link">
                        <h4 className="card-title">{props.category_name}</h4>
                    </button>
                </div>
            </div>
        </div>
    )
}


export default Block;

Block.propTypes = {
    category_name: PropTypes.string.isRequired,
    processes: PropTypes.array.isRequired,
    setCategory: PropTypes.func.isRequired,
};
